import React from 'react';

import { get }from 'lodash';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { EMPTY_VALUE, SYSTEM_COMPANY_IDS } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class ContractDetailsCounterParties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getCounterPartiesSectionAsPerGrid = (payload, sectionName) => {
    return (
      <div>
        <h4 className="section-title">{sectionName}</h4>
        <ul>
          {Object.keys(payload).map(function(key, index) {
            return (
              <li key={index}>
               <Tooltip
                  className="field-label ellipses"
                  tooltipText={key}
                  textContent={key}
                />
                {<Tooltip
                  className="field-value ellipses"
                  tooltipText={payload[key] || EMPTY_VALUE}
                  textContent={payload[key] || EMPTY_VALUE}
                />}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  render() {
    const { contract } = this.props;
    const { expanded } = this.state;
    const isSystemSeller = SYSTEM_COMPANY_IDS.includes(get(contract.seller, 'company.id'))
    const sellerPayload = {
      Name: isSystemSeller ? EMPTY_VALUE : (contract?.seller?.ngr?.shareholdersName ? contract?.seller?.ngr?.shareholdersName : get(contract, 'seller.company.name')),
      'Entity': isSystemSeller ? EMPTY_VALUE : (contract?.seller?.ngr?.shareholdersEntityName ? contract?.seller?.ngr?.shareholdersEntityName : get(contract, 'seller.company.entityName')),
      NGR: contract?.seller?.ngrId ? contract.seller.ngr.ngrNumber : null,
      Contact: isSystemSeller ? EMPTY_VALUE : get(contract.seller, 'contact.name')
    };

    const buyerPayload = {
      Name: contract?.buyer?.ngr?.shareholdersName ? contract?.buyer?.ngr?.shareholdersName : get(contract, 'buyer.company.name'),
      'Entity': contract?.buyer?.ngr?.shareholdersEntityName ? contract?.buyer?.ngr?.shareholdersEntityName : get(contract,'buyer.company.entityName'),
      NGR: contract?.buyer?.ngrId ? contract.buyer.ngr.ngrNumber: null,
      Contact: get(contract.buyer, 'contact.name'),
    };

    const assignerPayload = {
      Name: get(contract, 'createdBy.company.name'),
      'Entity': get(contract,'createdBy.company.entityName'),
      Contact: get(contract, 'createdBy.name'),
    };

    const sellerSectionName = 'Seller'.concat(get(contract, 'seller.representedById') === this.props.currentUser.companyId ? ' (Representing)' : '');
    const buyerSectionName = 'Buyer'.concat(get(contract, 'buyer.representedById') === this.props.currentUser.companyId ? ' (Representing)' : '');

    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Counterparties
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div className="section-details-container">
            {this.getCounterPartiesSectionAsPerGrid(sellerPayload, sellerSectionName)}
            {this.getCounterPartiesSectionAsPerGrid(buyerPayload, buyerSectionName)}
            {this.getCounterPartiesSectionAsPerGrid(assignerPayload, 'Assignor')}
          </div>
        )}
      </Paper>
    );
  }
}

export default ContractDetailsCounterParties;
