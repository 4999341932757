import React, { Component } from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { forEach, some, map, flatten, filter, get, set, mapValues, compact, isEqual, isEmpty } from 'lodash';
import APIService from '../../services/APIService';
import { required } from '../../common/validators';
import { cartesian } from '../../common/utils';
import AutoComplete from '../common/autocomplete/AutoComplete';
import TextField from '@mui/material/TextField';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';

class ImpexDocsMappingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
      productMaster: [],
      gradeIds: [],
      varietyIds: [],
      fields: {
        commodityId: {
          value: '',
          validators: [required()],
          errors: [],
        },
        gradeId: {
          value: '',
          validators: [],
          errors: [],
        },
        varietyId: {
          value: '',
          validators: [],
          errors: [],
        },
        productCode: {
          value: '',
          validators: [required()],
          errors: [],
        },
      }
    }
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  componentDidMount() {
    if(this.state.selected)
      this.setForEdit()
    APIService.companies(this.props.company.id).appendToUrl('impex-docs/product-master/').get().then(response => {
      this.setState({productMaster: response?.products || []})
    })
  }

  setAllFieldErrors = () => {
    const newState = {...this.state}
    forEach(newState.fields, (state, field) => {
      newState.fields[field].errors = []
      state.validators.forEach(validator => {
        if(validator.isInvalid(state.value))
          newState.fields[field].errors.push(validator.message)
      })
    })
    this.setState(newState)
  }

  getPayload = () => {
    let payload = []
    const NONE = '__NONE__'
    const { gradeIds,  varietyIds, fields} = this.state
    const formatList = list =>  isEmpty(list) ? [NONE] : list
    const combos = cartesian(formatList(gradeIds), formatList(varietyIds))
    const toValue = value => value === NONE ? undefined : value
    payload =  combos.map(combo => ({
      commodityId: fields.commodityId.value,
      gradeId: toValue(combo[0]),
      varietyId: toValue(combo[1]),
      productCode: fields.productCode.value,
    }))
    return payload
  }

  onSubmit = () => {
    this.setAllFieldErrors()
    if(!some(this.state.fields, field => field.errors.length > 0)) {
      if(this.state.selected) {
        let payload = mapValues(this.state.fields, field => field?.value ?? null)
        APIService.companies(this.props.company.id).appendToUrl(`impex-docs/mappings/${this.state.selected.id}/`)
        .put(payload)
        .then(response => {
          if (response?.error) 
            alertifyjs.error(response?.error)
          else {
            alertifyjs.success('Mapping updated successfully!')
            this.props.onSuccess()
          }
          this.props.onClose()
        })
      } 
      else {
      let payload = this.getPayload()
      let data = {impexDocsMappings: payload};
        APIService.companies(this.props.company.id).appendToUrl('impex-docs/mappings/products/')
        .post(data)
        .then(response => {
          if(some(response, r => r?.errors || get(r, 'errors._All_')))
            alertifyjs.error('Some of the combination already exists. Create rest.', 3)
          this.props.onSuccess()
          this.props.onClose()
        })
      }
    } 
  }

  setForEdit = () => {
    const newState = {...this.state};
    ['commodityId', 'gradeId', 'varietyId', 'productCode'].forEach(field => {
      newState.fields[field].value = newState.selected[field] || ''
    });
    newState.gradeIds = compact([newState.selected.gradeId])
    newState.varietyIds = compact([newState.selected.varietyId])
    this.setState(newState)
  }

  onGradeChange = (id, grades) => this.setState({gradeIds: map(grades, 'id')})

  onVarietyChange = (id, varieties) => this.setState({varietyIds: map(varieties, 'id')})

  handleValueChange(value, id) {
    const newState = { ...this.state };
    newState.fields[id].value = typeof value == 'object' ? value.id : value;
    this.setState(newState, () => this.setFieldErrors(id));
  }

  setFieldErrors = path => this.setState(state => set(state, `fields${path}.errors`, this.getFieldErrors(path)));
    
  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `fields.${path}.value`);
    const validators = get(this.state, `fields.${path}.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  handleProductCodeChange = (value, id) => {
    const newState = { ...this.state };
    newState.fields[id].value = typeof value == 'object' ? (value?.inputValue || value?.name) : value;
    this.setState(newState, () => this.setFieldErrors(id));
  }

  getGrades = () => flatten(map(filter(this.props.commodities, commodity => isEqual(this.state.fields.commodityId.value, commodity.id)), 'grades'))

  getVarieties = () => flatten(map(filter(this.props.commodities, commodity => isEqual(this.state.fields.commodityId.value, commodity.id)), 'varieties'))

  render() {
    const { fields, selected, productMaster } = this.state
    const { onClose } = this.props
    const allGrades = this.getGrades()
    const allVarieties = this.getVarieties()
    return (
      <div className='cardForm cardForm--drawer' style={{marginTop: '20px'}}>
        {
          <div className="cardForm-content row">
            <div className='col-md-12' style={{marginTop: '3px'}}>
              <CommodityAutoComplete
                id="commodityId"
                onChange={this.handleValueChange}
                floatingLabelText="Commodity"
                commodityId={this.state.fields.commodityId.value}
                errorText={get(this.state, 'fields.commodityId.errors[0]', '')}
                variant="outlined"
                disabled={this.props.selected}
                style={{ marginRight: '16px', marginBottom: '15px'}}
              />
            </div>
            
            { this.props.selected ?
              <div className='col-sm-12 form-wrap' style={{marginTop:'15px'}}>
                <GradeAutoComplete
                  id="gradeId"
                  onChange={this.handleValueChange}
                  floatingLabelText="Grade"
                  commodityId={this.state.fields.commodityId.value}
                  gradeId={this.state.fields.gradeId.value}
                  dependsOnCommodity
                  dependsOnSeason
                  selectedGradeId={this.state.fields.gradeId.value}
                  errorText={get(this.state, 'fields.gradeId.errors[0]', '')}
                  variant="outlined"
                /> 
              </div> :
              <div className='col-md-12'>
                <CommonMultiSelect
                  id='gradeIds'
                  items={allGrades}
                  onChange={this.onGradeChange}
                  selectedItems={this.state.gradeIds}
                  label="Grades"
                  limitTags={2}
                  displayField='name'
                />
              </div>
            }
            { this.props.selected ? 
              <div className='col-sm-12 form-wrap'>
              <VarietyAutoComplete
                id='varietyId'
                label='Variety'
                commodityId={this.state.fields.commodityId.value}
                varietyId={this.state.fields.varietyId.value}
                dependsOnCommodity
                onChange={this.handleValueChange}
                style={{ float: 'left' }}
                errorText={this.state.fields.varietyId.errors[0]}
                onBlur={this.handleBlur}
                variant="outlined"
              /> 
              </div> :
              <div className='col-sm-12'>
              <CommonMultiSelect
                id='varietyIds'
                items={allVarieties}
                onChange={this.onVarietyChange}
                selectedItems={this.state.varietyIds}
                label="Varieties"
                limitTags={2}
                displayField='name'
              />
            </div>
            }
            
            <div className='col-sm-12 form-wrap' style={{marginTop: this.props.selected ? '2px' : '20px'}}>
              <AutoComplete
                id="productCode"
                options={productMaster}
                getOptionLabel={option => option.name}
                variant='outlined'
                label="Product Code"
                placeholder="Product Code"
                renderInput={(params) => (
                  <TextField
                    label="Product Code"
                    helperText={get(fields.productCode, 'errors[0]', '')}
                    error={get(fields.productCode, 'errors[0]', '')}
                    {...params}
                    variant='outlined'
                  />
                )}
                value={fields.productCode.value}
                onChange={value => this.handleProductCodeChange(value, 'productCode')}
              />
            </div>
            <div className='col-sm-12' style={{marginTop: '15px'}}>
              <Button variant='contained' color='secondary' style={{marginRight: '20px'}} onClick={onClose}>
                Cancel
              </Button>
              <Button variant='contained' color='primary' onClick={this.onSubmit}>
                {selected?.id ? 'Update' : 'Create'}
              </Button>
              </div>
          </div>
        }
      </div>
    )
  }
}

export default connect()(ImpexDocsMappingForm)
