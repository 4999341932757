/*eslint no-process-env: 0*/

import alertifyjs from 'alertifyjs';
import { receiveBHCLocations } from '../../actions/master/bhc-company-locations';
import APIService from "../../services/APIService";
import { currentUserToken, encrypt_password, pendoInitialize, saveHandlers, getSavedHandlers } from '../../common/utils';
import {includes, remove, uniqBy, isEmpty, forOwn, has, get} from 'lodash';
import moment from 'moment';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const REQUEST_VERSION = 'REQUEST_VERSION';
export const RECEIVE_VERSION = 'RECEIVE_VERSION';
export const RECEIVE_MARKET_ZONES = 'RECEIVE_MARKET_ZONES';
export const RECEIVE_BANKS = 'RECEIVE_BANKS';
export const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';
export const RECEIVE_ABN_RESPONSE = 'RECEIVE_ABN_RESPONSE';
export const RECEIVE_COMPANY_RESPONSE = 'RECEIVE_COMPANY_RESPONSE';
export const RECEIVE_ADMIN_EMAILS = 'RECEIVE_ADMIN_EMAILS';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PASSWORD_ERRORS = 'CHANGE_PASSWORD_ERRORS';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_HEADER_TEXT = 'SET_HEADER_TEXT';
export const SET_SUB_HEADER_TEXT = 'SET_SUB_HEADER_TEXT';
export const SET_SETTING_BUTTON_TEXT = 'SET_SETTING_BUTTON_TEXT';
export const SET_SECONDARY_SETTING_BUTTON_TEXT = 'SET_SECONDARY_SETTING_BUTTON_TEXT';
export const IS_LOADING = 'IS_LOADING';
export const RECEIVE_VALIDATE_REGO_RESPONSE = 'RECEIVE_VALIDATE_REGO_RESPONSE';
export const CLEAR_ABN_ERRORS = 'CLEAR_ABN_ERRORS';
export const MY_PROFILE_UPDATED = 'MY_PROFILE_UPDATED';
export const MY_PROFILE_UPDATE_ERRORS = 'MY_PROFILE_UPDATE_ERRORS';
export const CLEAR_MY_PROFILE_UPDATE_ERRORS = 'CLEAR_MY_PROFILE_UPDATE_ERRORS';
export const RECIEVE_NOTIFICATIONS = 'RECIEVE_NOTIFICATIONS';
export const SERVER_ERROR = 'SERVER_ERROR';
export const SET_PASSWORD_ERRORS = 'SET_PASSWORD_ERRORS';
export const RECEIVE_TOGGLES = 'RECIEVE_TOGGLES';
export const SET_LOADING_TEXT = 'SET_LOADING_TEXT';
export const SET_DOWNLOAD_BAR = 'SET_DOWNLOAD_BAR';
export const RECEIVE_TRACKS = 'RECEIVE_TRACKS';
export const SET_LINK_INVALID = 'SET_LINK_INVALID';
export const IS_SEARCH_APPLIED = 'IS_SEARCH_APPLIED';

export const requestVersion = () => ({
  type: REQUEST_VERSION,
});

export const receiveVersion = json => ({
  type: RECEIVE_VERSION,
  version: json.version,
  serverEnv: json.env,
  setEnvLabel: true,
});

export const receiveToggles = toggles => ({
  type: RECEIVE_TOGGLES,
  toggles: toggles
});

export const receiveMarketZones = json => ({
  type: RECEIVE_MARKET_ZONES,
  marketZones: json,
});

export const receiveTracks = tracks => ({
  type: RECEIVE_TRACKS,
  tracks: tracks,
});

export const receiveBanks = json => ({
  type: RECEIVE_BANKS,
  banks: json,
});

export const isLoading = (component, milliseconds) => ({
  type: IS_LOADING,
  isLoading: true,
  waitForComponent: component,
  waitForMilliseconds: milliseconds,
});
export const setDownloadBar = (message, isOpen, onClose) => ({
  type: SET_DOWNLOAD_BAR,
  message: message,
  isOpen: isOpen,
  onClose: onClose
});

export const loaded = component => ({
  type: IS_LOADING,
  isLoading: false,
  waitForComponent: component,
});

export const setLoadingText = text => ({
  type: SET_LOADING_TEXT,
  loadingText: text,
});

export const forceStopLoader = component => ({
  type: IS_LOADING,
  isLoading: false,
  waitForComponent: component,
  forceStopLoader: true,
  waitForMilliseconds: undefined,
});

export const setServerErrorCode = code => ({
  type: SERVER_ERROR,
  serverErrorCode: code,
});

export const receiveABNResponse = json => ({
  type: RECEIVE_ABN_RESPONSE,
  abn: json,
});

export const receiveCompanyResponse = json => ({
  type: RECEIVE_COMPANY_RESPONSE,
  company: json,
});

export const receiveAdminEmails = json => ({
  type: RECEIVE_ADMIN_EMAILS,
  emails: json['emails']
});

export const requestLogin = () => ({
  type: REQUEST_LOGIN,
});

export const receiveLogin = json => ({
  type: RECEIVE_LOGIN,
  user: json,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setLinkInvalid = flag => ({
  type: SET_LINK_INVALID,
  isValid: flag
})

export const changePasswordErrors = json => ({
  type: CHANGE_PASSWORD_ERRORS,
  password: json,
});

export const setBreadcrumbs = breadcrumbs => ({
  type: SET_BREADCRUMBS,
  breadcrumbs,
});
export const setHeaderText = text => ({
  type: SET_HEADER_TEXT,
  text,
});
export const setSubHeaderText = text => ({
  type: SET_SUB_HEADER_TEXT,
  text,
});

export const setSettingButton = text => ({
  type: SET_SETTING_BUTTON_TEXT,
  text,
});

export const setSecondarySettingButton = text => ({
  type: SET_SECONDARY_SETTING_BUTTON_TEXT,
  text,
});

export const receiveValidateRegoResponse = (data) => ({
  type: RECEIVE_VALIDATE_REGO_RESPONSE,
  isRegoAvailable: data,
});

export const clearAbnErrors = () => ({
  type: CLEAR_ABN_ERRORS,
});

export const myProfileUpdated = fields => ({
  type: MY_PROFILE_UPDATED,
  fields,
});

export const myProfileUpdateErrors = errors => ({
  type: MY_PROFILE_UPDATE_ERRORS,
  errors,
});

export const setPasswordErrors = errors => ({
  type: SET_PASSWORD_ERRORS,
  errors,
});

export const clearMyProfileUpdateErrors = () => ({
  type: CLEAR_MY_PROFILE_UPDATE_ERRORS,
});

export const receiveNotifications = (notifications) => ({
  type: RECIEVE_NOTIFICATIONS,
  notifications,
});

export const isSearchApplied = flag => ({
  type: IS_SEARCH_APPLIED,
  flag
});

/*eslint no-undef: 0*/
export const apiURL = `${process.env.API_URL}`;
export const fetchVersion = () => (dispatch) => {
  fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      if (!localStorage.getItem(json.version)) {
        localStorage.setItem(json.version, JSON.stringify({ read: false }));
      }
      if (localStorage.getItem('serverEnv') !== json.env) {
        localStorage.setItem('serverEnv', json.env);
      }
      dispatch(receiveVersion(json));
    });
};

export const fetchToggles = () => (dispatch) => {
  fetch(`${apiURL}/toggles/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      if (!isEmpty(json)) {
        forOwn(json, (value, key) => {
          if (!has(window, key) || window[key] !== value) {
            window[key] = value;
          }
        });
      }
      dispatch(receiveToggles(json));
    });
};

export const fetchMarketZones = () => (dispatch) => {
  fetch(`${apiURL}/marketzones/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveMarketZones(json));
    });
};

export const fetchBanks = () => (dispatch) => {
  fetch(`${apiURL}/banks/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveBanks(json));
    });
};

export const fetchNameFromABN = (abn, isEditCompany=false) => (dispatch) => {
  let url = `${apiURL}/abn/?abn=${abn}`;
  if(isEditCompany)
    url += '&isEdit=true';
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveABNResponse(json));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const fetchGrowerCompanyByABN = abn => (dispatch, getState) => {
  fetch(`${apiURL}/abn/${abn}/local/grower/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveCompanyResponse(json));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const fetchCompanyByABN = abn => (dispatch, getState) => {
  fetch(`${apiURL}/companies?abn=${abn}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveCompanyResponse(json[0]));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const login = (payload, referrerUrl, returnTo, dispatch, navigateTo, errorCallback) => {
  payload['includes'] = ['company__address&platformfeatures', 'type'];

  if(get(payload, 'password'))
    payload['password'] = encrypt_password(payload['password'])

  fetch(`${apiURL}/profiles/login`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      if (JSON.stringify(Object.keys(json)) === JSON.stringify(['token', 'user'])) {
        localStorage.setItem('token', json.token);
        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('current_country', json.user.countryCode);
        json = {...json};
        dispatch(receiveLogin(json));
        pendoInitialize(json?.user);
        if (returnTo != null) {
          window.location = getFreshdeskURL()
          return;
        }
        if (navigateTo) {
          document.location.hash = navigateTo;
        } else if (referrerUrl) {
          document.location.hash = referrerUrl;
        } else {
          document.location.hash = '/';
        }
      } else if(json && json.isPasswordExpired){
        localStorage.setItem('token', json.token);
        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('current_country', json.user.countryCode);
        localStorage.setItem("isPasswordExpired", true);
        setTimeout(() => window.location.hash = '/reset-user-password', 300)
      } else {
        if(errorCallback)
          errorCallback(json);
        dispatch(receiveLogin(json));
      }
    });
};

export const switchToSysAdmin = dispatch => {
  alertifyjs.success('Switching Back!');
  fetch(`${apiURL}/profiles/sys-admin/switch/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({token: localStorage.getItem('beforeSwitchToken')}),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${currentUserToken()}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => {
      if(response.status === 401) {
        localStorage.clear();
        window.location = '/';
      }
      else
        return response.json()
    })
    .then(json => {
      if (JSON.stringify(Object.keys(json)) === JSON.stringify(['token', 'user'])) {
        localStorage.removeItem('beforeSwitchToken')
        localStorage.setItem('token', json.token);
        localStorage.setItem('user', JSON.stringify(json.user));
        dispatch(receiveLogin(json));
        document.location.hash = '/';
      } else {
        dispatch(receiveLogin(json));
      }
    });
};

export const adminEmails = dispatch => {
  APIService.profiles()['admin-emails']().get().then(response => {
    dispatch(receiveAdminEmails(response));
  });
};

export const setPassword = (payload, user, salt, referrerUrl, dispatch) => {
  payload['username'] = user.username;
  fetch(`${apiURL}/profiles/password/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${salt}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => {
      if (response.status === 200) {
        localStorage.setItem('token', salt);
        localStorage.setItem('user', JSON.stringify(user));
        window.location.hash = referrerUrl;
        window.location.reload();
      } else {
        return response.json();
      }
    })
    .then((json) => {
      if (has(json, 'errors')) {
        dispatch(setPasswordErrors(json.errors));
      }
    });
};

function handleErrors(response) {
  if (!response.ok) {
    throw response;
  }
  return response;
}

export const changePassword = payload => (dispatch, getState) => {
  const user = getState().main.user;
  const token = user.token || localStorage.getItem('token');
  fetch(`${apiURL}/profiles/password/`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(handleErrors)
    .then(response => response.json())
    .then((json) => {
      if (JSON.stringify(Object.keys(json)) === JSON.stringify(['token', 'user', 'isPasswordExpired'])) {
        localStorage.setItem('token', json.token);
        localStorage.setItem('user', JSON.stringify(json.user));
        localStorage.setItem('current_country', json.user.countryCode);
        json = {...json, 'isPasswordExpired': undefined};
        dispatch(receiveLogin(json));
        if(localStorage.getItem('isPasswordExpired')){
          localStorage.removeItem('isPasswordExpired');
          document.location.hash = '#/login';
        }
        else
          document.location.hash = '#/profiles/details';
        alertifyjs.success('Password updated successfully!');
      } else {
        if(errorCallback)
          errorCallback(json);
        dispatch(receiveLogin(json));
      }
    })
    .catch((error) => {
      error.json().then(json => dispatch(changePasswordErrors(json)));
    });
};

export const getBHCLocations = (dispatchLoaded = true) => (dispatch, getState) => {
  fetch(`${apiURL}/companies/bhc/locations/`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((items) => {
      if (dispatchLoaded) {
        dispatch(loaded());
      }
      dispatch(receiveBHCLocations(items));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const getTracks = () => (dispatch, getState) => {
  fetch(`${apiURL}/contracts/tracks/`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().main.user.token ? `Token ${getState().main.user.token}` : null,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then(tracks => {
      dispatch(receiveTracks(tracks.tracks));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const validateRego = (key, value, callback) => (dispatch, getState) => {
  fetch(`${apiURL}/regos/${value}/unique`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      var data = {
        'key': key,
        'isAvailable': json.data,
        'rego': value
      };
      dispatch(receiveValidateRegoResponse(data));
      if (typeof callback === 'function') {
        callback(data);
      }
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const getNotifications = () => (dispatch, getState) => {
  if(localStorage.getItem('isPasswordExpired'))
    return
  fetch(`${apiURL}/profiles/notifications/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    .then(response => response.json())
    .then((json) => {
      dispatch(receiveNotifications(json));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('An Error Occurred!');
    });
};

export const sendFeedback = payload => (dispatch, getState) => {
  fetch(`${apiURL}/profiles/feedback/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  })
    // eslint-disable-next-line no-unused-vars
    .then(response => { })
    .then(() => {
      alertifyjs.success('Feedback recorded successfully!');
    })
    .catch(() => {
      alertifyjs.error('An Error Occurred!');
    });
};

// eslint-disable-next-line no-unused-vars
export const getHandlers = (searchStr = null, token = null, callback) => (dispatch, getState) => {
  const cachedImage = localStorage.getItem('cachedImage');
  const imageVersion = getState().main.version;
  if(imageVersion && cachedImage !== imageVersion) {
    localStorage.setItem('cachedImage', imageVersion);
    localStorage.removeItem('handlers');
    localStorage.removeItem('handlersUpdatedAt');
  }

  let handlersUpdatedAt = JSON.parse(localStorage.getItem('handlersUpdatedAt'));
  let queryParams = searchStr ? { search: searchStr, handlers_updated_at: handlersUpdatedAt } : {};

  if (!isEmpty(handlersUpdatedAt))
    queryParams['handlers_updated_at'] = handlersUpdatedAt;
  if (window.location.hash.split('?')[0] === '#/cash-board') {
    APIService
      .farms()
      .appendToUrl('all/')
      .get(token, null, queryParams)
      .then(handlers => {
        let storedHandlers = getSavedHandlers();
        saveHandlers(uniqBy([...handlers, ...storedHandlers], 'id'))
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('An Error Occurred!');
      });
  }
  else {
    APIService
      .contracts()
      .appendToUrl('handlers/')
      .get(token, null, queryParams)
      .then(handlers => {
        let storedHandlers = getSavedHandlers();
        let activeHandlers = uniqBy([...get(handlers, 'handlers'), ...storedHandlers], 'id');
        remove(activeHandlers, obj => includes(get(handlers, 'deletedHandlers'), obj.id));
        saveHandlers(activeHandlers)
        localStorage.setItem('handlersUpdatedAt', JSON.stringify(moment.tz().utc()));
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('An Error Occurred!');
      });
  }
};
