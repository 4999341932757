import React, { Component } from 'react';
import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import CommonTextField from '../common/CommonTextField';
import { forEach, get, isEmpty, mapValues, set, some } from 'lodash';
import { GROWER } from '../../common/constants';
import { forceStopLoader, isLoading } from '../../actions/main';
import GoogleMapPopup from '../common/GoogleMapPopup';
import GooglePlaceAutoComplete from '../google-places/AutoComplete';
import { phoneMobileRegex, required } from '../../common/validators';
import { canEditFarm, getCurrentCountry } from '../../common/utils';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';

class UpdateLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFarm: undefined,
      selectedCompany: undefined,
      defaultLocation: {},
      canEdit: false,
      resetAddress: false,
      fields: {
        address: {
          value: undefined,
          validators: [required()],
          errors: []
        },
        location: {
          value: undefined,
          validators: [required()],
          errors: []
        },
        mobile: {
          value: '',
          validators: [phoneMobileRegex('Please enter a valid phone number')],
          errors: []
        }
      }
    }
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.onAddressFieldBlur = this.onAddressFieldBlur.bind(this);
    this.handleMobileFieldChange = this.handleMobileFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if ((this.props.entityId && this.props.entity === 'farm') || this.props.farmId) {
      const farmId = this.props.farmId || this.props.entityId;
      this.props.dispatch(isLoading())
      APIService.farms(farmId).get().then(item => {
        const newState = {...this.state};
        newState.canEdit = canEditFarm(item);
        newState.selectedFarm = item;
        newState.selectedCompany = get(item, 'company');
        newState.fields.address.value = get(item, 'address');
        newState.fields.mobile.value = get(item, 'mobile');
        const addressObj = this.props.entity === 'farm' ? get(item, 'address') : get(this.props.selectedStorage, 'address');
        newState.fields.location.value = {
          formatted_address: get(addressObj, 'address'),
          lat: parseFloat(parseFloat(get(addressObj, 'latitude')).toFixed(6)),
          lng: parseFloat(parseFloat(get(addressObj, 'longitude')).toFixed(6)),
          suburb: get(addressObj, 'suburb'),
          state: get(addressObj, 'state'),
          postCode: get(addressObj, 'postCode'),
          streetName: get(addressObj, 'streetName'),
        }
        this.setState(newState, () => this.props.dispatch(forceStopLoader()))
      });
    }
  }

  getEntityLabel() {
    if (this.props.entity === 'farm' && this.state.selectedFarm) {
      if (get(this.state.selectedFarm, 'company.typeId') === GROWER)
        return 'Farm'
      return 'Site'
    }
    else if (this.props.entity === 'storage') {
      return 'Storage'
    }
  }

  handleAddressChange(data) {
    const newState = {...this.state};
    var address = {
      name: data.name,
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    newState.fields.address.value = address;
    newState.resetAddress = true;
    if(data.lat && data.lng && data.address) {
      const locationValue = {
        formatted_address: data.address,
        lat: parseFloat(parseFloat(data.lat).toFixed(6)),
        lng: parseFloat(parseFloat(data.lng).toFixed(6)),
        suburb: data.suburb,
        state: data.state,
        postCode: data.postCode,
        streetName: data.streetName,
      };
      newState.fields.location.value = locationValue;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  handleStorageAddressChange(data) {
    const address = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    const newState = {...this.state};
    newState.fields.address.value = address;
    newState.fields.address.errors = [];
    newState.isAddressSelected = true;
    this.setState(newState);
  }

  setAddressFieldErrors(key) {
    if (this.props.entity === 'farm') {
      const newState = {...this.state};
      newState.fields.address.errors = this.getAddressFieldErrors(key);
      this.setState(newState);
    }
  }

  getAddressFieldErrors(key) {
    var errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    forEach(validators, (validator) => {
      if (validator.isInvalid(value.name) || validator.isInvalid(value.address))
        errors.push(validator.message);
      else
        errors = [];
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = {...this.state};
    set(newState.fields, `${key}.errors`, this.getFieldErrors(key));
    this.setState(newState);
  }

  onAddressFieldBlur(event) {
    const address = this.state.fields.address.value;
    const location = this.state.fields.location.value;
    if(address && !address.latitude && (isEmpty(location) || isEmpty(location.formatted_address))) {
      if(this.state.selectedCompany && get(this.state.selectedCompany, 'address.latitude')){
        const locationValue = {
          formatted_address: this.state.selectedCompany.address.address,
          lat: parseFloat(parseFloat(this.state.selectedCompany.address.latitude).toFixed(6)),
          lng: parseFloat(parseFloat(this.state.selectedCompany.address.longitude).toFixed(6)),
          suburb: this.state.selectedCompany.address.suburb,
          state: this.state.selectedCompany.address.state,
          postCode: this.state.selectedCompany.address.postCode,
          streetName: this.state.selectedCompany.address.streetName,
        };
        const newState = {...this.state};
        newState.defaultLocation = locationValue;
        this.setState(newState);
      }
    }
    this.setFieldErrors(event.target.id);
  }

  handleLocationChange(data) {
    const location = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    const address = this.state.fields.address.value;
    const newState = {...this.state};
    newState.fields.location.value = location;
    newState.fields.location.errors = [];

    if(data.lat && (isEmpty(address) || isEmpty(address.name))) {
      const addressValue = {
        name: data.formatted_address,
        address: data.formatted_address,
        latitude: data.lat,
        longitude: data.lng,
        suburb: data.suburb,
        state: data.state,
        postCode: data.postCode,
        streetName: data.streetName,
      };
      newState.fields.address.value = addressValue;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  getFieldErrors(key) {
    const errors = [];
    if(!isEmpty(key)) {
      const value = get(this.state.fields, key).value;
      const validators = get(this.state.fields, key).validators || [];

      forEach(validators, (validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }

    return errors;
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    forEach(newState.fields, (value, key) => {
      if (key !== 'address'){
        newState.fields[key].errors = this.getFieldErrors(key);
      }
    });

    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  getIsFormInvalid() {
    return some(this.state.fields, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  }

  handleSubmit() {
    this.setAllFieldsErrors();
    let submitData = mapValues(this.state.fields, (field) => {
      return field.value;
    });
    if (!this.getIsFormInvalid()) {
      if (this.props.entity === 'storage') {
        submitData.address = {
          latitude: submitData.location.lat,
          longitude: submitData.location.lng,
          suburb: submitData.location.suburb,
          postCode: submitData.location.postCode,
          streetName: submitData.location.streetName,
        }
      }
      submitData.address.latitude = submitData.location.lat;
      submitData.address.longitude = submitData.location.lng;
      submitData.address.suburb = submitData.location.suburb;
      submitData.address.postCode = submitData.location.postCode;
      submitData.address.streetName = submitData.location.streetName;

      delete submitData.location;
      delete submitData.address.entity;
      this.props.dispatch(isLoading('UpdateLocationLoader'))
      const addressId = this.props.entity === 'farm' ? this.state.selectedFarm.addressId : get(this.props.selectedStorage, 'address.id')
      APIService.location(addressId)
        .put(submitData)
        .then(() => {
          this.props.dispatch(forceStopLoader())
          if (this.props.updateEntities)
            this.props.updateEntities(typeof this.state.fields.address.value == 'object' ? get(this.state.fields.address.value, 'address') : this.state.fields.address.value, this.state.fields.mobile.value)
          this.props.onCloseDrawer();
        });
    }
  }

  handleMobileFieldChange(event) {
    const newState = {...this.state};
    newState.fields.mobile.value = event.target.value;
    this.setState(newState, () => this.setFieldErrors(event.target.id))
  }

  toggleResetState = () => this.setState({resetAddress: !this.state.resetAddress})

  render() {
    const entityLabel = this.getEntityLabel();
    const isFarm = this.props.entity === 'farm';
    const isStorage = this.props.entity === 'storage';
    const { selectedFarm, selectedCompany, canEdit } = this.state;
    const country = getCurrentCountry(selectedCompany?.countryCode)
    return (
      <div>
        {selectedFarm &&
          <Dialog
            open
            onClose={this.props.onCloseDrawer}
            fullWidth
            maxWidth="md"
            scroll="paper"
          >
           <DialogTitleWithCloseIcon onClose={this.props.onCloseDrawer}><div style={{marginTop: '-4px'}}>{`Edit ${entityLabel}`}</div></DialogTitleWithCloseIcon>
           <DialogContent>
           {isFarm && selectedFarm &&
              <>
                <div>
                  <CommonTextField
                    id="name"
                    label={`${entityLabel} Name`}
                    value={get(selectedFarm, 'name')}
                    disabled
                  />
                </div>
                <div style={{paddingTop: '15px'}}>
                  <GooglePlaceAutoComplete
                    id="address"
                    hintText="Please select an address from suggestions"
                    floatingLabelText="Address"
                    fullWidth
                    value={get(this.state.fields.address.value, 'address')}
                    name={get(this.state.fields.address.value, 'name')}
                    errorText= {this.state.fields.address.errors[0]}
                    onChange={this.handleAddressChange}
                    errorStyle={{textAlign: "left"}}
                    onBlur={this.onAddressFieldBlur}
                    countryCode={country.countryCode}
                    disabled={!canEdit}
                    resetState={this.state.resetAddress}
                    toggleResetState={this.toggleResetState}
                  />
                </div>
                <div style={{paddingTop: '15px'}}>
                  <CommonTextField
                    id="mobile"
                    label="Phone/Mobile (Optional)"
                    placeholder="Please enter a 10 digit number starting with 0"
                    value={this.state.fields.mobile.value}
                    disabled={!canEdit}
                    onChange={this.handleMobileFieldChange}
                    helperText={this.state.fields.mobile.errors[0]}
                    type="number"
                    onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,10);
                    }}
                  />
                </div>
              </>
            }
            {isStorage &&
            <div>
              <CommonTextField
                id="name"
                label={`${entityLabel} Name`}
                value={get(this.props.selectedStorage, 'name')}
                disabled
              />
            </div>
            }
            <div style={{paddingTop: '15px'}}>
              <GoogleMapPopup
                id="location"
                location={this.state.fields.location.value}
                defaultLocation={this.state.defaultLocation}
                errors={this.state.fields.location.errors}
                onDone={this.handleLocationChange}
                updateLocationFromSearchBox={this.handleAddressChange}
                useCurrentGeoLocation={false}
                openMapPopup
                noDialog
                canEdit={canEdit}
              />
            </div>
           </DialogContent>
           {canEdit &&
            <DialogActions>
              <Button type='button' onClick={this.props.onCloseDrawer} variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={this.handleSubmit} color='primary' variant='contained'>
                Save
              </Button>
            </DialogActions>
            }
          </Dialog>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  isLoading: () => dispatch(isLoading()),
  forceStopLoader: () => dispatch(forceStopLoader())
});

export default connect(mapDispatchToProps)(UpdateLocation);
