import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { setClickedOption, raiseVoidRequest, shouldFetchContract, getSelectedContract, receiveContract, markPaid, shouldOpenReviewAmendmentDrawer, closeOutContract, canCloseOutForContract, canContractBeClosedOut, showHierarchyForOrderId } from '../../actions/companies/contracts';
import SideDrawer from '../common/SideDrawer';
import CreateTitleTransfer from '../../containers/CreateTitleTransfer';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import ContractCustomEmail from '../common/ContractCustomEmail';
import { isLoading } from '../../actions/main';
import AmendContract from './contractDetails/AmendContract';
import { MarkPaidDialog } from '../invoices/MarkPaidDialog';
import HierarchySideDrawer from '../common/HierarchySideDrawer';
import find from 'lodash/find';
import { vendorDecBlockPermissionPopup, requestVendorDecBlockPermissionPopup } from '../../common/utils';
import { raiseVendorDecRequest } from '../../actions/companies/freights';
import isEmpty from 'lodash/isEmpty';
import MarkInvoiceOutSide from './MarkInvoiceOutSide';
import APIService from '../../services/APIService';
import CloseOutDialog from './contractDetails/CloseOutDialog';

let ContractActions = props => {

  const [options, setOptions] = useState({
    showHierarchy: false,
    isCOGFormOpen: false,
    isTitleTransferFormOpen: false,
    isVoidDialogOpen: false,
    amendDrawOpen: false,
    markPaidDialogOpen: false,
    showCurrentContract: true,
    showCustomEmailDialog: false,
    showInvoiceOutside: false,
    disableOption: false,
    showHierarchyForOrder: false,
    openCloseOutDialog: false
  })

  const [subject, setSubject] = useState("");
  const [vendorDecDetails,setVendorDecDetails] = useState(false);
  const [invoicable, setInvoicable] = useState();
  const [closeOutReason, setCloseOutReason] = useState("");
  const [isCloseOut, setIsCloseOut] = useState(false);
  const [currentOrderId,  setCurrentOrderId] = useState("");

  const canOpenReviewAmendmentDrawer = useSelector( state => {
    return  state.companies.contracts.shouldOpenReviewAmendmentDrawer;
  }
  );

  const [requestReason, setRequestReason] = useState("");


  let getSelectedContractIdentifier = () => {
    return get(find(props.items, {id: props.selectedContractId}), 'referenceNumber', false) ||
           get(props.selectedContract, 'referenceNumber');
  };

  let dispatch = useDispatch();

  let closeCustomEmailDialog = (communicationData, justClose) => {
    setOptions({
      ...options,
      showCustomEmailDialog: false,
      disableOption: false
    });
    setVendorDecDetails(false);
    if(!isCloseOut){
      setSubject("");
    }
    if (!justClose) {
      let data = {};
      if (communicationData) {
        data['communication'] = communicationData;
      }
      if (isCloseOut && closeOutReason){
        setSubject(`Close Out Contract #${getSelectedContractIdentifier()}`);
        const closeoutData = { closeOutReason: closeOutReason, ...data };
        dispatch(closeOutContract(get(props, 'selectedContract.id'), closeoutData));
      }
      else if (vendorDecDetails) {
        const requestData = { entity: 'contract', ...data };
        dispatch(raiseVendorDecRequest(props.selectedContractId, requestData));
      }
      else {
        const voidData = { requestReason: requestReason, ...data };
        const isDuplicated = get(props, 'canRaiseVoidAndDuplicateRequest')
        dispatch(raiseVoidRequest(props.selectedContractId, voidData, true, true, isDuplicated));
      }
    }
    else if (justClose && isCloseOut){
      setOptions({
        ...options,
        showCustomEmailDialog: false,
      });
    }
  };

  let shouldOpenCOGForm = () =>{
    const {selectedContractId, selectedContract, canCreateCOGForContractId} = props;
    return selectedContractId  && selectedContract && selectedContractId === canCreateCOGForContractId;
  };

  let setOptionKey = (key, value) =>{
    setOptions({
      ...options,
      [key]: value
    });
    dispatch(setClickedOption(null));
  };

  let checkForOptions = async () =>{
    const {selectedContractId, selectedContract} = props;
    const clickedOptionKey = get(props.clickedOption, 'key');
    if (clickedOptionKey === 'call_on_grain_orders_add' && shouldOpenCOGForm()) {
      document.location=`#/freights/grain/new?commodityContractId=${selectedContractId}`;
      setOptionKey("isCOGFormOpen", true);
    }
    else if(selectedContract && selectedContractId &&
            (selectedContractId === get(props, 'canCreateTitleTransferForContractId')) &&
            clickedOptionKey === 'title_transfers_add'){
      setOptionKey("isTitleTransferFormOpen", true);
    }
    else if (get(props, 'canRaiseVoidRequest') && clickedOptionKey === 'void'){
      setOptionKey("isVoidDialogOpen", true);
    }
    else if (get(props, 'canRaiseVoidAndDuplicateRequest') && clickedOptionKey === 'void_and_duplicate'){
      setOptionKey("isVoidDialogOpen", true);
    }
    else if (selectedContractId && canOpenReviewAmendmentDrawer && clickedOptionKey === 'review_amendment'){
      setOptionKey("amendDrawOpen", true);
    }
    else if(selectedContractId && props.canMarkAsPaid && clickedOptionKey === 'mark_as_paid'){
      setOptionKey("markPaidDialogOpen", true);
    }
    else if(selectedContractId && clickedOptionKey === 'show_hierarchy'){
      setOptionKey("showHierarchy", true);
    }
    else if (clickedOptionKey === 'vendor_dec_add_contract' && selectedContractId && selectedContract){
      dispatch(setClickedOption(null));
      if (isEmpty(selectedContract.cannotCreateVendorDecReasons)) {
        document.location=`#/vendor-decs/grain/new?entity=contract&entityId=${selectedContractId}`;
      }
      else{
        vendorDecBlockPermissionPopup(
          selectedContract.cannotCreateVendorDecReasons, 'Contract'
        );
      }
    }
    else if (clickedOptionKey === 'vendor_dec_view_contract' && selectedContractId){
      dispatch(setClickedOption(null));
      document.location=`#/contracts/${selectedContractId}/vendor-decs`;
    }
    else if (clickedOptionKey === 'vendor_dec_request_contract' && selectedContract && selectedContractId){
      dispatch(setClickedOption(null));
      if (isEmpty(selectedContract.cannotRequestVendorDecReasons)) {
        setVendorDecDetails(true);
        setSubject(`[Request] Vendor Declaration for Contract #${getSelectedContractIdentifier()}`);
        setOptions({...options, disableOption: true, showCustomEmailDialog:true});
      }
      else{
        requestVendorDecBlockPermissionPopup(selectedContract.cannotRequestVendorDecReasons, 'Contract');
      }
    }
    else if (clickedOptionKey === 'invoiced_outside' && selectedContractId){
      dispatch(setClickedOption(null));
      let response = await APIService.contracts(selectedContractId).appendToUrl('invoicable/').get(props.token);
      let result = response.result;
      setInvoicable(result);
      setOptionKey("showInvoiceOutside", true);
    }
    else if (clickedOptionKey === 'close_out_contract' && selectedContractId){
      dispatch(setClickedOption(null));
      setCloseOutReason("");
      dispatch(canContractBeClosedOut(selectedContractId));
      setSubject(`Close Out Contract #${getSelectedContractIdentifier()}`);
      setIsCloseOut(true);
    }
  };

  let closeCloseOutDialog = () => {
    dispatch(canCloseOutForContract(false));
    setCloseOutReason("");
  };

  let setMarkPaid = (data) => {
    const { selectedContractId } = props;
    if (selectedContractId)
      dispatch(markPaid(selectedContractId, data, true));
  };

  let closeAmendSideBar = () =>{
    setOptionKey("amendDrawOpen", false);
    dispatch(shouldOpenReviewAmendmentDrawer(false));
  };

  useEffect(()=> {
    if(props.clickedOption){
      checkForOptions();
    }
    if (get(props, 'showHierarchyForOrderId')){
      setCurrentOrderId(get(props, 'showHierarchyForOrderId'));
    }
    else {
      setCurrentOrderId("");
    }
    const _shouldFetchContract = props.shouldFetchContract;
    if (_shouldFetchContract && props.selectedContractId && props.selectedContractId !== get(props, 'selectedContract.id')) {
      dispatch(isLoading('contractDetail'));
      dispatch(
        getSelectedContract(props.selectedContractId, receiveContract, false, false, false, true, false)
      );
      dispatch(shouldFetchContract(false));
    }
  }, [props, canOpenReviewAmendmentDrawer]);

  return (
    <span>
      {
        options.showCustomEmailDialog &&
        <ContractCustomEmail
          contract={props.selectedContract}
          showCustomEmailDialog={options.showCustomEmailDialog}
          closeCustomEmailDialog ={closeCustomEmailDialog}
          disable={options.disableOption}
          subject={subject}
          vendorDecDetails={vendorDecDetails}
          hideAcceptance={closeOutReason}
          title={isCloseOut ? 'Close Out Contract' : ''}
        />
      }
      {
        props.selectedContract && options.isTitleTransferFormOpen &&
        <SideDrawer
          isOpen={ options.isTitleTransferFormOpen }
          title={`Add Title Transfer: ${getSelectedContractIdentifier()}`}
          onClose={() => setOptionKey("isTitleTransferFormOpen", false)} size='xlarge'>

          <CreateTitleTransfer
            closeDrawer={() => setOptionKey("isTitleTransferFormOpen", false)}
            contract={props.selectedContract}/>

        </SideDrawer>
      }

      {
        getSelectedContractIdentifier() && options.isVoidDialogOpen &&
        <RejectionReasonDialog
          open={options.isVoidDialogOpen}
          onClose={() => {
              setOptionKey("isVoidDialogOpen", false);
              setRequestReason("");
          }}
          title={`Void Contract: ${getSelectedContractIdentifier()}`}
          value={requestReason}
          onChange={(event) => setRequestReason(event.target.value)}
          onCancel={() => {
              setOptionKey("isVoidDialogOpen", false);
              setRequestReason("");
          }}
          onReject={() => {
              setVendorDecDetails(false);
              setOptionKey("showCustomEmailDialog", true);
              dispatch(shouldFetchContract(true));
          }}
          placeholder="Enter you reason for void request"
          submitText="Submit"
          required
        />
      }

      {
        props.selectedContract && options.amendDrawOpen &&
        <SideDrawer
          isOpen={options.amendDrawOpen}
          title={`Contract Amend Request (${getSelectedContractIdentifier()})`}
          onClose={closeAmendSideBar}
          size="big"
          paperStyle={{textAlign: 'left'}}
          >
          <AmendContract
            useCurrent={true}
            closeSidebar={closeAmendSideBar}
            contract={props.selectedContract}
            showCurrentContract={options.showCurrentContract}
            amendDetails={props.selectedContract.amendedDetails}
            amendTimeStamp={props.selectedContract.amendTimeStamp}
            isFromAuditHistory={false}
          />
        </SideDrawer>
      }

      {
        options.showInvoiceOutside && props.selectedContractId &&
        <SideDrawer
          isOpen={options.showInvoiceOutside}
          title={`Invoiced Outside `}
          onClose={() => setOptionKey("showInvoiceOutside", false)}
          paperStyle={{textAlign: 'left'}}
          >
          <MarkInvoiceOutSide
            handleCancel={() => setOptionKey("showInvoiceOutside", false)}
            identifier={getSelectedContractIdentifier()}
            defaultOption={invoicable}
            {...props}
          />
        </SideDrawer>
      }

      {
        options.markPaidDialogOpen &&
        <div className="status-actions-wrap">
          <MarkPaidDialog
            open={options.markPaidDialogOpen}
            onClose={() => setOptionKey("markPaidDialogOpen", false)}
            onCancel={() => setOptionKey("markPaidDialogOpen", false)}
            showWarning={true}
            onSubmit={setMarkPaid}/>
        </div>
      }

      {
        options.showHierarchy &&
        <HierarchySideDrawer
          isOpen={options.showHierarchy}
          onClose={() => setOptionKey("showHierarchy", false)}
          title="Contract Hierarchy"
          contractId={props.selectedContractId}
        />
      }

      {
        get(props, 'canCloseOutForContract') && getSelectedContractIdentifier() &&
        <RejectionReasonDialog
          open={props.canCloseOutForContract}
          onClose={closeCloseOutDialog}
          title={`Close Out Contract: ${getSelectedContractIdentifier()}`}
          value={closeOutReason}
          onChange={(event) => setCloseOutReason(event.target.value)}
          onCancel={closeCloseOutDialog}
          onReject={() => {
            const data = { closeOutReason: closeOutReason, ...data }
            setOptions({
              ...options,
              showCustomEmailDialog: true,
            });
            dispatch(canCloseOutForContract(false));
          }}
          placeholder="Enter your reason for close out"
          submitText="Submit"
          required
          dontShowIcon
        />
      }
      {
        get(props, 'showHierarchyForOrderId') && get(props, 'showHierarchyForOrderId') == currentOrderId &&
          <HierarchySideDrawer
            isOpen={props.showHierarchyForOrderId}
            onClose={() => {
              setCurrentOrderId(props.showHierarchyForOrderId);
              dispatch(showHierarchyForOrderId(""));
            }}
            title="Order Hierarchy"
            orderId={props.showHierarchyForOrderId}
            style={{ position: 'relative', zIndex: 50000 }}
          />
      }
      {
          get(props, 'openCloseOutDialog') &&
          <CloseOutDialog
            open={get(props, 'openCloseOutDialog')}
            response={get(props, 'openCloseOutDialog')}
          />
        }

    </span>
  );
};

export default ContractActions;
