import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import FreightOrders from "./FreightOrders";
import CallOnGrainOrders from "./CallOnGrainOrders";
import RequestOrders from './RequestOrders';
import PackOrders from './PackOrders';
import { get, includes } from 'lodash';

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.FREIGHT_ORDERS_URL = '/orders/freights';
    this.GRAIN_ORDERS_URL = '/orders/grain';
    this.REQUEST_ORDERS_URL = '/orders/requests';
    this.PACK_ORDERS_URL = '/orders/packing';

    this.state = {
      value: '#' + this.props.location.pathname,
    };
    this.contractId = get(this.props.match, 'params.contract_id');
    this.parentURL = this.getParentURL();
    this.setValueBasedOnPath = this.setValueBasedOnPath.bind(this);
  }

  getParentURL() {
    let url = this.props.location.pathname;
    if(includes(this.props.location.pathname, this.FREIGHT_ORDERS_URL))
      return url.split(this.FREIGHT_ORDERS_URL)[0];
    if(includes(this.props.location.pathname, this.GRAIN_ORDERS_URL))
      return url.split(this.GRAIN_ORDERS_URL)[0];
    if(includes(this.props.location.pathname, this.REQUEST_ORDERS_URL))
      return url.split(this.REQUEST_ORDERS_URL)[0];
    if(includes(this.props.location.pathname, this.PACK_ORDERS_URL))
      return url.split(this.PACK_ORDERS_URL)[0];
  }

  componentDidUpdate() {
    setTimeout(this.setValueBasedOnPath, 500);
  }

  setValueBasedOnPath() {
    const currentUrl = `#${this.props.location.pathname}`;
    if(this.state.value !== currentUrl)
      this.setState({value: currentUrl});
  }

  getURL(pathname) {
    const parentURL = this.getParentURL();

    return '#' + parentURL + pathname;

  }

  tabChanges = (event, value) => this.setState({ value });

  render() {
    const { value } = this.state;
    const freightOrdersURL = this.getURL(this.FREIGHT_ORDERS_URL);
    const grainOrdersURL = this.getURL(this.GRAIN_ORDERS_URL);
    const requestOrdersURL = this.getURL(this.REQUEST_ORDERS_URL);
    const packOrdersURL = this.getURL(this.PACK_ORDERS_URL);
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={freightOrdersURL} href={freightOrdersURL} label="Freight Orders" className={value !== freightOrdersURL ? 'unselected-subtab' : ''} />
          <Tab value={grainOrdersURL} href={grainOrdersURL} label="Call on Grain Orders" className={value !== grainOrdersURL ? 'unselected-subtab' : ''} />
          { !this.contractId && <Tab value={requestOrdersURL} href={requestOrdersURL} label="Request Orders" className={value !== requestOrdersURL ? 'unselected-subtab' : ''} /> }
          { <Tab value={packOrdersURL} href={packOrdersURL} label="Pack Orders" className={value !== packOrdersURL ? 'unselected-subtab' : ''} /> }
        </Tabs>
        {
          value === freightOrdersURL && <FreightOrders {...this.props} />
        }
        {
          value === grainOrdersURL && <CallOnGrainOrders {...this.props} />
        }
        {
          !this.contractId && value === requestOrdersURL && <RequestOrders {...this.props} />
        }
        {
          value === packOrdersURL && <PackOrders {...this.props}/>
        }
      </div>
    );
  }
}

export default Orders;
