import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import {  getFreshdeskURL, isStaff, isSystemCompany, currentUser, validateMovementUnitForLoadEdit, getCountryMovementDisplayUnit, getCountryConfig } from '../../common/utils';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import {
  setClickedOption, getSelectedFreight, raiseVoidRequest, confirmationResponse,
  shouldFetchFreightMovement, resetIsFetchingSelectedFreight,
  canMarkCompleteForSelectedMovementId, deliverMovement, completeMovement,
  assignParentToMovement, raiseVendorDecRequest, raiseIncludeInDocketEntryRequest, canAddLoads
} from '../../actions/companies/freights';
import RejectLoadDialog from '../rejections/RejectLoadDialog';
import MovementCustomEmail from '../common/MovementCustomEmail';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import includes from 'lodash/includes';
import CommonButton from '../common/CommonButton';
import { isLoading } from '../../actions/main';
import SideDrawer from '../common/SideDrawer';
import EditMovementReview from './MovementDetails/EditMovementReview';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import InloadOutloadConstDrawer from './MovementDetails/InloadOutloadConstDrawer';
import { OUTLOAD, INLOAD, ASSIGN_CONTRACT_FO_SUCCESS, ASSIGN_CONTRACT_PO_SUCCESS } from './MovementDetails/Constants';
import AllocateMovements from './MovementDetails/AllocateMovements';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { vendorDecBlockPermissionPopup, requestVendorDecBlockPermissionPopup } from '../../common/utils';
import AllocatePackMovements from './MovementDetails/AllocatePackMovements';
import { isCustomerGradeOrTonnageMissing } from './utils';
import ClarificationDrawer from './MovementDetails/ClarificationDrawer';


let MovementActions = props => {
    const [options, setOptions] = useState({
        isVoidDialogOpen: false,
        rejectLoadDialogOpen: false,
        showCustomEmailDialog: false,
        isOutloadConstSideDrawerOpened: false,
        isInloadConstSideDrawerOpened: false,
        completeDialogOpen: false,
        amendDialogOpen: false,
        allocate: false,
        disableOption: false,
        ignoreDocketDataDialogOpen: false,
        editDocketClarification: false
    });
    const [requestReason, setRequestReason] = useState("");
    const [requestVendorDecType, setRequestVendorDecType] = useState("");
    const [subject, setSubject] = useState("");
    const [vendorDecDetails,setVendorDecDetails] = useState(false);

    let dispatch = useDispatch();

    let getClickedMovement = () => {
        const {items, selectedFreightMovementId} = props;
        return find(items, {id: selectedFreightMovementId});
    };

    let canMarkToDelivered = () => {
        const movement = getClickedMovement() || props.selectedFreightMovement;
        if(movement)
          return includes(['completed', 'Completed', 'manual_contract_complete_balanced', 'Manually Completed'], (movement.status || movement.displayStatus));
    };

    let handleDeliverDialogOk = (event)  => {
        event.preventDefault();
        setOptionKey("completeDialogOpen", false);
        const { selectedFreightMovementId } = props;
        dispatch(deliverMovement(selectedFreightMovementId));
        dispatch(canMarkCompleteForSelectedMovementId(false));
    };

    let handleMovement = (data, id, isPackMovement) => {
        const message = isPackMovement ? ASSIGN_CONTRACT_PO_SUCCESS : ASSIGN_CONTRACT_FO_SUCCESS
        if (!isPackMovement && data)
          data.feature = 'Movement Details';
        dispatch(assignParentToMovement(id, data, message, isPackMovement));
    };

    const handleError = () => {
      alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to Assign this Pack Movement because:</p><ul>Only Pack Site Employees can assign this Pack movement.</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`
        );
      setOptionKey("allocate", false)
    };

    let handleCompleteDialogOk = (event) => {
        event.preventDefault();
        const { selectedFreightMovementId } = props;
        dispatch(isLoading('canMarkComplete'));
        dispatch(completeMovement(selectedFreightMovementId, true, 'Movement Details'));
        setOptionKey("completeDialogOpen", false);
        dispatch(canMarkCompleteForSelectedMovementId(false));
    };

    let closeEmailDialog = (communicationData, justClose) => {
      setSubject("");
      setOptions({
        ...options,
        showCustomEmailDialog: false,
        disableOption: false
      });
      setVendorDecDetails(false);
      if (!justClose) {
        let data = {};
        if (communicationData) {
          data['communication'] = communicationData;
        }
        if (options.showCustomEmailDialog && vendorDecDetails) {
          const requestData = { entity: 'freightcontract', ...data };
          dispatch(raiseVendorDecRequest(props.selectedFreightMovementId, requestData, requestVendorDecType ));
        }
        else {
          const voidData = { requestReason: requestReason, ...data, feature: 'Movement Details' };
          const isDuplicated = get(props, 'canVoidAndDuplicateFreightMovement')
          dispatch(raiseVoidRequest(props.selectedFreightMovementId, voidData, isDuplicated));
          setRequestReason("");
          setOptions({
            ...options,
            isVoidDialogOpen: false,
            showCustomEmailDialog: false
          });
        }
      };
    };

    let includeInDocketEntry = () =>{
      const data = {reason: requestReason , companyId: props.currentUser.companyId, pausedDocketEntry: false };
      dispatch(raiseIncludeInDocketEntryRequest(props.selectedFreightMovementId, data));
    };

    let setOptionKey = (key, value) =>{
        setOptions({
            ...options,
            [key]: value
        });
        dispatch(setClickedOption(null));
    };

    let closeVoidDialog = () =>{
        setOptionKey("isVoidDialogOpen", false);
        setRequestReason("");
    };

    let canAddEditLoad = key => {
      const { selectedFreightMovement } = props;
      const isLoadRejected = get(selectedFreightMovement, 'status') === 'load_rejected';
      return includes(
        ['confirmed', 'in_progress', 'delivered', 'manual_contract_complete_balanced', 'completed'],
        get(selectedFreightMovement, 'status')
      ) || (key === 'add_edit_outload' && isLoadRejected && get(selectedFreightMovement, 'freightDelivery.load')) || isSystemCompany();
    }

    let checkForOptions = () =>{
        const {
            selectedFreightMovementId, rejectLoadForMovementId, voidableSelectedFreightMovementId,
            canMarkCompleteForSelectedMovementId, clickedOption, canAssignForSelectedMovementId,
            selectedFreightMovement, selectedPackMovement
        } = props;
        const clickedOptionKey = get(clickedOption, 'key');
        const isMovementDeliveredDetailsVisible = includes(['confirmed', 'in_progress', 'delivered', 'completed', 'manual_contract_complete_balanced'], get(selectedFreightMovement, 'status'));
      const entity = props.isPackMovement ? 'Pack Movement' : 'Freight Movement';
      if(clickedOptionKey === 'recalculate_distance') {
        if(!selectedFreightMovement?.id)
          return
        let message = alertifyjs.warning('Updating distance, please wait for few seconds.', 10)
        APIService.freights().contracts(selectedFreightMovement?.id).appendToUrl('distance/').put().then(response => {
          message.dismiss()
          if(response?.estimatedDistance)
            alertifyjs.success('Successfully updated distance. Reloading...', 2, () => window.location.reload())
          else
            alertifyjs.error('Unable to update, please contact AgriChain Support.', 2)
        })
      }
        else if (
            selectedFreightMovementId && rejectLoadForMovementId &&
            rejectLoadForMovementId === selectedFreightMovementId && clickedOptionKey === 'load_reject'
          ) {
            setOptionKey("rejectLoadDialogOpen", true);
        }
        else if (
            selectedFreightMovementId &&
            (selectedFreightMovementId === voidableSelectedFreightMovementId) &&
            clickedOptionKey === 'void'
          ) {
            setOptionKey("isVoidDialogOpen", true);
        }
        else if (
          selectedFreightMovementId &&
          (selectedFreightMovementId === voidableSelectedFreightMovementId) &&
          clickedOptionKey === 'void_and_duplicate'
        ) {
          setOptionKey("isVoidDialogOpen", true);
       }
        else if (
            selectedFreightMovementId &&
            clickedOptionKey === 'add_edit_outload'
        ) {
          if(!validateMovementUnitForLoadEdit(getClickedMovement())) {
            dispatch(setClickedOption(null));
            return
          }

          if (isMovementDeliveredDetailsVisible && canAddEditLoad(clickedOptionKey))
            setOptionKey("isOutloadConstSideDrawerOpened", true)
          else
            dispatch(canAddLoads(event, selectedFreightMovementId, () => setOptionKey("isOutloadConstSideDrawerOpened", true)));
        }
      else if (
        selectedFreightMovementId &&
          clickedOptionKey === 'add_edit_inload'
      ) {
        if(!validateMovementUnitForLoadEdit(getClickedMovement())) {
          dispatch(setClickedOption(null));
          return
        }
          if (isMovementDeliveredDetailsVisible && canAddEditLoad(clickedOptionKey))
            setOptionKey("isInloadConstSideDrawerOpened", true)
          else
            dispatch(canAddLoads(event, selectedFreightMovementId, () => setOptionKey("isInloadConstSideDrawerOpened", true)));
        }
        else if (
          selectedFreightMovementId &&
          clickedOptionKey === 'seek_clarification'
        ) {
          setOptionKey("isClarificationDrawerOpened", true)
        }
        else if (
            selectedFreightMovementId && canMarkCompleteForSelectedMovementId
        ){
            setOptionKey("completeDialogOpen", true);
        }
        else if(
          clickedOptionKey === 'review_amendment'
        ){
            setOptionKey("amendDialogOpen", true);
        }
        else if (clickedOptionKey === 'assign_to'){
          if (canAssignForSelectedMovementId ||  (props.isPackMovement && (props.currentUser.companyId === get(selectedPackMovement, 'providerId') || props.currentUser.isStaff))) {
            const displayUnit = getCountryMovementDisplayUnit()
            const isNotInMovementDisplayUnit = (selectedFreightMovement?.requestedUnit || props.currentUser?.unit) !== displayUnit
            if(getCountryConfig()?.showConversions && isNotInMovementDisplayUnit)
              alertifyjs.alert('Movement Unit', `For assigning movement, please switch to ${displayUnit} unit.`, () => {})
            else
              setOptionKey("allocate", true);
          }
          if (props.isPackMovement && !(props.currentUser.companyId === get(selectedPackMovement, 'providerId') || props.currentUser.isStaff))
            handleError();
        }
        else if (clickedOptionKey === 'grain_commodity' && selectedFreightMovementId && selectedFreightMovement){
          dispatch(setClickedOption(null));
          isCustomerGradeOrTonnageMissing(selectedFreightMovement, (isMissing) => {
            if (!isMissing){
              document.location = `#/vendor-decs/truck/new?entity=freightcontract&entityId=${selectedFreightMovementId}`;
              if (isEmpty(selectedFreightMovement.cannotCreateVendorDecReasons))
                document.location=`#/vendor-decs/grain/new?entity=freightcontract&entityId=${selectedFreightMovementId}`;
              else
                vendorDecBlockPermissionPopup(selectedFreightMovement.cannotCreateVendorDecReasons, 'Freight Movement');
            }
          });
        }
        else if (clickedOptionKey === 'truck_cleanliness' && selectedFreightMovementId && selectedFreightMovement){
          dispatch(setClickedOption(null));
          isCustomerGradeOrTonnageMissing(selectedFreightMovement, (isMissing) => {
            if (!isMissing)
              document.location = `#/vendor-decs/truck/new?entity=freightcontract&entityId=${selectedFreightMovementId}`;
          });
        }
        else if (clickedOptionKey === 'vendor_dec_view' && selectedFreightMovementId){
          dispatch(setClickedOption(null));
          document.location=`#/freights/movements/${selectedFreightMovementId}/vendor-decs`;
        }
        else if (get(clickedOption, 'key') === 'grain_commodity_request' && selectedFreightMovementId && selectedFreightMovement){
          dispatch(setClickedOption(null));
          if (isEmpty(selectedFreightMovement.cannotRequestVendorDecReasons)) {
            const identifier = get(selectedFreightMovement, 'identifier', '').toUpperCase();
            setVendorDecDetails(true);
            setSubject(`[Request] Vendor Declaration for ${entity} #${identifier}`);
            setOptions({...options, showCustomEmailDialog: true, disableOption: true});
            setRequestVendorDecType('grain_commodity');
          }
          else{
            requestVendorDecBlockPermissionPopup(selectedFreightMovement.cannotRequestVendorDecReasons, entity);
          }
        }
        else if (get(clickedOption, 'key') === 'truck_cleanliness_request' && selectedFreightMovementId && selectedFreightMovement){
          dispatch(setClickedOption(null));
          const identifier = get(selectedFreightMovement, 'identifier', '').toUpperCase();
          setVendorDecDetails(true);
          setSubject(`[Request] Vendor Declaration for ${entity} #${identifier}`);
          setOptions({...options, showCustomEmailDialog: true, disableOption: true});
          setRequestVendorDecType('truck_cleanliness');
        }
        else if (clickedOptionKey === 'ignore_docket_data') {
          setOptionKey('ignoreDocketDataDialogOpen', true);
        }
        else if (clickedOptionKey === 'edit_docket_clarification') {
          setOptionKey('editDocketClarification', true);
        }
        else if (clickedOptionKey === 'include_in_docket_entry') {
          includeInDocketEntry();
        }
        else if (clickedOptionKey === 'create_container_movement') {
          const movement = getClickedMovement() || props.selectedFreightMovement;
          if (includes(['completed', 'Completed', 'delivered', 'Delivered'], (movement.status || movement.displayStatus))){
            if ((!isEmpty(get(movement, 'containerMovement'))) || get(movement, 'linkedContainerMovement'))
              alertifyjs.error("This Container has been moved from the pack site and is not available.", 3)
            else {
              alertifyjs.confirm(
                "Are you sure?",
                "This action will create a Container Movement. Do you want to proceed?",
                () => {
                  APIService.farms(movement?.packSiteId || get(movement, 'freightDelivery.load.0.farmId')).storages(movement?.containerId).appendToUrl('container-movement/').post({'createdById': currentUser()?.id}).then(response => {
                    if(response?.id)
                      alertifyjs.success('Container has been moved. Reloading...', 2, () => window.location.reload())
                    else
                      alertifyjs.error('Unable to move the container. Please create the Container Movement from Movement Creation Form.', 0)
                  })

                },
                () => {}
              )
            }
          }
          else
            alertifyjs.error("Only Completed or Delivered Pack Movement can be used to create container movement", 3)
          dispatch(setClickedOption(null));
        }
    };

    useEffect(()=> {
        if(props.clickedOption){
            checkForOptions();
        }
        if (props.shouldFetchMovement && props.selectedFreightMovementId &&
           props.selectedFreightMovementId !== get(props, 'selectedFreightMovement.id') ) {
          dispatch(isLoading("movementDetail"));
          dispatch(
            getSelectedFreight(props.selectedFreightMovementId, confirmationResponse, false, false, false, false, true)
          );
          dispatch(shouldFetchFreightMovement(false));
          dispatch(resetIsFetchingSelectedFreight());
        }
    }, [props.selectedFreightMovementId, props.rejectLoadForMovementId,
        props.voidableSelectedFreightMovementId, props.canMarkCompleteForSelectedMovementId,
        props.clickedOption, props.canAssignForSelectedMovementId, props.shouldFetchMovement, props.selectedFreightMovement]);

  return (<span>
    <MovementCustomEmail movement={props.selectedFreightMovement}
      disable={options.disableOption}
      showCustomEmailDialog={options.showCustomEmailDialog}
      closeCustomEmailDialog={closeEmailDialog}
      subject={subject} vendorDecDetails={vendorDecDetails}
      vendorDecType={requestVendorDecType}
    />

    {
      props.selectedFreightMovementId && options.rejectLoadDialogOpen &&
      <RejectLoadDialog
        open={options.rejectLoadDialogOpen}
        onClose={() => {setOptionKey("rejectLoadDialogOpen", false); setVendorDecDetails(false);}}
        movement={find(props.items, {id: props.selectedFreightMovementId}) || props.selectedFreightMovement}
      />
    }

    {
      canMarkCompleteForSelectedMovementId && options.completeDialogOpen &&
      <Dialog open={options.completeDialogOpen} onClose={() => setOptionKey("completeDialogOpen", false)}
              aria-labelledby='form-dialog-title' fullWidth id='complete-dialog-open'>
        <DialogTitle id='form-dialog-title'>
          {
            canMarkToDelivered() ? 'Deliver Movement' : 'Complete Movement'
          }
        </DialogTitle>
        <DialogContent>
          {
            canMarkToDelivered() ?
            'Are you sure to mark the movement as delivered?' :
            'To mark the movement complete, we will autofill the missing details in Inload and Outload. Do you wish to proceed?'
          }
        </DialogContent>
        <DialogActions>
          <CommonButton
            key='no'
            label={canMarkToDelivered()? 'No': 'No, I want to Update'}
            onClick={() => setOptionKey("completeDialogOpen", false)}
            color="default"
            variant='outlined'
          />
          <CommonButton
            key='yes'
            label={canMarkToDelivered()? 'Yes': 'Yes, Proceed'}
            primary={true}
            onClick={
              (event) => canMarkToDelivered()?
                       handleDeliverDialogOk(event) :
                       handleCompleteDialogOk(event)
            }
            variant='flat'
          />
        </DialogActions>
      </Dialog>
    }

    {
      options.amendDialogOpen && props.selectedFreightMovement &&
      <SideDrawer
        isOpen={options.amendDialogOpen}
        onClose={() => setOptionKey("amendDialogOpen", false)}
        title={`Movement Amend Request (${get(props.selectedFreightMovement, 'identifier')})`}
        classes={{ paper: 'left-text-align' }}
        size='big'
        >
        <EditMovementReview movement={props.selectedFreightMovement} closeSidebar={() => setOptionKey("amendDialogOpen", false)} />
      </SideDrawer>
    }

    {
      options.isVoidDialogOpen &&
      <RejectionReasonDialog
        open={options.isVoidDialogOpen}
        onClose={closeVoidDialog}
        title={`Void ${props.isPackMovement ? 'Pack' : 'Freight'} Movement: ${get(find(props.items, {id: props.selectedFreightMovementId}), 'identifier') || props?.selectedFreightMovement?.identifier}`}
        value={requestReason}
        onChange={(event) => setRequestReason(event.target.value)}
        onCancel={closeVoidDialog}
        onReject={() => {
          setOptionKey("showCustomEmailDialog", true);
          dispatch(shouldFetchFreightMovement(true));
        }}
        placeholder='Enter you reason for void request'
        submitText='Submit'
        required
      />
    }
    {
      props.selectedFreightMovement &&
      options.isOutloadConstSideDrawerOpened &&
      <InloadOutloadConstDrawer
        type={OUTLOAD}
        isOpen={options.isOutloadConstSideDrawerOpened}
        onClose={() => setOptionKey("isOutloadConstSideDrawerOpened", false)}
        movement={props.selectedFreightMovement}
        identifier={props?.selectedFreightMovement?.identifier}
      />
    }
    {
      props.selectedFreightMovement &&
      options.isInloadConstSideDrawerOpened &&
      <InloadOutloadConstDrawer
        type={INLOAD}
        isOpen={options.isInloadConstSideDrawerOpened}
        onClose={() => setOptionKey("isInloadConstSideDrawerOpened", false)}
        movement={props.selectedFreightMovement}
        identifier={props?.selectedFreightMovement?.identifier}
      />
    }
    {
      options.allocate && props.selectedFreightMovement &&
      <SideDrawer
        isOpen={options.allocate}
        onClose={() => setOptionKey("allocate", false)}
        title='Allocate Movement'
        classes={{ paper: 'left-text-align' }}
        size="big"
      >
        {props.isPackMovement ? <AllocatePackMovements
          handleCancel={() => setOptionKey("allocate", false)}
          movement={props.selectedFreightMovement}
          allocateMovement={handleMovement}
          handleError={handleError}
        /> : <AllocateMovements
          handleCancel={() => setOptionKey("allocate", false)}
          movement={props.selectedFreightMovement}
          allocateMovement={handleMovement}
        />}
      </SideDrawer>
    }
    { options.ignoreDocketDataDialogOpen && isStaff &&
    <ClarificationDrawer
        isOpen={options.ignoreDocketDataDialogOpen}
        onClose={() => setOptionKey("ignoreDocketDataDialogOpen", false)}
        freightMovementId={props.selectedFreightMovement?.id || props.selectedFreightMovementId}
        title={`Seek Clarification: ${get(find(props.items, {id: props.selectedFreightMovementId}), 'identifier') || props?.selectedFreightMovement?.identifier}`}
      />
    }
    { options.editDocketClarification && isStaff &&
    <ClarificationDrawer
        isOpen={options.editDocketClarification}
        onClose={() => setOptionKey("editDocketClarification", false)}
        freightMovementId={props.selectedFreightMovement?.id || props.selectedFreightMovementId}
        title={`Seek Clarification: ${get(find(props.items, {id: props.selectedFreightMovementId}), 'identifier') || props?.selectedFreightMovement?.identifier}`}
        isEditForm
      />
    }
  </span>);
};

export default MovementActions;
