import React, {Component} from 'react';
import alertify from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import includes from 'lodash/includes';
import {PRIMARY_COLOR_GREEN} from '../../common/constants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MapIcon from '@mui/icons-material/Map';
import CommonButton from '../common/CommonButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import GoogleMapSearchBox from './GoogleMapSearchBox';

const DEFAULT_LOCATION = {
  formatted_address: 'Australia',
  lat: -23.7001391,
  lng: 133.8810526,
  streetName: '',
  state: '',
  suburb: '',
  postCode: ''
};

class GoogleMapPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isChanged: false,
      searchBoxText: '',
      value: {
        formatted_address: '',
        lat: '',
        lng: '',
      },
      markerPosition: {
        formatted_address: '',
        lat: '',
        lng: '',
        suburb: '',
        state: '',
        postCode: '',
        streetName: ''
      }
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.getUserCurrentLocation = this.getUserCurrentLocation.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.setDefaultPosition = this.setDefaultPosition.bind(this);
  }

  componentDidMount() {
    if(this.props.location.lat && this.props.location.lng) {
      const value = {
        formatted_address: this.props.location.formatted_address,
        lat: this.props.location.lat,
        lng: this.props.location.lng,
        suburb: this.props.location.suburb,
        state: this.props.location.state,
        postCode: this.props.location.postCode,
        streetName: this.props.location.streetName
      };

      const newState = {...this.state};
      newState.value = {...value};
      newState.markerPosition = {...value};
      newState.searchBoxText = newState.markerPosition.formatted_address
      this.setState(newState, () => {
        if (this.props.openMapPopup)
          this.handleOpen()
      });
    }
    else if(this.props.defaultLocation && this.props.defaultLocation.lat && this.props.defaultLocation.lng) {
      const value = {
        formatted_address: this.props.defaultLocation.formatted_address,
        lat: this.props.defaultLocation.lat,
        lng: this.props.defaultLocation.lng,
        suburb: this.props.defaultLocation.suburb,
        state: this.props.defaultLocation.state,
        postCode: this.props.defaultLocation.postCode,
        streetName: this.props.defaultLocation.streetName
      };

      const newState = {...this.state};
      newState.markerPosition = {...value};
      newState.searchBoxText = newState.markerPosition.formatted_address
      this.setState(newState, () => {
        if (this.props.openMapPopup)
          this.handleOpen()
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.props.location, prevProps.location) || !isEqual(this.props.defaultLocation, prevProps.defaultLocation)) {
      const { location, defaultLocation } = this.props;
      // if props has location, set location and marker position
      if(location && location.lat && location.lng) {
        const value = {
          formatted_address: location.formatted_address,
          lat: location.lat,
          lng: location.lng,
          suburb: location.suburb,
          state: location.state,
          postCode: location.postCode,
          streetName: location.streetName
        };
        this.setState({value: value, markerPosition: {...value}, searchBoxText: value.formatted_address}, () => {
          if (this.props.openMapPopup)
            this.handleOpen()
        });
      }
      // if props has default location, set marker position
      else if(defaultLocation && defaultLocation.lat && defaultLocation.lng) {
        const value = {
          formatted_address: defaultLocation.formatted_address,
          lat: defaultLocation.lat,
          lng: defaultLocation.lng,
          suburb: defaultLocation.suburb,
          state: defaultLocation.state,
          postCode: defaultLocation.postCode,
          streetName: defaultLocation.streetName
        };
        this.setState({markerPosition: {...value}, searchBoxText: value.formatted_address});
      } else if(this.props.resetState && prevProps !== this.props) {
        const value = {
          formatted_address: '',
          lat: '',
          lng: '',
          suburb: '',
          state: '',
          postCode: '',
          streetName: ''
        };
        this.setState({value: value, markerPosition: {...value}, searchBoxText: value.formatted_address}, () => {
          if (this.props.openMapPopup)
            this.handleOpen()
        });
      }
    }
  }

  getUserCurrentLocation() {
    var geoOptions = {
       timeout: 60 * 1000,
       enableHighAccuracy: true,
       maximumAge: 5 * 60 * 1000,
    };
    var errorMessages = [
      'Unable to get location',
      'Permission denied',
      'Position unavailable',
      'Request timed out'
    ];

    var geoSuccess = (position) => {
      this.setPosition(position);
    };
    var geoError = (error) => {
      this.setDefaultPosition();
        if(error.code != 1){
            alertify.error(errorMessages[error.code] || 'Error');
        }
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
  }

  setPosition(position) {
    const value = {
      formatted_address: '',
      lat: parseFloat(parseFloat(position.coords.latitude).toFixed(6)),
      lng: parseFloat(parseFloat(position.coords.longitude).toFixed(6)),
      suburb: '',
      state: '',
      postCode: '',
      streetName: ''
    };

    const newState = {...this.state};
    newState.markerPosition = {...value};
    newState.searchBoxText = newState.markerPosition.formatted_address
    this.setState(newState);
  }

  setDefaultPosition() {
    if(!this.state.markerPosition.lat || !this.state.markerPosition.lng) {
      const value = {
        formatted_address: DEFAULT_LOCATION.formatted_address,
        lat: DEFAULT_LOCATION.lat,
        lng: DEFAULT_LOCATION.lng,
        suburb: DEFAULT_LOCATION.suburb,
        postCode: DEFAULT_LOCATION.postCode,
        state: DEFAULT_LOCATION.state,
        streetName: DEFAULT_LOCATION.streetName
      };

      const newState = {...this.state};
      newState.markerPosition = {...value};
      newState.searchBoxText = newState.markerPosition.formatted_address
      this.setState(newState);
    }
  }

  handleOpen() {
    if (navigator.geolocation && this.props.useCurrentGeoLocation && (isEmpty(this.props.defaultLocation) || !this.props.defaultLocation.lat)) {
      this.getUserCurrentLocation();
    }
    else{
      this.setDefaultPosition();
    }
    if(!this.props.disabled)
      this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleDone() {
    const newState = {...this.state};
    newState.value.formatted_address = this.state.markerPosition.formatted_address;
    newState.value.lat = parseFloat(parseFloat(this.state.markerPosition.lat).toFixed(6));
    newState.value.lng = parseFloat(parseFloat(this.state.markerPosition.lng).toFixed(6));
    newState.value.suburb = this.state.markerPosition.suburb;
    newState.value.state = this.state.markerPosition.state;
    newState.value.postCode = this.state.markerPosition.postCode;
    newState.value.streetName = this.state.markerPosition.streetName;
    newState.open = false;
    newState.isChanged = true;
    this.setState(
      newState,
      () => {
        if (typeof this.props.onDone === "function") {
          this.props.onDone(this.state.value);
        }
      }
    );
  }

  extractedAddressComponents(components) {
    let streetName = []
    let locality = ''
    let state = ''
    let suburb =  ''
    let postCode = ''

    forEach(components, component => {
      if (some(["street_number", "route"], type => component.types.includes(type)))
        streetName.push(component.long_name);

      if (includes(component.types, "locality"))
        locality = component.long_name;

      if (includes(component.types, "administrative_area_level_2"))
        suburb = component.short_name;

      if (includes(component.types, "administrative_area_level_1"))
        state = component.long_name;

      if (includes(component.types, "postal_code"))
        postCode = component.long_name;
    });
    return {
      streetName: !isEmpty(streetName) && locality ? `${streetName.join(' ')}, ${locality}` : (!isEmpty(streetName) ? streetName.join(' ') : locality),
      suburb: suburb,
      state: state,
      postCode: postCode,
    };
  }

  onPlacesChanged = searchBox => {
    const places = searchBox.getPlaces();
    const place = places[0];
    const nextCenter = place.geometry.location;
    let components = this.extractedAddressComponents(place?.address_components)
    const selectedPlace = {
      formatted_address: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      suburb: components.suburb,
      state: components.state,
      postCode: components.postCode,
      streetName: components.streetName 
    };
    this.props.updateLocationFromSearchBox({
      name: place.formatted_address,
      address: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      suburb: components.suburb,
      state: components.state,
      postCode: components.postCode,
      streetName: components.streetName
    });

    this.setState({
      center: nextCenter,
      searchBoxText: place.formatted_address,
      markerPosition: selectedPlace
    });
  };

  onSearchBoxChange = event => this.setState({searchBoxText: event.target.value});

  onMarkerDragEnd = e => {
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();
    const location = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    geocoder.geocode({'location': location}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const place = results[0];
          let components = this.extractedAddressComponents(place?.address_components)
          const selectedPlace = {
            formatted_address: place.formatted_address,
            lat: location.lat,
            lng: location.lng,
            suburb: components.suburb,
            state: components.state,
            postCode: components.postCode,
            streetName: components.streetName
          };
          const newState = {...this.state};
          if(this.props.noDialog) {
            newState.value.formatted_address = selectedPlace.formatted_address;
            newState.value.lat = parseFloat(parseFloat(selectedPlace.lat).toFixed(6));
            newState.value.lng = parseFloat(parseFloat(selectedPlace.lng).toFixed(6));
          }
          newState.center = location;
          newState.searchBoxText = place.formatted_address;
          newState.markerPosition = selectedPlace;
          this.setState(newState, () => {
            if (this.props.noDialog)
              this.handleDone();
          });
        } else {
          // eslint-disable-next-line no-console
          console.log('No results found');
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  render() {
    let locationValue = '';
    let locationLabel = 'Select location';
    if(this.state.value.lat && this.state.value.lng) {
      locationValue = "(" + this.state.value.lat + ", " + this.state.value.lng + ")";
      locationLabel = 'Location';
    }
    return (
      <div>
        {
          this.props.iconOnly ?
          <Tooltip id="tooltip-icon" title="Select location" placement="bottom">
            <IconButton onClick={this.handleOpen} size="large">
              <MapIcon color={PRIMARY_COLOR_GREEN} />
            </IconButton>
          </Tooltip> :
          <TextField
            value={locationValue}
            label={locationLabel}
            onClick={this.handleOpen}
            disabled={this.props.disabled}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Tooltip id="tooltip-icon" title="Select location" placement="bottom">
                  <IconButton onClick={this.handleOpen} size="large">
                    <MapIcon color={PRIMARY_COLOR_GREEN} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>,
              readOnly: true,
            }}
            variant="standard" />
        }
        <div style={{color:"rgb(244,67,54)", fontSize:"12px"}}>
          {!isEmpty(this.props.errors) ? this.props.errors[0] : ''}
        </div>
        {this.props.noDialog &&
          <div style={{marginTop: '15px'}}>
            <GoogleMapSearchBox
              center={{
                lat: parseFloat(this.state.markerPosition.lat),
                lng: parseFloat(this.state.markerPosition.lng)
              }}
              searchBoxText={this.state.searchBoxText}
              onSearchBoxChange={this.onSearchBoxChange}
              onMarkerDragEnd={this.onMarkerDragEnd}
              onPlacesChanged={this.onPlacesChanged}
              onSearchBoxMounted={this.onSearchBoxMounted}
            />
          </div>
        }
        {!this.props.noDialog &&
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose}
          scroll="paper"
        >
          <DialogTitle>Select Address</DialogTitle>
          <DialogContent>
            <GoogleMapSearchBox
              center={{
                lat: parseFloat(this.state.markerPosition.lat),
                lng: parseFloat(this.state.markerPosition.lng)
              }}
              searchBoxText={this.state.searchBoxText}
              onSearchBoxChange={this.onSearchBoxChange}
              onMarkerDragEnd={this.onMarkerDragEnd}
              onPlacesChanged={this.onPlacesChanged}
              onSearchBoxMounted={this.onSearchBoxMounted}
            />
          </DialogContent>
          <DialogActions>
            <CommonButton
              key="cancelButton"
              label="Cancel"
              primary={true}
              onClick={this.handleClose}
              variant="flat"
            />
            <CommonButton
              key="doneButton"
              label="Done"
              primary={true}
              onClick={this.handleDone}
              variant="flat"
            />
          </DialogActions>
        </Dialog>
        }
      </div>
    );
  }
}

export default GoogleMapPopup;
