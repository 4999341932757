// eslint-disable-next-line no-unused-vars
import React from 'react';
import axios from 'axios';
import alertifyjs from 'alertifyjs';
import { apiURL } from '../main';
import APIService from '../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { setHeaderText, setSubHeaderText, isLoading, loaded, forceStopLoader } from '../main';
import { getFreshdeskURL, openURLInNewTab, isAtGlobalContracts, downloadFileFromBlob, currentUserCompany, addPageCacheQueryParamsToUrl} from '../../common/utils';
import moment from 'moment';
import packageJson from '../../../package.json';
import { COMPANY_TYPE_DOCUMENT_TYPE_MAPPING, OTHERS_CANNOT_CREATE, OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE } from '../../common/constants';

const VERSION = packageJson?.version
export const GET_COMMODITY_CONTRACTS_SUCCESS = 'GET_COMMODITY_CONTRACTS_SUCCESS';
export const GET_PAGINATED_COMMODITY_CONTRACTS_SUCCESS = 'GET_PAGINATED_COMMODITY_CONTRACTS_SUCCESS';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_PARTIES_SUCCESS = 'GET_PARTIES_SUCCESS';
export const GET_BUYER_CONTACTS_SUCCESS = 'GET_BUYER_CONTACTS_SUCCESS';
export const GET_SELLER_CONTACTS_SUCCESS = 'GET_SELLER_CONTACTS_SUCCESS';

export const GET_CUSTOMER_CONTACTS_SUCCESS = 'GET_CUSTOMER_CONTACTS_SUCCESS';
export const GET_FIXTURES_SUCCESS = 'GET_FIXTURES_SUCCESS';
export const ADD_CONTRACT_FAILURE = 'ADD_CONTRACT_FAILURE';
export const CLEAR_CONTRACT_FAILURE = 'CLEAR_CONTRACT_FAILURE';
export const START_FETCHING_SELECTED_CONTRACT = 'START_FETCHING_SELECTED_CONTRACT';
export const RESET_IS_FETCHING_SELECTED_CONTRACT = 'RESET_IS_FETCHING_SELECTED_CONTRACT';
export const RECEIVE_CONTRACT = 'RECEIVE_CONTRACT';
export const START_FETCHING_SELECTED_TITLE_TRANSFER = 'START_FETCHING_SELECTED_TITLE_TRANSFER';
export const RESET_IS_FETCHING_SELECTED_TITLE_TRANSFER = 'RESET_IS_FETCHING_SELECTED_TITLE_TRANSFER';
export const RECEIVE_TITLE_TRANSFER = 'RECEIVE_TITLE_TRANSFER';
export const CLEAR_CONTRACT = 'CLEAR_CONTRACT';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const REJECTION_SUCCESS = 'REJECTION_SUCCESS';
export const CAN_CONFIRM_OR_REJECT = 'CAN_CONFIRM_OR_REJECT';
export const GET_TITLE_TRANSFERS_SUCCESS = 'GET_TITLE_TRANSFERS_SUCCESS';
export const GET_MOVEMENT_CARRY_ITEMS_SUCCESS = 'GET_MOVEMENT_CARRY_ITEMS_SUCCESS';
export const GET_TITLE_TRANSFER_CARRY_ITEMS_SUCCESS = 'GET_TITLE_TRANSFER_CARRY_ITEMS_SUCCESS';
export const GET_TITLE_TRANSFER_CANOLA_LOAD_CARRY_ITEMS_SUCCESS = 'GET_TITLE_TRANSFER_CANOLA_LOAD_CARRY_ITEMS_SUCCESS';
export const GET_PAYMENT_TERMS_SUCCESS = 'GET_PAYMENT_TERMS_SUCCESS';
export const ADD_TITLE_TRANSFER_FAILURE = 'ADD_TITLE_TRANSFER_FAILURE';
export const SHOW_HIDE_TITLE_TRANSFER_SIDE_DRAWER = 'SHOW_HIDE_TITLE_TRANSFER_SIDE_DRAWER';
export const SHOW_ADD_TITLE_TRANSFER_SIDE_DRAWER = 'SHOW_ADD_TITLE_TRANSFER_SIDE_DRAWER';
export const IS_PARTIES_FETCHED = 'IS_PARTIES_FETCHED';
export const CAN_CREATE_COG = 'CAN_CREATE_COG';
export const SET_SELECTED_CONTRACT_ID = 'SET_SELECTED_CONTRACT_ID';
export const CONTRACT_ACTION_OPTIONS = 'CONTRACT_ACTION_OPTIONS';
export const CAN_CLOSE_OUT_FOR_CONTRACT = 'CAN_CLOSE_OUT_FOR_CONTRACT';
export const SHOW_HIERARCHY_FOR_ORDER_ID = 'SHOW_HIERARCHY_FOR_ORDER_ID';
export const OPEN_CLOSE_OUT_DIALOG = 'OPEN_CLOSE_OUT_DIALOG';
export const CANNOT_MARK_COMPLETE_REASONS = 'CANNOT_MARK_COMPLETE_REASONS';
export const CAN_CREATE_TITLE_TRANSFERS = 'CAN_CREATE_TITLE_TRANSFERS';
export const CAN_RAISE_VOID_REQUEST = 'CAN_RAISE_VOID_REQUEST';
export const CAN_RAISE_VOID_AND_DUPLICATE_REQUEST = 'CAN_RAISE_VOID_AND_DUPLICATE_REQUEST';
export const SHOULD_OPEN_REVIEW_AMENDMENT_DRAWER = 'SHOULD_OPEN_REVIEW_AMENDMENT_DRAWER';
export const CAN_MARK_AS_PAID = 'CAN_MARK_AS_PAID';
export const IS_TITLE_TRANSFERS_FETCHED = 'IS_TITLE_TRANSFERS_FETCHED';
export const FREIGHT_MOVEMENT_WARNING = 'FREIGHT_MOVEMENT_WARNING';
export const SHOULD_FETCH_CONTRACT = 'SHOULD_FETCH_CONTRACT';
export const CLICKED_OPTION = 'CLICKED_OPTION';
export const CLICKED_TITLE_TRANSFER_OPTION = 'CLICKED_TITLE_TRANSFER_OPTION';
export const VOID_TITLE_TRANSFER_DIALOG = 'VOID_TITLE_TRANSFER_DIALOG';
export const UPDATE_DUPLICATE_TITLE_TRANSFER_ID = 'UPDATE_DUPLICATE_TITLE_TRANSFER_ID'
export const SHOW_VIEW_TITLE_TRANSFER_SIDE_DRAWER = 'SHOW_VIEW_TITLE_TRANSFER_SIDE_DRAWER';
export const SELECTED_TITLE_TRANSFER = 'SELECTED_TITLE_TRANSFER';

const MESSAGES = {
  CREATE_SUCCESS: 'Successfully created!',
  UPDATE_SUCCESS: 'Contract was successfully updated',
  CONFIRMED: 'Contract is confirmed.',
  REJECTED: 'Contract is rejected.',
  PAID: 'Contract is marked Paid.',
  VOID_REQUEST_RAISED: 'Contract void request sent for approval.',
  VOID_CONFIRMED: 'Contract is voided.',
  VOID_REJECTED: 'Void Request Rejected.',
  AMEND_REQUEST_RAISED: 'Contract amend request sent for approval.',
  AMEND_CONFIRMED: 'Contract is amended.',
  AMEND_REJECTED: 'Amend Request Rejected.',
  TITLE_TRANSFER_REQUEST_RAISED: 'Title Transfer request successfully sent.',
};
export const addContractFailure = errors => {
  return { type: ADD_CONTRACT_FAILURE, errors };
};
export const clearContractFailure = () => {
  return { type: CLEAR_CONTRACT_FAILURE };
};
export const getContractsResponse = contracts => {
  return { type: GET_COMMODITY_CONTRACTS_SUCCESS, contracts };
};
export const getPaginatedContractsResponse = paginatedContracts => {
  return { type: GET_PAGINATED_COMMODITY_CONTRACTS_SUCCESS, paginatedContracts };
};
export const getTemplatesResponse = templates => {
  return { type: GET_TEMPLATES_SUCCESS, templates };
};
export const canCreateCOG = contractId => {
  return { type: CAN_CREATE_COG, contractId };
};

const isPartiesFetched = isFetched => ({
  type: IS_PARTIES_FETCHED,
  isFetched,
});

export const getPartiesResponse = parties => {
  return { type: GET_PARTIES_SUCCESS, parties };
};
export const getContractFixturesResponse = fixtures => {
  return { type: GET_FIXTURES_SUCCESS, fixtures };
};
export const getPaymentTermsResponse = paymentTerms => {
  return { type: GET_PAYMENT_TERMS_SUCCESS, paymentTerms };
};
export const getContactsResponse = (contacts, role) => {
  var res = {};
  res['type'] = 'GET_' + role.toUpperCase() + '_CONTACTS_SUCCESS';
  res[role + 'Contacts'] = contacts;
  return res;
};

export const confirmationResponse = contract => {
  return { type: CONFIRMATION_SUCCESS, contract };
};
export const rejectionResponse = contract => {
  return { type: REJECTION_SUCCESS, contract };
};
export const canConfirmOrRejectResponse = result => {
  return { type: CAN_CONFIRM_OR_REJECT, result };
};

export const receiveContract = item => ({
  type: RECEIVE_CONTRACT,
  item,
});

export const receiveTitleTransfer = item => ({
  type: RECEIVE_TITLE_TRANSFER,
  item,
});

export const clearContract = item => ({
  type: CLEAR_CONTRACT,
  item,
});

export const titleTransfersResponse = items => ({
  type: GET_TITLE_TRANSFERS_SUCCESS,
  items,
});

export const isTitleTransfersFetch = isFetched => {
  return { type: IS_TITLE_TRANSFERS_FETCHED, isFetched };
};

export const movementCarryItemsResponse = items => ({
  type: GET_MOVEMENT_CARRY_ITEMS_SUCCESS,
  items,
});

export const titleTransfersCarryItemsResponse = items => ({
  type: GET_TITLE_TRANSFER_CARRY_ITEMS_SUCCESS,
  items,
});

export const titleTransfersCanolaLoadCarryItemsResponse = items => ({
  type: GET_TITLE_TRANSFER_CANOLA_LOAD_CARRY_ITEMS_SUCCESS,
  items,
})

export const addTitleTransferFailure = errors => {
  return { type: ADD_TITLE_TRANSFER_FAILURE, errors };
};

export const showHideTitleTransferSideDrawer = show => ({
  type: SHOW_HIDE_TITLE_TRANSFER_SIDE_DRAWER,
  show,
});

export const showAddTitleTransferSideDrawer = show => ({
  type: SHOW_ADD_TITLE_TRANSFER_SIDE_DRAWER,
  show,
});

export const showViewTitleTransferSideDrawer = show => ({
  type: SHOW_VIEW_TITLE_TRANSFER_SIDE_DRAWER,
  show,
});

export const contractActionOptions = subItems => ({
  type: CONTRACT_ACTION_OPTIONS,
  subItems,
});

export const cannotMarkCompleteReasons = cannotMarkCompleteReasons => ({
  type: CANNOT_MARK_COMPLETE_REASONS,
  cannotMarkCompleteReasons,
});

export const canCloseOutForContract = flag => ({
  type: CAN_CLOSE_OUT_FOR_CONTRACT,
  flag
});

export const showHierarchyForOrderId = id => ({
  type: SHOW_HIERARCHY_FOR_ORDER_ID,
  id
})

export const openCloseOutDialog = items => ({
  type: OPEN_CLOSE_OUT_DIALOG,
  items
})

export const setSelectedContractId = selectedContractId => ({
  type: SET_SELECTED_CONTRACT_ID,
  selectedContractId,
});

export const canCreateTitleTransferForContractId = selectedContractId => ({
  type: CAN_CREATE_TITLE_TRANSFERS,
  selectedContractId,
});

export const canRaiseVoidRequestForSelectedContract = flag => ({
  type: CAN_RAISE_VOID_REQUEST,
  flag,
});

export const canRaiseVoidAndDuplicateRequestForSelectedContract = flag => ({
  type: CAN_RAISE_VOID_AND_DUPLICATE_REQUEST,
  flag,
});

export const shouldOpenReviewAmendmentDrawer = flag => ({
  type: SHOULD_OPEN_REVIEW_AMENDMENT_DRAWER,
  flag,
});

export const canMarkAsPaid = flag => ({
  type: CAN_MARK_AS_PAID,
  flag,
});

export const shouldFetchContract = flag => ({
  type: SHOULD_FETCH_CONTRACT,
  flag,
});

export const setClickedOption = option => ({
  type: CLICKED_OPTION,
  option,
});

export const setClickedTitleTransferOption = option => ({
  type: CLICKED_TITLE_TRANSFER_OPTION,
  option,
});

export const freightMovementWarning = (FMwarningFlag, FMwarningList, FMwarningContractID) => ({
  FMwarningFlag,
  FMwarningList,
  FMwarningContractID,
  type: FREIGHT_MOVEMENT_WARNING,
});

export const voidTitleTransferDialog = (flag, item)=> ({
  type: VOID_TITLE_TRANSFER_DIALOG,
  flag,
  item
});

export const updateDuplicateTitleTransferId = (id)=> ({
  type: UPDATE_DUPLICATE_TITLE_TRANSFER_ID,
  id
});

export const setSelectedTitleTransfer = item => ({
  type: SELECTED_TITLE_TRANSFER,
  item,
});

export const getContracts = (url = '', _forceStopLoader = false, queryString, unit) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    if(unit)
      config.headers['REFERER-UNIT'] = unit

    let CONTRACTS_LIST_URL = url ? url : `${apiURL}/contracts/web/`;
    if (queryString) {
      CONTRACTS_LIST_URL += queryString;
    }

    if(isAtGlobalContracts()) {
      CONTRACTS_LIST_URL = addPageCacheQueryParamsToUrl(CONTRACTS_LIST_URL)
    }

    axios
      .get(CONTRACTS_LIST_URL, config)
      .then(response => {
        dispatch(getPaginatedContractsResponse(response.data));
        if (_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(error => {
        dispatch(getContractsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getTemplates = () => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let url = `${apiURL}/contracts/web/?template&page_size=100000`;

    axios
      .get(url, config)
      .then(response => {
        var items = [];
        if (has(response, 'data.results')) {
          items = response.data.results;
        } else {
          items = response.data;
        }
        dispatch(getTemplatesResponse(items));
      })
      .catch(error => {
        dispatch(getTemplatesResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getParties = urlSuffix => {
  return (dispatch, getState) => {
    dispatch(isPartiesFetched(false));
    dispatch(getPartiesResponse([]));
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let URL = `${apiURL}/contracts/parties/`;
    if (urlSuffix) {
      URL += urlSuffix;
    }
    axios(URL, config)
      .then(response => {
        dispatch(isPartiesFetched(true));
        dispatch(getPartiesResponse(response.data));
      })
      .catch(error => {
        dispatch(isPartiesFetched(true));
        dispatch(getPartiesResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getPartiesMinimal = urlSuffix => {
  return (dispatch, getState) => {
    dispatch(isPartiesFetched(false));
    dispatch(getPartiesResponse([]));
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let URL = `${apiURL}/contracts/parties/minimal/`;
    if (urlSuffix) {
      URL += urlSuffix;
    }
    axios(URL, config)
      .then(response => {
        dispatch(isPartiesFetched(true));
        dispatch(getPartiesResponse(response.data));
      })
      .catch(error => {
        dispatch(isPartiesFetched(true));
        dispatch(getPartiesResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getFixtures = () => {
  return (dispatch, getState) => {
    const currentUser = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${currentUser.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    const companyTypeId = currentUser.user.company.typeId;
    const URL = `${apiURL}/contracts/fixtures/?company_type_id=${companyTypeId}`;
    axios(URL, config)
      .then(response => {
        dispatch(getContractFixturesResponse(response.data));
      })
      .catch(error => {
        dispatch(getContractFixturesResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getPaymentTerms = () => {
  return dispatch => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    const URL = `${apiURL}/contracts/payment-terms/`;
    axios(URL, config)
      .then(response => {
        dispatch(getPaymentTermsResponse(response.data));
      })
      .catch(error => {
        dispatch(getPaymentTermsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const canCreateMovementForContract = contractId => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts(contractId)
  ['freight/contracts/new']()
    .get(token)
    .then(res => {
      if (res.result && isEmpty(res.warnings)) {
        dispatch(isLoading('movementFormFromContract'));
        window.location.hash = `#/freights/movements/new?commodityContractId=${contractId}`;
      } else if (!isEmpty(res.reasons)) {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create a Freight Movement for this Contract because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => { },
        );
      } else {
        const warnings = '<li>' + res.warnings.join('</li><li>');
        alertifyjs.confirm(
          'Warning',
          `<div className=""><ul>${warnings}</ul></div>`,
          () => {
            dispatch(isLoading('movementFormFromContract'));
            window.location.hash = `#/freights/movements/new?commodityContractId=${contractId}`;
          },
          () => { },
        );
      }
    });
};

export const canCreateOrderForContract = contractId => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts(contractId)
  ['freight/orders/new']()
    .get(token)
    .then(res => {
      if (res.result && isEmpty(res.warnings)) {
        dispatch(isLoading('movementFormFromContract'));
        window.location.hash = `#/freights/orders/new?commodityContractId=${contractId}`;
      } else if (!isEmpty(res.reasons)) {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create a Freight Order for this Contract because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => { },
        );
      } else {
        const warnings = '<li>' + res.warnings.join('</li><li>');
        alertifyjs.confirm(
          'Warning',
          `<div className=""><ul>${warnings}</ul></div>`,
          () => {
            dispatch(isLoading('movementFormFromContract'));
            window.location.hash = `#/freights/orders/new?commodityContractId=${contractId}`;
          },
          () => { },
        );
      }
    });
};

export const canCreateCOGForContract = contractId => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts(contractId)
    .appendToUrl('cog/orders/new/')
    .get(token)
    .then(res => {
      if (res.result && isEmpty(res.warnings)) {
        dispatch(isLoading('movementFormFromContract'));
        window.location.hash = `#/freights/grain/new?commodityContractId=${contractId}`;
      } else if (!isEmpty(res.reasons)) {
        dispatch(canCreateCOG(undefined));
        dispatch(forceStopLoader());
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create a Call on Grain Order for this Contract because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => { },
        );
      } else {
        dispatch(forceStopLoader());
        const warnings = '<li>' + res.warnings.join('</li><li>');
        alertifyjs.confirm(
          'Warning',
          `<div className=""><ul>${warnings}</ul></div>`,
          () => {
            dispatch(isLoading('movementFormFromContract'));
            window.location.hash = `#/freights/grain/new?commodityContractId=${contractId}`;
          },
          () => { },
        );
      }
    });
};

export const canCreateTitleTransfer = (contractId, callback) => (dispatch, getState) => {
  dispatch(isLoading('titleTransferForm'));
  const token = getState().main.user.token;
  APIService.contracts(contractId)
  ['title-transfers/new']()
    .get(token)
    .then(res => {
      if (res.result && isEmpty(res.warnings)) {
        dispatch(canCreateTitleTransferForContractId(contractId));
        if (callback) callback();
      } else if (!isEmpty(res.reasons)) {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        dispatch(canCreateTitleTransferForContractId(undefined));
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create a Title Transfer for this Contract because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => { },
        );
      } else {
        const warnings = '<li>' + res.warnings.join('</li><li>');
        alertifyjs.confirm(
          'Warning',
          `<div className=""><ul>${warnings}</ul></div>`,
          () => {
            dispatch(canCreateTitleTransferForContractId(contractId));
            if (callback) callback();
          },
          () => { },
        );
      }
      dispatch(forceStopLoader());
    });
};

export const create = data => (dispatch, getState) => {
  dispatch(clearContractFailure());
  fetch(`${apiURL}/contracts/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      dispatch(loaded());
      if (json.id == null) {
        if (has(json, 'errors.contractNumber')) {
          alertifyjs.error(json.errors['contractNumber'][0]);
          dispatch(forceStopLoader());
        } else if (has(json.errors, '_All_')) {
          alertifyjs.error(json.errors._All_[0]);
          dispatch(forceStopLoader());
        } else {
          dispatch(forceStopLoader());
          dispatch(addContractFailure(json.errors));
        }
      } else {
        if (json.status === 'template') {
          alertifyjs.success(MESSAGES.CREATE_SUCCESS, 1);
          dispatch(getTemplates());
        } else {
          document.location = `#/contracts/${json.id}/contract`;
          alertifyjs.success(MESSAGES.CREATE_SUCCESS, 1);
        }
      }
    });
};

const startFetchingSelectedContract = () => ({
  type: START_FETCHING_SELECTED_CONTRACT,
});

const resetIsFetchingSelectedContract = () => ({
  type: RESET_IS_FETCHING_SELECTED_CONTRACT,
});

const startFetchingSelectedTitleTransfer = () => ({
  type: START_FETCHING_SELECTED_TITLE_TRANSFER,
});

const resetIsFetchingSelectedTitleTransfer = () => ({
  type: RESET_IS_FETCHING_SELECTED_TITLE_TRANSFER,
});

export const getSelectedContract = (
  contractId,
  actionCreator,
  redirectPage = true,
  inActivatedUserToken,
  isEditingMode = false,
  stopLoader = false,
  shouldSetHeaderText = true,
  unit,
  doNotConvertUnit
) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  const { isFetchingSelectedContract } = getState().companies.contracts;
  if (!isFetchingSelectedContract) {
    dispatch(startFetchingSelectedContract());
    const headers = {}
    if(doNotConvertUnit)
      headers['REFERER-DO-NOT-CONVERT-UNIT'] = true
    else if(unit)
      headers['REFERER-UNIT'] = unit
    APIService.contracts(contractId)
      .get(token, headers)
      .then(item => {
        const headerText = item ? 'Commodity Contract ' + item.referenceNumber : '';
        const subHeaderText =
          item && item.commodity
            ? '(' +
            item.commodity.displayName +
            ' ' +
            parseFloat(item.tonnage).toFixed(2) +
            ' ' +
              (item.requestedUnit || item?.commodity?.priceUnit || 'MT') +
            ')'
            : '';
        if (shouldSetHeaderText) {
          dispatch(setHeaderText(headerText));
          dispatch(setSubHeaderText(subHeaderText));
        }
        dispatch(actionCreator(item));
        if (stopLoader) {
          dispatch(forceStopLoader());
        }
        if (redirectPage && isEditingMode) {
          document.location = '#/contracts/' + contractId + '/edit';
        } else if (isEditingMode || redirectPage) {
          document.location = '#/contracts/' + contractId + '/contract';
        }
      })
      .catch(() => {
        dispatch(resetIsFetchingSelectedContract());
      });
  }
};

export const getSelectedTitleTransfer = (
  titleTransferId,
  actionCreator,
  stopLoader = false,
  unit,
  openTitleTransfer = true
) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const { isFetchingSelectedTitleTransfer } = getState().companies.contracts;
  if (!isFetchingSelectedTitleTransfer) {
    dispatch(startFetchingSelectedTitleTransfer());
    APIService.contracts().appendToUrl(`title-transfer/${titleTransferId}/`)
      .get(token, {'REFERER-UNIT' : unit})
      .then(item => {
        dispatch(actionCreator(item));
        if (openTitleTransfer)
          dispatch(showViewTitleTransferSideDrawer(true));
        if (stopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(() => {
        dispatch(resetIsFetchingSelectedTitleTransfer());
      });
  }
};

export const updateContract = (contractId, data) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.contracts(contractId)
    .put(data, token)
    .then(item => {
      if (has(item, 'errors.contractNumber')) {
        alertifyjs.error(item.errors['contractNumber'][0]);
      } else if (!isEmpty(item.errors)) {
        dispatch(addContractFailure(item.errors));
      } else {
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS, 1, () => {
          document.location = '#/contracts';
        });
      }
    });
};

export const raiseUpdateContractRequest = (contractId, data) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts(contractId)
    .amend()
    .post(data, token)
    .then(response => {
      dispatch(loaded());
      if (has(response, 'errors.contractNumber')) {
        alertifyjs.error(response.errors['contractNumber'][0]);
      } else if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(MESSAGES.AMEND_REQUEST_RAISED, 1, () => {
          document.location = '#/contracts/' + contractId + '/contract';
        });
      }
    });
};

export const canConfirmOrReject = (contractId, inActivatedUserToken) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  fetch(`${apiURL}/contracts/${contractId}/confirm/`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(result => {
      dispatch(canConfirmOrRejectResponse(result));
    });
};

export const confirm = (contractId, data, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  fetch(`${apiURL}/contracts/${contractId}/confirm/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        if (has(json, 'errors.contractNumber')) {
          alertifyjs.error(json.errors['contractNumber'][0]);
        } else {
          alertifyjs.error(json.errors[0]);
        }
      } else {
        alertifyjs.success(MESSAGES.CONFIRMED, 1, () => {
          dispatch(confirmationResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const reject = (contractId, data, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  fetch(`${apiURL}/contracts/${contractId}/reject/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        if (has(json, 'errors.rejectionReason')) {
          alertifyjs.error(json.errors['rejectionReason'][0]);
        } else {
          alertifyjs.error(json.errors[0]);
        }
      } else {
        alertifyjs.success(MESSAGES.REJECTED, 1, () => {
          dispatch(rejectionResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const raiseVoidRequest = (contractId, data, reload, loader, isDuplicated=false) => (dispatch, getState) => {
  if(loader)
    dispatch(isLoading('contractVoid'));
  const token = getState().main.user.token;
  fetch(`${apiURL}/contracts/${contractId}/void/`, {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        if(loader)
          dispatch(forceStopLoader());
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REQUEST_RAISED, 1, () => {
          if(isDuplicated){
            dispatch(canRaiseVoidAndDuplicateRequestForSelectedContract(false))
            const queryParams = `copyFrom=${contractId}`
            document.location.hash = `#/contracts/new?${queryParams}`;
          }
          else if (reload)
            window.location.reload();
          else if (loader)
            dispatch(forceStopLoader());
        });
      }
    });
};

export const confirmVoid = (contractId, reload, inActivatedUserToken) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  fetch(`${apiURL}/contracts/${contractId}/void/confirm/`, {
    method: 'PUT',
    body: JSON.stringify({ status: null }),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_CONFIRMED, 1, () => {
          dispatch(confirmationResponse(json));
          if (reload) {
            window.location.reload();
          }
        });
      }
    });
};

export const rejectVoid = (contractId, data, reload, inActivatedUserToken) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  fetch(`${apiURL}/contracts/${contractId}/void/reject/`, {
    method: 'PUT',
    body: JSON.stringify(data ? data : { status: null }),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REJECTED, 1, () => {
          dispatch(rejectionResponse(json));
          if (reload) {
            window.location.reload();
          }
        });
      }
    });
};

export const markPaid = (contractId, data, reload) => (dispatch, getState) => {
  const token = getState().main.user.token;
  fetch(`${apiURL}/contracts/${contractId}/paid/`, {
    method: 'PUT',
    body: JSON.stringify(data ? data : { status: null }),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.PAID, 1, () => {
          if (reload) {
            window.location.reload();
          }
        });
      }
    });
};

export const markInvoicable = (contractId, data, successMsg) => (dispatch, getState) =>{
  const token = getState().main.user.token;
  APIService.contracts(contractId)
    .appendToUrl('invoicable/')
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(successMsg, 1, () => {
          window.location.reload();
        });
      }
    });
};


export const raiseAmendRequest = (contractId, data) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts(contractId)
    .amend()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors.contractNumber')) {
        alertifyjs.error(response.errors['contractNumber'][0]);
      } else if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_REQUEST_RAISED, 1, () => {
          window.location.reload();
        });
      }
    });
};

export const confirmAmend = (contractId, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.contracts(contractId)
  ['amend/confirm']()
    .put(null, token)
    .then(response => {
      if (has(response, 'errors.contractNumber')) {
        alertifyjs.error(response.errors['contractNumber'][0]);
      } else if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_CONFIRMED, 1, () => {
          dispatch(confirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectAmend = (contractId, data, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.contracts(contractId)
  ['amend/reject']()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_REJECTED, 1, () => {
          dispatch(rejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const getTitleTransfers = (contractId, forInvoice = true, url = '', _forceStopLoader=false, unit, doNotConvertUnit) => (dispatch, getState) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  };
  if(doNotConvertUnit)
    config.headers['REFERER-DO-NOT-CONVERT-UNIT'] = true
  else if(unit)
    config.headers['REFERER-UNIT'] = unit
  if (contractId)
    dispatch(titleTransfersResponse([]));
  dispatch(isTitleTransfersFetch(false));
  let service = '';
  if (contractId) {
    service = `${apiURL}/contracts/title-transfers/?contract_id=${contractId}`;
    if (forInvoice)
      service += '&invoicing';
  }
  else {
    service = url ? url : `${apiURL}/contracts/title-transfers/`;
  }
  service = addPageCacheQueryParamsToUrl(service)

  axios
    .get(service, config).then(response => {
    dispatch(titleTransfersResponse(response.data));
    dispatch(isTitleTransfersFetch(true));
    if(_forceStopLoader) {
      dispatch(forceStopLoader());
    }
  }).catch(error => {
    dispatch(titleTransfersResponse(error.response ? error.response.data : error.message));
    dispatch(isTitleTransfersFetch(true));
  });
};

export const requestTitleTransfer = (contractId, data, reload) => (dispatch, getState) => {
  const token = getState().main.user.token;
  dispatch(addTitleTransferFailure());
  if(contractId)
  {fetch(`${apiURL}/contracts/${contractId}/title-transfer/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (has(json, 'errors')) {
        dispatch(forceStopLoader());
        if(get(json, 'errors.canolaLoadIds')) {
          alertifyjs.error(get(json, 'errors.canolaLoadIds.0'), 3)
        }
        dispatch(addTitleTransferFailure(json.errors));
      } else {
        dispatch(showHideTitleTransferSideDrawer(false));
        alertifyjs.success(MESSAGES.TITLE_TRANSFER_REQUEST_RAISED, 2, () => {
          if (reload) {
            window.location.reload();
          }
        });
      }
    });}
    else{
      fetch(`${apiURL}/contracts/title-transfers/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Token ${token}`,
        },
      })
        .then(response => response.json())
        .then(json => {
          if (has(json, 'errors')) {
            dispatch(forceStopLoader());
            if(get(json, 'errors.canolaLoadIds')) {
              alertifyjs.error(get(json, 'errors.canolaLoadIds.0'), 3)
            }
            dispatch(addTitleTransferFailure(json.errors));
          } else {
            dispatch(showHideTitleTransferSideDrawer(false));
            dispatch(showAddTitleTransferSideDrawer(false));
            alertifyjs.success(MESSAGES.TITLE_TRANSFER_REQUEST_RAISED, 2, () => {
              if (reload) {
                window.location.reload();
              }
            });
          }
        });
    }
};

export const getFarmContracts = farmId => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    const CONTRACTS_LIST_URL = `${apiURL}/farms/${farmId}/contracts/`;
    axios
      .get(CONTRACTS_LIST_URL, config)
      .then(response => {
        dispatch(getContractsResponse(response.data));
      })
      .catch(error => {
        dispatch(getContractsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getMovementsCarryItems = (contractId, doNotConvertUnit) => dispatch => {
  let headers = {}
  if(doNotConvertUnit)
    headers['REFERER-DO-NOT-CONVERT-UNIT'] = true

  dispatch(movementCarryItemsResponse([]));
  APIService.contracts(contractId)
    .appendToUrl('movement-carry-items/')
    .get(null, headers)
    .then(items => dispatch(movementCarryItemsResponse(items)));
};

export const getTitleTransferCarryItems = (contractId, doNotConvertUnit) => dispatch => {
  let headers = {}
  if(doNotConvertUnit)
    headers['REFERER-DO-NOT-CONVERT-UNIT'] = true

  dispatch(titleTransfersCarryItemsResponse([]));
  APIService.contracts(contractId)
    .appendToUrl('title-transfer-carry-items/')
    .get(null, headers)
    .then(items => dispatch(titleTransfersCarryItemsResponse(items)));
};

export const getTitleTransferCanolaLoadCarryItems = (contractId, doNotConvertUnit) => dispatch => {
  let headers = {}
  if(doNotConvertUnit)
    headers['REFERER-DO-NOT-CONVERT-UNIT'] = true
  dispatch(titleTransfersCarryItemsResponse([]));
  APIService.contracts(contractId)
    .appendToUrl('title-transfer-canola-load-carry-items/')
    .get(null, headers)
    .then(items => dispatch(titleTransfersCanolaLoadCarryItemsResponse(items)));
};

export const getContractActionOptions = (contractId, type = 'default') => (dispatch, getState) => {
  dispatch(contractActionOptions(undefined));
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=${type}`);
  contract.get(token).then(subItems => {
    dispatch(contractActionOptions(get(subItems, '[0].subItems')));
  });
};

export const canContractBeVoided = (event, contractId, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=can-void`);
  contract.get(token).then(res => {
    let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot Void this Contract because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      if(callback)
        callback();
      dispatch(canRaiseVoidRequestForSelectedContract(true));
      dispatch(isLoading('voidReasonDialog'));
    }
    event.preventDefault();
  });
};

export const isValidUserDocumentType = contractObj => {
  const userCompanyTypeId = currentUserCompany()?.typeId
  const validDocumentTypes = userCompanyTypeId in COMPANY_TYPE_DOCUMENT_TYPE_MAPPING ? COMPANY_TYPE_DOCUMENT_TYPE_MAPPING[userCompanyTypeId]: []
  const contractDocumentTypeId = get(contractObj, 'documentTypeId')
  return validDocumentTypes.includes(contractDocumentTypeId)
}

export const canContractBeDuplicated = contractObj => {
  if(!isValidUserDocumentType(contractObj)){
    alertifyjs.alert(
      'Permission Denied',
      'You do not have permission to create a new contract for this document type'
    )
    return false
  }

  if(isBuyerContractCreationRestrictionApplicable(contractObj, OTHERS_CANNOT_CREATE)){
    const buyerName = get(contractObj, 'buyer.company.name') || get(contractObj, 'buyerName')
    alertifyjs.alert(
      'Permission Denied',
      `Contract Creation/Amending with ${buyerName} as Buyer is disabled. Please Contact ${buyerName} for Contract Creation/Amending`
    )
    return false
  } else if (isSellerContractCreationRestrictionApplicable(contractObj, OTHERS_CANNOT_CREATE)){
    const sellerName = get(contractObj, 'seller.company.name') || get(contractObj, 'sellerName')
    alertifyjs.alert(
      'Permission Denied',
      `Contract Creation/Amending with ${sellerName} as Seller is disabled. Please Contact ${sellerName} for Contract Creation/Amending`
    )
    return false
  }
  return true
}

export const canContractBeVoidedAndDuplicated = (event, existingContract, callback) => dispatch => {
  if(!canContractBeDuplicated(existingContract))
    return

  const contract = APIService.contracts(existingContract.id);
  contract.appendToUrl(`action-options/?type=can-void`);
  contract.get().then(res => {
    let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot Void this Contract because:</p><ul>${reasons}</ul></div>`,
        () => {}
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      let additionalWarning = ''
      if (isContractCreationRestrictionApplicable(existingContract, OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE))
        additionalWarning = ' The parties involved with this contract require acceptance for the new contract to be created. Do you still want to proceed?'

      alertifyjs
        .confirm(
          'Warning',
          `This will void the current contract and allow you to create a new one with similar details.${additionalWarning}`,
          () => {
            if (callback)
              callback();
            dispatch(canRaiseVoidAndDuplicateRequestForSelectedContract(true));
            dispatch(isLoading('voidReasonDialog'));
          },
          () => { },
        )
        .set('labels', { ok: 'Proceed', cancel: 'Cancel' });
    }
    event.preventDefault();
  });
};


export const canContractBeClosedOut = (contractId) => (dispatch, getState) => {
  dispatch(isLoading('dialog'));
  dispatch(canCloseOutForContract(false));
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=can-close-out`);
  contract.get(token).then(res => {
    dispatch(forceStopLoader());
    dispatch(openCloseOutDialog(res));
    }
  );
};

export const closeOutContract = (contractId, data) => (dispatch, getState) => {
  dispatch(isLoading('dialog'));
  const { token } = getState().main.user;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`close/`);
  contract.post(data, token).then(res => {
  dispatch(forceStopLoader());
  let reasons = get(res, 'reasons', undefined);
  let result = get(res, 'result', undefined);
  if (!result && !res.errors) {
    reasons = '<li>' + reasons.join('</li><li>');
    alertifyjs.alert(
      'Permission Denied',
      `<div><p>You cannot close out this Contract because:</p><ul>${reasons}</ul></div>`,
      () => { }
    );
  } else if (isEmpty(reasons) && result) {
    alertifyjs.success('Contract Closed Out Successfully', 3);
    window.location.reload();
  }
});
};

const isContractCreationRestrictionApplicable = (contractObj, contractCreationRestriction) => {
  return (
    isBuyerContractCreationRestrictionApplicable(contractObj, contractCreationRestriction) ||
    isSellerContractCreationRestrictionApplicable(contractObj, contractCreationRestriction)
  )
}

export const isBuyerContractCreationRestrictionApplicable = (contractObj, contractCreationRestriction) => {
  const currentUserCompanyId = currentUserCompany().id
  const buyerCompanyId = get(contractObj, 'buyer.company.id')|| contractObj.buyerCompanyId
  const purchaseContractCreationRestriction = get(contractObj, 'buyer.company.purchaseContractCreationRestriction') || contractObj.buyerPurchaseContractCreationRestriction

  return (
    buyerCompanyId != currentUserCompanyId &&
    purchaseContractCreationRestriction == contractCreationRestriction
  )
}

export const isSellerContractCreationRestrictionApplicable = (contractObj, contractCreationRestriction) => {
  const currentUserCompanyId = currentUserCompany().id
  const sellerCompanyId = get(contractObj, 'seller.company.id') || contractObj.sellerCompanyId
  const saleContractCreationRestriction = get(contractObj, 'seller.company.saleContractCreationRestriction') || contractObj.sellerSaleContractCreationRestriction

  return (
    sellerCompanyId != currentUserCompanyId &&
    saleContractCreationRestriction == contractCreationRestriction
  )
}

export const canContractBeMarkCompleted = (event, contractId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=mark_complete`);
  contract.get(token).then(res => {
    let reasons = get(res[0], 'cannotMarkCompleteReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot mark this contract complete because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      alertifyjs.confirm(
        'Warning',
        'It is highly recommended to create Freight Movements/Title Transfers from this contract and mark them complete. Contracts will be automatically completed once all the freight movements and title transfers linked with the contract are completed. Do you want to manually mark the contract complete without completing all the freight movements and title transfers?',
        () => {
          window.location = `#/freights/movements/new?commodityContractId=${contractId}&manualComplete=True`;
        },
        () => { }
      );
    }
    event.preventDefault();
  });
};

export const canContractBeAmended = (event, contractId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=can-amend`);
  contract.get(token).then(res => {
    let reasons = get(res[0], 'cannotRaiseAmendRequestReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot amend this Contract because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      window.location = `#/contracts/${contractId}/edit`;
    }
    event.preventDefault();
  });
};

export const canCreateCommodityContractInvoice = (event, contractId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=commodity-contract-invoice`);
  contract.get(token).then(res => {
    let reasons = get(res[0], 'cannotCreateCommodityContractInvoiceReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot create Commodity Contract Invoice because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      window.location.hash = `#/invoices/new?commodityContractId=${contractId}`;
    }
    event.preventDefault();
  });
};

export const canCreateBrokerageInvoice = (event, contractId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const contract = APIService.contracts(contractId);
  contract.appendToUrl(`action-options/?type=brokerage-invoice`);
  contract.get(token).then(res => {
    let reasons = get(res[0], 'cannotCreateBrokerageInvoiceReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot create Brokerage Invoice because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      window.location.hash = `#/invoices/brokerage/new?commodityContractId=${contractId}`;
    }
    event.preventDefault();
  });
};

export const freightAddForCreateableMovement = (contractId, status) => (dispatch, getState) => {
  const token = getState().main.user.token;
  if (status.toLowerCase() != 'delivered') {
    APIService.freights().orders().appendToUrl(`minimal/movement-creatable/?commodity_contract_id=${contractId}`).get(token)
      .then(response => {
        if (response.length > 0) {
          dispatch(freightMovementWarning(true, response, contractId));
        }
        else {
          dispatch(canCreateMovementForContract(contractId));
        }
      });
  }
  else {
    dispatch(canCreateMovementForContract(contractId));
  }
};

export const canVoidTitleTransfer = (item, isDuplicated=false) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.contracts().appendToUrl(`title-transfers/${item.id}/void/`)
  .get(token).then(res => {
    let reasons = get(res, 'reasons');
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot Void this Title Transfer because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      dispatch(voidTitleTransferDialog(true, item));
      if(isDuplicated){
        dispatch(updateDuplicateTitleTransferId(item.id))
      }
    }
});
};

export const downloadTitleTransferPDF = item => {
  let queryParams = '?download_pdf=true';
  APIService.contracts().appendToUrl('title-transfer/'+ get(item, 'id')+'/'+queryParams).requestUsingFetch('GET')
  .then(response => {
    if(isEqual(get(response, 'status'), 200))
      return response.blob()
  })
  .then(response => {
    if(response) {
      const fileName = 'Title_Transfer' +  moment().utc() + '.pdf';
      downloadFileFromBlob(response, fileName);
    }
  })
}

export const handleContractOptionsMapper = (event, item, baseEntity, mappingFrom = 'ContractsTable') => dispatch => {
  const fromListing = mappingFrom === 'ContractsTable';
  dispatch(setClickedOption(item));
  dispatch(contractActionOptions([]));
  dispatch(setSelectedContractId(null));
  dispatch(canCreateCOG(null));
  dispatch(canCreateTitleTransferForContractId(null));
  if(fromListing)
    dispatch(receiveContract(null));
  if (item.key === 'view_in_new_tab') {
    const url = `#/contracts/${baseEntity.id}/contract`;
    openURLInNewTab(url);
  } else if (item.key === 'freight_orders_add') {
    dispatch(canCreateOrderForContract(baseEntity.id));
  } else if (item.key === 'vendor_dec_add_contract'){
    dispatch(setSelectedContractId(baseEntity.id));
    if(fromListing){
        dispatch(shouldFetchContract(true));
    }
  } else if (item.key === 'vendor_dec_view_contract'){
    dispatch(setSelectedContractId(baseEntity.id));
  } else if (item.key === 'vendor_dec_request_contract'){
    dispatch(setSelectedContractId(baseEntity.id));
    if(fromListing){
      dispatch(shouldFetchContract(true));
    }
  } else if (item.key === 'freight_orders_view') {
    document.location.hash = `#/contracts/${baseEntity.id}/orders/freights`;
  } else if (item.key === 'call_on_grain_orders_add') {
    dispatch(canCreateCOGForContract(baseEntity.id, fromListing, () => {
      dispatch(setSelectedContractId(baseEntity.id));
      if(fromListing)
        dispatch(shouldFetchContract(true));
    }));
  } else if (item.key === 'call_on_grain_orders_view') {
    document.location.hash = `#/contracts/${baseEntity.id}/orders/grain`;
  } else if (item.key === 'freight_movements_add') {
    dispatch(freightAddForCreateableMovement(baseEntity.id, baseEntity.statusDisplayName));
  } else if (item.key === 'freight_movements_view') {
    document.location.hash = `#/contracts/${baseEntity.id}/movements`;
  } else if (item.key === 'title_transfers_add') {
    dispatch(canCreateTitleTransfer(baseEntity.id, () => {
      dispatch(setSelectedContractId(baseEntity.id));
      if(fromListing)
        dispatch(shouldFetchContract(true));
    }));
  } else if (item.key === 'title_transfers_view') {
    document.location.hash = `#/contracts/${baseEntity.id}/title-transfers`;
  } else if (item.key === 'invoices') {
    dispatch(getContractActionOptions(baseEntity.id, 'invoice'));
  } else if (item.key === 'invoices_add_commodity_contract_invoice') {
    dispatch(canCreateCommodityContractInvoice(event, baseEntity.id));
  } else if (item.key === 'invoices_view') {
    document.location.hash = `#/contracts/${baseEntity.id}/invoices`;
  } else if (item.key === 'invoices_add_brokerage_invoice') {
    dispatch(canCreateBrokerageInvoice(event, baseEntity.id));
  } else if (item.key === 'download_pdf') {
    dispatch(getContractActionOptions(baseEntity.id, 'download'));
  } else if (item.key === 'download_buyer_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'download_seller_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'download_broker_pdf' && item.url) {
    openURLInNewTab(item.url);
  } else if (item.key === 'amend') {
    dispatch(canContractBeAmended(event, baseEntity.id));
  } else if (item.key === 'void') {
    dispatch(canContractBeVoided(event, baseEntity.id, () => {
      dispatch(setSelectedContractId(baseEntity.id));
    }));
  } else if(item.key === 'duplicate') {
    if(canContractBeDuplicated(baseEntity)){
      let queryParams = `copyFrom=${baseEntity.id}`
      document.location.hash = `#/contracts/new?${queryParams}`;
    }
  } else if (item.key === 'void_and_duplicate') {
    dispatch(canContractBeVoidedAndDuplicated(event, baseEntity, () => {
      dispatch(setSelectedContractId(baseEntity.id));
    }));
  }
    else if (item.key === 'close_out_contract') {
      if(fromListing)
        dispatch(shouldFetchContract(true));
      dispatch(setSelectedContractId(baseEntity.id));
      dispatch(canCloseOutForContract(false));
      dispatch(canContractBeClosedOut(baseEntity.id));
  } else if (item.key === 'mark_complete') {
    dispatch(canContractBeMarkCompleted(event, baseEntity.id));
  } else if (item.key === 'review_amendment') {
    if(fromListing)
      dispatch(shouldFetchContract(true));
    dispatch(setSelectedContractId(baseEntity.id));
    dispatch(shouldOpenReviewAmendmentDrawer(true));
    dispatch(isLoading('contractAmendReviewForm'));
  } else if (item.key === 'mark_as_paid') {
    dispatch(canMarkAsPaid(true));
    dispatch(setSelectedContractId(baseEntity.id));
  } else if (item.key === 'show_hierarchy') {
    dispatch(setSelectedContractId(baseEntity.id));
  } else if(item.key === 'invoiced_outside'){
      dispatch(setSelectedContractId(baseEntity.id));
  }
};
