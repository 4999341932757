import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import CrossCircle from "@mui/icons-material/Cancel";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import CommonTextField from "../common/CommonTextField";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import CommonButton from "../common/CommonButton";
import Dialog from "@mui/material/Dialog/Dialog";
import isEmpty from "lodash/isEmpty";
import { MESSAGES } from "../../common/validators";
import get from "lodash/get";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export const RejectionReasonDialog = (props) => {
    const [error, setError] = useState({
      errorText: "",
      error: false
    });

    let handleSubmit = event => {
        if (isEmpty(props.value) && get(props, 'required', false)){
          setError({error: true, errorText: MESSAGES.REQUIRED});
          return ;
        }
        setError({error: false, errorText: ""});
        props.onReject(event);
    };

    return(<div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        id='rejection-dialog'
      >
        <DialogTitle id="form-dialog-title">
          {props.dontShowIcon ? '' : <CrossCircle style={{ fill: '#FF0000', height: '35px', width: '35px', verticalAlign: 'middle' }} />} {props.title}
        </DialogTitle>
        <DialogContent>
          {props.showLoadSelector ?
          <RadioGroup
              style={{display: 'inline-block'}}
              value={props.loadsSelectorValue || "all"}
              onChange={props.onChangeLoadSelection}
              name="loadsSelector"
              id="loadsSelector">
              <FormControlLabel
                value="all"
                control={<Radio color='primary' />}
                label={props.loadSelectorLabel[0]}
                labelPlacement="end"
              />
              <FormControlLabel
                value="selected"
                control={<Radio />}
                label={props.loadSelectorLabel[1]}
                labelPlacement="end"
              />
          </RadioGroup>: null}
          <CommonTextField
            id="rejectionReason"
            label="Reason"
            placeholder={props.placeholder || "Please enter your reason for rejection"}
            value={props.value}
            onChange={props.onChange}
            helperText={props.helperText || error.errorText}
            errorStyle={{textAlign: "left"}}
            fullWidth
            maxLength="255"
            multiline
            error={error.error}
            rows={7}
          />
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <CommonButton onClick={props.onCancel} key='cancel' label='Cancel' secondary variant="contained" />
            <CommonButton onClick={handleSubmit} key='reject' label={props.submitText || 'Reject'} primary variant="contained" />
          </DialogActions>
        </DialogActions>
      </Dialog>
    </div>);
};

