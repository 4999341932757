import React from 'react';

import Parties from '../../companies/Parties'

const InvoicePartiesNewView = ({ invoice }) => {
  return (
    <Parties
      parties={[
        {...invoice?.payee, sectionName: invoice?.payeeSectionName, name: invoice?.payeeDisplayName},
        {...invoice?.payer, sectionName: invoice?.payerSectionName, name: invoice?.payerDisplayName},
      ]}
    />
  );
}

export default InvoicePartiesNewView;
