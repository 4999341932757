/*eslint no-process-env: 0*/
import React from 'react';
import alertifyjs from 'alertifyjs';

import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import NewIcon from '@mui/icons-material/FiberNew';
import DocketIcon from '@mui/icons-material/LinkedCamera';
import IconButton from '@mui/material/IconButton';
import NavigationExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import HistoryIcon from '@mui/icons-material/ChangeHistoryOutlined';
import { Link } from 'react-router-dom';
import { logout, setDownloadBar, switchToSysAdmin } from '../../actions/main/index';
import {
  isCurrentUserRegisteredGrower, initials, isSystemCompany, toggleSpotLight, toggleHistoryPanel,
  isObserver, redirectToFreshdesk, STOCK_UPLOAD_DATA_TYPES, STOCK_UPLOAD_DATA_OF, isStaff, isCurrentUserCompanyPlanLite,
  showTargetMoistureTab, getCountryConfig, isCompanyAdminOrObserver, currentUser
} from '../../common/utils';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import SearchIcon from '@mui/icons-material/Search';
import './userSettings.scss';
import SideDrawer from '../common/SideDrawer';
import ReleaseNotesView from '../release-notes/ReleaseNotesView';
import Notifications from './Notifications';
import APIService from '../../services/APIService';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { FIELD, FRESHDESK_REDIRECT_URL } from '../../common/constants';
import { get, set, cloneDeep, map, isEqual, isEmpty, some, forEach } from 'lodash';
import { getCompanyNames } from '../../actions/api/companies';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { required } from '../../common/validators';
import CountrySwitch from '../common/CountrySwitch';
import UnitSwitch from '../common/UnitSwitch';
import FreshDeskWidget from '../common/FreshDeskWidget';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import jwt from 'jwt-simple';

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: null,
      newReleaseIconColor: 'secondary',
      releaseNotes: false,
      isStocksUploadDialog: false,
      stocksUpload: {
        dataType: cloneDeep(FIELD),
        dataOf: cloneDeep(FIELD),
        company: cloneDeep(FIELD)
      }
    };

    this.closeReleaseNotes = this.closeReleaseNotes.bind(this);
    this.openReleaseNotes = this.openReleaseNotes.bind(this);
    this.openSpotlight = this.openSpotlight.bind(this);
    this.logout = this.logout.bind(this);
    this.switchBackToSysAdmin = this.switchBackToSysAdmin.bind(this);
    this.handleStocksUploadChange = this.handleStocksUploadChange.bind(this);
    this.exportStocksDataPopup = this.exportStocksDataPopup.bind(this);
    this.exportStocksData = this.exportStocksData.bind(this);
    this.validateStocksUpdateData = this.validateStocksUpdateData.bind(this);
    this.resetStocksUploadValues = this.resetStocksUploadValues.bind(this);
  }

  logout() {
    const { user } = this.props;
    if(get(user, 'typeId') === 10) { // observer
      APIService.profiles().appendToUrl('logout/').post().then(() => {this._logout();});
    } else this._logout();
  }

  _logout() {
    this.props.logout();
    window.location.hash = '/';
  }

  handleClick = event => {
    this.setState({ menuOpen: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ menuOpen: null });
  };

  closeReleaseNotes() {
    this.setState({ releaseNotes: false });
  }

  openReleaseNotes() {
    this.setState({ releaseNotes: true, newReleaseIconColor: 'secondary' }, () => {
      const versionDetails = JSON.parse(localStorage.getItem(this.props.version));
      if (versionDetails) {
        versionDetails.read = true;
        localStorage.setItem(this.props.version, JSON.stringify(versionDetails));
      }
    });
  }

  handleStocksUploadChange(field, value) {
    const newState = this.state;
    if(isEqual(field, 'dataType') && isEqual(value, 'commodity')) {
      set(newState, `stocksUpload.dataOf.validators`, []);
      set(newState, `stocksUpload.company.validators`, []);
    } else {
      set(newState, `stocksUpload.dataOf.validators`, [required()]);
      set(newState, `stocksUpload.company.validators`, [required()]);
    }
    set(newState, `stocksUpload.${field}.value`, value);
    this.setState(newState);
  }

  openSpotlight() {
    toggleSpotLight();
  }

  switchBackToSysAdmin() {
    localStorage.setItem('stocks', JSON.stringify({}));
    localStorage.removeItem('handlers');
    localStorage.removeItem('handlersUpdatedAt');
    switchToSysAdmin(this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    const versionDetails = JSON.parse(localStorage.getItem(this.props.version));
    if (this.props.version && prevProps.version !== this.props.version && !versionDetails.read) {
      this.setState({ newReleaseIconColor: 'primary' });
    }
  }

  exportStocksDataPopup() {
    if(isEmpty(this.props.companyNames)) {
      this.props.getCompanyNames();
    }
    this.setState({ isStocksUploadDialog: !this.state.isStocksUploadDialog }, () => {
      this.resetStocksUploadValues();
    });
  }

  getFieldErrors(fieldPath) {
    let field = get(this.state, `${fieldPath}`)
    return some(field.validators, (validator) => {
      return validator.isInvalid(field.value);
    });
  }

  validateStocksUpdateData() {
    var isInvalid = false;
    let stockUpload = this.state.stocksUpload;
    if(isEmpty(stockUpload.dataType.value)) {
      isInvalid = true;
    } else {
      map(['stocksUpload.dataOf', 'stocksUpload.company'], field => {
        isInvalid = this.getFieldErrors(field)
        if(isInvalid)
          return
      })
    }
    return isInvalid;
  }

  resetStocksUploadValues() {
    const newState = { ...this.state };
    set(newState, 'stocksUpload.dataType.value', '');
    set(newState, 'stocksUpload.dataOf.value', '');
    set(newState, 'stocksUpload.company.value', '');
    this.setState(newState);
  }

  exportStocksData(event) {
    event.preventDefault();
    if(!this.validateStocksUpdateData()) {
      let dataType = get(this.state, 'stocksUpload.dataType.value')
      let queryParams = `?dataType=${dataType}`;
      if(!isEqual(dataType, 'commodity')) {
        queryParams = queryParams + `&dataOf=${get(this.state, 'stocksUpload.dataOf.value')}`;
        queryParams = queryParams + `&company=${get(this.state, 'stocksUpload.company.value')}`;
      }
      APIService.companies().appendToUrl(`download-stocks-upload/${queryParams}`).get()
      this.props.dispatch(setDownloadBar('Your Stocks Upload CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
      this.exportStocksDataPopup();
    } else {
      alertifyjs.error("Please fill required data to download stocks upload data");
    }
  }

  /* eslint-disable spellcheck/spell-checker */
  /*eslint no-undef: 0*/
  handleClickFreshdeskButton = () => {
    if (typeof window.FreshworksWidget === 'function') {
      FreshworksWidget("open");
      setTimeout(this.applyFreshdeskStyles, 20);
    }
  }

  generateToken = user => {
    if (!user || !process.env.RSA_PRIVATE_SSH_KEY)
      return null;
    let url = new URLSearchParams(window.location.search)
    let payload = {
      sub: user.id.toString(),
      FirstName: user.firstName,
      LastName: user.lastName,
      email: user.email,
      nonce: url.get('nonce'),
    }

    try {
      const encode = jwt.encode || jwt().encode
      return encode(payload, process.env.RSA_PRIVATE_SSH_KEY, "RS256");
    } catch (err) {
      return null;
    }
  };

  redirectToFreshdeskSSO = user => {
    const token = this.generateToken(user);

    if (token) {
      let url = new URLSearchParams(window.location.search)
      let state = url.get('state')
      const redirectUrl = `${FRESHDESK_REDIRECT_URL}?state=${state}&id_token=${token}`;
      window.open(redirectUrl, '_blank');
    }
  };

  handleArticleClick = () => {
    const user = currentUser();
    if (user)
      this.redirectToFreshdeskSSO(user);
  };

  addArticleClickListener(widgetFrameDoc, article) {
    article.addEventListener('click', () => {
      setTimeout(() => {
        const articleFrame = widgetFrameDoc.querySelector('#article-frame');
        const articleFrameDoc = articleFrame?.contentDocument || articleFrame?.contentWindow.document;

        setTimeout(() => {
          const articleLink = articleFrameDoc?.querySelector('.article-link');
          articleLink?.addEventListener('click', () => this.handleArticleClick());
        }, 500);
      }, 500);
    });
  }

  setEventListenersForArticles(widgetFrameDoc) {
    setTimeout(() => {
      const articleList = widgetFrameDoc?.querySelector('ul.kcVqPn');
      if (articleList) {
        const articleItems = articleList.querySelectorAll('li.czAucD');
        forEach(articleItems, article => this.addArticleClickListener(widgetFrameDoc, article));
      }
    }, 1000);
  }

  applyFreshdeskStyles = () => {
    const formElement = document?.querySelector('#freshworks-frame-wrapper');
    if (formElement) {
      formElement.style.position = 'absolute';
      formElement.style.top = '70px';
      formElement.style.setProperty('right', '1vw', 'important');
      formElement.style.setProperty('left', 'auto', 'important');
      formElement.style.zIndex = '9999';

      const widgetFrame = document.querySelector('#widget-frame');
      const widgetFrameDoc = widgetFrame?.contentDocument || widgetFrame?.contentWindow.document;

      if (widgetFrameDoc)
        this.setEventListenersForArticles(widgetFrameDoc);
    } else
      setTimeout(this.applyFreshdeskStyles, 100);
  };

  render() {
    const { newReleaseIconColor, releaseNotes, menuOpen } = this.state;
    const { companyId, user } = this.props;
    const companyNames = this.props.companyNames;
    const username = initials(user);
    let SETTINGS_URL_PREFIX = null;
    let menuItems = [];
    let FarmTab = null;
    let ngrTab = null;
    const COMPANY_SETTINGS_URL_PREFIX = '/companies/' + companyId;
    const countryConfig = getCountryConfig()


    SETTINGS_URL_PREFIX = COMPANY_SETTINGS_URL_PREFIX;
    menuItems = [
      { text: 'My Profile', url: '/profiles/details' },
      { text: 'Company Details', url: SETTINGS_URL_PREFIX + '/details?details' },
      { text: 'Employees', url: SETTINGS_URL_PREFIX + '/employees?employees' },
      { text: 'Trucks', url: SETTINGS_URL_PREFIX + '/trucks?trucks' },
    ];

    if (get(user.company, 'platformfeatures.planType') === 'premium') {
      menuItems = menuItems.concat([
        { text: 'Sites', url: SETTINGS_URL_PREFIX + '/company-sites?sites' }
      ]);
      (ngrTab = { text: 'NGRs', url: SETTINGS_URL_PREFIX + '/ngrs?ngrs' }), (FarmTab = { text: 'Farms', url: SETTINGS_URL_PREFIX + '/farms?farms' });
      menuItems.splice(3, 0, ngrTab);
      if (isCurrentUserRegisteredGrower()) {
        menuItems.splice(2, 0, FarmTab);
        menuItems = menuItems.filter(menu => menu.text !== 'Sites');
      }
    }

    if (isSystemCompany()) {
      menuItems.push({ text: 'Release Notes', url: '/release-notes' });
      menuItems.push({ text: 'Mobile Messages', url: '/mobile-messages' });
      menuItems.push({ text: 'Dockets', url: '/dockets', icon: <DocketIcon color="primary" /> });
      menuItems.push({ text: 'Export Stocks Upload Data', onClick: this.exportStocksDataPopup });
    }

    if (!isCurrentUserCompanyPlanLite()){
      if (showTargetMoistureTab())
        menuItems.push({ text: 'Target Moisture', url: SETTINGS_URL_PREFIX + '/target-moisture' });
      else
        menuItems.push({ text: 'Shrinkages', url: SETTINGS_URL_PREFIX + '/shrinkages' });
      menuItems.push({ text: 'Fees', url: SETTINGS_URL_PREFIX + '/fees' });
    }

    if (get(user.company, 'platformfeatures.planType') === 'premium') {
      menuItems = menuItems.concat([
        { text: 'Conditions', url: SETTINGS_URL_PREFIX + '/conditions?conditions' }]);
    }


    if (!isCurrentUserCompanyPlanLite() && (isCompanyAdminOrObserver() || isSystemCompany()))
      menuItems = menuItems.concat([
        { text: 'Alerts', url: SETTINGS_URL_PREFIX + '/alerts' }]);

    menuItems.push({ text: 'Integrations', url: `${COMPANY_SETTINGS_URL_PREFIX}/integrations`, icon: <NewIcon color="primary" /> });
    menuItems.push({ text: 'Downloads', url: '/downloads' });
    menuItems.push({text: 'Templates', url: '/profiles/templates/contract'})

    return (
      <React.Fragment>
        { window.FRESHDESK_TOGGLE && <FreshDeskWidget /> }
        <Toolbar className='user-info col-sm-2 col-md-2'>
          {
            countryConfig?.showConversions &&
              <span style={{marginRight: '15px'}}>
                <UnitSwitch useCountryUnits size='large' sx={{border: 'none'}} />
              </span>
          }
          {
            isStaff() &&
              <span style={{marginRight: '15px'}}>
            <CountrySwitch />
          </span>
          }
          {
            isObserver() &&
            <Tooltip title='Switch to System Admin'>
              <IconButton
                className='new-releases-button'
                onClick={this.switchBackToSysAdmin}
                size="large">
                <DonutSmallIcon color='primary' />
              </IconButton>
            </Tooltip>
          }
          {
            isStaff() &&
              <Tooltip title='History Panel'>
                <IconButton className='new-releases-button' onClick={toggleHistoryPanel} size="large">
                  <HistoryIcon color='primary' />
                </IconButton>
              </Tooltip>
          }
          <Tooltip title='Global Search'>
            <IconButton className='new-releases-button' onClick={this.openSpotlight} size="large">
              <SearchIcon color='primary' />
            </IconButton>
          </Tooltip>
          <Tooltip title='New Releases'>
            <IconButton
              className='new-releases-button'
              onClick={this.openReleaseNotes}
              size="large">
              <NewReleasesIcon color={newReleaseIconColor} />
            </IconButton>
          </Tooltip>
          <SideDrawer isOpen={releaseNotes} title="What's New" size='big' onClose={this.closeReleaseNotes}>
            <ReleaseNotesView />
          </SideDrawer>
          { window.FRESHDESK_TOGGLE &&
            <Tooltip title='Help'>
            <IconButton
              className='new-releases-button'
              onClick={this.handleClickFreshdeskButton}
              size="large">
              <HelpOutlinedIcon color='primary' />
            </IconButton>
            </Tooltip>
          }
          <Notifications />
          <Tooltip title='Profile'>
            <IconButton
              touch='true'
              className={'user-info-icon'}
              onClick={this.handleClick}
              size="large">
              {username}
              <NavigationExpandMoreIcon color='primary' />
            </IconButton>
          </Tooltip>
          <Menu id='settings-menu' anchorEl={menuOpen} open={Boolean(menuOpen)} onClose={this.handleClose}>
            {
              menuItems.map((item, index) => {
              return (
                item.url ?
                <Link to={item.url} key={item.url} onClick={this.handleClose} className='no-link'>
                  <MenuItem>
                    {item.text}
                    {item.icon && <span style={{marginLeft: '10px', marginTop: '5px'}}>{item.icon}</span>}
                  </MenuItem>
                </Link> :
                <MenuItem onClick={item.onClick} key={index}>
                    {item.text}
                  </MenuItem>
              );
            })}
            <MenuItem onClick={redirectToFreshdesk}>Help / FAQ</MenuItem>
            <MenuItem onClick={this.logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        {
          this.state.isStocksUploadDialog &&
            <Dialog
              open={this.state.isStocksUploadDialog}
              onClose={this.exportStocksDataPopup}
              aria-labelledby="form-dialog-title"
              id="complete-dialog-open"
              fullWidth>
              <DialogTitleWithCloseIcon
                onClose={this.exportStocksDataPopup}
                closeButtonStyle={{ color: "#FFF" }}
              >
                Export Stocks Upload
              </DialogTitleWithCloseIcon>
              <DialogContent style={{ borderBottom: ' 1px solid #e6e6e6', padding: '16px' }}>
                <form onSubmit={this.exportStocksData}>
                  <div className='cardForm cardForm--drawer'>
                    <div className='cardForm-content row'>
                      <div className='col-sm-12 form-wrap' style={{minHeight: '50px'}}>
                        <CommonAutoSelect
                          id='dataType'
                          label="Data Type"
                          name='dataType'
                          variant="outlined"
                          items={STOCK_UPLOAD_DATA_TYPES}
                          value={this.state.stocksUpload.dataType.value}
                          onChange={(value) => this.handleStocksUploadChange('dataType', value)}
                        />
                      </div>
                      <div className='col-sm-12 form-wrap' style={{minHeight: '50px', marginTop: '20px'}}>
                        <CommonAutoSelect
                          id='dataOf'
                          label="Data Of"
                          name='dataOf'
                          variant="outlined"
                          items={STOCK_UPLOAD_DATA_OF}
                          value={this.state.stocksUpload.dataOf.value}
                          onChange={(value) => this.handleStocksUploadChange('dataOf', value)}
                          disabled={isEqual(this.state.stocksUpload.dataType.value, 'commodity')}
                        />
                      </div>
                      <div className='col-sm-12 form-wrap' style={{minHeight: '50px', marginTop: '20px'}}>
                        <CommonAutoSelect
                          id='company'
                          label="Company"
                          name='company'
                          variant="outlined"
                          items={companyNames}
                          fullWidth
                          onChange={(value) => this.handleStocksUploadChange('company',  value)}
                          value={this.state.stocksUpload.company.value}
                          disabled={isEqual(this.state.stocksUpload.dataType.value, 'commodity')}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button type='button' onClick={this.exportStocksDataPopup} variant="outlined" color="primary" >
                  Cancel
                </Button>
                <Button type='button' onClick={this.exportStocksData} variant="contained" color="primary" >
                  Export
                </Button>
              </DialogActions>
            </Dialog>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.main.user.user.companyId,
  user: state.main.user.user,
  version: state.main.version,
  companyNames: state.companies.companies.names
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  getCompanyNames: () => dispatch(getCompanyNames()),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
