import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { required, valueAbove, minValue, MESSAGES } from '../../common/validators';
import CommonButton from '../common/CommonButton';
import alertifyjs from 'alertifyjs';
import CommonTextField from '../common/CommonTextField';
import AutoComplete from '../common/autocomplete/AutoComplete';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import CommonDatePicker from '../common/CommonDatePicker';
import QuickContract from '../common/TemplateSearch';
import { Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import {
  TextField, InputAdornment, Button
} from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import {
  get, find, isEqual, forEach, isEmpty, some, mapValues, map, includes, without, omit,
  pick, keys, uniq, filter, set, has, values, compact, uniqBy, isNumber, sum, isString
} from 'lodash';
import {
  generateIdentifier, isCurrentUserBroker, getAutoSelectFocusField, getFreshdeskURL, currentUserCompany, isCompanyGrower,
  getSavedHandlers
} from '../../common/utils';
import { getLoadReferences, getSpecColumns, loadReferencesDisplay } from '../stocks/utils';
import APIService from '../../services/APIService';
import {
  COMMODITIES,
  DELIVERY_MANDATORY_PRICE_POINTS,
  PICKUP_MANDATORY_PRICE_POINTS,
  GROWER,
  MT_UNIT,
  MAX_YEAR,
  MIN_YEAR,
  SYSTEM_COMPANY_IDS,
  MIN_SEARCH_LENGTH
} from '../../common/constants';
import { isLoading, forceStopLoader } from '../../actions/main';
import Notes from "../common/Notes";
import CustomEmailDialog from '../common/CustomEmailDialog';
import { getCompanyCompaniesMinimal } from "../../actions/api/companies";
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import SeasonSelect from '../common/select/SeasonSelect';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import GenericTable from '../GenericTable';
import { canCreateTitleTransferForContractId } from '../../actions/companies/contracts';
import NumberField from '../common/NumberField';

class TitleTransferForm extends Component {
  constructor(props) {
    super(props);
    this.isBroker = isCurrentUserBroker();
    this.EXCESS_TONNAGE_ERROR = 'Tonnage can not be more than Contract tonnage.';
    this.EXCESS_QUANTITY_ERROR = 'Volume can not be more than Contract volume.';
    this.EXCESS_AVAILABLE_STOCK_ERROR = 'Not enough stocks at the site.';
    this.selectedContract = undefined;
    this.isFetchingSettings = false
    this.state = {
      settings: null,
      contractDetailsDialog: false,
      tableData: [],
      sellerTonnageAvailable: undefined,
      managedCompanies: [],
      fetchedCanolaLoads: false,
      isLoadingCanolaLoads: false,
      canolaLoads: [],
      selectedCanolaLoads: [],
      openCanolaLoads: false,
      grades: [],
      sites: [],
      handlers: [],
      buyerNgrs: [],
      sellerNgrs: [],
      emailPopupParties: [],
      showEmailDialog: false,
      buyerContacts: [],
      sellerContacts: [],
      transferSitePartyEmails: [],
      transferSite: null,
      fields: {
        season: {
          value: '',
          validators: [required()],
          errors: [],
        },
        commodityId: {
          value: '',
          validators: [required()],
          errors: [],
        },
        seller: {
          contactId: {
            value: '',
            validators: [required()],
            errors: [],
          },
          companyId: {
            value: '',
            validators: [required()],
            errors: [],
          },
          ngrId: {
            value: '',
            validators: [required()],
            errors: [],
          },

        },
        buyer: {
          contactId: {
            value: '',
            validators: [required()],
            errors: [],
          },
          companyId: {
            value: '',
            validators: [required()],
            errors: [],
          },
          ngrId: {
            value: '',
            validators: [required()],
            errors: [],
          },

        },
        handlerId: {
          value: '',
          validators: [required()],
          errors: [],
        },
        processOn: {
          value: moment().format('YYYY-MM-DD'),
          validators: [required()],
          errors: [],
        },
        quantity: {
          value: undefined,
          validators: [],
          errors: [],
        },
        tonnage: {
          value: '',
          validators: [required(), valueAbove(0)],
          errors: [],
        },
        identifier: {
          value: generateIdentifier('title_transfer'),
          validators: [required()],
          errors: [],
        },
        varietyId: {
          value: '',
          validators: [],
          errors: [],
        },
        gradeId: {
          value: '',
          validators: [required()],
          errors: [],
        },
        externalReferenceNumber: {
          value: '',
          validators: [],
          errors: [],
        },
        commodityContractId: {
          value: '',
          validators: [],
          errors: [],
        },
        referenceNumber: {
          value: '',
          validators: [],
          errors: [],
        },
        coil: {
          value: null,
          validators: [],
          errors: [],
        },
        impu: {
          value: null,
          validators: [],
          errors: [],
        },
        note: {
          description: '',
          attachments: [],
          companyId: this.props.currentUser.companyId,
          errors : []
        },
      },
    };

    this.isDuplicatedTitleTransfer = Boolean(this.props.copyFrom)
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setPartyFieldErrors = this.setPartyFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHandlerChange = this.handleHandlerChange.bind(this);
    this.handleBuyerNgrChange = this.handleBuyerNgrChange.bind(this);
    this.handleSellerNgrChange = this.handleSellerNgrChange.bind(this);
    this.handleProcessOnChange = this.handleProcessOnChange.bind(this);
    this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.getPartyFieldErrors = this.getPartyFieldErrors.bind(this);
    this.setTonnageError = this.setTonnageError.bind(this);
    this.getDisabledPartiesForEmail = this.getDisabledPartiesForEmail.bind(this);
    this.isLaterDisabled = this.isLaterDisabled.bind(this);
    this.fetchTitleTransfer = this.fetchTitleTransfer.bind(this);
    this.populateTitleTransfer = this.populateTitleTransfer.bind(this);
    this.fieldsOrder = [
      'identifier', 'seller.companyId', 'seller.contactId', 'seller.ngrId',
      'buyer.companyId', 'buyer.contactId', 'buyer.ngrId', 'commodityId', 'varietyId',
      'gradeId', 'seasonId', 'tonnageId', 'processOn', 'handlerId',
      'coil', 'impu'
    ];
    this.fieldRef = {};

    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.overdraftWarningText = '';
    const { dispatch } = this.props;
    dispatch(isLoading());
  }

  fetchHandlers = ids => {
    APIService.contracts().handlers().get(null, null, {handler_ids: ids}).then(res => {
      let items = res?.handlers || []
      items = uniqBy([...this.state.handlers, ...items], 'id')
      let transferSite = this.state.transferSite
      if(this.props.stock?.farmId && ids && ids?.toString()?.includes(this.props.stock.farmId.toString()) && !this.state.transferSite?.id) {
        transferSite = items?.find(item => item?.id === this.props.stock.farmId)
      }
      this.setState({handlers: items, transferSite: transferSite})
    })
  }

  componentDidMount() {
    this.fetchManagedCompanies();
    this.props.dispatch(getCompanyCompaniesMinimal(this.props.currentUser.companyId, { include_parent_company: true }, null, true));
    const { contract, stock } = this.props;
    if (stock) {
      const newState = { ...this.state };
      if (stock.commodityId === COMMODITIES.CANOLA) {
        newState.fields.coil.validators = [required()];
        newState.fields.impu.validators = [required()];
      } else {
        newState.fields.coil.validators = [];
        newState.fields.coil.errors = [];
        newState.fields.impu.validators = [];
        newState.fields.impu.errors = [];
      }
      newState.fields.seller.ngrId.value = get(stock, 'ngrId');
      newState.fields.commodityId.value = get(stock, 'commodityId');
      newState.fields.seller.contactId.value = this.props.currentUser.id;
      newState.fields.seller.companyId.value = get(stock, 'ngrCompanyId');
      newState.fields.season.value = get(stock, 'season');
      newState.fields.gradeId.value = get(stock, 'gradeId');
      newState.fields.varietyId.value = get(stock, 'varietyId');
      newState.fields.handlerId.value = get(stock, 'farmId')
      if(get(stock, 'farmId'))
        this.fetchHandlers(get(stock, 'farmId'));
      if (!isEqual(this.state, newState))
        this.setState(newState);
    }
    else if (contract) {
      this.populateTitleTransfer(contract);
    }

    if(this.props.copyFrom){
      this.fetchTitleTransfer(this.props.copyFrom)
    }
  }

  populateTitleTransfer = (entity) => {
    const { fetchVarieties } = this.props;

    fetchVarieties();
    const newState = { ...this.state };
    const spreadDetails = get(entity, 'spread.details');
    if (spreadDetails) {
      newState.grades = spreadDetails;
      newState.fields.gradeId.validators = [required()];
    }
    if (entity.commodityId === COMMODITIES.CANOLA && !(get(entity, 'paymentScale.name') == 'Flat')) {
      newState.fields.coil.validators = [required()];
      newState.fields.impu.validators = [required()];
    } else {
      newState.fields.coil.validators = [];
      newState.fields.coil.errors = [];
      newState.fields.impu.validators = [];
      newState.fields.impu.errors = [];
    }

    if(get(entity,'entity')!= "contract" && get(entity, 'status') == 'void')
      newState.fields.identifier.value = get(entity, 'identifier');

    newState.fields.seller.ngrId.value = get(entity, 'seller.ngrId');
    newState.fields.buyer.ngrId.value = get(entity, 'buyer.ngrId');
    newState.fields.commodityId.value = get(entity, 'commodityId');
    newState.fields.buyer.contactId.value = get(entity, 'buyer.contact.id') || get(entity, 'buyer.contactId');
    newState.fields.seller.contactId.value = get(entity, 'seller.contact.id') || get(entity, 'seller.contactId');
    newState.fields.buyer.companyId.value = get(entity, 'buyer.company.id') || get(entity, 'buyer.companyId');
    newState.fields.seller.companyId.value = get(entity, 'seller.company.id') || get(entity, 'seller.companyId');
    newState.fields.season.value = get(entity, 'season');
    newState.fields.gradeId.value = get(entity, 'gradeId');
    newState.fields.handlerId.value = get(entity, 'site.id');

    if(this.props.copyFrom){
      newState.fields.tonnage.value = get(entity, 'tonnage');
      newState.fields.note.description = get(entity, 'note.description');
      if(get(entity, 'contractNumber')){
        newState.fields.externalReferenceNumber.value = get(entity, 'contractNumber');
      }

      if (COMMODITIES.CANOLA == get(entity, 'commodityId')) {
        newState.fields.coil.value = get(entity, 'coil');
        newState.fields.impu.value = get(entity, 'impu');
      }
    }

    if (this.isPickupPP()) {
      newState.fields.handlerId.value = get(entity, 'consignorsWithSites[0].handlerId');
    } else if (this.isDeliveredPP()) {
      const consigneesWithSites = get(entity, 'consigneesWithSites', []);
      newState.fields.handlerId.value = get(consigneesWithSites, '0.handlerId');
    }
    if (newState.fields.handlerId.value)
      this.fetchHandlers(newState.fields.handlerId.value);
    this.setTransferSitePartyEmails(newState.fields.handlerId.value);
    newState.fields.varietyId.value = entity.varietyId;

    this.getNgrs(entity.buyer.companyId, 'buyerNgrs');
    this.getNgrs(entity.seller.companyId, 'sellerNgrs');
    if (entity) {
      this.getContactNgr(entity.seller.companyId, true, false);
      this.getContactNgr(entity.buyer.companyId, false, true);
    }
    if (entity.buyer?.ngrId) {
      newState.fields.buyer.ngrId.value = entity.buyer.ngrId;
    }
    else {
      newState.fields.buyer.ngrId.value = get(entity, 'buyer.company.titleTransferNgrId');
    }

    if (entity.seller?.ngrId) {
      newState.fields.seller.ngrId.value = entity.seller?.ngrId;
    }

    if (!isEqual(this.state, newState))
      this.setState(newState);
  }

  fetchTitleTransfer = async (titleTransferId) => {
    await APIService.contracts()
    .appendToUrl(`title-transfer/${titleTransferId}/`)
      .get(this.props.userToken, {'original-unit': true})
      .then(response => {
        this.titleTransferContract = response
        this.populateTitleTransfer(response)
      })
  }

  toggleCanolaLoads = () => {
    const open = !this.state.openCanolaLoads;
    if(open)
      this.fetchCanolaLoads();
    else
      this.setState({openCanolaLoads: open, selectedCanolaLoads: [], canolaLoads: []});
  };

  shouldFetchCanolaLoads = () => !this.state.isLoadingCanolaLoads && this.shouldSelectLoads();

  fetchCanolaLoads = () => this.setState(
    {isLoadingCanolaLoads: true, selectedCanolaLoads: [], canolaLoads: []},
    () => APIService
      .farms(this.state.fields.handlerId.value)
      .appendToUrl('loads/minimal/')
      .get(null, null, {
        commodity_id: COMMODITIES.CANOLA,
        grade_id: this.state.fields.gradeId.value,
        ngr_company_id: this.state.fields.seller.companyId.value,
        ngr_id: this.state.fields.seller.ngrId.value,
        season: this.state.fields.season.value,
        transfer: true,
      }).then(loads => {
        if(isEmpty(loads))
          this.setState(
            {isLoadingCanolaLoads: false, fetchedCanolaLoads: false},
            () => alertifyjs.error('No Loads found.')
          );
        else {
          const formattedLoads = map(loads, load => {
            load.transferrableTonnage = load.remainingTonnage;
            return load;
          });
          this.setState({canolaLoads: formattedLoads, isLoadingCanolaLoads: false, fetchedCanolaLoads: true, openCanolaLoads: true});
        }
      }));

  isDeliveredPP() {
    const { contract } = this.props;
    const pricePointId = get(contract, 'pricePointId') || get(this.selectedContract, 'pricePointId');
    return includes(
      DELIVERY_MANDATORY_PRICE_POINTS,
      pricePointId,
    );
  }

  isPickupPP() {
    const { contract } = this.props;
    const pricePointId = get(contract, 'pricePointId') || get(this.selectedContract, 'pricePointId');
    return includes(
      PICKUP_MANDATORY_PRICE_POINTS,
      pricePointId,
    );
  }

  getHandlers() {
    const { contract } = this.props;
    let handlers = this.state.handlers;
    let _mapValues = true;
    if (this.isDeliveredPP())
      handlers = contract?.consigneesWithSites ? map(contract?.consigneesWithSites, consignee => consignee?.handler) : get(this.selectedContract, 'deliverySites', [])
    else if (this.isPickupPP())
      handlers = contract?.consignorsWithSites ? map(contract?.consignorsWithSites, consignor => consignor?.handler) : get(this.selectedContract, 'pickupSites', [])
    else
      _mapValues = false;
    handlers = handlers.map(handler => {
      const correspondingStateHandler = this.state.handlers.find(stateHandler => stateHandler.id === handler.handlerId);
      return correspondingStateHandler ? { ...correspondingStateHandler, ...handler } : {...handler};
    });
    handlers = filter(handlers, handler => handler?.isActive);
    if (_mapValues)
      return map(handlers, handler => {
        return {
          id: get(handler, 'handlerId') || get(handler, 'id'),
          name: get(handler, 'handler.displayName') || get(handler, 'displayName'),
          isOverdraftAllowed: handler?.isOverdraftAllowed
        };
      });

    return handlers;
  }

  fetchManagedCompanies() {
    if (isCurrentUserBroker())
      APIService.companies().appendToUrl('managed/companies/').get().then(res => {
        this.setState({ managedCompanies: res });
      });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.serverErrors, this.props.serverErrors)) {
      const newState = { ...this.state };
      forEach(this.props.serverErrors, (value, key) => {
        var clientKey = key;
        if (key == 'sellerNgr') {
          clientKey = 'sellerNgrId';
        } else if (key == 'buyerNgr') {
          clientKey = 'buyerNgrId';
        } else if (key == 'grade') {
          clientKey = 'gradeId';
        } else if (key == 'tonnage') {
          clientKey = 'tonnage';
          value = [MESSAGES.REQUIRED]
        }
        if (has(newState.fields, clientKey)) {
          newState.fields[clientKey].errors = value;
        }
      });
      this.setState(newState);
    }
    this.fetchSettings()
    let site = find(this.state.handlers, {id: this.state.fields.handlerId.value})
    if(this.state.fields.handlerId.value && this.state.transferSite?.id && this.state.fields.handlerId.value != this.state.transferSite?.id && site)
      this.setState({transferSite: site})
  }


  getNgrs(partyId, fieldName) {
    APIService.companies(partyId).ngrs().appendToUrl('minimal/').get(this.props.token)
              .then(items => {
                const newState = { ...this.state };
                newState[fieldName] = items;
                this.setState(newState);
              });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let contractId = get(this.props, 'contract.id') || this.state.fields.commodityContractId.value
    this.setAllFieldsErrors();
    let isFormInvalid = some(this.state.fields, field => {
      return field.errors.length > 0;
    }) || some(this.state.fields.seller, field => {
      return field.errors.length > 0;
    }) || some(this.state.fields.buyer, field => {
      return field.errors.length > 0;
    });
    let canCreateTitleTransfer = true;
    if (!isFormInvalid) {
      const { dispatch } = this.props;
      if(contractId) {
      await APIService.contracts(contractId)
                      .appendToUrl('title-transfers/new')
                      .get(this.props.token)
                      .then(res => {
                        if (!isEmpty(res.reasons)) {
                          canCreateTitleTransfer = false;
                          const reasons = '<li>' + res.reasons.join('</li><li>');
                          dispatch(canCreateTitleTransferForContractId(undefined));
                          alertifyjs.alert(
                            'Permission Denied',
                            `<div className=""><p>You do not have permission to create a Title Transfer for this Contract because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
                            () => { },
                          );
                        } else if (!isEmpty(res.warnings)) {
                          const warnings = '<li>' + res.warnings.join('</li><li>');
                          alertifyjs.confirm(
                            'Warning',
                            `<div className=""><ul>${warnings}</ul></div>`,
                            () => {
                              dispatch(canCreateTitleTransferForContractId(contractId));
                            },
                            () => { },
                          );
                        }
                      });
      }
      let transferSiteId = get(find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') }), 'companyId');
      let sellerNgr = filter(this.state.sellerNgrs, { id: this.state.fields.seller.ngrId.value });
      isFormInvalid = !this.isBroker && !((includes([this.state.fields.seller.companyId.value, transferSiteId], this.props.currentUser.companyId)) || (includes(get(sellerNgr, '0.primaryShareholderCompanyIds'), this.props.currentUser.companyId) && includes([this.state.fields.buyer.companyId.value], this.props.currentUser.companyId))) || this.isBroker && !(find(this.state.managedCompanies, { id: this.state.fields.seller.companyId.value }) || find(this.state.managedCompanies, { id: transferSiteId }) || this.checkRepresenting('seller'));
      if (isFormInvalid) {
        let sellerCompany = find(this.props.allCompanyParties, {id: this.state.fields.seller.companyId.value});
        let siteCompany = find(this.props.allCompanyParties, {id: transferSiteId});
        let isSiteAndSellerNonSubscriber = !get(sellerCompany, 'transactionParticipation') && !get(siteCompany, 'transactionParticipation')
        if (isSiteAndSellerNonSubscriber) {
          if (get(sellerNgr, '0.ngrType') === 'shared') {
            let secondaryShareholderCompanyIds = get(sellerNgr, '0.secondaryShareholderCompanyIds');
            let isAnyShareHoldersSubscriber = false;
            forEach(secondaryShareholderCompanyIds, companyId => {
              let shareHolderCompany = find(this.props.allCompanyParties, {id: companyId});
              if (get(shareHolderCompany, 'transactionParticipation'))
                isAnyShareHoldersSubscriber = true;
            });
            if (isAnyShareHoldersSubscriber)
              alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>One of the shareholders in seller NGR is a registered user.</li></ul></div>`);
            else
              isFormInvalid = false;
          }
          else
            isFormInvalid = false;
        }
        else
          alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>You are not the seller or the site employee.</li></ul></div>`);
      }

      let selectedSite = find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') });
      const buyerCompany = find(this.props.allCompanyParties, {id: this.state.fields.buyer.companyId.value})
      if(get(selectedSite, 'enableTransferToApprovedBuyers', false) && !isEqual(get(selectedSite, 'companyId'), get(this.state.fields, 'buyer.companyId.value')) && !isCompanyGrower(buyerCompany)) {
        await APIService.companies().appendToUrl(`${get(selectedSite, 'companyId')}/approved-buyers/`).get()
        .then(response => {
          if(!includes(get(response, 'companyIds'), get(this.state.fields.buyer.companyId, 'value'))) {
            isFormInvalid = true;
            if(isEqual(get(currentUserCompany(), 'id'), get(selectedSite, 'companyId')))
              alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>Please add the buyer in the approved buyers list from site listing in company settings</li></ul></div>`);
            else
              alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>Buyer is not on the approved Buyers list for this site.</li></ul><div>Please contact the Site Admin to get the buyer approved and then execute this Title Transfer.</div></div>`);
          }
        })
      }

      if (!includes(get(sellerNgr, '0.primaryShareholderCompanyIds'), get(this.state.fields, 'seller.companyId.value'))) {
        let secondaryShareholderCompanyIds = [];
        await APIService.ngrs(this.state.fields.seller.ngrId.value).get().then(res => secondaryShareholderCompanyIds  = get(res, 'secondaryShareholderCompanyIds', []));
        if (includes(secondaryShareholderCompanyIds, get(this.state.fields, 'seller.companyId.value')) && includes(get(sellerNgr, '0.primaryShareholderCompanyIds'), get(this.state.fields, 'buyer.companyId.value')))
          isFormInvalid = false;
        else {
          isFormInvalid = true;
          alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>Seller is not the primary shareholder in this ngr</li></ul></div>`);
        }
      }
      if(this.state.fields.seller.ngrId.value == this.state.fields.buyer.ngrId.value){
        isFormInvalid = true;
        alertifyjs.alert('Title Transfer',`<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>Seller ngr and Buyer ngr cannot be the same.</li></ul></div>` );
      }
    }
    if (isFormInvalid) {
      this.focusOnFirstErrorField();
    }
    let check = false;
    if (get(this.state.fields.processOn, 'value')) {
      let date = get(this.state.fields.processOn, 'value').split("-");
      if (MIN_YEAR < parseInt(date[0]) && parseInt(date[0]) < MAX_YEAR)
        check = true;
      else
        alertifyjs.error(`Date should be between year ${MIN_YEAR} and ${MAX_YEAR}`);
    }
    const data = mapValues(this.state.fields, 'value');
    data.seller = mapValues(this.state.fields.seller, 'value');
    data.buyer = mapValues(this.state.fields.buyer, 'value');
    data.note = omit(this.state.fields.note,['errors']);
    const shouldFetchCanolaLoads = this.shouldFetchCanolaLoads();
    if (!isFormInvalid && check && canCreateTitleTransfer) {
      if(shouldFetchCanolaLoads && !this.state.openCanolaLoads)
        this.toggleCanolaLoads();
      else {
        if(shouldFetchCanolaLoads && this.state.openCanolaLoads)
          if(isEmpty(this.getInvalidSelectedLoadIds()) && !isEmpty(this.state.selectedCanolaLoads)) {
            const result = {};
            forEach(this.state.selectedCanolaLoads, load => result[load.id] = load.transferrableTonnage);
            data.canolaLoadIds = result;
            data.tonnage = parseFloat(sum(values(data.canolaLoadIds)).toFixed(2));
            if((this.props.contract && this.props.contract.unaccountedTonnage < data.tonnage) || (this.selectedContract && this.selectedContract?.unaccountedTonnage < data.tonnage))
              alertifyjs.error(this.EXCESS_TONNAGE_ERROR);
            else
              this.openEmailDialog(data);
          }
          else
            alertifyjs.error('Please make sure loads are selected and transfer tonnage is less than remaining tonnage');
        else
          this.openEmailDialog(data);
      }
    }
  }

  getInvalidSelectedLoadIds() {
    return compact(map(this.state.selectedCanolaLoads, load => {
      if(!isNumber(load.transferrableTonnage) || !load.transferrableTonnage || load.transferrableTonnage < 0 || load.remainingTonnage < load.transferrableTonnage)
        return load.id;
    }));
  }

  handleVarietyChange = id => {
    this.setFieldValue('varietyId', id);
  };

  handleGradeChange(id) {
    if (id)
      this.setFieldValue('gradeId', id.value);
  }

  handleCommodityChange(id) {
    this.setFieldValue('commodityId', id);
  }

  handleSellerNgrChange(id) {
    this.setFieldValue('seller.ngrId', id);
  }

  handleBuyerNgrChange(id) {
    this.setFieldValue('buyer.ngrId', id);
  }

  handleSeasonChange(id) {
    this.setFieldValue('season', id);
  }

  handleHandlerChange(value) {
    let id = typeof value == 'object' ? value?.id : value
    this.setTransferSitePartyEmails(id);
    this.setFieldValue('handlerId', id, value);
  }

  handleProcessOnChange(value, id) {
    this.setFieldValue(id, value);
  }

  handleFieldChange(event) {
    if (event.target.id == 'externalReferenceNumber') {
      var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
      this.setFieldValue(event.target.id, value);
    }
    else this.setFieldValue(event.target.id, event.target.value);
  }

  handleIdentifierChange = event => {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.setFieldValue(event.target.id, event.target.value);
    } else {
      this.setFieldValue(event.target.id, this.state.fields.identifier.value);
    }
  };

  handleTonnageFieldChange(event) {
    const newState = { ...this.state };
    newState.fields.tonnage.value = event.target.value;
    if(isString(newState.fields.tonnage.value))
      newState.fields.tonnage.value = newState.fields.tonnage.value.replaceAll(',', '')
    this.setState(newState, () => this.setTonnageError());
  }

  setTonnageError(event) {
    if(event)
      this.handleTonnageFieldChange(event)

    const newState = { ...this.state };
    newState.fields.tonnage.errors = [];
    this.overdraftWarningText = '';
    if (!this.isMeterCubeCommodityCommodity() && (this.props.contract || this.selectedContract)) {
      let remainingTonnage = this.props.contract?.unaccountedTonnage || this.selectedContract?.unaccountedTonnage;
      if (parseFloat(newState.fields.tonnage.value) > remainingTonnage) {
        newState.fields.tonnage.errors = [this.EXCESS_TONNAGE_ERROR];
      }
      else {
        newState.fields.tonnage.errors = [];
      }
    }
    if (this.state.sellerTonnageAvailable != undefined && parseFloat(newState.fields.tonnage.value) > this.state.sellerTonnageAvailable && this.state.fields.handlerId.value) {
      if (!get(find(this.getHandlers(), { id: get(this.state.fields.handlerId, 'value') }), 'isOverdraftAllowed'))
        newState.fields.tonnage.errors = [this.EXCESS_AVAILABLE_STOCK_ERROR];
      else
        this.overdraftWarningText = 'Warning: Not enough stocks at the site.';
    }
    if (!isEmpty(this.getFieldErrors('tonnage')))
      newState.fields.tonnage.errors.push(this.getFieldErrors('tonnage'));
    this.setState(newState);
  }

  handleQuantityFieldChange(event) {
    const newState = { ...this.state };
    newState.fields.quantity.value = event.target.value;
    if(isString(newState.fields.quantity.value))
      newState.fields.quantity.value = newState.fields.quantity.value.replaceAll(',', '')
    if (this.isMeterCubeCommodityCommodity() && this.props.contract) {
      if (parseFloat(event.target.value) > this.props.contract.unaccountedTonnage) {
        newState.fields.quantity.errors = [this.EXCESS_QUANTITY_ERROR];
      } else {
        newState.fields.quantity.errors = [];
      }
    }
    this.setState(newState, () => this.setFieldErrors('quantity'));
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  async setSellerTonnageAvailable() {
    let sellerTonnageAvailable = this.state.sellerTonnageAvailable;
    if (this.state.fields.commodityId.value && this.state.fields.gradeId.value && this.state.fields.handlerId.value && this.state.fields.season.value &&
        this.state.fields.seller.ngrId.value) {
      let tonnage = await APIService.ngrs(this.state.fields.seller.ngrId.value).appendToUrl('available-stock/')
                                    .appendToUrl(`?commodityId=${this.state.fields.commodityId.value}&gradeId=${this.state.fields.gradeId.value}&siteId=${this.state.fields.handlerId.value}&season=${this.state.fields.season.value}`)
          .get(this.props.token, {'REFERER-UNIT' : get(this.getSelectedCommodity(), 'priceUnit'), 'REFERER-UNIT-FOR-REQUEST': true});
      sellerTonnageAvailable = tonnage.totalTonnage;
    }
    else {
      sellerTonnageAvailable = undefined;
      this.overdraftWarningText = '';
    }
    this.setState({ sellerTonnageAvailable: sellerTonnageAvailable }, () => this.setTonnageError());
  }

  isSellerGrower = () => (
    get(this.props.contract, 'seller.company.typeId') ||
    get(find(this.props.allCompanyParties, {id: this.state.fields.seller.companyId.value}), 'typeId')
  ) === GROWER;

  isDelivered = () => {
    const date = this.state.fields.processOn.value;
    if(date) {
      const now = moment();
      return now.format('YYYY/MM/DD') === date || now.isAfter(date);
    }
    return false;
  };

  shouldSelectLoads = () => {
    return this.state.fields.commodityId.value === COMMODITIES.CANOLA &&
           this.isSellerGrower() &&
           get(this.state.settings, 'loadByLoadTransfer');
  };

  setValidatorsForCanola() {
    if(this.shouldSelectLoads() && !this.isCanolaLoadByLoadStateUpdated()) {
      const newState = { ...this.state };
      this.updateStateForCanola(newState)
      this.setState(newState);
    }
  };

  updateStateForCanola = newState => {
    newState.fields.tonnage.value = null;
    newState.fields.tonnage.validators = [];
    newState.fields.tonnage.errors = [];
    newState.fields.coil.value = null;
    newState.fields.coil.validators = [];
    newState.fields.coil.errors = [];
    newState.fields.impu.value = null;
    newState.fields.impu.validators = [];
    newState.fields.impu.errors = [];
  }


  isCanolaLoadByLoadStateUpdated = () => {
    const { fields } = this.state;
    let result = true;
    ['tonnage', 'coil', 'impu'].forEach(field => {
      if(result) {
        const fieldState = fields[field]
        result = Boolean(isEmpty(fieldState.value) && isEmpty(fieldState.validators) && isEmpty(fieldState.errors))
      }
    })
    return result
  }

  setFieldValue(key, value, item=null) {
    const newState = { ...this.state };
    set(newState.fields, key + '.value', value);
    const shouldSelectLoads = this.shouldSelectLoads();
    if (key == 'commodityId') {
      if (!shouldSelectLoads && value == COMMODITIES.CANOLA) {
        if(this.props.contract && (get(this.props.contract, 'paymentScale.name') == 'Flat')) {
          newState.fields.coil.validators = [];
          newState.fields.impu.validators = [];
        } else {
          newState.fields.coil.validators = [required()];
          newState.fields.impu.validators = [required()];
        }
      }
      else if (this.hasQuantityBasedCommodity(value)) {
        newState.fields.quantity.validators = [required(), minValue(0)];
      }
      else {
        newState.fields.coil.validators = [];
        newState.fields.coil.errors = [];
        newState.fields.impu.validators = [];
        newState.fields.impu.errors = [];
        newState.fields.quantity.validators = [];
      };
    }
    if (key == 'handlerId') {
      if (this.state.fields.commodityId.value === COMMODITIES.CANOLA) {
        if(this.props.contract && (get(this.props.contract, 'paymentScale.name') == 'Flat')) {
          newState.fields.coil.validators = [];
          newState.fields.impu.validators = [];
        } else {
          newState.fields.coil.validators = [required()];
          newState.fields.impu.validators = [required()];
        }
      }
      newState.transferSite = item
      if (item) {
        newState.handlers = uniqBy([...newState.handlers, item], 'id')
      }
      let transferSiteId = find(newState.handlers, { id: get(newState.fields.handlerId, 'value') });
      if (transferSiteId && get(transferSiteId, 'stocksManagement') && get(transferSiteId, 'companyId') != this.props.currentUser.companyId) {
        newState.fields.processOn.value = moment().format('YYYY-MM-DD');
      }
    }
    this.setVarietyMandate(newState);
    this.setState(newState, () => {
      this.setSellerTonnageAvailable();
      this.setFieldErrors(key);
      this.fetchSettings()
      if (key == 'handlerId' && value)
        this.setExternalContractNumberMandate(value);
    });
  }

  fetchSettings = () => {
    const handlerId = this.state.fields.handlerId.value;
    if(handlerId && !this.isFetchingSettings && this.state.settings?.siteId !== handlerId ) {
      this.isFetchingSettings = true
      APIService.company_sites(handlerId).appendToUrl('settings/').get()
        .then(settings => {
          this.setState({settings: settings}, () => {
            this.isFetchingSettings = false
            this.setValidatorsForCanola()
          })
        });
    }
  };

  getFieldErrors(key) {
    const errors = [];
    const value = get(this.state.fields, `${key}.value`);
    const validators = get(this.state.fields, `${key}.validators`) || [];
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  getPartyFieldErrors(party, key) {
    const errors = [];
    const value = get(this.state.fields, `${party}.${key}.value`);
    const validators = get(this.state.fields, `${party}.${key}.validators`) || [];
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = { ...this.state };
    set(newState.fields, key + '.errors', this.getFieldErrors(key));
    this.setState(newState);
  }

  setPartyFieldErrors(party, key) {
    const newState = { ...this.state };
    set(newState.fields, party + "." + key + '.errors', this.getPartyFieldErrors(party, key));
    this.setState(newState);
  }


  setAllFieldsErrors() {
    forEach(this.state.fields, (value, key) => {
      this.setFieldErrors(key);
    });
    forEach(this.state.fields.seller, (value, key) => {
      this.setPartyFieldErrors('seller', key);
    });
    forEach(this.state.fields.buyer, (value, key) => {
      this.setPartyFieldErrors('buyer', key);
    });
    this.setTonnageError();
    const newState = { ...this.state };
    if (this.props.contract || this.selectedContract) {
      let remainingTonnage = this.props.contract?.unaccountedTonnage || this.selectedContract?.unaccountedTonnage;
      if (this.state.fields.tonnage.value > remainingTonnage && !this.isMeterCubeCommodityCommodity())
        newState.fields.tonnage.errors.push(this.EXCESS_TONNAGE_ERROR);
      if (this.state.fields.quantity.value > remainingTonnage && this.isMeterCubeCommodityCommodity())
        newState.fields.quantity.errors.push(this.EXCESS_QUANTITY_ERROR);
    }
    this.setState(newState);
  }

  showOilAndAdmixFields = () => {
    return COMMODITIES.CANOLA == get(this.state.fields.commodityId, 'value', get(this.props.contract, 'commodityId'));
  };


  getSelectedCommodity = commodityId => {
    const id = commodityId || get(this.props.contract, 'commodityId', this.state.fields.commodityId.value)
    return id ? find(this.props.commodities, {id: id}) : null
  }

  hasQuantityBasedCommodity(commodityId) {
    return Boolean(this.getSelectedCommodity(commodityId)?.isQuantityBased)
  }

  isMeterCubeCommodityCommodity = () => this.getSelectedCommodity()?.isStrictQuantityBased

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  getEmailPopupParties = () => {
    if (get(this.props, 'contract.administration.brokeredById')) {
      return ['buyer', 'seller', 'broker', 'pickup site', 'delivery site'];
    }
    return ['buyer', 'seller', 'transferSite'];
  };

  getSelectedParties = () => {
    let selectedParties = ['buyer', 'seller'];
    if (get(this.props.contract, 'isBuyer') && get(this.props.contract, 'isSeller')) selectedParties = ['buyer', 'seller'];
    else if (get(this.props.contract, 'isBuyer') && this.props.currentUser.companyId === get(this.props.contract, 'buyer.companyId')) selectedParties = ['seller'];
    else if (get(this.props.contract, 'isSeller') && this.props.currentUser.companyId === get(this.props.contract, 'seller.companyId')) selectedParties = ['buyer'];
    let transferSite = find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') });
    if (transferSite && get(transferSite, 'stocksManagement'))
      selectedParties.push('transferSite');
    return selectedParties;
  };

  getPartyEmails = () => {
    const { sellerContacts, buyerContacts, transferSitePartyEmails } = this.state;
    return {
      buyer: get(this.props, 'contract.buyer.contact.email', get(find(buyerContacts, { id: get(this.state.fields.buyer, 'contactId.value') }), 'email')),
      seller: get(this.props, 'contract.seller.contact.email', get(find(sellerContacts, { id: get(this.state.fields.seller, 'contactId.value') }), 'email')),
      broker: get(this.props, 'contract.administration.brokerContact.email', ''),
      consignor: '',
      consignee: '',
      transferSite: isEmpty(transferSitePartyEmails) ? '' : transferSitePartyEmails,
    };
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    forEach(parties, party => {
      if (party === 'broker')
        ids.broker = get(this.props.contract, 'administration.brokerContact.companyId');
      if (party === 'buyer')
        ids.buyer = get(this.props.contract, 'buyer.companyId', get(this.state.fields, 'buyer.companyId.value'));
      if (party === 'seller')
        ids.seller = get(this.props.contract, 'seller.companyId', get(this.state.fields, 'seller.companyId.value'));
      if (party === 'pickup site')
        ids.consignor = get(this.props.contract, 'consignorsWithSites[0].handler.companyId');
      if (party === 'delivery site')
        ids.consignee = get(this.props.contract, 'consigneesWithSites[0].handler.companyId');
      if (party === 'transferSite')
        ids.transferSite = get(find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') }), 'companyId');
    });

    return ids;
  }

  async getPartyContacts() {
    if (this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties), 'consignee', 'consignor');
    const contacts = {};
    if (!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if (isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.token);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, { companyId: parties[party] });
      });
    }

    return contacts;
  }

  checkRepresenting = (type) => {
    return (get(this.props.contract, `${type}.representedById`) == this.props.currentUser.companyId) ;
  };


  getEmailSubject = () => {
    const companyName = get(this.props.currentUser, 'company.name', "");
    const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
    let subject = `${companyName} Title Transfer`
    if (this.isBroker){
      let representingPartyCompany = this.checkRepresenting("seller") ? get(this.props.contract, 'seller.company.name'): get(this.props.contract, 'buyer.company.name');
      if(representingPartyCompany)
        subject += ` a/c ${representingPartyCompany}`
    }
    subject += ` #${identifier}`
    return subject
  };

  getFooterNote = () => {
    return 'Title Transfer PDF will be automatically sent as part of the email';
  };

  openEmailDialog = data => {
    this.setState({ showEmailDialog: true, emailPopupParties: this.getEmailPopupParties() });
    this.payloadData = data;
  };

  closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      this.gotOncePartyContacts = false;
      this.setState({ showEmailDialog: false });
    }
    else if (this.state.showEmailDialog) {
      const data = this.payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showEmailDialog: false }, () => {
        this.props.dispatch(isLoading('alertify'));
        this.props.submit(get(this.props, 'contract.id'), data);
      });
    }
  };


  isVarietyMandatory = (existingState) => {
    const newState = existingState || {...this.state};
    const site = find(newState.handlers, { id: get(newState.fields.handlerId, 'value') });
    let result = false
    if(!SYSTEM_COMPANY_IDS.includes(site?.companyId) && site?.isVarietyMandatory) {
      const sellerCompany = find(this.props.allCompanyParties, {id: newState.fields.seller.companyId.value})
      const { isVarietyMandatory, loadTypeForVarietyMandatory, userTypeForVarietyMandatory } = site
      let isGrower = isCompanyGrower(sellerCompany)
      let isGrowerType = isGrower && userTypeForVarietyMandatory && ['growers'].some(type => userTypeForVarietyMandatory.includes(type))
      let isNonGrowerType = !isGrower && userTypeForVarietyMandatory && ['non_growers'].some(type => userTypeForVarietyMandatory.includes(type))
      let isLoadTypeForVarietyMandatory = loadTypeForVarietyMandatory && ['title_transfers_and_cash_outs'].some(type => (loadTypeForVarietyMandatory.includes(type)))
      result = isVarietyMandatory && isLoadTypeForVarietyMandatory && (isGrowerType || isNonGrowerType)
    }
    return result
  }

  setVarietyMandate = existingState => {
    const isMandatory = this.isVarietyMandatory(existingState);
    const shouldUpdateState = !existingState
    const newState = shouldUpdateState ? {...this.state} : existingState
    if(isMandatory && !newState.fields.varietyId.validators.length) {
      newState.fields.varietyId.validators = [required()]
      shouldUpdateState && setTimeout(() => this.setState(newState), 100)
    } else if(!isMandatory && newState.fields.varietyId.validators.length) {
      newState.fields.varietyId.validators = []
      newState.fields.varietyId.errors = []
      shouldUpdateState && setTimeout(() => this.setState(newState), 100)
    }
    return newState
  };

  setExternalContractNumberMandate = siteId => {
    if (!this.props.contract) {
      const handler = find(this.getHandlers(), {id: siteId});
      if (handler) {
        const handlerCompany = find(this.props.allCompanyParties, {id: get(handler, 'companyId')});
        const newState = {...this.state};
        if (get(handlerCompany, 'contractNumberMandatoryInTransfers'))
          newState.fields.externalReferenceNumber.validators = [required()];
        else
          newState.fields.externalReferenceNumber.validators = [];
        this.setState(newState);
      }
    }
  }

  updateContacts = (seller, buyer, value, contacts) => {
    const fieldName = seller ? 'seller' : 'buyer';
    const stateKey = `${fieldName}Contacts`;
    const contactIdField = `fields.${fieldName}.contactId.value`;
    const newState = { ...this.state };
    newState[stateKey] = uniqBy(contacts, 'id');

    const contactId = find(contacts, { id: this.state[contactIdField] });
    if (!contactId)
      newState[contactIdField] = null;
    this.setState(newState, () => this.fetchNgr(value, seller, buyer));
  };

  getContactNgr(value, seller, buyer) {
    APIService
      .contracts()
      .companies(value)
      .contacts()
      .get()
      .then(contacts => {
        if (seller || buyer)
          this.updateContacts(seller, buyer, value, contacts);
      });
  }

  updateNgrs = (isSeller, items) => {
    const fieldName = isSeller ? 'seller' : 'buyer';
    const stateKey = isSeller ? 'sellerNgrs' : 'buyerNgrs';
    const ngrIdField = `fields.${fieldName}.ngrId.value`;
    const newState = { ...this.state };
    newState[stateKey] = uniqBy(items, 'id');

    const ngr = find(items, { id: this.state[ngrIdField] });
    if (!ngr)
      newState[ngrIdField] = null;
    this.setState(newState);
  };

  fetchNgr = (value, seller, buyer) => {
    APIService.companies(value).ngrs().appendToUrl('minimal/')
                          .get().then(items => {
                            if (seller || buyer)
                              this.updateNgrs(seller, items)
                          }
                          );
  }
  handleValueChange(value, fieldId) {
    const newState = { ...this.state };
    const { stock } = this.props;
    set(newState.fields, `${fieldId}.value`, value);
    let buyer = fieldId === 'buyer.companyId';
    let seller = fieldId === 'seller.companyId';
    if ((seller || buyer)) {
      if (seller) {
        if (stock) {
          set(newState.fields, 'seller.contactId.value', this.props.currentUser.id);
          set(newState, 'sellerContacts', []);
          set(newState, 'sellerNgrs', []);
          set(newState.fields, 'seller.ngrId.value', stock.ngrId);
        }
      }
      if (buyer) {
        if (value && !this.state.fields.commodityContractId.value) {
          APIService.companies(value).appendToUrl('ngrs/tags/title_transfer/')
            .get()
            .then(response => {
              const newState = {...this.state};
              set(newState.fields, 'buyer.ngrId.value', get(response, 'ngrId'));
              this.setState(newState);
            })
        }
      }
    };
    set(newState.fields, fieldId + '.errors', this.getFieldErrors(fieldId));
    if (this.state.fields.commodityId.value === COMMODITIES.CANOLA) {
      if(this.props.contract && (get(this.props.contract, 'paymentScale.name') == 'Flat')) {
        newState.fields.coil.validators = [];
        newState.fields.impu.validators = [];
      } else {
        newState.fields.coil.validators = [required()];
        newState.fields.impu.validators = [required()];
      }
    }
    this.setVarietyMandate(newState)
    this.setState(newState, () => {
      if (value && (seller || buyer)) {
        this.getContactNgr(value, seller, buyer);
      }
      if (seller) {
        this.setSellerTonnageAvailable();
      }
    });
  }

  focusOnFirstErrorField(){
    const nestedFields = ["identifier", "seller.companyId", "seller.contactId", "seller.ngrId",
                          "buyer.companyId", "buyer.contactId", "buyer.ngrId", "quantity", "tonnage", "processOn", "handlerId",
                          "coil", "impu"
    ];
    const autoCompleteFields = [ "commodityId", "gradeId", "season"];

    for(let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];
      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if ((this.fieldsOrder[i] === "identifier" && this.state.fields.identifier.errors.length) ||
            (this.fieldsOrder[i] === "seller.contactId" && this.state.fields.seller.contactId.errors.length) ||
            (this.fieldsOrder[i] === "seller.companyId" && this.state.fields.seller.companyId.errors.length) ||
            (this.fieldsOrder[i] === "seller.ngrId" && this.state.fields.seller.ngrId.errors.length) ||
            (this.fieldsOrder[i] === "buyer.companyId" && this.state.fields.buyer.companyId.errors.length) ||
            (this.fieldsOrder[i] === "buyer.contactId" && this.state.fields.buyer.contactId.errors.length) ||
            (this.fieldsOrder[i] === "buyer.ngrId" && this.state.fields.buyer.ngrId.errors.length) ||
            (this.fieldsOrder[i] === "quantity" && this.state.fields.quantity.errors.length) ||
            (this.fieldsOrder[i] === "tonnage" && this.state.fields.tonnage.errors.length) ||
            (this.fieldsOrder[i] === "processOn" && this.state.fields.processOn.errors.length)||
            (this.fieldsOrder[i] === "handlerId" && this.state.fields.processOn.errors.length)) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if (
          (this.fieldsOrder[i] === "seller.contactId" && this.state.fields.seller.contactId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === "buyer.contactId" && this.state.fields.buyer.contactId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === "handlerId" && this.state.fields.handlerId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === "coil" && this.state.fields.coil.errors.length)||
          (this.fieldsOrder[i] === "impu" && this.state.fields.impu.errors.length)
        ){
          formField.current.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (get(formField, 'current.node')) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          const current = get(formField, 'current');
          if(current)
            current.focus();
          break;
        }
      }
    }
  }

  onLoadCheckboxChange = (selectedLoad, isChecked) => {
    if(isChecked)
      this.setState({selectedCanolaLoads: [...this.state.selectedCanolaLoads, selectedLoad]});
    else
      this.setState({selectedCanolaLoads: without(this.state.selectedCanolaLoads, selectedLoad)});
  };

  handleRemainingTonnageChange = (event, load) => {
    const { canolaLoads } = this.state;
    this.setState({canolaLoads: map(canolaLoads, canolaLoad => {
      if(canolaLoad.id === load.id)
        canolaLoad.transferrableTonnage = event.target.value ? parseFloat(event.target.value) : 0;
      return canolaLoad;
    })});
  };

  isSelectedLoad = loadId => includes(map(this.state.selectedCanolaLoads, 'id'), loadId);

  getColumns = commodity => {
    const invalidLoadIds = this.getInvalidSelectedLoadIds();
    const specColumns = commodity ? getSpecColumns(commodity, 'specs') : [];
    return [
      {checkbox: true, onChange: 'onChange', func: item => this.isSelectedLoad(item.id)},
      {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
      { key: 'season', header: 'Season' },
      { key: 'varietyName', header: 'Variety' },
      { key: 'gradeName', header: 'Grade' },
      ...specColumns,
      { key: 'remainingTonnage', header: 'Remaining Tonnage' },
      { header: 'Transfer Tonnage',
        formatter: load => <input
                             style={includes(invalidLoadIds, load.id) ? {color: 'red'} : {}}
                             disabled={!this.isSelectedLoad(load.id)}
                             size='small'
                             value={load.transferrableTonnage || 0}
                             type='number'
                             min="0.00001"
                             step="1"
                             max={load.remainingTonnage}
                             onChange={event => this.handleRemainingTonnageChange(event, load)} />
      },
    ];
  };

  getMinDate() {
    let transferSite = find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') });
    if ((transferSite && get(transferSite, 'stocksManagement') && get(transferSite, 'companyId') != this.props.currentUser.companyId) || this.shouldSelectLoads())
      return moment().format('YYYY/MM/DD');

    return moment().subtract(5, 'years').format('YYYY/MM/DD');
  }

  getMaxDate() {
    let transferSite = find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') });
    if ((transferSite && get(transferSite, 'stocksManagement') && get(transferSite, 'companyId') != this.props.currentUser.companyId) || this.shouldSelectLoads())
      return moment().format('YYYY/MM/DD');

    return moment().add(5, 'years').format('YYYY/MM/DD');
  }

  getDisabledPartiesForEmail() {
    const stocksManagement = get(find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') }), 'stocksManagement');
    if (stocksManagement) {
      return ['buyer', 'transferSite'];
    }
    return [];
  }

  isLaterDisabled() {
    let disabledParties = this.getDisabledPartiesForEmail();
    if (disabledParties.length > 0)
      return true;
    return false;
  }

  totalTransferrableCanolaTonnage = () => sum(map(this.state.selectedCanolaLoads, 'transferrableTonnage')).toFixed(2)

  setTransferSitePartyEmails(handlerId) {
    if (handlerId) {
      let handlers = isEmpty(this.state.handlers) ? getSavedHandlers() : this.state.handlers;
      let site = find(handlers, {id: handlerId});
      if (site && get(site, 'stocksManagement')) {
        let companyId = get(site, 'companyId');
        APIService.contracts()
          .appendToUrl(`companies/${companyId}/contacts/?company_admin=true`)
          .get()
          .then(contacts => {
            let sitePartyEmails = contacts.filter(contact=> contact.email && !isEmpty(contact.email)).map(contact => contact.email);
            this.setState({transferSitePartyEmails: sitePartyEmails});
          });
      }
      else {
        this.setState({transferSitePartyEmails: []});
      }
    }
  }

  priceUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'priceUnit', MT_UNIT);
  }

  tonnageUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'tonnageUnit', MT_UNIT);
  }

  noteHandler = val => {
    this.setState({fields: {...this.state.fields, note: val}});
  };

  clearTTForm = () => {
    if(this.props.copyFrom && this.isDuplicatedTitleTransfer){
      const newState = {...this.state};
      this.selectedContract = undefined;
      newState.contractDetailsDialog = false;
      this.isDuplicatedTitleTransfer = false;
      this.setState(newState)
      return
    }

    this.selectedContract = undefined;
    const newState = {...this.state};
    if(!this.state.contractDetailsDialog)
      newState.fields.externalReferenceNumber.value = ''
    newState.contractDetailsDialog = false;
    newState.fields.commodityContractId.value = null
    newState.fields.commodityId.value = null;
    newState.fields.season.value = null;
    newState.fields.gradeId.value = null;
    newState.fields.varietyId.value = null;
    newState.fields.buyer.companyId.value = null;
    newState.fields.seller.companyId.value = null;
    newState.fields.buyer.contactId.value = null;
    newState.fields.seller.contactId.value = null;
    newState.fields.seller.ngrId.value = null;
    newState.fields.buyer.ngrId.value = null;
    newState.fields.handlerId.value = null;
    newState.fields.tonnage.value = null;
    this.setState(newState)
    this.getHandlers()
  }

  populateContractDetails = () => {
    if (this.selectedContract) {
      const newState = {...this.state};
      newState.contractDetailsDialog = false;
      newState.fields.externalReferenceNumber.value = ''
      newState.fields.commodityContractId.value = this.selectedContract?.id
      newState.fields.commodityId.value = this.selectedContract?.commodityId;
      newState.fields.season.value = this.selectedContract?.season;
      newState.fields.buyer.companyId.value = get(this.selectedContract, 'buyer.companyId');
      newState.fields.seller.companyId.value = get(this.selectedContract, 'seller.companyId');
      newState.fields.buyer.contactId.value = get(this.selectedContract, 'buyer.contactId');
      newState.fields.seller.contactId.value = get(this.selectedContract, 'seller.contactId');
      newState.fields.buyer.ngrId.value = get(this.selectedContract, 'buyer.ngrId');
      newState.fields.gradeId.value = this.selectedContract.grade;
      newState.fields.gradeId.errors = []
      newState.fields.varietyId.value = this.selectedContract?.varietyId;
      this.setState(newState)
      this.getHandlers()
    }
  }

  handleContractChange = item => {
    if(item) {
      let data = {
        'Seller': item?.seller.companyName,
        'Buyer': item?.buyer.companyName,
        'Commodity': item?.commodity,
        'Grade': item?.gradeName,
        'Season': item?.season,
        'Grade Spreads': item.spreadGrades?.join(', '),
        'Price Point': item?.pricePoint,
      }
      if (item.pickupSites.length > 0)
        data['Pickup Site(s)'] = item.pickupSites.map(site => site.displayName).join(", ");
      if (item.deliverySites.length > 0)
        data['Delivery Site(s)'] = item.deliverySites.map(site => site.displayName).join(", ");
      this.selectedContract = item;
      let handlers;
      if (this.isDeliveredPP())
        handlers = item?.deliverySites;
      else if (this.isPickupPP())
        handlers = item?.pickupSites;
      let handlerIds = compact(map(handlers, handler => handler?.handlerId))
      if (handlerIds.length > 0)
        this.fetchHandlers(handlerIds.join(','))
      this.setState({tableData: data, contractDetailsDialog: true})
    } else {
      this.clearTTForm()
    }
  };

  getContracts = (queryString, callback) => {
    let queryParams = '?search_in_creating_tt=true'
    const newState = {...this.state};
    newState.fields.externalReferenceNumber.value = queryString['search']
    this.setState(newState)

    APIService.contracts().appendToUrl(`global-search/${encodeURIComponent(encodeURIComponent(queryString['search']))}/${queryParams}`).get().then(results => {
      callback(get(results, 'contracts'));
      this.props.dispatch(forceStopLoader());
    });
  }

  render() {
    const shouldSelectLoads = this.shouldSelectLoads();
    const showOilAndAdmixFields = this.showOilAndAdmixFields();
    const { openCanolaLoads, canolaLoads } = this.state;
    const priceUnit = this.priceUnit();
    const tonnageUnit = this.tonnageUnit();
    const isExternalContractNumberMandatory = this.state.fields.externalReferenceNumber.validators.length > 0;
    const sites = this.getHandlers();
    return (
      <div id='title-transfer-side-form' className='col-xs-12 padding-reset'>
        <form onSubmit={this.handleSubmit} noValidate>
          {
            <React.Fragment>
              <div className='cardForm cardForm--drawer col-xs-12 padding-reset' style={openCanolaLoads ? {} : {display: 'none'}}>
                <div className='col-xs-12 no-side-padding' onClick={this.toggleCanolaLoads} style={{margin: '10px 0'}}>
                  <Button color='primary' size='small' variant='outlined'>
                    <BackIcon fontSize='inherit' style={{marginRight: '5px'}} />
                    Back
                  </Button>
                </div>
                {
                  openCanolaLoads &&
                  <GenericTable
                    items={canolaLoads}
                    columns={this.getColumns(get(canolaLoads, '0.commodity'))}
                    optionsItems={[]}
                    onChange={this.onLoadCheckboxChange}
                    displayIDColumn = 'season'
                  />
                }
              </div>
              <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
                <div style={openCanolaLoads ? {display: 'none'} : {}} className='col-xs-12 padding-reset'>
                  <div className='cardForm-content row col-xs-12 padding-reset'>
                    <div className='col-xs-6 form-wrap'>
                      <TextField
                        error={!isEmpty(this.state.fields.identifier.errors[0])}
                        inputRef={this.fieldRef['identifier']}
                        id='identifier'
                        label='Title Transfer No.'
                        placeholder='Please enter 14 digit unique number'
                        style={{ float: 'left' }}
                        value={this.state.fields.identifier.value}
                        inputProps={{
                          maxLength: 14,
                        }}
                        fullWidth
                        helperText={this.state.fields.identifier.errors[0]}
                        onChange={this.handleIdentifierChange}
                        onBlur={this.onFieldBlur}
                        variant="standard" />
                    </div>
                    {get(this.props.contract, 'contractNumber') ? (
                       <div className='col-xs-6 form-wrap'>
                         <CommonTextField id='contractNumber' label='Contract Number' value={this.props.contract.contractNumber} disabled />
                       </div>
                    ) : (
                       <div className='col-xs-6 form-wrap'>
                          {!this.props.contract && (
                            <div className='col-md-12 padding-reset form-wrap'>
                              <QuickContract
                                id="externalReferenceNumber"
                                onChange={this.handleContractChange}
                                service={this.getContracts}
                                disabled={this.props.contract}
                                helperText={this.state.fields.externalReferenceNumber.errors[0]}
                                label={this.props.contract ? 'Contract Number' : isExternalContractNumberMandatory ? 'External Contract No.' : 'Contract No.'}
                                onBlur={this.onFieldBlur}
                                setRef={this.fieldRef['externalReferenceNumber']}
                                minCharSearch={MIN_SEARCH_LENGTH}
                                isTTForm={true}
                                initialSearchValue={this.state.fields.externalReferenceNumber.value}
                              />
                            </div>
                          )}
                       </div>
                    )}
                    {get(this.props.contract, 'seller.company.displayName') ? (
                       <div className='col-xs-6 form-wrap'>
                         <CommonTextField id='seller.companyId' label='Seller Company' value={this.props.contract.seller.company.displayName} disabled />
                       </div>
                    ) : (
                       <div className='col-xs-6 form-wrap'>
                         <CommonAutoSelect
                           id='seller.companyId'
                           label='Seller Company'
                           setRef={this.fieldRef['seller.companyId']}
                           placeholder='Seller Company'
                           items={this.props.allCompanyParties}
                           fullWidth
                           onBlur={this.onFieldBlur}
                           onChange={this.handleValueChange}
                           errorText={this.state.fields.seller.companyId.errors[0]}
                           value={this.state.fields.seller.companyId.value}
                           disabled={Boolean(this.props.stock || this.selectedContract)}
                         />
                       </div>
                    )}
                    {get(this.props.contract, 'buyer.company.displayName') ? (
                       <div className='col-xs-6 form-wrap'>
                         <CommonTextField id='buyer.companyId' label='Buyer Company' value={this.props.contract.buyer.company.displayName} disabled />
                       </div>
                    ) : (
                       <div className='col-xs-6 form-wrap'>
                         <CommonAutoSelect
                           id='buyer.companyId'
                           label='Buyer Company'
                           setRef={this.fieldRef['buyer.companyId']}
                           placeholder='Buyer Company'
                           items={this.props.allCompanyParties}
                           fullWidth
                           onBlur={this.onFieldBlur}
                           onChange={this.handleValueChange}
                           errorText={this.state.fields.buyer.companyId.errors[0]}
                           value={this.state.fields.buyer.companyId.value}
                           disabled={Boolean(this.selectedContract)}
                         />
                       </div>
                    )}
                    <div className='col-xs-6 form-wrap'>
                      <CommonAutoSelect
                        items={this.state.sellerContacts}
                        setRef={this.fieldRef['seller.contactId']}
                        id="seller.contactId"
                        label="Seller Contact"
                        value={this.state.fields.seller.contactId.value}
                        onChange={this.handleValueChange}
                        errorText={this.state.fields.seller.contactId.errors[0]}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <CommonAutoSelect
                        items={this.state.buyerContacts}
                        id="buyer.contactId"
                        label="Buyer Contact"
                        setRef={this.fieldRef['buyer.contactId']}
                        value={this.state.fields.buyer.contactId.value}
                        onChange={this.handleValueChange}
                        errorText={this.state.fields.buyer.contactId.errors[0]}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <AutoComplete
                        id='seller.ngrId'
                        label='Seller NGR'
                        placeholder='Seller NGR'
                        setRef={this.fieldRef['seller.ngrId']}
                        options={this.state.sellerNgrs.map(s => ({ label: s.ngrNumber, value: s.id }))}
                        fullWidth
                        onBlur={this.onFieldBlur}
                        onChange={this.handleSellerNgrChange}
                        errorText={this.state.fields.seller.ngrId.errors[0]}
                        value={this.state.fields.seller.ngrId.value}
                        disabled={Boolean(this.props.stock)}
                        showLockIcon={this.props.stock}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <AutoComplete
                        id='buyer.ngrId'
                        label='Buyer NGR'
                        placeholder='Buyer NGR'
                        setRef={this.fieldRef['buyer.ngrId']}
                        options={this.state.buyerNgrs.map(s => ({ label: s.ngrNumber, value: s.id }))}
                        fullWidth
                        onBlur={this.onFieldBlur}
                        onChange={this.handleBuyerNgrChange}
                        errorText={this.state.fields.buyer.ngrId.errors[0]}
                        value={this.state.fields.buyer.ngrId.value}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <CommodityAutoComplete
                        id="commodityId"
                        onChange={this.handleCommodityChange}
                        floatingLabelText="Commodity"
                        setRef={this.fieldRef['commodityId']}
                        commodityId={this.state.fields.commodityId.value}
                        style={{float: 'left'}}
                        errorText={this.state.fields.commodityId.errors[0]}
                        disabled={Boolean(this.props.contract || this.props.stock || this.selectedContract)}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <VarietyAutoComplete
                        id="varietyId"
                        onChange={this.handleVarietyChange}
                        label={isEmpty(this.state.fields.varietyId.validators) ? "Variety (Optional)" : "Variety" }
                        commodityId={this.state.fields.commodityId.value}
                        varietyId={this.state.fields.varietyId.value}
                        dependsOnCommodity
                        errorText={get(this.state, 'fields.varietyId.errors[0]', '')}
                      />
                    </div>
                    {isEmpty(this.state.grades) && this.props.contract ? (
                       <div className='col-xs-6 form-wrap'>
                         <CommonTextField id='gradeId' label='Grade' value={get(this.props.contract, 'gradeName')} disabled />
                       </div>
                    ) : (
                       <div className='col-md-6 form-wrap'>
                         <GradeAutoComplete
                           id="gradeId"
                           setRef={this.fieldRef['gradeId']}
                           onChange={this.handleGradeChange}
                           floatingLabelText="Grade"
                           commodityId={this.state.fields.commodityId.value}
                           gradeId={this.state.fields.gradeId.value}
                           season={this.state.fields.season.value}
                           dependsOnCommodity
                           dependsOnSeason
                           selectedGradeId={this.state.fields.gradeId.value}
                           disabled={Boolean(!this.state.fields.commodityId.value || this.props.stock)}
                           errorText={get(this.state, 'fields.gradeId.errors[0]', '')}
                         />
                       </div>
                    )}
                    <div className='col-xs-6 form-wrap'>
                      <SeasonSelect
                        id="season"
                        setRef={this.fieldRef["season"]}
                        onChange={this.handleSeasonChange}
                        season={this.state.fields.season.value}
                        style={{ float: 'left' }}
                        errorText={this.state.fields.season.errors[0]}
                        disabled={Boolean(this.props.contract || this.props.stock || this.selectedContract)}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      { sites.length > 0 && !isEmpty(this.props.contract || this.selectedContract) ?
                      <CommonAutoSelect
                        id='handlerId'
                        label='Site Name'
                        placeholder='Site Name'
                        items={sites}
                        setRef={this.fieldRef['handlerId']}
                        fullWidth
                        errorText={this.state.fields.handlerId.errors[0]}
                        onBlur={this.onFieldBlur}
                        onChange={this.handleHandlerChange}
                        value={this.state.fields.handlerId.value}
                        disabled={Boolean(this.props.stock)}
                      /> :
                        <SiteAsyncAutocomplete
                          limitTags={2}
                          label="Site Name"
                          id="handlerId"
                          onChange={item => this.handleHandlerChange(item)}
                          selected={get(this.state, 'transferSite', '')}
                          minLength={3}
                          variant="standard"
                          disabled={this.props.stock}
                          popupIcon={this.props.stock? <i className="icon-lock-inline"></i> : undefined}
                          fullWidth
                          activeSitesOnly
                          errorText={this.state.fields.handlerId.errors[0]}
                        />
                      }
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <CommonDatePicker
                        id='processOn'
                        floatingLabelText='Transfer Date'
                        setRef={this.fieldRef['processOn']}
                        errorText={this.state.fields.processOn.errors[0]}
                        value={this.state.fields.processOn.value}
                        onChange={this.handleProcessOnChange}
                        minDate={this.getMinDate()}
                        maxDate={this.getMaxDate()}
                      />
                    </div>
                    {this.hasQuantityBasedCommodity() && (
                       <div className='col-xs-6 form-wrap'>
                         <NumberField
                           id='quantity'
                           label={this.quantityLabel()}
                           setRef={this.fieldRef['quantity']}
                           value={this.state.fields.quantity.value}
                           maxValue={99999.99}
                           helperText={this.state.fields.quantity.errors[0]}
                           onChange={this.handleQuantityFieldChange}
                           onBlur={this.handleQuantityFieldChange}
                           InputProps={{
                             endAdornment: (
                               <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                                 {this.quantityUnit()}
                               </InputAdornment>
                             ),
                           }}
                           disabled={this.isEditForm && includes(['delivered', 'completed'], get(this.editOrder, 'status'))}
                         />
                       </div>
                    )}
                    <div className='col-xs-6 form-wrap'>
                      <NumberField
                        error={!isEmpty(this.state.fields.tonnage.errors[0])}
                        id='tonnage'
                        label={!isEqual(this.state.sellerTonnageAvailable, undefined) ? `Tonnage (Available: ${this.state.sellerTonnageAvailable} ${tonnageUnit})` : 'Tonnage'}
                        placeholder='Tonnage'
                        setRef={this.fieldRef['tonnage']}
                        value={this.state.fields.tonnage.value || ''}
                        fullWidth
                        helperText={this.state.fields.tonnage.errors[0] || this.overdraftWarningText}
                        maxValue={9999.99}
                        onChange={this.handleTonnageFieldChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                              {tonnageUnit}
                            </InputAdornment>
                          ),
                        }}
                        onBlur={this.setTonnageError}
                        disabled={shouldSelectLoads}
                        variant="standard"
                      />
                    </div>
                    {showOilAndAdmixFields && (
                       <div className={'col-xs-6 form-wrap'}>
                         <NumberField
                           id='coil'
                           setRef={this.fieldRef['coil']}
                           label={isEmpty(this.state.fields.coil.validators) ? 'COIL (Optional)' : 'COIL'}
                           value={this.state.fields.coil.value}
                           helperText={this.state.fields.coil.errors[0]}
                           maxValue={99.99}
                           onChange={this.handleFieldChange}
                           onBlur={this.onFieldBlur}
                           disabled={shouldSelectLoads}
                         />
                       </div>
                    )}
                    {showOilAndAdmixFields && (
                       <div className={'col-xs-6 form-wrap'}>
                         <NumberField
                           id='impu'
                           setRef={this.fieldRef['impu']}
                           label={isEmpty(this.state.fields.impu.validators) ? 'IMPU (Optional)' : 'IMPU'}
                           value={this.state.fields.impu.value}
                           helperText={this.state.fields.impu.errors[0]}
                           onChange={this.handleFieldChange}
                           maxValue={99.99}
                           onBlur={this.onFieldBlur}
                           disabled={shouldSelectLoads}
                         />
                       </div>
                    )}
                    <div className='col-xs-6 form-wrap'>
                      <CommonTextField
                        id='referenceNumber'
                        label='Reference No. (Optional)'
                        helperText={this.state.fields.referenceNumber.errors[0]}
                        onChange={this.handleFieldChange}
                        onBlur={this.onFieldBlur}
                        value={this.state.fields.referenceNumber.value}
                      />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <Notes
                        title='Comments'
                        handler={this.noteHandler}
                        note={this.state.fields.note}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
                {
                  !isEmpty(this.state.selectedCanolaLoads) &&
                    <div className='col-xs-12 cardForm-action top15 padding-reset' style={{marginBottom: '15px'}}>
                      Total Transfer Tonnage: {this.totalTransferrableCanolaTonnage()}{priceUnit}
                    </div>
                }
                <div className='col-xs-12 cardForm-action top15 padding-reset'>
                  <CommonButton type='button' variant='outlined' label='Cancel' default onClick={this.props.closeDrawer} />
                  <CommonButton primary={true} variant="contained" label={openCanolaLoads ? 'Transfer' : (shouldSelectLoads ? 'Select Loads' : 'Save')} type='submit' />
                </div>
              </div>
            </React.Fragment>
          }
          {this.state.contractDetailsDialog &&
            <Dialog
              open={this.state.contractDetailsDialog}
              onClose={() => this.setState({contractDetailsDialog: false})}
              aria-labelledby="form-dialog-title"
              fullWidth
              onClick={(e) => e.stopPropagation()}
              disableEscapeKeyDown
            >
            <DialogTitle id="form-dialog-title">
              Title Transfer
            </DialogTitle>
            <DialogContent>
              <Typography>
              A contract matching this identifier already exists in the system. Please confirm if you are trying to create this Title Transfer against this contract or want to add this Title Transfer as independent Transfer?
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="xsmall">Field Name</TableCell>
                    <TableCell className="medium">Contract Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.entries(this.state.tableData).map(([key, value], index)=> {
                    return (
                      <TableRow key={index}>
                        <TableCell className="xsmall">{key}</TableCell>
                        <TableCell className="medium">{value}</TableCell>
                      </TableRow>
                    )
                  })
                }
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <CommonButton onClick={() => this.clearTTForm()} key='independent' label='Create Independent' secondary variant="contained" />
              <CommonButton onClick={() => this.populateContractDetails()} key='against_contract' label='Create Against Contract' primary variant="contained" />
            </DialogActions>
          </Dialog>
          }
          {this.state.showEmailDialog &&
           <CustomEmailDialog
             parties={this.state.emailPopupParties}
             selectedParties={this.getSelectedParties()}
             title="Email PDF copies to"
             partyEmails={this.getPartyEmails()}
             partyContacts={this.getPartyContacts()}
             subject={this.getEmailSubject()}
             noBody={true}
             footer={this.getFooterNote()}
             open={this.state.showEmailDialog}
             onClose={this.closeEmailDialog}
             disableAcceptanceRequired={true}
             disabledPartiesForEmail={this.getDisabledPartiesForEmail()}
             disableLater={this.isLaterDisabled()}
           />
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    serverErrors: state.companies.contracts.titleTransferErrors,
    varieties: state.master.varieties.items || [],
    currentUser: state.main.user.user,
    allCompanyParties: state.companies.companies.companyParties,
    selectedTitleTransfer: state.companies.contracts.selectedTitleTransfer,
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(TitleTransferForm);
