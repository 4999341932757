import React from 'react';

import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import {get, includes, upperFirst, isArray, map } from 'lodash';

import {
  convertEpochToDateFormat, convertEpochToDateTimeFormat
} from '../../../common/momentUtilities';
import Number from '../../../services/Number'

import { InvoiceDetailsAction } from "./InvoiceDetailsAction";
import InvoiceAcceptRejectForm from "../InvoiceAcceptRejectForm";
import { ChipLabel } from '../../common/ChipLabel';
import Contract from '../../contracts/Contract'
import Invoice from '../Invoice'
import PaymentRun from '../PaymentRun'

const renderBasedOnStatus = status => {
  const style={width: '24px', height: '24px'}
  switch (status) {
  case 'Action Pending':
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  case 'Awaiting Confirmation':
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  case 'Awaiting Payment Confirmation':
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  case 'Pending Payment (Overdue)':
    return (
      <img style={style} src="images/pending-payment-overdue.svg"></img>
    );
  case 'Awaiting Payment (Overdue)':
    return (
      <img style={style} src="images/pending-payment-overdue.svg"></img>
    );
  case 'Awaiting Payment':
    return (
      <img style={style} src="images/pending-payment.svg"></img>
    );
  case 'Rejected':
    return (
      <img style={style} src="images/rejected.png"></img>
    );
  case 'Pending Payment':
    return (
      <img style={style} src="images/pending-payment.svg"></img>
    );
  case 'Paid':
    return (
      <img style={style} src="images/paid.png"></img>
    );
  case 'Generated':
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  case 'Confirmed':
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  case 'Overpaid':
    return (
      <img style={style} src="images/overpaid.svg"></img>
    );
  case 'Partially Paid':
    return (
      <img style={style} src="images/partially_paid.svg"></img>
    );
  case 'Void':
    return (
      <img style={style} src="images/rejected.png"></img>
    );
  default:
    return (
      <img style={style} src="images/awaiting-confirmation.svg"></img>
    );
  }
};

const getSectionWithUrl = (title, identifier, created, url = null, linkProps={}, xeroError=null) => {
  const _props = linkProps || {}
  return (
    <li className="flex-direction-column">
      <span className="field-label" key="label">{title}</span>
      <span className="field-value" key="value">
        {
          url ?
            <a href={url} {..._props}>{identifier}</a> :
          identifier
        }
      </span>
      {
        isArray(created) ? (
          map(created, (_created, index) => (
            <span key={index} className="created-at" style={{textAlign: 'center'}}>{_created}</span>
          ))
        ) : (
          created &&
            <span className="created-at" key="created-at" style={{textAlign: 'center'}}>{created}</span>
        )
      }
      {
        xeroError?.error &&
          <React.Fragment>
            <span style={{color: 'red', textAlign: 'center', maxWidth: '200px', fontSize: '14px', paddingTop: '8px'}}>
              {xeroError.error}
            </span>
            <span style={{color: 'red', textAlign: 'center', fontSize: '12px', fontWeight: '300', paddingTop: '2px'}}>
              Attempted Sync: {convertEpochToDateTimeFormat(xeroError.createdAt)}
            </span>
          </React.Fragment>
      }
    </li>
  );
};

const toCurrency = (currency, number) => new Number(number, currency).formatted

export const InvoiceStatusBar = props => {
  const { invoiceDetails, status, warehouse, currentUserParty } = props;
  const { xeroInvoiceUrl, isCreatingXeroInvoice, xeroSyncedAt, currency } = invoiceDetails
  const statusDisplayName = warehouse ? invoiceDetails.warehouseStatus : (status ? upperFirst(status) : upperFirst(invoiceDetails.statusDisplayName));
  const voidRequest = !invoiceDetails.voidable && invoiceDetails.isVoidRequestPending ? true : false;
  const isValidInvoice = !includes(['rejected', 'void'], invoiceDetails?.status)
  const systemFees = warehouse ? invoiceDetails.systemAmount : get(invoiceDetails, 'totalDetails.total');
  const identifier = get(invoiceDetails, 'identifier');
  const raisedForNo = get(invoiceDetails, 'raisedForNo');
  const xeroLastSyncedAt = xeroSyncedAt ? convertEpochToDateFormat(xeroSyncedAt) : null;
  const amountPayable = parseFloat(get(invoiceDetails, 'amountPayable', 0)).toFixed(2);
  const amountPaid = parseFloat(get(invoiceDetails, 'amountPaid', 0)).toFixed(2);
  const onCreateXeroInvoiceClick = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onCreateXeroClick()
  }


  let xeroSyncedAtSubLabels = []
  if(xeroLastSyncedAt)
    xeroSyncedAtSubLabels = [`Synced at: ${xeroLastSyncedAt}`]
  if(invoiceDetails?.xeroPaymentSyncedAt)
    xeroSyncedAtSubLabels.push(`Payment synced at: ${convertEpochToDateFormat(invoiceDetails.xeroPaymentSyncedAt)}`)

  const paymentRunId = invoiceDetails?.canViewPaymentRun ? invoiceDetails?.paymentRunId : false
  const externalError = invoiceDetails?.externalError
  const payable = new Number(amountPayable, currency)

  return (
    <Paper className='invoice-status new'>
      <ul className="invoice-status-bar new">
        <li className="status flex-direction-column new">
          <span style={{display: 'flex', alignItems: 'center'}}>
            {renderBasedOnStatus(statusDisplayName)}
            <span className="field-value" style={{marginLeft: '8px'}}>{statusDisplayName}</span>
          </span>
          {
            props.canSwitchToNewView &&
              <span>
                <FormControlLabel
                  sx={{margin: '0 0 -20px 0', '.MuiTypography-root': {fontSize: '14px'} }}
                  control={
                    <Switch
                      size='small'
                      checked={props.newView}
                      onChange={event => props.onViewChange(event.target.checked)} />
                  }
                  label="Simplified View"
                />
              </span>
          }
        </li>
        {
          !warehouse && identifier &&
            <Invoice invoice={invoiceDetails} noLink />
        }
        {
          xeroInvoiceUrl &&
            getSectionWithUrl('Xero Invoice', 'Open in Xero', xeroLastSyncedAt ? xeroSyncedAtSubLabels : false, xeroInvoiceUrl, {target: '_blank', rel: 'noreferrer noopener', style: {fontSize: '12px', display: 'flex', textAlign: 'center'}}, externalError)
        }
        {
          !xeroInvoiceUrl && isCreatingXeroInvoice && currentUserParty?.isXeroAccountLinked &&
            getSectionWithUrl('Xero Invoice', 'Creating...')
        }
        {
          !xeroInvoiceUrl && !isCreatingXeroInvoice && currentUserParty?.isValidXeroConnection && isValidInvoice &&
            getSectionWithUrl(
              'Xero Invoice',
              props.xeroMode ? 'Draft Invoice' : (invoiceDetails.hasCounterPartyXeroContact ? 'Create' : "Cannot create in Xero as counter party does not exist as a Contact"),
              false,
              !props.xeroMode,
              {
                style: invoiceDetails.hasCounterPartyXeroContact ?
                  {fontSize: '12px', cursor: 'pointer'} :
                {fontSize: '12px', color: 'red', textDecoration: 'none', display: 'flex', textAlign: 'center', maxWidth: '150px'},
                onClick: invoiceDetails.hasCounterPartyXeroContact ? onCreateXeroInvoiceClick : null
              },
              props?.xeroMode ? false : externalError
            )
        }
        {
          !xeroInvoiceUrl && !currentUserParty?.isValidXeroConnection && currentUserParty?.isXeroAccountLinked && isValidInvoice &&
            getSectionWithUrl('Xero Invoice', 'Re-Connect', 'Xero Connection is invalidated, please Re-Connect', `/#/companies/${currentUserParty?.companyId}/integrations`)
        }
        {
          raisedForNo &&
            <Contract contract={{identifier: raisedForNo, id: invoiceDetails?.raisedForId, createdAt: invoiceDetails?.raisedForCreatedAt }} />
        }
        {
          paymentRunId &&
            <PaymentRun paymentRun={{identifier: invoiceDetails?.paymentRunIdentifier, id: paymentRunId, createdAt: invoiceDetails.paymentRunCreatedAt}} />
        }
        <li style={{display: 'flex', flexDirection: 'column', alignItems: 'unset', fontSize: '14px'}}>
          {
            Boolean(systemFees) &&
              <span style={{display: 'flex', align: 'center', justifyContent: 'space-between'}}>
                <span style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                  Total
                </span>
                <span>
                  {toCurrency(currency, systemFees)}
                </span>
              </span>
          }
          <span style={{display: 'flex', align: 'center', justifyContent: 'space-between'}}>
            <span style={{color: 'rgba(0, 0, 0, 0.6)'}}>
              Paid
            </span>
            <span>
              {new Number(amountPaid, currency).formatted}
            </span>
          </span>
          {
            payable.number ?
              <span style={{display: 'flex', align: 'center', justifyContent: 'space-between'}}>
                <span style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                  Payable
                </span>
                <span>
                  {payable.formatted}
                </span>
              </span> : null
          }
          {
            Boolean(systemFees) &&
              <span style={{display: 'flex', align: 'center', justifyContent: 'space-between'}}>
                <span style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                  Due
                </span>
                <span>
                  {convertEpochToDateFormat(invoiceDetails.paymentDueDate)}
                </span>
              </span>
          }
        </li>
        {
          !warehouse && voidRequest && <ChipLabel label='Void Request Sent' />
        }

      </ul>
      {
        !warehouse &&
          <div className="status-actions">
            <InvoiceAcceptRejectForm {...props} />
            <InvoiceDetailsAction {...props} />
          </div>
      }
    </Paper>
  );
};
