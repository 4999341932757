import React from 'react';

import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import { VALUE_NOT_SELECTED } from '../../../common/validators';
import APIService from '../../../services/APIService';
import { getCommoditiesWithGrades } from '../../../actions/api/commodities';
import { receiveGrades } from '../../../actions/master/commodities';
import AutoComplete from './AutoComplete';
import { getPoolGrades } from '../../../common/utils';
import { COMMODITIES } from '../../../common/constants';

class GradeAutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      grades: [],
      allGrades: [],
      selectedGradeId: null,
      fetchedGrades: [],
      fetchingGradesQueue: [],
    };

    this.handleNewRequest = this.handleNewRequest.bind(this);
    this.handleUpdateInput = this.handleUpdateInput.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.state.grades) && isEmpty(this.state.allGrades) && !this.props.noFetch) {
      this.props.getCommoditiesWithGrades();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      commodityId, selectedGradeId, grades, varietyId, dependsOnCommodity, onChange, id, specs,
      gradeName, gradeId, marketZoneId, fixedGrades, selectedVarietyId
    } = this.props;
    if(selectedGradeId && (prevProps.selectedGradeId !== selectedGradeId || this.state.selectedGradeId !== selectedGradeId) ){
      this.setState({
        selectedGradeId: selectedGradeId
      });
    }
    if(this.state.allGrades < grades && !isEmpty(grades) && !isEqual(this.state.allGrades, grades) && commodityId <= 5027)
      this.setState({allGrades: grades});

    if(commodityId && !varietyId && !isEqual(grades, this.state.grades)){
        const gradeFound = find(this.state.grades, { commodityId: commodityId });
        if(!gradeFound)
            this.setState({grades: grades});
    }

    if (
      dependsOnCommodity &&
      prevProps.commodityId &&
      commodityId !== prevProps.commodityId &&
      this.state.selectedGradeId
    )
      this.setState(
        {searchText: '', selectedGradeId: null},
        () => onChange('', id)
      );

    if (varietyId === VALUE_NOT_SELECTED && this.state.selectedGradeId && !this.props.disabled) {
      this.setState({
        searchText: '',
        grades: [],
        selectedGradeId: null,
      }, () => onChange({id: VALUE_NOT_SELECTED, specs: specs}, id)
      );
    } else if (gradeName && gradeName != this.state.searchText) {
      this.setState(
        {searchText: gradeName, selectedGradeId: gradeId},
        () => onChange({id: gradeId, specs: specs}, id)
      );
    } else if (
      (commodityId != prevProps.commodityId && prevProps.commodityId) ||
      (varietyId != prevProps.varietyId) ||
      (marketZoneId != prevProps.marketZoneId && prevProps.marketZoneId)
    ) {
      this.setStateGradesBasedOnProps();
      if (
        prevProps.commodityId !== VALUE_NOT_SELECTED &&
        prevProps.varietyId !== VALUE_NOT_SELECTED &&
        prevProps.commodityId == COMMODITIES.WHEAT &&
        this.state.selectedGradeId &&
        !this.props.disabled
      ) {
        this.setState(
          {searchText: '', selectedGradeId: null},
          () => onChange('', id)
        );
      }
    }
    else if(fixedGrades && !isEmpty(fixedGrades) && !isEqual(this.state.grades, fixedGrades))
      this.setState({grades: fixedGrades});

    if (
      dependsOnCommodity &&
      (gradeId || prevProps.gradeId) &&
      !isEmpty(grades) &&
      isEmpty(this.state.searchText)
    ){
      const grade = find(grades, {id: gradeId, commodityId: commodityId});
      if (grade)
        this.setState({searchText: grade.name, selectedGradeId: grade.id});
    }

    if (prevProps.gradeId !== gradeId && !gradeId && !this.props.disabled)
      this.setState({ searchText: '', selectedGradeId: null });
    if(selectedVarietyId && commodityId && !isEqual(grades, prevState.grades) && commodityId === COMMODITIES.WHEAT)
      this.getGradesFilterByVariety();
  }

  isVarietyHasGrade = () => {
    if (!this.props.disabled && typeof this.props.varietyWarning === 'function') {
      this.getVarietyGrades();
      // this.props.varietyWarning("Grade doesn't belong to selected Variety.");
      return true;
    }
  };

  handleNewRequest(chosenRequest, value) {
    this.setState(state => ({
      ...state,
      selectedGradeId: value ? chosenRequest.id : null,
    }));
    this.props.onChange(value ? chosenRequest : VALUE_NOT_SELECTED, this.props.id);
  }

  handleUpdateInput(searchText) {
    if (isEmpty(this.state.grades)) {
      this.setStateGradesBasedOnProps();
    }

    this.setState(
      state => ({ ...state, searchText, selectedGradeId: null }),
      () => this.props.onChange(VALUE_NOT_SELECTED, this.props.id),
    );
  }

  setStateGradesBasedOnProps() {
    const commodity = find(this.props.commodities, {id: this.props.commodityId});
    const variety = find(this.props.varieties, {id: this.props.varietyId, commodityId: this.props.commodityId});
    const isWheat = this.props.commodityId === COMMODITIES.WHEAT
    const varietyId = this.props.varietyId
    const zone = get(this.props.farm, 'marketZone.wheatClassificationZone')
    if (commodity && this.props.dependsOnCommodity && !this.props.varietyId) {
      this.setState(state => ({ ...state, grades: this.state.allGrades}));
    } else if (
      !commodity ||
      (isWheat && !varietyId) ||
      (isWheat && !varietyId && !zone)
    ) {
      this.setState(state => ({ ...state, grades: [] }));
    } else if (variety && isWheat && zone) {
      const queryParams = {};
      queryParams['variety_id'] = variety.id;
      queryParams['zone'] = zone;
      const fetchedGrades = find(this.state.fetchedGrades, {queryParams: queryParams});
      if(fetchedGrades) {
        if(get(fetchedGrades, 'grades.0.commodityId') == get(commodity, 'id')) {
          this.setState({grades: fetchedGrades.grades});
        }
      }
      else if(!includes(this.state.fetchingGradesQueue, `${commodity.id}/${JSON.stringify(queryParams)}`)) {
        this.setState({fetchingGradesQueue: [...this.state.fetchingGradesQueue, `${commodity.id}/${JSON.stringify(queryParams)}`]}, () => {
          APIService.commodities(commodity.id).grades().get(undefined, undefined, queryParams)
                    .then( grades => {
                      const _fetchedGrades = {queryParams: queryParams, grades: grades};
                      const fetchedGrades = this.state.fetchedGrades;
                      fetchedGrades.push(_fetchedGrades);
                      if(this.props.isFreightMovementForm)
                        this.setState({grades: grades, fetchedGrades: fetchedGrades });
                      else
                        this.setState({grades: grades, fetchedGrades: fetchedGrades }, () => this.props.setGrades(this.state.grades));
                    });
        });
      }
    }
  }

  getGradesFilterByVariety() {
    const isWheat = this.props.commodityId === COMMODITIES.WHEAT
    const zone = get(this.props.farm, 'marketZone.wheatClassificationZone')
    if (isWheat && this.props.selectedVarietyId && zone) {
      const queryParams = {variety_id: this.props.selectedVarietyId};
      const fetchedGrades = find(this.state.fetchedGrades, {queryParams: queryParams});
      if(fetchedGrades) {
        if(get(fetchedGrades, 'grades.0.commodityId') === this.props.commodityId) {
          this.setState({grades: fetchedGrades.grades});
        }
      }
      else if(!includes(this.state.fetchingGradesQueue, `${this.props.commodityId}/${JSON.stringify(queryParams)}`)){
        this.setState({fetchingGradesQueue: [...this.state.fetchingGradesQueue, `${this.props.commodityId}/${JSON.stringify(queryParams)}`]}, () => {
          APIService.commodities(this.props.commodityId).grades().get(undefined, undefined, queryParams)
                  .then( grades => {
                    const _fetchedGrades = {queryParams: queryParams, grades: grades};
                    const fetchedGrades = this.state.fetchedGrades;
                    fetchedGrades.push(_fetchedGrades);
                    this.setState({grades: grades, fetchedGrades: fetchedGrades }, () => this.props.setGrades(this.state.grades));
                  });
        });
      }
    }
  }

  getGrades = () => {
    return filter(isEmpty(this.state.grades) ? this.state.allGrades : this.state.grades, grade => {
      return this.props.commodityId && isEmpty(this.props.fixedGrades) ? this.props.commodityId === grade.commodityId : true;
    });
  };

  shouldDisplayBarleyFTypeGradeBySeason() {
    const { commodityId, season, dependsOnSeason } = this.props;
    return commodityId == COMMODITIES.BARLEY && dependsOnSeason && includes(['16/17', '17/18', '18/19'], season);
  }

  getItems = () => {
    const { removeUngraded, itemFilterFunc } = this.props;
    let gradeItems = this.getGrades();
    if (itemFilterFunc)
      gradeItems = itemFilterFunc(gradeItems)
    return compact(
      map(gradeItems, grade => {
        const data = { label: grade.name, value: grade.id, order: grade.order };

        if (removeUngraded && grade.name.match('UNGRADED')) return;

        if (this.shouldDisplayBarleyFTypeGradeBySeason() && includes(['BAR1', 'BAR2', 'BAR3'], grade.name)) data['label'] = grade.name.replace('BAR', 'F');

        data['name'] = data['label'];
        data['id'] = data['value'];
        return data;
      }),
    );
  };

  handleChange = data => value => {
    const chosenRequest = value ? find(data, { id: value }) : { id: '', name: '' };
    this.handleNewRequest(chosenRequest, value);
  };

  render() {
    const items = this.getItems();
    const isDisabled =
      isEmpty(items) ||
      (includes([null, undefined], this.props.disabled) ? isNull(this.state.selectedGradeId) && isEmpty(this.props.grades) : this.props.disabled) ||
      (this.props.commodityId && this.props.commodityId > 5027 && isEmpty(items));
    return this.props.displayNameOnly ? (
      <div className='relative-pos'>
        <label style={{ color: '#757575' }}>{this.props.floatingLabelText || 'Grade'}</label>
        <br />
        {getPoolGrades(items)}
      </div>
    ) : (
      <div className='relative-pos' ref={this.props.setRef}>
        <AutoComplete
          id={this.props.id}
          placeholder={this.props.floatingLabelText || 'Grade'}
          options={orderBy(items, ['order', 'name'])}
          value={this.state.selectedGradeId}
          label={this.props.floatingLabelText === false ? undefined : this.props.floatingLabelText || 'Grade'}
          onBlur={this.props.onBlur}
          errorText={this.props.errorText}
          disabled={isDisabled}
          onChange={this.handleChange(items)}
          style={this.props.style}
          suffix={this.props.nameSuffix}
          variant={this.props.variant}
        />
        {this.props.disabled && this.state.selectedGradeId ? <i style={this.props.style} className='icon-lock'></i> : ''}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
    varieties: state.master.varieties.items,
    grades: state.master.grades.items,
    farm: state.companies.farms.selectedFarm,
  };
};

const mapDispatchToProps = dispatch => ({
  getCommoditiesWithGrades: () => dispatch(getCommoditiesWithGrades()),
  setGrades: grades => dispatch(receiveGrades(grades)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GradeAutoComplete);
