import { connect } from 'react-redux';
import { some } from 'lodash'
import GenericTable from '../components/GenericTable';
import { clickEditEmployeeIcon, deleteEmployeeAction } from '../actions/company-settings/employees';
import { upsert, unlink } from '../actions/companies/key-contacts';
import {
  isInMyCompanyContext, isSuperuser, isCurrentUserBelongsToCompany,
  isCompanyAdminOrObserver,
  isStaff
} from '../common/utils';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies/index';
import { deleteEmployee } from '../actions/api/employees';
import alertifyjs from 'alertifyjs';
import { OBSERVER_TYPE_ID } from '../common/constants';


const mapStateToProps = state => {
  const tooltipTextFunc = item => item.keyContact ? 'Unmark this person as your key contact' : 'Mark this person as your key contact';

  var employees = state.companySettings.employees.items;
  var isSystemCompanyEmployees = some(employees, 'isStaff')
  var companyId = employees ? employees[0]?.companyId : null;
  const isSuperUser = isSuperuser()
  const keyContactTab = { key: 'keyContact', header: 'Key Contact', checkbox: true, onChange: 'handleKeyContactClick', tooltipTextFunc: tooltipTextFunc };

  let columns = [
    { key: 'firstName', header: 'First Name', },
    { key: 'lastName', header: 'Last Name' },
    { key: 'type.displayName', header: 'Role' },
    { key: 'linkedFarmNames', header: 'Farm/Site' },
    { key: 'title', header: 'Job Title' },
    { key: 'mobile', header: 'Mobile' },
    { key: 'email', header: 'Email' }
  ];

  if (!isInMyCompanyContext()) {
    columns.splice(0, 0, keyContactTab);
  }

  if(isSystemCompanyEmployees)
    columns = [...columns, {key: 'alias', header: 'Alias'}, {key: 'isStaff', header: 'Staff', type: 'bool'}, {key: 'isSuperuser', header: 'SuperUser', type: 'bool'}]

  let config = {
    columns: columns,
    items: employees,
    scrollToTopOnUpdate: false,
    orderBy: 'firstName',
    order: 'asc',
    optionsItems: [],
    displayIDColumn: 'firstName'
  };

  if (isSuperUser) {
    config.optionsItems.push({ key: 'transfer', text: 'Transfer' });
    config.optionsItems.push({ key : 'merge', text: 'Merge' });
  }
  if (
      isSystemCompanyEmployees ?
      isSuperUser :
      (
        (isCompanyAdminOrObserver() && isCurrentUserBelongsToCompany(companyId)) ||
        isSuperUser ||
        isStaff()
      )
    )
    config.optionsItems.push({ key: 'delete', text: 'Delete' });

  return config;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDefaultCellClick: rowNum => {
      if(rowNum?.typeId == OBSERVER_TYPE_ID)
        alertifyjs.error("Update functionality not allowed for observer type employees")
      else
        dispatch(clickEditEmployeeIcon(rowNum));
    },
    handleKeyContactClick: (selectedItem, isChecked) => {
      if (isChecked) {
        dispatch(upsert({ employeeId: selectedItem.id }, 'companies', selectedItem.companyId));
      } else {
        dispatch(unlink('companies', selectedItem.companyId));
      }
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        dispatch(showSideDrawerForTransfer(item, true));
      }
      if (key === 'delete') {
        if(item.typeId == OBSERVER_TYPE_ID)
          alertifyjs.error("Delete functionality not allowed for observer type employees")
        else
          dispatch(deleteEmployee(item, deleteEmployeeAction));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);
