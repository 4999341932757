import React from 'react';
import { connect } from 'react-redux';

import alertifyjs from 'alertifyjs';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import pick from 'lodash/pick';
import isString from 'lodash/isString';
import TextField from "@mui/material/TextField";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { FIELD, REQUIRED_FIELD } from '../../common/constants';
import { mobileRegex, emailRegex, minLength, required } from '../../common/validators';
import { isStaff } from '../../common/utils';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import { clearMyProfileUpdateErrors } from '../../actions/main';
import APIService from '../../services/APIService';
import { myProfileUpdated } from '../../actions/main';
import PhoneField from '../common/PhoneField';
import { getCurrentCountry, copyToClipboard } from '../../common/utils';

class MyProfileDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: getCurrentCountry(),
      showTransactionPassword: false,
      model: {
        firstName: {
          ...REQUIRED_FIELD,
          value: get(props.user, 'firstName', ''),
        },
        lastName: {
          ...REQUIRED_FIELD,
          value: get(props.user, 'lastName', ''),
        },
        mobile: {
          ...FIELD,
          validators: [mobileRegex()],
          value: get(props.user, 'mobile', ''),
        },
        email: {
          ...FIELD,
          validators: [emailRegex()],
          value: get(props.user, 'email', ''),
        },
        username: {
          ...FIELD,
          validators: [required(), minLength(3)],
          value: get(props.user, 'username', ''),
        },
        transactionPassword: {
          value: '',
          validators: [],
          errors: []
        }
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillUnmount() {
    if (!isEmpty(this.props.errors)) {
      this.props.clearErrors();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.errors, prevProps.errors)) {
      const newState = {...this.state};

      forEach(['username', 'mobile', 'email'], field => {
        set(
          newState.model[field].errors,
          get(this.props, `errors.${field}`),
          newState.model[field].errors
        );
      });

      this.setState(newState);
    }
  }

  isEmailUsernameUpdated = () => {
    const { user } = this.props
      const { model } = this.state
    return (model.username.value != user.username) || (model.email.value && model.email.value != user.email)
  }

  handleSubmit(event) {
    event.preventDefault();
    const newState = {...this.state}
    newState.model.transactionPassword.errors = this.isEmailUsernameUpdated() && !this.state.model.transactionPassword.value ? [true] : []
    this.setState(newState, () => {
      if(this.state.model.transactionPassword.errors.length)
        return
      this.setAllFieldsErrors();
      if (this.getIsFormValid()) {
        const data = this.getSubmitData();
        APIService.companies(
          this.props.user.companyId
        ).employees(
          this.props.user.id
        ).put(
          data, this.props.token
        ).then(item => {
          if(!isEmpty(item.errors)){
            const newState = {...this.state};
            forEach(['username', 'mobile', 'email', 'transactionPassword'], field => {
              newState.model[field].errors = get(
                item, `errors.[${field}]`, newState.model[field].errors
              );
            });
            this.setState(newState);
          } else {
            const myProfileFields = pick(
              item,
              ['title', 'firstName', 'lastName', 'type', 'mobile',
               'email', 'farm', 'username']
            );
            this.props.updateLocalProfile(myProfileFields);
            alertifyjs.success('Profile details were successfully updated');
          }
        });
      }
    })
  }

  handleFieldChange(event) {
    this.setFieldValue(`model.${event.target.id}`, event.target.value, false);
  }

  handleBlur(event) {
    this.setFieldValue(`model.${event.target.id}`, event.target.value);
  }

  setFieldValue(path, value, validateAfterSet = true) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (validateAfterSet) {
          this.setFieldErrors(path);
        }
      }
    );
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  getFieldErrors(path) {
    let errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    if(path === 'model.mobile' && value) {
      if(!value.match(this.state.country?.config?.phoneMobileRegex)) {
        errors.push(validators[0].message)
      }
    } else {
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });

    }

    return errors;
  }

  setAllFieldsErrors() {
    let newState = cloneDeep(this.state);

    forEach(newState.model, (field, fieldKey) => {
      const path = `model.${fieldKey}`;
      newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
    });

    this.setState(newState);
  }

  getIsFormValid() {
    return !some(this.state.model, (field, key) => {
      if(key === 'mobile')
        return !isEmpty(field.errors)
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  }

  getSubmitData() {
    let data = {};

    forEach(this.state.model, (field, fieldKey) => {
      data = set(data, fieldKey, get(this.state, `model.${fieldKey}.value`));
    });

    return data;
  }

  onCopyToken = event => {
    event.preventDefault()
    event.stopPropagation()
    const token = localStorage.getItem('token') || false
    if(token)
      copyToClipboard(token, 'Copied API Token to your clipboard', 0)
  }

  render() {
    const isDisabled = !this.props.user.isDefaultAuthEnabled || isStaff();
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm">
            <h4 className="cardForm-title">My Profile</h4>
            <div className="cardForm-content">
              <div className="col-md-5">
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="firstName"
                    label="First Name"
                    value={this.state.model.firstName.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleBlur}
                    helperText={this.state.model.firstName.errors[0]}
                    maxLength="50"
                    disabled={isDisabled}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="lastName"
                    label="Last Name"
                    value={this.state.model.lastName.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleBlur}
                    helperText={this.state.model.lastName.errors[0]}
                    maxLength="50"
                    disabled={isDisabled}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <PhoneField
                    id="mobile"
                    label="Mobile"
                    value={this.state.model.mobile.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleBlur}
                    helperText={this.state.model.mobile.errors[0]}
                    onInput = {(e) =>{
                      e.target.value = e.target.value.toString().slice(0,10);
                    }}
                    disabled={isDisabled}
                    countryCode={this.props.user?.countryCode}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="email"
                    type="email"
                    label="Email"
                    value={this.state.model.email.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleBlur}
                    helperText={this.state.model.email.errors[0]}
                    maxLength="100"
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <div className="col-md-5 col-md-offset-1">
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="company"
                    label="Company"
                    value={get(this.props, 'user.company.name', '')}
                    disabled
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="role"
                    label="Role"
                    value={get(this.props, 'user.type.displayName', '')}
                    disabled
                  />
                </div>
                { this.props.user.farm ?
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonTextField
                      id="farm"
                      label="Farm"
                      value={get(this.props, 'user.farm.name', '')}
                      disabled
                    />
                  </div> : ''
                }
                { this.props.user.site ?
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonTextField
                      id="site"
                      label="Site"
                      value={get(this.props, 'user.site.name', '')}
                      disabled
                    />
                  </div> : ''
                }
                <div className="col-md-12 form-wrap padding-reset">
                  <CommonTextField
                    id="username"
                    type="username"
                    label="Username"
                    value={this.state.model.username.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.handleBlur}
                    helperText={this.state.model.username.errors[0]}
                    maxLength="100"
                    disabled={isDisabled}
                  />
                </div>
                {
                  this.isEmailUsernameUpdated() &&
                    <div className="col-md-12 form-wrap padding-reset">
                    <TextField
                      fullWidth
                      variant='standard'
                      id="transactionPassword"
                      label="Enter your password"
                      value={this.state.model.transactionPassword.value}
                      onChange={this.handleFieldChange}
                      onBlur={this.handleBlur}
                      helperText={isString(this.state.model.transactionPassword.errors[0]) ? this.state.model.transactionPassword.errors[0] : "Email or Username update requires password validation"}
                      error={Boolean(this.state.model.transactionPassword.errors[0])}
                      type={this.state.showTransactionPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={() => this.setState({showTransactionPassword: !this.state.showTransactionPassword})}
                              size="large">
                              {this.state.showTransactionPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
              }

              </div>
            </div>
          </div>
          <div className="container-fluid row">
            <CommonButton
              type="submit"
              variant="contained"
              label="Save"
              primary
              disabled={isDisabled}
            />
            <CommonButton
              onClick={this.onCopyToken}
              variant="text"
              label="Copy API Token"
              sx={{textTransform: 'none'}}
              size='small'
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
  token: state.main.user.token,
  errors: state.main.user.errors,
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => dispatch(clearMyProfileUpdateErrors()),
  updateLocalProfile: data => dispatch(myProfileUpdated(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileDetails);
