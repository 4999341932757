import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import alertifyjs from 'alertifyjs';
import 'react-quill/dist/quill.snow.css';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import ChainOfResponsibilityForm from './ChainOfResponsibilityForm';
import { get } from 'lodash';
import { currentUserCompany, isSystemCompany } from '../../common/utils';
import { forceStopLoader, isLoading } from '../../actions/main';
import APIService from '../../services/APIService';
import GenericTable from '../GenericTable';
import { getCompanyDetails } from '../../actions/companies';

const COLUMNS = [
  {key: 'corName', header: 'COR Name', cellStyle: {width: '20%'}},
  {key: 'corText', header: 'COR Text', cellStyle: {width: '80%'}, richText: true},
]

const OPTION_ITEMS = [
  { key: 'edit', text: 'Edit' },
  { key: 'delete', text: 'Delete' }
]

const COR_NAME_MAPPING = {
  siteCor: 'Site COR',
  driverCor: 'Driver COR',
  vendorDecDriverCor: 'Vendor Dec Driver COR',
  vendorDecSiteEmployeeCor: 'Vendor Dec Employee COR',
  grainVendorDecCor: 'Grain Vendor Dec COR',
}

const COR_KEY_MAPPING = {
  'Site COR': 'siteCor',
  'Driver COR': 'driverCor',
  'Vendor Dec Driver COR': 'vendorDecDriverCor',
  'Vendor Dec Employee COR': 'vendorDecSiteEmployeeCor',
  'Grain Vendor Dec COR': 'grainVendorDecCor',
}

class ChainOfResponsibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      items: [],
      selectedItem: null
    };
  }

  componentDidMount() {
    if (this.props.selectedCompany)
      this.setChainOfResponsibilityItems();
    if (this.props.companyId && (!this.props.selectedCompany || this.props.companyId !== this.props.selectedCompany.id))
      this.props.getCompany(this.props.companyId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCompany && this.props.selectedCompany !== prevProps.selectedCompany)
      this.setChainOfResponsibilityItems();
  }

  setChainOfResponsibilityItems() {
    let corTexts = get(this.props.selectedCompany, 'corTexts') || {};
    const result = Object.entries(corTexts).map(([key, value]) => ({
      corName: get(COR_NAME_MAPPING, key),
      corText: value
    }));
    this.setState({items: result});
  }

  onOptionClick = (index, key, id, item) => {
    if (key === 'edit') {
      let key = get(COR_KEY_MAPPING, item.corName);
      this.setState({selectedItem: {key: key, value: item.corText}, openSideDrawer: true});
    }
    else if (key === 'delete') {
      alertifyjs.confirm(
        'Delete Chain Of Responsibility',
        `Are you sure you want to delete ${item.corName}?`,
        () => { 
          let corData = get(this.props.selectedCompany, 'corTexts') || {};
          let key = get(COR_KEY_MAPPING, item.corName);
          delete corData[key];
          APIService.companies(this.props.companyId)
            .put({'corTexts': corData})
            .then(() => this.props.getCompany(this.props.companyId))
        },
        () => {}
      );
    }
  }

  onDefaultClick = item => {
    let key = get(COR_KEY_MAPPING, item.corName);
    this.setState({selectedItem: {key: key, value: item.corText}, openSideDrawer: true});
  }

  refreshData = () => {
    if (this.props.companyId)
      this.props.getCompany(this.props.companyId);
    this.setState({openSideDrawer: false})
  }

  render() {
    const canCreateCor = currentUserCompany().id === get(this.props.selectedCompany, 'id') || isSystemCompany();
    return (
      <Paper className='paper-table'>
        <div>
          <AddButton
            label="Chain Of Responsibility"
            onClick={() => this.setState({openSideDrawer: true})}
            disabled={!canCreateCor}
          />
        </div>
        <GenericTable
          columns={COLUMNS}
          items={this.state.items}
          optionsItems={canCreateCor ? OPTION_ITEMS : []}
          diaplayIdColumn='corName'
          handleOptionClick={this.onOptionClick}
          handleDefaultCellClick={this.onDefaultClick}
        />
        <SideDrawer
          isOpen={this.state.openSideDrawer}
          title="Add Chain Of Responsibility"
          onClose={() => this.setState({openSideDrawer: false, selectedItem: null})}
        >
          <ChainOfResponsibilityForm selectedItem={this.state.selectedItem} refreshData={this.refreshData} selectedCompany={this.props.selectedCompany} companyId={this.props.companyId} closeDrawer={() => this.setState({openSideDrawer: false, selectedItem: null})} />
        </SideDrawer>
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  const user = state.main.user.user;
  return {
    headerText: state.main.headerText,
    user: user,
    selectedCompany: state.companies.companies.selectedCompany,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompany: companyId => dispatch(getCompanyDetails(companyId)),
    isLoading: () => dispatch(isLoading()),
    forceStopLoader: () => dispatch(forceStopLoader())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChainOfResponsibility);
