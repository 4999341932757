import React from 'react';
import InvoiceIcon from '../common/icons/Invoice';
import TransactionBase from '../common/TransactionBase'

const PaymentRun = ({ paymentRun, noLink }) => {
  return (
    <TransactionBase
      icon={<InvoiceIcon fill='#000' width="34" height="34" noStyle />}
      sx={{
        '.MuiListItemIcon-root': {minWidth: 'auto', marginRight: '4px'},
      }}
      transaction={paymentRun}
      url={`#/invoices/payment-runs/${paymentRun.id}`}
      noLink={noLink}
    />
  )
}

export default PaymentRun
