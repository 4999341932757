import React from 'react';
import { connect } from 'react-redux';
import { getInvoicesWeb, getPaginatedInvoicesResponse } from '../../actions/companies/invoices';
import SideDrawer from '../common/SideDrawer';
import { setHeaderText, setSubHeaderText, setBreadcrumbs, isLoading, forceStopLoader, isSearchApplied } from '../../actions/main';
import {
  getContractSubHeaderText, isGlobalInvoicesPath, attachCSVEventListener, isAtGlobalInvoices, getDateTimeInUTC,
  defaultViewAction,
} from "../../common/utils";
import CreateCommodityContractInvoice from '../../containers/CreateCommodityContractInvoice';
import InvoicesTable from '../../containers/InvoicesTable';
import CreateBrokerageInvoice from '../../containers/CreateBrokerageInvoice';
import { BROKER, CONTRACT_TYPES, DEFAULT_INVOICES_TABLE_COLUMN_LIMIT, FILTER_KEYS_TO_EXCLUDE, INVOICES_TABLE_COLUMN_LIMIT, INVOICE_FILTER_KEYS_MAPPING, INVOICE_TABLE_GLOBAL_LISTING_HEADERS, PREDEFINED_DATE_RANGE_FILTER_KEYS } from '../../common/constants';
import alertifyjs from 'alertifyjs';
import {
  COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID
} from '../../common/constants';
import APIService from "../../services/APIService";
import { setDownloadBar } from '../../actions/main';
import CommonListingButton from '../common/CommonListingButton';
import Button from '@mui/material/Button/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import InvoiceFilters from '../common/InvoiceFilters';
import FiltersAppliedChip from '../common/FiltersAppliedChip';
import { titleTransfersResponse } from '../../actions/companies/contracts';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import { get, isEmpty, isEqual, includes } from 'lodash';
import { Checkbox, Dialog, DialogActions, DialogContent, Tooltip, Paper  } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { CARRY_ITEM_TYPE, CUSTOM_ITEM_TYPE, EPR_ITEM_DB_TYPE, GRAIN_LEVY_ITEM_TYPE } from './Constants';
import CommonDatePicker from '../common/CommonDatePicker';
import DownloadDataDialog from '../common/DownloadDataDialog';
import {set, forEach} from 'lodash';
import CommonSelect from '../common/select/CommonSelect';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';

const invoiceTypes = [{ id: 'Commodity Contract', name: 'Commodity Contract' }];
const invoiceItemTypes = [
  { id: 'movement_and_title_transfer', name: 'Freight Movement / Title Transfer' },
  { id: EPR_ITEM_DB_TYPE, name: 'EPR' },
  { id: GRAIN_LEVY_ITEM_TYPE, name: 'Grain Levy' },
  { id: CARRY_ITEM_TYPE, name: 'Carry' },
  { id: CUSTOM_ITEM_TYPE, name: 'Custom' },
]
const invoiceStatuses = [
  {id: "paid", name: "Overpaid/Paid"},
  {id: "generated", name: "Action Pending/Awaiting Confirmation"},
  {id: "pending_payments", name: "Pending Payment Confirmation/Awaiting Payment Confirmation"},
  {id: "confirmed", name: "Pending Payment/Awaiting Payment"},
  {id: "rejected", name: "Rejected"},
  {id: "void", name: "Void"}
]

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applyFilters: false,
      csvData: [],
      openSideDrawerForContractInvoice: false,
      openSideDrawerForBrokerageInvoice: false,
      filters: {},
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
      invoiceLineItemsPopup: false,
      daffLevyReportPopup: false,
      invoiceItemsFilters: {
        invoice__type: '',
        item_type__model: '',
        invoice__created_at__gte: '',
        invoice__created_at__lte: '',
        invoice__payer__company_id__in: [],
        invoice__payee__company_id__in: [],
        invoice__status__in: [],
      },
      daffLevyFilters: {
        created_at__gte: '',
        created_at__lte: '',
        payer__company_id__in: [],
        payee__company_id__in: [],
        status__in: [],
      },
      eprSummary: true,
      includePlanned: false,
      levySummary: true,
      showEprSummary: false,
      showLevySummary: false,
      errors: {
        invoice__type: '',
        item_type__model: ''
      },
      isFilteredCsv: false,
      csvPopup: false,
      searchView: false,
      type: null,
      customColumns: true
    };
    this.openSideDrawerForContractInvoice = this.openSideDrawerForContractInvoice.bind(this);
    this.openSideDrawerForBrokerageInvoice = this.openSideDrawerForBrokerageInvoice.bind(this);
    this.closeSideDrawerForContractInvoice = this.closeSideDrawerForContractInvoice.bind(this);
    this.closeSideDrawerForBrokerageInvoice = this.closeSideDrawerForBrokerageInvoice.bind(this);
    this.handleActionsClose = this.handleActionsClose.bind(this);
    this.onHandleAddInvoiceButtonClick = this.onHandleAddInvoiceButtonClick.bind(this);
    this.onHandleAddBrokerageInvoiceButtonClick = this.onHandleAddBrokerageInvoiceButtonClick.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.handleSelectFilterChange = this.handleSelectFilterChange.bind(this);
    this.updateColumnCount = this.updateColumnCount.bind(this);
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  resetFilters = () => {
    this.setState({filters: {}, applyFilters: false, openSideDrawer: false}, () => this.handleFilterState('applyFilters', true))
  }

  handleFilterState = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      if(key === 'applyFilters' && value) {
        const { filters } = this.state;
        APIService
          .profiles()
          .filters()
          .post({ invoice: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.invoice', {})}, () => {
              this.props.isLoading();
              this.props.getInvoicesDispatchFunc();
            })
          });
      }
    });
  };

  handleCustomEmailDialog = state => {
    this.setState({ showCustomEmailDialog: state});
  };

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener(
      'brokerage-invoices-csv-ready', 'Brokerage Invoices', this.onDownloadResponse
    );
    attachCSVEventListener(
      'commodity-contract-invoices-csv-ready', 'Commodity Contract Invoices',
      this.onDownloadResponse
    );
    attachCSVEventListener(
      'freight-invoices-csv-ready', 'Freight Invoices', this.onDownloadResponse
    );
    attachCSVEventListener(
      'warehouse-invoices-csv-ready', 'Warehouse Fee Invoices', this.onDownloadResponse
    );
    attachCSVEventListener(
      'invoice-item-csv-ready', 'Commodity Contract Invoices', this.onDownloadResponse
    );
  }

  onHandleAddInvoiceButtonClick(callback) {
    if (get(this.props, 'contract.typeId') === CONTRACT_TYPES.AREA && !get(this.props, 'contract.price')) {
      alertifyjs.alert(
        'Permission Denied',
        `<div className=""><p>You do not have permission to create invoice for this Contract because:</p><ul><li>Price for contract is missing</li></ul></div>`,
        () => { },
      );
    }
    else {
      const func = this.props.onHandleAddInvoiceButtonClick;
      if (func) {
        func();
      }
      else {
        window.location = '/#/invoices/new';
      }
      if (callback) {
        callback();
      }
    }
  }

  onHandleAddWarehouseInvoiceButtonClick(callback) {
    const func = this.props.onHandleAddInvoiceButtonClick;
    if (func) {
      func();
    }
    else {
      window.location = '/#/invoices/warehouse/new';
    }
    if (callback) {
      callback();
    }
  }

  onHandleAddBrokerageInvoiceButtonClick(callback) {
    const func = this.props.onHandleAddBrokerageInvoiceButtonClick;
    if (this.props.contract && this.props.contract.brokeragesForUser && ((this.props.contract.brokeragesForUser.length === 1 &&
      (this.props.contract.sellerBrokerageInvoiced || this.props.contract.buyerBrokerageInvoiced)) ||
      (this.props.contract.sellerBrokerageInvoiced && this.props.contract.buyerBrokerageInvoiced))) {
      this.alertErrorMessage('Brokerage invoice for this contract have already been created.');
    } else {
      this.clickBrokerageInvoice(func);
    }
    if (callback) {
      callback();
    }
  }

  alertErrorMessage(msg) {
    alertifyjs.alert(
      'Permission Denied',
      `<div><p>${msg}</p></div>`,
      () => {
      }
    );
  }

  clickBrokerageInvoice(func) {
    if (func) {
      func();
    } else {
      window.location = '/#/invoices/brokerage/new';
    }
  }

  handleActionsClose() {
    this.setState({ anchorEl: null });
  }

  componentDidMount() {
    this.props.applySearch(null);
    this.props.isLoading();
    this.props.titleTransfersResponse([]);
    APIService.profiles()
    .filters('invoice')
    .get(this.props.token)
    .then(res => {
      this.setState({
        filters: get(res, 'invoice', {}),
      });
    });
    this._attachCSVEventListener();
    const contractId = this.props.contractId || this.props.match.params.contract_id;
    if (!window.location.hash.includes("warehouse/dashboard"))
      this.props.getInvoicesDispatchFunc(contractId, this.props.selectedUnit);
    this.setHeaderAndBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.getPaginatedInvoicesResponse({ results: [] });
    this.props.applySearch(null);
  }

  componentDidUpdate() {
    this.setHeaderAndBreadcrumbs()
  }

  setHeaderAndBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    const contractId = this.getContractId();
    let breadcrumbs = [{ text: 'Invoices' + countLabel }];
    let headerText = 'Invoices';
    if (contractId) {
      breadcrumbs = [
        { text: 'Contracts', route: '/Contracts' },
        { text: get(this.props.contract, 'referenceNumber', ''), route: '/Contracts/' + contractId + '/contract' },
        { text: 'Invoices' + countLabel },
      ];
      headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
    }
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setHeaderText(headerText);
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  getContractId() {
    return this.props.contractId || this.props.match.params.contract_id;
  }

  openSideDrawerForContractInvoice() {
    this.setState({ openSideDrawerForContractInvoice: true, });
    this.handleActionsClose();
  }

  closeSideDrawerForContractInvoice() {
    this.setState({ openSideDrawerForContractInvoice: false, });
  }

  openSideDrawerForBrokerageInvoice() {
    this.setState({ openSideDrawerForBrokerageInvoice: true, });
    this.handleActionsClose();
  }

  closeSideDrawerForBrokerageInvoice() {
    this.setState({ openSideDrawerForBrokerageInvoice: false, });
  }

  fetchFilteredInvoiceData = type => {
    let type_to_fx_mapping = {'Commodity Contract': 'contract', 'Brokerage': 'brokerage', 'Freight': 'freight', 'WarehouseFee': 'warehouse'};
    this.customCsvEnabled(true, type_to_fx_mapping[type]);
  };

  fetchCSVData = type => {
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns)
      param+= param.length == 0 ? 'custom_csv' : '&custom_csv';
    if (this.state.searchView && this.props.isSearchApplied)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    this.props.setDownloadBar('Your Invoices CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    const service = APIService.invoices();
    service.appendToUrl(`${type}/csv/${param.length == 0 ? '': `?${param}`}`);
    if (this.props.contract)
      service.appendToUrl(`${param.length == 0 ? '?' : '&'}raised_for_id=${this.props.contract?.id}&raised_for_type=contract`);
    this.setState({csvPopup: false, searchView: false})
    service.get(
      this.props.token,
      {
        'Content-Type': 'text/csv',
        'Accept': 'text/csv',
      },
    ).then(csvData => {
      this.setState({ csvData: csvData || [] });
    });
  }

  shouldShowExportCSVButton() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  checkOptionMappers = () =>{
    const {filters} = this.state;
    let options = null;

    if (isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))){
      options = [
        { name: "Commodity Contract Invoices", fx: () => this.customCsvEnabled(false, 'contract') },
        { name: "Brokerage Invoices", fx: () => this.customCsvEnabled(false, 'brokerage') },
        { name: "Freight Invoices", fx: () => this.customCsvEnabled(false, 'freight') },
        { name: "Warehouse Fee Invoices", fx: () => this.customCsvEnabled(false, 'warehouse') },
      ];
    }
    else if(!isEmpty(get(filters, 'type__in', [])))  {
      let including_types = [];
      filters.type__in.forEach(val =>{
        including_types.push({
          name: `Filtered ${val} Invoices`, fx: () => this.fetchFilteredInvoiceData(val)
        });
      });

      options = [
        { name: "Commodity Contract Invoices", fx: () => this.customCsvEnabled(false, 'contract') },
        { name: "Brokerage Invoices", fx: () => this.customCsvEnabled(false, 'brokerage') },
        { name: "Freight Invoices", fx: () => this.customCsvEnabled(false, 'freight') },
        { name: "Warehouse Fee Invoices", fx: () => this.customCsvEnabled(false, 'warehouse') },
        ...including_types
        ];
    }
    else{
      options = [
        { name: "Commodity Contract Invoices", fx: () => this.customCsvEnabled(false, 'contract') },
        { name: "Brokerage Invoices", fx: () => this.customCsvEnabled(false, 'brokerage') },
        { name: "Freight Invoices", fx: () => this.customCsvEnabled(false, 'freight') },
        { name: "Warehouse Fee Invoices", fx: () => this.customCsvEnabled(false, 'warehouse') },
        { name: "Filtered Commodity Contract Invoices", fx: () => this.customCsvEnabled(true, 'contract')  },
        { name: "Filtered Brokerage Invoices", fx: () => this.customCsvEnabled(true, 'brokerage') },
        { name: "Filtered Freight Invoices", fx: () => this.customCsvEnabled(true, 'freight') },
        { name: "Filtered Warehouse Fee Invoices", fx: () => this.customCsvEnabled(true, 'warehouse') },
      ];
    }
    options.push({ name: "Invoice Line Items", fx: () => this.setState({invoiceLineItemsPopup: true}) })
    options.push({ name: "DAFF Levy Report", fx: () => this.setState({daffLevyReportPopup: true}) })
    return options;
  };

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/invoices_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const contractColumns = INVOICE_TABLE_GLOBAL_LISTING_HEADERS;
    return contractColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  closeLineItemsPopup = () => {
    const newState = {...this.state};
    newState.invoiceItemsFilters = {
      invoice__type: '',
      item_type__model: '',
      invoice__created_at__gte: '',
      invoice__created_at__lte: '',
      invoice__payer__company_id__in: [],
      invoice__payee__company_id__in: [],
      invoice__status__in: [],
    }
    newState.invoiceLineItemsPopup = false;
    this.setState(newState);
  }

  closeLevyReportPopup = () => {
    const newState = {...this.state};
    newState.daffLevyFilters = {
      created_at__gte: '',
      created_at__lte: '',
      payer__company_id__in: [],
      payee__company_id__in: [],
      status__in: [],
    }
    newState.daffLevyReportPopup = false;
    this.setState(newState);
  }

  fetchInvoiceLineItemsCsv = () => {
    const filters = this.state.invoiceItemsFilters;
    const newState = {...this.state};
    let areFiltersValid = true;
    forEach(['invoice__type', 'item_type__model'], item => {
      if (!get(filters, item)) {
        set(newState.errors, item, 'This field is required');
        areFiltersValid = false;
      }
    });
    this.setState(newState);
    if (areFiltersValid) {
      this.props.setDownloadBar('Your Invoice Items CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
      let createdAtAfter = get(filters, 'invoice__created_at__gte');
      let createdAtBefore = get(filters, 'invoice__created_at__lte');
      filters.invoice__created_at__gte = createdAtAfter ? get(getDateTimeInUTC(createdAtAfter, '00:00:00'), 'dateTime') : createdAtAfter;
      filters.invoice__created_at__lte = createdAtBefore ? get(getDateTimeInUTC(createdAtBefore, '23:59:59'), 'dateTime') : createdAtBefore;
      let data = {filters: filters};
      if (filters['item_type__model'] === EPR_ITEM_DB_TYPE)
        data['include_epr_summary'] = this.state.eprSummary;
      else if (filters['item_type__model'] === GRAIN_LEVY_ITEM_TYPE)
        data['include_levy_summary'] = this.state.levySummary;
      if (this.state.includePlanned)
        data['include_planned'] = this.state.includePlanned
      APIService.invoices()
        .appendToUrl('items/csv/')
        .put(data)
        .then(this.closeLineItemsPopup);
    }
  }

  fetchDaffLevyReport = () => {
    const filters = this.state.daffLevyFilters;
    this.props.setDownloadBar(`Your DAFF Levy CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.`, true);
    let createdAtAfter = get(filters, 'created_at__gte');
    let createdAtBefore = get(filters, 'created_at__lte');
    filters.created_at__gte = createdAtAfter ? get(getDateTimeInUTC(createdAtAfter, '00:00:00'), 'dateTime') : createdAtAfter;
    filters.created_at__lte = createdAtBefore ? get(getDateTimeInUTC(createdAtBefore, '23:59:59'), 'dateTime') : createdAtBefore;
    let data = {filters: filters};
    if (this.state.includePlanned)
      data['include_planned'] = this.state.includePlanned
    APIService.invoices()
    .appendToUrl('daff-levy/csv/')
    .put(data)
    .then(this.closeLevyReportPopup);
  }

  handleMultiSelectFilterChange(id, selectedItems) {
    const newState = {...this.state};
    let filters = this.state.daffLevyReportPopup ? 'daffLevyFilters' : 'invoiceItemsFilters';
    newState[filters][id] = selectedItems.map(obj => obj.id);
    this.setState(newState);
  }

  handleDateFilterChange(value, id) {
    const newState = {...this.state};
    let filters = this.state.daffLevyReportPopup ? 'daffLevyFilters' : 'invoiceItemsFilters';
    newState[filters][id] = value;
    this.setState(newState);
  }

  handleSelectFilterChange(value, id) {
    const newState = {...this.state};
    if (!value)
      set(newState.errors, id, 'This field is required');
    else {
      if (id === 'item_type__model') {
        if (value === EPR_ITEM_DB_TYPE) {
          newState.showEprSummary = true;
          newState.showLevySummary = false;
        }
        else if (value === GRAIN_LEVY_ITEM_TYPE) {
          newState.showLevySummary = true;
          newState.showEprSummary = false;
        }
        else {
          newState.showLevySummary = false;
          newState.showEprSummary = false;
        }
      }
      newState.invoiceItemsFilters[id] = value;
      set(newState.errors, id, '');
    }
    this.setState(newState);
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  customCsvEnabled(isFilteredCsv, type) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    newState.type = type;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, () => {
        if (type)
          this.fetchCSVData(type)
      });
    }
  }

  toggleCustomColumnDownloads = () =>{
    this.setState({customColumns: !this.state.customColumns})
  }

  render() {
    const canCreateAnyInvoice = this.props.canCreateInvoices ? Object.values(this.props.canCreateInvoices).reduce((x, y) => x || y) : true;
    const typeId = get(this.props.currentUser, 'company.typeId');
    const canCreateBrokerageInvoice = this.props.contract ? (this.props.canCreateInvoices && this.props.canCreateInvoices.brokerageInvoice) : typeId === BROKER;
    const canCreateCommodityContractInvoice = this.props.contract ? (this.props.canCreateInvoices && this.props.canCreateInvoices.contractInvoice) : true;
    const canCreateWarehouseInvoice = isGlobalInvoicesPath();
    let optionMappers = this.checkOptionMappers();
    let { invoiceItemsFilters } = this.state
    return (
      <Paper className="paper-table">
        <div>
          {this.shouldShowExportCSVButton() && (
            <CommonListingButton
              defaultHandler={() => this.props.contract ? this.customCsvEnabled(false, 'contract') : this.customCsvEnabled(false)}
              showMenus={!this.props.contract}
              optionMapper={this.props.contract ? null : optionMappers}
              title='Download Contents of the table in a CSV'
              name='Export'
            />
          )}
          <DownloadDataDialog
            open={this.state.csvPopup}
            onClose={() => this.setState({csvPopup: false, searchView: false})}
            title='Download Invoices Data'
            isSearchApplied={this.props.isSearchApplied}
            searchView={this.state.searchView}
            onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
            isFilteredCsv={this.state.isFilteredCsv}
            onDownload={() => this.fetchCSVData(this.state.type)}
            enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
            customColumnTitle={this.state.customColumnTitle}
            user={this.props.currentUser}
            token={this.props.token}
            csvType={`${this.state.type}_invoice_csv`}
            updateColumnCount={(count) => this.updateColumnCount(count)}
            toggleCustomColumnDownloads = {this.toggleCustomColumnDownloads}
          />
          {isAtGlobalInvoices() &&
            <Tooltip title='Apply filters' placement='top'>
              <Button
                value={this.state.applyFilters}
                variant="contained"
                type='button'
                onClick={() => this.handleFilters(true)}
                color='primary'
                className='add-button'
                style={{ float: 'right', marginLeft: '10px' }}
              >
                <FilterListIcon style={{ paddingRight: '5px' }} />
                FILTERS{' '}
                {+!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1') && this.filterCriteria(val[0], val[1])))
                 ? `(${Object.entries(this.state.filters).filter(val => get(val, '1') && this.filterCriteria(val[0], val[1])).length})`
                 : ''}
              </Button>
            </Tooltip>
          }
            {this.state.applyFilters && (
              <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                <InvoiceFilters
                  handleFilterState={this.handleFilterState}
                  filters={this.state.filters}
                />
              </SideDrawer>
            )}
          {canCreateAnyInvoice &&
            <CommonListingButton
              showMenus={true}
              optionMapper={[{
                name: "Commodity Contract Invoice",
                fx: (callback) => this.onHandleAddInvoiceButtonClick(callback),
                enableOption: canCreateCommodityContractInvoice
              },
              {
                name: "Warehouse Invoice",
                fx: (callback) => this.onHandleAddWarehouseInvoiceButtonClick(callback),
                enableOption: canCreateWarehouseInvoice
              },
              {
                name: "Brokerage Invoice",
                fx: (callback) => this.onHandleAddBrokerageInvoiceButtonClick(callback),
                enableOption: canCreateBrokerageInvoice
              }]}
              title=""
              showDownLoadIcon={false}
              name="Generate Invoice"
            />
          }
          <SideDrawer
            isOpen={this.state.openSideDrawerForContractInvoice}
            title="Commodity Contract Invoice"
            onClose={this.closeSideDrawerForContractInvoice}
          >
            <CreateCommodityContractInvoice
              closeDrawer={this.closeSideDrawerForContractInvoice}
              contract={this.props.contract}
            />
          </SideDrawer>
          <SideDrawer
            isOpen={this.state.openSideDrawerForBrokerageInvoice}
            title="Brokerage Invoice"
            onClose={this.closeSideDrawerForBrokerageInvoice}
          >
            <CreateBrokerageInvoice
              closeDrawer={this.closeSideDrawerForBrokerageInvoice}
              contract={this.props.contract}
            />
          </SideDrawer>
          <div style={{float: 'right'}}>
            <CommonListingButton
              showMenus
              showDownLoadIcon={false}
              optionMapper={this.getActionsOptionMapperListItems()}
              title='Actions'
              name='Actions'
            />
          </div>
          <SideDrawer
            isOpen={this.state.customTableColumnOptions}
            title={this.state.customColumnTitle}
            onClose={() => this.setState({customTableColumnOptions: false})}
            size="small"
          >
            <CustomHeaderOptions
              customColumns={this.state.customTableColumnNames}
              closeDrawer={() => this.setState({customTableColumnOptions: false})}
              user={this.props.currentUser}
              token={this.props.token}
              table_type="invoices_table"
              columnsMapping={this.getColumnsMapping()}
              maxColumnLimit={INVOICES_TABLE_COLUMN_LIMIT}
              updateColumnCount={(count) => this.updateColumnCount(count)}
              defaultColumnLimit={DEFAULT_INVOICES_TABLE_COLUMN_LIMIT}
            />
          </SideDrawer>
          <FiltersAppliedChip show={isAtGlobalInvoices()} filters={this.state.filters} onClear={this.resetFilters} />
          <InvoicesTable contract={this.props.contract} nested={this.props.nested} />
          {
            (this.state.invoiceLineItemsPopup || this.state.daffLevyReportPopup) &&
            <Dialog open fullWidth onClose={this.closeLineItemsPopup}>
              <DialogTitleWithCloseIcon
                onClose={this.state.invoiceLineItemsPopup ? this.closeLineItemsPopup : this.closeLevyReportPopup}
                closeButtonStyle={{ marginTop: '0px' }}
                id='form-dialog-title'>
              {this.state.invoiceLineItemsPopup ? 'Invoice Line Items Export' : 'DAFF Levy Report'}
              </DialogTitleWithCloseIcon>
              <DialogContent>
                { this.state.invoiceLineItemsPopup &&
                <React.Fragment>
                <div className="col-sm-12 invoice-item-filters">
                  <CommonSelect
                    id="invoice__type"
                    items={invoiceTypes}
                    selectedItems={invoiceItemsFilters.invoice__type}
                    selectConfig={{text: 'name', value: 'id'}}
                    onChange={this.handleSelectFilterChange}
                    floatingLabelText="Invoice Type"
                    errorText= {this.state.errors.invoice__type}
                  />
                </div>
                <div className="col-sm-12 invoice-item-filters">
                  <CommonSelect
                    id="item_type__model"
                    items={invoiceItemTypes}
                    value={invoiceItemsFilters.item_type__model}
                    selectConfig={{text: 'name', value: 'id'}}
                    onChange={this.handleSelectFilterChange}
                    floatingLabelText="Invoice Item Type"
                    errorText= {this.state.errors.item_type__model}
                  />
                </div>
                </React.Fragment>
                }
                <div className="col-sm-12 invoice-item-filters padding-reset">
                  <div className="col-sm-12" style={{marginBottom: '5px'}}>
                    <h4 style={{ margin: '5px 0px', fontWeight: 'normal' }}>Invoice Creation Date</h4>
                  </div>
                  <div className='col-sm-6'>
                    <CommonDatePicker
                      id={this.state.invoiceLineItemsPopup ? "invoice__created_at__gte" : "created_at__gte"}
                      floatingLabelText="After"
                      onChange={this.handleDateFilterChange}
                      value={this.state.invoiceLineItemsPopup ? invoiceItemsFilters.invoice__created_at__gte : this.state.daffLevyFilters.created_at__gte}
                    />
                  </div>
                  <div className='col-md-6'>
                    <CommonDatePicker
                      id={this.state.invoiceLineItemsPopup ? "invoice__created_at__lte" : "created_at__lte"}
                      floatingLabelText="Before"
                      onChange={this.handleDateFilterChange}
                      value={this.state.invoiceLineItemsPopup ? invoiceItemsFilters.invoice__created_at__lte : this.state.daffLevyFilters.created_at__lte}
                    />
                  </div>
                </div>
                <div className="col-sm-12 invoice-item-filters">
                  <CompanyAutocomplete
                    multiple
                    disableCloseOnSelect
                    id={this.state.invoiceLineItemsPopup ? 'invoice__payer__company_id__in' : 'payer__company_id__in'}
                    label='Payer'
                    getOptionLabel={option => option.name}
                    onChange={items => this.handleMultiSelectFilterChange(this.state.invoiceLineItemsPopup ? 'invoice__payer__company_id__in' : 'payer__company_id__in', items)}
                    urlPath='directory/names/'
                    queryParams={{include_self: true, excludeGroups: true}}
                    minLength={3}
                    limitTags={3}
                  />
                </div>
                <div className="col-sm-12 invoice-item-filters">
                  <CompanyAutocomplete
                    multiple
                    disableCloseOnSelect
                    id={this.state.invoiceLineItemsPopup ? 'invoice__payee__company_id__in' : 'payee__company_id__in'}
                    label='Payee'
                    getOptionLabel={option => option.name}
                    onChange={items => this.handleMultiSelectFilterChange(this.state.invoiceLineItemsPopup ? 'invoice__payee__company_id__in' : 'payee__company_id__in', items)}
                    urlPath='directory/names/'
                    queryParams={{include_self: true, excludeGroups: true}}
                    minLength={3}
                    limitTags={3}
                  />
                </div>
                <div className="col-sm-12 invoice-item-filters">
                  <CommonMultiSelect
                    id={this.state.invoiceLineItemsPopup ? "invoice__status__in" : "status__in"}
                    items={invoiceStatuses}
                    selectedItems={this.state.invoiceLineItemsPopup ? invoiceItemsFilters.invoice__status__in : this.state.daffLevyFilters.status__in}
                    displayField="name"
                    onChange={(id, selectedItems) => this.handleMultiSelectFilterChange(id, selectedItems)}
                    placeholder="Select Status..."
                    label="Status"
                  />
                </div>
                <div className="col-sm-12 invoice-item-filters">
                  <Checkbox
                    id='includePlanned'
                    checked={this.state.includePlanned}
                    style={{ paddingLeft: '0px', height: '40px' }}
                    onChange={() => this.setState({includePlanned: !this.state.includePlanned})}
                  /> {'Include planned (i.e unconfirmed) invoices'}
                </div>
                {this.state.showEprSummary &&
                <div className="col-sm-12 invoice-item-filters">
                  <Checkbox
                    id='eprSummary'
                    checked={this.state.eprSummary}
                    style={{ paddingLeft: '0px', height: '40px' }}
                    onChange={() => this.setState({eprSummary: !this.state.eprSummary})}
                  /> Include EPR Summary
                </div>
                }
                {this.state.showLevySummary &&
                <div className="col-sm-12 invoice-item-filters">
                  <Checkbox
                    id='levySummary'
                    checked={this.state.levySummary}
                    style={{ paddingLeft: '0px', height: '40px' }}
                    onChange={() => this.setState({levySummary: !this.state.levySummary})}
                  /> Include Levy Summary
                </div>
                }
              </DialogContent>
              <DialogActions>
                <Button variant='outlined' color='default' onClick={this.state.daffLevyReportPopup ? this.closeLevyReportPopup : this.closeLineItemsPopup}>Cancel</Button>
                <Button variant='outlined' onClick={this.state.daffLevyReportPopup ? this.fetchDaffLevyReport : this.fetchInvoiceLineItemsCsv}>Download</Button>
              </DialogActions>
            </Dialog>
          }

        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoices: state.companies.contracts.invoices,
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
    count: get(state.companies.invoices, 'paginationData.count') || get(get(state.companies.invoices, 'invoices', []), 'length') || 0,
    isSearchApplied: state.main.isSearchApplied,
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: (component) => dispatch(isLoading(component)),
  getInvoicesDispatchFunc: (contractId, unit) => dispatch( getInvoicesWeb(contractId, null, null, true, null, unit)),
  getPaginatedInvoicesResponse: () => dispatch(getPaginatedInvoicesResponse([])),
  setHeaderText: text => dispatch(setHeaderText(text)),
  setSubHeaderText: text => dispatch(setSubHeaderText(text)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  titleTransfersResponse: () => dispatch(titleTransfersResponse([])),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
