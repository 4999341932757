import React from 'react'
import alertifyjs from 'alertifyjs'
import Chip from '@mui/material/Chip'
import ClearIcon from '@mui/icons-material/HighlightOff';
import { isEmpty, get, merge } from 'lodash'
import { AppContext } from '../main/LayoutContext';

const FiltersAppliedChip = ({ show, filters, style, onClear }) => {
  const { isMobileDevice } = React.useContext(AppContext);
  const applied = Object.entries(filters).filter(val => get(val, '1.length', 0) !== 0)
  const shouldShow = !isMobileDevice && show && !isEmpty(applied)

  const onClearFilters = () => {
    alertifyjs.confirm(
      'Clear Filters',
      'Are you sure you want to clear all filters?',
      onClear,
      () => {})
  }

  return shouldShow ? (
    <div style={merge({float: 'right', marginLeft: '10px', paddingRight: '10%', paddingTop: '2px'}, (style || {}))}>
      <Chip variant='outlined' color='warning' label={`${applied.length} Filters Applied`} onDelete={onClear ? onClearFilters : undefined}  deleteIcon={<ClearIcon fontSize='inherit' />} />
    </div>
  ) : <React.Fragment />
}

export default FiltersAppliedChip;
