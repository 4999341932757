import React from 'react';
import InvoiceIcon from '../common/icons/Invoice';
import TransactionBase from '../common/TransactionBase'

const Invoice = ({ invoice, noLink }) => {
  return (
    <TransactionBase
      icon={<InvoiceIcon fill='#000' width="34" height="34" noStyle />}
      sx={{
        '.MuiListItemIcon-root': {minWidth: 'auto', marginRight: '4px'},
      }}
      transaction={invoice}
      url={`#/invoices/${invoice.id}/details`}
      noLink={noLink}
    />
  )
}

export default Invoice
