import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import { getCommodities } from "../../actions/api/commodities";
import SideDrawer from "../common/SideDrawer"
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import APIService from '../../services/APIService';
import { required, valueBetween } from '../../common/validators';
import { filter, map, get, isEmpty, includes, find, forEach, has, mapValues, set, every, isEqual } from 'lodash';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import CommonTextField from '../common/CommonTextField';
import { InputAdornment } from '@mui/material';
import CommonButton from '../common/CommonButton';
import { cartesian } from '../../common/utils';
import CommonDatePicker from '../common/CommonDatePicker';

class TargetMoistureForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      sites: [],
      storages: [],
      fields: {
        siteIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        storageIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        commodityIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        percentage: {
          value: undefined,
          validators: [required(), valueBetween(3, 40)],
          errors: [],
        },
        startDate: {
          value: null,
          validators: [required()],
          errors: [],
        },
        endDate: {
          value: null,
          validators: [],
          errors: [],
        }
      }
    }
  }

  componentDidMount() {
    const { open, dispatch, selected } = this.props;
    this.getSites();
    dispatch(getCommodities());
    if(this.state.open != open)
      this.setState({open: open});
    if(selected)
      this.setAllFieldValuesFromSelectedTargetMoisture();
  }

  setAllFieldValuesFromSelectedTargetMoisture() {
    const { selected } = this.props;
    if(selected) {
      const newState = {...this.state};
      newState.fields.percentage.value = selected.percentage;
      newState.fields.siteIds.value = [selected.siteId];
      newState.fields.commodityIds.value = [selected.commodityId];
      newState.fields.storageIds.value = [selected.storageId];
      newState.fields.startDate.value = selected.startDate.split("T")[0];
      newState.fields.endDate.value = selected.endDate.split("T")[0];
      this.setState(newState, () => this.fetchStoragesForSiteId(selected.siteId));
    }
  }

  componentDidUpdate() {
    const { open } = this.props;
    if(this.state.open != open)
      this.setState({open: open});
  }

  getSites() {
    const { companyId } = this.props;
    APIService.companies(companyId).company_sites().appendToUrl('minimal/').get().then(sites => this.setState({sites: map(sites, site => ({id: site.id, name: site.name, isActive: site.isActive}))}));
  }

  onClose = (event, refresh) => this.setState({open: false}, () => this.props.onClose(refresh));

  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields[id].value = map(selectedItems, 'id');
    if (!isEmpty(selectedItems))
      newState.fields[id].errors = []
    this.setState(newState, () => {
      if (id === 'siteIds')
        this.fetchStorages()
    });
  };

  fetchStorages() {
    const uniqueFarmIds = [...new Set(this.state.storages.map(obj => obj.farmId))];
    const fetchStoragesFor = this.state.fields.siteIds.value.filter(element => !uniqueFarmIds.includes(element));
    if (!isEmpty(fetchStoragesFor)) {
      fetchStoragesFor.forEach(async siteId => {
        this.fetchStoragesForSiteId(siteId)
      })
    }
  }

  async fetchStoragesForSiteId(siteId) {
    let storages = await APIService.farms(siteId).appendToUrl('storages/home/?no_stocks&no_aggregations&no_relations').get()
    const newState = {...this.state};
    const farm = find(this.state.sites, {id: siteId});
    const modifiedStorages = storages.map(storage => ({ ...storage, name: `${farm.name} (${storage.name})` }));
    newState.storages = [...newState.storages, ...modifiedStorages];
    this.setState(newState)
  }

  onPercentageChange(value) {
    const newState = {...this.state};
    newState.fields.percentage.value = value;
    if (value)
      newState.fields.percentage.errors = [];
    this.setState(newState);
  }

  getFieldErrors(field, value) {
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      field.validators.forEach(validator => {
        if (validator.isInvalid(value || field.value)) {
          field.errors.push(validator.message);
        }
      });
    }
  }

  applyValidatorsOn(fields) {
    forEach(fields, field => {
      this.getFieldErrors(field);
    });
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
    this.applyValidatorsOn(newState.fields);
    this.setState(newState, () => this.validateAndSubmit())
  }

  isFormValid() {
    const {fields} = this.state;
    return every(fields, field => isEmpty(field.errors));
  }

  validateAndSubmit() {
    if(this.isFormValid()) {
      let payload = {};
      let action = 'post';
      let path = 'target-moistures/';
      if(this.props.selected) {
        action = 'put';
        payload = {startDate: this.state.fields.startDate.value};
        if (this.state.fields.endDate.value)
          payload.endDate = this.state.fields.endDate.value;
        path += `${this.props.selected.id}/`;
        set(payload, 'datesUpdated', true);
      }
      else
        payload = this.getPayload();

      APIService.farms().appendToUrl(path)[action](payload).then(data => {
        if(get(data, 'details[0]')) {
          if(includes(data.details[0], 'duplicate key'))
            alertifyjs.error('You are trying to create duplicate Target Moisture for one or more Storage/Commodity combination. Please fix that and re-try!');
          else if (isEqual(data.details, 'Overlapping Tenure'))
            alertifyjs.error('You are trying to create Target Moisture for one or more overlapping tenures. Please fix that and re-try!', 3);
          else
            alertifyjs.error('An Error Occurred!');
        } else {
          alertifyjs.success('Successfully created!');
          this.onClose(null, true);
        };
      });
    }
  }

  getPayload() {
    let data = mapValues(this.state.fields, 'value');
    const {commodityIds, storageIds, percentage} = data;

    let args = [[percentage], commodityIds, storageIds];

    let results = cartesian(...args);
    let payload = map(results, result => {
      let obj = {
        percentage: result[0],
        commodityId: result[1],
        storageId: result[2],
        startDate: get(data, 'startDate')
      }
      if (get(data, 'endDate'))
        obj.endDate = get(data, 'endDate')
      return obj;
    });

    return payload;
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setAllFieldErrors()
  }

  getLastDateOfMonth(year, month) {
    const lastDate = new Date(year, month, 0);
    return lastDate.getDate();
  }

  handleDateChange = (value, id) => {
    var [year, month] = value.split('-');
    let date = `${year}-${month}`;
    if(value && id === "startDate")
      date += '-01'
    else if (value && id === "endDate") {
      const lastDayOfMonth = this.getLastDateOfMonth(year, month);
      date += `-${lastDayOfMonth}`
    }
    const newState = {...this.state};
    set(newState.fields, `${id}.value`, date);
    set(newState.fields, `${id}.errors`, []);
    this.setState(newState);
  }

  render() {
    const {open, fields, sites, storages} = this.state
    const { commodities, selected } = this.props;
    const isEdit = Boolean(selected);
    return (
      <SideDrawer isOpen={open} title='Target Moisture' onClose={this.onClose}>
        <form noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                {
                  isEdit ?
                  <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="siteIds"
                      label="Site"
                      value={get(selected, 'siteName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="siteIds"
                      items={filter(sites, site => site?.isActive)}
                      selectedItems={fields.siteIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Site(s)..."
                      label='Site'
                      error={Boolean(get(fields.siteIds.errors, '0'))}
                      helperText={get(fields.siteIds.errors, '0')}
                      selectAll
                      clearAll
                    />
                }
              </div>
              <div className="col-sm-12 form-wrap-70">
                {
                  isEdit ?
                  <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="storageId"
                      label="Storage"
                      value={get(selected, 'storageName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="storageIds"
                      items={filter(storages, storage => includes(fields.siteIds.value, storage.farmId))}
                      selectedItems={fields.storageIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Storage(s)..."
                      label='Storage'
                      error={Boolean(get(fields.storageIds.errors, '0'))}
                      helperText={get(fields.storageIds.errors, '0')}
                      selectAll
                      clearAll
                    />
                }
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '10px'}}>
                {
                  isEdit ?
                  <div style={{marginTop: '15px'}}>
                    <CommonTextField
                      id="commodityId"
                      label="Commodity"
                      value={get(selected, 'commodityName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommodityMultiSelect
                      id="commodityIds"
                      commodities={commodities}
                      selectedCommodities={fields.commodityIds.value}
                      onChange={this.onMultiSelectChange}
                      placeholder='Select Commodities...'
                      error={Boolean(get(fields.commodityIds.errors, '0'))}
                      helperText={get(fields.commodityIds.errors, '0')}
                      label='Commodity'
                    />
                }
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '25px'}}>
                <CommonTextField
                  id='percentage'
                  label="Percentage"
                  value={this.state.fields.percentage.value}
                  helperText={this.state.fields.percentage.errors[0]}
                  onChange={event => this.onPercentageChange(event.target.value)}
                  variant='outlined'
                  disabled={isEdit}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '15px'}}>
                <CommonDatePicker
                  id="startDate"
                  floatingLabelText="Start Date"
                  errorText={this.state.fields.startDate.errors[0]}
                  value={this.state.fields.startDate.value}
                  onChange={this.handleDateChange}
                  variant='outlined'
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '15px'}}>
                <CommonDatePicker
                  id="endDate"
                  floatingLabelText="End Date (Optional)"
                  errorText={this.state.fields.endDate.errors[0]}
                  value={this.state.fields.endDate.value}
                  onChange={this.handleDateChange}
                  minDate={this.state.fields.startDate.value ? moment(this.state.fields.startDate.value) : moment.now()}
                  variant='outlined'
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '5px', textAlign: 'right', paddingRight: '0px'}}>
                <CommonButton
                  variant="contained"
                  onClick={this.onClose}
                  label='Cancel'
                />
                <CommonButton
                  variant="contained"
                  type='submit'
                  primary
                  onClick={this.handleSubmit}
                  label='Submit'
                />
              </div>
            </div>
          </div>
        </form>
      </SideDrawer>
    )
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(TargetMoistureForm);