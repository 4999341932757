import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Cancel from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { canCreateOrUpdate } from '../../common/utils';
import { BLACK } from '../../common/constants';
import merge from 'lodash/merge';
import Alert from '@mui/material/Alert';
import { AppContext } from '../main/LayoutContext';


let styles = () => ({
  paper: {
    zIndex: 11,
    position: 'fixed',
    right: 0,
    top: '88px',
    height: 'calc(100vh - 88px)',
    padding: '24px',
    overflowY: 'auto',
    borderRadius: 0,
  },
  small: {
    width: '400px',
  },
  medium: {
    width: '660px',
  },
  big: {
    width: '720px',
  },
  xlarge: {
    width: '1040px',
  },
  cancel: {
    position: 'absolute',
    right: 25,
    top: 25,
    cursor: 'pointer',
  },
  title: {
    fontWeight: 500,
    color: '#112c42',
    fontSize: 20,
  },
});

class SideDrawer extends React.Component {
  static contextType = AppContext
  render() {
    const { classes, isOpen, children, title, onClose, size, subHeading } = this.props;

    let canCreate = true;
    if(!this.props.canCreate && this.props.app && this.props.user){
      canCreate = canCreateOrUpdate(this.props.user, this.props.app);
    }

    const getClasses = () => {
      const { isMobileDevice } = this.context;
      if(isMobileDevice)
        return classes.paper + ' side-drawer-paper'
      if(!size)
        return `${classes.paper} ${classes.small}`;
      return `${classes.paper} ${classes[size]}`;
    };
    if(canCreate) {
      return(
        <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
          <Paper elevation={4} style={merge({overflowX: 'hidden', color: BLACK}, this.props.paperStyle || {})} className={getClasses()}>
            <Cancel onClick={() => onClose()} className={classes.cancel + ' side-drawer-close-icon'} />
            <Typography variant="title" className={classes.title}>
              {title}
            </Typography>
            {
              subHeading &&
                <Alert severity="warning" style={{marginTop: '10px'}}>
                  {subHeading}
                </Alert>
            }
            {children}
          </Paper>
        </Slide>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
});

export default withStyles(styles)(connect(mapStateToProps)(SideDrawer));
