import React from 'react';

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import get from 'lodash/get'

import { isVisibleInCountry } from '../../common/utils';

import Company from './Company'

const Party = ({ party }) => {
  const contactEmail = get(party, `${party?.role?.toLowerCase()}Email`) || party?.contact?.email || '-'
  const hideNGR = !isVisibleInCountry('ngr')
  return (
    <div className='col-xs-6 padding-reset'>
      <div className='col-xs-12' style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)'}}>
        {party?.sectionName || party?.role}
      </div>
      <List
        className='col-xs-12'
        dense
        sx={{
          padding: 0,
          display: 'flex',
          '.MuiListItemText-primary': {fontSize: '14px', fontWeight: 'bold'},
          '.MuiListItemText-secondary': {fontSize: '12px'},
          '.MuiListItem-root': {padding: 0, maxWidth: '300px', width: 'auto', paddingRight: '24px'},
          '.MuiListItemText-root': {marginTop: '4px', marginBottom: '4px'}
        }}
      >
        <ListItem>
          <ListItemText
            primary={
              <Company company={{...(party?.company || party), id: party?.companyId}} />
            }
            secondary={hideNGR ? '' : `NGR: ${party?.ngrNumber || '-'}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={party?.contactName || party?.contact?.name}
            secondary={contactEmail}
          />
        </ListItem>
      </List>
    </div>
  )
}

export default Party;
