import React from 'react';
import { connect } from 'react-redux';

import { getContracts, getFarmMovements, getContractsResponse } from '../../actions/companies/freights';
import FreightMovementsTable from '../../containers/FreightMovementsTable';
import AddButton from '../common/AddButton';
import { Paper, Checkbox, FormGroup, FormControl, FormLabel, FormControlLabel, Box, Dialog, DialogActions, DialogContent, Button, Tooltip, Badge, DialogContentText } from '@mui/material';
import {
  setHeaderText, setSubHeaderText, setBreadcrumbs, isLoading, forceStopLoader, setLoadingText, isSearchApplied
} from '../../actions/main';
import { has, get, isEqual, includes, isEmpty, omitBy, isFunction, isObject, isArray, map, forEach } from 'lodash';
import {
  getContractSubHeaderText, getOrderSubHeaderText, isAtGlobalFMs, attachCSVEventListener, isSystemCompany, isCurrentUserCompanyPlanLite, currentUserCompany, attachABLYListener,
  defaultViewAction, getOrderHeaderText
} from '../../common/utils';
import { receiveFreight } from '../../actions/companies/freights';
import APIService from '../../services/APIService';
import {
  FM_FILTER_STATUSES,
  OBSERVER_TYPE_ID, COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, PACK_ORDER_TYPE_ID, FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT, DEFAULT_FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT, FILTER_KEYS_TO_EXCLUDE, PREDEFINED_DATE_RANGE_FILTER_KEYS,
  INVOICED_FOR_STATUSES,
  ORDER_TYPE_ROUTE_MAPPING,
  PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS,
  PACK_MOVEMENTS_TABLE_COLUMN_LIMIT,
  DEFAULT_PACK_MOVEMENTS_TABLE_COLUMN_LIMIT
} from '../../common/constants';
import { setDownloadBar } from '../../actions/main';
import { freightAddForCreateableMovement } from '../../actions/companies/contracts';
import { canCreateMovementForOrder } from '../../actions/companies/orders';
import CommonListingButton from '../common/CommonListingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import Filters from '../common/Filters';
import RSMReportFilters from './RSMReportFilters';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import alertifyjs from 'alertifyjs';
import { MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS } from "../../common/constants";
import FiltersAppliedChip from '../common/FiltersAppliedChip';
import DownloadDataDialog from '../common/DownloadDataDialog';
import PackMovementsTable from '../../containers/PackMovementsTable';


const MOVEMENT_FILTER_KEYS_MAPPING = {
  'freight_pickup_date_range': ['freight_pickup__date_time__gte', 'freight_pickup__date_time__lte'],
  'freight_delivery_date_range': ['freight_delivery__date_time__gte', 'freight_delivery__date_time__lte'],
  'outload_date_range': ['outload__date_time__gte', 'outload__date_time__lte'],
  'inload_date_range': ['inload__date_time__gte', 'inload__date_time__lte'],
  'updated_at_date_range': ['updated_at__gte', 'updated_at__lte']
}

const EMPTY_PACK_MOVEMENTS_CSV_TEMPLATE_INCLUDING_RELEASE_NUMBER_HEADER_LENGTH = 7;

class FreightMovements extends React.Component {
  constructor(props) {
    super(props);
    this.uploadForm = React.createRef();
    this.state = {
      isOpen: false,
      csvData: [],
      filterValues: {
        commodity__id__in: [],
        planned_grade__id__in: [],
        status__in: [],
        invoiced_for: [],
        customer__company__id__in: [],
        provider__id__in: [],
        planned_truck__id__in: [],
        freight_pickup_date_range: '',
        freight_pickup__date_time__gte: '',
        freight_pickup__date_time__lte: '',
        freight_delivery_date_range: '',
        freight_delivery__date_time__gte: '',
        freight_delivery__date_time__lte: '',
        outload_date_range: '',
        outload__date_time__gte: '',
        outload__date_time__lte: '',
        inload_date_range: '',
        inload__date_time__gte: '',
        inload__date_time__lte: '',
        date_type_filters: [],
        date_range_filters: '',
        min_custom_date: '',
        max_custom_date: '',
        season__in: [],
        freight_pickup__consignor__handler__id__in: [],
        freight_delivery__consignee__handler__id__in: [],
        updated_at_date_range: '',
        updated_at__lte: '',
        updated_at__gte: '',
        freight_pickup__loads_set__ngr__company_id__in: [],
        freight_delivery__loads_set__ngr__company_id__in: [],
        freight_delivery__loads_set__ngr_id__in: [],
        freight_pickup__loads_set__ngr_id__in: [],
        freight_delivery__loads_set__storage_id__in: [],
        freight_pickup__loads_set__storage_id__in: [],
        freight_delivery__loads_set__farm_field_id__in: [],
        freight_pickup__loads_set__farm_field_id__in: [],

      },
      filters: {},
      showErrors: false,
      filter_statuses: FM_FILTER_STATUSES,
      applyFilters: false,
      openSideDrawer: false,
      csvPopup: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      searchView: false,
      isFilteredCsv: false,
      isPackOrder: false,
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
      csvType: undefined,
      isUploadingContainers: false,
      isLoadingMovements: false
    };
    this.movementTTUploadCsv = React.createRef();
    this.onHandleAddMovementButtonClick = this.onHandleAddMovementButtonClick.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.editCustomHeaderOptions = this.editCustomHeaderOptions.bind(this);
    this.getOptionMapperListItems = this.getOptionMapperListItems.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
    this.handleMovementsTTFileChosen = this.handleMovementsTTFileChosen.bind(this);
    this.movementsLoaded = this.movementsLoaded.bind(this);
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  onContainerUploadComplete() {
    if (window.location.hash.includes(`pack/orders/${this.props.orderId}/movements`))
      window.location.reload()
    this.props.setDownloadBar(false);
  }

  _attachCSVEventListener() {
    attachCSVEventListener(
      'freight-movements-csv-ready', 'Freight Movements', this.onDownloadResponse
    );
    attachABLYListener('freight-movements-containers-ready', this.props.currentUser?.id, this.onContainerUploadComplete);
  }

  componentWillUnmount() {
    this.props.unMountContracts();
    this.props.applySearch(null);
    if (window.location.hash.includes('?') && isAtGlobalFMs())
      window.location.hash = window.location.hash.split('?')[0]
  }

  movementsLoaded = () => this.setState({isLoadingMovements: true})

  componentDidMount() {
    this._attachCSVEventListener();
    this.props.unsetSelectedFreight();
    this.props.applySearch(null);
    let isPackOrder = false
    if (get(this.props, 'order')) {
      isPackOrder = this.props.order.typeId === PACK_ORDER_TYPE_ID;
      this.setState({isPackOrder: isPackOrder});
    }
    this.setHeaderAndBreadcrumbs(isPackOrder);
    if (has(this.props.match.params, 'order_id') && get(this.props,'order') === undefined) {
      this.props.isLoading();
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    const farmId = this.props.farmId || queryParams.get('farmId');
    if (this.props.match.params && (has(this.props.match.params, 'order_id') || has(this.props.match.params, 'contract_id'))) {
      this.orderId = this.props.match.params.order_id;
      this.commodityContractId = this.props.match.params.contract_id;
      this.setState({isLoadingMovements: true});
      this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder, this.props.selectedUnit, this.movementsLoaded);
    } else if (farmId) {
      this.props.isLoading('tillFarmMovements');
      this.props.onGetFarmMovements(farmId);
    } else if (has(this.props.location, 'search')) {
      this.orderId = queryParams.get('order_id');
      this.commodityContractId = queryParams.get('commodity_contract_id');
      this.props.isLoading();
      this.setState({isLoadingMovements: true});
      this.props.onGetMovements(this.orderId, this.commodityContractId, '', true, isPackOrder, this.props.selectedUnit, this.movementsLoaded);
    }
    if (isPackOrder) {
      this.setState({csvType: 'pack_movements_csv'});
    }
    else {
      this.setState({csvType: 'freight_contracts_csv'});
    }
    APIService.profiles()
      .filters('freight_movement')
      .get(this.props.token)
      .then(res => {
        let filters = get(res, 'freight_movement', {});
        this.setState({
          filters: filters,
        });
      });
  }

  setHeaderAndBreadcrumbs(isPackOrder) {
    const countLabel = ` (${this.props.count})`;

    let breadcrumbs = [
      { text: 'Movements' + countLabel },
    ];
    let headerText = isPackOrder ? 'Pack Movements' : 'Freight Movements';

    if (this.props.contractId) {
      breadcrumbs = [
        { text: 'Contracts', route: '/contracts' },
        { text: get(this.props.contract, 'referenceNumber', ''), route: '/contracts/' + this.props.contractId + '/contract' },
        { text: 'Movements' + countLabel },
      ];
      headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
    } else if (this.props.farmId) {
      let farmRoute = '/stocks/storages-view?farmId=' + this.props.farmId;
      breadcrumbs = [{ text: 'Farms', route: '/farms' }, { text: this.props.farmName, route: farmRoute }, { text: 'Movements' + countLabel }];
      headerText = this.props.farmName;
    } else if (this.orderId || this.props.order || this.props.movementParentOrder) {
      const order = this.props.order || this.props.movementParentOrder;

      headerText = getOrderHeaderText(order)
      breadcrumbs = this.getOrderBreadCrumb(order)
      const subHeaderText = getOrderSubHeaderText(order)
      this.props.setSubHeaderText(subHeaderText);
    }
    this.props.setHeaderText(headerText);

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  getOrderBreadCrumb(order){
    const orderTypeId = get(order, 'typeId')
    const orderType = get(ORDER_TYPE_ROUTE_MAPPING, orderTypeId, 'freights')
    const orderId = get(order, 'id') || this.orderId

    const countLabel = ` (${this.props.count})`;
    const breadcrumbs = [
      { text: 'Orders', route: `/orders/${orderType}` },
      { text: get(order, 'identifier', ''), route: '/freights/orders/' + orderId+ '/order' },
      { text: 'Movements' + countLabel }
    ];
    return breadcrumbs
  }

  componentDidUpdate(prevProps) {
    if(this.props.movementParentOrder){
      const headerText = getOrderHeaderText(this.props.movementParentOrder)
      const subHeaderText = getOrderSubHeaderText(this.props.movementParentOrder)
      const breadcrumbs = this.getOrderBreadCrumb(this.props.movementParentOrder)

      if(!isEqual(this.props.breadcrumbs, breadcrumbs))
        this.props.setBreadcrumbs(breadcrumbs);
      if(!isEqual(this.props.headerText, headerText))
        this.props.setHeaderText(headerText)
      if(!isEqual(this.props.subheaderText, subHeaderText))
        this.props.setSubHeaderText(subHeaderText);
    }

    if (has(this.props.match.params, 'order_id') && get(this.props,'order') !== undefined && (!this.state.isLoadingMovements && !this.state.isUploadingContainers)) {
      this.props.forceStopLoader();
      this.props.setLoadingText(null)
    }
    let isPackOrder = get(this.props, 'order.typeId') === PACK_ORDER_TYPE_ID;
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs(isPackOrder);
    if (this.props.match.params && (has(this.props.match.params, 'order_id') || has(this.props.match.params, 'contract_id') || prevProps.selectedUnit !== this.props.selectedUnit)) {
      if (this.orderId != this.props.match.params.order_id || this.commodityContractId != this.props.match.params.contract_id || prevProps.selectedUnit !== this.props.selectedUnit) {
        this.orderId = this.props.match.params.order_id;
        this.commodityContractId = this.props.match.params.contract_id;
        this.setState({isLoadingMovements: true});
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder, this.props.selectedUnit, this.movementsLoaded);
      }
    } else if (has(this.props, 'location.search')) {
      const queryParams = new URLSearchParams(this.props.location.search);
      if (this.orderId != queryParams.get('order_id') || this.commodityContractId != queryParams.get('commodity_contract_id')) {
        this.orderId = queryParams.get('order_id');
        this.commodityContractId = queryParams.get('commodity_contract_id');
        this.setState({isLoadingMovements: true});
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder, this.props.selectedUnit, this.movementsLoaded);
      }
    }
    if (isPackOrder && !this.state.isPackOrder) {
      this.setState({isPackOrder: isPackOrder}, () => {
        this.setHeaderAndBreadcrumbs(isPackOrder);
        this.setState({isLoadingMovements: true});
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder, this.props.selectedUnit, this.movementsLoaded);
      });
    }
  }

  onHandleAddMovementButtonClick() {
    const func = this.props.handleAddMovementButtonClick || this.props.onHandleAddMovementButtonClick;
    if (func) {
      const { contract, orderId, dispatch } = this.props;
      if (contract)
        dispatch(freightAddForCreateableMovement(get(contract, 'id'), get(contract, 'status')));
      else
        dispatch(canCreateMovementForOrder(orderId));
    } else window.location = '/#/freights/movements/new';
  }

  fetchCSVData = (callback) => {
    const { farmId, setDownloadBar } = this.props;
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns)
      param+= param.length == 0 ? 'custom_csv' : '&custom_csv';
    if (this.props.isSearchApplied && this.state.searchView)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    this.setState({csvPopup: false, searchView: false})
    let service;
    let movementType = this.state.isPackOrder ? 'Pack': 'Freight';
    setDownloadBar(`Your ${movementType} Movements CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.`, true);
    if (this.state.isPackOrder) {
      if (this.orderId)
        service = APIService.freights(this.orderId).appendToUrl(`pack-movements/csv/?${param}`);
    }
    else {
      const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
      if (farmId)
        service = APIService.farms(farmId).appendToUrl('freight-movements/csv/');
      else {
        service = APIService.freights().contracts().appendToUrl(`csv/?${param}`);
        if (this.orderId)
          service.appendToUrl(`&order_id=${this.orderId}`);
        if (this.commodityContractId)
          service.appendToUrl(`&commodity_contract_id=${this.commodityContractId}`);
        if(queryParams.get('include_void') == 'true')
          service.appendToUrl('&include_void=true')
      }
    }

    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || [] });
      });
    if (callback) {
      callback();
    }
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  resetFilters = () => {
    this.setState({filters: {}, applyFilters: false, openSideDrawer: false}, () => this.handleFilterState('applyFilters', false))
  }


  handleFilterState = (key, value) => {
    let _value = value
    if(key === 'filters' && isSystemCompany())
      _value = {..._value, show_missing_docket: this.state.filters.show_missing_docket || false, show_paused_docket: this.state.filters.show_paused_docket || false, sms_entry: this.state.filters.sms_entry || false}
    this.setState({ [key]: _value }, () => {
      if (key === 'applyFilters' && isAtGlobalFMs()) {
        const { filters } = this.state;
        let newFilters = {}
        forEach(filters, (__value, __key) => {
          if(isArray(__value) && isObject(__value[0]))
            newFilters[__key] = map(__value, 'id')
          else
            newFilters[__key] = __value
        })
        APIService.profiles()
          .filters()
          .post({ freight_movement: newFilters }, this.props.token)
          .then(res => {
            this.props.isLoading('manualWait');
            this.setState({filters: res?.filters?.freight_movement}, () => {
              this.setState({isLoadingMovements: true});
              this.props.onGetMovements(null, null, '', true, this.state.isPackOrder, this.props.selectedUnit, this.movementsLoaded);
            })
          });
      }
    });
  };

  fetchRMSReportData = (callback) => {
    this.toggleDialog();
    if (callback) {
      callback();
    }
  };

  toggleDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/${this.state.csvType}/`).get(this.props.token);
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied ) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  getOptionMapperListItems() {
    const dualSeal = get(this.props, 'order.freightContainer.dualSeal')
    const packMovementsTemplateURL = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/pack_movements_2504202342.csv'
    const packMovementsDualSealTemplateURL = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/pack_movements_dual_seal_2504202342.csv'
    let items = [{ name: 'Download Empty Container Template', fx: () => location.href = dualSeal ? packMovementsDualSealTemplateURL : packMovementsTemplateURL }];
    items.push({ name: 'Download Packed Container Template', fx: () => location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/PackMovementAgainstOrderUploadTemplate.csv' });
    items.push({ name: 'Upload Containers', fx: () => this.uploadForm.current.click() })
    return items;
  }

  handleFileChosen = event => {
    event.stopPropagation();
    let file = event.target.files[0];
    event.target.value = '';
    let formData = new FormData();
    formData.append('file', file);
    formData.append('orderId', this.props.orderId);
    let reader = new FileReader();
    let numberOfRecords;
    let isEmptyPackMovement = false;
    reader.onload = e => {
      let content = e.target.result;
      let headerRow = content.split(/\r?\n/)[0]
      let headerRowList = headerRow.split(',')
      let templateLength = EMPTY_PACK_MOVEMENTS_CSV_TEMPLATE_INCLUDING_RELEASE_NUMBER_HEADER_LENGTH
      if (get(this.props, 'order.freightContainer.dualSeal'))
        templateLength += 1
      if (headerRowList[headerRowList.length - 1] == 'Container Capacity (optional)')
        templateLength -= 1
      isEmptyPackMovement = headerRowList.length == templateLength
      numberOfRecords = content.split('\n').length - 1;
      if (isEmptyPackMovement) {
        if (numberOfRecords < 20) {
          this.setState({isUploadingContainers: true}, () => {
            this.props.isLoading('tillMovements')
            this.props.setLoadingText('Uploading containers can take up to 30 seconds...')
          });
        } else
        this.props.setDownloadBar(`The containers are being processed. Please <a href='#/pack/orders/${this.props.orderId}/movements'>click here</a> to view.`, true);
      } else
        this.props.setDownloadBar('The packed containers are being processed. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    }
    reader.readAsText(file);
    APIService.freights().contracts().appendToUrl('csv/')
    .post(formData)
    .then(response => {
      let reasons = get(response, 'reasons')
      if (reasons != null && reasons.length > 0) {
        this.props.forceStopLoader();
        this.setState({reasons: reasons, showErrors: true});
        this.props.setDownloadBar(false);
        this.props.setLoadingText(null);
      }
      else {
        if (isEmptyPackMovement && numberOfRecords < 20){
          this.setState({isLoadingMovements: true});
          this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, this.state.isPackOrder, this.props.selectedUnit, () => this.setState({isUploadingContainers: false, isLoadingMovements: false}));
        }
      }
    })
  };

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns()},
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableType = this.state.isPackOrder ? 'pack_movements_table' : 'freight_movements_table';
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/${tableType}/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    if (this.state.isPackOrder)
      return PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
    return MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: isFunction(objectKey.header) ? objectKey.header() : objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  handleDownloadTemplateClick = () => {
    if(isSystemCompany())
      location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/MovementsTransfersUploadTemplateSuperAdmin.csv'
    else
      location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/UserMovementsTransfersUploadTemplate.csv'
  };

  onUploadMovementsTTCsvClick = () => {
    if (get(currentUserCompany(), 'acquisitionFileUpload') || isSystemCompany())
      this.movementTTUploadCsv.current.click();
    else {
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>Uploading Movements/Transfers is a premium feature. Please contact AgriChain support <a href="mailto:support@agrichain.com" target='_blank' rel="noopener noreferer">support@agrichain.com</a>  for more details.</p></div>`,
        () => {}
      );
    }
  }

  handleMovementsTTFileChosen(event) {
    const { setDownloadBar } = this.props;
    const file = event.target.files[0];
    event.target.value = '';
    const formData = new FormData();
    formData.append('file', file);
    setDownloadBar('Your Movement File CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    APIService.freights().contracts().appendToUrl(`csv/?movement_tt_upload=true`).post(
      formData, null,
    ).then(response => {
      let reasons = get(response, 'reasons')
      if (reasons != null && reasons.length > 0) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
          'Error While Uploading CSV',
          `<div id="complete-dialog-open" className=""><p>Movements File cannot be created because:</p><ul>${reasons}</ul><div>Please download the CSV template again and reupload it with the data under all the given headers.</div></div>`,
          () => {},
        );
      }
    })
    .catch(() => alertifyjs.error("Failed - Incorrect CSV format"));
  };
  getCsvOptions() {
    let options = [
      { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
      !isEmpty(Object.entries(this.state.filters).filter(val => !isEmpty(val[1]))) ? { name: 'Filtered List', fx: () => this.customCsvEnabled(true) } : {},
      { name: 'RMS Report', fx: callback => this.fetchRMSReportData(callback) },
    ];
    if (this.state.isPackOrder) {
      return [options[0]];
    }
    options = [...options,
      { name: 'Download Template', fx: () => this.handleDownloadTemplateClick()},
      { name: 'Upload Movements/Transfers', fx: () =>  this.onUploadMovementsTTCsvClick()}
    ]
    return options;
  }

  onSystemFilterChange = (event, key) => {
    const newState = {...this.state}
    newState.filters[key] = event.target.checked
    if(key === 'show_missing_docket' && event.target.checked)
      newState.filters.show_paused_docket = false
    else if(key === 'show_paused_docket' && event.target.checked)
      newState.filters.show_missing_docket = false
    this.setState(newState, () => this.handleFilterState('applyFilters', true))
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(MOVEMENT_FILTER_KEYS_MAPPING, key)) : value.length !== 0 && value !== false;

  toggleCustomColumnDownloads = () => {
    this.setState({customColumns: !this.state.customColumns})
  }

  render() {
    const { filterValues, filters } = this.state;
    const isGlobal = isAtGlobalFMs()
    const isSystem = isSystemCompany()
    const generalFilters = omitBy(filters, (value, key) => ['show_missing_docket', 'show_paused_docket', 'sms_entry'].includes(key))
    const filtersCount = Object.entries(generalFilters).filter(val => get(val, '1') && this.filterCriteria(val[0], val[1])).length
    return (
      <React.Fragment>
        {
          isGlobal && isSystem &&
            <Paper style={{marginBottom: '-5px'}}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <FormControl>
                  <FormGroup row style={{alignItems: 'center', padding: '0 10px', borderRadius: '0 0 0 6px', background: 'rgba(0, 0, 0, 0.1)'}}>
                    <FormLabel component="legend" style={{color: '#000', marginRight: '15px'}}>Dockets:</FormLabel>
                    <FormControlLabel
                      label="Pending"
                      control={<Checkbox checked={filters?.show_missing_docket || false} onChange={event => this.onSystemFilterChange(event, 'show_missing_docket')} />}
                    />
                    <FormControlLabel
                      label="Seeking Clarification"
                      control={<Checkbox checked={filters?.show_paused_docket || false} onChange={event => this.onSystemFilterChange(event, 'show_paused_docket')} />}
                    />
                    <FormControlLabel
                      label="SMS"
                      control={<Checkbox checked={filters?.sms_entry || false} onChange={event => this.onSystemFilterChange(event, 'sms_entry')} />}
                    />
                  </FormGroup>
                  </FormControl>
              </Box>
            </Paper>
        }
      <Paper className='paper-table-paginated'>
        <div style={{ position: 'relative' }}>
          <FiltersAppliedChip filters={filters} show={isGlobal} style={{paddingRight: '50%'}} onClear={this.resetFilters} />
          <div style={{ position: 'absolute', right: '0px', top: '0px', display: 'flex' }}>
            {
              (!has(this.props, 'canCreate') || this.props.canCreate) && !this.state.isPackOrder &&
                <AddButton label='FREIGHT MOVEMENT' onClick={this.onHandleAddMovementButtonClick} app='movement' style={{ marginTop: '0px' }} />
            }
            {
              (isSystem|| (this.canExportCSV() && !isCurrentUserCompanyPlanLite())) && (
               <CommonListingButton
                 defaultHandler={() => this.customCsvEnabled(false)}
                 showMenus={isGlobal}
                 optionMapper={this.getCsvOptions()}
                 title='Download Contents of the table in a CSV'
                 name='CSV'
               />
              )
            }
            <input ref={this.movementTTUploadCsv} name="uploadMovementTT" id="uploadMovementTT" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={this.handleMovementsTTFileChosen} />
            {
              this.state.isPackOrder &&
              <CommonListingButton
                showMenus
                optionMapper={this.getOptionMapperListItems()}
                title='Upload Packed Order Details'
                name='Container'
              />
            }
            {
              !isCurrentUserCompanyPlanLite() &&
              <div style={{ float: 'right' }}>
                <CommonListingButton
                  showMenus
                  showDownLoadIcon={false}
                  optionMapper={this.getActionsOptionMapperListItems()}
                  title='Actions'
                  name='Actions'
                  variant='outlined'
                  color='secondary'
                />
                <SideDrawer
                  isOpen={this.state.customTableColumnOptions}
                  title={this.state.customColumnTitle}
                  onClose={() => this.setState({customTableColumnOptions: false})}
                  size="small"
                >
                  <CustomHeaderOptions
                    customColumns={this.state.customTableColumnNames}
                    closeDrawer={() => this.setState({customTableColumnOptions: false})}
                    user={this.props.currentUser}
                    token={this.props.token}
                    table_type={this.state.isPackOrder ? "pack_movements_table" : "freight_movements_table"}
                    columnsMapping={this.getColumnsMapping()}
                    maxColumnLimit={this.state.isPackOrder ? PACK_MOVEMENTS_TABLE_COLUMN_LIMIT : FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT}
                    updateColumnCount={(count) => this.updateColumnCount(count)}
                    defaultColumnLimit={this.state.isPackOrder ? DEFAULT_PACK_MOVEMENTS_TABLE_COLUMN_LIMIT : DEFAULT_FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT}
                  />
                </SideDrawer>
              </div>
            }
            {
              isGlobal &&
              <Tooltip title='Apply filters' placement='top'>
                <Badge badgeContent={filtersCount} color="secondary">
                  <Button
                    value={this.state.applyFilters}
                    variant={filtersCount ? 'contained' : 'outlined'}
                    type='button'
                    color='secondary'
                    onClick={() => this.handleFilters(true)}
                    style={{marginLeft: '10px'}}
                    >
                    <FilterListIcon style={{ paddingRight: '5px' }} />
                    FILTERS
                  </Button>
                </Badge>
              </Tooltip>
            }
            <DownloadDataDialog
              open={this.state.csvPopup}
              onClose={() => this.setState({csvPopup: false, searchView: false})}
              title={this.state.isPackOrder ? 'Download Pack Movements Data' : 'Download Freight Movements Data'}
              enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
              isSearchApplied={this.props.isSearchApplied}
              searchView={this.state.searchView}
              onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
              isFilteredCsv={this.state.isFilteredCsv}
              onDownload={this.fetchCSVData}
              customColumnTitle={this.state.customColumnTitle}
              user={this.props.currentUser}
              token={this.props.token}
              csvType={this.state.csvType}
              updateColumnCount={(count) => this.updateColumnCount(count)}
              toggleCustomColumnDownloads={this.toggleCustomColumnDownloads}
            />
            {this.state.applyFilters && (
               <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                 <Filters
                   isLoading={this.props.isLoading}
                   forceStopLoader={this.props.forceStopLoader}
                   handleFilterState={this.handleFilterState}
                   filters={generalFilters}
                   statusTemp={this.state.filter_statuses}
                   invoicedStatuses={INVOICED_FOR_STATUSES}
                   filterValues={filterValues}
                   isFMFilters={true}
                 />
               </SideDrawer>
            )}
            <input ref={this.uploadForm} name="upload" id="upload" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={this.handleFileChosen} />
          </div>
          {
            this.state.isPackOrder ?
              <PackMovementsTable
                dontRedirect={this.props.dontRedirect}
                removeContractFromSearch={get(this.props, 'removeContractFromSearch')}
                nested={this.props.nested}
              /> :
            <FreightMovementsTable
              dontRedirect={this.props.dontRedirect}
              removeContractFromSearch={get(this.props, 'removeContractFromSearch')}
              nested={this.props.nested}
            />
          }
        </div>
        {
          this.state.isOpen &&
          <RSMReportFilters isOpen={this.state.isOpen} toggleDialog={this.toggleDialog} />
        }
      </Paper>
      {this.state.showErrors &&
        <Dialog open={this.state.showErrors} onClose={() => this.setState({showErrors: false})}>
          <DialogTitleWithCloseIcon onClose={() => this.setState({showErrors: false})}>
            Error While Uploading CSV
          </DialogTitleWithCloseIcon>
          <DialogContent>
            <DialogContentText>
              <div>Pack Movement cannot be created because:</div>
              <ul>{map(this.state.reasons, reason => <li>{reason}</li>)}</ul>
              <div>Please download the CSV template again and reupload it with the data under all the given headers.</div>
            </DialogContentText>
            <DialogActions>
              <Button type='button' onClick={() => this.setState({showErrors: false})} variant='outlined' color='secondary'>
                OK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      }
        </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  unMountContracts: () => dispatch(getContractsResponse([])),
  onGetMovements: (orderId, commodityContractId, url, loader, isPackOrder, unit, callback) => dispatch(getContracts(orderId, commodityContractId, url, loader, isPackOrder, unit, callback)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  setSubHeaderText: text => dispatch(setSubHeaderText(text)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  onGetFarmMovements: farmId => dispatch(getFarmMovements(farmId, true)),
  unsetSelectedFreight: () => dispatch(receiveFreight(null)),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setLoadingText: message => dispatch(setLoadingText(message)),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr)),
});

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
    subHeaderText: state.main.subHeaderText,
    token: state.main.user.token,
    selectedFarm: state.companies.farms.selectedFarm,
    currentUser: state.main.user.user,
    count: get(state.companies.freights, 'paginationData.count') || 0,
    fmWarningList: state.companies.contracts.fmWarningList,
    fmWarningFlag: state.companies.contracts.fmWarningFlag,
    isSearchApplied: state.main.isSearchApplied,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightMovements);

