import React from "react";
import './orderPreview.scss';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { isVisibleInCountry, getGradeName, getCurrentCountry, toPhoneFormat, getCountryLabel } from '../../../common/utils';

class RequestOrderPreview extends React.Component {

  getEntityName = ngr => ngr?.shareholdersEntityName || ngr?.shareHolderEntityNames

  render() {
    const { previewFields, header, isPickupOrder } = this.props;
    const { pickupDetails, deliveryDetails, customerDetails } = previewFields;
    const { customer, customerContact, customerNgr } = customerDetails;
    const { config } = getCurrentCountry();
    const ABNLabel = config.abn.label
    const tonnageLabel = getCountryLabel('tonnage')
    const isNGRVisible = isVisibleInCountry('ngr')
    const isABNVisible = isVisibleInCountry('abn')
    return (
      <div className="order-preview">
        <div className="order-header">
          <div className="agri-logo">
            {
              previewFields.currentUserCompanyLogo ?
              <img src={previewFields.currentUserCompanyLogo} alt={previewFields.currentUser.company.name} crossOrigin="anonymous" /> :
              <h2>{previewFields.currentUser.company.name}</h2>
            }
          </div>
          <div className="order-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{header}</h4>
              {
                previewFields.contractReferenceNumber &&
                <p className="reference">
                  <span>Contract Number:</span> {previewFields.contractReferenceNumber}
                </p>
              }
              {
                previewFields.externalReferenceNumber &&
                <p className="reference">
                  <span>Contract Number:</span> {previewFields.externalReferenceNumber}
                </p>
              }
              <p className="reference">
                <span>Order Number:</span> {previewFields.identifier.toUpperCase()}
              </p>
              <p className="reference">
                <span>Date Issued:</span> {previewFields.dateIssued}
              </p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.currentUser.company.name}</h4>
              {
                previewFields.currentUser.company.address.address &&
                <p>{previewFields.currentUser.company.address.address}</p>
              }
              {
                previewFields.currentUser.email &&
                <p><span>EMAIL: </span>{previewFields.currentUser.email}</p>
              }
              {
                previewFields.currentUser.company.mobile &&
                <p><span>PHONE: </span>{toPhoneFormat(previewFields.currentUser.company.mobile)}</p>
              }
              {
                previewFields.currentUser.company.abn && isABNVisible && 
                  <p><span>{`${ABNLabel}: `}</span> {previewFields.currentUser.company.abn}</p>
              }
            </div>
          </div>
        </div>
        <div className="order-body">
          <div className="order-details">
            <div className="order-details__left col-xs-12 col-sm-12 col-md-12">
              <h4>CUSTOMER</h4>
              <h5>{customerNgr?.shareholdersName ? customerNgr?.shareholdersName : get(customer,'name')}</h5>
            {
                isABNVisible &&
                  <p>
                    <span className="pre-label">{ABNLabel}</span>
                    <span className="pre-content">{customerNgr?.shareholdersAbn ? customerNgr?.shareholdersAbn : get(customer,'abn')}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Entity</span>
                <span className="pre-content">{customerNgr?.ngrType == 'shared' ? this.getEntityName(customerNgr) : get(customer,'entityName')}</span>
              </p>
            {
                isNGRVisible &&
                  <p>
                    <span className="pre-label">NGR</span>
                    <span className="pre-content">{customerNgr?.ngrNumber}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Contact</span>
                <span className="pre-content">{get(customerContact, 'name')}</span>
              </p>
              <div className="contacts">
                <p>
                  <span className="pre-label">Phone</span>
                  <span className="pre-content">{toPhoneFormat(get(customer,'mobile') || get(customer,'company.mobile'))}</span>
                </p>
                <p>
                  <span className="pre-label">Email</span>
                  <span className="pre-content">{customerContact && customerContact.email}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>COMMODITY DETAILS</h5>
              <p>
                <span className="pre-label">COMMODITY</span>
                <span className="pre-content">{get(previewFields, 'commodity.displayName')}</span>
              </p>
              <p>
                <span className="pre-label">VARIETY</span>
                <span className="pre-content">{get(previewFields, 'variety.name')}</span>
              </p>
              <p>
                <span className="pre-label">GRADE</span>
                <span className="pre-content">
                  {getGradeName(previewFields)}
                </span>
              </p>
              {
                previewFields.quantity &&
                <p>
                  <span className="pre-label">{previewFields.quantityLabel}</span>
                  <span className="pre-content">{get(previewFields, 'quantity')} {get(previewFields, 'quantityUnit')}</span>
                </p>
              }
              <p>
                <span className="pre-label">{tonnageLabel.toUpperCase()}</span>
                <span className="pre-content">{get(previewFields, 'plannedTonnage')} {previewFields?.commodity?.tonnageUnit}</span>
              </p>
              <p>
                <span className="pre-label">SEASON</span>
                <span className="pre-content">{get(previewFields, 'season')}</span>
              </p>
              <p>
                <span className="pre-label">TOLERANCE</span>
                <span className="pre-content">{get(previewFields, 'tolerance')}</span>
              </p>
            </div>
          </div>
          <div className="order-details margintop-20">
            {
              isPickupOrder ?
              <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
                <h5>PICKUP DETAILS</h5>
                {
                  pickupDetails &&
                  Object.keys(pickupDetails).map((key, index) => (
                    <p key={index}>
                      <span className="pre-label">{key}</span>
                      <span className="pre-content">{pickupDetails[key]}</span>
                    </p>
                  ))
                }
              </div> :
              <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
                <h5>DELIVERY DETAILS</h5>
                {
                  deliveryDetails &&
                  Object.keys(deliveryDetails).map((key, index) => (
                    <p key={index}>
                      <span className="pre-label">{key}</span>
                      <span className="pre-content">{deliveryDetails[key]}</span>
                    </p>
                  ))
                }
              </div>
            }
          </div>
          {
            previewFields && previewFields.note && filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).length !== 0 &&
            <div className="order-details margintop-20">
              <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
                <h5>Notes</h5>
                {
                  filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).map(function (key, index) {
                    if (key != 'attachments')
                      return (<p key={index}>
                        <span className="pre-label">Description: </span>
                        <span className="pre-content">{previewFields.note[key]}</span>
                      </p>);
                    return (<p key={index}>
                      <span className="pre-label">Attachment: </span>
                      <span className="pre-content">{previewFields.note[key][0]['name']}</span>
                    </p>);
                  })
                }
              </div>
            </div>
          }
        </div>
        {
          previewFields.generalConditions &&
          <div className="commodity-details order-conditions">
            <h4>{`${header} Conditions`}</h4>
            <p>{previewFields.generalConditions}</p>
          </div>
        }
        {
          previewFields.specialConditions &&
          <div className="commodity-details order-conditions">
            <h4>Special Conditions</h4>
            <p>{previewFields.specialConditions}</p>
          </div>
        }
      </div>
    );
  }
}

export default RequestOrderPreview;
