import React from 'react';

import Paper from '@mui/material/Paper'
import map from 'lodash/map'
import Party from './Party'

const Parties = ({ parties }) => {
  return (
    <Paper sx={{display: 'inline-block', boxShadow: 'none', padding: '8px', width: '100%'}}>
      {
        map(parties, (party, index) => <Party key={index} party={party} />)
      }
    </Paper>
  );
}

export default Parties;
