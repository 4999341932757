import React from 'react';

import { KeyboardArrowDown, KeyboardArrowUp, Edit } from '@mui/icons-material';
import { Paper, IconButton } from '@mui/material';
import { capitalize, get } from 'lodash';
import { EMPTY_VALUE, PACK_ORDER_TYPE_ID, SYSTEM_COMPANY_IDS } from '../../../common/constants';
import { isSystemCompany, currentUserCompany } from '../../../common/utils';
import Tooltip from '../../../common/Tooltip';
import CompanyAssignmentDialog from './CompanyAssignmentDialog';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class MovementDetailsContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      open: false,
    };
  }

  toggleExpanded = () => this.setState((prevState) => ({ expanded: !prevState.expanded}));

  handleCustomerUpdateOpen = () => this.setState({open: true});

  handleDialogClose = () => this.setState({open: false});

  getCounterPartiesSectionAsPerGrid = (payload, sectionName, isEditable) => {
    return (
      <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 className="section-title" style={{display: 'inline-block'}}>
          {sectionName}
        </h4>
        {
          isEditable &&
            <IconButton size='small' sx={{marginLeft: '15px'}} color='secondary' onClick={this.handleCustomerUpdateOpen}>
            <Edit fontSize="small"/>
          </IconButton>
        }
      </div>
        <ul className="field-label-list">
          {Object.keys(payload).map(function(key, index) {
             return (
               <li key={index}>
                 <Tooltip
                   className="field-label ellipses"
                   tooltipText={key}
                   textContent={key}
                 />
                 { <Tooltip
                     className="field-value ellipses"
                     tooltipText={payload[key] || EMPTY_VALUE}
                     textContent={payload[key] || EMPTY_VALUE}
                 />}
               </li>
             );
           })}
        </ul>
      </div>
    );
  };

  getSectionTitle = (party) => {
    const deliveryOnus = get(this.props.movement, 'commodityContract.deliveryOnus');
    const customer = get(this.props.movement, 'customer');
    const seller = get(this.props.movement, 'seller');
    const buyer = get(this.props.movement, 'buyer');
    const externalReferenceNumber = get(this.props.movement, 'externalReferenceNumber');

    if (externalReferenceNumber) {
      if ('Seller' === party && get(seller, 'companyId') === get(customer, 'companyId'))
        return `${party} (Customer)`;
      else if ('Buyer' === party && get(buyer, 'companyId') === get(customer, 'companyId'))
        return `${party} (Customer)`;
      else
        return party;
    }

    if (customer){
      if(deliveryOnus && party === 'Customer' && !get(this.props.movement,'orderId')){
        return `${deliveryOnus} (Customer)`;
      } else {
        return party;
      }
    } else {
      if (deliveryOnus === party){
        return `${party} (Customer)`;
      } else {
        return party;
      }
    }
  };

  showPartySection (party) {
    const { movement } = this.props;
    if(get(movement, 'externalReferenceNumber') || (get(movement, 'seller') && get(movement, 'buyer')))
      return true;
    return get(movement,`commodityContract.${party}`) && (get(movement, 'commodityContract.deliveryOnus') !== capitalize(party) || !get(movement,'customer')) || (get(movement, 'order.level') > 0 && get(movement.order, 'parentOrder.typeId') !== 3 && get(movement.order, 'commodityContractId'));
  }

  showSellerSection = () => this.showPartySection('seller');

  showBuyerSection = () => this.showPartySection('buyer');

  shouldShowCustomerSection() {
    if(isSystemCompany())
      return true;

    const {movement} = this.props;
    const showSeller = this.showSellerSection();
    const showBuyer = this.showBuyerSection();

    return ((!showSeller && !showBuyer) || (get(movement, 'order.level') > 0 && get(movement.order, 'parentOrder.typeId') !== 3)) && movement.customer;
  }

  render() {
    const { movement } = this.props;
    const { expanded } = this.state;

    const emptyPayload = {};
    const createdFrom = get(movement, 'createdFrom') || 'Web';
    const assignorPayload = {
      Name: get(movement, 'createdBy.company.businessName', EMPTY_VALUE),
      Entity: get(movement,'createdBy.company.entityName'),
      Contact: get(movement, 'createdBy.name', EMPTY_VALUE),
      'Created Through': get(movement, 'isAdHoc') ? 'Mobile' : createdFrom,
    };

    let providerPayload = {
      Name: get(movement, 'provider.businessName', EMPTY_VALUE),
      Entity: get(movement,'provider.entityName'),
      Contact: get(movement, 'assignTo.name', EMPTY_VALUE),
    };

    if (SYSTEM_COMPANY_IDS.includes(get(movement, 'providerId'))) {
      providerPayload = {
        Name: EMPTY_VALUE,
        Entity: EMPTY_VALUE,
        Contact: EMPTY_VALUE,
      };
    }
    var customerPayload = (movement.customer) ? {
      Name: movement.customer?.ngr?.shareholdersName ? movement.customer?.ngr?.shareholdersName : get(movement, 'customer.company.displayName', EMPTY_VALUE),
      Entity: movement.customer?.ngr?.shareholdersEntityName ? movement.customer?.ngr?.shareholdersEntityName : get(movement,'customer.company.entityName'),
      NGR : get(movement,'customer.ngr.ngrNumber'),
      Contact: get(movement, 'customer.contact.name', EMPTY_VALUE),
    } : emptyPayload;

    if (SYSTEM_COMPANY_IDS.includes(get(movement, 'customer.companyId'))) {
      customerPayload = {
        Name: EMPTY_VALUE,
        Entity: EMPTY_VALUE,
        NGR : get(movement,'customer.ngr.ngrNumber'),
        Contact: EMPTY_VALUE,
      };
    }
    const sellerNgr = get(movement, 'seller.ngr') || get(movement, 'commodityContract.seller.ngr')
    const sellerPayload = {
      Name: sellerNgr?.shareholdersName ? sellerNgr.shareholdersName : get(movement, 'seller.company.name') || get(movement, 'commodityContract.seller.company.name', EMPTY_VALUE),
      Entity: sellerNgr?.shareholdersEntityName ? sellerNgr.shareholdersEntityName : get(movement, 'seller.company.entityName') || get(movement, 'commodityContract.seller.company.entityName', EMPTY_VALUE),
      NGR : get(movement, 'seller.ngr.ngrNumber') || get(movement,'commodityContract.seller.ngr.ngrNumber', EMPTY_VALUE),
      Contact: get(movement, 'seller.contact.name') || get(movement, 'commodityContract.seller.contact.name', EMPTY_VALUE),
    };
    const buyerNgr = get(movement, 'buyer.ngr') || get(movement, 'commodityContract.buyer.ngr')
    const buyerPayload = {
      Name: buyerNgr?.shareholdersName ? buyerNgr.shareholdersName : get(movement, 'buyer.company.name') || get(movement, 'commodityContract.buyer.company.name', EMPTY_VALUE),
      Entity: buyerNgr?.shareholdersEntityName ? buyerNgr.shareholdersEntityName : get(movement, 'buyer.company.entityName') || get(movement, 'commodityContract.buyer.company.entityName', EMPTY_VALUE),
      NGR : get(movement,'buyer.ngr.ngrNumber') || get(movement,'commodityContract.buyer.ngr.ngrNumber'),
      Contact: get(movement, 'buyer.contact.name') || get(movement, 'commodityContract.buyer.contact.name', EMPTY_VALUE),
    };
    let deliveryOnus = '';
    if (get(movement, 'externalReferenceNumber')) {
      if (get(movement, 'seller.companyId') === get(movement, 'customer.companyId'))
        deliveryOnus = 'Seller';
      else if (get(movement, 'buyer.companyId') === get(movement, 'customer.companyId'))
        deliveryOnus = 'Buyer';
    }
    else
      deliveryOnus = get(movement, 'commodityContract.deliveryOnus');
    if(!(get(movement, 'order.level') > 0 && get(movement.order, 'parentOrder.typeId') !== 3)){
      if(deliveryOnus === 'Seller'){
        customerPayload = sellerPayload;
      } else if (deliveryOnus === 'Buyer') {
        customerPayload = buyerPayload;
      }
    }
    const showSeller = this.showSellerSection();
    const showBuyer = this.showBuyerSection();
    const isPackOrder = get(movement, 'typeId') === PACK_ORDER_TYPE_ID;
    const sellerLabel = showSeller ? (movement.canViewParties ? 'Seller' : null) : (movement.canViewParties ? "Seller (Customer)" : 'Customer')
    const buyerLabel = showBuyer ? (movement.canViewParties ? 'Buyer' : null) : (movement.canViewParties ? "Buyer (Customer)" : 'Customer')
    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Counterparties
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {
          expanded &&
            (
              <div className={showSeller && showBuyer && movement.customer ? "section-details-container-5 section-details-container" : "section-details-container"}>
                {
                  this.getCounterPartiesSectionAsPerGrid(
                    providerPayload,
                    isPackOrder ? 'Pack Provider' : 'Freight Provider'
                  )
                }
                {
                  this.shouldShowCustomerSection() && (
                    this.getCounterPartiesSectionAsPerGrid(
                      customerPayload,
                      this.getSectionTitle('Customer'),
                      (isSystemCompany() || (currentUserCompany().id === movement.createdBy.companyId && !get(movement,'orderId')))
                    )
                  )
                }
                {
                  get(movement, 'commodityContract') &&
                    !showSeller &&
                    movement.customer &&
                    this.getCounterPartiesSectionAsPerGrid(customerPayload, this.getSectionTitle(sellerLabel))
                }
                {
                  get(movement, 'commodityContract') &&
                    !showBuyer &&
                    movement.customer &&
                    this.getCounterPartiesSectionAsPerGrid(customerPayload, this.getSectionTitle(buyerLabel))
                }
                {
                  showSeller && movement.canViewParties &&
                    this.getCounterPartiesSectionAsPerGrid(sellerPayload, this.getSectionTitle(sellerLabel))
                }
                {
                  showBuyer && movement.canViewParties &&
                    this.getCounterPartiesSectionAsPerGrid(buyerPayload, this.getSectionTitle(buyerLabel))
                }
                {
                  this.getCounterPartiesSectionAsPerGrid(assignorPayload, 'Assignor')
                }
              </div>
            )
        }
        {
          this.state.open &&
          <CompanyAssignmentDialog
            onClose={this.handleDialogClose}
            open={this.state.open}
            movementId={get(movement, 'id')}
            entityField='customerCompanyId'
            entityName='Customer'
          />
        }
      </Paper>
    );
  }
}

export default MovementDetailsContacts;
