import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import GenericTable from '../GenericTable';
import ImpexDocsMappingForm from './ImpexDocsMappingForm';

const ImpexDocsStatus = ({ company }) => {
  let status = 'Not Linked'
  let color = 'default'
  if(company.isImpexDocsAccountLinked) {
    status = 'Connected'
    color = 'primary'
  }
  else {
    status = 'Disconnected'
    color = 'error'
  }

  const style = {
    fontSize: '1rem',
    height: '40px',
    borderRadius: '20px',
  }
  return <Chip size='large' color={color} variant='outlined' label={`Status: ${status}`} style={style} />
}


class ImpexDocsMappings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawer: false,
      selected: null,
      mappings: [],
      isLoadingMappings: false,
    }
  }

  fetchMappings = () => {
    return this.setState({mappings: [], isLoadingMappings: true}, () => {
      this.refetchMappings(response => this.setState({mappings: response || [], isLoadingMappings: false}))
    })
  }

  refetchMappings = callback => {
    return APIService.companies(this.props.company.id).appendToUrl('impex-docs/mappings/products/')
    .get()
    .then(response => callback ? callback(response) : this.setState({mappings: response || []}))
  }

  componentDidMount() {
    this.fetchMappings()
  }

  toggleDrawer = () => this.setState({drawer: !this.state.drawer, selected: this.state.drawer ? null : this.state.selected})

  onMappingClick = mapping => this.setState({selected: mapping}, this.toggleDrawer)

  onOptionClick = (index, key, id, mapping) => {
    this.setState({selected: mapping}, () => {
      if(key === 'edit')
        this.toggleDrawer()
      else if (key === 'delete')
        this.deleteMapping()
    })
  }

  deleteMapping = () => {
    APIService.companies(this.props.company.id).appendToUrl(`impex-docs/mappings/${this.state.selected.id}/`).delete().then(() => {
      this.setState({selected: null}, () => {
        alertifyjs.success('Mapping deleted successfully')
        this.fetchMappings()
      })
    })
  }

  render() {
    const { company } = this.props
    const { drawer, selected } = this.state
    let columns = [
      { key: 'productCode', header: 'Product Code'},
      { key: 'commodity', header: 'Commodity', },
      { key: 'variety', header: 'Variety', },
      { key: 'grade', header: 'Grade', }
    ]
    return (
      <div style={{ position: 'relative' }}>
        {
          company?.isImpexDocsAccountLinked &&
            <div style={{ position: 'absolute', right: '170px', top: '2px' }}>
              <ImpexDocsStatus company={company}/>
            </div>
        }
        <div style={{ position: 'absolute', right: '0', top: '2px' }}>
          <AddButton
            label='Add Mapping'
            onClick={this.toggleDrawer}
            disabled={!company.isImpexDocsAccountLinked}
          />
        </div>
        <GenericTable
          columns={columns}
          items={this.state.mappings}
          handleDefaultCellClick={this.onMappingClick}
          handleOptionClick={this.onOptionClick}
          optionsItems={[
            {key: 'edit', text: 'Edit'},
            {key: 'delete', text: 'Delete'},
          ]}
          order='asc'
        />
        {
          drawer &&
            <SideDrawer
              isOpen={drawer}
              title={selected?.id ? "Edit Product Code Mapping" : "Create Product Code Mapping"}
              size="small"
              onClose={this.toggleDrawer}
            >
              <ImpexDocsMappingForm {...this.props} selected={selected} onClose={this.toggleDrawer}  onSuccess={this.fetchMappings}/>
            </SideDrawer>
        }
      </div>
    )
  }
}

export default connect()(ImpexDocsMappings);
