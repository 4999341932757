import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './Style.scss';
import TextField from '@mui/material/TextField';
import {map, isEmpty, get} from 'lodash';
import { getCurrentCountryCode } from '../../common/utils'
import { includes, forEach, some } from 'lodash';

const DEFAULT_LATITUDE = -33.865143;
const DEFAULT_LONGITUDE = 151.209900;

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: get(this.props, 'name', ''),
      address: get(this.props, 'address', ''),
      lat: '',
      lng: '',
      showAutoComplete: false,
      addressComponents: [],
      streetName: '',
      suburb: '',
      state: '',
      postCode: '',
    };

    this.isSelected = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.extractedAddressComponents = this.extractedAddressComponents.bind(this);
  }

  getSearchOptions = () => ({
    componentRestrictions: {country: this.props.countryCode || getCurrentCountryCode()}
  });

  componentDidUpdate(prevProps, prevState) {
    if (
        (isEmpty(this.state.address) && this.props.value && prevState.address !== this.props.value)
        || (isEmpty(this.state.name) && this.props.name && prevState.name !== this.props.name)
    ) {
      this.setState(state => ({
        ...state,
        name: this.props.name,
        address: this.props.value,
      }));
    } else if(this.props.resetState && (this.props.value && prevState.address !== this.props.value || this.props.name && prevState.name !== this.props.name) ) {
      this.setState(state => ({
        ...state,
        name: this.props.name,
        address: this.props.value,
      }));
      if (this.props.toggleResetState)
        this.props.toggleResetState()
    } else if (this.props.resetState && (prevState.address !== this.props.value || prevState.name !== this.props.name) && prevProps !== this.props) {
        this.setState(state => ({
          ...state,
          name: '',
          address: '',
          lat: '',
          lng: '',
        }));
        if (this.props.toggleResetState)
          this.props.toggleResetState()
    }
  }

  handleChange(name) {
    this.isSelected = false;
    this.setState(state => ({
      ...state,
      name: name,
      address: name,
      showAutoComplete: true,
    }), () => {
      if (this.props.onChange) this.props.onChange(this.state);
    });
  }

  extractedAddressComponents() {
    let streetName = []
    let locality = ''
    let state = ''
    let suburb =  ''
    let postCode = ''

    forEach(this.state.addressComponents, component => {
      if (some(["street_number", "route"], type => component.types.includes(type)))
        streetName.push(component.long_name);

      if (includes(component.types, "locality"))
        locality = component.long_name;

      if (includes(component.types, "administrative_area_level_2"))
        suburb = component.short_name;

      if (includes(component.types, "administrative_area_level_1"))
        state = component.long_name;

      if (includes(component.types, "postal_code"))
        postCode = component.long_name;
    });
    return {
      streetName: !isEmpty(streetName) && locality ? `${streetName.join(' ')}, ${locality}` : (!isEmpty(streetName) ? streetName.join(' ') : locality),
      suburb: suburb,
      state: state,
      postCode: postCode,
    };
  }

  setAddressWithOnChange(address, latitude, longitude, showAutoComplete){
    let components = this.extractedAddressComponents();
    this.setState(state => ({
      ...state,
      showAutoComplete: showAutoComplete,
      name: state.name,
      address: address,
      lat : latitude,
      lng : longitude,
      streetName: components?.streetName,
      suburb: components?.suburb,
      state: components?.state,
      postCode: components?.postCode,
    }),() => {
      this.props.onChange(this.state);
    });
  }

  handleBlur(event) {
    if (this.props.onBlur) this.props.onBlur(event);
    const address = this.state.address;
    if(address){
      geocodeByAddress(address)
      .then(results => {
        this.setState({addressComponents: results[0].address_components})
        getLatLng(results[0])})
      .then(response => {
        this.setAddressWithOnChange(address,response.lat, response.lng, false);
      })
      .catch(() => {
        this.setAddressWithOnChange(address, DEFAULT_LATITUDE, DEFAULT_LONGITUDE, false);
      });
    } else {
      this.setAddressWithOnChange(address,'', '', false);
    }
  }

  handleSelect(address, placeId) {
    this.isSelected = !!placeId;
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState(state => ({
          ...state,
          name: address,
          address: address,
          lat: latLng.lat,
          lng: latLng.lng,
          showAutoComplete: false,
        }), () => {
          if (this.props.onChange) this.props.onChange(this.state);
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error', error);
        this.setState(state => ({
          ...state,
          name: address,
          address: address,
          lat: DEFAULT_LATITUDE,
          lng: DEFAULT_LONGITUDE,
          showAutoComplete: false,
        }), () => {
          if (this.props.onChange) this.props.onChange(this.state);
        });
      });
  }

  render() {
    return (
      <div className={this.props.disabled && this.state.address ? "relative-pos text-black": "relative-pos"}>
          <PlacesAutocomplete
            value={isEmpty(this.state.name) ? this.state.address : this.state.name}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            searchOptions={this.getSearchOptions()}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <TextField
                  id={this.props.id || 'address'}
                  label={this.props.floatingLabelText || 'Address'}
                  placeholder={this.props.hintText ||  ''}
                  error={!isEmpty(this.props.errorText)}
                  helperText={this.props.errorText ||  ''}
                  {...getInputProps({
                     placeholder: this.props.placeholder,
                     className: 'search-input'
                  })}
                  onBlur={this.handleBlur}
                  disabled={this.props.disabled}
                  variant="standard" />
                  {this.props.disabled && this.state.address ? <i className="icon-lock"></i>: ''}
                {this.state.showAutoComplete &&
                 <div className="autocomplete-dropdown-container">
                   {
                     map(suggestions, (suggestion, i) => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                      const style = suggestion.active ?
                                    { backgroundColor: '#fafafa', cursor: 'pointer' } :
                                    { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div key={i} {...getSuggestionItemProps(suggestion, { className, style })}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                   })}
                 </div>}
              </div>
            )}
          </PlacesAutocomplete>
      </div>
    );
  }
}

export default AutoComplete;
