/*eslint no-process-env: 0*/
/*eslint no-undef: 0*/
import '@babel/polyfill';
import React from 'react';
import Calendar from '@toast-ui/calendar';
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import alertifyjs from 'alertifyjs';
import moment from 'moment';
import 'moment-timezone';
import { connect } from 'react-redux';
import { Realtime } from 'ably';
import {
  IconButton, Menu, MenuItem, Tooltip, Badge, Chip, Dialog, DialogContent,
  DialogActions, Button, Divider
} from '@mui/material/';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RssFeed,
  TrackChanges as ActivityLogIcon,
  Cancel as CancelIcon
} from '@mui/icons-material/';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {
  omit, compact, isEqual, isEmpty, cloneDeep, forEach, isElement, map, filter,
  uniq, orderBy, includes, get, isArray, isNumber, last, find, extend, values, camelCase,
  startCase, reject, join, keys, isString, isDate, flatMap
} from 'lodash';
import APIService from '../../services/APIService';
import {
  getCompanySites, getAllSlots, getOpenOrSelfSlots, updateSlot, createSlots, getSMSettings, bulkUpdateSlot,
} from '../../actions/api/company-sites';
import { getCompanyDetails } from '../../actions/companies';
import { receiveCompanySites, receiveFreightSlots } from '../../actions/companies/company-sites';
import {
  setHeaderText, setBreadcrumbs, isLoading, forceStopLoader, setSettingButton, setLoadingText,
} from '../../actions/main';
import { getCompanyCompaniesMinimalisticWithAssets } from '../../actions/api/companies';
import {
  PRIMARY_COLOR_GREEN, WHITE, INLOAD_COLOR, OUTLOAD_COLOR, ACCENT_COLOR_BLUE, CALENDAR_STRUCT,
  NEUTRAL_GRAY, SLOT_PLANNED, SLOT_BOOKED, SLOT_IN_PROGRESS, SLOT_COMPLETED, SLOT_DELAYED,
  SLOT_CANCELLED, BLACK, COMPANY_ADMIN, OFFICE_ADMIN, PINK_NEON, GREEN_NEON, YELLOW_NEON,
  SLOT_RESTRICTED, OBSERVER_TYPE_ID, TOMATO_RED
} from '../../common/constants';
import {
  commentIcon,
  restrictedIcon,
  collapseLeftNav,
  isSystemCompany,
  isObserver,
  isSiteManagementPath,
  isSiteBookingPath,
  getDateTimeFromUTC,
  toDateFormat,
  getCountryConfig,
  getCountryFormats,
  isCurrentUserCompanyAdmin
} from '../../common/utils';
import FreightSlotForm from './FreightSlotForm';
import SlotStats from './SlotStats';
import SlotsCSV from './SlotsCSV';
import CalendarViewOptions from './CalendarViewOptions';
import Timezones from './Timezones';
import CheckpointFilter from './CheckpointFilter';
import CalendarList from './CalendarList';
import CalendarDateSelector from './CalendarDateSelector';
import TonnageDistribution from './TonnageDistribution';
import OrderDistribution from './OrderDistribution';
import GradeDistribution from './GradeDistribution';
import SiteMessageSideDrawer from './SiteMessageSideDrawer';
import BulkHistory from './BulkHistory';
import MoveSlotsDialog from './MoveSlotsDialog';
import ConfirmMoveSlotsDialog from './ConfirmMoveSlotsDialog';
import './icons.css';
import './SiteManagement.scss';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';

const COMMON_ACTIONS = [
  {
    id: 'multi_slots',
    label: 'Bulk Actions',
    description: 'Book and manage multiple slots in few clicks',
    toggle: false,
  },
  {
    id: 'calendar_view',
    label: 'Calendar View (Weekly)',
    description: 'Customize the calendar view',
    toggle: false,
  },
]

const SM_ACTIONS = [
  {
    id: 'new_slot',
    label: 'Open New Slot',
    description: 'Create a new slot',
    toggle: false,
  },
  { id: 'broadcast_message',
    label: 'Broadcast Message',
    description: 'Create a broadcast message for all Bookies',
    toggle: false,
  },
  {
    id: 'daily_grade',
    label: 'Daily Grade',
    description: 'Daily Receival/Outturn Grades distribution',
    toggle: true,
  },
  ...COMMON_ACTIONS
]

const SB_ACTIONS = [
  ...COMMON_ACTIONS
]


class SiteManagement extends React.Component {
  constructor(props) {
    super(props);
    this._intervals = []
    this._timeouts = []
    this.ABLY_KEY = process.env.ABLY_KEY;
    this.companyId = null;
    this.calendar = cloneDeep(CALENDAR_STRUCT);
    this.allStatuses = [
      'planned', 'booked', 'in_progress', 'completed', 'delayed', 'cancelled', 'rejected', 'restricted'
    ];
    this.multiSlotAnchorRef = React.createRef(null)
    this.state = {
      allCompanies: [],
      checkpoint: this.getCache()?.checkpoint || false,
      unit: getCountryConfig()?.truck?.unit,
      selectedFromQuery: false,
      countryFormats: getCountryFormats(),
      multiSlots: false,
      cancelMultiSlots: false,
      bookMultiSlots: false,
      moveMultiSlots: false,
      showMoveSlotDialog: false,
      showConfirmMoveSlotDialog: false,
      moveSlotDirection: "Forwards",
      directionError: '',
      moveSlotTimeAmount: [],
      timeAmountError: '',
      multiSlotResponse: false,
      selectedPlannedSlots: [],
      selectedBookedSlots: [],
      showHistory: false,
      siteMessage: null,
      selectedSite: null,
      selectedTimezone: null,
      timezones: [],
      siteOrdersMap: {},
      setCalendarVisibility: true,
      settings: null,
      settingsView: false,
      enabledStatuses: this.allStatuses,
      isNewSlot: false,
      slotTarget: null,
      selectedSchedule: null,
      allSlots: [],
      calendars: [],
      renderSchedule: false,
      schedule: [],
      showNewSlotDialog: false,
      listeningOnMyCompanyEvents: false,
      listeningOnSelectedCompanyEvents: false,
      openSiteMessageSideDrawer: false,
      selectedOrderIds: undefined,
      actionButton: true,
      actionButtonOptions: null,
      changeViewButton: false,
      actions: props.siteBooking ? SB_ACTIONS : SM_ACTIONS,
      toggleDailyGrade: null,
      selectedView: null,
      selectedControls: [],
      isDuplicate: false,
    };
    this.onSiteMessageButtonClick = this.onSiteMessageButtonClick.bind(this);
    this.onTimezoneChange = this.onTimezoneChange.bind(this);
    this.onChangeSite = this.onChangeSite.bind(this);
    this.handleNewSlotDialogClose = this.handleNewSlotDialogClose.bind(this);
    this.showNewSlotDialog = this.showNewSlotDialog.bind(this);
    this.showUpdateSlotDialog = this.showUpdateSlotDialog.bind(this);
    this.notifyNoSites = this.notifyNoSites.bind(this);
    this.setVisibleSitesAfterNewLoad = this.setVisibleSitesAfterNewLoad.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
    this.closeRightClickMenu = this.closeRightClickMenu.bind(this);
    this.duplicate = this.duplicate.bind(this);
    this.handleStatClick = this.handleStatClick.bind(this);
    this.stopSlotFlash = this.stopSlotFlash.bind(this);
    this.onCalendarViewChange = this.onCalendarViewChange.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.constructSlot = this.constructSlot.bind(this);
    this.fetchSelectedSiteMessage = this.fetchSelectedSiteMessage.bind(this);
  }

  fetchAllCompanies() {
      APIService
      .companies()
      .appendToUrl('minimal/all/')
      .get()
      .then(items => {
        this.setState({ allCompanies: items});
      });
  }

  getCache = () => JSON.parse((localStorage.getItem('site_management') || '{}'))

  setCache = updates => {
    localStorage.setItem('site_management', JSON.stringify({...this.getCache(), ...updates}))
  }

  setActionButton = event => this.setState({actionButtonOptions: event.currentTarget})

  closeActionButtonOptions = () => this.setState({actionButtonOptions: false})

  closeMultiSlotsResponseDialog = () => this.setState({multiSlotResponse: false, selectedPlannedSlots: [], bookMultiSlots: false, actionButton: true, actionButtonOptions: false})

  onMultiSlotsBookResponse = response => response ? this.setState({multiSlotResponse: response, actionButton: true, actionButtonOptions: false}) : this.closeMultiSlotsResponseDialog()

  cancelMultiSlots = () => this.setState({multiSlots: false, cancelMultiSlots: false, bookMultiSlots: false, moveMultiSlots:false, selectedPlannedSlots: [], selectedBookedSlots: [], actionButton: true, actionButtonOptions:false})

  handleSlotDirection = direction => this.setState({moveSlotDirection: direction})

  toggleMultiSlots = event => {
    if(this.isMultiSlotBookingSettingOn()) {
    const newValue = !this.state.multiSlots
    if(!newValue && event && this.multiSlotAnchorRef.current && this.multiSlotAnchorRef.current.contains(event.target))
      return
      this.setState({multiSlots: newValue})
    } else {
      alertifyjs.alert('Multi-Slot Booking', 'The multislot booking feature is a premium feature. Please contact <a href="mailto: support@agrichain.com" target=="_blank" rel="noopener noreferrer">support@agrichain.com</a> to activate', () => {})
    }
  }

  toggleMultiSlotsBooking = () => {
    if(!this.isSiteSelected())
      this.setState({bookMultiSlots: !this.state.bookMultiSlots, cancelMultiSlots: false, multiSlots: false, selectedPlannedSlots: [], actionButton: false})
  }

  toggleMultiSlotsCancel = () =>  {
    if(!this.isSiteSelected())
      this.setState({cancelMultiSlots: !this.state.cancelMultiSlots, bookMultiSlots: false, multiSlots: false, selectedBookedSlots: [], actionButton: false})
  }

  toggleMultiSlotsMove = () =>  {
    if(!this.isSiteSelected())
      this.setState({moveMultiSlots: !this.state.moveMultiSlots, cancelMultiSlots: false, bookMultiSlots: false, multiSlots: false, selectedPlannedSlots: [], selectedBookedSlots: [], actionButton: false})
  }

  toggleSelectedPlannedSlot = slot => {
    const exists = find(this.state.selectedPlannedSlots, {id: slot.id})
    this.setState({
      selectedPlannedSlots: exists ?
        reject(this.state.selectedPlannedSlots, {id: slot.id}) :
        [...this.state.selectedPlannedSlots, slot]
    }, () => this.self.render(true))
  }

  toggleSelectedBookedSlot = slot => {
    const exists = find(this.state.selectedBookedSlots, {id: slot.id})
    this.setState({
      selectedBookedSlots: exists ?
        reject(this.state.selectedBookedSlots, {id: slot.id}) :
        [...this.state.selectedBookedSlots, slot]
    }, () => this.self.render(true))
  }

  hasPlannedSlots = () => Boolean(find(this.state.schedule, {status: 'planned'}))
  hasBookedSlots = () => Boolean(find(this.state.schedule, schedule => ['booked', 'delayed'].includes(schedule.status)))

  handleHistoryIconClick = () => this.setState({showHistory: !this.state.showHistory});

  stopSlotFlash() {
    if(this.state.selectedSchedule)
      this.props.updateSlot(
        get(this.state.selectedSchedule, 'id'),
        {
          isUrgentForProvider: false,
          siteId: parseInt(get(this.state.selectedSchedule, 'calendarId')),
          updatedAt: get(this.state.selectedSchedule, 'raw.slot.updatedAt'),
          settingsUpdatedAt: get(this.state.selectedSchedule, 'raw.slot.settingsUpdatedAt'),
        });

    this.closeRightClickMenu();
  }

  closeRightClickMenu() {
    this.setState({slotTarget: null, selectedSchedule: null});
  }

  onContextMenu(e) {
    e.preventDefault();
    const slotContainer = e.target.closest('div[data-event-id]');
    if(slotContainer) {
      const schedule = find(this.state.schedule, {id: slotContainer.getAttribute('data-event-id')});
      this.setState({slotTarget: e.currentTarget, selectedSchedule: schedule});
    }
  }

  duplicate() {
    if(this.state.selectedSchedule)
      this.showNewSlotDialog({event: this.getDataToDuplicate()}, true);
      this.setState({isDuplicate: true});
  }

  getDataToDuplicate() {
    let schedule = cloneDeep(this.state.selectedSchedule);
    if(schedule) {
      let slot = get(schedule, 'raw.slot');
      if(slot) {
        schedule.raw.slot = omit(
          slot,
          [
            'id', 'siblings', 'createdById', 'updatedById', 'createdAt', 'updatedAt',
            'settingsUpdatedAt', 'freightProvider', 'commodity', 'grade', 'entity', 'parentGradeName',
            'isActive', 'hasSiblings', 'providerUpdatedFields', 'commentsCount',
            'isUrgentForProvider', 'isUrgentForManager', 'onRoute', 'movement', 'movementId',
          ]
        );
        schedule.raw.slot.status = slot.status === 'restricted' ? 'restricted' : slot.orderId || includes(['booked', 'in_progress', 'completed'], slot.status) ? 'booked' : 'planned'
        schedule = omit(schedule, ['id']);
      }
    }
    return schedule;
  }

  isSiteManagerView() {
    return !this.props.siteBooking && !this.props.readOnly;
  }

  updateSettingsState(settings) {
    if(!isEqual(this.state.settings, settings) && !isEmpty(settings)) {
      this.setState({settings: settings}, () => {
        const newState = {...this.state};
        newState.schedule = this.setSlotColor(newState.schedule);
        newState.allSlots = this.setSlotColor(newState.allSlots);
        this.setState(newState, () => {
          this.refreshSchedule()
        });
      });
    }
  }

  fetchSiteOrders = () => {
    const site = this.state.selectedSite
    if(site?.id && !isEmpty(this.state.settings?.titleOrder) && [...this.state.settings.titleOrder, ...this.state.settings.tooltipOrder].includes('siteOrder')) {
      this.setState({isFetchSiteOrders: true}, () => {
        const start = this.getStartForSlotsCall()
        const end = this.getEndForSlotsCall()
        APIService
          .freights()
          .orders()
          .appendToUrl('site-orders/')
          .post({siteId: site.id, companyId: this.props.currentUserCompanyId, start: start, end: end})
          .then(response => this.setState({siteOrdersMap: response || {}, isFetchSiteOrders: false}, this.refreshSchedule))
      })
    }
  }

  setSlotColor(slots) {
    if(!isArray(slots))
      return [];

    forEach(slots, slot => {
      const color = this.getSlotColor(slot);
      slot.backgroundColor = color;
      slot.dragBackgroundColor = color;
    });

    return slots;
  }

  setupHeader() {
    const { dontSetBreadcrumbs, setHeaderText, setBreadcrumbs } = this.props;

    if(!dontSetBreadcrumbs) {
      setHeaderText('Site Management');
      setBreadcrumbs([{text: 'Site Management'}]);
    }
  }

  async componentDidMount() {
    const {
      siteBooking, currentUserCompanyId, company,
      getSMSettings, getCompanyCompaniesMinimalisticWithAssets, setLoadingText,
      startLoader, dontAssignColor, location
    } = this.props;

    collapseLeftNav();
    this.fetchTimezones();

    startLoader('tuiCalendar');
    setLoadingText('Loading calendar...');
    this.setupHeader();

    const query = new URLSearchParams(location.search);
    const siteId = query.get('siteId');
    this.getSites(siteId ? parseInt(siteId) : undefined);
    this.getScheduleData();
    getSMSettings(get(company, 'id'));
    this.fetchAllCompanies()
    this.initiateLiveReload();

    if(!siteBooking && currentUserCompanyId)
      getCompanyCompaniesMinimalisticWithAssets(currentUserCompanyId);

    this._timeouts.push(setTimeout(this.setVisibleSitesAfterNewLoad, 2500));

    if(!dontAssignColor)
      this._intervals.push(setInterval(() => {
        this.setColor();
        this.hideScheduleBullet();
      }, 100));


    this._intervals.push(setInterval(this.setTooltip, 300));
    this.pollForSiteMessage();
  }

  fetchTimezones() {
    APIService.timezones().get(this.props.token).then(timezones => {
      this.setState({timezones: timezones});
    });
  }

  setVisibleSitesAfterNewLoad() {
    if(this.state.setCalendarVisibility) {
      this.setState({setCalendarVisibility: false}, () =>  {
        const text = 'Please select Site(s) from menu';
        if(isArray(this.state.calendars) && this.state.calendars.length > 0 && !this.isAlreadyShowingAlertify(text))
          alertifyjs.warning(text, 2);
      });
    }
  }

  getDefaultSelectedSiteId() {
    const { selectedSiteId, currentUser, location } = this.props;
    const query = new URLSearchParams(location.search);
    const siteId = query.get('siteId');
    if(isNumber(selectedSiteId))
      return selectedSiteId;
    if(siteId)
      return parseInt(siteId);
    const userFarmIds = get(currentUser, 'farmIds') || [];
    return userFarmIds.length === 1 ? userFarmIds[0] : undefined
  }

  isAlreadyShowingAlertify(text) {
    const el = document.querySelector('div.ajs-message.ajs-warning.ajs-visible');
    return get(el, 'textContent', '') === text;
  }

  alertifySlotUpdate(slot) {
    if(slot) {
      const mStart = moment(slot.start);
      const site = find(this.state.calendars, {id: slot.siteId});
      const description = `Slot for <b>${get(site, 'name')}</b> site on <b>${mStart.format(this.state.countryFormats.shortDateDisplay)}</b> at <b>${mStart.format(this.state.countryFormats.time)}</b> is getting updated...`;
      alertifyjs.success(description, 10);
    } else {
      alertifyjs.success('Updating slots...', 10);
    }
  }

  alertifyNewSlot(data) {
    if(isArray(data) && data.length > 0)
      alertifyjs.success(`${data.length} new slots are coming through...`, 8);
    else
      alertifyjs.success(`new slots are coming through...`, 8);

  }

  isInCurrentView(slot) {
    if(this.self && slot && parseInt(slot.siteId) === parseInt(this.getSelectedSiteId())) {
      const mStart = moment(slot.start);
      const mEnd = moment(slot.end);
      const currentViewStart = this.self.getDateRangeStart().toDate();
      const currentViewEnd = this.self.getDateRangeEnd().toDate();
      if(mStart && mEnd && currentViewStart && currentViewEnd)
        return mStart.isSameOrAfter(currentViewStart) && mEnd.isSameOrBefore(currentViewEnd);
    }
  }

  updateOnUpsertEvent(companyId) {
    if(companyId && this.ably) {
      const that = this;
      const channelNew = this.ably.channels.get('freight-slots-new');
      channelNew.subscribe(companyId.toString(), message => {
        if(!document.querySelector('div.ajs-message.ajs-success.ajs-visible'))
          that.alertifyNewSlot(message.data);

        this._timeouts.push(setTimeout(() => that.getScheduleData(false), 5000));
      });

      const channelUpdate = this.ably.channels.get('freight-slots-update');
      channelUpdate.subscribe(companyId.toString(), message => {
        const data = JSON.parse(message.data);
        if(!document.querySelector('div.ajs-message.ajs-success.ajs-visible'))
          that.alertifySlotUpdate(data);

        if(that.isInCurrentView(data))
          this._timeouts.push(setTimeout(() => that.getScheduleData(false), 5000));
      });
    }
  }

  updateOnDeleteEvent(companyId) {
    if(companyId && this.ably) {
      const that = this;
      const channel = this.ably.channels.get('freight-slots-delete');
      channel.subscribe(companyId.toString(), message => {
        that.removeSlotsAndResetSchedule(get(message, 'data'));
      });
    }
  }

  removeSlotsAndResetSchedule(deletedSlotIds) {
    if(isArray(deletedSlotIds) && !isEmpty(deletedSlotIds)) {
      const { allSlots } = this.state;
      const ids = map(compact(deletedSlotIds), id => id.toString());
      const remainingSlots = reject(allSlots, slot => includes(ids, slot.id));
      this.setState({schedule: remainingSlots, allSlots: remainingSlots}, this.refreshSchedule);
    }
  }

  initiateLiveReload() {
    if(this.ABLY_KEY) {
      const { currentUserCompanyId, company, siteBooking } = this.props;

      this.ably = new Realtime(this.ABLY_KEY);
      this.ably.connection.on('connected', () => {
        // eslint-disable-next-line no-console
        console.log('Connected!');
      });

      this.setState({listeningOnMyCompanyEvents: true}, () => {
        let companyId = (company && siteBooking) ? company.id : currentUserCompanyId;
        this.updateOnUpsertEvent(companyId);
        this.updateOnDeleteEvent(companyId);
      });
    }
  }

  notifyNoSites(sites) {
    if(isArray(sites) && sites.length === 0) {
      this.props.forceStopLoader();
      alertifyjs.warning('You have no sites. Please add sites in your company via Company Settings', 0);
    } else if(isArray(sites) && sites.length > 0) {
      this.forceCalendarRender();
    }
  }

  isCalendarRendered() {
    return isElement(document.querySelector('div.toastui-calendar-layout'));
  }

  shouldReRenderCalendar() {
    return !this.isCalendarRendered();
  }

  forceCalendarRender() {
    const _timeout = setTimeout(
      () => {
        this._reRender = setInterval(() => {
          if(!isSiteManagementPath() && !isSiteBookingPath())
            this.clearDeferred()
          else if(this.shouldReRenderCalendar()) {
            this.constructCalendar();
            this.setVisibleSitesAfterNewLoad();
          } else if(this.isCalendarRendered() && this.props.isLoading && !this.props.waitForComponent) {
            clearInterval(this._reRender);
            this.props.forceStopLoader();
          }
        }, 1000);
        this._intervals.push(this._reRender)
      },
      1000
    );
    this._timeouts.push(_timeout)
  }

  getStartForSlotsCall() {
    if(this.self) {
      const date = moment(this.self.getDateRangeStart().toDate()).utc().format('YYYY-MM-DD');
      return date + 'T00:00:00';
    }
    else
      return '';
  }

  getEndForSlotsCall() {
    if(this.self)
    {
      const date = moment(this.self.getDateRangeEnd().toDate()).add(1, 'days').utc().format('YYYY-MM-DD');
      return date + 'T23:59:59';
    }
    else
      return '';
  }

  getScheduleData(showLoader=true) {
    const {
      forceStopLoader, isLoading, startLoader, siteBooking, company, getAllSlots,
      currentUserCompanyId
    } = this.props;

    let selectedSiteId = this.getSelectedSiteId();
    if(!selectedSiteId) {
      this._timeouts.push(setTimeout(forceStopLoader, 4000));
      return;
    }
    if(!isLoading && showLoader)
      startLoader('waitTillStopped');
    let startDate = this.getStartForSlotsCall();
    let endDate = this.getEndForSlotsCall();
    if(!startDate || !endDate)
      return
    if(siteBooking && company) {
      this.getBookingData(startDate, endDate, selectedSiteId);
      this.fetchSiteOrders()
    } else if(currentUserCompanyId) {
      this.companyId = currentUserCompanyId;
      getAllSlots(this.companyId, startDate, endDate, selectedSiteId, true);
      this.fetchSiteOrders()
    } else {
      forceStopLoader();
    }
  }

  getSites(defaultSiteId) {
    const {siteBooking, company, currentUserCompanyId} = this.props;
    if(siteBooking && company) {
      this.company = company;
      this.companyId = company.id;
    } else if(currentUserCompanyId) {
      this.companyId = currentUserCompanyId;
    }

    if(this.companyId)
      this.props.getCompanySites(this.companyId, sites => {
        if(defaultSiteId) {
          this.onChangeSite(find(sites, {id: defaultSiteId}));
        }
        this.notifyNoSites(sites);
      });

  }

  getBookingData(start, end, siteId) {
    let startDate = start || this.getStartForSlotsCall();
    let endDate = end || this.getEndForSlotsCall();
    let selectedSiteId = siteId || this.getSelectedSiteId();

    this.company = this.props.company;
    this.companyId = this.props.company.id;
    if(selectedSiteId)
      this.props.getOpenOrSelfSlots(
        this.props.currentUserCompanyId, this.companyId, startDate, endDate, selectedSiteId, true
      );
  }

  handleNewSlotDialogClose(fetchSlots) {
    this.removeEmptyEventElement()
    this.hideAllTooltip()
    this.setState({showNewSlotDialog: false}, () => {
      this.closeRightClickMenu();
      this.refreshSchedule();
      if(this.state.isDuplicate)
        this.setState({isDuplicate: false});
      if(fetchSlots)
        this.getScheduleData();
    });
  }


  clearIntervals = () => this._intervals.forEach(_interval => clearInterval(_interval))
  clearTimeouts = () => this._timeouts.forEach(_timeout => clearTimeout(_timeout))
  clearDeferred = () => {
    this.clearIntervals()
    this.clearTimeouts()
  }

  componentWillUnmount() {
    this.clearDeferred()
    this.destroyCalendar();
    this.props.setLoadingText(null);
    this.props.resetCompanySites();
    this.props.resetSlots();
    if(this.ably)
      this.ably.connection.close();

    if(this._reRender)
      clearInterval(this._reRender);
    if(this.fetchSiteMessagePoll)
      clearInterval(this.fetchSiteMessagePoll);
  }

  hideAllTooltip() {
    if(window.$) {
      try {
        window.$(".tooltip").tooltip("hide");
      } catch {
        //doesn't work for old browsers, cant do much there!
      }
    }
  }

  setTooltip() {
    if(window.$ && !isEmpty(this.state.schedule)) {
      window.$('div [data-event-id]').each((i, el) => {
        const scheduleId = parseInt(el.getAttribute('data-event-id'));
        let slot;
        if(this.props.slots && scheduleId)
          slot = find(this.props.slots, {id: scheduleId});
        if(this.props.schedule && scheduleId)
          slot = find(this.props.schedule, {id: scheduleId.toString()});
        if(slot) {
          try {
            window.$(el).tooltip({
              title: this.getTitleHTML(slot, true),
              html: true,
              container: 'body'
            });
          } catch {
            //doesn't work for old browsers, cant do much there!
          }
        }
      });
    }
  }

  resetStatusFilters() {
    this.setState({enabledStatuses: this.allStatuses});
  }

  getTrailerBookingEligibleSlots() {
    const { currentSlot, schedule } = this.state;
    if(this.isTrailerBooking() && !isEmpty(currentSlot)) {
      const rawCurrentSlot = get(currentSlot, 'event.raw.slot');
      if(!rawCurrentSlot)
        return [];
      return filter(schedule, slot => {
        const rawSlot = slot.raw.slot;
        return currentSlot.event.id !== slot.id &&
          includes(compact(['planned', 'booked', get(currentSlot, 'event.raw.slot.status')]), rawSlot.status) &&
          (!rawSlot.orderId || rawCurrentSlot.orderId === rawSlot.orderId) &&
          (!rawSlot.commodityId || rawCurrentSlot.commodityId === rawSlot.commodityId) &&
          (!rawSlot.gradeId || rawCurrentSlot.gradeId === rawSlot.gradeId) &&
          (!rawSlot.freightProviderId || rawCurrentSlot.freightProviderId === rawSlot.freightProviderId) &&
          (!rawSlot.movementId || rawCurrentSlot.movementId === rawSlot.movementId) &&
          toDateFormat(rawCurrentSlot.start) === toDateFormat(rawSlot.start);
      });
    }
    return [];
  }

  getExistingTrailerSlots() {
    const { currentSlot } = this.state;
    if(this.isTrailerBooking() && !isEmpty(currentSlot))
      return get(currentSlot, 'event.raw.slot.trailerSlotIds');
    return [];
  }

  getParentSlot() {
    const { currentSlot, schedule } = this.state;
    if(this.isTrailerBooking() && !isEmpty(currentSlot)) {
      const parentSlotId = get(currentSlot, 'event.raw.slot.parentSlotId');
      if(parentSlotId)
        return find(schedule, {id: parentSlotId.toString()});
    }
  }

  componentDidUpdate(prevProps){
    const {
      settings, company, selectedSiteId, sites, getSMSettings, siteBooking,
      slots,
    } = this.props;

    this.setCurrentViewDateRanges();
    if(isEmpty(this.state.settings))
      this.updateSettingsState(settings);

    if(selectedSiteId && isNumber(selectedSiteId) && selectedSiteId !== prevProps.selectedSiteId)
      this.resetStatusFilters();

    if(
      company && !this.state.listeningOnSelectedCompanyEvents && this.ABLY_KEY && this.ably
    )
      this.setState({listeningOnSelectedCompanyEvents: true}, () => {
        this.updateOnUpsertEvent(company.id);
        this.updateOnDeleteEvent(company.id);
      });

    if(!isEqual(company, prevProps.company))
      this.setState(
        { setCalendarVisibility: true },
        () => {
          getSMSettings(get(company, 'id'));
          this.getBookingData();
        });

    if(siteBooking && !isEqual(sites, prevProps.sites)) {
      this.destroyCalendar();
      this._createCalendar();
      this.refreshSchedule(true);
    }

    if(!this.self)
      this.createCalendar();

    if(
      isArray(slots) && (
        !isEqual(slots, prevProps.slots) ||  // For new slots from API
          ( //to prevent allSlots from getting empty accidently, ideally not needed
            isArray(this.state.allSlots) &&
              this.state.allSlots.length === 0 &&
              slots.length > 0
          )
      )
    ) {
      const allSlots = this.constructSchedule(slots);
      if(!isEqual(allSlots, this.state.allSlots)) {
        this.setState({
          schedule: allSlots, allSlots: allSlots, selectedOrderIds: undefined
        }, () => {
          this.refreshSchedule(true)
          this._timeouts.push(setTimeout(this.selectSlotFromQuery, 500))
        });
      }
    }
  }

  selectSlotFromQuery = () => {
    if(this.state.selectedFromQuery)
      return
    const queryParams = new URLSearchParams(this.props.location.search);
    const slotId = queryParams.get('slotId') || this.props.selectedSlotId;
    let slotType = queryParams.get('slotType');

    const callback = () => {
      const slot = find(this.state.schedule, {id: slotId});
      if(slot)
        this.setState(
          {selectedFromQuery: true},
          () => this.showUpdateSlotDialog({event: slot})
        )
    }

    if(slotId) {
      if(
        slotType &&
          !['all', slotType.toLowerCase()].includes(this.state.checkpoint) &&
          this.state.checkpoint
      )
        this.onChangeCheckpoint('all', callback)
      else
        callback()
    }
  }

  refreshSchedule(immediately=false) {
    if(this.self)
      this.updateScheduleByVisibleCalendars(immediately);
  }

  updateScheduleByVisibleCalendars(immediately) {
    let visibleSlots = [];
    const selectedSiteId = this.getSelectedSiteId();
    visibleSlots = filter(
      this.state.allSlots,
      slot => selectedSiteId === parseInt(get(slot, 'calendarId'))
    );

    if(this.state.enabledStatuses)
      visibleSlots = filter(
        visibleSlots,
        slot => includes(
          this.state.enabledStatuses,
          get(slot, 'raw.slot.status')
        )
      );

    if(this.state.checkpoint && this.state.checkpoint !== 'all') {
      visibleSlots = filter(visibleSlots, slot => {
        const type = slot?.raw?.slot?.type
        return !type || this.state.checkpoint === type
      })
    }

    if(this.state.selectedOrderIds !== undefined && isArray(this.state.selectedOrderIds) && this.state.selectedOrderIds.length > 0)
      visibleSlots = filter(visibleSlots, slot => includes(this.state.selectedOrderIds, get(slot, 'raw.slot.orderId')));

    this._renderSchedule(visibleSlots, immediately);
  }

  _renderSchedule(schedule, immediately=false) {
    schedule = schedule || this.state.schedule;
    this.setState({
      schedule: orderBy(uniq(schedule, 'id'), 'id')
    }, () => {
      this.self.clear(immediately);
      this.self.createEvents(this.state.schedule, false);
      this.self.render(immediately);
    });
  }

  handleStatClick(statuses) {
    this.setState({enabledStatuses: statuses}, this.refreshSchedule);
  }

  shouldAlertSlot(schedule) {
    return get(schedule, 'raw.slot.isUrgentForProvider') && this.props.siteBooking;
  }


  shouldAlertSlotForManager(schedule) {
    return get(schedule, 'raw.slot.isUrgentForManager') && !this.props.siteBooking;
  }


  getTimeTemplate(schedule) {
    var html = [];
    const onRoute = get(schedule, 'raw.slot.onRoute');
    let status = get(schedule, 'raw.slot.status');
    if(onRoute)
      status += ' on-route';

    const selectedPlannedOrBookedSlots = this.state.selectedPlannedSlots.concat(this.state.selectedBookedSlots)
    const isSelectedForMultiSlotBooking = Boolean(find(this.state.selectedPlannedSlots, {id: schedule.id}))
    const isSelectedForMultiSlotCancel = Boolean(find(this.state.selectedBookedSlots, {id: schedule.id}))
    const isSelectedForMultiSlotMove = Boolean(find(selectedPlannedOrBookedSlots, {id: schedule.id}))

    if(this.shouldAlertSlot(schedule) || this.shouldAlertSlotForManager(schedule))
      html.push(`<div class="${status}" style="margin-top:4px; font-weight:400;" data-border-color=${this.getSlotBorderColor(get(schedule.raw, 'slot'))} data-color=${schedule.dragBackgroundColor} data-class="slot-urgent" data-multi-slot-selected=${isSelectedForMultiSlotBooking || isSelectedForMultiSlotCancel || isSelectedForMultiSlotMove}>`);
    else
      html.push(`<div class="${status}" style="margin-top:4px; font-weight:400;" data-border-color=${this.getSlotBorderColor(get(schedule.raw, 'slot'))} data-color=${schedule.dragBackgroundColor} data-multi-slot-selected=${isSelectedForMultiSlotBooking || isSelectedForMultiSlotCancel || isSelectedForMultiSlotMove}>`);

    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(' Private');
    } else {
      if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (isArray(schedule.attendees) && schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      }
      const title = this.getTitleHTML(schedule.raw.slot, false, schedule.start) || schedule.title;
      html.push(title);
      html.push('</div>');
    }

    return html.join('');
  }

  setColor() {
    const colors = this.getAllSlotColors();
    const isMultiSlotBooking = this.state.bookMultiSlots
    const isMultiSlotCancel = this.state.cancelMultiSlots
    const isMultiSlotMove = this.state.moveMultiSlots
    colors.forEach(color => {
      const els = document.querySelectorAll(`[data-color="${color}"]`);
      if(!isEmpty(els)) {
        els.forEach(el => {
          const className = el.getAttribute('class')
          let opacity = 1
          if((isMultiSlotBooking && className === 'planned') || (isMultiSlotCancel && ['booked', 'delayed'].includes(className)) || (isMultiSlotMove && ['booked', 'planned'].includes(className))) {
            const isSelectedForMultiSlot = el.getAttribute('data-multi-slot-selected') === 'true'
            if(isSelectedForMultiSlot)
              opacity = 1
            else
              opacity = 0.5
          }
          const firstContainerAfterMain = el.parentElement.parentElement
          const mainSlotContainer = firstContainerAfterMain.parentElement
          firstContainerAfterMain.style.opacity = opacity
          if(includes(el.classList, 'on-route'))
            mainSlotContainer.style.borderRight = '8px solid ' + PRIMARY_COLOR_GREEN;
          if(el.getAttribute('data-class'))
            mainSlotContainer.classList.add(el.getAttribute('data-class'));
          if(!this.props.siteBooking || includes(el.dataset.class, 'slot-urgent'))
            mainSlotContainer.oncontextmenu = this.onContextMenu;
        });
      }
    });
  }


  removeEmptyEventElement = () => {
    let el = document.querySelector(
      'div.toastui-calendar-time.toastui-calendar-grid-selection'
    );
    if(el) {
      el.remove()
      el.style.display = 'none'
    }
  }

  hideScheduleBullet() {
    const els = document.querySelectorAll('span.toastui-calendar-weekday-event-dot');
    if(!isEmpty(els))
      els.forEach(el => {el.style.display = 'none';});
  }

  toUTC(date) {
    const mDate = moment(date);
    return mDate.utc().format();
  }

  slotDataForUpdate(schedule) {
    let slot = {};
    let start = isString(schedule.start) || isDate(schedule.start) || !schedule.start ? schedule.start : schedule.start.toDate()
    let end = isString(schedule.end) || isDate(schedule.end) || !schedule.end ? schedule.end : schedule.end.toDate()
    slot.start = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').isValid() ? this.toUTC(start) : start;
    slot.end = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').isValid() ? this.toUTC(end) : end;
    [
      'siteId', 'type', 'bookingNumber', 'id',
      'deliveryOrderNumber', 'status', 'commodityId',
      'gradeId', 'tonnage', 'driverId', 'priority', 'truckId', 'updatedAt', 'settingsUpdatedAt',
    ].forEach((attr) => {
      slot[attr] = get(schedule, `raw.slot.${attr}`);
    });
    return slot;
  }

  getCalendarsFromSites() {
    let calendars = [];
    let {sites, currentUser} = this.props;
    const userFarmIds = get(currentUser, 'farmIds') || [];

    if(!isArray(sites))
      sites = [];
    sites = filter(sites, site => site.isActive)
    if (!isEmpty(userFarmIds) && !this.props.siteBooking && !isCurrentUserCompanyAdmin() && !isObserver())
      sites = filter(sites, site => includes(userFarmIds, site.id));
    sites.forEach(site => {
      let calendar = cloneDeep(this.calendar);
      calendar.id = site.id;
      calendar.companyId = site.companyId;
      calendar.name = site.name;
      calendar.color = WHITE;
      calendar.backgroundColor = ACCENT_COLOR_BLUE;
      calendar.borderColor = ACCENT_COLOR_BLUE;
      calendar.dragBackgroundColor = ACCENT_COLOR_BLUE;
      calendars.push(calendar);
    });

    return calendars;
  }

  formatTemplateTime(time) {
    let _time = time.time
    const _hour = _time.getHours()
    let hour, meridiem;
    if(_hour < 12) {
      hour = _hour;
      meridiem = 'am';
    } else if (_hour === 12) {
      hour = 12;
      meridiem = 'pm';
    } else {
      hour = _hour - 12;
      meridiem = 'pm';
    }

    if(hour === 0)
      hour = '00'
    if(_time.getMinutes())
      hour += `:${_time.getMinutes()}`;
    return hour + ' ' + meridiem;
  }

  constructCalendar() {
    if(!this.self) {
      const _timeout = setTimeout(() => {
        const that = this;
        /*eslint no-undef: 0*/
        if(this.self)
          this.destroyCalendar();

        this.self = new Calendar('#calendar', {
          defaultView: 'week',
          taskView: false,
          useFormPopup: false,
          useDetailPopup: false,
          usageStatistics: false,
          calendars: this.state.calendars,
          timezone: {
            zones: [
              {
                timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                tooltip: that.getCurrentTimezoneDisplayName(),
                displayLabel: that.getCurrentTimezoneAbbr(),
                timezoneOffset: that.getCurrentTimezoneOffset()
              }
            ]
          },
          theme: {
            week: {
              timeGridOneHour: {
                height: '100px'
              },
              timeGridLeft: {
                width: '100px',
              },
              dayGridLeft: {
                width: '100px',
              }
            }
          },
          template: {
            timegridDisplayTime: time => that.formatTemplateTime(time),
            timegridDisplayPrimaryTime: time => that.formatTemplateTime(time),
            time: schedule => that.getTimeTemplate(schedule),
            timegridNowIndicatorLabel: time => that.formatTemplateTime(time)
          },
          dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          month: {
            startDayOfWeek: 1,
          },
          week: {
            startDayOfWeek: 1,
            taskView: false,
            eventView: ['time'],
            showTimezoneCollapseButton: true
          }
        });
        this.setState({renderSchedule: true}, () => {
          const queryParams = new URLSearchParams(this.props.location.search);
          const start = queryParams.get('startDate') || this.props.startDate;
          const end = queryParams.get('endDate') || this.props.endDate;
          if(start)
            this.self.setDate(new Date(start));
          if(end)
            this.self.setDate(new Date(end));

          this._timeouts.push(setTimeout(this.selectSlotFromQuery, 1000));

          this.self.on({
            'clickEvent': e => {
              const schedule = e.event
              const status = get(schedule, 'raw.slot.status')
              if(this.state.bookMultiSlots) {
                if(status === 'planned')
                  this.toggleSelectedPlannedSlot(schedule)
              } else if(this.state.cancelMultiSlots) {
                if(['booked', 'delayed'].includes(status))
                  this.toggleSelectedBookedSlot(schedule)
              } else if(this.state.moveMultiSlots) {
                if(['planned', 'booked'].includes(status)) {
                  this.toggleSelectedPlannedSlot(schedule)
                  this.toggleSelectedBookedSlot(schedule)
                }
              } else {
                if(that.props.siteBooking && status === 'restricted')
                  return;
                if(that.props.handleSlotClick) {
                  that.props.handleSlotClick(schedule);
                } else {
                  that.showUpdateSlotDialog(e);
                }
              }
            },
            'beforeDeleteEvent': e => {
              that.showNewSlotDialog(e);
            },
            'beforeCreateEvent': e => {
              that.removeEmptyEventElement()
              if(!that.props.readOnly) {
                if(this.state.bookMultiSlots || this.state.cancelMultiSlots || this.state.moveMultiSlots)
                  return
                if(that.props.handleNewSlot)
                  that.props.handleNewSlot(e);
                else
                  that.showNewSlotDialog(e, true);
              }
            },
            'selectDateTime': e => {
              that.removeEmptyEventElement()
              if(!that.props.readOnly) {
                if(this.state.bookMultiSlots || this.state.cancelMultiSlots || this.state.moveMultiSlots)
                  return
                if(that.props.handleNewSlot)
                  that.props.handleNewSlot(e);
                else {
                  that.showNewSlotDialog(e, true);
                }
              }
            },
            'beforeUpdateEvent': e => {
              this.hideAllTooltip()
              if(this.state.bookMultiSlots || this.state.cancelMultiSlots)
                return
              if(!that.props.siteBooking) {
                e.event.start = e.changes.start || e.event.start
                e.event.end = e.changes.end || e.event.end;
                this.hideAllTooltip()
                this.props.updateSlot(e.event.raw.slot.id, that.slotDataForUpdate(e.event));
              }
            },
            'clickTimezonesCollapseBtn': prevCollapsedState => {
              this.self.setOptions({
                week: {
                  timezonesCollapsed: !prevCollapsedState,
                },
              });
            }
          });
          this.refreshSchedule();
        });
      }, 500);
      this._timeouts.push(_timeout)
    }
  }

  destroyCalendar() {
    if(this.self) {
      this.self.destroy();
      this.self = null;
      const calendarEl = document.getElementById('calendar');
      if(calendarEl)
        calendarEl.innerHTML = '';
    }
    document.querySelectorAll('div.toastui-calendar-layout').forEach(el => el.remove())
  }

  createCalendar() {
    if(!isEmpty(this.props.sites) && isEmpty(this.state.calendars))
      this._createCalendar();
  }

  _createCalendar() {
    const calendars = this.props.calendars || this.getCalendarsFromSites();
    if(!isEqual(this.state.calendars, calendars))
      this.setState({calendars: calendars}, this.constructCalendar);
  }

  showNewSlotDialog(slot, isNewSlot, callback) {
    if(!this.isSiteSelected())
      this.setState({showNewSlotDialog: true, isNewSlot: isNewSlot, currentSlot: slot}, () => {
        if(callback)
          callback()
        this.removeEmptyEventElement()
        this.hideAllTooltip()
      });
  }

  showUpdateSlotDialog(slot) {
    if(this.state.selectedSchedule)
      slot = {event: this.state.selectedSchedule};
    if(get(slot, 'event.raw.slot.isTrailerSlot')) {
      let parentSlot = find(this.state.schedule, {id: get(slot, 'event.raw.slot.parentSlotId').toString()});
      alertifyjs.confirm(
        'Warning',
        `This is a trailer slot booked against the parent slot of <br> ${this.getStartDateTimeTitlePart(get(parentSlot, 'raw.slot'), false)}. To view or make any changes, please visit parent slot.`,
        () => {
          this.showNewSlotDialog({event: parentSlot}, false, () => {
            this.self.updateEvent(slot.event.id, slot.event.calendarId, slot.event);
          });
        },
        () => {}
        ).set('reverseButtons', true).set(
          'labels', {ok: 'Open Parent Slot', cancel: 'Cancel'}
        ).show()
    } else {
      this.showNewSlotDialog(slot, false, () => {
        this.self.updateEvent(slot.event.id, slot.event.calendarId, slot.event);
      });
    }
  }


  onMultiSlotBookClick = event => {
    event.persist()
    this.setState({actionButton: false})
    this.showUpdateSlotDialog({event: event, schedule: this.state.selectedPlannedSlots[0]})
  }

  onMultiSlotMoveClick = event => {
    event.persist()
    this.setState({actionButton: false, showMoveSlotDialog: !this.state.showMoveSlotDialog})
  }


  getSlotsOverlapped = (updatedStart, updatedEnd, scheduleToUpdate) => {
    const plannedSchedule = this.state.schedule.filter(schedule => schedule.status === 'planned' && schedule.id !== scheduleToUpdate.id)
    const bookedSchedule = this.state.schedule.filter(schedule => ['booked', 'delayed'].includes(schedule.status) && schedule.id !== scheduleToUpdate.id)
    let slots = []
    plannedSchedule.map(schedule => {
      const scheduledStart = getDateTimeFromUTC(schedule.raw.slot.start)
      const scheduledEnd = getDateTimeFromUTC(schedule.raw.slot.end)
      if(scheduledStart.date === updatedStart.date && scheduledStart.date === updatedEnd.date) {
        if((scheduledStart.time <= moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') < scheduledEnd.time)  ||
        (scheduledStart.time < moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') <= scheduledEnd.time ) ||
        (scheduledStart.time > moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') > scheduledStart.time) ||
        (scheduledEnd.time > moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') && moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') > scheduledEnd.time))
          slots.push({
            start: scheduledStart,
            end: scheduledEnd
          })
      }
    })
    bookedSchedule.map(schedule => {
      const scheduledStart = getDateTimeFromUTC(schedule.raw.slot.start)
      const scheduledEnd = getDateTimeFromUTC(schedule.raw.slot.end)
      if(scheduledStart.date === updatedStart.date && scheduledStart.date === updatedEnd.date) {
      if((scheduledStart.time <= moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') < scheduledEnd.time) ||
      (scheduledStart.time < moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') <= scheduledEnd.time ) ||
      (scheduledStart.time > moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') &&  moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') > scheduledStart.time) ||
        (scheduledEnd.time > moment(updatedStart.time, 'h:mm A').format('HH:mm:ss') && moment(updatedEnd.time, 'h:mm A').format('HH:mm:ss') > scheduledEnd.time))
          slots.push({
            start: scheduledStart,
            end: scheduledEnd
          })
      }
    })
    return slots
  }

  getUpdatedSlotTime = (time) => {
    let updatedDate = time.date
    let timeArray = time.time.split(':')
    let timeObj = new Date()
    timeObj.setHours(timeArray[0])
    timeObj.setMinutes(timeArray[1])
    timeObj.setSeconds(timeArray[2])
    if (this.state.moveSlotDirection === 'Forwards') {
      if (this.state.moveSlotTimeAmount.days)
        updatedDate = moment(updatedDate).clone().add(this.state.moveSlotTimeAmount.days, 'days').format('YYYY-MM-DD')
      if (this.state.moveSlotTimeAmount.hours) {
        const updatedHour = moment(timeObj, 'HH:mm:ss').clone().add(this.state.moveSlotTimeAmount.hours, 'hours').format('HH:mm:ss')
        timeObj.setHours(moment(updatedHour, 'HH:mm:ss').hour())
      }
      if (this.state.moveSlotTimeAmount.minutes) {
        const updatedMinutes = moment(timeObj, 'HH:mm:ss').clone().add(this.state.moveSlotTimeAmount.minutes, 'minutes').format('HH:mm:ss')
        timeObj.setHours(moment(updatedMinutes, 'HH:mm:ss').hour())
        timeObj.setMinutes(moment(updatedMinutes, 'HH:mm:ss').minute())
      }
      if (time.time > moment(timeObj).format('HH:mm:ss') )
        updatedDate = moment(updatedDate).clone().add(1, 'day').format('YYYY-MM-DD')
    } else {
      if(this.state.moveSlotTimeAmount.days)
        updatedDate =  moment(updatedDate).clone().subtract(this.state.moveSlotTimeAmount.days, 'days').format('YYYY-MM-DD')
      if(this.state.moveSlotTimeAmount.hours) {
        const updatedHour = moment(timeObj, 'HH:mm:ss').clone().subtract(this.state.moveSlotTimeAmount.hours, 'hours').format('HH:mm:ss')
        timeObj.setHours(moment(updatedHour, 'HH:mm:ss').hour())
      }
      if(this.state.moveSlotTimeAmount.minutes) {
        const updatedMinutes = moment(timeObj, 'HH:mm:ss').clone().subtract(this.state.moveSlotTimeAmount.minutes, 'minutes').format('HH:mm:ss')
        timeObj.setHours(moment(updatedMinutes, 'HH:mm:ss').hour())
        timeObj.setMinutes(moment(updatedMinutes, 'HH:mm:ss').minute())
      }
      if (time.time < moment(timeObj).format('HH:mm:ss'))
        updatedDate = moment(updatedDate).clone().subtract(1, 'day').format('YYYY-MM-DD')
    }
    return {
      date: moment(updatedDate).format('YYYY-MM-DD'),
      time: moment(timeObj, 'HH:mm:ss').format('h:mm A')
    }
  }

  handleSubmitMultiSlotMove = () => {
    const selectedPlannedOrBookedSlots = [...this.state.selectedPlannedSlots, ...this.state.selectedBookedSlots.filter(slot => !this.state.selectedPlannedSlots.includes(slot))]
    const payload = selectedPlannedOrBookedSlots.map(schedule => {
      const updatedStart = this.getUpdatedSlotTime(getDateTimeFromUTC(schedule.raw.slot.start))
      const updatedEnd = this.getUpdatedSlotTime(getDateTimeFromUTC(schedule.raw.slot.end))
      schedule.start = new Date(moment(updatedStart.date + ' ' + updatedStart.time).format('YYYY-MM-DD HH:mm:ss')).toISOString()
      schedule.raw.slot.start = new Date(moment(updatedStart.date + ' ' + updatedStart.time).format('YYYY-MM-DD HH:mm:ss')).toISOString()
      schedule.end = new Date(moment(updatedEnd.date + ' ' + updatedEnd.time).format('YYYY-MM-DD HH:mm:ss')).toISOString()
      schedule.raw.slot.end = new Date(moment(updatedEnd.date + ' ' + updatedEnd.time).format('YYYY-MM-DD HH:mm:ss')).toISOString()
      return this.slotDataForUpdate(schedule)
    })
    this.props.dispatch(bulkUpdateSlot(selectedPlannedOrBookedSlots[0].raw.slot.siteId, payload, response => {
      this.props.dispatch(forceStopLoader());
      const slotIds = keys(response) || []
      if(slotIds.length === selectedPlannedOrBookedSlots.length) {
        const statuses = uniq(map(values(response), 'status')) || []
        if(statuses.length === 1 && statuses[0] === 200) {
          alertifyjs.success('Successfully Updated!')
          this.getScheduleData()
        }
        else {
          alertifyjs.error('An Error Occurred!')
        }
      } else {
        alertifyjs.error('An Error Occurred!')
      }
    }))
    this.setState({showConfirmMoveSlotDialog: false, moveSlotDirection: null, moveSlotTimeAmount: [], selectedBookedSlots: [], selectedPlannedSlots: [], actionButton: true, actionButtonOptions:false, multiSlots: false, cancelMultiSlots: false, bookMultiSlots: false, moveMultiSlots:false})
  }

  handleConfirmMultiSlotMove = () => {
    let directionError = this.state.moveSlotDirection === null ? 'This field can not be blank' : ''
    let timeAmountError = ''
    let nonZeroOrNullValues = filter(this.state.moveSlotTimeAmount, val => val !== null && val !== 0)
    if (this.state.moveSlotTimeAmount.length === 0 || nonZeroOrNullValues.length === 0)
      timeAmountError = 'Select the time amount to be moved'
    if (!isEmpty(directionError) || !isEmpty(timeAmountError)) {
      this.setState({directionError: directionError, timeAmountError: timeAmountError})
      return;
    }
    else
      this.setState({showMoveSlotDialog:false, showConfirmMoveSlotDialog: !this.state.showConfirmMoveSlotDialog, directionError: directionError, timeAmountError: timeAmountError})
  }

  closeConfirmMoveSlotDialog = () => this.setState({showConfirmMoveSlotDialog: false})

  closeMoveSlotDialog = () => this.setState({actionButton: false, showMoveSlotDialog: false})

  handleDaysChange = amount => {
    let error = isEmpty(this.state.moveSlotDirection) ? 'This field can not be blank' : '';
     if(amount === 3) {
      this.setState({
        moveSlotTimeAmount: {
          days: amount,
          hours: 0,
          minutes: 0
        }, directionError: error
      }, () => this.self.render(true))
    } else
      this.setState({
      moveSlotTimeAmount: {
        ...this.state.moveSlotTimeAmount,
        days: amount
      }, directionError: error
    })
  }

  handleHoursChange = hours => this.setState({
    moveSlotTimeAmount: {
      ...this.state.moveSlotTimeAmount,
      hours: hours
    }
  })

  handleMinutesChange = minutes => this.setState({
    moveSlotTimeAmount: {
      ...this.state.moveSlotTimeAmount,
      minutes: minutes
    }
  })

  onMultiSlotCancelClick = () => {
    const count = this.state.selectedBookedSlots.length
    this.setState({actionButton: false})
    alertifyjs.prompt(
      `Cancel Booking for ${count} Slots?`,
      `This action will cancel ${count} selected bookings and void all related movements. Please give a reason for the cancellation:`,
      '',
      (event, value) => {
        const el = document.querySelector('input.ajs-input')
        if(value) {
          if(el)
            el.style.border = '1px solid black'
          this.cancelMultiBookedSlots(value)
        } else {
          if(el)
            el.style.border = '1px solid red'
          return false
        }
      },
      () => {}
    )
  }

  cancelMultiBookedSlots = reason => {
    const payload = map(this.state.selectedBookedSlots, slot => {
      let _slot = slot.raw.slot
      return {
        status: 'planned',
        id: _slot.id,
        siteId: _slot.siteId,
        freightProviderId: null,
        subFreightProviderId: null,
        truckId: null,
        driverId: null,
        updatedAt: _slot.updatedAt,
        settingsUpdatedAt: _slot.settingsUpdatedAt,
        cancellationReason: reason
      }
    })
    this.props.dispatch(isLoading('loaderDom'));
    this.props.dispatch(bulkUpdateSlot(this.state.selectedBookedSlots[0].raw.slot.siteId, payload, response => {
      this.props.dispatch(forceStopLoader());
      const slotIds = keys(response) || []
      if(slotIds.length === this.state.selectedBookedSlots.length) {
        const statuses = uniq(map(values(response), 'status')) || []
        if(statuses.length === 1 && statuses[0] === 200) {
          alertifyjs.success('Successfully Cancelled!')
          this.cancelMultiSlots()
          this.getScheduleData()
        }
        else {
          const reasons = '<li>' + compact(uniq(flatMap(response, "errors"))).join('</li><li>');
          alertifyjs.alert(
            'Permission Denied',
            `<div><p>Some slots have not been cancelled due to the following reason(s):
            </p><ul>${reasons}</ul></div>`,
            () => this.cancelMultiSlots()
          );
          this.getScheduleData()
        }
      } else {
        alertifyjs.error('An Error Occurred!')
        this.cancelMultiSlots()
      }
    }))
  }

  getSelectedSiteId() {
    return parseInt(get(this.state, 'selectedSite.id'));
  }

  setCurrentSiteTimezone() {
    const selectedSiteId = this.getSelectedSiteId();
    if(!selectedSiteId)
      return
    const tz = get(find(this.props.sites, {id: selectedSiteId}), 'timezone');
    if(tz)
      this.onTimezoneChange(tz);
  }

  getCurrentSiteSettings() {
    const selectedSiteId = this.getSelectedSiteId()
    if(!selectedSiteId)
      return
    return find(this.props.sites, {id: selectedSiteId})?.settings || this.props.settings
  }

  onChangeSite(selectedSite) {
    this.setState({selectedSite: selectedSite, selectedOrderIds: undefined}, () => {
      this.fetchSelectedSiteMessage(true);
      this.setCurrentSiteTimezone();
      this.getScheduleData();
      this.updateSettingsState(this.getCurrentSiteSettings())
    });
  }

  onChangeCheckpoint = (value, callback) => {
    if(value !== this.state.checkpoint) {
      this.setCache({checkpoint: value})
      this.setState({checkpoint: value}, () => {
        this.refreshSchedule()
        if(callback)
          callback()
      })
    }
  }

  isInCurrentDateRange(date) {
    const { startDate, endDate } = this.state;
    if(startDate && endDate && date)
      return moment(date).isBetween(moment(startDate.toDate()), moment(endDate.toDate()));

    return false;
  }

  onDateChange = date => {
    if(!this.self)
      return;

    this.self.setDate(new Date(date));
    if(this.isInCurrentDateRange(date))
      return;

    this.setState({showHistory: false}, () => {
      this.setCurrentViewDateRanges();
      this.fetchSlotsForUpdateView();
    });
  }

  onViewRangeChange(event, view) {
    event.preventDefault();

    if(this.self) {
      if(view === 'today')
        this.self.today();
      else if(view === 'prev')
        this.self.prev();
      else if(view === 'next')
        this.self.next();

      this.setState({showHistory: false}, () => {
        this.setCurrentViewDateRanges();
        this.fetchSlotsForUpdateView();
      });
    }
  }

  fetchSlotsForUpdateView() {
    let selectedSiteId = this.getSelectedSiteId();
    if(this.self && selectedSiteId) {
      this.props.startLoader('UpdateScheduleBoundary');
      this.props.setLoadingText('Loading slots...');
      this.getScheduleData();
    }
  }

  setCurrentViewDateRanges() {
    this.setCurrentViewStartDate();
    this.setCurrentViewEndDate();
  }

  setCurrentViewStartDate() {
    const date = this.getCurrentViewDateBoundary('renderStartDate');
    if(!isEqual(date, this.state.startDate))
      this.setState({startDate: date});
  }

  setCurrentViewEndDate() {
    const date = this.getCurrentViewDateBoundary('renderEndDate');

    if(!isEqual(date, this.state.endDate))
      this.setState({endDate: date});
  }

  getCurrentViewDateBoundary(startOrEnd) {
    if(this.self) {
      if(startOrEnd === 'renderStartDate')
        return this.self.getDateRangeStart();
      else if(startOrEnd === 'renderEndDate')
        return this.self.getDateRangeEnd();
    }
  }

  getCurrentTimezoneOffset() {
    let currentTimezone = this.currentTimezone();
    if(currentTimezone)
      return currentTimezone.utcoffsetSeconds / 60;

    const date = new Date();
    return -1 * date.getTimezoneOffset();
  }

  getCurrentTimezoneDisplayName() {
    let currentTimezone = this.currentTimezone();
    if(currentTimezone)
      return currentTimezone.displayName;

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if(tz)
      return last(tz.split('/'));
  }

  getCurrentTimezoneAbbr() {
    let currentTimezone = this.currentTimezone();
    if(currentTimezone)
      return currentTimezone.abbreviation;

    return moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
  }

  currentTimezone() {
    return find(this.state.timezones, {isCurrent: true});
  }

  isEqToCurrentTimezone(tz) {
    return this.isEqTimezones(this.currentTimezone(), tz);
  }

  isEqToSelectedTimezone(tz) {
    return this.isEqTimezones(this.state.selectedTimezone, tz);
  }

  isEqTimezones(timeZone1, timeZone2) {
    return get(timeZone1, 'utcoffsetSeconds') === get(timeZone2, 'utcoffsetSeconds');
  }

  onTimezoneChange(selectedTimezone) {
    if(this.isEqToSelectedTimezone(selectedTimezone))
      return;

    const isEqToCurrentTimezone = this.isEqToCurrentTimezone(selectedTimezone);

    this.setState({
      selectedTimezone: isEqToCurrentTimezone ? null : selectedTimezone
    }, () => {
      if(!this.self)
        return;
      const currentTimezoneOptions = this.self.getOptions().timezone.zones[0];
      const timezoneOffset = selectedTimezone.utcoffsetSeconds/60;
      if(isEqToCurrentTimezone && !isEmpty(currentTimezoneOptions))
        this.self.setOptions({timezones: compact([currentTimezoneOptions])});
      else
        this.self.setOptions({
          timezone: {
            zones: compact([
              currentTimezoneOptions,
              {
                timezoneName: selectedTimezone.location,
                timezoneOffset: timezoneOffset,
                tooltip: selectedTimezone.displayName,
                displayLabel: selectedTimezone.abbreviation
              }
            ])}
        });
    });
  }
  updateCalendarLabel(option) {
    const calendarViewIndex = this.state.actions.findIndex(action => action.id == 'calendar_view')
    const updatedActions = [...this.state.actions];
    updatedActions[calendarViewIndex].label = `Calendar View (${option.name})`;
    this.setState({ actions: updatedActions });
  }

  onCalendarViewChange(option, controls) {
    if(!this.self)
      return;
    this.setState({selectedView: option, changeViewButton: false})
    this.updateCalendarLabel(option)
    if(controls)
      this.setState({selectedControls: controls})
    const options = cloneDeep(this.self.getOptions());
    let viewName = get(option, 'id');
     if(includes(['month', '2Weeks', '3Weeks'], viewName)) {
       viewName = 'month';
       options.month.visibleWeeksCount = get(option, 'weeks');
     }

    const workWeek = Boolean(find(controls, {id: 'hideWeekends'}));
    const narrowerThanWeekdays = Boolean(find(controls, {id: 'narrowerThanWeekdays'}));
    const startOnSunday = Boolean(find(controls, {id: 'startWeekOnSunday'}));

    options.month.workweek = workWeek;
    options.week.workweek = workWeek;
    options.month.narrowWeekend = narrowerThanWeekdays;
    options.week.narrowWeekend = narrowerThanWeekdays;
    options.month.startDayOfWeek = startOnSunday ? 0 : 1;
    options.week.startDayOfWeek = startOnSunday ? 0 : 1;

    this.self.setOptions(options, true);
    this.self.changeView(viewName, true);

    this.setState({showHistory: false}, () => {
      this.setCurrentViewDateRanges();
      this.fetchSlotsForUpdateView();
    });
  }

  getRenderRangeText() {
    if(!this.self)
      return '';
    var options = this.self.getOptions();
    var viewName = this.self.getViewName();

    var range = [];
    if (viewName === 'day') {
      range.push(moment(this.self.getDate().getTime()).format(this.state.countryFormats.dateDisplay));
    }
     else if (
      viewName === 'month' &&
        (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)
    ) {
      range.push(moment(this.self.getDate().getTime()).format('MMM YYYY'));
    }
    else {
      range.push(moment(this.self.getDateRangeStart().getTime()).format('DD'));
      range.push(' - ');
      range.push(moment(this.self.getDateRangeEnd().getTime()).format(this.state.countryFormats.dateDisplay));
    }

    return range.join('');
  }

  getStatusInfoFromSettings(status) {
    return find(get(this.state.settings, 'statuses'), {id: status});
  }

  getSlotColor(slot) {
    const status = slot.status || get(slot, 'raw.slot.status');
    const statusInfo = this.getStatusInfoFromSettings(status);
    if (status === 'planned')
      return this.props.siteBooking ? SLOT_PLANNED : get(statusInfo, 'color', SLOT_PLANNED);
    if (status === 'booked')
      return this.props.siteBooking ? SLOT_BOOKED : get(statusInfo, 'color', SLOT_BOOKED);
    if (status === 'in_progress' || status === 'inProgress')
      return this.props.siteBooking ? SLOT_IN_PROGRESS : get(statusInfo, 'color', SLOT_IN_PROGRESS);
    if (status === 'delayed')
      return this.props.siteBooking ? SLOT_DELAYED : get(statusInfo, 'color', SLOT_DELAYED);
    if (status === 'completed')
      return this.props.siteBooking ? SLOT_COMPLETED : get(statusInfo, 'color', SLOT_COMPLETED);
    if (status === 'cancelled')
      return this.props.siteBooking ? SLOT_CANCELLED : get(statusInfo, 'color', SLOT_CANCELLED);
    if (status === 'restricted')
      return this.props.siteBooking ? SLOT_RESTRICTED : get(statusInfo, 'color', SLOT_RESTRICTED);

    return NEUTRAL_GRAY;
  }

  getAllSlotColors() {
    return values(this.getStatusColors());
  }

  getStatusColors() {
    if(this.state.settings && !this.props.siteBooking)
      return extend(
        ...map(
          this.state.settings.statuses,
          status => { return {[camelCase(status.id)]: status.color}; }
        )
      );
    else
      return {
        planned: SLOT_PLANNED,
        booked: SLOT_BOOKED,
        inProgress: SLOT_IN_PROGRESS,
        completed: SLOT_COMPLETED,
        delayed: SLOT_DELAYED,
        cancelled: SLOT_CANCELLED,
        rejected: BLACK,
        restricted: SLOT_RESTRICTED,
      };
  }

  getSlotBorderColor(slot, neutral=true) {
    if(get(this.state, 'settings.leftBorderColorByPits'))
      return this.getSlotBorderColorByPits(slot);

    return this.getSlotBorderColorByLoadType(slot, neutral);
  }

  getSlotBorderColorByLoadType(slot, neutral=true) {
    if(slot) {
      if (slot.type === 'inload')
        return INLOAD_COLOR;
      if(slot.type === 'outload')
        return OUTLOAD_COLOR;
    }

    if (neutral)
      return NEUTRAL_GRAY;
  }

  getSlotBorderColorByPits(slot) {
    if(slot) {
      const pits = (get(slot, 'pits', '') || '').toLowerCase();
      if (pits === 'road')
        return INLOAD_COLOR;
      if (pits === 'rail')
        return OUTLOAD_COLOR;
      if (pits === 'bulk')
        return PINK_NEON;
      if(pits === 'liquid')
        return GREEN_NEON;
      if(pits === 'additive')
        return YELLOW_NEON;
      if(pits === 'flour')
        return PINK_NEON;
      if (pits === 'animal_nutrition')
        return GREEN_NEON;
      if (pits === 'meal')
        return YELLOW_NEON;
    }
  }

  getTonnageTitlePart(slot) {
    const title = this.getFieldTitlePart(slot, 'tonnage');

    if(title)
      return title + ' ' + this.state.unit;
    else
      return '';
  }

  getCommodityTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'commodity.displayName');
  }

  getGradeTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'parentGradeName') || this.getFieldTitlePart(slot, 'grade.name');
  }

  getSeasonTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'season');
  }

  getSiteOrderTitlePart(slot) {
    let val;
    if(slot?.deliveryOrderNumber && get(this.state.siteOrdersMap, slot.deliveryOrderNumber.toLowerCase())) {
      const orders = this.state.siteOrdersMap[slot.deliveryOrderNumber.toLowerCase()]
      val = slot.type =='outload' ? orders?.pickupSiteOrderNumber : orders?.deliverySiteOrderNumber
    }
    return val || slot?.deliveryOrderNumber || ''
  }

  getTruckTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'truck.rego');
  }

  getFreightProviderTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'freightProvider.name');
  }

  getTruckOwnerTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'truck.companyName');
  }

  getFreightMovementNumberTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'movementIdentifier');
  }

  getOrderNumberTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'deliveryOrderNumber');
  }

  getCommodityContractNumberTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'commodityContractNumber');
  }

  getDriverTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'driver.mobile');
  }

  getCustomerTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'customer');
  }

  getBookingNumberTitlePart(slot) {
    return this.getFieldTitlePart(slot, 'bookingNumber');
  }

  getTypeTitlePart(slot) {
    const type = this.getFieldTitlePart(slot, 'type');
    if(type)
      return startCase(type);
    else
      return '';
  }

  getPriorityTitlePart(slot) {
    if(this.getFieldTitlePart(slot, 'priority'))
      return 'Priority';
    else
      return '';
  }

  getPitsTitlePart(slot) {
    return startCase(this.getFieldTitlePart(slot, 'pits') || '');
  }

  getStartDateTimeTitlePart = (slot, showAbbreviation) => this.getStartTitlePart(slot.start, 'D/M/YYYY h:mm a', showAbbreviation);

  getStartTimeTitlePart(slot) {
    return this.getStartTitlePart(slot?.start, 'h:mm a');
  }

  getStartTitlePart(start, format, showAbbreviation=true) {
    const mStart = moment(start);
    const { selectedTimezone } = this.state;
    if(selectedTimezone) {
      const offset = selectedTimezone.utcoffsetSeconds/60 - mStart.utcOffset();
      let formattedTime = mStart.add(offset, 'minutes').format(format);
      if (showAbbreviation)
        return `${formattedTime} (${selectedTimezone.abbreviation})`;
      else
        return formattedTime;
    }

    return mStart.format(format);
  }

  getFieldTitlePart(slot, field) {
    const value = get(slot, field);
    if(value)
      return value;
    else
      return null;
  }

  getTooltip(slot) {
    return this.getTitle(slot, 'tooltipOrder', true);
  }

  getName(slot, format) {
    const mStart = moment(slot.start);
    const mEnd = moment(slot.end);
    const { selectedTimezone } = this.state;
    if(selectedTimezone) {
      const offset = selectedTimezone.utcoffsetSeconds/60 - mStart.utcOffset();
      return `${mStart.add(offset, 'minutes').format('h:mm a')} - ${mEnd.add(offset, 'minutes').format('h:mm a')} (${selectedTimezone.abbreviation})`;
    }
    return `${mStart.format(format)} - ${mEnd.format(format)}`;
  }

  getTitle(slot, lookupField='titleOrder', refresh=false, tempStartDate=null) {
    if(!refresh && !isEmpty(slot.title))
      return slot.title;

    const onRoute = get(slot, 'onRoute');
    const onRouteLabel = '[On Route] ';

    if(get(this.state.settings, lookupField) && isArray(this.state.settings[lookupField])) {
      let title = [];

      this.state.settings[lookupField].forEach(field => {
        if(field === 'start')
          title.push(tempStartDate ? this.getStartTitlePart(tempStartDate.toDate(), 'hh:mm a') : this.getStartTimeTitlePart(slot));
        if(field === 'commodity')
          title.push(this.getCommodityTitlePart(slot));
        if(field === 'bookingNumber')
          title.push(this.getBookingNumberTitlePart(slot));
        if(field === 'tonnage' && !slot?.isTrailerSlot)
          title.push(this.getTonnageTitlePart(slot));
        if(field === 'freightProvider')
          title.push(this.getFreightProviderTitlePart(slot));
        if(field === 'truckOwner')
          title.push(this.getTruckOwnerTitlePart(slot));
        if(field === 'truck')
          title.push(this.getTruckTitlePart(slot));
        if(field === 'driver')
          title.push(this.getDriverTitlePart(slot));
        if(field === 'grade')
          title.push(this.getGradeTitlePart(slot));
        if(field === 'type')
          title.push(this.getTypeTitlePart(slot));
        if(field === 'customer')
          title.push(this.getCustomerTitlePart(slot));
        if(field === 'pits')
          title.push(this.getPitsTitlePart(slot));
        if(field === 'priority')
          title.push(this.getPriorityTitlePart(slot));
        if(field === 'movementIdentifier')
          title.push(this.getFreightMovementNumberTitlePart(slot));
        if(field === 'deliveryOrderNumber')
          title.push(this.getOrderNumberTitlePart(slot));
        if(field === 'commodityContractNumber')
          title.push(this.getCommodityContractNumberTitlePart(slot));
        if(field === 'season')
          title.push(this.getSeasonTitlePart(slot));
        if(field === 'siteOrder')
          title.push(this.getSiteOrderTitlePart(slot));
      });

      if(isSystemCompany())
        title = [`[${slot.id}]`, ...title];

      if(slot.status === 'restricted')
        title.push(slot.restrictionReason);

      let titleLabel = compact(title).join(' - ');
      if (slot?.isTrailerSlot) {
        var parentSlot = find(this.props.slots, {id: slot.parentSlotId});
        titleLabel = `Trailer Slot - ${titleLabel} <br> Parent Slot - ${this.getStartTimeTitlePart(parentSlot)}`;
      }
      if(onRoute)
        titleLabel = onRouteLabel + titleLabel;

      return titleLabel;
    }

    return compact([tempStartDate ? this.getStartTitlePart(tempStartDate.toDate(), 'hh:mm a') : this.getStartTimeTitlePart(slot),
                    this.getCommodityTitlePart(slot),
                    this.getBookingNumberTitlePart(slot),
                    this.getTonnageTitlePart(slot),
                    this.getFreightProviderTitlePart(slot),
                    this.getTruckTitlePart(slot),
                    this.getDriverTitlePart(slot)]).join(' - ');
  }

  getTitleHTML(slot, tooltip=false, tempStartDate=null) {
    const iconColor = tooltip ? BLACK : WHITE;
    let title = tooltip ? this.getTooltip(slot) : this.getTitle(slot, 'titleOrder', false, tempStartDate);
    let icon;
    const isRestricted = slot.status === 'restricted';

    if(isRestricted && !tooltip)
      icon = restrictedIcon(iconColor);

    if(slot.commentsCount > 0 && !tooltip)
      icon = commentIcon(iconColor);

    const titleHTML = `<span class="title" style="position:relative;top:-3px;">${title}</span>`;
    const badge = isRestricted ? '' : `<span class="slot-comments-badge" style="font-size: 9px;">${slot.commentsCount}</span>`;

    if(icon) {
      return `<div style="display:inline-block;margin-right:3px;">${icon}${badge}</div>${titleHTML}`;
    } else {
      return titleHTML;
    }
  }

  constructSchedule(slots) {
    return isArray(slots) ? map(slots, this.constructSlot) : [];
  }

  constructSlot(_slot) {
    const color = this.getSlotColor(_slot);
    const site = find(this.props.sites, {id: _slot.siteId});
    const slot = {};
    slot.status = _slot.status;
    slot.id = (_slot.id || '').toString();
    slot.calendarId = (_slot.siteId || '').toString();
    slot.title = this.getTitle(_slot);
    slot.name = this.getName(_slot, 'hh:mm a');
    slot.body = "";
    slot.isReadOnly = false;
    slot.isPrivate = false;
    slot.isAllDay = false;
    slot.category = 'time';
    slot.dueDateClass = '';
    slot.color = WHITE;
    slot.backgroundColor = color;
    slot.dragBackgroundColor = color;
    slot.borderColor = this.getSlotBorderColor(_slot, false) || 'none';
    slot.customStyle = {borderTop: '0.1px solid #FFF'};
    slot.isFocused = false;
    slot.isPending = false;
    slot.isVisible = true;
    slot.recurrenceRule = '';
    slot.raw = {
      slot: _slot,
      site: site,
      class: "public",
    };
    slot.location = get(site, 'address.address');
    slot.attendees = [];
    slot.goingDuration = 0;
    slot.comingDuration = 0;

    slot.start = new Date(_slot.start);
    slot.end = new Date(_slot.end);

    return slot;
  }

  getSitesForNewSlot() {
    if(!isEmpty(this.props.sites))
      return filter(
        this.props.sites,
        site => parseInt(this.getSelectedSiteId()) === parseInt(site.id)
      );
  }

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  shouldShowTonnageDistribution() {
    return this.self && (
      isSystemCompany() || this.isSiteManagerView() || isObserver()
    );
  }

  shouldShowOrderDistribution() {
    return this.isOrderBooking();
  }

  isOrderBooking() {
    return get(this.state, 'settings.orderBooking');
  }

  isTrailerBooking() {
    return get(this.state, 'settings.trailerBooking');
  }

  isMultiSlotBookingSettingOn() {
    return get(this.props.currentUser, 'company.multiSlotBooking')
  }

  isSiteSelected() {
    if(!this.props.siteBooking && isEmpty(this.getSitesForNewSlot()))
      return Boolean(alertifyjs.warning('Please select one of the sites from left panel.', 3));
  }

  onSiteMessageButtonClick() {
    if(!this.isSiteSelected())
      this.setState({openSiteMessageSideDrawer: !this.state.openSiteMessageSideDrawer});
  }

  shouldShowSiteMessagesButton() {
    return Boolean(this.props.siteBooking && this.getSelectedSiteId());
  }

  hasSiteMessage() {
    return Boolean(this.state.siteMessage);
  }

  pollForSiteMessage() {
    this.fetchSiteMessagePoll = setInterval(this.fetchSelectedSiteMessage, 60 * 60 * 1000);
    this._intervals.push(this.fetchSiteMessagePoll)
  }

  fetchSelectedSiteMessage(show){
    if(this.shouldShowSiteMessagesButton()) {
      const selectedSiteId = this.getSelectedSiteId();
      APIService.farms(selectedSiteId).appendToUrl('messages/')
        .get()
        .then(
          messages => this.setState({
            siteMessage: join(compact(map(messages, 'message')), '\n')
          }, () => {
            if(show)
              this.showSiteMessage();
          })
        );
    }
  }

  showSiteMessage = () => {
    if(this.state.siteMessage)
      alertifyjs.alert(`Message from ${this.props.company.name}`, '<div style="height: 65vh;overflow: auto">' + this.state.siteMessage + '</div>', () => {});
  };

  onOrdersSelectChange = orderIds => this.setState({selectedOrderIds: orderIds}, this.refreshSchedule);

  getTimezoneOffsetMinutesToAdd(date) {
    const { selectedTimezone } = this.state;
    if(date && selectedTimezone) {
      const currentTimezoneOffset = date.utcOffset();
      const selectedTimezoneOffset = selectedTimezone.utcoffsetSeconds/60;
      if(currentTimezoneOffset !== selectedTimezoneOffset)
        return selectedTimezoneOffset - currentTimezoneOffset;
    }
  }

  initialMDate(slot, attr) {
    return moment(
      (get(slot, `${attr}`) || get(slot, `event.${attr}`) || get(slot, `event.${attr}`)).toDate()
    );
  }


  getSlotDisplay = slot => {
    const start = this.initialMDate(slot, 'start');
    let end = this.initialMDate(slot, 'end');
    if (start.toString() === end.toString() && !this.props.siteBooking) end.add(30, 'minutes');
    const timezoneOffset = this.getTimezoneOffsetMinutesToAdd(start);
    if(timezoneOffset) {
      start.add(timezoneOffset, 'minutes');
      end.add(timezoneOffset, 'minutes');
    }
    return `${start.format(this.state.countryFormats.date)} - ${start.format('hh:mm')}-${end.format('hh:mm')}`
  }

  handleViewButtonClose = () => this.setState({changeViewButton: false, actionButtonOptions: !this.state.actionButtonOptions})

  handleToggleDailyGrade = value => this.setState({toggleDailyGrade: value})

  handleClick = (event, action) => {
    this.setState({multiSlots: false, changeViewButton: false})
    switch(action.id) {
      case 'new_slot':
        this.showNewSlotDialog(event, true)
        break;
      case 'broadcast_message':
        this.onSiteMessageButtonClick()
        break;
      case 'calendar_view':
        this.setState({changeViewButton: !this.state.changeViewButton})
        break;
      case 'daily_grade':
        if(!this.isSiteSelected())
          this.setState({toggleDailyGrade: !this.state.toggleDailyGrade})
        break;
      case 'multi_slots':
        this.toggleMultiSlots()
        break;
      default:
          return;
    }
    if(!includes(['calendar_view', 'multi_slots'], action.id))
      this.setState({ actionButtonOptions: !this.state.actionButtonOptions})
  }

  render() {
    const hasPlannedSlots = this.hasPlannedSlots()
    const hasBookedSlots = this.hasBookedSlots()
    const showCSVButton = this.canExportCSV();
    const selectedSiteId = this.getSelectedSiteId();
    const defaultSelectedSiteId = this.getDefaultSelectedSiteId();
    const dateRangeText = this.getRenderRangeText();
    const rightDivStyles = get(this.props, 'rightDivStyles', {});
    const showTonnageDistribution = this.shouldShowTonnageDistribution();
    const showOrderDistribution = this.shouldShowOrderDistribution();
    const hasSiteMessage = this.hasSiteMessage();
    const isMultiSlotsMode = this.state.bookMultiSlots || this.state.cancelMultiSlots || this.state.moveMultiSlots
    const selectedPlannedOrBookedSlots = [...this.state.selectedPlannedSlots, ...this.state.selectedBookedSlots.filter(slot => !this.state.selectedPlannedSlots.includes(slot))]
    const { actionButton, actionButtonOptions, actions } = this.state;
    return (
      <div>
        <div id="right" className={showTonnageDistribution ? 'right-sm': ''} style={rightDivStyles}>
          {
            showTonnageDistribution &&
              <TonnageDistribution
                schedule={this.state.schedule}
                settings={this.state.settings}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
          }
          {
            this.self &&
              <div id="menu" style={{marginLeft: '5px', marginRight: '10px'}}>
                <span className='col-xs-8' style={{padding: '0px', display: 'flex', alignItems: 'center'}}>
                  {
                    this.self &&
                      <CalendarList
                        calendars={isArray(this.state.calendars) ? this.state.calendars : []}
                        onChange={this.onChangeSite}
                        defaultLabel='Select Site'
                        defaultCalendarId={defaultSelectedSiteId}
                        disabled={isMultiSlotsMode}
                      />
                  }
                  {
                    this.self &&
                      <CheckpointFilter
                        onChange={this.onChangeCheckpoint}
                        value={this.state.checkpoint}
                      />
                  }
                  {
                    isArray(this.state.timezones) &&
                      !isEmpty(this.state.timezones) &&
                      <Timezones
                        timezones={this.state.timezones}
                        onChange={this.onTimezoneChange}
                        updatedTz={this.state.selectedTimezone}
                      />
                  }
                  <span id="menu-navi" style={{display: 'flex', alignItems: 'center'}}>
                    {
                      this.state.settings &&
                        <SlotStats
                          slots={this.state.schedule}
                          handleStatClick={this.handleStatClick}
                          statusColors={this.getStatusColors()}
                          settings={this.state.settings}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          disabled={isMultiSlotsMode}
                        />
                    }
                    {
                      this.self &&
                        <CalendarDateSelector
                          defaultDate={moment(this.self.getDate().getTime()).format('YYYY-MM-DD')}
                          rangeText={dateRangeText}
                          onChange={this.onDateChange}
                          disabled={isMultiSlotsMode}
                        />
                    }
                    <IconButton
                      variant="outlined"
                      color="secondary"
                      style={{margin: '0 1px', padding: '3px', border: `1px solid ${ACCENT_COLOR_BLUE}`}}
                      onClick={(event) => this.onViewRangeChange(event, 'prev') }
                      disabled={isMultiSlotsMode}
                      size="large">
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      color="secondary"
                      style={{margin: '0 1px', padding: '3px', border: `1px solid ${ACCENT_COLOR_BLUE}`}}
                      onClick={(event) => this.onViewRangeChange(event, 'next') }
                      disabled={isMultiSlotsMode}
                      size="large">
                      <KeyboardArrowRight />
                    </IconButton>
                    {
                      Boolean(selectedSiteId && get(this.state.settings, 'activityLog') && !this.props.siteBooking) &&
                        <Tooltip title='Activity Log for the current view'>
                          <IconButton
                            size='small'
                            variant="outlined"
                            color={this.state.showHistory ? "primary" : "secondary"}
                            style={{margin: '0 1px', padding: '3px', border: `1px solid ${ACCENT_COLOR_BLUE}`}}
                            onClick={this.handleHistoryIconClick}>
                            <ActivityLogIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                    }
                    {
                      showOrderDistribution && this.state.selectedSite &&
                        <OrderDistribution
                          title={`Orders (${dateRangeText})`}
                          site={this.state.selectedSite}
                          start={this.getStartForSlotsCall()}
                          end={this.getEndForSlotsCall()}
                          viewStartDate={this.state.startDate}
                          viewEndDate={this.state.endDate}
                          onSelectChange={this.onOrdersSelectChange}
                        />
                    }
                  </span>
                  {
                    this.state.toggleDailyGrade &&
                      <GradeDistribution
                        slots={this.state.schedule}
                        start={this.self.getDateRangeStart().toDate()}
                        end={this.self.getDateRangeEnd().toDate()}
                        open={this.state.toggleDailyGrade}
                        toggleDailyGrade={this.handleToggleDailyGrade}
                      />
                  }
                </span>
                <span  className='col-xs-4 controls-right' style={{padding: '0px', textAlign: 'right', display: 'flex', alignItems: 'center', paddingRight: '10px', justifyContent: 'right'}}>
                  {
                    isMultiSlotsMode && (!this.state.selectedPlannedSlots.length && !this.state.selectedBookedSlots.length) &&
                      <Chip id="cancel-multi-slots" label={`Cancel`} variant='contained' color='error' style={{margin: '0 1px'}} onClick={this.cancelMultiSlots}/>
                  }
                  {
                    isMultiSlotsMode && (hasPlannedSlots || hasBookedSlots) && (this.state.selectedPlannedSlots.length > 0 || this.state.selectedBookedSlots.length > 0) &&
                    <React.Fragment>
                    {
                      this.state.bookMultiSlots ?
                          <Chip id="book-multi-slots" label={`Book (${this.state.selectedPlannedSlots.length})`} variant='contained' color='primary' style={{margin: '0 1px'}} onClick={this.onMultiSlotBookClick} deleteIcon={<CancelIcon fontSize='inherit' />} onDelete={this.cancelMultiSlots}/> :
                          (
                            this.state.cancelMultiSlots ?
                            <Chip id="cancel-multi-slots" label={`Cancel Booking (${this.state.selectedBookedSlots.length})`} variant='contained' color='error' style={{margin: '0 1px'}} onClick={this.onMultiSlotCancelClick} deleteIcon={<CancelIcon fontSize='inherit' />} onDelete={this.cancelMultiSlots}/> :
                            <Chip id="move-multi-slots" label={`Move (${this.state.selectedPlannedSlots.length})`} variant='contained' color='primary' style={{margin: '0 1px'}} onClick={this.onMultiSlotMoveClick} deleteIcon={<CancelIcon fontSize='inherit' />} onDelete={this.cancelMultiSlots}/>
                          )
                    }
                    </React.Fragment>
                  }
                  { actionButton &&
                    <span>
                      <Tooltip arrow placement='bottom' title='Actions'>
                        <Chip
                          id="action-options"
                          color='primary'
                          label='Actions'
                          variant='contained'
                          onClick={this.setActionButton}
                          style={{fontSize:'inherit', marginRight: '3px',order: 1}}
                          icon={<MoreVertIcon fontSize='small' style={{order:2, margin: '0 4px 0 -12px'}}/>}
                        />
                      </Tooltip>
                      <Menu anchorEl={actionButtonOptions} open={Boolean(actionButtonOptions)} onClose={this.closeActionButtonOptions} style={{ width: '260px', maxHeight: '100%' }} >
                      {
                        map(actions, (action, index) => (
                          <div key={index}>
                           {index !== 0 && <Divider style={{margin: '0px'}}/>}
                          <Tooltip arrow placement='left' title={action.description}>
                            <MenuItem
                              style={{fontSize: '14px', padding: '12px 15px',flexDirection: 'column'}}
                              key={ action }
                              onClick={ (event) => this.handleClick(event, action) }>
                              <div style={{width: '200px', display: 'flex', alignItems: 'center'}}>
                                <div style={{ marginLeft: '1px',width: '88%'}}>
                                {`${action.label}`}
                                </div>
                                {
                                  action.toggle && (
                                    <span style={{margin: '-8px 0 -8px 95px'}}>
                                    { this.state.toggleDailyGrade ?
                                     <ToggleOnIcon color='primary' fontSize='large' /> : <ToggleOffIcon color='default' fontSize='large' />
                                    }
                                    </span>
                                  )
                                }
                                {
                                  isEqual(action.id, 'calendar_view') &&
                                  <span style={{ display: 'inline-block', width: '12%' }}>
                                    {this.state.changeViewButton ? <ExpandLess /> : <ExpandMore />}
                                  </span>
                                }
                                {
                                  isEqual(action.id, 'multi_slots') && (
                                  <span  style={{ display: 'inline-block', width: '12%' }}>
                                    {this.state.multiSlots ? <ExpandLess /> : <ExpandMore />}
                                  </span>
                                  )
                                }
                              </div>
                              {this.state.multiSlots && isEqual(action.id, 'multi_slots') && (
                                 <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Divider style={{ width: '230px', marginTop: '6px'}}/>
                                    <div style={{ position: 'relative', zIndex: '1' }}>
                                      <MenuItem onClick={this.toggleMultiSlotsBooking} selected={this.state.bookMultiSlots} style={{ fontSize: '14px', padding: '13px 0 13px 28px', zIndex: '0', marginBottom: '-2px'}}>
                                        Book Multiple Slots
                                      </MenuItem>
                                    </div>
                                    <Divider style={{ width: '230px'}}/>
                                    <div style={{ position: 'relative', zIndex: '1' }}>
                                      <MenuItem onClick={this.toggleMultiSlotsCancel} selected={this.state.cancelMultiSlots} style={{ fontSize: '14px', padding: '13px 0 13px 28px', zIndex: '0', marginBottom: '-2px' }}>
                                        Cancel Multiple Bookings
                                      </MenuItem>
                                    </div>
                                   {
                                     !this.props.siteBooking &&
                                       <React.Fragment>
                                         <Divider style={{ width: '230px'}}/>
                                         <div style={{ position: 'relative', zIndex: '1' }}>
                                           <MenuItem onClick={this.toggleMultiSlotsMove} selected={this.state.moveMultiSlots} style={{ fontSize: '14px', padding: '13px 0 13px 28px', zIndex: '0', marginBottom: '-12px' }}>
                                             Move Multiple Slots
                                           </MenuItem>
                                         </div>
                                         </React.Fragment>
                                   }
                                  </div>
                                )}
                            </MenuItem>
                          </Tooltip>
                          </div>
                        ))
                        }
                        {
                          this.state.changeViewButton &&
                          <CalendarViewOptions
                            onSelect={this.onCalendarViewChange}
                            defaultView={get(this.state.settings, 'defaultView', 'week')}
                            disabled={isMultiSlotsMode}
                            open={this.state.changeViewButton}
                            close={this.handleViewButtonClose}
                            selectedView={this.state.selectedView}
                            selectedControls={this.state.selectedControls}
                          />
                        }
                      </Menu>
                    </span>
                  }
                  {
                    !this.props.siteBooking && showCSVButton && this.self &&
                    <SlotsCSV
                      siteIds={compact([selectedSiteId])}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      sites={filter(this.props.sites, site => site?.isActive)}
                      siteBooking={this.props.siteBooking}
                    />
                  }
                  {
                    this.shouldShowSiteMessagesButton() &&
                      <React.Fragment>
                        {
                          this.props.siteBooking && showCSVButton && this.self &&
                            <SlotsCSV
                              siteIds={compact([selectedSiteId])}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              sites={filter(this.props.sites, site => site?.isActive)}
                              siteBooking={this.props.siteBooking}
                            />
                        }
                        <Tooltip title="Site Messages" placement='top'>
                          <span>
                            <IconButton
                              color="primary"
                              disabled={!hasSiteMessage}
                              onClick={this.showSiteMessage}
                              size="large">
                              {
                                hasSiteMessage ?
                                  <Badge badgeContent={1} color='primary'>
                                    <RssFeed />
                                  </Badge> :
                                <RssFeed />
                              }
                            </IconButton>
                          </span>
                        </Tooltip>
                      </React.Fragment>
                  }
                </span>
              </div>
          }
          <div id='calendar' />
        </div>
        {
          this.state.showHistory &&
            <BulkHistory
              timezone={this.state.selectedTimezone}
              siteId={selectedSiteId}
              slotIds={map(this.state.schedule, 'id')}
              show={this.state.showHistory}
              onClose={this.handleHistoryIconClick}
              schedule={this.state.schedule}
              getTitle={this.getStartDateTimeTitlePart}
              onClick={slot => this.showNewSlotDialog(slot, false)}
            />
        }
        {
          this.state.showMoveSlotDialog &&
          <MoveSlotsDialog
            closeMoveSlotDialog={this.closeMoveSlotDialog}
            open={this.state.showMoveSlotDialog}
            moveSlotDirection={this.state.moveSlotDirection}
            moveSlotTimeAmount={this.state.moveSlotTimeAmount}
            handleConfirmMultiSlotMove={this.handleConfirmMultiSlotMove}
            handleSlotDirection={this.handleSlotDirection}
            handleDaysChange={this.handleDaysChange}
            handleHoursChange={this.handleHoursChange}
            handleMinutesChange={this.handleMinutesChange}
            selectedPlannedOrBookedSlots={selectedPlannedOrBookedSlots}
            getUpdatedSlotTime={this.getUpdatedSlotTime}
            timeAmountError={this.state.timeAmountError}
            directionError={this.state.directionError}
          />
        }
        {
          this.state.showConfirmMoveSlotDialog &&
          <ConfirmMoveSlotsDialog
            closeConfirmMoveSlotDialog={this.closeConfirmMoveSlotDialog}
            selectedPlannedOrBookedSlots={selectedPlannedOrBookedSlots}
            getUpdatedSlotTime={this.getUpdatedSlotTime}
            handleSubmitMultiSlotMove={this.handleSubmitMultiSlotMove}
            getSlotsOverlapped={this.getSlotsOverlapped}
          />
        }
        {
          this.state.showNewSlotDialog && this.state.settings &&
            <FreightSlotForm
              companyId={this.companyId}
              slot={isMultiSlotsMode ? null : this.state.currentSlot}
              multiSlots={this.state.selectedPlannedSlots}
              showDialog={this.state.showNewSlotDialog}
              handleClose={this.handleNewSlotDialogClose}
              sites={this.getSitesForNewSlot()}
              siteBooking={this.props.siteBooking}
              isNew={this.state.isNewSlot}
              statusColors={this.getStatusColors()}
              settings={this.state.settings}
              selectedTimezone={this.state.selectedTimezone}
              bookieTrucks={this.props.bookieTrucks}
              bookieDrivers={this.props.bookieDrivers}
              companyDirectory={this.props.companyDirectory}
              trailerBookingSlots={this.getTrailerBookingEligibleSlots()}
              existingTrailerSlotIds={this.getExistingTrailerSlots()}
              parentSlot={this.getParentSlot()}
              onMultiSlotsClose={this.state.bookMultiSlots ? this.onMultiSlotsBookResponse : null}
              isDuplicate={this.state.isDuplicate}
              startDate={this.getStartForSlotsCall()}
              endDate={this.getEndForSlotsCall()}
              siteOrdersMap={this.state.siteOrdersMap}
              allCompanies={this.state.allCompanies}
            />
        }
        <Menu
          id="slot-right-click-menu"
          anchorEl={this.state.slotTarget}
          open={Boolean(this.state.slotTarget)}
          onClose={this.closeRightClickMenu}>
          {
            this.props.siteBooking &&
              <MenuItem onClick={this.stopSlotFlash}>Stop Flash</MenuItem>
          }
          {
            !this.props.siteBooking &&
              <MenuItem onClick={this.showUpdateSlotDialog}>View/Edit</MenuItem>
          }
          {
            !this.props.siteBooking && !get(this.state.selectedSchedule, 'raw.slot.isTrailerSlot') && !get(this.state.selectedSchedule, 'raw.slot.counterSlotId') &&
              <MenuItem onClick={this.duplicate}>Duplicate</MenuItem>
          }

        </Menu>
        {
          this.state.openSiteMessageSideDrawer &&
            <SiteMessageSideDrawer
              open={this.state.openSiteMessageSideDrawer}
              onClose={this.onSiteMessageButtonClick}
              sites={filter(this.props.sites, site => site?.isActive)}
              selectedSiteId={selectedSiteId}
            />
        }
        {
          Boolean(this.state.multiSlotResponse) &&
            <Dialog open onClose={this.closeMultiSlotsResponseDialog}>
              <DialogTitleWithCloseIcon onClose={this.closeMultiSlotsResponseDialog}>
                Partial Failure
              </DialogTitleWithCloseIcon>
              <DialogContent>
                <div style={{marginBottom: '10px'}}>We were not able to book some slots, below are the details</div>
                {
                  map(this.state.multiSlotResponse, (info, id) => {
                    const slot = find(this.state.selectedPlannedSlots, {id: id})
                    return (
                      <li key={id}>
                        <span><i>{this.getSlotDisplay(slot)}</i></span>
                        {
                          info.status === 200 ?
                            <span>
                              <span style={{margin: '0 5px'}}>-</span>
                              <span style={{color: PRIMARY_COLOR_GREEN}}>Booked</span>
                            </span> :
                          <span>
                            <span style={{margin: '0 5px'}}>-</span>
                            <span style={{color: TOMATO_RED}}>Failed</span>
                            <span style={{margin: '0 5px'}}>-</span>
                            <span>{values(info.errors).join(', ')}</span>
                          </span>
                        }
                      </li>
                    )
                  })
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeMultiSlotsResponseDialog}>Close</Button>
              </DialogActions>
            </Dialog>
        }
      </div>
    );
  }

}
const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
    currentUserCompanyId: state.main.user.user.companyId,
    sites: state.companies.companySites.items || [],
    slots: state.companies.companySites.slots,
    waitForComponent: state.main.waitForComponent,
    isLoading: state.main.isLoading,
    settingButton: state.main.settingButton,
    settings: state.companies.companySites.settings,
    token: state.main.user.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanySites: (companyId, callback) => dispatch(getCompanySites(companyId, receiveCompanySites, callback, '?properties=timezone,settings')),
    getAllSlots: (companyId, start, end, siteId, stopLoader) => dispatch(getAllSlots(companyId, start, end, siteId, stopLoader)),
    getOpenOrSelfSlots: (companyId, siteCompanyId, start, end, siteId, stopLoader) => dispatch(getOpenOrSelfSlots(companyId, siteCompanyId, start, end, siteId, stopLoader)),
    getCompany:(companyId) => dispatch(getCompanyDetails(companyId)),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    updateSlot: (id, slot) => dispatch(updateSlot(id, slot)),
    forceStopLoader: () => dispatch(forceStopLoader()),
    startLoader: (dom) => dispatch(isLoading(dom)),
    createSlot: (siteId, data, callback) => dispatch(createSlots(siteId, data, callback)),
    getCompanyCompaniesMinimalisticWithAssets: (companyId) => dispatch(getCompanyCompaniesMinimalisticWithAssets(companyId)),
    setSettingsButton: (component) => dispatch(setSettingButton(component)),
    getSMSettings: (companyId) => dispatch(getSMSettings(companyId)),
    resetCompanySites: () => dispatch(receiveCompanySites([])),
    setLoadingText: text => dispatch(setLoadingText(text)),
    resetSlots: () => dispatch(receiveFreightSlots([])),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteManagement);
