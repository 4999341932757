import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ScaleIcon from '@mui/icons-material/Scale';
import { EMPTY_VALUE, PRIMARY_COLOR_GREEN } from '../../../common/constants';
import moment from 'moment';
import Tooltip from '../../../common/Tooltip';
import { getGradeName, getCountryFormats, replaceUnit, getCountryConfig, getCountryLabel, toPhoneFormat } from '../../../common/utils';
import { get, map, isEmpty } from "lodash";
import {IconButton, Tooltip as MUITooltip} from '@mui/material';
import UnitConversions from '../../common/UnitConversions';
import UpdateLocation from '../../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Create from '@mui/icons-material/Create';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (
      <KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />
    );
  }
  return (
    <KeyboardArrowDown
      style={{ fill: '#112c42', height: '20px', width: '20px' }}
    />
  );
};

class ContractDetailsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      countryFormats: getCountryFormats(),
      countryConfig: getCountryConfig(),
      conversion: false,
      openConsignorFarmForm: false,
      openConsigneeFarmForm: false,
      openFormForFarm: undefined,
    };
  }

  toggleExpanded = () => this.setState(prevState => ({ expanded: !prevState.expanded}));

  getContractDetailsDataAsPerGrid = (dataLists, title) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(Object.keys(dataLists), (key, index) => (
              <li key={index}>
                <Tooltip
                  className="field-label ellipses"
                  tooltipText={key}
                  textContent={key}
                />
                <Tooltip
                  className="field-value ellipses"
                  valueStyle={{textWrap: (key == 'Payment Term' && 'wrap')}}
                  tooltipText={dataLists[key] || EMPTY_VALUE}
                  textContent={dataLists[key] || EMPTY_VALUE}
                  prefix={
                    this.state.countryConfig?.showConversions && key.toLowerCase() === 'quantity' &&
                      <MUITooltip title='View in other units' arrow>
                        <span style={{marginLeft: '10px'}}>
                          <IconButton size='small' color='primary' onClick={() => this.setState({conversion: !this.state.conversion})}>
                            <ScaleIcon fontSize='small' />
                          </IconButton>
                        </span>
                      </MUITooltip>
                  }
                />
              </li>
            ))
          }
        </ul>
      </div>
    );
  };

  getDeliveryDetails = contract => {
    let basicDetails = {
      'Delivery Onus': contract.deliveryOnus,
      'Delivery Start Date': moment(contract.deliveryStartDate).format(this.state.countryFormats.date),
    };
    return (
      <ul>
        {
          map(Object.keys(basicDetails), (key, index) => (
            <li key={index}>
              <Tooltip
                className="field-label ellipses"
                tooltipText={key}
                textContent={key}
              />
              <Tooltip
                className="field-value ellipses"
                tooltipText={basicDetails[key] || EMPTY_VALUE}
                textContent={basicDetails[key] || EMPTY_VALUE}
              />
            </li>
          ))
        }
        {this.getConsignorsDetails(contract)}
        {this.getConsigneesDetails(contract)}
        <li>
          <Tooltip
            className="field-label ellipses"
            tooltipText='Delivery End Date'
            textContent='Delivery End Date'
          />
          <Tooltip
            className="field-value ellipses"
            tooltipText={moment(contract.deliveryEndDate).format(this.state.countryFormats.date) || EMPTY_VALUE}
            textContent={moment(contract.deliveryEndDate).format(this.state.countryFormats.date) || EMPTY_VALUE}
          />
        </li>
      </ul>
    );
  };

  getSpreadDetailsSection = (spreadDetails, title, currency) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(spreadDetails, (grade, index) => (
              <li key={index} style={grade.gist ? {width: '100%'} : {}}>
                {
                !grade.gist &&
                    <Tooltip
                      className="field-label ellipses"
                      tooltipText={grade.name}
                      textContent={grade.name}
                    />
                }
                {
                  grade.gist ?
                    <span style={{width: '100%'}}>{grade.gist}</span> :
                <Tooltip
                  className="field-value ellipses"
                  tooltipText={grade.name}
                  textContent={grade.value === 0 ? `${currency} ${grade.value}` : (grade.value ? `${grade.priceVariation}${currency} ${grade.value}` : '-')}
                />
                }
              </li>
            ))
          }
        </ul>
      </div>
    );
  };

  getChemicalApplicationsSection = (applications, title) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(applications, application => {
              return map(Object.keys(application), (key, index) => (
                <li key={index}>
                  <Tooltip
                    className="field-label ellipses"
                    tooltipText={key}
                    textContent={key}
                  />
                  <Tooltip
                    className="field-value ellipses"
                    tooltipText={application[key] || EMPTY_VALUE}
                    textContent={application[key] || EMPTY_VALUE}
                  />
                </li>
              ))
            })
          }
        </ul>
      </div>
    );
  };

  getConsigneesDetails(contract){
    const consignees = contract.consigneesWithSites;
    if(isEmpty(consignees)) {
      let consigneeDetails = {
        'Delivery Site': null,
      };
      return Object.keys(consigneeDetails).map(function(key, index) {
        return (
          <li key={index}>
            <Tooltip
              className="field-label ellipses"
              tooltipText={key}
              textContent={key}
            />
            {key === 'Delivery Site' ?
             <Tooltip
               className="field-value"
               tooltipText={consigneeDetails[key] || EMPTY_VALUE}
               textContent={consigneeDetails[key] || EMPTY_VALUE}
             /> :
             <Tooltip
               className="field-value ellipses"
               tooltipText={consigneeDetails[key] || EMPTY_VALUE}
               textContent={consigneeDetails[key] || EMPTY_VALUE}
             />}
          </li>
        );
      });
    }
    return map(consignees, (consignee, index) => {
      const consigneeName = get(consignee,'handler.entity') == 'farm' ? get(consignee, 'handler.displayName') : get(consignee, 'handler.name');
      const consigneeMobile = get(consignee, 'handler.mobile');
      const consigneeNameWithLocation = <span>
        <>{consigneeName}</>
        <IconButton
          onClick={() => this.setState({openConsigneeFarmForm: true, openFormForFarm: get(consignee, 'handlerId')})}
          style={{paddingLeft: '0px'}}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      const consigneeKey = consignees.length > 1 ? `Delivery Site ${index + 1}`: 'Delivery Site';
      const consigneeMobileKey = consignees.length > 1 ? `Delivery Site ${index + 1} Phone No`: 'Delivery Site Phone No';
      let ld = get(consignee, 'ld');
      if (ld !== undefined || ld !== null) {
        ld = get(contract, 'siteLdLabel', 'LD')+': ' + ld;
      }
      let consigneeMobileWithIcon = '';
      if (consigneeMobile) {
        consigneeMobileWithIcon = <span>
          <>{toPhoneFormat(consigneeMobile)}</>
          <IconButton
            onClick={() => this.setState({openConsigneeFarmForm: true, openFormForFarm: get(consignee, 'handlerId')})}
            style={{paddingLeft: '0px'}}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
          </span>
      }
      return (
        <React.Fragment key={index}>
          <li style={ld ? { paddingBottom: '0px', lineHeight: 'initial' } : null}>
            <Tooltip
              className="field-label ellipses"
              tooltipText={consigneeKey}
              textContent={consigneeKey}
            />
            <Tooltip
              className="field-value"
              tooltipText={consigneeName || EMPTY_VALUE}
              textContent={consigneeNameWithLocation || consigneeName || EMPTY_VALUE}
            />
          </li>
          {ld && <li key={`${index}-ld`} style={{ paddingTop: '0px', color: 'gray' }}>
                   <Tooltip
                     className="field-label ellipses"
                     tooltipText={null}
                     textContent={null}
                   />
                   <Tooltip
                     className="field-value ellipses"
                     tooltipText={ld || EMPTY_VALUE}
                     textContent={ld || EMPTY_VALUE}
                     valueStyle={{ fontSize: 'smaller' }}
                   />
                 </li>}
          <li>
            <Tooltip
              className="field-label ellipses"
              tooltipText={consigneeMobileKey}
              textContent={consigneeMobileKey}
            />
            <Tooltip
              className="field-value"
              tooltipText={consigneeMobile || EMPTY_VALUE}
              textContent={consigneeMobileWithIcon || consigneeMobile || EMPTY_VALUE}
            />
          </li>
        </React.Fragment>
      );
    });
  }

  getConsignorsDetails(contract){
    const consignors = contract.consignorsWithSites;
    if(isEmpty(consignors)) {
      let consignorDetails = {
        'Pickup Site': null,
      };
      return Object.keys(consignorDetails).map(function(key, index) {
        return (
          <li key={index}>
            <Tooltip
              className="field-label ellipses"
              tooltipText={key}
              textContent={key}
            />
            {key === 'Pickup Site' ?
             <Tooltip
               className="field-value"
               tooltipText={consignorDetails[key] || EMPTY_VALUE}
               textContent={consignorDetails[key] || EMPTY_VALUE}
             /> :
             <Tooltip
               className="field-value ellipses"
               tooltipText={consignorDetails[key] || EMPTY_VALUE}
               textContent={consignorDetails[key] || EMPTY_VALUE}
             />}
          </li>
        );
      });
    }
    return map(consignors, (consignor, index) => {
      const consignorName = get(consignor,'handler.entity') == 'farm' ? get(consignor, 'handler.displayName') : get(consignor, 'handler.name');
      const consignorMobile = get(consignor, 'handler.mobile');
      const consignorKey = consignors.length > 1 ? 'Pickup Site' + (index + 1): 'Pickup Site';
      const consignorMobileKey = consignors.length > 1 ? `Pickup Site ${index + 1} Phone No`: 'Pickup Site Phone No';
      const consignorNameWithLocation = <span>
        <>{consignorName}</>
        <IconButton
          onClick={() => this.setState({openConsignorFarmForm: true, openFormForFarm: get(consignor, 'handlerId')})}
          style={{paddingLeft: '0px'}}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      let consignorMobileWithIcon = '';
      if (consignorMobile) {
        consignorMobileWithIcon = <span>
          <>{toPhoneFormat(consignorMobile)}</>
          <IconButton
            onClick={() => this.setState({openConsignorFarmForm: true, openFormForFarm: get(consignor, 'handlerId')})}
            style={{paddingLeft: '0px'}}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
          </span>
      }
      let ld = get(consignor, 'ld');
      if (ld !== undefined || ld !== null) {
        ld = get(contract,'consignorSiteLdLabel', 'LD')+': ' + ld;
      }
      return (
        <React.Fragment key={index}>
          <li style={ld ? { paddingBottom: '0px', lineHeight: 'initial' } : null}>
            <Tooltip
              className="field-label ellipses"
              tooltipText={consignorKey}
              textContent={consignorKey}
            />
            <Tooltip
              className="field-value"
              tooltipText={consignorName || EMPTY_VALUE}
              textContent={consignorNameWithLocation || consignorName || EMPTY_VALUE}
            />
          </li>
          {ld && <li key={`${index}-ld`} style={{ paddingTop: '0px', color: 'gray' }}>
            <Tooltip
              className="field-label ellipses"
              tooltipText={null}
              textContent={null}
            />
            <Tooltip
              className="field-value ellipses"
              tooltipText={ld || EMPTY_VALUE}
              textContent={ld || EMPTY_VALUE}
              valueStyle={{ fontSize: 'smaller' }}
            />
          </li>}
          <li>
            <Tooltip
              className="field-label ellipses"
              tooltipText={consignorMobileKey}
              textContent={consignorMobileKey}
            />
            <Tooltip
              className="field-value"
              tooltipText={consignorMobile || EMPTY_VALUE}
              textContent={consignorMobileWithIcon || consignorMobile || EMPTY_VALUE}
            />
          </li>
        </React.Fragment>
      );
    });
  }

  getSiteNames(sites){
    const locations = [];
    sites.forEach(site => {
      locations.push(site.location.name);
    });
    if(!isEmpty(locations)){
      return locations.join(', ');
    } else {
      return null;
    }
  }

  getChemicalApplicationDetails(applications) {
    let applicationDetails = [];
    map(applications, application => {
      applicationDetails.push({
        'Commodity': application.commodityName,
        'Price': application.priceDisplay,
        'Application Fee': application.mixingFeeDisplay
      })
    });
    return applicationDetails;
  }

  render() {
    const { contract } = this.props;
    const { expanded, countryConfig } = this.state;
    const fieldSizeUnit = countryConfig?.farmField?.sizeUnit
    const unit = contract?.requestedUnit || contract?.commodity?.tonnageUnit || contract?.commodity?.priceUnit
    const estimatedYieldUnit = `${this.props?.contract?.commodity?.priceUnit} per ${fieldSizeUnit}`
    let general = {
      'Contract Type': get(contract, 'type.displayName')
    };

    if (get(contract, 'internalReferenceNumber', '')) {
      general['Internal Reference No.'] = get(contract, 'internalReferenceNumber');
    }

    if(contract.quantity)
      general[contract.quantityLabel] = contract.quantity + ' ' + contract.quantityUnit;

    general[contract.tonnageLabel] = contract.tonnage + ' ' + unit;

    general['Commodity'] = get(contract, 'commodity.displayName');
    if (contract.sustainableCommodity)
      general['Commodity'] += ' (Sustainable)'
    general['Lot No.'] = get(contract, 'lotNumber', '');
    general['Variety'] = contract.varietyId ? contract.variety.name : null;
    general['Season'] = contract.season;

    if(contract.isAreaContract){
      const tonnageLabel = getCountryLabel('tonnage');
      general['Area'] = get(contract, 'area.area') ? get(contract, 'area.area') + ' ' + fieldSizeUnit : '';
      general['Estimated Yield'] = get(contract, 'area.estimatedYield') ? get(contract, 'area.estimatedYield') + ' ' + estimatedYieldUnit : '';
      general[`Estimated ${tonnageLabel}`] = get(contract, 'area.estimatedTonnage') ? get(contract, 'area.estimatedTonnage').toFixed(2) + ' ' + unit : '';
      general['Price By'] = get(contract, 'area.priceBy') ? moment(get(contract, 'area.priceBy')).format(this.state.countryFormats.date) : null;
    }

    if(contract.blendingFee)
      general[`Blending Fee (per ${unit})`] = `${contract?.currency} ${contract.blendingFee}`

    if(contract.isPoolContract){
      general[contract.gradeLabel] = contract.poolGrades;
    } else {
      general[contract.gradeLabel] = getGradeName(contract);
      let price = contract.price ? `${contract?.currency} ${contract.price}` : null;
      if (price && contract.isBlended && contract.useContractPriceForInvoicing)
        price = `${price} (Single Price)`
      general[contract.priceLabel] = price;
    }

    const generalDetails = {
      ...general,
      ...{'Price Point': get(contract, 'pricePoint.displayName'),
          'Track': contract.track,
          'Payment Scale': contract.paymentScale?.name,
          'Payment Term': contract.paymentTermDescription,
          'Tolerance': replaceUnit(contract.tolerance?.name, contract.commodity?.priceUnit),
          'Conveyance': contract.conveyanceId ? contract.conveyance.name : null,
          'Inspection': contract.inspection?.name,
          'Weight': contract.weight?.name,
          'Packaging': contract.packaging?.name,
          'Market Zone': contract.marketZoneId ? contract.marketZone.name : null,
          'Region': contract.regionId ? contract.region.name : null,
          'Contract Invoicing': get(contract,'administration.invoicing')}
    };

    let spreadDetails = null;
    if(!isEmpty(contract.spread)){
      spreadDetails = contract.spread.details;
    }

    let carryDetails = null;
    if(contract.carryRate){
      carryDetails = {
        'Carry Rate' : `${contract?.currency} ${contract.carryRate.toFixed(2)}`,
        'Frequency' : contract.carryFrequency,
        'Carry Start Date' : contract.carryStartDate ? moment(contract.carryStartDate).format(this.state.countryFormats.date) : null,
        'Carry End Date' : contract.carryEndDate ? moment(contract.carryEndDate).format(this.state.countryFormats.date) : null,
        'Current Carry Value' : contract.carryFrequency ? `${contract?.currency} ${parseFloat(contract.carryCurrent).toFixed(2)}` : null,
        'Max Carry Value' : contract.carryMax ? `${contract?.currency} ${parseFloat(contract.carryMax).toFixed(2)}` : null,
      };
    }

    let valuationDetails = {
      'Contract Value' : get(contract, 'contractValue') ? `${contract?.currency} ${parseFloat(get(contract, 'contractValue')).toFixed(2)}` : null,
      'Max Contract Value' : get(contract, 'contractMaxValue') ? `${contract?.currency} ${parseFloat(get(contract, 'contractMaxValue')).toFixed(2)}` : null,
      'Est Payment Due Date' : get(contract, 'estimatedPaymentDueDateDisplay'),
      'Current Valuation' : get(contract, 'contractCurrentValue') ? `${contract?.currency} ${parseFloat(get(contract, 'contractCurrentValue')).toFixed(2)}` : null,
      'EPR Value' : contract.eprValue ? `${contract?.currency} ${parseFloat(contract.eprValue).toFixed(2).toString()}` : null,
      'Est Central Levy' : contract.levy ? `${contract?.currency} ${parseFloat(contract.levy).toFixed(2).toString()}` : null,
    };

    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Contract Details
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {
          expanded &&
            <div className="section-details-container">
              <div>
                {this.getContractDetailsDataAsPerGrid(generalDetails, 'General')}
              </div>
              <div>
                <h4 className="section-title">Delivery Details</h4>
                {this.getDeliveryDetails(contract)}
                {
                  spreadDetails &&
                    <div className="section-details">
                      {this.getSpreadDetailsSection(spreadDetails, contract?.isBlended ? 'Grades' : getCountryLabel('spreadDetails'), contract?.currency)}
                    </div>
                }
                {
                  contract.chemicalApplications?.length > 0 &&
                    <div className="section-details">
                      {this.getChemicalApplicationsSection(this.getChemicalApplicationDetails(contract.chemicalApplications), 'Applications')}
                    </div>
                }
              </div>
              <div>
                {carryDetails && this.getContractDetailsDataAsPerGrid(carryDetails, 'Carry Details')}
                <div className="section-details">
                  {this.getContractDetailsDataAsPerGrid(valuationDetails,'Valuations')}
                </div>
              </div>
            </div>
        }
        {
          this.state.conversion &&
            <UnitConversions
              onClose={() => this.setState({conversion: false})}
              unitConversions={contract.unitConversions}
              preferredUnit={unit}
              commodityUnit={contract.commodity.unit}
            />
        }
        {this.state.openConsignorFarmForm &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={this.state.openFormForFarm} entity='farm' onCloseDrawer={() => this.setState({openConsignorFarmForm: false, openFormForFarm: undefined})}/>
        }
        {this.state.openConsigneeFarmForm &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={this.state.openFormForFarm} entity='farm' onCloseDrawer={() => this.setState({openConsigneeFarmForm: false, openFormForFarm: undefined})}/>
        }
      </Paper>
    );
  }
}

export default ContractDetailsSection;
