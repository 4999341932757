import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import {
  Paper, Button, Menu, MenuItem, ListItemIcon, ListItemText,
  Dialog, DialogContent, DialogTitle, DialogActions
} from '@mui/material';
import {
  Event as DueDateIcon,
  AssignmentTurnedIn as CreateIcon,
  MarkEmailRead as SendIcon,
  AttachEmail as MailIcon,
} from '@mui/icons-material';
import {
  get, isEqual, map, compact, isEmpty, reject, find, forEach,
  includes, filter, isString, some, uniq
} from 'lodash';
import APIService from '../../services/APIService';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader } from '../../actions/main';
import { getInvoicesWeb } from '../../actions/companies/invoices';
import WarehouseDashboardTable from '../../containers/WarehouseDashboardTable';
import MonthsSelector from '../common/MonthsSelector';
import { getWarehouseDashboardHeaders } from '../../common/constants';
import CommonDatePicker from '../common/CommonDatePicker';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import CommonWeekSelector from '../common/CommonWeekSelector';
import CommonListingButton from '../common/CommonListingButton';
import { defaultViewAction } from '../../common/utils';

const OPTIONS = {
  'create': {
    title: 'Create Invoices',
  },
  'createSend': {
    title: 'Create and Send Invoices',
  },
  'dueDate': {
    title: 'Update Payment Due Date',
  },
  'updateRecipients': {
    title: 'Update Recipients',
  }
}

class WarehouseDashboard extends React.Component {
  constructor(props) {
    super(props)
    const previousMonth = moment().subtract(1, 'month').format('YYYY-MM');

    this.state = {
      tenureOpen: false,
      months: [],
      previousMonth: previousMonth,
      selectedMonths: [previousMonth],
      selectedWeeks: [],
      selectedInvoices: [],
      openBulkActions: false,
      openDialog: false,
      selectedBulkOption: false,
      dueDate: '',
      payerContacts: [],
      selectedRecipients: []
    }
  }

  componentDidMount() {
    this.setHeaderAndBreadcrumbs()
    this.setSelectedMonths();
    this.setSelectedWeeks();
  }

  fetchInvoices = () => {
    const urlSearchParams = new URLSearchParams(window.location.hash.split('?')[1]);
    urlSearchParams.set('status', 'draft');
    urlSearchParams.set('type', 'warehousefee');

    if (this.isWeeklyWarehouseInvoiceFrequency()) {
      const selectedWeeks = this.state.selectedWeeks;
      if (isEmpty(selectedWeeks)) return;
      urlSearchParams.set('week_tenure', selectedWeeks.join(','));
    }
    else {
      const selectedMonths = this.state.selectedMonths;
      if (isEmpty(selectedMonths)) return;
      urlSearchParams.set('tenure', selectedMonths.join(','));
    }

    const query = '?' + urlSearchParams.toString();
    this.props.dispatch(isLoading());
    this.props.dispatch(getInvoicesWeb(null, null, query, true, true));
  }

  setMonths() {
    const { currentUser } = this.props
    if (currentUser.company.bulkInvoicingFrom) {
      const mStart = moment(currentUser.company.bulkInvoicingFrom)
      const today = moment()
      let months = []
      while (today > mStart || mStart.format('M') === today.format('M')) {
        months.push({value: mStart.format('YYYY-MM'), title: mStart.format('MMM YYYY')})
        mStart.add(1, 'month')
      }
      this.setState({months: months})
    }
  }

  setSelectedMonths() {
    let selectedMonths = [this.state.previousMonth]
    const hash = window.location.hash || ''
    const queryString = hash.split('?')[1]
    if(queryString) {
      const params = new URLSearchParams(queryString)
      const months = compact((params.get('months') || '').split(','))
      if(!isEmpty(months))
        selectedMonths = months
    }
    this.setState({selectedMonths: selectedMonths}, () => {
      this.setMonths()
      this.fetchInvoices()
    })
  }

  setSelectedWeeks() {
    let selectedWeeks = [];
    const hash = window.location.hash || ''
    const queryString = hash.split('?')[1]
    if(queryString) {
      const params = new URLSearchParams(queryString)
      const weeks = compact((params.get('weeks') || '').split(','))
      if(!isEmpty(weeks))
      selectedWeeks = weeks
    }
    this.setState({selectedWeeks: selectedWeeks}, () => {
      this.fetchInvoices()
    })
  }

  setHeaderAndBreadcrumbs() {
    const breadcrumbs = [{ text: 'Warehouse Dashboard' }];
    const headerText = 'Warehouse Dashboard';
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setHeaderText(headerText));
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setHeaderText(''))
  }

  onMonthChange = (event, months) => {
    const _fetch = !this.state.tenureOpen
    const newValues = map(months, 'value')
    if(!isEqual(newValues, this.state.selectedMonths)) {
      this.setState(
        {selectedMonths: map(months, 'value')},
        () => {
          if(_fetch)
            this.fetchInvoices()
          let URL = '/invoices/warehouse/dashboard'
          if(!isEmpty(this.state.selectedMonths))
            URL += `?months=${this.state.selectedMonths.join(',')}`
          this.props.history.replace(URL)
        }
      )
    }
  }

  handleWeekChange = (event, weeks) => {
    const _fetch = !this.state.tenureOpen
    const newValues = map(weeks, 'id')
    if(!isEqual(newValues, this.state.selectedWeeks)) {
      if (newValues.length > 5) {
        alertifyjs.error("Can only select 5 tenures maximum.");
        return;
      }
      this.setState(
        {selectedWeeks: map(weeks, 'id')},
        () => {
          if(_fetch)
            this.fetchInvoices()
          let URL = '/invoices/warehouse/dashboard'
          if(!isEmpty(this.state.selectedWeeks))
            URL += `?weeks=${this.state.selectedWeeks.join(',')}`
          this.props.history.replace(URL)
        }
      )
    }
  }

  updateSelectedInvoice = (selected, isChecked) => this.setState({selectedInvoices: isChecked ? [...this.state.selectedInvoices, selected] : reject(this.state.selectedInvoices, {id: selected.id})})

  isSelected = invoice => Boolean(invoice?.id && find(this.state.selectedInvoices, {id: invoice.id}))

  onSelectAllToggle = event => this.setState({selectedInvoices: event.target.checked ? this.props.invoices : []})

  toggleBulkActions = event => this.setState(
    {openBulkActions: this.state.openBulkActions ? false : event.currentTarget}
  )

  onBulkActionClick = event => {
    const option = event.currentTarget.id
    if(includes(['create', 'createSend'], option)) {
      if(some(this.state.selectedInvoices, invoice => (invoice.systemAmount - invoice.invoicedAmount) == 0)) {
        alertifyjs.alert('Operation Not Permitted', 'Some of the selected items are fully invoiced. You cannot re-create these. For this action, please select invoices that are not fully invoiced.')
        return
      }
      const currentCompany = get(this.props, 'currentUser.company');
      let xeroMissingContacts = []
      const checkXeroContactStatus = currentCompany.xeroEnabled;
      if (checkXeroContactStatus) {
        this.state.selectedInvoices.map(selectedInvoice => {
          if(selectedInvoice?.payerCompanyId && !selectedInvoice.xeroContactStatus)
            xeroMissingContacts.push(selectedInvoice.payerDisplayName)
        });
      }
      xeroMissingContacts = uniq(xeroMissingContacts);
      if(!isEmpty(xeroMissingContacts)) {
        const missingContactsText = '<li>' + xeroMissingContacts.join('</li><li>')
        const warningContent = `<div>Following counter parties does not exist in Xero as Contacts:</div><ul>${missingContactsText}</ul>`
        alertifyjs.confirm(
          'Warning',
          `<div>
              ${warningContent}
              <div>
                Do you want to continue to create Invoices ?
              </div>
          </div>`,
          () => this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: option}),
          () => { }
        );
        return;
      }
    }
    this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: option})
  }

  onPaymentDueDateChange = dueDate => {
    this.setState({dueDate: dueDate})
  }

  onDialogClose = () => this.setState({openDialog: false, selectedBulkOption: false})

  onSubmit = () => {
    const { dueDate, selectedBulkOption, selectedInvoices } = this.state
    if(selectedBulkOption === 'create')
      this.generateInvoices(true)
    else if(selectedBulkOption === 'createSend')
      this.generateInvoices(false)
    else if(selectedBulkOption === 'dueDate')
      APIService.invoices().appendToUrl('web/').put({invoice_ids: map(selectedInvoices, 'id'), payment_due_date: dueDate}).then(() => {
        this.fetchInvoices()
        this.onDialogClose()
      })
    if (selectedBulkOption === 'updateRecipients') {
      const invoice = selectedInvoices[0]
      APIService.invoices(invoice.id).appendToUrl('communication/').put({recipients: {'bill_to_party': this.getRecipientEmailsToSubmit()}}).then(() => {
        this.fetchInvoices()
        this.onDialogClose()
      })
    }
  }

  getRecipientEmailsToSubmit = () => {
    const { selectedRecipients } = this.state
    const result = []
    if(!isEmpty(selectedRecipients))
      forEach(selectedRecipients, recipient => result.push(isString(recipient) ? recipient : get(recipient, 'email')))
    return compact(result)
  }

  generateInvoices = noMail => {
    this.props.dispatch(isLoading('bulkGenerateWarehouseInvoices'))
    let count = 0
    const xeroEnabled = get(this.props, 'currentUser.company.xeroEnabled');
    let data = {noMail: noMail, isFromWarehouseDashboard: true}
    if (xeroEnabled)
      data['communication'] = {'createXeroInvoice': true};
    forEach(this.state.selectedInvoices, invoice => {
      APIService.invoices(invoice.id).appendToUrl('generate/').put(data).then(() => {
        count += 1
        if(count === this.state.selectedInvoices.length) {
          this.setState({selectedInvoices: []}, () => {
            this.fetchInvoices()
            this.onDialogClose()
            this.props.dispatch(forceStopLoader())
          })
        }
      })
    })
  }

  handleOptionClick = (event, option, invoice) => {
    let invoiceTenure = invoice.tenureRaw;
    if (includes(invoiceTenure, '/'))
      invoiceTenure = invoiceTenure.split('/').join('-');
    if(includes(['create', 'createSend', 'dueDate'], option.key))
      this.setState({selectedBulkOption: option.key, openDialog: true, selectedInvoices: [invoice]})
    else if (option.key === 'updateRecipients') {
      this.setState({selectedBulkOption: option.key, selectedInvoices: [invoice]}, () => {
        APIService
          .profiles()
          .appendToUrl('employees-signature/')
          .get(null, null, {company_ids: invoice.payerCompanyId})
          .then(employees => {
            const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({...employee, title: `${employee.name} (${employee.email})`, value: employee.email}))
            let selected = filter(contactsWithEmail, contact => includes(invoice.recipients.emails, contact.email))
            const selectedEmployeesEmails = map(selected, 'email')
            let customEmails = filter(invoice.recipients.emails, email => !includes(selectedEmployeesEmails, email))
            this.setState({payerContacts: contactsWithEmail, openDialog: true, selectedRecipients: compact([...selected, ...customEmails])})
          })

      })
    }
    else if (option.key === 'edit') {
      this.props.history.push(`/invoices/warehouse/${invoiceTenure}/payees/${invoice.payeeCompanyId}/payers/${invoice.payerCompanyId}/edit?ngrId=${invoice.payerNgrId}`)
    }
    else if(option.key === 'verifyLoads')
      this.props.history.push(`/invoices/warehouse/${invoiceTenure}/payees/${invoice.payeeCompanyId}/payers/${invoice.payerCompanyId}?ngrId=${invoice.payerNgrId}`)
    else if(option.key === 'invoices')
      this.props.history.push(`/invoices/warehouse/${invoiceTenure}/payees/${invoice.payeeCompanyId}/payers/${invoice.payerCompanyId}/active`)
  }

  onRecipientsChange = (event, selected) => {
    this.setState({selectedRecipients: selected})
  }

  isWeeklyWarehouseInvoiceFrequency = () => get(this.props.currentUser, 'company.warehouseInvoiceFrequency') === 'weekly';

  startOfWeek = () => get(this.props.currentUser, 'company.warehouseInvoiceStartOfWeek');

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }


  render() {
    const {
      selectedInvoices, months, selectedMonths, openBulkActions, openDialog, selectedBulkOption, dueDate,
      payerContacts, selectedRecipients, selectedWeeks
    } = this.state;
    const { invoices } = this.props;
    const selectedCount = selectedInvoices?.length || 0;
    const isIndeterminate = selectedCount > 0 && selectedCount < get(invoices, 'length');
    const allSelected = selectedCount && selectedCount === get(invoices, 'length')
    const today = moment()
    const isDueDateOption = selectedBulkOption === 'dueDate'
    const isCreateOption = selectedBulkOption === 'create'
    const isCreateAndSendOption = selectedBulkOption === 'createSend'
    const isUpdateRecipients = selectedBulkOption === 'updateRecipients'
    const isWeeklyWarehouseInvoiceFrequency = this.isWeeklyWarehouseInvoiceFrequency();
    const headers = getWarehouseDashboardHeaders(this.props.currentUser?.company);
    return (
      <Paper className="paper-table">
        <div>
          <Button
            variant="contained"
            type='button'
            color='primary'
            disabled={selectedCount === 0}
            onClick={this.toggleBulkActions}
            style={{ float: 'right', marginLeft: '10px' }}>
            Action Selected Invoices
          </Button>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
          <WarehouseDashboardTable
            headerControls={
              isWeeklyWarehouseInvoiceFrequency ?
              <CommonWeekSelector
                id='fields.tenure'
                className='months-selector-table-header'
                label='Weeks'
                weekStart={this.startOfWeek()}
                onChange={this.handleWeekChange}
                onClose={() => this.setState({tenureOpen: false}, this.fetchInvoices)}
                onOpen={() => this.setState({tenureOpen: true})}
                selected={selectedWeeks}
                numberOfWeeks={15}
                style={{marginBottom: '20px'}}
                multiple
              /> :
              <MonthsSelector
                className='months-selector-table-header'
                options={months}
                onChange={this.onMonthChange}
                onClose={() => this.setState({tenureOpen: false}, this.fetchInvoices)}
                selected={selectedMonths}
                onOpen={() => this.setState({tenureOpen: true})}
              />
            }
            columns={[
              { checkbox: true, className: 'xsmall', onChange: this.updateSelectedInvoice, func: this.isSelected, selectAll: true, indeterminate: isIndeterminate, checked: Boolean(allSelected), onSelectAll: this.onSelectAllToggle },
              ...headers
            ]}
            handleOptionClick={this.handleOptionClick}
          />
        </div>
        {
          Boolean(openBulkActions) &&
            <Menu
              id="actions-menu"
              anchorEl={openBulkActions}
              open
              onClose={this.toggleBulkActions}>
              <MenuItem onClick={this.onBulkActionClick} id='create'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <CreateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.onBulkActionClick} id='createSend'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Create & Send</ListItemText>
              </MenuItem>
              <MenuItem onClick={this.onBulkActionClick} id='dueDate'>
                <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                  <DueDateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Update Payment Due Date</ListItemText>
              </MenuItem>
            </Menu>
        }
        {
          openDialog &&
            <Dialog open keepMounted onClose={this.onDialogClose}>
              <DialogTitle>
                {OPTIONS[selectedBulkOption].title}
              </DialogTitle>
              <DialogContent>
                {
                  isDueDateOption &&
                    <CommonDatePicker
                      minDate={today.format('YYYY-MM-DD')}
                      label='Payment Due Date'
                      onChange={this.onPaymentDueDateChange}
                      value={dueDate}
                    />
                }
                {
                  isCreateOption &&
                    "Selected Invoice(s) will be created but emails will not be sent. Are you sure you want to proceed?"
                }
                {
                  isCreateAndSendOption &&
                    <React.Fragment>
                      <div>
                        {
                          `Selected Invoice(s) will be created and emails will be sent to ${selectedCount === 1 ? "following" : "corresponding"} recipients. Are you sure you want to proceed?`
                        }
                      </div>
                      {
                        selectedCount === 1 &&
                          <div style={{marginTop: '10px', marginLeft: '15px'}}>
                            {
                              map(selectedInvoices[0].recipients.emails, email => (
                                <div key={email} style={{display: 'flex', alignItems: 'center'}}>
                                  <MailIcon fontSize='inherit' style={{marginRight: '5px'}}/>
                                  {email}
                                </div>
                              ))
                            }
                          </div>
                      }
                      </React.Fragment>
                }
                {
                  isUpdateRecipients &&
                    <React.Fragment>
                      <div>
                        {`Update Recipients for ${get(selectedInvoices, '0.payerDisplayName')} | ${get(selectedInvoices, '0.payerNgr')}.`}
                      </div>
                      <div>
                        <EmailAutocomplete
                          options={payerContacts}
                          onChange={this.onRecipientsChange}
                          selected={selectedRecipients}
                        />

                      </div>
                    </React.Fragment>
                }
              </DialogContent>
              <DialogActions>
                <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                <Button disabled={isDueDateOption && !dueDate} onClick={this.onSubmit}>Proceed</Button>
              </DialogActions>
            </Dialog>
        }
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    invoices: state.companies.invoices.invoices,
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
    count: get(state.companies.invoices, 'paginationData.count') || get(get(state.companies.invoices, 'invoices', []), 'length') || 0,
  };
};

export default connect(mapStateToProps)(WarehouseDashboard);
