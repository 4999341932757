import React from 'react';
import { connect } from 'react-redux';

import CompaniesTable from '../../containers/CompaniesTable';
import AddButton from '../common/AddButton';
import APIService from '../../services/APIService';
import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs, isLoading } from '../../actions/main';
import SideDrawer from '../common/SideDrawer';
import CreateCompany from '../../containers/CreateCompany';
import { Button, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import RegisteredIcon from '@mui/icons-material/PrivacyTip';
import { getCompanyCompaniesWeb } from '../../actions/api/companies';
import {
  showPopUpDailyReports,
  isFromCompanyDirectory,
} from '../../actions/companies';
import alertifyjs from 'alertifyjs';
import { findIndex, find, get, isEqual } from 'lodash';
import { showSideDrawerForTransfer, updateCompany } from '../../actions/companies/index';
import DailyReports from './DailyReports';
import { setDownloadBar } from '../../actions/main';
import { attachCSVEventListener, defaultViewAction } from '../../common/utils';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { isSystemCompany, getCurrentCountry } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      companies: [],
      openSideDrawer: false,
      paginationData: null,
    };

    this.handleAddCompanyButtonClick = this.handleAddCompanyButtonClick.bind(this);
    this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
    this.fetchAndSetCompanies = this.fetchAndSetCompanies.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.fetchCSVData = this.fetchCSVData.bind(this);
    this.handleDefaultClick = this.handleDefaultClick.bind(this);
    this.isDefaultDirectoryCompany = this.isDefaultDirectoryCompany.bind(this);
  }

  _setBreadcrumbs() {
    const breadcrumbs = [{ text: `Companies (${this.props.count})` }];
    if(!isEqual(this.props.breadcrumbs, breadcrumbs))
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
  }

  onCloseDownloadResponse() {
    this.props.dispatch(setDownloadBar(false));
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('companies-csv-ready', 'Companies', this.onDownloadResponse);
  }

  componentDidMount() {
    this.props.dispatch(isLoading('genericTableWithData'));
    this.props.dispatch(setHeaderText('Companies'));
    this._setBreadcrumbs();
    this.props.dispatch(getCompanyCompaniesWeb(this.props.userCompanyId));
    this.props.dispatch(showSideDrawerForTransfer(false));
    this.props.dispatch(isFromCompanyDirectory(true))
    this._attachCSVEventListener();
  }

  componentDidUpdate() {
    this._setBreadcrumbs();
    if (!isEqual(this.props.companies, this.state.companies))
      this.setState({ companies: this.props.companies });
    this.props.dispatch(isFromCompanyDirectory(true))
  }

  handleReportClose = (company, recipientsList) =>{
    APIService.companies(company.id).appendToUrl('report-recipients/')
      .post({"report_recipients": recipientsList}, this.props.token)
      .then(() =>{
        alertifyjs.success("Created successfully");
      });
    this.props.dispatch(showPopUpDailyReports(company, false));
  };

  fetchAndSetCompanies() {
    this.props.dispatch(getCompanyCompaniesWeb(this.props.userCompanyId));
  }

  handleAddCompanyButtonClick() {
    this.setState({
      openSideDrawer: true,
    });
  }

  onCloseSideDraw() {
    this.setState({
      openSideDrawer: false,
    });
  }

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  fetchCSVData() {
    this.props.dispatch(setDownloadBar('Your Companies CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    const service = APIService.companies().appendToUrl('web/csv/');
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      });
  }

  handleDefaultClick(item, checked) {
    let newState = { ...this.state };
    const index = findIndex(newState.companies, { id: item.id });
    let previousCompany = find(newState.companies, { id: item.id });
    const payload = {
      defaultDirectoryCompany: checked || false
    };
    this.props.dispatch(updateCompany(previousCompany?.id, payload ));
    if(previousCompany?.isDefaultDirectoryCompany) {
      previousCompany.isDefaultDirectoryCompany = checked || false;
      newState.companies.splice(index, 1, previousCompany);
    }
    this.setState(newState);
  }

  isDefaultDirectoryCompany = item => {
    return item.isDefaultDirectoryCompany;
  }

  abnFormatter = company => {
    if(company.transactionParticipation)
      return <Tooltip title='Subscriber'>
               <span style={{display: 'flex', alignItems: 'center'}}>
               <span>{company.abn}</span>
                 <VerifiedIcon fontSize='small' color='primary' sx={{marginLeft: '5px'}}/>
                 </span>
             </Tooltip>
    if(company.isRegisteredText === 'Yes')
      return <Tooltip title='Registered (not subscriber)'>
               <span style={{display: 'flex', alignItems: 'center'}}>
                 <span>{company.abn}</span>
                 <RegisteredIcon fontSize='small' color='warning' sx={{marginLeft: '5px'}}/>
               </span>
             </Tooltip>
    return company.abn
  }

  getIcon = company => {
    if(company.transactionParticipation)
      return <Tooltip title='Subscriber'>
                 <VerifiedIcon fontSize='small' color='primary' sx={{marginTop: '5px'}}/>
             </Tooltip>
    if(company.isRegisteredText === 'Yes')
      return <Tooltip title='Registered (not subscriber)'>
               <RegisteredIcon fontSize='small' color='warning' sx={{marginTop: '5px'}}/>
             </Tooltip>
  }

  render() {
    const country = getCurrentCountry()
    const isAllCompanies = true
    let TABLE_COLUMNS = [
      { header: '', className: 'xxxsmall', sortable: false, formatter: this.getIcon },
      { key: 'name', header: 'Business Name', className: 'large' },
      { key: 'entityName', header: 'Entity Name', className: 'large' },
      { key: 'abn', header: country?.config?.abn?.label, className: 'small' },
      { key: 'businessType', header: 'Primary Business', className: 'xsmall' },
      { key: 'address', header: 'Address', className: 'large' },
      { key: 'mobile', header: 'Phone / Mobile', className: 'small' },
      { key: 'website', header: 'Website', className: 'medium' },
      { key: 'keyContactNameForUser', header: 'Key Contact', className: 'medium' }
    ];

    if(isSystemCompany())
      TABLE_COLUMNS = [
        { checkbox: true, className: 'xxsmall', onChange: 'handleDefaultClick', func: this.isDefaultDirectoryCompany },
        ...TABLE_COLUMNS
      ];

    return (
      <Paper className='paper-table-paginated'>
        <div>
          <div style={{float: 'right', marginRight: '10px'}}>
            <AddButton
              tooltipTitle='Add a new company to your directory'
              label='Company'
              onClick={this.handleAddCompanyButtonClick}
            />
          </div>
          <div style={{float: 'right', marginRight: '10px'}}>
          <Button type='button' variant='contained' onClick={this.fetchCSVData} title='Download Contents of the table in a CSV'>
            <CloudDownload style={{ paddingRight: '5px' }} />
            CSV
          </Button>
          </div>
          <div style={{float: 'right', marginRight: '10px'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
          <CompaniesTable columns={TABLE_COLUMNS} items={this.state.companies} handleDefaultClick={this.handleDefaultClick} orderBy='name' paginationData={this.props.paginationData} isAllCompanies={isAllCompanies} isGroups={!this.props.isFromCompanyDirectory}/>
        </div>

        <SideDrawer isOpen={this.state.openSideDrawer} title='Add Company' onClose={this.onCloseSideDraw}>
          <CreateCompany closeDrawer={this.onCloseSideDraw} fetchAndSetCompanies={this.fetchAndSetCompanies} />
        </SideDrawer>
        {
          this.props.dailyReports &&
          <DailyReports
            handleReports = {this.handleReportClose}
          />
        }
      </Paper>
    );
  }
}


const mapStateToProps = state => ({
  openSideDrawer: state.companies.companies.showTransferSideDrawer,
  dailyReports: state.companies.companies.showDailyReports,
  isFromCompanyDirectory: state.companies.companies.isFromCompanyDirectory,
  token: state.main.user.token,
  userCompanyId: state.main.user.user.companyId,
  companies: state.companies.companies.items,
  paginationData: state.companies.companies.paginationData,
  selectedSubcontractRequestId: state.companies.companies.selectedSubcontractRequestId,
  count: get(state.companies.companies, 'paginationData.count') || 0,
  breadcrumbs: state.main.breadcrumbs,
});

export default connect(mapStateToProps)(Companies);
