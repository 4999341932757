import React from 'react';

import get from 'lodash/get';
import { EMPTY_VALUE } from '../../../common/constants';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getCountryLabel, toBankAccountFormat, toBranchNumberFormat} from '../../../common/utils';

const InvoiceHowToPayNewView = props => {
	const payee = get(props,'invoiceDetails.payee');
  const bankDetails = get(payee, 'bankDetails');

	return (
    <Accordion defaultExpanded sx={{boxShadow: 'none', margin: '24px 0 !important'}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{fontWeight: 'bold', marginTop: '-16px !important'}}>
        How To Pay
      </AccordionSummary>
      <AccordionDetails style={{padding: '0 16px 8px 16px', marginTop: '-28px'}}>
        <h5 style={{fontWeight: '500', marginBottom: '8px'}}>Electronic Transfer</h5>
        <div className="table-container">
          <Table size='small' sx={{'.MuiTableCell-root': {padding: '4px 8px !important', fontSize: '13px'}, width: '100%'}}>
            <TableHead>
              <TableRow>
                <TableCell className="xxsmall" width="8%">NGR</TableCell>
                <TableCell className="xxsmall" width="10%">Shareholder %</TableCell>
                <TableCell className="small" width="7%">ABN</TableCell>
                <TableCell width="27%" align="center">Entity</TableCell>
                <TableCell width="18%" align="center">Bank Name</TableCell>
                <TableCell width="15%" align="center">Account Name</TableCell>
                <TableCell width="6%" align="center">{getCountryLabel('bsb')} Number</TableCell>
                <TableCell width="10%" align="center">Account No</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
							{
								bankDetails &&
									bankDetails.map(item => (
											<TableRow key={item.accountNumber}>
												<TableCell className="xxsmall" width="8%">
													{
														get(payee, 'ngrNumber', EMPTY_VALUE)
													}
												</TableCell>
												<TableCell align="center" width="10%">
													{
														(get(item, 'shareholderPercent') || '100') + '%'
													}
												</TableCell>
												<TableCell width="7%" >
													{
														get(item, 'abn') || get(payee, 'abn', EMPTY_VALUE)
													}
												</TableCell>
												<TableCell align="center" width="27%">
													{
														get(item, 'entityName') || get(payee, 'entityName', EMPTY_VALUE)
													}
												</TableCell>
												<TableCell align="center" width="18%">{get(item, 'name') || EMPTY_VALUE}</TableCell>
												<TableCell align="center" width="15%">{get(item, 'accountName') || EMPTY_VALUE}</TableCell>
												<TableCell align="center" width="3%">{toBranchNumberFormat(get(item, 'bsbNumber'))}</TableCell>
												<TableCell align="center" width="10%">{toBankAccountFormat(item)}</TableCell>
											</TableRow>
										))
							}
            </TableBody>
          </Table>
        </div>
      </AccordionDetails>
		</Accordion>
	);
}

export default InvoiceHowToPayNewView;
