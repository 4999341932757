import React from 'react';
import Divider from '@mui/material/Divider';
import SiteConnector from './SiteConnector';
import { find, isEqual, get } from 'lodash';
import moment from 'moment';

class TooltipTemplate extends React.Component {

    constructor(props) {
        super(props);
        let { type, orders, movement, order, actualRemainingTonnage } = props;
        if (isEqual(type, 'movement')) {
            order = find(orders, { id: movement.orderId });
        }
        this.state = {
            type: type,
            order: order,
            movement: movement,
				actualRemainingOrderTonnage: actualRemainingTonnage
        };
    }


    navigateToMovement = () => {
        window.open(`/#/freights/movements/${this.state.movement.id}/details`);
    }

    navigateToOrder = () => {
        window.open(`/#/freights/orders/${this.state.order.id}/order`);
    }
  navigateToContract = () => {
    window.open(`/#/contracts/${this.state.order.commodityContractId}/contract`);
  }


    render() {
        const orderGrade = get(this.state.order, 'gradeName') || get(this.state.order, 'plannedGrade');
        const isBlended = get(this.state.order, 'isBlended');
        return (
            <React.Fragment>
                <div className='row'>
                    <div className="col-md-8" style={{ paddingRight: '0px', width: '58%' }} >
                        <span className='title-label' style={{ marginLeft: '3px' }}>Order No</span>
                        {
                            this.state.order &&
                            <span className='title-child' style={{ marginLeft: '25px' }}><a style={{ cursor: "pointer" }} onClick={this.navigateToOrder} >{this.state.order.identifier}</a></span>
                        }
                    </div>
                    <div className='col-md-4' style={{ margin: '0px', padding: '0px', width: '40%' }}>
                        <span className='title-label'>Start Date</span>
                        {
                            isEqual(this.state.type, 'movement') && moment(this.state.movement.startDate).isValid() ? <React.Fragment>
                                <span className='title-child' style={{ marginLeft: '8px' }} >{moment(this.state.movement.startDate).format('DD-MM-YYYY')}</span>
                            </React.Fragment>
                                : ''
                        }
                        {
                            isEqual(this.state.type, 'order') && moment(this.state.order.startDate).isValid() ? <React.Fragment>
                                <span className='title-child' style={{ marginLeft: '12px' }} >{moment(this.state.order.startDate).format('DD-MM-YYYY')}</span>
                            </React.Fragment>
                                : ''
                        }
                    </div>
                </div>



                <div className='row'>
                    <div className="col-md-8" style={{ paddingRight: '0px', width: '58%' }} >
                        <span className='title-label' style={{ marginLeft: '3px' }}>Contract No</span>
                        {
                            this.state.order &&
                            <span className='title-child' style={{ marginLeft: '10px' }}>
                              {
                                this.state.order.canViewContract ?
                                  <a style={{ cursor: "pointer" }} onClick={this.navigateToContract}>
                                    {this.state.order.contractIdentifier}
                                  </a> :
                                this.state.order.contractIdentifier
                              }
                          </span>
                        }
                    </div>
                    <div className='col-md-4' style={{ margin: '0px', padding: '0px', width: '40%' }}>
                        <span className='title-label'>End Date</span>
                        {
                            isEqual(this.state.type, 'movement') && moment(this.state.movement.endDate).isValid() ? <React.Fragment>
                                <span className='title-child' style={{ marginLeft: '13px' }} >{moment(this.state.movement.endDate).format('DD-MM-YYYY')}</span>
                            </React.Fragment>
                                : ''
                        }
                        {
                            isEqual(this.state.type, 'order') && moment(this.state.order.endDate).isValid() ? <React.Fragment>
                                <span className='title-child' style={{ marginLeft: '12px' }} >{moment(this.state.order.endDate).format('DD-MM-YYYY')}</span>
                            </React.Fragment>
                                : ''
                        }
                    </div>
                </div>
                { isEqual(this.state.type, 'movement') &&
                <div className='row'>
                    <div className="col-md-8" style={{ paddingRight: '0px', width: '58%' }} >
                        <span className='title-label' style={{ marginLeft: '3px' }}>Invoice No</span>
                        {
                            get(this.state.movement, 'freightInvoice') &&
                            <span className='title-child' style={{ marginLeft: '10px' }}>
                                <a style={{ cursor: "pointer" }} onClick={() => window.open(`/#/invoices/${get(this.state.movement, 'freightInvoice.id')}/details`)}>
                                {get(this.state.movement, 'freightInvoice.identifier')}
                                </a>
                            </span>
                        }
                    </div>
                </div>
                }
                <Divider light style={{ margin: '5px 0px' }} />
                {
                    isEqual(this.state.type, 'movement') &&
                    <React.Fragment>
                        <div className='row' style={{ paddingTop: '3px', paddingLeft: '3px' }}>
                            <div className="container col-sm-12" style={{ paddingBottom: '5px' }}>
                                <span className='title-label'>Movement No</span>
                                <span className='title-child' style={{ marginLeft: '2px' }}>
                                    {
                                        this.state.movement.id ? 
                                        <a style={{ cursor: "pointer" }} onClick={this.navigateToMovement} >{this.state.movement.identifier}</a> :
                                        this.state.movement.identifier
                                    }
                                </span>
                            </div>
                            <div className="container col-sm-12">
                                <span className='title-label'>Tonnage</span>
                                <span className='title-child' style={{ marginLeft: '28px' }}>{this.state.movement.plannedTonnage} MT</span>
                            </div>
                        </div>
                        <div className='row' style={{ paddingLeft: '3px' }}>
                            <div className='col-sm-12 form-wrap' style={{ marginTop: '7px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '4%' }}>
                                        <SiteConnector height={'18px'} />
                                    </div>
                                    <div>
                                        <div className='col-sm-12 form-wrap' style={{ marginBottom: '10px' }}>
                                            <div style={{ fontSize: '12px' }}>
                                                <span style={{ fontWeight: '500' }}>{this.state.movement.showStartTime ? this.state.movement.startTime : ''}  {this.state.movement.consignorName}</span>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 form-wrap'>
                                            <div style={{ fontSize: '12px' }}>
                                                <span style={{ fontWeight: '500' }}>{this.state.movement.showEndTime ? this.state.movement.endTime : ''}  {this.state.movement.consigneeName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                {
                    isEqual(this.state.type, 'order') &&
                    <React.Fragment>
                        <div className='row' style={{ paddingTop: '3px', paddingLeft: '3px' }}>
                            <div className="container col-sm-12" style={{ paddingBottom: '5px' }}>
                                <span className='title-label'>Total</span>
                                <span className='title-child' style={{ marginLeft: '60px' }}>{this.state.order.tonnage} MT</span>
                            </div>
                            <div className="container col-sm-12">
                                <span className='title-label'>Unplanned</span>
                                <span className='title-child' style={{ marginLeft: '34px' }}>{this.state.actualRemainingOrderTonnage} MT</span>
                            </div>
                        </div>
                        <div className='row' style={{ paddingLeft: '3px' }}>
                            <div className='col-sm-12 form-wrap' style={{ marginTop: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '4%' }}>
                                        <SiteConnector height={'18px'} />
                                    </div>
                                    <div>
                                        <div className='col-sm-12 form-wrap' style={{ marginBottom: '10px' }}>
                                            <div style={{ fontSize: '12px' }} >
                                                <span style={{ fontWeight: '500' }}>{this.state.order.consignorName}</span>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 form-wrap'>
                                            <div style={{ fontSize: '12px' }} >
                                                <span style={{ fontWeight: '500' }}>{this.state.order.consigneeName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                <Divider light style={{ marginBottom: '5px', marginTop: '5px' }} />
                <div className='row' style={{ display: 'inline-block', paddingLeft: '3px' }}>
                    <div className="container col-sm-12">
                        <span className='title-label'>Commodity</span>
                        {
                            this.state.order &&
                            <span className='title-child' style={{ marginLeft: '28px' }}>{this.state.order.commodity}</span>
                        }
                        {!isBlended &&
                        <>
                            <span className='title-label' style={{ marginLeft: '38px' }}>Grade</span>
                            {
                                this.state.order &&
                                <span className='title-child' style={{ marginLeft: '38px' }}>{this.state.movement ? this.state.movement.inloadGrade ? this.state.movement.inloadGrade : this.state.movement.outloadGrade ? this.state.movement.outloadGrade : orderGrade: orderGrade}</span>
                            }
                        </>
                        }
                    </div>
                    {isBlended &&
                    <div className="container col-sm-12" style={{ display: 'flex', alignItems: 'baseline' }}>
                        <span className='title-label' style={{ whiteSpace: 'nowrap', minWidth: '77px' }}>Grade</span>
                        {
                            this.state.order &&
                            <span className='title-child' style={{ wordBreak: 'break-word', flex: 1 }}>
                                {this.state.movement ? (this.state.movement.inloadGrade || this.state.movement.outloadGrade || orderGrade) : orderGrade}
                            </span>
                        }
                    </div>
                    }
                </div>
                {
                    isEqual(this.state.type, 'movement') && (this.state.movement.pickupOrderNumber || this.state.movement.deliveryOrderNumber || this.state.movement.pickupBookingNumber || this.state.movement.deliveryBookingNumber) ?
                        <div>
                            <Divider light style={{ marginBottom: '5px', marginTop: '5px' }} />
                            <div className='row' style={{ paddingBottom: '3px' }}>
                                <div className="col-md-8" style={{ paddingRight: '0px', width: '55%' }} >
                                    <span className='title-label' style={{ marginLeft: '3px', color: '#000' }}>Pickup</span>
                                    <span className='title-child'></span>
                                </div>
                                <div className='col-md-4' style={{ margin: '0px', padding: '0px', width: '40%' }}>
                                    <span className='title-label' style={{ color: '#000' }}>Delivery</span>
                                    <span className='title-child'></span>
                                </div>
                            </div>
                            <div className='row' style={{ paddingBottom: '3px' }}>
                                {
                                    this.state.movement.pickupOrderNumber &&
                                    <div className="col-md-8" style={{ paddingRight: '0px', width: '55%' }} >
                                        <span className='title-label' style={{ marginLeft: '3px' }}>Order No</span>
                                        {
                                            this.state.order &&
                                            <span className='title-child' style={{ marginLeft: '15px' }}>{this.state.movement.pickupOrderNumber}</span>
                                        }
                                    </div>
                                }
                                {
                                    this.state.movement.deliveryOrderNumber &&
                                    <div className='col-md-4' style={{ margin: '0px', padding: '0px', width: '45%' }}>
                                        <span className='title-label'>Order No</span>
                                        {
                                            this.state.order &&
                                            <span className='title-child' style={{ marginLeft: '18px' }}>{this.state.movement.deliveryOrderNumber}</span>
                                        }
                                    </div>
                                }
                            </div>
                            <div className='row'>
                                {
                                    this.state.movement.pickupBookingNumber &&
                                    <React.Fragment>
                                        <div className="col-md-3" style={{ paddingRight: '0px', }} >
                                            <span className='title-label' style={{ marginLeft: '3px' }}>Booking No</span>
                                        </div>
                                        <div className="col-md-3" style={{ padding: '0px', wordBreak: 'break-all', }}>
                                            {
                                                this.state.order &&
                                                <span className='title-child' >{this.state.movement.pickupBookingNumber}</span>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    this.state.movement.deliveryBookingNumber &&
                                    <React.Fragment>
                                        <div className="col-md-3" style={{ paddingRight: '0px', }} >
                                            <span className='title-label'>Booking No</span>
                                        </div>
                                        <div className='col-md-3' style={{ margin: '0px', padding: '0px',  wordBreak: 'break-all', paddingRight: '8px' }}>
                                            {
                                                this.state.order &&
                                                <span className='title-child'>{this.state.movement.deliveryBookingNumber}</span>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div> : ''
                }
            </React.Fragment>
        );
    }
};

export default TooltipTemplate;
