import React from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import get from 'lodash/get';
import APIService from '../../../services/APIService'
import NestedOptionMenu from "../../NestedOptionMenu";
import { forceStopLoader, isLoading } from '../../../actions/main';
import { handleInvoicesOptionsMapper } from "../../../actions/companies/invoices";
import { getActionMenuOptions, getWarehouseDashboardMenuOptions } from '../utils';

export class InvoiceDetailsAction extends React.Component {

  state = {
    options: [],
    isOptionsLoaded: false,
  };

  componentDidMount() {
    this.updateOptions();
    setTimeout(this.setActionButtonBoundaries, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.invoiceDetails !== this.props.invoiceDetails ||
      prevProps.currentUser !== this.props.currentUser
    ) {
      this.updateOptions();
    }
  }

  setActionButtonBoundaries() {
    const statusBarEl = get(document.getElementsByClassName('invoice-status'), '0');
    if(statusBarEl) {
      const top = statusBarEl.offsetTop;
      const height = statusBarEl.offsetHeight;
      if(top && height) {
        const actionsContainer = document.getElementById('nested-actions-menu-container');
        if(actionsContainer) {
          actionsContainer.style.top = (top - 1) + 'px';
          actionsContainer.style.height = (height + 1) + 'px';
        }
      }

    }
  }

  handleActionsClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };


  syncXeroPayments = invoice => {
    this.props.dispatch(isLoading('xeroPaymentSync'))
    APIService
      .invoices(invoice.id)
      .appendToUrl('xero/payments/')
      .get()
      .then(() => alertifyjs.success('Successfully synced payments. Reloading...', 2, () => window.location.reload()))
  }

  syncXeroInvoice = invoice => {
    if (!invoice.hasCounterPartyXeroContact){
      alertifyjs.error('Cannot sync in Xero as counter party does not exist as a Contact', 5)
      return;
    }
    this.props.dispatch(isLoading('xeroInvoiceSync'))
    APIService.invoices(invoice.id).appendToUrl('xero/').post({syncXeroInvoice: true}).then(response => {
      this.props.dispatch(forceStopLoader())
      if(get(response, 'result.invoices[0].invoiceID'))
        alertifyjs.success('Successfully synced Invoice in Xero. Reload...', 1, () => window.location.reload())
      else if(get(response, 'result.creditNotes[0].creditNoteID'))
        alertifyjs.success('Successfully synced Credit Note in Xero. Reload...', 1, () => window.location.reload())
      else {
        const errorMessage = get(response, 'result.elements[0].validationErrors[0].message')
        alertifyjs.error(errorMessage || 'An Error Occurred!', 5)
      }
    })
  }

  handleOptionClick = (event, item, baseEntity) => {
    const { pdfUrl } = get(this.props, 'invoiceDetails');
    if(item.key === 'create')
      window.location.hash = `#/invoices/warehouse/${baseEntity.id}/edit`
    else if(item.key === 'sync_xero_payments')
      this.syncXeroPayments(baseEntity)
    else if(item.key === 'sync_xero_invoice')
      this.syncXeroInvoice(baseEntity)
    else
      this.props.dispatch(handleInvoicesOptionsMapper(event, item, baseEntity, pdfUrl, 'InvoiceDetailsAction'));
  };

  updateOptions = () => {
    const { invoiceDetails, currentUser } = this.props;
    let options =
      invoiceDetails.statusDisplayName === 'draft'
        ? getWarehouseDashboardMenuOptions(invoiceDetails)
        : getActionMenuOptions(invoiceDetails, true);

    if (currentUser?.company.id && !this.state.isOptionsLoaded) {
      this.props.dispatch(isLoading('checkingXeroConnection'));
      APIService.companies(currentUser.company.id)
        .appendToUrl('xero/connections/')
        .get()
        .then((res) => {
          this.props.dispatch(forceStopLoader());
          if (res?.valid) {
            options = [...options, { key: 'sync_xero_invoice', text: 'Sync Xero Invoice' }];
          }
          this.setState({ options, isOptionsLoaded: true });
        });
    } else {
      this.setState({ options });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.invoiceDetails && (
          <NestedOptionMenu
            optionsItems={this.state.options}
            item={this.props.invoiceDetails}
            handleOptionClick={this.handleOptionClick}
            currentUser={this.props.currentUser}
            shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
            useButton={true}
            useIconButton={false}
          />
        )}
      </React.Fragment>
    );

  }
}

const mapStateToProps = (state) => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(InvoiceDetailsAction);
