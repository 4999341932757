import React from 'react';
import alertifyjs from 'alertifyjs';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import uniq from 'lodash/uniq'
import { copyToClipboard, isSuperuser, isCurrentUserBelongsToCompany, isCompanyAdminOrObserver } from '../../common/utils'
import APIService from '../../services/APIService';
import ImpexDocsConnect from './ImpexDocsConnect';


const ImpexDocsSetupInstructions = ({ company }) => {
  const [clientId, setClientId] = React.useState({value: company?.impexDocsClientId || '', error: ''});
  const [clientSecret, setClientSecret] = React.useState({value: company?.impexDocsClientSecret || '', error: ''});
  const [isValidImpexDocsConnection, setIsValidImpexDocsConnection] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(false)
  const [activeSteps, setActiveSteps] = React.useState([])
  const MORPHED_SECRET = '*'.repeat(48)

  const anchorStyles = {fontSize: '1rem', color: '#337ab7', fontWeight: 'normal'}

  const hadSecrets = Boolean(company?.impexDocsClientId && company?.impexDocsClientSecret)

  const canLinkAccount = () => {
    if(!clientId.value && !clientSecret.value && company.impexDocsClientId && company.impexDocsClientSecret)
      return true
    if(clientId.value === company.impexDocsClientId && clientSecret.value === company.impexDocsClientSecret)
      return true
    if(clientId.value && clientSecret.value)
      return true
    return false
  }

  const verifyImpexDocsConnection = () => {
    if(company.id) {
      setIsLoading(true)
      APIService.companies(company.id).appendToUrl('impex-docs/connections/').get().then(res => {
        setIsValidImpexDocsConnection(res?.valid)
        setIsLoading(false)
      })
    }
  }

  const onUnlink = () => {
    alertifyjs.confirm('Unlink ImpexDocs Account', 'This action will remove ImpexDocs Account Connection with AgriChain.<br/>Are you sure you want to proceed?', () => {
      setIsLoading(true)
      APIService.companies(company.id).appendToUrl('impex-docs/connections/').delete().then(res => {
        if(res?.detail) {
          setIsLoading(false)
          alertifyjs.alert('Permission Denied', res?.detail, () => {})
        }
        else {
          alertifyjs.success('Successfully unlinked ImpexDocs Account', 1, () => {
            setIsValidImpexDocsConnection(false)
            window.location.reload()
          })
        }
      })
    }, () => {})
  }

  const markStepActive = step => setActiveSteps(uniq([...activeSteps, step]))

  React.useEffect(() => {
    verifyImpexDocsConnection()
  }, [company.id])


  return (
    <Box sx={{ p: 2 }}>
      <Stepper orientation="vertical">
        <Step active={!hadSecrets || activeSteps.includes(1)} completed={hadSecrets} onClick={() => markStepActive(1)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Connect AgriChain with ImpexDocs
          </StepLabel>
          <StepContent>
            <Typography>
              <span>Connect AgriChain with ImpexDocs in three simple steps. Our </span>
              <a style={anchorStyles} href='https://www.youtube.com/watch?v=LCh45euvaKE' target='_blank' rel="noreferrer">How to Setup ImpexDocs Integration</a>
              <span> video explains the steps in details, please watch and follow.</span>
            </Typography>
          </StepContent>
        </Step>
        <Step active={!hadSecrets || activeSteps.includes(2)} completed={hadSecrets} onClick={() => markStepActive(2)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Register AgriChain App in ImpexDocs
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
              Open <a style={anchorStyles} href='https://id.impexdocs.com/realms/impex/protocol/openid-connect/auth?response_type=code&client_id=impex&redirect_uri=https://app.impexdocs.com/Home/Index' target='_blank' rel="noreferrer">{'ImpexDocs Registration'}</a>
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              Below are some URLs referenced in the video for you to use:
              <div style={{marginTop: '8px'}}>
                <span className='no-select 'style={{fontSize: '14px', color: 'gray', marginRight: '8px'}}>AgriChain Application URL:</span>
                <span>{'https://platform.agrichain.com'}</span>
                <Button size='small' variant='text' color='primary' sx={{textTransform: 'none'}} onClick={() => copyToClipboard('https://platform.agrichain.com', 'Copied in your clipboard', 2)}>
                  copy
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step active={!hadSecrets || activeSteps.includes(3)} completed={canLinkAccount()} onClick={() => markStepActive(3)} sx={{cursor: 'pointer'}}>
          <StepLabel sx={{cursor: 'pointer !important'}}>
            Enter AgriChain App Secrets
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
              Please enter Client credentials copied from ImpexDocs:
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <TextField
                size='small'
                label='Client ID'
                value={clientId.value}
                onChange={event => setClientId({...clientId, value: event.target.value})}
                required
                style={{width: '50%'}}
                error={Boolean(clientId.error)}
                helperText={clientId.error}
                disabled={isValidImpexDocsConnection}
              />
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <TextField
                size='small'
                label='Client Secret'
                value={clientSecret.value === company.impexDocsClientSecret ? MORPHED_SECRET : clientSecret.value}
                onChange={event => setClientSecret({...clientSecret, value: event.target.value})}
                required
                style={{width: '50%'}}
                error={Boolean(clientSecret.error)}
                helperText={clientSecret.error}
                disabled={isValidImpexDocsConnection}
              />
            </div>
            {/* <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              <Button variant="contained" onClick={onClientSecretUpdate} disabled={isValidImpexDocsConnection || isLoading}>
                Update Secrets
              </Button>
            </div> */}
          </StepContent>
        </Step>
        <Step active={!isLoading } completed={isValidImpexDocsConnection}>
          <StepLabel
            optional={
                <Typography variant="caption">Final step</Typography>
            }
          >
            Authorize AgriChain
          </StepLabel>
          <StepContent>
            <div className='col-xs-12 padding-reset'>
            </div>
            <div className='col-xs-12 padding-reset' style={{marginTop: '16px'}}>
              {
                isValidImpexDocsConnection ?
                  <React.Fragment>
                  <Button disabled variant='contained'>
                    Authorized and Connected
                  </Button>
                    {
                      (isSuperuser() || (isCompanyAdminOrObserver() && isCurrentUserBelongsToCompany(company.id))) &&
                        <Button sx={{marginLeft: '16px'}} variant='outlined' color='error' onClick={onUnlink}>
                          Unlink Account
                        </Button>
                    }
                    </React.Fragment> :
                  <ImpexDocsConnect
                    label={company.isImpexDocsAccountLinked && !isValidImpexDocsConnection ? 'Reconnect ImpexDocs' : 'Connect ImpexDocs'}
                    disabled={(isLoading && isValidImpexDocsConnection) || !(clientId.value && clientSecret.value)}
                    clientId={clientId.value}
                    clientSecret={clientSecret.value}
                    companyId={company.id}
                  />
              }
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  )
}

export default ImpexDocsSetupInstructions;
