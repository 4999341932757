import React, { Component } from 'react';
import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import { Tabs, Tab } from '@mui/material';
import { forceStopLoader } from '../../actions/main';
import ImpexDocsSetupInstructions from './ImpexDocsSetupInstructions';
import { getCurrentCountry } from '../../common/utils';
import ImpexDocsMappings from './ImpexDocsMappings';
import LoaderInline from '../LoaderInline';
import { isEmpty } from 'lodash';


class ImpexDocs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      commodities: [],
      grades: [],
      varieties: [],
      itemTypes: [],
    }
  }

  handleTabChange = (event, value) => this.setState({tab: value})

  componentDidMount() {
    this.fetchCommodities()
  }
  componentDidUpdate() {
    if (this.props.isLoading)
      this.props.forceStopLoader()
  }

  fetchCommodities = () => {
    APIService.commodities().appendToUrl(`?includes=grade,variety&countryId=${getCurrentCountry().id}`).get().then(response => this.setState({commodities: response}))
  }

  getTransactionType = () => {
    const { tab } = this.state
    if(tab === 1)
      return 'product_code'
  }

  render() {
    const { tab, commodities } = this.state
    const tabs = [
      {id: 0, label: 'Instructions'},
      {id: 1, label: 'Product Code'}
    ]
    const transactionType = this.getTransactionType()
    return (
        <div className="subTab">
          <Tabs indicatorColor="primary" className="subTab-header" value={tab} onChange={this.handleTabChange}>
            {
              tabs.map(_tab => (
                <Tab key={_tab.label} label={_tab.label}  className={tab !== _tab.id ? 'unselected-subtab' : ''} />
              ))
            }
          </Tabs>
          {
            tab === 0 ?
            <div className="subTab-container">
              <ImpexDocsSetupInstructions company={this.props.company}/>
            </div> :
            <div className="subTab-container">
              {
                isEmpty(commodities) ?
                <LoaderInline containerClassName="inline-loader-container" /> :
                <ImpexDocsMappings
                  commodities={commodities}
                  transactionType={transactionType}
                  company={this.props.company}
                  onRefetch={this.refetchMappings}
                />
              }
            </div>
          }
        </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.main.isLoading,
})

const mapDispatchToProps = dispatch => ({
  forceStopLoader: () => dispatch(forceStopLoader()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImpexDocs);
