import React from 'react';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import ReactQuill from 'react-quill';
import CommonButton from '../common/CommonButton';
import { COR_DEFAULT_TEXTS_MAPPING } from '../../common/constants';
import { get } from 'lodash';
import APIService from '../../services/APIService';

const COR_ITEMS = [
  {id: 'siteCor', name: 'Site COR'},
  {id: 'driverCor', name: 'Driver COR'},
  {id: 'vendorDecDriverCor', name: 'Vendor Dec Driver COR'},
  {id: 'vendorDecSiteEmployeeCor', name: 'Vendor Dec Employee COR'},
  {id: 'grainVendorDecCor', name: 'Grain Vendor Dec COR'},
]

class ChainOfResponsibilityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      text: '',
    };
  }

  componentDidMount() {
    if (this.props.selectedItem) {
      this.setState({
        name: this.props.selectedItem.key,
        text: this.props.selectedItem.value,
      });
    }
  }

  handleValueChange = value => {
    const newState = {...this.state};
    newState.name = value;
    if (!this.props.selectedItem)
      newState.text = get(COR_DEFAULT_TEXTS_MAPPING, value, '');
    this.setState(newState);
  }

  handleSubmit = () => {
    let corData = get(this.props.selectedCompany, 'corTexts') || {};
    const companyId = this.props.companyId || get(this.props.selectedCompany, 'id');
    corData[this.state.name] = this.state.text;
    APIService.companies(companyId)
      .put({'corTexts': corData})
      .then(() => this.props.refreshData())
  }

  render () {
    return (
      <div>
        <div className='col-md-12' style={{padding: '0px', marginTop: '30px'}}>
          <CommonAutoSelect
            id='corField'
            label='COR Name'
            value={this.state.name}
            onChange={this.handleValueChange}
            items={COR_ITEMS}
            variant='outlined'
            disabled={this.props.selectedItem}
          />
        </div>
        {this.state.name &&
        <div className="col-sm-12 padding-reset" style={{marginTop: "40px", marginBottom: "40px"}}>
          <ReactQuill
            theme="snow"
            value={this.state.text || ''}
            modules={ChainOfResponsibilityForm.modules}
            formats={ChainOfResponsibilityForm.formats}
            onChange={value => this.setState({text: value})}
            placeholder={'Enter CoR...'}
            style={{ height: "200px" }}
          />
        </div>
        }
        <div className="col-sm-12" style={{marginLeft: "107px", marginTop: "30px"}}>
          <CommonButton
            type="button"
            label="Cancel"
            default
            onClick={this.props.closeDrawer}
            variant="outlined"
          />
          <CommonButton
            type="submit"
            variant="contained"
            label="Save"
            disabled={!this.state.name || !this.state.text}
            onClick={this.handleSubmit}
            primary
          />
        </div>
      </div>
    );
  }
}

ChainOfResponsibilityForm.modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
  ],
  clipboard: {
    matchVisual: false,
  }
};
ChainOfResponsibilityForm.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default ChainOfResponsibilityForm;
