class Number {
  constructor(value, currency, unit, suffix, defaultValue='0') {
    this._rawValue = value
    this.number = 0
    this.value = value
    this.currency = currency
    this.unit = unit
    this.suffix = suffix
    this.defaultValue = defaultValue || 'N/A'
    this.formatted = null
    this._compute()
  }

  isNA = () => this.value === 'N/A'

  _compute() {
    this._toVal()
    this.formatted = this._format()
  }

  recompute() {
    this.value = this._rawValue
    this._compute()
  }

  _toVal() {
    if(!this.value && this.value !== 0)
      this.value = this.defaultValue

    if(this.isNA())
      return

    let str = this.value.toString().replace(/[^\d.-]/g, '');
    this.number = parseFloat(str)
    const isBelowZero = this.number < 0
    let num = parseFloat(Math.abs(str)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.value = isBelowZero ? `-${num}` : num;
  }

  _format() {
    if(this.isNA())
      return this.value

    let val = ''
    if(this.currency)
      val += this.currency + ' '
    val += this.value
    val += this.suffix || ''
    val += this.unit || ''

    return val
  }
}

export default Number;
