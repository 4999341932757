import React from 'react';

import get from 'lodash/get';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { EMPTY_VALUE } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';
import {formatPrice, getCountryLabel} from "../../../common/utils";
import includes from 'lodash/includes';


const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
              style={{ fill: '#112c42', height: '20px', width: '20px' }}
    />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class InvoiceTotalSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getHighlightedRows() {
    const { invoiceDetails } = this.props;
    if(get(invoiceDetails, 'isFreightInvoice'))
      return [3];
    if(get(invoiceDetails, 'isBrokerageInvoice'))
      return [4];
    if(get(invoiceDetails, 'isCommodityContractInvoice'))
      return [5, 7];
  }

  getInvoiceTotalSection = (payload, sectionName=null) => {
    const highLightedRows = this.getHighlightedRows();
    return (
      <div style={{borderRight: 'none', paddingLeft: '0px', paddingRight: '0px'}}>
        {
        sectionName &&
        <h4 className="section-title">{sectionName}</h4>
        }
        <ul>
          {Object.keys(payload).map((key, index) => {
             return (
               <li key={index} className={includes(highLightedRows, index + 1) ? "highlighted" : null}>
                 <Tooltip
                   className="field-label-black-bold ellipses"
                   tooltipText={key}
                   textContent={key}
                 />
                 {<Tooltip
                    className="field-value-right ellipses"
                    tooltipText={payload[key] || EMPTY_VALUE}
                    textContent={payload[key] || EMPTY_VALUE}
                 />}
               </li>
             );
           })}
        </ul>
      </div>
    );
  };

  getTotal() {
    const { invoiceDetails } = this.props;
    return formatPrice(get(invoiceDetails,'totalDetails.total'), null, get(invoiceDetails, 'currency'));
  }

  getTotalDom(payload) {
    const {isRcti, payeeDisplayName} = this.props.invoiceDetails;
    const { newView } = this.props
    const gstLabel = getCountryLabel('gst')
    return (
      <React.Fragment>
        <div className="section-details-container" style={{gridTemplateColumns: 'none', marginTop: '0px'}}>
          {!newView && this.getInvoiceTotalSection(payload)}
          {
          !newView &&
              <div className="total-row">
                <div className="col-sm-6" style={{paddingLeft: '20px'}}>
                  {this.props.invoiceDetails.isWarehouseInvoice ? `Total Amount Payable(Inc ${gstLabel})` : 'Total'}
                </div>
                <span className="col-sm-6" style={{textAlign: 'right', paddingRight: '20px'}}>
                  {this.getTotal()}
                </span>
              </div>
          }
          <div style={{margin: newView ? '0 0 8px 0' : '10px 0', padding: 0}}>
            <p style={{margin: '2px 0', padding: 0, fontSize: '0.8125rem'}}>
              * {gstLabel} is not applicable
            </p>
            {
              isRcti &&
                <p style={{fontSize: '0.8125rem', margin: '2px 0'}}>** PLEASE NOTE: The {gstLabel} shown on this RCTI is payable
                by {payeeDisplayName}</p>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }


  render() {
    const { expanded } = this.state;
    const {
      isRcti, totalDetails, totalInloads, totalOutloads, totalStorages,
      totalTransfers, totalRegradeReseasons, totalStockSwaps, requestedUnit, raisedForUnit, currency
    } = this.props.invoiceDetails;
    let payload = {};
    const gstLabel = isRcti ? `${getCountryLabel('gst')}**` : `${getCountryLabel('gst')}`;
    const unit = requestedUnit || raisedForUnit
    if(this.props.invoiceDetails.isFreightInvoice){
      payload = {
        ...payload,
        [`Total ${getCountryLabel('tonnage')}`]: (get(this.props.invoiceDetails,'fmTtLoadTonnage', 0) || 0) + unit,
        ['Freight Charges (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'freightCharges'), `${currency } 0.00`, currency),
        ['Others (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'othersExGst'), `${currency } 0.00`, currency),
        ['Gross Amount (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'grossAmountExGst'), `${currency } 0.00`, currency),
        [gstLabel]: formatPrice(get(totalDetails,'gst'), `${currency } 0.00`, currency),
      };
    } else if (this.props.invoiceDetails.isCommodityContractInvoice) {
      payload = {
        ...payload,
        'Total Quantity': (get(this.props.invoiceDetails,'fmTtLoadTonnage', 0) || 0) + unit,
        ['Total Commodity Price (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'totalCommodityPrice'), `${currency } 0.00`, currency),
        ['Carry Charges (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'carryCharges'), `${currency } 0.00`, currency),
        ['EPR (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'eprExGst'), `${currency } 0.00`, currency),
        ['Others (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'othersExGst'), `${currency } 0.00`, currency),
        ['Gross Amount (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails,'grossAmountExGst'), `${currency } 0.00`, currency),
        [gstLabel]: formatPrice(get(totalDetails,'gst'), `${currency } 0.00`, currency),
        'Sub Total': formatPrice(get(totalDetails,'subTotal'), `${currency } 0.00`, currency),
        'Grain Levies': formatPrice(get(totalDetails,'grainLevies'), `${currency } 0.00`, currency),
      };
    } else if(this.props.invoiceDetails.isWarehouseInvoice){
        payload = {
          ...payload,
          'Total Inload Fees': formatPrice(totalInloads, `${currency } 0.00`, currency),
          'Total Outload Fees': formatPrice(totalOutloads, `${currency } 0.00`, currency),
          'Total Transfer Fees': formatPrice(totalTransfers, `${currency } 0.00`, currency),
          'Total Storage Fees': formatPrice(totalStorages, `${currency } 0.00`, currency),
          'Total Stock Swap Fees': formatPrice(totalStockSwaps, `${currency } 0.00`, currency),
          'Total Regrade Reseason Fees': formatPrice(totalRegradeReseasons, `${currency } 0.00`, currency),
          'Total Others (Ex. GST)': formatPrice(get(totalDetails,'othersExGst'), `${currency } 0.00`, currency),
          ['Gross Amount (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails, 'grossAmountExGst'), `${currency } 0.00`, currency),
          [gstLabel]: formatPrice(get(totalDetails, 'gst'), `${currency } 0.00`, currency),
        };
    }
    else
    {
      payload = {
        ...payload,
        'Commodity Contract Brokerages Fee': formatPrice(get(totalDetails, 'totalCommodityPrice'), `${currency } 0.00`, currency),
        ['Periodic Brokerage Fees (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails, 'periodicBrokerageFee'), `${currency } 0.00`, currency),
        ['Others (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails, 'others'), `${currency } 0.00`, currency),
        ['Gross Amount (Ex ' + gstLabel + ')']: formatPrice(get(totalDetails, 'grossAmountExGst'), `${currency } 0.00`, currency),
        [gstLabel]: formatPrice(get(totalDetails, 'gst'), `${currency } 0.00`, currency),
      };
    }

    return (
      <Paper className="invoice-details-section-container total-section">
        {
          this.props.showHeader &&
          <h2 onClick={this.toggleExpanded}>
            Total
            <span className="expand-icon">
              { this.props.expansionFalse && renderBasedOnExpandedFlag(expanded)}
            </span>
          </h2>
        }
        {
          expanded && this.getTotalDom(payload)
        }
      </Paper>
    );
  }
}

export default InvoiceTotalSection;
