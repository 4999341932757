import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchBanks } from '../../actions/main';
import alertifyjs from 'alertifyjs';
import CommonSelect from '../common/select/CommonSelect';
import {
  forEach, filter, every, some, get, map,  isEmpty, isNumber, max, includes, uniqBy, has, find,
  orderBy, cloneDeep, difference, compact, reject, first, isEqual, mapValues, uniq, isArray
} from 'lodash';
import { required, fixLength, numRegex } from '../../common/validators';
import CompanyBankAccountForm from './CompanyBankAccountForm';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import { PRIMARY_COLOR_GREEN } from '../../common/constants';
import FileUpload from '../common/FileUpload';
import { currentUserCompany, isCurrentUserBelongsToCompany, isSystemCompany, isCompanyNgrsEditable } from '../../common/utils';
import APIService from '../../services/APIService';
import moment from 'moment';
import { Button, IconButton, Checkbox } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { getCommodities } from '../../actions/api/commodities';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { isLoading, forceStopLoader } from '../../actions/main';
import NgrDeclarationForm from './NgrDeclarationForm';

const TAGS = [
  {id: 'shrinkage', name: 'Shrink Default'},
  {id: 'title_transfer', name: 'Title Transfer Default'},
  {id: 'warehouse_invoice', name: 'Warehouse Invoice Default'},
  {id: 'receivable', name: 'Receivable'},
  {id: 'payable', name: 'Payable'},
]


class CompanyNgrForm extends Component {
  constructor(props) {
    super(props);
    this.declarationModel = {season: '', commodityIds: []}
    this.state = {
      bankNameSearchText: '',
      shareholderCount: props.selectedNgr ? get(props, 'selectedNgr.bankAccounts.length') : 1,
      totalShareholderPercent: 0,
      bankValidationSignals: [false, false, false, false, false],
      isAbnDuplicate: [false, false, false, false, false],
      ngrTypes: [
        { id: 'single', name: 'Single' },
        { id: 'shared', name: 'Shared' },
      ],
      fetchNgrDetails: undefined,
      fetchingNGRDetails: false,
      declarations: [{...this.declarationModel}],
      fields: {
        ngrType: {
          value: 'single',
          validators: [],
          errors: [],
        },
        ngrNumber: {
          value: '',
          validators: [required('NGR is required.'), fixLength(8), numRegex()],
          errors: [],
        },
        shareAuthorityUrl: {
          value: [],
          validators: [],
          errors: [],
        },
        shareAuthorityDoc: {
          value: [],
          validators: [],
          errors: [],
        },
        shareAuthorityFile: {
          value: [],
          validators: [],
          errors: [],
        },
        shareAuthorityFileClone: {
          value: [],
          validators: [],
          errors: [],
        },
        tags: {
          value: undefined,
          validators: [],
          errors: [],
        }
      },
      ngrPortalDetails: undefined,
      sustainableCheck: false,
    };

    this.bankAccounts = [
      {
        id: '',
        name: '',
        bsbNumber: '',
        accountName: '',
        accountNumber: '',
        shareholderPercent: 100,
        companyId: '',
        isValid: this.isBankAccountValid(get(props, 'selectedNgr.bankAccounts.0')),
        isPrimary: ''
      },
    ];
    this.toBePercentage = get(props, 'selectedNgr.bankAccounts.0.shareholderPercent', 0);
    this.highestSharesPercentage = 0;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedNgr = this.setAllFieldsValuesBySelectedNgr.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleNgrTypeChanged = this.handleNgrTypeChanged.bind(this);
    this.handleBankAccountChanged = this.handleBankAccountChanged.bind(this);
    this.addShareholder = this.addShareholder.bind(this);
    this.removeShareholder = this.removeShareholder.bind(this);
    this.setBankValidationSignal = this.setBankValidationSignal.bind(this);
    this.onBankValidated = this.onBankValidated.bind(this);
    this.loadImageFileAsURL = this.loadImageFileAsURL.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.fetchNgrDetails = this.fetchNgrDetails.bind(this);
    this.isPortalVerified = this.isPortalVerified.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchBanks());
    setTimeout(() => this.setAllFieldsValuesBySelectedNgr(), 500);
    this.getAllCompanies();
    let companyId = '';

    if(isSystemCompany()) {
      companyId = this.props.companyId;
      this.props.dispatch(getCommodities());
    }
    else
      companyId = get(currentUserCompany(), 'id')

    this.getNgrPortalDetails(companyId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedNgr) {
      if (this.props.selectedNgr.id !== prevProps.selectedNgr.id) {
        this.setAllFieldsValuesBySelectedNgr();
      }
    }
  }

  onClickAddNewDeclaration = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({declarations: [...this.state.declarations, {...this.declarationModel}]})
  }
  onClickDeleteDeclaration = (event, index) => {
    event.preventDefault()
    event.stopPropagation()
    const newState = {...this.state}
    newState.declarations.splice(index, 1)
    newState.declarations = [...newState.declarations]
    this.setState(newState)
  }

  onDeclarationChange = (data, index) => {
    const newState = {...this.state}
    newState.declarations.splice(index, 1, data)
    newState.declarations = [...newState.declarations]
    this.setState(newState)
  }

  async getNgrPortalDetails(id) {
    let portalDetails = {}

    APIService.companies().appendToUrl(`${id}/ngrs/credentials/`).requestUsingFetch('GET')
      .then(response => response.json())
      .then(portalCredentials => {
        if(!portalCredentials || isEqual(get(portalCredentials, 'username'), '') || isEqual(get(portalCredentials,'detail'), 'Not found.'))
          portalDetails = { isPortalLinked: false };
        else {
          portalDetails = portalCredentials;
          portalDetails.isPortalLinked = true;
        }
        this.setState({
          ngrPortalDetails: portalDetails
        })
      })

  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      if (has(newState.fields, key)) {
        newState.fields[key].errors = value;
      }
    });
    this.setState(newState);
  }

  onBankValidated(index) {
    this.setBankValidationSignal(index, false);
  }

  setBankValidationSignal(index, value) {
    const newState = { ...this.state };
    newState.bankValidationSignals[index] = value;
    this.setState(newState);
  }

  loadImageFileAsURL(filestate) {
    const newState = { ...this.state };
    newState.fields.shareAuthorityDoc.value.push(filestate.file);
    this.setState(newState, this.notifyValueChanged);
  }

  handleFileRemove(src) {
    const newState = { ...this.state };
    const index = this.state.fields.shareAuthorityDoc.value.findIndex(file => file.base64 === src);
    newState.fields.shareAuthorityDoc.value.splice(index, 1);
    this.setState(newState);
  }

  handleNgrTypeChanged(value) {
    if (this.state.fields.ngrType.value === value) {
      return;
    }
    let shareholderCount;
    if (value === 'single') {
      shareholderCount = 1;
      this.bankAccounts.splice(1, this.bankAccounts.length - 1);
      this.bankAccounts[0].shareholderPercent = 100;
      this.bankAccounts[0].isValid = true;
      this.bankAccounts[0].abn = '';
    } else {
      this.bankAccounts[0].shareholderPercent = '';
      this.bankAccounts[0].isValid = false;
      this.bankAccounts[0].isPrimary = true;
      this.bankAccounts.push({
        name: '',
        bsbNumber: '',
        accountName: '',
        accountNumber: '',
        companyId: '',
        isValid: false,
        shareholderPercent: '',
        isPrimary: false
      });
      shareholderCount = 2;
    }

    const newState = { ...this.state };
    newState.fields['ngrType'].value = value;
    newState.shareholderCount = shareholderCount;
    this.setState(newState);
  }

  handleBankAccountChanged(index, bankAccount) {
    if (index == 0)
      this.toBePercentage = bankAccount.shareholderPercent;
    this.bankAccounts[index] = bankAccount;
    this.highestSharesPercentage = max(map(this.bankAccounts, 'shareholderPercent'));
  }

  addShareholder() {
    if (this.state.shareholderCount < 5) {
      this.bankAccounts.push({
        name: '',
        bsbNumber: '',
        accountName: '',
        accountNumber: '',
        isValid: false,
        shareholderPercent: '',
      });

      this.setState(prevState => ({
        shareholderCount: prevState.shareholderCount + 1,
      }));
    }
  }

  removeShareholder(index) {
    if (this.bankAccounts.length > 2) {
      this.bankAccounts.splice(index, 1);

      this.setState(prevState => ({
        shareholderCount: prevState.shareholderCount - 1,
      }));
    }
  }

  removeFile(index) {
    const newState = { ...this.state };
    newState.fields.shareAuthorityFileClone.value.splice(index, 1);
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors(this.submitForm);
  }

  submitForm() {
    const isFormInvalid = some(this.state.fields, field => {
      return some(field.validators, validator => {
        return validator.isInvalid(field.value);
      });
    });
    const areBanksValid = this.bankAccounts.every(bank => bank.isValid);
    const isAbnDuplicate = this.state.isAbnDuplicate.indexOf(true) != -1;
    const submitData = mapValues(this.state.fields, field => {
      return field.value;
    });
    if(this.state.sustainableCheck) {
      let sustainableDeclaration = {}
      forEach(this.state.declarations, declaration => {
        if(declaration.season && !isEmpty(compact(declaration.commodities))) {
          sustainableDeclaration[declaration.season] = isArray(get(sustainableDeclaration, declaration.season)) ? sustainableDeclaration[declaration.season] : []
          sustainableDeclaration[declaration.season] = compact([...sustainableDeclaration[declaration.season], ...declaration.commodities])
        }
      })
      submitData['sustainableDeclaration'] = sustainableDeclaration;
    }
    if(!isSystemCompany())
      delete submitData.tags
    const banks = [];
    let isBankInvalid = false;
    let isInvalidShareholder = false;
    this.bankAccounts.forEach(bankAccount => {
      const bank = Object.assign({}, bankAccount);
      delete bank.isValid;
      delete bank.currentTime;
      if (!get(bank, 'companyId')) {
        isInvalidShareholder = true;
      }
      if (bank.abn || bank.name || bank.bsbNumber || bank.accountName || bank.accountNumber || bank.shareholderPercent) {
        if(bank.name) {
          const banksByName = filter(this.props.banks, {name: bank.name});
          let selectedBank = find(banksByName, {bsbNumber: bank.bsbNumber})
          if(!selectedBank)
            selectedBank = banksByName[0]
          bank.bankId = get(selectedBank, 'id');
        }
        if(bank.isMandatory) {
          if(!this.isBankAccountValid(bank)) {
            isBankInvalid = true;
            return
          }
        }
        delete bank.name;
        delete bank.isMandatory;
        banks.push(bank);
      }
    });
    if (isInvalidShareholder && this.bankAccounts.length > 1) {
      return;
    }
    if(isBankInvalid){
      return;
    }

    if (banks.length !== 0) {
      submitData.bankAccounts = banks;
    } else {
      if (get(submitData, 'bankAccounts')) {
        delete submitData.bankAccounts;
      }
    }
    submitData.ngrType = submitData.ngrType.toLowerCase();
    submitData['shareAuthorityUrl'] = submitData['shareAuthorityDoc'];
    submitData['shareAuthorityFile'] = submitData['shareAuthorityFileClone'];
    submitData['isVerified'] = this.isPortalVerified();
    if (submitData['isVerified'] && this.state.fetchNgrDetails) {
      submitData['verifiedOn'] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
		}
    delete submitData['shareAuthorityDoc'];
    delete submitData['shareAuthorityFileClone'];
    let primary_holders_shares_list = filter(submitData.bankAccounts, 'isPrimary');
    forEach(submitData.bankAccounts, bankAccount => delete bankAccount.id);
    if (!isFormInvalid && areBanksValid) {
      if (submitData.ngrType === 'single') {
        if (get(submitData, 'bankAccounts')) {
          submitData.bankAccounts[0].shareholderPercent = 100;
          submitData.bankAccounts[0].isPrimary = true;
        }
        this.props.submit(this.props.companyId, submitData, this.props.onSuccess);
      } else if (!isAbnDuplicate && this.state.totalShareholderPercent == 100 && every(primary_holders_shares_list, obj => obj.shareholderPercent == this.highestSharesPercentage)) {
        if (!includes((map(submitData.bankAccounts, 'companyId')), this.props.companyId)) {
          alertifyjs.error("Company where the NGR is being added must be one of the shareholders");
          return;
        };
        this.props.submit(this.props.companyId, submitData, this.props.onSuccess);
      }
    }
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  isPortalVerified() {
    let ngrDetails = this.state.fetchNgrDetails ? this.state.fetchNgrDetails : this.props.selectedNgr?.bankAccounts;
    if(ngrDetails && ngrDetails.length > 1){
      if(ngrDetails.length !== this.bankAccounts.length)
        return false;
      else {
        let invalidFields =  this.state.fetchNgrDetails ? find(ngrDetails, (item, index) => !this.checkPortalFields(item, this.bankAccounts[index])) : find(ngrDetails, (item, index) => !this.checkPortalFieldsUnchanged(item, this.bankAccounts[index]))
        return !invalidFields;
      }
    } else {
      return this.state.fetchNgrDetails ? this.checkPortalFields(get(ngrDetails, '0'), this.bankAccounts[0]) : this.checkPortalFieldsUnchanged(get(ngrDetails, '0'), this.bankAccounts[0]);
    }
  }

  checkPortalFields(lastFetchedNgrDetails, currentDetails) {
    let isVerified = true;
    let fieldToValidate = ['bankAccountName', 'bankAccountNumber', 'bsbId', 'bsbBank',
                           'ngrType', 'ngrNumber'];
    if( isEqual(get(lastFetchedNgrDetails, 'ngrValue'), 'shared'))
      fieldToValidate.push('shareholderPercent', 'companyId');

    forEach(fieldToValidate, field => {
      if(isEqual(field, 'ngrType')) {
        if(!isEqual(get(lastFetchedNgrDetails, 'ngrValue'), this.state.fields.ngrType.value )) {
          isVerified = false;
        }
      } else if(isEqual(field, 'ngrNumber')) {
        if(!isEqual(get(lastFetchedNgrDetails, 'ngrNumber'), this.state.fields.ngrNumber.value )) {
          isVerified = false;
        }
      } else if(isEqual(field, 'bsbBank')){
        let bankDetails = find(this.props.banks, { id: lastFetchedNgrDetails.bankId });
        if(!isEqual(get(bankDetails, 'name'), get(currentDetails, 'name')))
          isVerified = false;
      } else if(isEqual(field, 'bankAccountName')){
        if(!isEqual(get(lastFetchedNgrDetails, 'bankAccountName'), get(currentDetails, 'accountName')))
          isVerified = false;
      } else if(isEqual(field, 'bankAccountNumber')){
        if(!isEqual(get(lastFetchedNgrDetails, 'bankAccountNumber'), get(currentDetails, 'accountNumber')))
          isVerified = false;
      } else if(isEqual(field, 'bsbId')){
        if(!isEqual(get(lastFetchedNgrDetails, 'bsbId'), get(currentDetails, 'bsbNumber')))
          isVerified = false;
      } else if(isEqual(field, 'shareholderPercent')){
        if(!isEqual( parseFloat(get(lastFetchedNgrDetails, 'payeeSplit')), parseFloat(get(currentDetails, 'shareholderPercent'))))
          isVerified = false;
      } else if(isEqual(field, 'companyId')){
        if(!isEqual(get(lastFetchedNgrDetails, 'companyId'), get(currentDetails, 'companyId')))
          isVerified = false;
      }
    })
    return isVerified;
  }

  checkPortalFieldsUnchanged(selectedNgrBank, currentDetails) {
    let isVerified = this.props.selectedNgr?.isVerified;
    let fieldToValidate = ['ngrNumber', 'ngrType', 'bsbNumber', 'bankName' , 'accountName', 'accountNumber']

    if (isEqual(get(this.props.selectedNgr, 'ngrType'), 'shared'))
      fieldToValidate.push('shareholderPercent', 'companyId');

    forEach(fieldToValidate, field => {
      if (isEqual(field, 'ngrNumber')) {
        if (!isEqual(get(this.props.selectedNgr, 'ngrNumber'), this.state.fields.ngrNumber.value))
					isVerified = false;
      }
      else if (isEqual(field, 'ngrType')) {
        if (!isEqual(get(this.props.selectedNgr, 'ngrType'), this.state.fields.ngrType.value))
          isVerified = false;
      }
      else if (isEqual(field, 'bsbNumber')) {
        if (!isEqual(get(selectedNgrBank, 'bsbNumber'), get(currentDetails, 'bsbNumber')))
          isVerified = false;
      }
      else if (isEqual(field, 'bankName')) {
        if (!isEqual(get(selectedNgrBank, 'name'), get(currentDetails, 'name')))
          isVerified = false;
      }
      else if (isEqual(field, 'accountName')) {
				if (!isEqual(get(selectedNgrBank, 'accountName'), get(currentDetails, 'accountName')))
          isVerified = false;
      }
      else if (isEqual(field, 'accountNumber')) {
        if (!isEqual(get(selectedNgrBank, 'accountNumber'), get(currentDetails, 'accountNumber')))
          isVerified = false;
      }
      else if (isEqual(field, 'shareholderPercent')) {
        if (!isEqual( parseFloat(get(selectedNgrBank, 'shareholderPercent')), parseFloat(get(currentDetails, 'shareholderPercent'))))
          isVerified = false;
      }
      else if (isEqual(field, 'companyId')) {
        if (!isEqual(get(selectedNgrBank, 'companyId'), get(currentDetails, 'companyId')))
          isVerified = false;
	    }
    })
    return isVerified;
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleNGRNumberBlur = event => {
    event.persist()
    let value = event.target.value
    const isAdmin = isSystemCompany()
    if(isAdmin)
      this.onFieldBlur(event)
    else {
      if(value && value.toLowerCase().startsWith('unknown')){
        //pass
      } else {
        value = value.replaceAll(' ', '')
        value = value.toString().slice(0, 8)
      }
      this.setState({
        fields: {
          ...this.state.fields,
          [event.target.id]: {
            ...this.state.fields[event.target.id],
            value: value
          }
        }
      }, () => this.onFieldBlur(event))
    }
  }

  handleSelectChange(key, event, index, value) {
    this.setFieldValue(key, value);
  }

  setFieldValue(key, value) {
    const newState = { ...this.state };
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }


  getAllCompanies = async () => {
    let DirectoryCompanies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(this.props.token);
    DirectoryCompanies = uniqBy([...DirectoryCompanies, { id: this.props.user.companyId, name: this.props.user.company.name, abn: this.props.user.company.abn }], 'id');
    this.setState({ 'allCompanies': DirectoryCompanies });
  };


  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key),
        },
      },
    }));
  }

  setAllFieldsValuesBySelectedNgr() {
    if (this.props.selectedNgr) {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedNgr[key];
      });
      newState.fields.ngrNumber.validators = [required('NGR is required.')];
      newState.fields.shareAuthorityDoc.value = [];
      newState.shareholderCount = get(this.props, 'selectedNgr.bankAccounts.length');
      if (this.props.selectedNgr.shareAuthorityFile)
        newState.fields.shareAuthorityFileClone.value = this.props.selectedNgr.shareAuthorityFile.slice();
      else newState.fields.shareAuthorityFileClone.value = [];
      newState.sustainableCheck = !isEmpty(this.props.selectedNgr.sustainableDeclarations)
      if(newState.sustainableCheck) {
        const declarations = {}
        forEach(this.props.selectedNgr.sustainableDeclarations, declaration => {
          declarations[declaration.season] = get(declarations, declaration.season) || []
          declarations[declaration.season].push(declaration.commodityId)
        })
        newState.declarations = map(declarations, (commodityIds, season) => ({season: season, commodities: commodityIds }))
      }
      if (get(this.props, 'selectedNgr.bankAccounts.length') > 0) {
        this.bankAccounts = this.props.selectedNgr.bankAccounts.map(bankAccount => {
          return {
            name: bankAccount.name,
            bsbNumber: bankAccount.bsbNumber,
            accountName: bankAccount.accountName,
            accountNumber: bankAccount.accountNumber,
            shareholderPercent: bankAccount.shareholderPercent,
            companyId: bankAccount.companyId,
            entityName: bankAccount.entityName,
            isValid: this.isBankAccountValid(bankAccount),
            isPrimary: bankAccount.isPrimary,
          };
        });
      }
      this.setState(newState);
    }
  }

  getNgrSustainableDeclarations(ngr) {
    let declarations = [];
    let ngrDeclarations = get(ngr, 'sustainableDeclarations')
    if(ngr && !isEmpty(ngrDeclarations)) {
      forEach(ngrDeclarations, declaration => {
        let season = get(declaration, 'season');
        let groupedDeclarations = filter(ngrDeclarations, { season })
        if(!isEmpty(groupedDeclarations)) {
          let commodityNames = orderBy(map(groupedDeclarations, 'commodityName')).join(',')
          declarations.push(`(${commodityNames}) (${season})`);
        }
      })
    }
    return uniq(declarations);
  }

  isBankAccountValid = bank => {
    const isBankDetailsRequired = get(this.props, 'bankAccountDetailsMandatory', false) || get(bank, 'isMandatory', false);
    if (isBankDetailsRequired) {
      return (
        get(bank, 'name') &&
          get(bank, 'bsbNumber') &&
          get(bank, 'accountNumber') &&
          get(bank, 'shareholderPercent') &&
          ((isEqual(get(this.props, 'selectedNgr.ngrType'), 'shared') || isEqual(get(this.state.fields, 'ngrType.value'), 'shared')) ||
           (isEqual(get(this.props, 'selectedNgr.ngrType'),  'single') || isEqual(get(this.state.fields, 'ngrType.value'), 'single')))
      );
    } else {
      return true;
    }
  };

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    let totalShareholderPercent = 0;
    let isAbnDuplicate = [false, false, false, false, false];
    if (this.state.fields.ngrType.value.toLowerCase() === 'shared') {
      forEach(this.bankAccounts, (bankAccount, index) => {
        for (var i = index + 1; i < this.bankAccounts.length; i++) {
          if (!isEmpty(bankAccount.abn) && bankAccount.abn == this.bankAccounts[i].abn) {
            isAbnDuplicate[i] = true;
            isAbnDuplicate[index] = true;
          }
        }
        totalShareholderPercent +=
          isEmpty(bankAccount.shareholderPercent) && !isNumber(bankAccount.shareholderPercent) ? 0 : parseFloat(bankAccount.shareholderPercent);
      });
    }
    newState.totalShareholderPercent = totalShareholderPercent;
    newState.isAbnDuplicate = isAbnDuplicate;
    newState.bankValidationSignals.fill(true, 0, this.bankAccounts.length);
    this.setState(newState, callback);
  }

  canEditNgrDetails() {
    return isCompanyNgrsEditable(this.props.company?.id, this.props.company) || this.props.isRepresenting
  }

  isUnknownNGR() {
    const { selectedNgr } = this.props;
    return selectedNgr && get(selectedNgr, 'ngrNumber').match(/UNKNOWN_/);
  }

  canEditUnknown() {
    if (get(this.props.selectedNgr, 'company.transactionParticipation')) {
      if (this.props.selectedNgr.company.id == this.props.user.companyId)
        return false;
      else
        return true;
    }
    return false;
  }

  checkCurrentCompanyExist(currentCompanyAbn, allNgrsABN) {
    return includes(allNgrsABN, currentCompanyAbn)
  }

  fetchNgrDetails() {
    this.setState({fetchingNGRDetails: true}, () => {
      this.props.dispatch(isLoading('FetchNGRDetails'))
      APIService.companies().appendToUrl(`${get(this.state.ngrPortalDetails, 'companyId')}/ngrs/${this.state.fields.ngrNumber.value}/validate/`)
        .get().then(response => {
          this.setState({fetchingNGRDetails: false}, () => {
            this.props.dispatch(forceStopLoader())
            if(has(response, 'error')) {
              alertifyjs.error(response.error);
              return
            }
            let { ngrDetails } = response;
            if(ngrDetails.length > 1) {
              ngrDetails = ngrDetails.map(ngr => ({ ...ngr, payeeSplit: parseFloat(ngr.payeeSplit) }))
              ngrDetails = orderBy(ngrDetails, 'payeeSplit', 'desc');
              ngrDetails[0].isPrimary = true;
              let parsedBankAccounts = [];
              let allCompaniesABN = map(this.state.allCompanies, 'abn' );
              let allNgrsABN = map(ngrDetails, 'abn')
              if(!this.checkCurrentCompanyExist(get(this.props.company, 'abn'), allNgrsABN) || difference(compact(allNgrsABN), compact(allCompaniesABN)).length !== 0) {
                alertifyjs.error('ABN of the Primary/Secondary shareholder does not match. Please contact AgriChain Support', 10);
                return;
              }
              const maxShare = max(map(ngrDetails, ngr => ngr.payeeSplit))
              forEach(ngrDetails, (item, index) => {
                let selectedCompany = ''
                if (get(item, 'abn')) {
                  selectedCompany = get(find(this.state.allCompanies, { abn: get(item, 'abn') }), 'id')
                } else {
                  let nonEmptyAbnCompanies = reject(ngrDetails, ['abn', null]);
                  let existingCompany = find(this.state.allCompanies, {abn: get(first(nonEmptyAbnCompanies), 'abn')})
                  let excludeCompany = filter(this.bankAccounts, { companyId: get(existingCompany, 'id') });
                  if(!isEmpty(excludeCompany)) {
                    let previousCompany = first(this.bankAccounts.filter(item => get(item, 'companyId') !== first(excludeCompany).companyId));
                    selectedCompany = get(previousCompany, 'companyId');
                  }
                }
                const share = get(item, 'payeeSplit')
                item.isPrimary = share === maxShare;
                let bankAccountDetails = cloneDeep(this.bankAccounts[0]);
                bankAccountDetails.accountName = item.bankAccountName;
                bankAccountDetails.accountNumber = item.bankAccountNumber;
                let bankDetails = '';
                if(item.bankId) {
                  bankDetails = find(this.props.banks, { id: item.bankId });
                } else {
                  bankDetails = find(this.bankAccounts, { companyId: selectedCompany });
                }
                bankAccountDetails.name = get(bankDetails, 'name');
                bankAccountDetails.id = get(bankDetails, 'id');
                bankAccountDetails.bsbNumber = item.bsbId;
                bankAccountDetails.shareholderPercent = item.payeeSplit;
                bankAccountDetails.isPrimary = item.isPrimary;
                bankAccountDetails.companyId = selectedCompany;
                ngrDetails[index].companyId = bankAccountDetails.companyId;
                parsedBankAccounts.push(cloneDeep(bankAccountDetails));
                ngrDetails[index].ngrNumber = this.state.fields.ngrNumber.value;
                ngrDetails[index].ngrValue = 'shared';
              })
              this.bankAccounts = parsedBankAccounts;
              let newState = { ...this.state };
              newState.fields.ngrType.value = 'shared';
              newState.fetchNgrDetails = ngrDetails;
              this.setState(newState, () => this.setAllFieldsErrors());
            } else {
              let singleNgrDetail = ngrDetails[0];
              if(get(singleNgrDetail, 'abn') && !isEqual(get(singleNgrDetail, 'abn'), get(this.props.company, 'abn'))) {
                alertifyjs.error("ABN of the shareholder does not match. Please contact AgriChain Support", 10);
                return
              }
              let bankAccountDetails = this.bankAccounts[0];
              let newState = { ...this.state };
              newState.fields.ngrType.value = 'single';
              bankAccountDetails.accountName = singleNgrDetail?.bankAccountName;
              bankAccountDetails.accountNumber = singleNgrDetail?.bankAccountNumber;
              if (singleNgrDetail?.bankId) {
                let bankDetails = find(this.props.banks, { id: singleNgrDetail.bankId });
                bankAccountDetails.name = get(bankDetails, 'name');
                bankAccountDetails.id = get(bankDetails, 'id');
              }
              bankAccountDetails.bsbNumber = singleNgrDetail?.bsbId;
              bankAccountDetails.currentTime = moment()
              bankAccountDetails.isValid = Boolean(singleNgrDetail?.bankAccountNumber);
              this.bankAccounts = [bankAccountDetails];
              ngrDetails[0].ngrNumber = this.state.fields.ngrNumber.value;
              ngrDetails[0].ngrValue = this.state.fields.ngrType.value;
              newState.fetchNgrDetails = ngrDetails;
              this.setState(newState, () => this.setAllFieldsErrors());
            }
          })
        })
    })
  }

  handleSustainableCheckboxChange = () => {
    const newState = {...this.state};
    newState.sustainableCheck = !this.state.sustainableCheck;
    newState.declarations = [{...this.declarationModel}]
    this.setState(newState);
  }

  handleTagsChange = (id, selectedTags) => this.setState({fields: {...this.state.fields, tags: {...this.state.tags, value: map(selectedTags, 'id')}}})

  getNgrNumberLabel = () => {
    const isUnknownNGR = this.isUnknownNGR();
    let label = 'NGR'
    if(isUnknownNGR)
      label += ` - (${get(this.props.selectedNgr, 'ngrNumber')})`
    else if(this.props.selectedNgr?.isVerified)
      label += ` - Verified`
    return label
  }

  render() {
    const { fetchNgrDetails, ngrPortalDetails } = this.state
    const isUnknownNGR = this.isUnknownNGR();
    const maxFileCountReached = this.state.fields.shareAuthorityFileClone.value.length + this.state.fields.shareAuthorityDoc.value.length >= 5;
    const ngrSustainableDeclarations = this.getNgrSustainableDeclarations(this.props.selectedNgr)
    const isAdmin = isSystemCompany()
    const isDisabledBankAccountDetails = this.props.selectedNgr?.isVerified || (get(fetchNgrDetails, '0.ngrNumber') === this.state.fields.ngrNumber.value && this.state.fields.ngrNumber.value)
    const hasCredentialCompany = Boolean(ngrPortalDetails?.companyId)
    return (
      <form onSubmit={this.handleSubmit} noValidate style={{marginTop: '10px'}}>
        <div className='cardForm cardForm--drawer'>
          <div className='cardForm-content row trucks'>
            <div className='col-sm-6 form-wrap'>
              <CommonTextField
                autoFocus={!this.props.selectedNgr?.id}
                id='ngrNumber'
                label={this.getNgrNumberLabel()}
                placeholder='Please enter 8 digits NGR number'
                type={(this.props.selectedNgr || isAdmin)? '' : 'number'}
                helperText={this.state.fields.ngrNumber.errors[0]}
                onChange={this.handleFieldChange}
                onBlur={this.handleNGRNumberBlur}
                value={this.state.fields.ngrNumber.value}
                disabled={!isAdmin && (!isEmpty(this.props.selectedNgr) && !isUnknownNGR)}
                sx={{
                  '.MuiInputLabel-root' : {
                    color: this.props.selectedNgr?.isVerified ? `${PRIMARY_COLOR_GREEN} !important` : undefined
                  }
                }}
              />
              {
                !isSystemCompany() && get(this.state.ngrPortalDetails, 'isPortalLinked') && (isEqual(this.props.company?.isRegistered, false) || this.canEditNgrDetails()) &&
                  <CommonButton
                    type='button'
                    label='Fetch NGR Details'
                    variant='text'
                    size="small"
                    default
                    color="primary"
                    style={{margin: "0px 10px 10px 0", minWidth: "100px", textTransform: "none", paddingLeft: 0}}
                    disabled={!hasCredentialCompany || !isEqual(this.state.fields.ngrNumber.value.length, 8)}
                    onClick={this.fetchNgrDetails}
                  />
              }
            </div>
            <div className='col-sm-6 form-wrap'>
              <CommonSelect
                id='ngrType'
                floatingLabelText='NGR Type'
                selectedItemId={this.state.fields.ngrType.value}
                onChange={this.handleNgrTypeChanged}
                items={this.state.ngrTypes}
                value={this.state.fields.ngrType.value}
                selectConfig={{ text: 'name', value: 'id' }}
                disabled={ !isAdmin && (!isEmpty(this.props.selectedNgr) && !isUnknownNGR)}
              />
            </div>
          </div>
          {
            Boolean(this.props.selectedNgr && !isEmpty(get(this.props.selectedNgr, 'sustainableDeclarations'))) &&
              <div className='cardForm-content row trucks' style={{marginBottom: '20px', marginTop: '10px'}}>
                <div className="col-sm-1" style={{fontWeight: '400'}}>
                  eDOCS:
                </div>
                <div className="col-sm-8" style={{fontSize: "14px"}}>
                  {
                    ngrSustainableDeclarations.map((declaration, index) => (
                      <div className="row" style={{marginLeft: '5px', marginTop: '2px'}} key={index}>
                        SGA Self Declaration {declaration}
                      </div>
                    ))
                  }
                </div>
              </div>
          }
          {
            this.bankAccounts.map((bankAccount, index) => (
              <CompanyBankAccountForm
                key={index}
                banks={this.props.banks}
                ngrType={this.state.fields.ngrType.value}
                canAccessAny={this.props.canAccessAny}
                totalShareholderPercent={this.state.totalShareholderPercent}
                isAbnDuplicate={this.state.isAbnDuplicate[index]}
                startValidation={this.state.bankValidationSignals[index]}
                validated={this.onBankValidated}
                {...bankAccount}
                index={index}
                bankAccountDetailsMandatory={this.props.bankAccountDetailsMandatory}
                valueChanged={this.handleBankAccountChanged}
                disabled={isDisabledBankAccountDetails || (!this.canEditNgrDetails() && !isCurrentUserBelongsToCompany(this.props.selectedCompany?.id))}
                shareholderRemoved={this.removeShareholder}
                allCompanies={this.state.allCompanies}
                toBePercentage={this.toBePercentage}
                highestSharesPercentage={this.highestSharesPercentage}
              />
            ))
          }
          {
            this.state.fields.ngrType.value.toLowerCase() === 'shared' ?
              <div>
                <div className='cardForm-content row trucks'>
                  {
                    this.state.shareholderCount < 5 ?
                      <div className='col-sm-6' style={{ minHeight: '40px' }}>
                        <a
                          onClick={this.addShareholder}
                          style={{ color: PRIMARY_COLOR_GREEN, cursor: 'pointer', float: 'left' }}
                          disabled={isDisabledBankAccountDetails || !this.props.canAccessAny}
                        >
                          Add Shareholder
                        </a>
                      </div>
                    : null
                  }
                </div>
                <div className='cardForm-content row trucks'>
                  <div className='form-wrap'>
                    <FileUpload
                      id='shareDoc'
                      buttonText='Attach Share Authority'
                      disabled={!this.props.canAccessAny || maxFileCountReached}
                      onRemove={this.handleFileRemove}
                      onChange={this.loadImageFileAsURL}
                    />
                  </div>
                </div>
                <div className='cardForm-content row trucks'>
                  <div className='col-sm-12 form-wrap'>
                    {
                      this.state.fields.shareAuthorityFileClone.value.map((file, index) => (
                        <span key={index}>
                          <span>
                            <embed className='thumb' type='application/pdf' src={this.state.fields.shareAuthorityFile.value[index]} />
                            <span className='thumbnail-text'>{file.name}</span>
                          </span>
                          <span className='file-close' onClick={() => this.removeFile(index)}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
                              <path d='M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z' />
                            </svg>
                          </span>
                        </span>
                      ))
                    }
                  </div>
                </div>
              </div>
            : null
          }
          {
            isAdmin &&
              <div className='col-sm-12 form-wrap padding-reset' style={{marginBottom: '15px'}}>
                <CommonMultiSelect
                  id='tags'
                  items={TAGS}
                  selectedItems={this.state.fields.tags.value}
                  displayField='name'
                  onChange={this.handleTagsChange}
                  placeholder="Select Tag(s)"
                  label="Tags"
                />
              </div>
          }
          {
            isAdmin && get(this.props, 'isEdit') &&
              <div>
                <Checkbox checked={this.state.sustainableCheck} onChange={this.handleSustainableCheckboxChange} /> Sustainable Check
                {
                  this.state.sustainableCheck && (
                    <div className='col-xs-12 padding-reset'>
                      {
                        map(this.state.declarations, (declaration, index) => (
                          <div className='col-xs-12 padding-reset' key={index}>
                            <div className='col-xs-11 no-left-padding'>
                              <NgrDeclarationForm
                                index={index}
                                selectedSeason={declaration.season}
                                selectedCommodities={declaration.commodities}
                                allCommodities={this.props.commodities}
                                onChange={data => this.onDeclarationChange(data, index)}
                              />
                            </div>
                            {
                              index > 0 &&
                                <div className='col-xs-1 padding-reset' style={{marginTop: '30px'}}>
                                  <IconButton size='small' color='error' onClick={event => this.onClickDeleteDeclaration(event, index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                            }
                          </div>
                        )
                           )
                      }
                      <div className='col-xs-12 padding-reset' style={{marginTop: '15px'}}>
                        <Button size='small' variant='text' onClick={this.onClickAddNewDeclaration} color='primary' style={{textTransform: 'none'}}>
                          Add New Declaration
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
          }
          {
            this.canEditNgrDetails() ?
              <div className='col-sm-12 cardForm-action top15 padding-reset'>
                <CommonButton
                  type='button'
                  label='Cancel'
                  variant='outlined'
                  default
                  onClick={this.props.selectedNgr ? this.props.cancelEdit : this.props.closeDrawer}
                />
                <CommonButton primary={true} variant="contained" label='Save' type='submit' />
              </div>
            : null
          }
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const banks = state.main.banks || [];
  return {
    banks,
    serverErrors: state.companies.companyNgrs.serverErrors,
    user: state.main.user.user,
    token: state.main.user.token,
    isRepresenting: state.companies.companyNgrs.isCurrentUserRepresentingAnyParty,
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(CompanyNgrForm);
