import React from 'react';
import APIService from '../../services/APIService';
import { Dialog, DialogContent, DialogActions, Button, Checkbox } from '@mui/material/';
import { DialogTitleWithCloseIcon } from './DialogTitleWithCloseIcon';
import SideDrawer from './SideDrawer';
import CustomHeaderOptions from './CustomHeaderOptions';

const CUSTOM_HEADER_EDIT = {
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '10px'
};

class DownloadDataDialog extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        customColumns: true,
        customColumnNames: {},
        customHeaderOptions: false,
        searchView: false,
    }
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.user.id}/report-preferences/${this.props.csvType}/`).get(this.props.token);
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  toggleCustomColumnDownloads() {
    this.setState({customColumns: !this.state.customColumns})
    this.props.toggleCustomColumnDownloads()
  }

  render() {
    const { open, title, enableCustomCsv, searchView, isSearchApplied, isFilteredCsv, customColumnTitle, user, token, csvType } = this.props;
    return (
      <Dialog open={open} onClose={() => this.props.onClose()} aria-labelledby='form-dialog-title' fullWidth>
      <DialogTitleWithCloseIcon
        onClose={() => this.props.onClose()}
        closeButtonStyle={{ marginTop: '0px' }}
        id='form-dialog-title'>
        {title}
      </DialogTitleWithCloseIcon>
      <DialogContent style={{ marginTop: '15px' }}>
        {enableCustomCsv &&
        <React.Fragment>
        <span style={{float: 'left'}}>Select checkbox for custom csv download</span>
        <div className='col-sm-12 padding-reset' style={{marginTop: '10px'}}>
          <Checkbox
            id='custom-headers'
            checked={this.state.customColumns}
            style={{ height: '40px' }}
            onChange={this.toggleCustomColumnDownloads}
          />
          Custom Columns
          <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>
            View & Update
          </a>
        </div>
        </React.Fragment>
        }
        {
          isSearchApplied &&
          <div className='col-sm-12 padding-reset' style={{marginTop: enableCustomCsv ? '10px' : '0px'}}>
            <Checkbox
              id='current-view'
              checked={searchView}
              style={{ height: '40px' }}
              onChange={() => this.props.onSearchViewChange()}
            />
            {`${isFilteredCsv ? 'Filtered' : 'Complete'} View with search applied on "${isSearchApplied}"`}
          </div>
        }
        <SideDrawer
          isOpen={this.state.customHeaderOptions}
          title={customColumnTitle}
          onClose={() => this.setState({customHeaderOptions: false})}
          size="small"
        >
          <CustomHeaderOptions
            customColumns={this.state.customColumnNames}
            closeDrawer={() => this.setState({customHeaderOptions: false})}
            user={user}
            token={token}
            csv_type={csvType}
            updateColumnCount={count => this.props.updateColumnCount(count)}
          />
        </SideDrawer>
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          onClick={() => this.props.onClose()}
          variant='outlined'>
          Cancel
        </Button>
        <Button type='button' onClick={() => this.props.onDownload()} color='primary' variant='outlined'>
          Download
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

export default DownloadDataDialog;