import React from 'react';

import Employees from '../employees/Employees';
import Trucks from '../trucks/Trucks';
import CompanyNgrs from '../company-ngrs/CompanyNgrs';
import CompanyForm from '../companies/CompanyForm';
import Conditions from '../conditions/Conditions';
import Farms from '../farms/Farms';
import Shrinkages from '../farms/Shrinkages';
import Fees from '../farms/Fees';
import { connect } from 'react-redux';
import CompanySites from '../company-sites/CompanySites';
import CommonTabs from '../common/CommonTabs';
import '../common/commonTabs.scss';
import APIService from '../../services/APIService';
import '@babel/polyfill';
import { GROWER, ROOT_USER_IDS } from '../../common/constants';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { receiveCompanyDetails } from '../../actions/companies';
import { isSystemCompany, getCompanyIdFromCurrentRoute, currentUserCompany, showTargetMoistureTab, isCurrentUserBelongsToCompany, isCompanyAdminOrObserver } from '../../common/utils';
import SettingsButton from '../common/SettingsButton';
import SiteManagementSettingsForm from '../company-sites/SiteManagementSettingsForm';
import { setSettingButton, setSecondarySettingButton, isLoading, forceStopLoader } from '../../actions/main';
import { getSMSettings } from '../../actions/api/company-sites';
import SideDrawer from '../common/SideDrawer';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import Alerts from '../alerts/Alerts';
import TargetMoisture from '../farms/TargetMoisture';
import CompanyOverview from './CompanyOverview'
import { AppContext } from '../main/LayoutContext';

class CompanySettings extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      company: undefined,
      overview: undefined,
      activeTab: this.props.location.pathname,
      isFetchingSettings: false,
      settings: null,
      openSideDrawer: false,
      settingsView: false,
      ngrPortalDetails: undefined
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.onSettingsClick = this.onSettingsClick.bind(this);
    this.onCloseSettingsView = this.onCloseSettingsView.bind(this);
    this.handlePortalCredentials = this.handlePortalCredentials.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.state.company) && !this.props.serverErrorCode) {
      const companyId = parseInt(this.props.match.params.company_id);
      this.props.dispatch(isLoading())
      this.getCompanyDetails(companyId);
      let ngrCompanyId = '';
      if(isSystemCompany())
        ngrCompanyId = companyId;
      else
        ngrCompanyId = get(currentUserCompany(), 'id')
      this.getNgrPortalDetails(ngrCompanyId);
    }
  }

  getSMSettings() {
    if (this.state.company && !this.state.isFetchingSettings)
      this.setState({ isFetchingSettings: true }, () => {
        this.props.dispatch(getSMSettings(this.state.company.id));
      });
  }

  getSettingsButton() {
    return { button: <SettingsButton onClick={this.onSettingsClick} buttonText='SM Settings' /> };
  }

  async getCompanyDetails(id) {
    const { dispatch } = this.props;
    const company = await APIService.companies(id).get(this.props.userToken);
    dispatch(forceStopLoader())
    this.setState({
      company: company
    }, () => {
      if (isSystemCompany() && (parseInt(getCompanyIdFromCurrentRoute()) !== this.props.user.companyId)) {
        this.getSMSettings();
        this.props.dispatch(setSettingButton(this.getSettingsButton()));
      }
    });
    dispatch(receiveCompanyDetails(company));
    if(isSystemCompany())
      this.getCompanyOverview(id)
  }

  getCompanyOverview = id => APIService.companies(id).appendToUrl('overview/').get().then(response => this.setState({overview: response}))

  getNgrPortalDetails(id) {
    let portalDetails = {}

    APIService.companies().appendToUrl(`${id}/ngrs/credentials/`).requestUsingFetch('GET')
    .then(response => response.json())
    .then(portalCredentials => {
      if(!portalCredentials || isEqual(get(portalCredentials, 'detail'), 'Not found.'))
        portalDetails = { isPortalLinked: false };
      else {
        portalDetails = portalCredentials;
        portalDetails.isPortalLinked = true;
      }
      this.setState({
        ngrPortalDetails: portalDetails
      })
    })
  }

  componentDidUpdate() {
    if ((this.props.settings && !this.state.settings) || !isEqual(this.props.settings, this.state.settings))
      this.setState({ settings: this.props.settings });

    const companyId = parseInt(this.props.match.params.company_id);
    if (this.state.company && companyId !== this.state.company.id && !this.props.serverErrorCode) {
      this.getCompanyDetails(companyId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ? { ...prevState, activeTab: nextProps.location.pathname } : prevState;
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  handlePortalCredentials(portalDetails) {
    this.setState({
      ngrPortalDetails: portalDetails
    });
  }

  canNotEdit() {
    if (isSystemCompany()) {
      return false;
    }
    return (
      this.state.company &&
      this.state.company.typeId === 4 &&
        ROOT_USER_IDS.includes(this.state.company.createdById) &&
      this.state.company.id !== this.props.user.companyId
    );
  }

  onSettingsClick() {
    this.setState({ settingsView: true });
  }

  onCloseSettingsView() {
    this.setState({ settingsView: false });
  }

  componentWillUnmount() {
    if (isSystemCompany())
      this.props.dispatch(setSettingButton({ button: null }));

    this.props.dispatch(setSecondarySettingButton({ button: null }));
  }

  shouldShowSMSettingsForm() {
    return isSystemCompany() && this.state.settings;
  }

  shouldRenderTabContent = (tab, commonTabs) => Boolean(this.state.activeTab === tab.url && find(commonTabs, {label: tab.label}))

  render() {
    const { isMobileDevice } = this.context
    const companyId = parseInt(this.props.match.params.company_id);
    const PARENT_URL = this.props.match.url;
    var commonTabs = [{ label: 'Details', url: `${PARENT_URL}/details`, component: () => <CompanyForm companyId={companyId} /> }];

    const employeesTabs = [{ label: 'Employees', url: `${PARENT_URL}/employees`, component: () => <Employees companyId={companyId} /> }];

    const trucksTab = [{ label: 'Trucks', url: `${PARENT_URL}/trucks`, component: () => <Trucks companyId={companyId} /> }];

    var myCompanyTabs = [{ label: 'Conditions', url: `${PARENT_URL}/conditions`, component: () => <Conditions /> }];

    const ngrTabs = [{ label: 'NGRs', url: `${PARENT_URL}/ngrs`, component: () => <CompanyNgrs companyId={companyId} ngrPortalDetails={this.state.ngrPortalDetails} /> }];

    const sitesTabs = [{ label: 'Sites', url: `${PARENT_URL}/company-sites`, component: () => <CompanySites companyId={companyId} /> }];

    const FarmsTab = { label: 'Farms', url: `${PARENT_URL}/farms`, component: () => <Farms companyId={companyId} /> };

    const shrinkagesTab = { label: 'Shrinkages', url: `${PARENT_URL}/shrinkages`, component: () => <Shrinkages companyId={companyId} /> };
    const targetMoistureTab = { label: 'Target Moisture', url: `${PARENT_URL}/target-moisture`, component: () => <TargetMoisture companyId={companyId} /> };

    const feesTab = { label: 'Fees', url: `${PARENT_URL}/fees`, component: () => <Fees companyId={companyId} company={this.state.company} /> };

    const alertsTab = { label: 'Alerts', url: `${PARENT_URL}/alerts`, component: () => <Alerts companyId={companyId} /> };

    commonTabs = commonTabs.concat(employeesTabs);
    commonTabs = commonTabs.concat(trucksTab);
    commonTabs.splice(2, 0, ngrTabs[0]);
    if (this.state.company && this.state.company.typeId !== GROWER) {
      commonTabs = commonTabs.concat(sitesTabs);
    }

    if (this.state.company && this.state.company.typeId === GROWER) {
      commonTabs = commonTabs.concat([FarmsTab]);
    }

    let tabs = [...commonTabs];
    tabs = tabs.concat([showTargetMoistureTab() ? targetMoistureTab : shrinkagesTab]);
    tabs = tabs.concat([feesTab]);
    if ((isCurrentUserBelongsToCompany(companyId) && isCompanyAdminOrObserver()) || isSystemCompany())
      tabs = tabs.concat([alertsTab]);

    const planType = get(this.props.user, 'company.platformfeatures.planType');

    if (this.props.user.companyId === companyId) {
      if (planType === 'premium') {
        tabs = tabs.concat(myCompanyTabs);
      } else if (planType === 'logistics_lite') {
        tabs = [
          { label: 'Details', url: `${PARENT_URL}/details`, component: () => <CompanyForm companyId={companyId} /> },
          {
            label: 'Employees',
            url: `${PARENT_URL}/employees`,
            component: () => <Employees companyId={companyId} />,
          },
          { label: 'Trucks', url: `${PARENT_URL}/trucks`, component: () => <Trucks companyId={companyId} /> },
        ];
      }
    }

    if(isSystemCompany() && !tabs.some(tab => tab.label === 'Conditions'))
      tabs = tabs.concat(myCompanyTabs)

    const shouldShowOverview = !isMobileDevice && isSystemCompany()

    return (
      <div className='col-xs-12 padding-reset'>
        {
          shouldShowOverview && !isEmpty(this.state.overview) &&
            <div className='col-xs-12 padding-reset' style={{zIndex: 3, marginLeft: '-10px', marginTop: '-8px', width: 'calc(100% + 16px)', position: 'fixed'}}>
              <CompanyOverview
                overview={this.state.overview}
              />
            </div>
        }
        <div className='col-xs-12 tab padding-reset'>
          <div className='tab-header'>
            {
              this.state.company &&
                <CommonTabs value={this.props.location.pathname} tabs={tabs} tabsStyle={shouldShowOverview ? {top: '143px'} : {}} marginBottom={shouldShowOverview ? "110px" : undefined} />
            }
          </div>
          <div key={0} className='tab-content'>
            {this.shouldRenderTabContent(commonTabs[0], tabs) && <CompanyForm companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(employeesTabs[0], tabs) && <Employees companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(trucksTab[0], tabs) && <Trucks companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(FarmsTab, tabs) && <Farms isFromCompanySetting={true} companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(shrinkagesTab, tabs) && <Shrinkages companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(targetMoistureTab, tabs) && <TargetMoisture companyId={companyId} canEdit={!this.canNotEdit()} />}
            {this.shouldRenderTabContent(feesTab, tabs) && <Fees companyId={companyId} canEdit={!this.canNotEdit()} company={this.state.company} />}
            {this.shouldRenderTabContent(alertsTab, tabs) && <Alerts companyId={companyId}/>}
            {this.shouldRenderTabContent(myCompanyTabs[0], tabs) && (planType === 'premium' || isSystemCompany()) && <Conditions companyId={isSystemCompany() && companyId} />}
            {planType === 'premium'
             ? this.shouldRenderTabContent(ngrTabs[0], tabs) && (
               <CompanyNgrs
                  companyId={companyId}
                  currentUserCompanyId={this.props.user.companyId}
                  canEdit={!this.canNotEdit()}
                  ngrPortalDetails={this.state.ngrPortalDetails}
                  handlePortalCredentials={this.handlePortalCredentials}
                  company={this.state.company}
                />
             )
             : null}
            {this.state.company && this.state.company.typeId !== GROWER && planType === 'premium'
             ? this.shouldRenderTabContent(sitesTabs[0], tabs) && <CompanySites isFromCompanySetting={true} companyId={companyId} canEdit={!this.canNotEdit()} />
             : null}
          </div>
        </div>
        {this.shouldShowSMSettingsForm() && (
           <SideDrawer isOpen={this.state.settingsView} title='Site Management Settings' onClose={this.onCloseSettingsView} size='big'>
             <SiteManagementSettingsForm
               handleClose={this.onCloseSettingsView}
               companyId={get(this.state, 'company.id')}
               settings={this.state.settings}
               statusColors={this.state.settings.colors}
               postSubmit={this.onCloseSettingsView}
             />
           </SideDrawer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
  userToken: state.main.user.token,
  serverErrorCode: state.main.serverErrorCode,
  settings: state.companies.companySites.settings,
});

export default connect(mapStateToProps, null)(CompanySettings);
