import React, { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { get, includes, isEmpty, map, startCase, set, find, forEach, orderBy, mapValues } from 'lodash';
import { COMMODITIES, FIELD, OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, OPTION_TYPE_STORAGE_UPDATE_LOAD, STOCK_UPDATE_OPTION_TYPE, STRICT_COMMODITY_BASED_UNITS } from '../../common/constants';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonTextField from '../common/CommonTextField';
import { positiveDecimalFilter } from '../../common/input-filters';
import { Button } from '@mui/material';
import ContentRemove from '@mui/icons-material/Remove';
import { forceStopLoader, isLoading } from '../../actions/main';
import alertifyjs from 'alertifyjs';
import { useDispatch } from 'react-redux';
import { currentUserCompany, generateIdentifier, getCountryDisplayUnit, getCountryLabel, getDateTimeFromUTC, isSystemCompany } from '../../common/utils';
import CommonButton from '../common/CommonButton';
import SpecParametersValue from '../common/SpecParametersValue';
import { required, valueBetween } from '../../common/validators';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonTimePicker from '../common/CommonTimePicker';

let ManageStorageAndOwnershipStocks = props => {

  const [stockLoads, setStockLoads] = useState([]);
  const [storagesLoads, setStorageLoads] = useState([]);
  const [manageOwnership, setManageOwnership] = useState(false);
  const [manageStorages, setManageStorages] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [farmStorages, setFarmStorages] = useState([]);
  const [stockUpdateMetaData, setStockUpdateMetaData] = useState({});
  const [stockUpdateSpecs, setStockUpdateSpecs] = useState({});
  const [loadCount, setLoadsCount] = useState(0);
  const [comment, setComment] = useState('');
  const [stocksBeforeDate, setStocksBeforeDate] = useState('');
  const [stocksBeforeTime, setStocksBeforeTime] = useState('');

  const isStorageEmpty = get(props.load, 'optionType') === OPTION_TYPE_STORAGE_EMPTY_LOAD;
  const isStorageStockUpdate = get(props.load, 'optionType') === OPTION_TYPE_STORAGE_UPDATE_LOAD;
  const isStockEmpty = get(props.load, 'optionType') === OPTION_TYPE_STOCK_EMPTY_LOAD;
  const isStockUpdate = get(props.load, 'optionType') === OPTION_TYPE_STOCK_UPDATE_LOAD;
  const displayUnit = getCountryDisplayUnit();


  const dispatch = useDispatch();

  useEffect(() => {
    fetchLoads()
    fetchStockUpdateMetaData()
    fetchCompanies()
  }, []);

  let fetchCompanies = () => {
    APIService.companies()
      .appendToUrl('directory/names/?excludeGroups=true')
      .get()
      .then(companies => setAllCompanies([...companies, currentUserCompany()]));
  }

  let fetchStockUpdateMetaData = () => {
    APIService.stocks()
      .appendToUrl(`meta/${get(props.load, 'extras.identifier')}/`)
      .get()
      .then(response => {
        setStockUpdateMetaData(response);
        setComment(get(response, 'comment'));
        if (get(response, 'stocksBeforeDateTime')) {
          const localDateTime = getDateTimeFromUTC(get(response, 'stocksBeforeDateTime'));
          setStocksBeforeDate(localDateTime.date);
          setStocksBeforeTime(localDateTime.time);
        }
        if (isStorageStockUpdate) {
          let specs = getCommoditySpec(get(response, 'commoditySpecs'));
          let responseSpecs = get(response, 'specs');
          Object.keys(responseSpecs).forEach(spec => {
            set(specs, `${spec.toUpperCase()}.value`, parseFloat(get(responseSpecs, spec).toFixed(2)))
          })
          setStockUpdateSpecs(specs);
        }
      });
  }

  let fetchLoads = () => {
    dispatch(isLoading('UpdateStorageFiltersLoader'));
    APIService.loads()
      .appendToUrl(`?serialize=true&exclude_void=${props.load?.status !== 'void'}&extras__identifier__iexact=${get(props.load, 'extras.identifier')}`)
      .get()
      .then(async loads => {
        dispatch(forceStopLoader());
        if (!isEmpty(loads)) {
          setLoadsCount(loads.length);
          let isStockUpdate = undefined;
          if (props.load.optionType === STOCK_UPDATE_OPTION_TYPE)
            isStockUpdate = loads.some(obj => obj.ngrId !== null);
          if (includes([OPTION_TYPE_STORAGE_EMPTY_LOAD, OPTION_TYPE_STORAGE_UPDATE_LOAD], props.load.optionType) || (props.load.optionType === STOCK_UPDATE_OPTION_TYPE && !isStockUpdate)) {
            const promises = [];
            const tonnagePromises = [];
            let aggregatedLoads = Object.values(loads.reduce((accumulator, currentLoad) => {
              const key = `${currentLoad.commodityId}-${currentLoad.gradeId}-${currentLoad.season}-${currentLoad.storageId}-${currentLoad.type}`;
              if (accumulator[key])
                accumulator[key].tonnage += currentLoad.estimatedNetWeight;
              else {
                accumulator[key] = {
                  ...currentLoad,
                  "commodityId": currentLoad.commodityId,
                  "gradeId": currentLoad.gradeId,
                  "season": currentLoad.season,
                  "storageId": currentLoad.storageId,
                  "farmId": currentLoad.farmId,
                  "tonnage": currentLoad.estimatedNetWeight,
                  "type": currentLoad.type,
                  "commodityName": currentLoad.commodityName,
                  "gradeName": currentLoad.gradeName,
                  "storageName": currentLoad.storageName,
                  "unit": displayUnit || currentLoad.commodityUnit
                };
                delete accumulator[key]['id'];
                if (props.load.optionType === STOCK_UPDATE_OPTION_TYPE)
                  accumulator[key]['optionType'] = OPTION_TYPE_STORAGE_UPDATE_LOAD
              }
              return accumulator;
            }, {}));
            aggregatedLoads.map((aggregatedLoad, index) => {
              aggregatedLoad['ngrDistribution'] = []
              aggregatedLoad['tonnageDisplayValue'] = `${aggregatedLoad.tonnage} ${aggregatedLoad.unit}`
              let id = 1;
              let totalDistributedTonnage = 0
              loads.forEach(load => {
                if (load.ngrId && load.commodityId === aggregatedLoad.commodityId && load.gradeId === aggregatedLoad.gradeId
                    && load.season === aggregatedLoad.season && load.storageId === aggregatedLoad.storageId && load.type === aggregatedLoad.type) {
                      aggregatedLoad['ngrDistribution'].push({'id': id, 'stockOwnerId': load.stockOwnerId, 'ngrId': load.ngrId, 'tonnage': load.estimatedNetWeight, 'errors': ''});
                      totalDistributedTonnage += load.estimatedNetWeight;
                      id += 1
                    }
              });
              if (isEmpty(aggregatedLoad['ngrDistribution']))
                aggregatedLoad['ngrDistribution'].push({'id': id, 'stockOwnerId': undefined, 'ngrId': undefined, 'tonnage': undefined, 'errors': ''})
              aggregatedLoad['remainingTonnage'] = aggregatedLoad.tonnage - totalDistributedTonnage;
              aggregatedLoad['id'] = index + 1;
            });
            aggregatedLoads.forEach(aggregatedLoad => {
              aggregatedLoad.ngrDistribution.forEach(ngrDistributionObj => {
                if (ngrDistributionObj.stockOwnerId) {
                  const promise = APIService.companies(ngrDistributionObj.stockOwnerId).appendToUrl('ngrs/minimal/').get();
                  promises.push(promise);
                  promise.then(response => {
                    ngrDistributionObj.ngrs = response;
                  });
                }
                if (ngrDistributionObj.ngrId) {
                  let query_params = `commodity_ids=${aggregatedLoad.commodityId}&ngr_id=${ngrDistributionObj.ngrId}`;
                  if (aggregatedLoad.gradeId)
                    query_params += `&grade_id=${aggregatedLoad.gradeId}`
                  if (aggregatedLoad.season)
                    query_params += `&season=${aggregatedLoad.season}`
                  if (aggregatedLoad.farmId)
                    query_params += `&farm_ids=${aggregatedLoad.farmId}`
                  const promise = APIService.loads().appendToUrl(`stocks/tonnage/?${query_params}`).get();
                  tonnagePromises.push(promise);
                  promise.then(response => {
                    ngrDistributionObj.availableTonnage = aggregatedLoad.type == 'outload' ? get(response, 'tonnage') + ngrDistributionObj.tonnage : get(response, 'tonnage') - ngrDistributionObj.tonnage;
                  });
                }
              })
            });
            await Promise.all(promises);
            await Promise.all(tonnagePromises);
            setStorageLoads([...aggregatedLoads])
            setManageOwnership(true);
          }
          else if (includes([OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD], props.load.optionType) || (props.load.optionType === STOCK_UPDATE_OPTION_TYPE && isStockUpdate)) {
            const tonnagePromises = [];
            let farmId = get(loads, '0.farmId');
            if (farmId) {
              APIService.farms(farmId)
                .appendToUrl('storages/home/?no_stocks&no_aggregations&no_relations')
                .get()
                .then(response => setFarmStorages(response));
            }
            let aggregatedLoads = Object.values(loads.reduce((accumulator, currentLoad) => {
              const key = `${currentLoad.commodityId}-${currentLoad.gradeId}-${currentLoad.season}-${currentLoad.ngrId}-${currentLoad.type}`;              
              if (accumulator[key]) {
                accumulator[key].tonnage += currentLoad.estimatedNetWeight;
                if (currentLoad.isGateStorage)
                  accumulator[key]['storageId'] = currentLoad.storageId
              }
              else {
                let obj = {...currentLoad};
                delete obj['storageId'];
                accumulator[key] = {
                  ...obj,
                  "commodityId": currentLoad.commodityId,
                  "gradeId": currentLoad.gradeId,
                  "season": currentLoad.season,
                  "tonnage": currentLoad.estimatedNetWeight,
                  "type": currentLoad.type,
                  "commodityName": currentLoad.commodityName,
                  "gradeName": currentLoad.gradeName,
                  "storageName": currentLoad.storageName,
                  "unit": displayUnit || currentLoad.commodityUnit,
                  "ngrId": currentLoad.ngrId,
                  "ngrNumber": currentLoad.ngrNumber
                };
                delete accumulator[key]['id'];
                if (currentLoad.isGateStorage)
                  accumulator[key]['storageId'] = currentLoad.storageId
                if (props.load.optionType === STOCK_UPDATE_OPTION_TYPE)
                  accumulator[key]['optionType'] = OPTION_TYPE_STOCK_UPDATE_LOAD
              }
              return accumulator;
            }, {}));
            aggregatedLoads.map((aggregatedLoad, index) => {
              aggregatedLoad['storageDistribution'] = []
              aggregatedLoad['tonnageDisplayValue'] = `${aggregatedLoad.tonnage} ${aggregatedLoad.unit}`
              let id = 1;
              let totalDistributedTonnage = 0
              loads.forEach(load => {
                if (!load.isGateStorage && load.ngrId && load.commodityId === aggregatedLoad.commodityId && load.gradeId === aggregatedLoad.gradeId
                    && load.season === aggregatedLoad.season && load.ngrId === aggregatedLoad.ngrId && load.type === aggregatedLoad.type) {
                      aggregatedLoad['storageDistribution'].push({'id': id, 'storageId': load.storageId, 'tonnage': load.estimatedNetWeight, 'errors': ''});
                      totalDistributedTonnage += load.estimatedNetWeight;
                      id += 1
                    }
              });
              if (isEmpty(aggregatedLoad['storageDistribution']))
                aggregatedLoad['storageDistribution'].push({'id': id, 'storageId': undefined, 'tonnage': undefined, 'errors': ''})
              aggregatedLoad['remainingTonnage'] = aggregatedLoad.tonnage - totalDistributedTonnage;
              aggregatedLoad['id'] = index + 1;
            });
            const resultLoads = aggregatedLoads.map(obj => {
              const matchingElement = aggregatedLoads.find(
                el =>
                  el.id !== obj.id &&
                  el.commodityId === obj.commodityId &&
                  el.gradeId === obj.gradeId &&
                  el.season === obj.season &&
                  el.type !== obj.type
              );
              const counterLoadId = matchingElement ? matchingElement.id : null;
              const isCounterLoad = obj.ngrId === null;
              return {
                ...obj,
                counterLoadId,
                isCounterLoad,
              };
            });
            resultLoads.forEach(resultLoad => {
              resultLoad.storageDistribution.forEach(storageDistributionObj => {
                if (storageDistributionObj.storageId) {
                  const promise = APIService.loads().appendToUrl(`stocks/tonnage/?commodity_ids=${resultLoad.commodityId}&storage_id=${storageDistributionObj.storageId}`).get();
                  tonnagePromises.push(promise);
                  promise.then(response => {
                    storageDistributionObj.availableTonnage = get(response, 'tonnage') + storageDistributionObj.tonnage;
                  });
                }
              })
            });
            await Promise.all(tonnagePromises);
            setStockLoads([...resultLoads])
            setManageStorages(true);
          }
        }
      })
  }

  let updateStorageLoads = async (value, storageLoadRow, ngrDistributionRow, property, pullNgrs) => {
    const promises = []
    storagesLoads.forEach(obj => {
      if (obj.id === storageLoadRow.id) {
        obj.ngrDistribution.forEach(ngrDistributionObj => {
          if (ngrDistributionObj.id === ngrDistributionRow.id) {
            let currentValue = get(ngrDistributionObj, property)
            let isInitializing = property === 'ngrId' && value === ngrDistributionObj.ngrId;
            set(ngrDistributionObj, property, value);
            let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, obj?.unit) ? 'quantity' : 'tonnage';
            if (value && (ngrDistributionObj.stockOwnerId && ngrDistributionObj.ngrId && ngrDistributionObj.tonnage) && !includes(ngrDistributionObj.errors, tonnageLabel))
              set(ngrDistributionObj, 'errors', '');
            if (!ngrDistributionObj.stockOwnerId && !ngrDistributionObj.ngrId && !ngrDistributionObj.tonnage && !includes(ngrDistributionObj.errors, tonnageLabel))
              set(ngrDistributionObj, 'errors', '');
            if (property === 'tonnage') {
              let remainingTonnage = currentValue ? obj.remainingTonnage + parseFloat(currentValue) : obj.remainingTonnage
              obj.remainingTonnage = value ? parseFloat((remainingTonnage - parseFloat(value)).toFixed(2)) : remainingTonnage
              if (obj.remainingTonnage < 0)
                set(ngrDistributionObj, 'errors', `Nominated ${tonnageLabel} cannot be greater than total ${tonnageLabel}`);
              else if (includes(ngrDistributionObj.errors, tonnageLabel))
                set(ngrDistributionObj, 'errors', '');
            }
            if (value && pullNgrs) {
              const promise = APIService.companies(value).appendToUrl('ngrs/minimal/').get();
                promises.push(promise);
                promise.then(response => {
                  ngrDistributionObj.ngrs = response;
                });
            }
            if (property === 'ngrId' && !isInitializing) {
              if (value) {
                let query_params = `commodity_ids=${obj.commodityId}&ngr_id=${value}`;
                if (obj.gradeId)
                  query_params += `&grade_id=${obj.gradeId}`
                if (obj.season)
                  query_params += `&season=${obj.season}`
                if (obj.farmId)
                  query_params += `&farm_ids=${obj.farmId}`
                const promise = APIService.loads().appendToUrl(`stocks/tonnage/?${query_params}`).get();
                promises.push(promise);
                promise.then(response => {
                  ngrDistributionObj.availableTonnage = get(response, 'tonnage');
                });
              }
              else
                ngrDistributionObj.availableTonnage = 0;
            }
            if (!value && property === 'stockOwnerId')
              ngrDistributionObj.ngrs = [];
          }
        });
      }
    });
    await Promise.all(promises);
    setStorageLoads([...storagesLoads])
  };

  let setStockOwner = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'stockOwnerId', true);

  let setStockOwnerNgr = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'ngrId', false);

  let setNgrDistributionTonnage = (value, storageLoadRow, ngrDistributionRow) => updateStorageLoads(value, storageLoadRow, ngrDistributionRow, 'tonnage', false);

  let handleRemoveNgrDistributions = (storageLoadRow, ngrDistributionRow) => {
    storagesLoads.forEach(storagesLoad => {
      if (storagesLoad.id === storageLoadRow.id) {
        storagesLoad.ngrDistribution = storagesLoad.ngrDistribution.filter(obj => obj.id !== ngrDistributionRow.id).map((obj, index) => ({ ...obj, id: index + 1 }));
        const totalTonnage = storagesLoad.ngrDistribution.reduce((sum, ngrDistributionObj) => {
          const tonnage = parseFloat(ngrDistributionObj.tonnage);
            return !isNaN(tonnage) ? sum + tonnage : sum;
          }, 0);
        storagesLoad.remainingTonnage = parseFloat((storagesLoad.tonnage - totalTonnage).toFixed(2));
        storagesLoad.ngrDistribution.forEach(obj => {
          if (storagesLoad.remainingTonnage > 0 && includes(obj.error, 'tonnage'))
            obj.error = ''
        })
      }
    });
    setStorageLoads([...storagesLoads])
  }

  let addNgrDistribution = (storageLoadRow) => {
    storagesLoads.forEach(obj => {
      if (obj.id === storageLoadRow.id)
        obj.ngrDistribution.push({'id': obj.ngrDistribution.length + 1, 'stockOwnerId': undefined, 'ngrId': undefined, 'tonnage': undefined})
    });
    setStorageLoads([...storagesLoads])
  }

  let updateStockLoads = async (value, stockLoadRow, storageDistributionRow, property) => {
    const promises = [];
    stockLoads.forEach(obj => {
      if (obj.id === stockLoadRow.id) {
        obj.storageDistribution.forEach(storageDistributionObj => {
          if (storageDistributionObj.id === storageDistributionRow.id) {
            let currentValue = get(storageDistributionObj, property)
            let isInitializing = property === 'storageId' && value === storageDistributionObj.storageId;
            set(storageDistributionObj, property, value);
            let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, obj?.unit) ? 'quantity' : 'tonnage';
            if (value && (storageDistributionObj.storageId && storageDistributionObj.tonnage) && !includes(storageDistributionObj.errors, tonnageLabel))
              set(storageDistributionObj, 'errors', '');
            if (!storageDistributionObj.storageId && !storageDistributionObj.tonnage && !includes(storageDistributionObj.errors, tonnageLabel))
              set(storageDistributionObj, 'errors', '');
            if (property === 'tonnage') {
              let remainingTonnage = currentValue ? obj.remainingTonnage + parseFloat(currentValue) : obj.remainingTonnage
              obj.remainingTonnage = value ? parseFloat((remainingTonnage - parseFloat(value)).toFixed(2)) : remainingTonnage
              if (obj.remainingTonnage < 0)
                set(storageDistributionObj, 'errors', `Nominated ${tonnageLabel} cannot be greater than total ${tonnageLabel}`);
              else if (includes(storageDistributionObj.errors, tonnageLabel))
                set(storageDistributionObj, 'errors', '');
            }
            if (property === 'storageId' && !isInitializing) {
              if (value) {
                const promise = APIService.loads().appendToUrl(`stocks/tonnage/?commodity_ids=${obj.commodityId}&storage_id=${value}`).get(props.token, {'REFERER-UNIT' : getCountryDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true});
                promises.push(promise);
                promise.then(response => {
                  storageDistributionObj.availableTonnage = get(response, 'tonnage');
                });
              }
              else
                storageDistributionObj.availableTonnage = 0;
            }
          }
        });
      }
    });
    await Promise.all(promises);
    setStockLoads([...stockLoads]);
  }

  let setStorage = (value, stockLoadRow, storageDistributionRow) => updateStockLoads(value, stockLoadRow, storageDistributionRow, 'storageId', true);

  let setStorageDistributionTonnage = (value, stockLoadRow, storageDistributionRow) => updateStockLoads(value, stockLoadRow, storageDistributionRow, 'tonnage', false);

  let addStorageDistribution = (stockLoadRow) => {
    stockLoads.forEach(obj => {
      if (obj.id === stockLoadRow.id)
        obj.storageDistribution.push({'id': obj.storageDistribution.length + 1, 'storageId': undefined, 'tonnage': undefined})
    });
    setStockLoads([...stockLoads]);
  }

  let handleRemoveStorageDistributions = (stockLoadRow, storageDistributionRow) => {
    stockLoads.forEach(stockLoad => {
      if (stockLoad.id === stockLoadRow.id) {
        stockLoad.storageDistribution = stockLoad.storageDistribution.filter(obj => obj.id !== storageDistributionRow.id).map((obj, index) => ({ ...obj, id: index + 1 }))
        const totalTonnage = stockLoad.storageDistribution.reduce((sum, storageDistributionObj) => {
          const tonnage = parseFloat(storageDistributionObj.tonnage);
            return !isNaN(tonnage) ? sum + tonnage : sum;
          }, 0);
        stockLoad.remainingTonnage = parseFloat((stockLoad.tonnage - totalTonnage).toFixed(2));
        stockLoad.storageDistribution.forEach(obj => {
          if (stockLoad.remainingTonnage > 0 && includes(obj.error, 'tonnage'))
            obj.error = ''
        });
      }
    });
    setStockLoads([...stockLoads]);
  }

  let handleSubmit = () => {
    if (manageOwnership) {
      let canProceed = true;
      storagesLoads.forEach(storagesLoad => {
        storagesLoad.ngrDistribution.forEach(obj => {
          if (obj.errors)
            canProceed = false;
          else {
            const isAnyFieldPresent = obj.stockOwnerId || obj.ngrId || obj.tonnage;
            if (isAnyFieldPresent) {
              if (!obj.stockOwnerId || !obj.ngrId || !obj.tonnage) {
                obj.errors = 'This field is required';
                canProceed = false
              }
            }
          }
        })
      })
      let specs = {}
      if (isStorageStockUpdate) {
        const isSpecsInvalid = validateSpecs()
        if(!isSpecsInvalid) {
          forEach(stockUpdateSpecs, (specValue, specCode) => {
            set(specs, `${specCode.toUpperCase()}`, specValue.value);
          })
          setStockUpdateSpecs(specs)
        }
        else
          canProceed = false;
      }
      if (canProceed) {
        let loadsData = []
        let identifier = get(props.load, 'extras.identifier') || generateIdentifier('load');
        let identifierSuffix = loadCount + 1;
        storagesLoads.forEach(storagesLoad => {
          let loadObj = {
            'commodityId': storagesLoad.commodityId, 'date_time': storagesLoad.dateTime, 'option_type': storagesLoad.optionType,
            'grade_id': storagesLoad.gradeId, 'season': storagesLoad.season, 'source': storagesLoad.source,
            'created_by_id': storagesLoad.createdById, 'updated_by_id': storagesLoad.createdById, 'skip_shrinkage': storagesLoad.skipShrinkage,
            'storage_id': storagesLoad.storageId, 'variety_id': storagesLoad.varietyId, 'specs': storagesLoad.specs || {},
            'extras': {'identifier': identifier, 'comment': ''}, 'identifier': identifier + `_${identifierSuffix}`, 'type': storagesLoad.type
          }
          if (storagesLoad.type === 'inload' && isStorageStockUpdate)
            loadObj['specs'] = specs;
          let totalDistributedTonnage = 0;
          storagesLoad.ngrDistribution.forEach(obj => {
            if (obj.stockOwnerId && obj.ngrId && obj.tonnage)  {
              loadsData.push({
                ...loadObj,
                'ngr_id': obj.ngrId,
                'identifier': identifier + `_${identifierSuffix}`,
                'estimated_net_weight': parseFloat(parseFloat(obj.tonnage).toFixed(2))
              });
              totalDistributedTonnage += parseFloat(obj.tonnage);
              identifierSuffix += 1;
            }
          });
          if (storagesLoad.tonnage - totalDistributedTonnage > 0) {
            loadsData.push({
              ...loadObj,
              'ngr_id': null,
              'identifier': identifier + `_${identifierSuffix}`,
              'estimated_net_weight': parseFloat((storagesLoad.tonnage - totalDistributedTonnage).toFixed(2))
            });
            identifierSuffix += 1;
          }
        });
        let data = {
          'loads': loadsData,
          'identifier': identifier,
          'storage_ids': get(stockUpdateMetaData, 'storageIds'),
          'commodity_ids': get(stockUpdateMetaData, 'commodityIds'),
          'grade_ids': get(stockUpdateMetaData, 'gradeIds'),
          'variety_ids': get(stockUpdateMetaData, 'varietyIds'),
          'ngr_ids': get(stockUpdateMetaData, 'ngrIds'),
          'seasons': get(stockUpdateMetaData, 'seasons'),
          'tonnage': get(stockUpdateMetaData, 'tonnage'),
          'quantity': get(stockUpdateMetaData, 'quantity'),
          'specs': specs,
          'status': 'completed',
          'load_id_to_void': props.load.id,
          'comment': comment
        }
        dispatch(isLoading('UpdateStorageFiltersLoader'));
        APIService.stocks()
          .appendToUrl(`meta/`)
          .post(data)
          .then(response => {
            dispatch(forceStopLoader());
            if (get(response, '0.id')) {
              props.onClose();
              alertifyjs.success("Load updated successfully", 3, () => window.location.reload());
            }
            else
              alertifyjs.error('Something went wrong!');
          });
      }
      else
        setStorageLoads([...storagesLoads])
    }
    else if (manageStorages) {
      let canProceed = true;
      stockLoads.forEach(stockLoad => {
        stockLoad.storageDistribution.forEach(obj => {
          if (obj.errors)
            canProceed = false;
          else {
            const isAnyFieldPresent = obj.storageId || obj.tonnage;
            if (isAnyFieldPresent) {
              if (!obj.storageId || !obj.tonnage) {
                obj.errors = 'This field is required';
                canProceed = false
              }
            }
          }
        })
      })
      if (canProceed) {
        let loadsData = []
        let identifier = get(props.load, 'extras.identifier') || generateIdentifier('load');
        let identifierSuffix = loadCount + 1;
        stockLoads.forEach(stockLoad => {
          let loadObj = {
            'commodityId': stockLoad.commodityId, 'date_time': stockLoad.dateTime, 'option_type': stockLoad.optionType,
            'grade_id': stockLoad.gradeId, 'season': stockLoad.season, 'source': stockLoad.source,
            'created_by_id': stockLoad.createdById, 'updated_by_id': stockLoad.createdById, 'skip_shrinkage': stockLoad.skipShrinkage,
            'ngr_id': stockLoad.ngrId, 'variety_id': stockLoad.varietyId, 'specs': stockLoad.specs || {},
            'extras': {'identifier': identifier, 'comment': ''}, 'type': stockLoad.type
          }
          if (!stockLoad.isCounterLoad) {
            let totalDistributedTonnage = 0;  
            stockLoad.storageDistribution.forEach(obj => {
              if (obj.storageId && obj.tonnage)  {
                loadsData.push({
                  ...loadObj,
                  'storage_id': obj.storageId,
                  'identifier': identifier + `_${identifierSuffix}`,
                  'estimated_net_weight': parseFloat(parseFloat(obj.tonnage).toFixed(2))
                });
                totalDistributedTonnage += parseFloat(obj.tonnage);
                identifierSuffix += 1;
              }
            });
            if (stockLoad.tonnage - totalDistributedTonnage > 0) {
              loadsData.push({
                ...loadObj,
                'storage_id': stockLoad.storageId,
                'identifier': identifier + `_${identifierSuffix}`,
                'estimated_net_weight': parseFloat((stockLoad.tonnage - totalDistributedTonnage).toFixed(2))
              });
              identifierSuffix += 1;
            }
          }
          else {
            let relatedLoad = find(stockLoads, {id: stockLoad.counterLoadId});
            if (relatedLoad) {
              const totalDistributedTonnage = relatedLoad.storageDistribution.reduce((sum, storageDistributionObj) => {
                const tonnage = parseFloat(storageDistributionObj.tonnage);
                  return !isNaN(tonnage) ? sum + tonnage : sum;
                }, 0);
              if (relatedLoad.tonnage - totalDistributedTonnage > 0) {
                loadsData.push({
                  ...loadObj,
                  'ngr_id': stockLoad.ngrId,
                  'storage_id': stockLoad.storageId,
                  'identifier': identifier + `_${identifierSuffix}_only_storage`,
                  'estimated_net_weight': parseFloat(parseFloat(relatedLoad.tonnage - totalDistributedTonnage).toFixed(2))
                });
                identifierSuffix += 1;
              }
            }
          }
        })
        dispatch(isLoading('UpdateStorageFiltersLoader'));
        let data = {
          'loads': loadsData,
          'identifier': identifier,
          'storage_ids': get(stockUpdateMetaData, 'storageIds'),
          'farm_id': get(stockUpdateMetaData, 'farmId'),
          'commodity_ids': get(stockUpdateMetaData, 'commodityIds'),
          'grade_ids': get(stockUpdateMetaData, 'gradeIds'),
          'variety_ids': get(stockUpdateMetaData, 'varietyIds'),
          'ngr_ids': get(stockUpdateMetaData, 'ngrIds'),
          'seasons': get(stockUpdateMetaData, 'seasons'),
          'tonnage': get(stockUpdateMetaData, 'tonnage'),
          'quantity': get(stockUpdateMetaData, 'quantity'),
          'specs': get(stockUpdateMetaData, 'specs'),
          'status': 'completed',
          'load_id_to_void': props.load.id,
          'comment': comment
        }
        APIService.stocks()
          .appendToUrl(`meta/`)
          .post(data)
          .then(response => {
            dispatch(forceStopLoader());
            if (get(response, '0.id')) {
              props.onClose();
              alertifyjs.success("Stock updated successfully", 3, () => window.location.reload());
            }
            else
              alertifyjs.error('Something went wrong!');
          });
      }
      else
        setStockLoads([...stockLoads]);
    }
  }

  let voidLoad = () => {
    const { load } = props;
    if(get(load, 'farmStocksManagement') && get(currentUserCompany(), 'id') != get(load, 'farmCompanyId') && !isSystemCompany()){
      alertifyjs.error("Inturns into and Outturns from this site can only be created/edited by the company employees. Please contact the site manager for creating this load.", 5);
    } else {
      alertifyjs.confirm(
        'Warning',
        'Load will be marked Void. Do you want to proceed?',
        () => {
          dispatch(isLoading('UpdateStorageFiltersLoader'));
          APIService
            .loads(load.id)
            .appendToUrl('void/')
            .put().then(() => {
              dispatch(forceStopLoader());
              alertifyjs.success('Load Deleted');
              window.location.reload();
              props.onClose();
            });
        },
        () => { },
      );
    }
  }

  let getCommoditySpec = commoditySpecs => {
    const modelSpecs = {};
    if (!isEmpty(commoditySpecs)) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (get(stockUpdateMetaData, 'commodityIds.0') === COMMODITIES.CANOLA && includes(['COIL', 'IMPU'], spec.code))
          validators.push(required());
        modelSpecs[spec.code] = {
          ...FIELD,
          value: '',
          validators,
        };
      });
    }
    return modelSpecs;
  }

  let setFieldErrors = (obj, path) => {
    const errors = [];
    const value = get(obj, `${path}.value`);
    const validators = get(obj, `${path}.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  let handleSpecsChange = (specCode, specValue) => {
    let specs = {...stockUpdateSpecs}, errors = [];
    set(specs, `${specCode}.value`, specValue);
    setStockUpdateSpecs(specs);

    errors = setFieldErrors(stockUpdateSpecs, specCode);
    set(specs, `${specCode}.errors`, errors);
    setStockUpdateSpecs(specs);
  }

  let validateSpecs = () => {
    let specs = {...stockUpdateSpecs}, isSpecsInvalid = false;
    forEach(stockUpdateSpecs, (specValue, specCode) => {
      let errors = [];
      errors = setFieldErrors(stockUpdateSpecs, specCode);
      if(!isEmpty(errors))
        isSpecsInvalid = true;
      set(specs, `${specCode}.errors`, errors)
    })
    setStockUpdateSpecs(specs);
    return isSpecsInvalid;
  }

  return (
    <div>
      <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
        <CommonTextField
          id='farm'
          label='Farm/Site'
          value={get(stockUpdateMetaData, 'farmName')}
          disabled
        />
      </div>
      {(isStockEmpty || isStockUpdate) &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='stockOwners'
            label='Stock Owners'
            value={get(stockUpdateMetaData, 'stockOwners')}
            disabled
          />
        </div>
      }
      {(isStockEmpty || isStockUpdate) &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='ngrs'
            label='Ngrs'
            value={get(stockUpdateMetaData, 'ngrNumbers')}
            disabled
          />
        </div>
      }
      {(isStorageEmpty || isStorageStockUpdate) &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='storages'
            label='Storages'
            value={get(stockUpdateMetaData, 'storageNames')}
            disabled
          />
        </div>
      }
      <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
        <CommonTextField
          id='commodity'
          label='Commodities'
          value={get(stockUpdateMetaData, 'commodityNames')}
          disabled
        />
      </div>
      <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
        <CommonTextField
          id='grade'
          label='Grades'
          value={get(stockUpdateMetaData, 'gradeNames')}
          disabled
        />
      </div>
      {(isStorageStockUpdate || isStockUpdate) &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='variety'
            label='Varieties'
            value={get(stockUpdateMetaData, 'varietyNames')}
            disabled
          />
        </div>
      }
      <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
        <CommonTextField
          id='season'
          label='Seasons'
          value={get(stockUpdateMetaData, 'seasons')}
          disabled
        />
      </div>
      {isStorageEmpty &&
      <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
        <div className='col-md-6' style={{paddingLeft: '0px'}}>
          <CommonDatePicker
            id="stocks_before_date"
            floatingLabelText="Stocks Before Date"
            value={stocksBeforeDate}
            disabled
          />
        </div>
        <div className='col-md-6' style={{paddingRight: '0px'}}>
          <CommonTimePicker
            id="stocks_before_time"
            floatingLabelText="Stocks Before Time"
            value={stocksBeforeTime}
            disabled
          />
        </div>
      </div>
      }
      {(isStorageStockUpdate || isStockUpdate) && get(stockUpdateMetaData, 'quantityBasedCommodity') &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='quantity'
            label="Quantity"
            value={get(stockUpdateMetaData, 'quantityDisplayValue')}
            disabled
          />
        </div>
      }
      {(isStorageStockUpdate || isStockUpdate) &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <CommonTextField
            id='tonnage'
            label={getCountryLabel('tonnage')}
            value={get(stockUpdateMetaData, get(stockUpdateMetaData, 'tonnage') ? 'tonnageDisplayValue' : 'quantityDisplayValue')}
            disabled
          />
        </div>
      }
      {isStorageStockUpdate &&
        <div className='col-md-12' style={{paddingLeft: '0px', paddingBottom: '15px'}}>
          <SpecParametersValue
            commoditySpecs={get(stockUpdateMetaData, 'commoditySpecs')}
            fieldsSpecs={stockUpdateSpecs}
            onChange={handleSpecsChange}
            className="col-sm-4 form-wrap"
            style={{paddingLeft: '0px'}}
            errorTexts={mapValues(stockUpdateSpecs, spec => get(spec, 'errors[0]', ''))}
          />
        </div>
      }
      <div className="col-md-12 padding-reset" style={{ marginBottom: "10px" }}>
        <CommonTextField
          id="comment"
          label="Comment (Optional)"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          multiline={true}
          rows={2}
          rowsMax={2}
        />
      </div>
      {manageOwnership && !isEmpty(storagesLoads) &&
        <div>
          <span>Nominate ownership in following loads:</span><br/>
          {
            map(storagesLoads, row => {
              let ngrDistribution = row.ngrDistribution;
              let heading = `${startCase(row.type)} | ${row.storageName} | ${row.commodityName}`
              if (row.gradeName)
                heading += ` | ${row.gradeName}`;
              if (row.season)
                heading += ` | ${row.season}`;
              heading += ` | ${row.tonnageDisplayValue}`;
              let remainingTonnageLabel = `${row.remainingTonnage || 0} ${row.unit}`;
              let allocatedTonnageLabel = `${row.tonnage - row.remainingTonnage || 0} ${row.unit}`;
              let tonnageDetail = `(Allocated: ${allocatedTonnageLabel} | Remaining: ${remainingTonnageLabel})`
              return (
                <div style={{marginTop: '20px'}}>
                <div className='col-md-12' style={{paddingLeft: '0px'}}>{heading}</div>
                <div className='col-md-12' style={{paddingLeft: '0px'}}>{tonnageDetail}</div>
                {
                  map(ngrDistribution, (ngrDistributionRow, index) => {
                    let ngrs = get(ngrDistributionRow, 'ngrs', []);
                    let tonnageLabel = get(ngrDistributionRow, 'strictQuantityBased') ? 'Quantity' : 'Tonnage'
                    return (
                      <React.Fragment>
                      <div className='col-md-12' style={{paddingLeft: '0px', marginTop: '15px', paddingRight: '0px'}}>
                        <div className='col-md-4' style={{paddingLeft: '0px', paddingRight: '10px'}}>
                          <CommonAutoSelect
                            id="stockOwner"
                            label="Stock Owner"
                            value={ngrDistributionRow.stockOwnerId}
                            dataSourceConfig={{text: 'name', value: 'id'}}
                            onChange={(value) => setStockOwner(value, row, ngrDistributionRow)}
                            errorText={ngrDistributionRow.stockOwnerId ? '' : includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                            items={allCompanies}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                        <div className='col-md-3' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                          <CommonAutoSelect
                            id="stockOwnerNgr"
                            label="Ngr"
                            value={ngrDistributionRow.ngrId}
                            dataSourceConfig={{text: 'ngrNumber', value: 'id'}}
                            onChange={(value) => setStockOwnerNgr(value, row, ngrDistributionRow)}
                            errorText={ngrDistributionRow.ngrId ? '' : includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                            items={ngrs}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                        <div className='col-md-2' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                          <CommonTextField
                            id="tonnage"
                            label={tonnageLabel}
                            value={ngrDistributionRow.tonnage}
                            onChange={(event) => setNgrDistributionTonnage(event.target.value, row, ngrDistributionRow)}
                            helperText={ngrDistributionRow.tonnage && !includes(ngrDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : ngrDistributionRow.errors}
                            onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                        <div className='col-md-2' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                          <CommonTextField
                            id="availableTonnage"
                            label={`Available ${tonnageLabel}`}
                            value={get(ngrDistributionRow, 'availableTonnage') || 0}
                            variant='outlined'
                            size='small'
                            lockIconStyle={{ top: '10px' }}
                            disabled
                          />
                        </div>
                        <div className='col-md-1' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <Button
                            className='btn-minus'
                            mini
                            id='ngrDistributions'
                            variant='fab'
                            secondary={true}
                            onClick={() => handleRemoveNgrDistributions(row, ngrDistributionRow)}
                            disabled={ngrDistribution.length === 1}
                          >
                            <ContentRemove />
                          </Button>
                        </div>
                      </div>
                        {index === ngrDistribution.length - 1 &&
                          <Button
                            id='ngrs'
                            variant='outlined'
                            onClick={() => addNgrDistribution(row)}
                            style={{ float: 'left', marginTop: '15px', marginBottom: '30px' }}
                          >Add Ownership</Button>
                        }
                      </React.Fragment>
                    )
                  })
                }
                </div>
              )
            })
          }
        </div>
      }
      {manageStorages && !isEmpty(stockLoads) &&
          <div>
            <span>Nominate storages in following loads:</span><br/>
            {
              map(stockLoads.filter(obj => !obj.isCounterLoad), row => {
                let storageDistribution = row.storageDistribution;
                let heading = `${startCase(row.type)} | ${row.commodityName}`
                if (row.ngrNumber)
                  heading += ` | ${row.ngrNumber}`;
                if (row.gradeName)
                  heading += ` | ${row.gradeName}`;
                if (row.season)
                  heading += ` | ${row.season}`;
                heading += ` | ${row.tonnageDisplayValue}`;
                let remainingTonnageLabel = `${row.remainingTonnage || 0} ${row.unit}`;
                let allocatedTonnageLabel = `${row.tonnage - row.remainingTonnage || 0} ${row.unit}`;
                let tonnageDetail = `(Allocated: ${allocatedTonnageLabel} | Remaining: ${remainingTonnageLabel})`
                let tonnageLabel = includes(STRICT_COMMODITY_BASED_UNITS, row.unit) ? 'Quantity' : 'Tonnage'
                return (
                  <div style={{marginTop: '20px'}}>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{heading}</div>
                  <div className='col-md-12' style={{paddingLeft: '0px'}}>{tonnageDetail}</div>
                  {
                    map(storageDistribution, (storageDistributionRow, index) => {
                      return (
                        <React.Fragment>
                        <div className='col-md-12' style={{paddingLeft: '0px', marginTop: '15px', paddingRight: '0px'}}>
                          <div className='col-md-5' style={{paddingLeft: '0px', paddingRight: '10px'}}>
                            <CommonAutoSelect
                              id="storage"
                              label="Storage"
                              value={storageDistributionRow.storageId}
                              dataSourceConfig={{text: 'name', value: 'id'}}
                              onChange={(value) => setStorage(value, row, storageDistributionRow)}
                              errorText={storageDistributionRow.storageId ? '' : includes(storageDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : storageDistributionRow.errors}
                              items={farmStorages}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-3' style={{paddingLeft: '10px', paddingRight: '20px'}}>
                            <CommonTextField
                              id="tonnage"
                              label={tonnageLabel}
                              value={storageDistributionRow.tonnage}
                              onChange={(event) => setStorageDistributionTonnage(event.target.value, row, storageDistributionRow)}
                              helperText={storageDistributionRow.tonnage && !includes(storageDistributionRow.errors, tonnageLabel.toLowerCase()) ? '' : storageDistributionRow.errors}
                              onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                              variant='outlined'
                              size='small'
                            />
                          </div>
                          <div className='col-md-3' style={{paddingLeft: '10px', paddingRight: '0px'}}>
                            <CommonTextField
                              id="availableTonnage"
                              label={`Available ${tonnageLabel}`}
                              value={get(storageDistributionRow, 'availableTonnage') || 0}
                              variant='outlined'
                              size='small'
                              disabled
                              lockIconStyle={{ top: '10px' }}
                            />
                          </div>
                          <div className='col-md-1' style={{paddingLeft: '20px', paddingRight: '0px'}}>
                          <Button
                              className='btn-minus'
                              mini
                              id='storageDistributions'
                              variant='fab'
                              secondary={true}
                              onClick={() => handleRemoveStorageDistributions(row, storageDistributionRow)}
                              disabled={storageDistribution.length === 1}
                            >
                              <ContentRemove />
                            </Button>
                          </div>
                        </div>
                          {index === storageDistribution.length - 1 &&
                            <Button
                              id='storages'
                              variant='outlined'
                              onClick={() => addStorageDistribution(row)}
                              style={{ float: 'left', marginTop: '15px', marginBottom: '30px' }}
                            >Add Storage</Button>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        }
      {(!isEmpty(stockLoads) || !isEmpty(storagesLoads)) &&
       <div style={{float: "right", marginTop: "50px"}}>
         {
             props.load?.status !== 'void' &&
             <CommonButton
               label='Delete'
               default={true}
               variant='outlined'
               onClick={voidLoad}
             />
         }
            <Button type='button' onClick={props.onClose} variant='outlined' color='default'>
              Cancel
            </Button>
            <Button style={{marginLeft: '5px'}} type='button' onClick={handleSubmit} color='primary' variant='outlined'>
              Save
            </Button>
          </div>
        }
    </div>
  )
}

export default ManageStorageAndOwnershipStocks;
