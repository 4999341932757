import React from 'react';
import CompanyTooltip from './CompanyTooltip'

const Company = ({ company }) => {
  return (
    <CompanyTooltip company={company}>
      <a target='_blank' href={`#/companies/${company.id}/details`} rel='noreferrer noopener'>
        {company?.name}
      </a>
    </CompanyTooltip>
  )
}

export default Company;
