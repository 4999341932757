import React from 'react';
import ContractIcon from '../common/icons/Contract';
import TransactionBase from '../common/TransactionBase'

const Contract = ({ contract, noLink }) => {
  return (
    <TransactionBase
      icon={<ContractIcon width="34" height="34" fill='#000' noStyle />}
      sx={{
        '.MuiListItemIcon-root': {minWidth: 'auto', marginRight: '4px'},
      }}
      transaction={contract}
      url={`#/contracts/${contract.id}/contract`}
      noLink={noLink}
    />
  )
}

export default Contract
