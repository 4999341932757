import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../common/icons.scss';
import { withRouter } from 'react-router-dom';
import { isLoading, loaded } from '../../actions/main';
import { isSystemCompany, stocksHref, getCountryLabel } from '../../common/utils';
import { every, camelCase, includes } from 'lodash';
import './LeftPanel.scss';
import PanelControl from './PanelControl';
import Tooltip from '@mui/material/Tooltip';
import { HOME } from '../../common/constants';
import { AppContext } from '../main/LayoutContext'


class LeftPanel extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props);

    this.tabs = [
      'actionCentre',
      'companies',
      'farms',
      'contracts',
      'movements',
      'orders',
      'titleTransfers',
      'stocks',
      'invoices',
      'siteManagement',
      'siteBookings',
      'vendorDecs',
      'cashBoard',
      'scheduler',
      'dashboard',
    ];

    this.state = {
      activeTab: null,
    };
    this.setActiveTab(this.props.location);

    const noLoaderViaRouteChange = [
      '/$',
      '/new$',
      '/confirm$',
      '/reject$',
      '/accept$',
      '/contract$',
      '/title-transfers$',
      '/order$',
      '/details$',
      '/notes$',
      '/change-password$',
      '/accept-reject$',
      '/pending-request$',
      '/simplesignup$',
      '/sign-up$',
      '/forgot-password$',
      '/edit$',
      '/action-centre$',
      '/action-centre/movements$',
      '/action-centre/vendor-dec$',
      '/action-centre/orders$',
      '/action-centre/invoices$',
      '/([0-9]+)/stocks$',
      '/([0-9]+)/stocks/beta$',
      '/([0-9]+)/home/stocks$',
      '/([0-9]+)/home/storages-view$',
      '/([0-9]+)/home/ownership-view$',
      '/([0-9]+)/loads$',
      '/downloads$',
      '/site-bookings$',
      '/stocks$',
      '/stocks/beta$',
      '/stocks/storages-view$',
      '/stocks/ownership-view$',
      '/stocks/beta/storages-view$',
      '/stocks/beta/ownership-view$',
      '/stocks/beta/containers$',
      '/submit/success',
      '/stocks/other-stocks-view$',
      '/stocks/beta/other-stocks-view$',
      '/cash-board/cash-prices',
      '/freights/scheduler',
      '/mobile-messages',
      'companies/([0-9]+)/sso/azure/login',
      'companies/([0-9]+)/sso/azure/callback',
      '/stocks/stock-swaps$',
      '/stocks/beta/stock-swaps$',
      '/invoices/warehouse/dashboard$',
      'companies/([0-9]+)/merge',
      '/invoices/payable',
      '/invoices/payment-runs',
      '/invoices/payment-runs/:payment_run_id([0-9]+)',
      '/invoices/:invoice_id([0-9]+)/details/canola',
      '/dockets',
      '/alerts',
      '/dashboard',
      '/forgot-username'
    ];

    this.props.history.listen((location) => {
      this.setActiveTab(location);
      const isWhitelistedRoute = every(noLoaderViaRouteChange, (route) => {
        const re = new RegExp(route);
        return location.pathname.match(re) === null;
      }) && !location.search;
      if (isWhitelistedRoute) {
        this.props.dispatch(isLoading('genericTableWithData'));
      } else {
        this.props.dispatch(loaded());
      }
    });
  }

  setActiveTab(location) {
    if (location.pathname === HOME && isSystemCompany()){
      setTimeout(() => this.setState({ activeTab: 'companies' }), 100);
    }
    const routeParts = location.pathname.split('/').filter(p => p);
    const activeTab = camelCase(routeParts[routeParts[0] === 'freights' ? 1 : 0]);
    if (this.state.activeTab !== activeTab && includes(this.tabs, activeTab)) {
      setTimeout(() => this.setState({ activeTab: activeTab }), 100);
    }
  }

  isNavCollapsed() {
    return this.props.toggleNav;
  }

  render() {
    const panelControls = this.props.isLoggedIn && (
      <div style={{display: 'inline-block', width: '100%'}}>
        <PanelControl href='#/action-centre' feature="actionCentre" displayText={getCountryLabel('actionCentre')} activeTab={this.state.activeTab} />
        <PanelControl href='#/dashboard' feature="dashboard" displayText="Dashboard" activeTab={this.state.activeTab} />
        <PanelControl feature="companies" activeTab={this.state.activeTab} />
        <PanelControl feature="farms" activeTab={this.state.activeTab} />
        <PanelControl href={stocksHref()} feature="stocks" activeTab={this.state.activeTab} />
        <PanelControl href="#/cash-board/cash-prices" feature="cashBoard" activeTab={this.state.activeTab} />
        <PanelControl feature="contracts" activeTab={this.state.activeTab} />
        <PanelControl href="#/orders/freights" feature="orders" displayText="Orders" activeTab={this.state.activeTab} />
        <PanelControl href="#/movements/freights" feature="movements" displayText="Movements" activeTab={this.state.activeTab} />
        <PanelControl href="#/title-transfers" feature="titleTransfers" displayText="Title Transfers" activeTab={this.state.activeTab} />
        <PanelControl feature="invoices" activeTab={this.state.activeTab} />
        <PanelControl href="#/vendor-decs" feature="vendorDecs" displayText='Vendor Decs' activeTab={this.state.activeTab} />
        <PanelControl href="#/site-management" feature="siteManagement" displayText='Site Management' activeTab={this.state.activeTab} />
        <PanelControl href="#/site-bookings" feature="siteBookings" displayText='Site Bookings' activeTab={this.state.activeTab} />
        <PanelControl href="#/freights/scheduler" feature="freightScheduler" displayText='Freight Scheduler' activeTab={this.state.activeTab} />
      </div>
    );


    return (
      <div className="left-bar">
        {panelControls}
        <Tooltip title={this.isNavCollapsed() ? 'Expand' : 'Collapse'} placement="top">
          <button className="btn-pinned" onClick={this.props.toggleNavHandler}></button>
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: !!state.main.user.user,
  isLoading: state.main.isLoading,
  toggles: state.main.toggles,
});

export default withRouter(connect(mapStateToProps)(LeftPanel));
