export const MASS_LIMIT_BREACH_ALERT = "Mass Limit Breach";
export const RECEIVAL_REPORT = "Receival Report";
export const DELIVERY_CONFIRMATION_SMS_ALERT = "Delivery Confirmation - SMS";
export const PICKUP_CONFIRMATION_SMS_ALERT = "Pickup Confirmation - SMS"
export const CLASS_1_MATERIAL_ALERT = "Class 1 material";
export const PICKUP_CONFIRMATION_ALERT = "Pickup Confirmation - Email";
export const DELIVERY_CONFIRMATION_ALERT = "Delivery Confirmation - Email";
export const OUTLOAD_REPORT_ALERT = "Outload Report";
export const SLOT_BOOKING_UPDATE_ALERT = "Slot Booking Updates";
export const MISSING_DOCKET_ALERT = "Missing Docket Alert for Freight Providers";
export const MISSING_DOCKET_PICKUP_SITE_ALERT = "Missing Docket Alert for Pickup Site";
export const TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT = 'Truck Cleanliness Declaration Not Filled';
export const DOCKET_CLARIFICATION_ALERT = 'Docket Clarification';
export const STOCK_OPERATIONS_UPDATE_ALERT = 'Stock Operations Update';
export const STOCK_AUTO_UPDATE_ALERT = 'Stock Auto Update';
export const ACQUISITION_REPORT_ALERT = 'Acquisition Report';
export const BULK_INVOICE_ALERT = 'Automatic Invoice Emails';
export const NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT = 'New Cash Price For My Stocks';
export const LEADING_CASH_PRICE_WARNING_ALERT = 'Leading Cash Price Warning';
export const SPEC_AVG_REPORT_ALERT_CODE = "spec_avg_report";
export const MASS_LIMIT_BREACH_ALERT_CODE = "mass_limit";
export const DELIVERY_CONFIRMATION_SMS_ALERT_CODE = "inload_done";
export const PICKUP_CONFIRMATION_SMS_ALERT_CODE = "pickup_confirmation_sms";
export const CLASS_1_MATERIAL_ALERT_CODE = "class_1_material";
export const PICKUP_CONFIRMATION_ALERT_CODE = "pickup_information";
export const DELIVERY_CONFIRMATION_ALERT_CODE = "delivery_information";
export const OUTLOAD_REPORT_ALERT_CODE = "outload_report";
export const SLOT_BOOKING_UPDATE_ALERT_CODE = "slot_booking_alert";
export const MISSING_DOCKET_ALERT_CODE = 'missing_docket_alert';
export const MISSING_DOCKET_PICKUP_SITE_ALERT_CODE = 'missing_docket_pickup_site_alert';
export const TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE = 'truck_cleanliness_declaration_alert';
export const DOCKET_CLARIFICATION_ALERT_CODE = 'docket_clarification_alert';
export const STOCK_OPERATIONS_UPDATE_ALERT_CODE = 'stock_operations_update_alert';
export const STOCK_AUTO_UPDATE_ALERT_CODE = 'stock_auto_update_alert';
export const ACQUISITION_REPORT_ALERT_CODE = 'acquisition_report_alert'
export const BULK_INVOICE_ALERT_CODE = 'bulk_invoice_alert'
export const NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT_CODE = 'new_cash_price_for_my_stocks_alert'
export const LEADING_CASH_PRICE_WARNING_ALERT_CODE = 'leading_cash_price_warning_alert'
export const FREQUENCY_DAILY = 'daily';
export const FREQUENCY_WEEKLY = 'weekly';
export const FREQUENCY_MONTHLY = 'monthly';
export const FREQUENCEY_DAILY_AND_WEEKLY = 'daily_and_weekly'
export const MASS_LIMIT_ALERT_DESCRIPTION = "OverWeight Pickup/Delivery at my site";
export const RECEIVAL_REPORT_DESCRIPTION = "Total Receivals of the day at your site";
export const DELIVERY_CONFIRMATION_SMS_ALERT_DESCRIPTION = "Send SMS when Inload is done at my site";
export const PICKUP_CONFIRMATION_SMS_ALERT_DESCRIPTION = "Send SMS when Pickup is done from my site";
export const CLASS_1_MATERIAL_ALERT_DESCRIPTION = "Alerts to notify the site if the truck has carried a dangerous Class 1 material as one of its previous 3 loads";
export const PICKUP_CONFIRMATION_ALERT_DESCRIPTION = "Alerts to notify the parties about pickup information from your site";
export const DELIVERY_CONFIRMATION_ALERT_DESCRIPTION = "Alerts to notify the parties about delivery information at your site";
export const OUTLOAD_REPORT_ALERT_DESCRIPTION = "Get daily updates of all the outloads completed at your sites";
export const SLOT_BOOKING_UPDATE_ALERT_DESCRIPTION = "Get instant updates every time a booking is created or modified on your site";
export const MISSING_DOCKET_ALERT_DESCRIPTION = "Get a weekly email with all the missing dockets for movements where you are the freight provider";
export const MISSING_DOCKET_PICKUP_SITE_ALERT_DESCRIPTION = "Get a weekly email with all the missing dockets for movements where you are the pickup site.";
export const TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_DESCRIPTION = "Get notified when a truck cleanliness declaration is not filled in advance of a planned movement";
export const DOCKET_CLARIFICATION_ALERT_DESCRIPTION = "Get alerts when there is an issue on a docket attached to your movement";
export const STOCK_OPERATIONS_UPDATE_ALERT_DESCRIPTION = "Get an email whenever a stock operation is performed at your site.";
export const STOCK_AUTO_UPDATE_ALERT_DESCRIPTION = "Get an email whenever stocks at your site are auto updated due to change in stock owner company business type.";
export const ACQUISITION_REPORT_ALERT_DESCRIPTION = 'Total Acquisition of the day';
export const BULK_INVOICE_ALERT_DESCRIPTION = 'Automatic emails on creation of invoices in bulk via Payable, Receivable and Warehouse Dashboard.';
export const NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT_DESCRIPTION = 'Get alerted when a leading cash price is posted for your stock';
export const LEADING_CASH_PRICE_ALERT_DESCRIPTION = 'Get alerted when you are no longer the leading cash price on a site for given stock combination';

export let ALERT_NAMES = [
  {name: MASS_LIMIT_BREACH_ALERT, id: MASS_LIMIT_BREACH_ALERT_CODE},
  {name: RECEIVAL_REPORT, id: SPEC_AVG_REPORT_ALERT_CODE},
  {name: DELIVERY_CONFIRMATION_SMS_ALERT, id: DELIVERY_CONFIRMATION_SMS_ALERT_CODE},
  {name: CLASS_1_MATERIAL_ALERT, id: CLASS_1_MATERIAL_ALERT_CODE},
  {name: PICKUP_CONFIRMATION_ALERT, id: PICKUP_CONFIRMATION_ALERT_CODE},
  {name: DELIVERY_CONFIRMATION_ALERT, id: DELIVERY_CONFIRMATION_ALERT_CODE},
  {name: OUTLOAD_REPORT_ALERT, id : OUTLOAD_REPORT_ALERT_CODE},
  {name: SLOT_BOOKING_UPDATE_ALERT, id : SLOT_BOOKING_UPDATE_ALERT_CODE},
  {name: MISSING_DOCKET_ALERT, id : MISSING_DOCKET_ALERT_CODE},
  {name: MISSING_DOCKET_PICKUP_SITE_ALERT, id: MISSING_DOCKET_PICKUP_SITE_ALERT_CODE},
  {name: TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT, id: TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE},
  {name: DOCKET_CLARIFICATION_ALERT, id: DOCKET_CLARIFICATION_ALERT_CODE},
  {name: STOCK_OPERATIONS_UPDATE_ALERT, id: STOCK_OPERATIONS_UPDATE_ALERT_CODE},
  {name: STOCK_AUTO_UPDATE_ALERT, id: STOCK_AUTO_UPDATE_ALERT_CODE},
  {name: PICKUP_CONFIRMATION_SMS_ALERT, id: PICKUP_CONFIRMATION_SMS_ALERT_CODE},
  {name: ACQUISITION_REPORT_ALERT, id: ACQUISITION_REPORT_ALERT_CODE},
  {name: BULK_INVOICE_ALERT, id: BULK_INVOICE_ALERT_CODE},
  {name: NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT, id: NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT_CODE},
  {name: LEADING_CASH_PRICE_WARNING_ALERT, id: LEADING_CASH_PRICE_WARNING_ALERT_CODE},
];

export const ALERT_ALREADY_CONFIGURED_ERRORS = "This alert type is already configured";

export const EMAIL_ALERTS = [
  MASS_LIMIT_BREACH_ALERT_CODE, SPEC_AVG_REPORT_ALERT_CODE, CLASS_1_MATERIAL_ALERT_CODE, MISSING_DOCKET_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE,
  PICKUP_CONFIRMATION_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_CODE, BULK_INVOICE_ALERT_CODE,
  TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_CODE, STOCK_OPERATIONS_UPDATE_ALERT_CODE, STOCK_AUTO_UPDATE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE
];
export const SMS_ALERTS = [DELIVERY_CONFIRMATION_SMS_ALERT_CODE, PICKUP_CONFIRMATION_SMS_ALERT_CODE];
export const MOBILE_PUSH_NOTIFICATION_ALERTS = [NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT_CODE, LEADING_CASH_PRICE_WARNING_ALERT_CODE];
export const OWN_COMPANY_RECIPIENT_ALERTS = [
  MASS_LIMIT_BREACH_ALERT_CODE, SPEC_AVG_REPORT_ALERT_CODE, CLASS_1_MATERIAL_ALERT_CODE, MISSING_DOCKET_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE,
  PICKUP_CONFIRMATION_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_CODE,
  TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_CODE, STOCK_OPERATIONS_UPDATE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE,
  NEW_CASH_PRICE_FOR_MY_STOCKS_ALERT_CODE, LEADING_CASH_PRICE_WARNING_ALERT_CODE, BULK_INVOICE_ALERT_CODE, STOCK_AUTO_UPDATE_ALERT_CODE,
];
export const CUSTOMER_RECIPIENT_ALERTS = [
  MASS_LIMIT_BREACH_ALERT_CODE, CLASS_1_MATERIAL_ALERT_CODE, PICKUP_CONFIRMATION_ALERT_CODE,
  DELIVERY_CONFIRMATION_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_CODE, 
];
export const FREIGHT_PROVIDER_RECIPIENT_ALERTS = [
  MASS_LIMIT_BREACH_ALERT_CODE, CLASS_1_MATERIAL_ALERT_CODE, PICKUP_CONFIRMATION_ALERT_CODE,
  DELIVERY_CONFIRMATION_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_CODE, TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE
];
export const SITE_WISE_ALERTS = [SLOT_BOOKING_UPDATE_ALERT_CODE, TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE, CLASS_1_MATERIAL_ALERT_CODE,
  PICKUP_CONFIRMATION_ALERT_CODE, MASS_LIMIT_BREACH_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, SPEC_AVG_REPORT_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE, STOCK_OPERATIONS_UPDATE_ALERT_CODE
];

export const SUB_FREIGHT_PROVIDER_RECIPIENT_ALERTS = [PICKUP_CONFIRMATION_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_CODE, TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE];
export const TRUCK_OWNER_RECIPIENT_ALERTS = [PICKUP_CONFIRMATION_SMS_ALERT_CODE, DELIVERY_CONFIRMATION_SMS_ALERT_CODE];
export const STOCK_OWNER_RECIPIENT_ALERTS = [DELIVERY_CONFIRMATION_SMS_ALERT_CODE, PICKUP_CONFIRMATION_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_CODE, STOCK_OPERATIONS_UPDATE_ALERT_CODE];
export const SELLER_RECIPIENT_ALERTS = [SLOT_BOOKING_UPDATE_ALERT_CODE];
export const BUYER_RECIPIENT_ALERTS = [SLOT_BOOKING_UPDATE_ALERT_CODE];
export const ALERTS_WITH_HOURS_BEFORE_TRIGGER = [TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE]
