import alertifyjs from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import APIService from '../../services/APIService';
import { addNgrErrors, editNgrErrors } from '../../actions/companies/ngrs';
import { isLoading, forceStopLoader, loaded } from '../../actions/main';
import { getFreshdeskURL, isCompanyNgrsEditable } from '../../common/utils';

const MESSAGES = {
  CREATE_SUCCESS: 'NGR was successfully added.',
  UPDATE_SUCCESS: 'NGR details were successfully updated.',
};

export const createNgr = (farmId, data, actionCreator) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .ngrs()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addNgrErrors(item.errors));
        dispatch(forceStopLoader());
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        dispatch(loaded());
      }
    });
};

export const createAndGetNgr = (farmId, data, actionCreator, onSuccess) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .ngrs()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addNgrErrors(item.errors));
        dispatch(forceStopLoader());
      } else {
        dispatch(actionCreator(item));
        onSuccess(farmId, 'farm', item);
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        dispatch(loaded());
      }
    });
};

export const updateNgr = (farmId, ngrId, data, actionCreator) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .ngrs(ngrId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editNgrErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
        dispatch(loaded());
      }
    });
};

export const canCreateNgr = (id, type, selectedEntity, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const apiService = type === 'farm' ? APIService.farms(id) : APIService.companies(id);
  (isCompanyNgrsEditable(id, selectedEntity)) ?
    apiService.ngrs().appendToUrl('new/')
      .get(token)
      .then(res => {
        if (res.result) {
          if (callback) {
            callback();
          }
        } else {
          const reasons = '<li>' + res.reasons.join('</li><li>');
          alertifyjs.alert(
            'Permission Denied',
            `<div className=""><p>You do not have permission to create ngr for the party because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank' rel="noopener noreferrer">FAQs</a> for more details</div></div>`,
            () => {},
          );
        }
      }) :  
          alertifyjs.alert(
            'Permission Denied',
            `<div className=""><p>You do not have permission to create ngr for the party because:</p><ul>You are not authorised. Please contact Company Admin</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank' rel="noopener noreferrer">FAQs</a> for more details</div></div>`,
            () => {},
          );
};
