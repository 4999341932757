import React from 'react';
import isFunction from 'lodash/isFunction';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import withStyles from '@mui/styles/withStyles';

import PDFData from './common/PDFData';

const styles = {
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#fff',
  },
  nested: {
    paddingLeft: '8px',
  },
};

class NestedOptionMenu extends React.Component {
  state = {
    anchorEl: null,
    open: -1,
  };

  handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ anchorEl: event.currentTarget, open: -1 });
    return false
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.props.clearEntity ? this.props.clearEntity() : null;
  };

  handleOptionClick = (e, item, entity) => {
    this.handleClose();
    e.preventDefault();
    this.props.handleOptionClick(e, item, entity);
  };

  handleActionClick = (e, index, item, entity, hasFunc) => {
    e.persist()
    e.preventDefault();
    e.stopPropagation()
    this.setState(
      prevState => ({ open: prevState.open === index ? -1 : index }),
      () => {
        if (hasFunc) {
          this.props.handleOptionClick(e, item, entity);
        }
      }
    );
    return false
  };

  getOptionsItems() {
    const { optionsItems, item } = this.props;
    if (isFunction(optionsItems)) return optionsItems(item);
    else return optionsItems;
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    const optionsItems = this.getOptionsItems();
    return (
      <div>
        {
          this.props.useIconButton &&
          <IconButton
            aria-label='More'
            aria-owns={open ? 'long-menu' : null}
            aria-haspopup='true'
            onClick={this.handleClick}
            size="large">
            <MoreVertIcon />
          </IconButton>
        }
        {
          this.props.useButton &&
          <Tooltip title='Actions' placement='top'>
            <div id='nested-actions-menu-container' style={this.props.buttonContainerStyle || {}}>
              <Button
                id='nested-actions-button'
                color='primary'
                variant='outlined'
                onClick={this.handleClick}
                style={{ height: '100%' }}
              >
                <MoreVertIcon />
              </Button>
            </div>
          </Tooltip>
        }
        <Popover
          id='simple-popper'
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ maxHeight: '100%', overflow: 'auto' }}
        >
          <List component='nav' className={classes.root} style={{ width: '260px', maxHeight: '100%', overflow: 'auto' }}>
            {optionsItems.map((item, index) => {
              return !item?.subItems ? (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  {item?.component ? (
                    <ListItem key={index} style={{ padding: '8px 15px' }}>
                      <PDFData data={item?.data} text={<ListItemText primary={item?.text} />} callback={this.handleClose} />
                    </ListItem>
                  ) : (
                    <ListItem key={index} button style={{ padding: '8px 15px' }} onClick={e => this.handleOptionClick(e, item, this.props.item)}>
                      <ListItemText primary={item?.text} />
                    </ListItem>
                  )}
                </React.Fragment>
              ) : isEmpty(item?.subItems) ? (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <ListItem
                    button
                    onClick={e => this.handleActionClick(e, index, item, this.props.item, item?.hasFunc)}
                    style={{ padding: '8px 15px' }}
                  >
                    <ListItemText primary={item?.text} />
                    {this.state.open === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                </React.Fragment>
              ) : (
                item?.key !== 'view_in_new_tab' && item?.subItems?.map((subItem, key) => {
                  return (
                    <React.Fragment key={key}>
                      {key === 0 && (
                        <React.Fragment>
                          {index !== 0 && <Divider />}
                          <ListItem
                            button
                            onClick={e => this.handleActionClick(e, index, item, this.props.item, item?.hasFunc)}
                            style={{ padding: '8px 15px' }}
                          >
                            <ListItemText primary={item?.text} />
                            {this.state.open === index ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                        </React.Fragment>
                      )}
                      <Collapse in={this.state.open === index} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          {subItem?.component ? (
                            <ListItem style={{ padding: '7px 15px' }} className={classes.nested}>
                              <PDFData data={subItem?.data} text={<ListItemText secondary={subItem?.text} />} callback={this.handleClose} />
                            </ListItem>
                          ) : (
                            <ListItem
                              style={{ padding: '7px 15px' }}
                              button
                              className={classes.nested}
                              onClick={e => this.handleOptionClick(e, subItem, this.props.item)}
                            >
                              <ListItemText secondary={subItem?.text} />
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                })
              );
            })}
          </List>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles)(NestedOptionMenu);
