import React from 'react';
import moment from 'moment';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, List, ListItem, ListItemText, Tooltip, ListItemIcon } from '@mui/material'
import { map, values, flatten, compact, sum, isEmpty } from 'lodash';
import APIService from '../../services/APIService'
import { toDateTimeFormat } from '../../common/utils'

const Communications = ({ open, onClose, communications, title }) => {
  const [disabledSend, setDisabledSend] = React.useState([])
  const resend = communication => APIService
        .invoices(communication.invoiceId)
        .appendToUrl(`invoice-payments/${communication.invoicePaymentId}/mail/`)
        .post()
        .then(() => setDisabledSend(compact([...disabledSend, communication.id])))

  const _onClose = event => isEmpty(disabledSend) ? onClose(event) : window.location.reload()

  return (
    <Dialog open={open} onClose={_onClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        {title || 'Communications'}
      </DialogTitle>
      <DialogContent>
        <List>
        {
          map(communications, (comm, index) => {
            const recipients = compact(flatten(values(comm.recipients)))
            const mailStatus = sum(values(comm.mailStatus))
            const isPending = isEmpty(comm.mailStatus)
            const success = recipients.length === mailStatus
            const partialSuccess = mailStatus > 0;
            const color = success ? 'success' : ((partialSuccess || isPending) ? 'info' : 'error')
            const updatedAt = toDateTimeFormat(comm.updatedAt)
            const isThirtyMinutesFromLastUpdatedAt = moment(comm.updatedAt).add('30', 'minutes').isSameOrBefore(moment())
            const title = success ? 'Success' : (partialSuccess ? `Sent to ${mailStatus} out of ${recipients.length}` : (isPending ? (isThirtyMinutesFromLastUpdatedAt ? 'You might not have clicked on send mail, please re-send.'  : 'Scheduled to be sent') : 'Failed.'))
            return (
              <React.Fragment key={index}>
                <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                    <Button onClick={() => resend(comm)} variant='outlined' edge="end" aria-label="status" disabled={(isPending && !isThirtyMinutesFromLastUpdatedAt) || success || disabledSend.includes(comm.id)}>
                      Re-Send
                    </Button>
                  }
                >
                  <ListItemIcon>
                    <Tooltip title={title}>
                      <MailIcon color={color} />
                    </Tooltip>
                    </ListItemIcon>
                  <ListItemText
                    primary={
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{display: 'flex', alignItems: 'center'}}>
                          {comm.invoiceIdentifier}
                        </span>
                        <span style={{display: 'flex', alignItems: 'center', fontSize: '12px', fontStyle: 'italic', marginLeft: '8px'}}>
                          {updatedAt}
                        </span>
                      </span>
                    }
                    secondary={
                      <React.Fragment>
                        {
                          map(recipients, (recipient, i) => (
                            <div key={i}>{recipient}</div>
                          ))
                        }
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </React.Fragment>
            )
          })
        }
          </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose} color='default'>
          Close
        </Button>
        </DialogActions>
    </Dialog>
  )
}

export default Communications;
