import React from 'react';
import { map, pick, compact, isEmpty, includes, join, uniqBy, trim, reject, find } from 'lodash';
import APIService from '../../services/APIService';
import SideDrawer from '../common/SideDrawer';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommonButton from '../common/CommonButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import alertifyjs from 'alertifyjs';
import { MAX_MESSAGE_SIZE_ALLOWED } from '../../common/utils';

class SiteMessageSideDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      siteIds: [],
      message: '',
      savedMessages: [],
      messagePlainText: '',
    };
    this.onMessageChange = this.onMessageChange.bind(this);
  }

  componentDidMount() {
    const { selectedSiteId, open } = this.props;
    if(this.state.open != open)
      this.setState({open: open, siteIds: compact([selectedSiteId])}, this.fetchSavedMessages);
  }

  componentDidUpdate() {
    if(this.state.open != this.props.open)
      this.setState({open: this.props.open});
  }

  onClose = () => {
    this.setState({open: false}, this.props.onClose);
  };

  isValid() {
    return !isEmpty(this.state.siteIds) && this.state.message;
  }

  getPayload() {
    const { siteIds, message, messagePlainText } = this.state;
    return {message: message, farmIds: siteIds, plainText: messagePlainText};
  }


  checkMessageSize(text){
    const messageSize = new TextEncoder().encode(text).length;

    if (messageSize < MAX_MESSAGE_SIZE_ALLOWED) return true; 

    alertifyjs.error("Message too large. Current Message Length: " + messageSize + " characters. Max allowed length: 3584 characters. Please reduce message length and try again.");

    return false;
  }

  onSubmit = () => {
    if(this.isValid()) {
      const payload = this.getPayload();
      if (!this.checkMessageSize(payload.plainText)) return; 

      APIService.farms().appendToUrl('messages/').post(payload).then(() => {
        alertifyjs.success('Successfully sent!');
        this.onClose();
      });
    } else {
      alertifyjs.error('Please enter all the details', 2);
    }
  };

  hasSavedMessageFor = siteId => {
    return Boolean(find(this.state.savedMessages, {farmId: siteId}));
  };

  fetchSavedMessages() {
    const { siteIds } = this.state ;
    const newSiteIds = reject(siteIds, this.hasSavedMessageFor);
    const queryString = join(map(newSiteIds, id => `farm_ids=${id}`), '&');
    if(!isEmpty(newSiteIds))
      APIService.farms().appendToUrl(`messages/?${queryString}`)
                .get()
                .then(messages => this.setState({savedMessages: messages}, this.setSavedMessagesForSelectedSites));
  }

  deleteSavedMessages() {
    const { siteIds } = this.state ;
    const queryString = join(map(siteIds, id => `farm_ids=${id}`), '&');
    if(!isEmpty(siteIds))
      APIService.farms().appendToUrl(`messages/?${queryString}`)
                .delete()
                .then(() => {
                  this.onClose();
                  alertifyjs.success('Successfully deleted!');
                });
  }

  onDelete = () => {
    this.deleteSavedMessages();
  };

  getSites() {
    const { sites } = this.props;
    return map(sites, site => pick(site, ['id', 'name']));
  }

  onSiteChange = (id, selectedItems) => {
    this.setState({siteIds: map(selectedItems, 'id')}, this.fetchSavedMessages);
  };

  onMessageChange(value, delta, source, editor) {
    this.setState({message: value, messagePlainText: editor.getText().trim()});
  }

  getSavedMessagesForSelectedSites() {
    const { siteIds, savedMessages } = this.state ;
    if(!isEmpty(siteIds) && !isEmpty(savedMessages))
      return join(uniqBy(map(savedMessages, 'message'), trim), '\n');
    return '';
  }

  setSavedMessagesForSelectedSites() {
    const message = this.getSavedMessagesForSelectedSites();
    if(!includes(this.state.message, message))
      this.setState({message: this.state.message + '\n' + message});
  }

  render() {
    const { open, message, siteIds } = this.state;
    const sites = this.getSites();
    return(
      <SideDrawer isOpen={open} title="Broadcast Message" onClose={this.onClose} size="big">
        <div className="col-sm-12 padding-reset" style={{marginTop: '20px'}}>
          <CommonMultiSelect
            id="siteSelector"
            items={sites}
            selectedItems={siteIds}
            displayField="name"
            onChange={this.onSiteChange}
            placeholder="Select Site..."
            label="Site"
            selectAll
            clearAll
          />
        </div>
        <div className="col-sm-12 padding-reset" style={{marginTop: '30px'}}>
          <ReactQuill
            theme="snow"
            value={message}
            modules={SiteMessageSideDrawer.modules}
            formats={SiteMessageSideDrawer.formats}
            onChange={this.onMessageChange}
            placeholder="Enter Message..."
            style={{height: '200px'}}
          />
        </div>
        <div className="col-sm-12 padding-reset" style={{marginTop: '50px'}}>
          <CommonButton label="Cancel" default onClick={this.onClose} variant="contained" />
          <CommonButton label="Send" primary onClick={this.onSubmit} variant="contained" />
          <CommonButton label="Delete Message" secondary onClick={this.onDelete} variant="contained" />
        </div>
      </SideDrawer>
    );
  }
}

SiteMessageSideDrawer.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link',],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};
SiteMessageSideDrawer.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];


export default SiteMessageSideDrawer;
