import React from 'react';

import { connect } from 'react-redux';
import AddButton from '../common/AddButton';
import { required } from '../../common/validators';
import TitleTransfersTable from '../../containers/TitleTransfersTable';
import {
  getTitleTransfers,
  canCreateTitleTransfer, showHideTitleTransferSideDrawer,
  showViewTitleTransferSideDrawer, voidTitleTransferDialog, titleTransfersResponse, receiveTitleTransfer,
  showAddTitleTransferSideDrawer,
  updateDuplicateTitleTransferId
} from '../../actions/companies/contracts';
import { setHeaderText, setSubHeaderText, setBreadcrumbs, isLoading, forceStopLoader, isSearchApplied } from '../../actions/main';
import { getContractSubHeaderText, attachCSVEventListener, isAtGlobalTitleTransfer, defaultViewAction } from "../../common/utils";
import SideDrawer from '../common/SideDrawer';
import CreateTitleTransfer from '../../containers/CreateTitleTransfer';
import {
  COMPANY_ADMIN, OFFICE_ADMIN, TITLE_TRANSFER_FILTER_STATUSES, TITLE_TRANSFER_CONTRACT_TYPE_FILTER_STATUSES,
  OBSERVER_TYPE_ID, SYSTEM, TITLE_TRANSFER_HEADERS, TITLE_TRANSFER_TABLE_COLUMN_LIMIT, DEFAULT_TITLE_TRANSFER_TABLE_COLUMN_LIMIT, FILTER_KEYS_TO_EXCLUDE, PREDEFINED_DATE_RANGE_FILTER_KEYS
} from '../../common/constants';
import APIService from "../../services/APIService";
import Button from "@mui/material/Button/Button";
import { Tooltip, Paper } from '@mui/material';
import DownloadDataDialog from '../common/DownloadDataDialog';
import { setDownloadBar } from '../../actions/main';
import TitleTransferDetails from './TitleTransferDetails';
import { RejectionReasonDialog } from "../../components/rejections/RejectionReasonDialog";
import CustomEmailDialog from '../common/CustomEmailDialog';
import {
  keys, without, forEach, get, includes, map, filter, has,
  isEqual, isEmpty, uniq, compact, pick, values, intersectionBy,
} from 'lodash';
import alertifyjs from 'alertifyjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import Filters from '../common/Filters';
import CommonListingButton from '../common/CommonListingButton';
import CustomHeaderOptions from '../common/CustomHeaderOptions';

const TITLE_TRANSFER_FILTER_KEYS_MAPPING = {
  'process_on_date_range': ['process_on__gte', 'process_on__lte']
}

class TitleTransfers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: [],
      openTitleTransferSideDrawer: false,
      emailPopupParties: [],
      showEmailDialog: false,
      requestReason: {
        value: '',
        validators: [required()],
        errors: []
      },
      applyFilters: false,
      openSideDrawer: false,
      type_options: TITLE_TRANSFER_CONTRACT_TYPE_FILTER_STATUSES,
      filter_statuses: TITLE_TRANSFER_FILTER_STATUSES,
      filters: {},
      filterValues: {
        type__in: [],
        buyer__company__id__in: [],
        seller__company__id__in: [],
        status__in: [],
        commodity__id__in: [],
        grade__id__in: [],
        season__in: [],
        site__id__in: [],
        process_on_date_range: '',
        process_on__gte: '',
        process_on__lte: '',
        created_at__lte: '',
        created_at__gte: '',
      },
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
      isFilteredCsv: false,
      csvPopup: false,
      searchView: false,
    };
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.closeRejectionReasonDialog = this.closeRejectionReasonDialog.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);

    this.contractId = this.props.contractId || this.props.match.params.contract_id;
  }


  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ title_transfer: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.title_transfer', {})}, () => {
              this.props.isLoading();
              this.props.getTitleTransfers(this.contractId, false, null, true);
            })
          });
      }
    });
  };

  onCloseDownloadResponse() {
    this.props.setDownloadBar(null, false, null);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener(
      'title-transfers-csv-ready', 'Title Transfers', this.onDownloadResponse
    );
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.props.applySearch(null);
    APIService.profiles()
      .filters('title_transfer')
      .get(this.props.token)
      .then(res => {
        this.setState({
          filters: get(res, 'title_transfer', {}),
        });
      });
    this.props.getTitleTransfers(this.contractId, false);
    this.setHeaderAndBreadcrumbs();
  }

  componentDidUpdate(prevProp, prevState) {
    if (get(prevProp, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs();
    if (
      prevState.openTitleTransferSideDrawer == false &&
      (this.props.isViewTitleTransferSideDrawerOpened || this.props.isAddTitleTransferSideDrawerToBeOpened)
    )
      this.openSideDraw();
  }

  componentWillUnmount() {
    this.props.titleTransfersResponse();
    this.props.applySearch(null);
    this.props.showHideTitleTransferSideDrawer(false);
    this.props.showViewTitleTransferSideDrawer(false);
    this.props.showAddTitleTransferSideDrawer(false);
  }

  setHeaderAndBreadcrumbs() {
    const { count } = this.props;
    let breadcrumbs = [
      { text: this.contractId ? 'Title Transfers' : `Title Transfers (${count})` },
    ];
    let headerText = 'Title Transfers';
    if (this.contractId) {
      breadcrumbs = [
        { text: 'Contracts', route: '/Contracts' },
        { text: get(this.props.contract, 'referenceNumber', ''), route: '/Contracts/' + this.contractId + '/contract' },
        { text: 'Title Transfers' },
      ];
      headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
    }
    this.props.setHeaderText(headerText);

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  openSideDraw() {
    this.props.showHideTitleTransferSideDrawer(!this.props.isViewTitleTransferSideDrawerOpened);
    if (this.props.isViewTitleTransferSideDrawerOpened)
      this.props.showViewTitleTransferSideDrawer(true);
    this.setState({ openTitleTransferSideDrawer: true, });
  }

  closeSideDraw = success => {
    if(success)
      this.props.getTitleTransfers(this.contractId, false)
    this.props.showHideTitleTransferSideDrawer(false);
    this.props.showViewTitleTransferSideDrawer(false);
    this.props.showAddTitleTransferSideDrawer(false);
    this.props.setSelectedTitleTransfer(null);
    this.props.updateDuplicateTitleTransferId(null)
    this.setState({ openTitleTransferSideDrawer: false, });
  }

  fetchCSVData = () => {
    this.props.setDownloadBar('Your Title Transfers CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true, null);
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.searchView && this.props.isSearchApplied)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    if (this.state.customColumns)
      param+= param.length == 0 ? 'custom_csv' : '&custom_csv';
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    if(queryParams.get('include_void') == 'true')
      param+= param.length == 0 ? 'include_void=true' : '&include_void=true';
    let service = APIService.contracts();
    service.appendToUrl(`title-transfers/csv/?${param}`);
    if (this.props.contractId)
      service.appendToUrl(`commodity_contract_id=${this.contractId}`);
    this.setState({csvPopup: false, searchView: false});
    service.get(
      this.props.token,
      {
        'Content-Type': 'text/csv',
        'Accept': 'text/csv',
      },
    ).then(csvData => {
      csvData = csvData || [];
      this.setState(state => ({ ...state, csvData }));
    });
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  handleRequestReasonChange = (event) => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.requestReason.value = value;
    this.setState(newState);
  };

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.requestReason.errors = errors || this.getReasonErrors();
    this.setState({
      requestReason: { ...this.state.requestReason, errors: errors || this.getReasonErrors() }
    });
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `requestReason.value`);
    const validators = get(this.state, `requestReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  getEmailPopupParties = () => {
    return ['buyer', 'seller', 'transferSite'];
  };

  getPartyEmails = () => {
    const titleTransfer = intersectionBy(this.props.titleTransfers, [{ id: this.props.titleTransferId }], 'id');
    return {
      buyer: get(titleTransfer[0], 'buyerContact.email', '') || get(titleTransfer[0], 'buyerEmail', ''),
      seller: get(titleTransfer[0], 'sellerContact.email', '') || get(titleTransfer[0], 'sellerEmail', ''),
    };
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    const titleTransfer = intersectionBy(this.props.titleTransfers, [{ id: this.props.titleTransferId }], 'id');
    forEach(parties, party => {
      if (party === 'buyer')
        ids.buyer = get(titleTransfer[0], 'buyer.companyId') || get(titleTransfer[0], 'buyerCompanyId');
      if (party === 'seller')
        ids.seller = get(titleTransfer[0], 'seller.companyId')|| get(titleTransfer[0], 'sellerCompanyId');
      if (party === 'delivery site' || party == 'transferSite')
        ids.transferSite = get(titleTransfer[0], 'transferSite.companyId') || get(titleTransfer[0], 'transferSiteCompanyId');
    });

    return ids;
  }

  async getPartyContacts() {
    if (this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties));
    const contacts = {};
    if (!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if (isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.token);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, { companyId: parties[party] });
      });
    }

    return contacts;
  }

  getEmailSubject = () => {
    const companyName = get(this.props.currentUser, 'company.name', "");
    const titleTransfer = intersectionBy(this.props.titleTransfers, [{ id: this.props.titleTransferId }], 'id');
    return `${companyName} Title Transfer Void #${titleTransfer[0].identifier}`;
  };

  getFooterNote = () => {
    return 'Title Transfer PDF will be automatically sent as part of the email';
  };

  openEmailDialog = data => {
    this.setState({ showEmailDialog: true, emailPopupParties: this.getEmailPopupParties() });
    this.payloadData = data;
  };

  closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      this.gotOncePartyContacts = false;
      this.setState({ showEmailDialog: false });
    }
    else if (this.state.showEmailDialog) {
      const data = this.payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showEmailDialog: false }, () => {
        this.props.isLoading('alertify');
        APIService.contracts().appendToUrl(`title-transfers/${this.props.titleTransferId}/void/`)
          .put({ data }, this.props.userToken)
          .then(item => {
            if (has(item, 'errors')) {
              alertifyjs.error(item.errors[0]);
            } else {
              alertifyjs.success('Title Transfer is voided', 1, () => {
                if(this.props.copyFrom){
                  this.props.getTitleTransfers(this.contractId, false);
                  this.props.showAddTitleTransferSideDrawer(true)
                }
                else
                  window.location.reload();
              });
            }
            this.props.voidTitleTransferDialog(false, []);
          });
      });
    }
  };

  handleVoidClick = () => {
    this.setReasonErrors();
    if (this.state.requestReason.errors.length === 0) {
      if (this.props.showVoidDialog) {
        const data = { rejection_reason: this.state.requestReason.value };
        this.openEmailDialog(data);
      }
    }
  };

  closeRejectionReasonDialog = () => {
    const newState = { ...this.state };
    newState.requestReason.value = '';
    this.setState(newState);
    this.props.voidTitleTransferDialog(false, [])
  }

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/title_transfer_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const contractColumns = TITLE_TRANSFER_HEADERS;
    return contractColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(TITLE_TRANSFER_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};

    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  toggleCustomColumnDownloads = () => {
    this.setState({customColumns: !this.state.customColumns})
  }

  render() {
    return (
      <Paper className='paper-table-paginated'>
        <div style={{ position: 'relative'}}>

          {this.canExportCSV() && (
             <CommonListingButton
               defaultHandler={() => this.customCsvEnabled(false)}
               showMenus={isAtGlobalTitleTransfer() && !isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))}
               optionMapper={[
                 { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
                 { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
               ]}
               title='Download Contents of the table in a CSV'
               name='Export'
             />
          )}
          <DownloadDataDialog
            open={this.state.csvPopup}
            onClose={() => this.setState({csvPopup: false, searchView: false})}
            title='Download Title Transfers Data'
            enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
            isSearchApplied={this.props.isSearchApplied}
            searchView={this.state.searchView}
            onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
            isFilteredCsv={this.state.isFilteredCsv}
            onDownload={this.fetchCSVData}
            customColumnTitle={this.state.customColumnTitle}
            user={this.props.currentUser}
            token={this.props.token}
            csvType='title_transfer_csv'
            updateColumnCount={(count) => this.updateColumnCount(count)}
            toggleCustomColumnDownloads={this.toggleCustomColumnDownloads}
          />
          {isAtGlobalTitleTransfer() &&
           <Tooltip title='Apply filters' placement='top'>
             <Button
               value={this.state.applyFilters}
               variant="contained"
               type='button'
               onClick={() => this.handleFilters(true)}
               color='primary'
               style={{ float: 'right', marginLeft: '10px' }}
             >
               <FilterListIcon style={{ paddingRight: '5px' }} />
               FILTERS{' '}
               {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                : ''}
             </Button>
           </Tooltip>}
          {this.state.applyFilters && (
             <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
               <Filters
                 isLoading={this.props.isLoading}
                 forceStopLoader={this.props.forceStopLoader}
                 handleFilterState={this.handleFilterState}
                 filters={this.state.filters}
                 statusTemp={this.state.filter_statuses}
                 type_options={this.state.type_options}
                 filterValues={this.state.filterValues}
                 isTitleTransferFilters
               />
             </SideDrawer>
          )}
          {
            (this.props.contract || isAtGlobalTitleTransfer()) &&
            <AddButton label="Title Transfer" onClick={this.openSideDraw} />
          }
          {
            (this.props.isAddTitleTransferSideDrawerOpened || this.props.isAddTitleTransferSideDrawerToBeOpened) &&
            <SideDrawer
              isOpen={this.state.openTitleTransferSideDrawer}
              title="Add Title Transfer"
              onClose={this.closeSideDraw}
              size='xlarge'>
              <CreateTitleTransfer
                closeDrawer={this.closeSideDraw}
                contract={this.props.contract}
                copyFrom={this.props.copyFrom}
              />
            </SideDrawer>
          }
          {
            this.props.showVoidDialog && isAtGlobalTitleTransfer() &&
            <RejectionReasonDialog
              open={this.props.showVoidDialog}
              onClose={this.closeRejectionReasonDialog}
              title={'Void Title Transfer'}
              value={this.state.requestReason.value}
              onChange={this.handleRequestReasonChange}
              helperText={get(this.state, 'requestReason.errors[0]', '')}
              onCancel={this.closeRejectionReasonDialog}
              onReject={this.handleVoidClick}
              placeholder='Enter you reason for void request'
              submitText='Submit'
            />
          }
          {this.state.showEmailDialog && isAtGlobalTitleTransfer() &&
           <CustomEmailDialog
             parties={this.state.emailPopupParties}
             selectedParties={['buyer', 'seller']}
             title="Email PDF copies to"
             partyEmails={this.getPartyEmails()}
             partyContacts={this.getPartyContacts()}
             subject={this.getEmailSubject()}
             noBody={true}
             footer={this.getFooterNote()}
             open={this.state.showEmailDialog}
             onClose={this.closeEmailDialog}
             disableAcceptanceRequired={true}
           />
          }
          {
            this.props.isViewTitleTransferSideDrawerOpened &&
            <SideDrawer
              isOpen={this.state.openTitleTransferSideDrawer}
              title="Title Transfer"
              onClose={this.closeSideDraw}
              size='xlarge'>
              <TitleTransferDetails onClose={this.closeSideDraw} />
            </SideDrawer>
          }
            <div style={{float: 'right', marginRight: '10px'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
            <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="title_transfer_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={TITLE_TRANSFER_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_TITLE_TRANSFER_TABLE_COLUMN_LIMIT}
              />
            </SideDrawer>
          <TitleTransfersTable contractId={this.props.contractId} nested={this.props.nested} />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
    token: state.main.user.token,
    titleTransfers: state.companies.contracts.titleTransfers,
    isAddTitleTransferSideDrawerOpened: state.companies.contracts.isAddTitleTransferSideDrawerOpened,
    isAddTitleTransferSideDrawerToBeOpened: state.companies.contracts.isAddTitleTransferSideDrawerToBeOpened,
    breadcrumbs: state.main.breadcrumbs,
    count: get(state.companies.contracts, 'titleTransferPaginatedData.count') || 0,
    isViewTitleTransferSideDrawerOpened: state.companies.contracts.isViewTitleTransferSideDrawerOpened,
    showVoidDialog: state.companies.contracts.showVoidDialog,
    copyFrom: state.companies.contracts.copyFrom,
    titleTransferId: state.companies.contracts.titleTransferId,
    isSearchApplied: state.main.isSearchApplied,
  };
};

const mapDispatchToProps = dispatch => ({
  showHideTitleTransferSideDrawer: (flag) => dispatch(showHideTitleTransferSideDrawer(flag)),
  showViewTitleTransferSideDrawer: (flag) => dispatch(showViewTitleTransferSideDrawer(flag)),
  canCreateTitleTransfer: (id, callback) => dispatch(canCreateTitleTransfer(id, callback)),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  voidTitleTransferDialog: (flag, item) => dispatch(voidTitleTransferDialog(flag, item)),
  setSubHeaderText: (item) => dispatch(setSubHeaderText(item)),
  getTitleTransfers: (id, flag, url, loaderFlag) => dispatch(getTitleTransfers(id, flag, url, loaderFlag)),
  setHeaderText: (text) => dispatch(setHeaderText(text)),
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, flag, onClose) => dispatch(setDownloadBar(message, flag, onClose)),
  titleTransfersResponse: () => dispatch(titleTransfersResponse([])),
  setSelectedTitleTransfer: (value) => dispatch(receiveTitleTransfer(value)),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr)),
  showAddTitleTransferSideDrawer: flag => dispatch(showAddTitleTransferSideDrawer(flag)),
  updateDuplicateTitleTransferId: id => dispatch(updateDuplicateTitleTransferId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TitleTransfers);
