import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import PropTypes from 'prop-types';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import CommonButton from '../common/CommonButton';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import DragHandle from '@mui/icons-material/Dehaze';
import { get, has, includes, isEmpty, isFunction } from 'lodash';
import { getPageCache, updatePageCache } from '../../common/utils';


class CustomHeaderOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabledHeaders: [],
            disabledHeaders: [],
            highestEnabledId: props.customColumns.enabledHeaders.length - 1,
            highestDisabledId: props.customColumns.disabledHeaders.length - 1,
            isEnabledHeadersEmpty: false,
            isCsv: Object.prototype.hasOwnProperty.call(props, 'csv_type'),
            selectedAll: false
        };
        this.handleEnabledHeadersChange = this.handleEnabledHeadersChange.bind(this);
        this.handleDisabledHeadersChange = this.handleDisabledHeadersChange.bind(this);
        this.updateUserPreferences = this.updateUserPreferences.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.reorder = this.reorder.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentDidMount() {
      const newState = {...this.state};
      let enabledHeaders = this.props.customColumns.enabledHeaders;
      let disabledHeaders = this.props.customColumns.disabledHeaders;
      if (!this.state.isCsv && isEmpty(enabledHeaders)) {
        enabledHeaders = disabledHeaders.splice(0, this.props.defaultColumnLimit);
      }
      newState.enabledHeaders = enabledHeaders.map((val, index)=>{
        let _val = this.state.isCsv ? val : get(this.props.columnsMapping, val)
        if(isFunction(_val))
          _val = _val()
        return {
          id: `${index}`,
          value: val,
          displayName: _val
        };
      });
      newState.disabledHeaders = disabledHeaders.map((val, index)=>{
        let _val = this.state.isCsv ? val : get(this.props.columnsMapping, val)
        if(isFunction(_val))
          _val = _val()
        return {
          id: `${index}`,
          value: val,
          displayName: _val,
        };
      });
      if (isEmpty(disabledHeaders)) {
        newState.selectedAll = true;
      }
      this.props.updateColumnCount(newState.enabledHeaders.length);
      this.setState(newState);
    }
    handleEnabledHeadersChange(obj) {
        const newState = { ...this.state };
        newState.enabledHeaders.splice(newState.enabledHeaders.findIndex(item => item.id === obj.id), 1);
        newState.highestDisabledId += 1;
        newState.disabledHeaders.push({id: newState.highestDisabledId.toString(), value: obj.value, displayName: obj.displayName});
        this.props.updateColumnCount(newState.enabledHeaders.length);
        newState.selectedAll = false;
        this.setState(newState);
    }

    handleDisabledHeadersChange(obj){
      const newState = { ...this.state };
      if (newState.isEnabledHeadersEmpty) {
        newState.isEnabledHeadersEmpty = false;
      }
      if (!newState.isCsv && newState.enabledHeaders.length >= this.props.maxColumnLimit) {
        alertifyjs.error(`Max Columns shown in the listing cannot exceed ${this.props.maxColumnLimit}`);
        return;
      }
      newState.disabledHeaders.splice(newState.disabledHeaders.findIndex(item => item.id === obj.id), 1);
      newState.highestEnabledId += 1;
      newState.enabledHeaders.push({id: newState.highestEnabledId.toString(), value: obj.value, displayName: obj.displayName});
      this.props.updateColumnCount(newState.enabledHeaders.length);
      if (isEmpty(newState.disabledHeaders)) {
        newState.selectedAll = true;
      }
      this.setState(newState);
    }

    updateUserPreferences() {
      if (this.state.enabledHeaders.length == 0) {
        this.setState({isEnabledHeadersEmpty: true});
        return;
      }
      if (!this.state.isCsv && this.state.enabledHeaders.length > this.props.maxColumnLimit) {
        alertifyjs.error(`Max Columns shown in the listing cannot exceed ${this.props.maxColumnLimit}`);
        return;
      }
      const data = this.state.enabledHeaders;
      const urlPath = this.state.isCsv ? "report-preferences" : "table-preferences";
      const type = this.state.isCsv ? this.props.csv_type : this.props.table_type;
      const pageCache = getPageCache() || {};
      if(!includes(data, get(pageCache, 'orderBy')))
        updatePageCache({orderBy: '', order: ''});
      APIService.profiles()
        .appendToUrl(`${this.props.user.id}/${urlPath}/${type}/`)
        .put({enabledHeaders: data.map(obj => obj.value)}, this.props.token)
        .then(response => {
          if (has(response, 'errors')) {
            alertifyjs.error('An Error Occurred!');
          }
          else {
            alertifyjs.success("User Preferences Updated", 1, () => {
              this.props.closeDrawer();
              if (!this.state.isCsv) {
                window.location.reload();
              }
            });
          }
        });
    }

    reorder(list, startIndex, endIndex) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    }

    onDragEnd(result) {
      if (!result.destination) {
        return;
      }
      const newState = {...this.state};
      newState.enabledHeaders = this.reorder(
        this.state.enabledHeaders,
        result.source.index,
        result.destination.index
      );
      this.setState(newState);
    }

    handleSelectAll() {
      const newState = {...this.state};
      if (isEmpty(newState.disabledHeaders) && newState.selectedAll) {
        newState.enabledHeaders.forEach(obj => {
          newState.highestDisabledId += 1;
          newState.disabledHeaders.push({id: newState.highestDisabledId.toString(), value: obj.value, displayName: obj.displayName});
        });
        newState.enabledHeaders = [];
        newState.selectedAll = false;
      }
      else if (!newState.selectedAll) {
        newState.disabledHeaders.forEach(obj => {
          newState.highestEnabledId += 1;
          newState.enabledHeaders.push({id: newState.highestEnabledId.toString(), value: obj.value, displayName: obj.displayName});
        });
        newState.disabledHeaders = [];
        newState.selectedAll = true;
      }
      else {
        newState.selectedAll = false;
      }
      this.props.updateColumnCount(newState.enabledHeaders.length);
      this.setState(newState);
    }

    render() {
        return(
            <React.Fragment>
              <div style={{marginTop: '8px', height: '40px', fontSize: '15px'}}>
                <Checkbox
                  checked={this.state.selectedAll}
                  onChange={this.handleSelectAll}
                /> Select All
              </div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.enabledHeaders.map((obj, index) =>
                        <Draggable key={obj.id} draggableId={obj.id} index={index}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ ...provided.draggableProps.style, position: 'static' }}
                            >
                              <div style={{display: "flex", alignItems: "center", border: '1px solid rgba(0,0,0,0.2)', marginTop: '8px', height: '40px', fontSize: obj.displayName.length < 48 ? '15px' : '11px', borderRadius: '4px'}} key={obj.id}>
                                <Checkbox
                                  checked
                                  onChange={() => this.handleEnabledHeadersChange(obj)}
                                />
                                <span>{obj.displayName}</span>
                                <DragHandle style={{marginTop: '2px', float: 'right', marginRight: '5px', color: 'rgba(0,0,0,0.2)', marginLeft: 'auto'}} />
                              </div>
                            </div>)}
                        </Draggable>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {this.state.disabledHeaders.map(obj =>
                <div style={{display: "flex", alignItems: "center", border: '1px solid rgba(0,0,0,0.2)', marginTop: '8px', height: '40px', fontSize: '15px', borderRadius: '4px'}} key={obj.id}>
                  <Checkbox
                    checked={obj.value === 'Currency'}
                    onChange={() => this.handleDisabledHeadersChange(obj)}
                    disabled={obj.value === 'Currency'}
                  />
                  <span>{obj.displayName}</span>
                </div>
              )}
              {
                this.state.isEnabledHeadersEmpty &&
                <div style={{top: '10px', position: 'relative'}}>
                  <span style={{color: "red", fontSize: '15px'}}>Please check atleast one column</span>
                </div>
              }
              <div style={{marginTop: '20px', marginLeft: 'auto', float: 'right' }}>
                <CommonButton
                  variant="outlined"
                  label="Cancel"
                  type="button"
                  default
                  onClick={this.props.closeDrawer}
                />
                <CommonButton
                  variant="raised"
                  primary={true}
                  label="Confirm"
                  type="button"
                  onClick={this.updateUserPreferences}
                />
              </div>
            </React.Fragment>
        );
    }
}

CustomHeaderOptions.propTypes = {
    customColumns: PropTypes.object,
    token: PropTypes.string,
    user: PropTypes.object,
    csv_type: PropTypes.string,
    closeDrawer: PropTypes.func.isRequired,
    table_type: PropTypes.string,
};

export default CustomHeaderOptions;
