import React from 'react';
import { connect } from "react-redux";
import alertifyjs from 'alertifyjs'
import { includes, get, find, isEmpty } from 'lodash';
import APIService from '../services/APIService';
import { isLoading } from "../actions/main";
import FreightOrderActions from '../components/freights/FreightOrderActions';
import { getGrainOrderActionMenuOptions, regenerateOrderPDF } from '../components/freights/utils';
import { ORDERS_TABLE_COLUMN_LIMIT, REQUEST_ORDERS_HEADERS } from "../common/constants";
import {
  getSelectedOrder,
  receiveOrder,
  getPaginatedOrdersResponse,
  getRequestOrders,
  handleCallOnGrainOrderOptionsMapper
} from "../actions/companies/orders";
import GenericTable from "../components/GenericTable";
import { getCustomColumns } from '../actions/companies/index';

class RequestOrdersTable extends React.Component {

  componentDidMount() {
    this.props.getCustomColumns('request_order_table');
  }

  render() {
    return (
      <div>
        <GenericTable {...this.props} />
        <FreightOrderActions {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  const token = state.main.user.token;
  let customColumns = [...REQUEST_ORDERS_HEADERS];
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(REQUEST_ORDERS_HEADERS, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > ORDERS_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, ORDERS_TABLE_COLUMN_LIMIT);
  }
  return {
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    clearSearch: getPaginatedOrdersResponse,
    columns: customColumns,
    currentUser: state.main.user.user,
    items: state.companies.orders.cogOrders,
    globalSearch: true,
    optionsItems: item => getGrainOrderActionMenuOptions(item, subItems, clickedOption),
    order: 'desc',
    orderBy: 'createdAt',
    paginationData: state.companies.orders.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedOrderId: state.companies.orders.selectedOrderId,
    selectedOrder: state.companies.orders.selectedOrder,
    useNestedOptionMenu: true,
    clickedOption: clickedOption,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    subItems: subItems,
    token,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    voidFilter: true,
    displayIDColumn: 'identifier'
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(item?.key === 'recalculate_distance') {
        let message = alertifyjs.warning('Updating distance, please wait for few seconds.', 10)
        APIService.freights().orders(baseEntity.id).appendToUrl('distance/').put().then(response => {
          message.dismiss()
          if(response?.id)
            alertifyjs.success('Successfully updated distance.', 2)
          else
            alertifyjs.error('Unable to update, please contact AgriChain Support.', 2)
        })
      } else if(get(item, 'key') === 'regenerate_pdf') {
        return regenerateOrderPDF(baseEntity);
      } else {
        dispatch(handleCallOnGrainOrderOptionsMapper(event, item, baseEntity, 'listing', true));
      }

    },
    handleDefaultCellClick: (item) => {
      dispatch(getSelectedOrder(item.id, receiveOrder, false, false, false, false, true));
      dispatch(isLoading('orderDetail'));
      document.location.hash = '/freights/orders/' + item.id + '/order';
    },
    navigateTo: url => dispatch(getRequestOrders(url, true)),
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getRequestOrders( url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
      const service = APIService.freights().orders();
      service.appendToUrl(`requests/web/search/?page_size=${pageSize}`);
      if (page) {
        service.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if(includeVoid) {
        service.appendToUrl(`&include_void=${includeVoid}`)
      }
      return service.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestOrdersTable);
