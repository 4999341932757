/*eslint no-process-env: 0*/
/*eslint no-undef: 0*/
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommonButton from '../common/CommonButton';
import { forceStopLoader } from '../../actions/main/index';
import './errorPage.scss';
import { isErrorPathname, clearPageCache } from '../../common/utils';
import Header from '../header/Header';
import LeftPanel from '../panels/LeftPanel';
import { Notifier } from '@airbrake/browser';
import { map, includes, get } from 'lodash';
import StackTrace from "stacktrace-js";

const TITLES = {
  '403': 'Access Denied',
  '404': 'Page Not Found',
  '500': 'Internal Server Error',
};
const DESCRIPTIONS = {
  '403': 'Sorry, access to this page is denied.',
  '404': 'Sorry, the page you are looking for does not exist.',
  '500': "Whoops, an error occurred and your request couldn’t be completed. <br/> Please refresh the page, or feel free to report the issue at <a href='mailto:support@agrichain.com'>support@agrichain.com</a>",
};

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, code: null };

    this.props.history.listen(() => {
      this.setState({code: '', error: '', errorInfo: ''});
    });

    this.handleBackToHomeButtonClick = this.handleBackToHomeButtonClick.bind(this);
  }

  getNotifier = () => {
    /*eslint no-undef: 0*/
    this.ERRBIT_KEY = process.env.ERRBIT_KEY;
    /*eslint no-undef: 0*/
    this.ERRBIT_URL = process.env.ERRBIT_URL || 'http://localhost:8000';
    if(this.ERRBIT_URL && this.ERRBIT_KEY)
      return new Notifier({
        projectId: 1,
        projectKey: this.ERRBIT_KEY,
        environment: this.props.serverEnv,
        host: this.ERRBIT_URL
      });
  };

  componentDidCatch(error, errorInfo) {
    StackTrace.fromError(error).then(traces => {
      const newTraces = map(traces, trace => ({
        column: trace.columnNumber,
        file: trace.fileName,
        'function': trace.functionName,
        line: trace.lineNumber
      }));
      this.setState({ error, errorInfo }, () => {
        clearPageCache();
        const notifier = this.getNotifier();
        if(notifier) {
          const user = this.props.user.user || {};
          notifier.addFilter(notice => {
            notice.errors[0].backtrace = newTraces;
            return notice;
          });
          notifier.notify({
            error: this.state.error,
            params: {info: this.state.errorInfo},
            context: {
              component: window.location.href, user: {id: user.id, email: user.email}
            }
          });
        }
      });
    })
  }

  componentDidMount() {
    if (this.props.isLoading) {
      this.props.forceStopLoader();
    }
  }

  componentDidUpdate() {
    if((!this.props.user.user && !this.state.code) && (this.state.errorInfo !== '')) {
      this.setState({code: '', error: '', errorInfo: ''});
    } else if((this.props.serverErrorCode !== this.state.code) && (this.state.code !== '500')) {
      this.setState({code: this.props.serverErrorCode});
    } else if((this.state.error || this.state.errorInfo) && (this.state.code !== '500')) {
      this.setState({code: '500'}, () => this.props.forceStopLoader());
    } else if(this.state.code && this.props.isLoading) {
      this.props.forceStopLoader();
    }
  }

  handleBackToHomeButtonClick() {
    window.location.hash = '/';
  }

  isError() {
    return this.state.errorInfo || this.state.error;
  }

  render() {
    const code = isErrorPathname() ? window.location.hash.split('#/')[1] : this.state.code;

    if(includes(['403', '404'], code) || this.isError()) {
      return (
        <div className="main-page">
          <Header />
          <div className="main-container">
            <LeftPanel toggleNavHandler={ this.toggleNavHandler }/>
            <div className="wrapper">
              <img src={`images/${code}.svg`} alt={TITLES[code]} />
              <h1 className="error-page-title">{get(TITLES, code, 'Unknown Error')}</h1>
              <h4 className="error-page-description" dangerouslySetInnerHTML={{__html: get(DESCRIPTIONS, code, 'Something went wrong.')}}></h4>
              {
                code === '500' &&
                <CommonButton
                  label="Refresh"
                  variant="contained"
                  onClick={ () => { window.location.reload(); } }
                />
              }
              <CommonButton
                label="Back To Home"
                primary={true}
                variant="contained"
                onClick={this.handleBackToHomeButtonClick}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

const mapStateToProps = state => ({
  isLoading: state.main.isLoading,
  serverErrorCode: state.main.serverErrorCode,
  user: state.main.user,
  serverEnv: state.main.serverEnv,
});

const mapDispatchToProps = (dispatch) => ({
  forceStopLoader: () => dispatch(forceStopLoader()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage));
