import React from 'react';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import {
  convertEpochToDateFormat
} from '../../common/momentUtilities';

const TransactionBase = ({ transaction, noLink, icon, url, sx }) => {
  const createdAt = transaction?.createdAt ? convertEpochToDateFormat(transaction?.createdAt) : '';
  const number = transaction?.referenceNumber || transaction?.identifier
  return (
    <List
      dense
      sx={{
        padding: '0 !important',
        display: 'flex',
        '.MuiListItemIcon-root': {minWidth: 'auto', marginRight: '8px'},
        '.MuiListItemText-primary': {fontSize: '14px', fontWeight: 'bold'},
        '.MuiListItemText-secondary': {fontSize: '12px'},
        '.MuiListItem-root': {padding: 0, maxWidth: '300px', width: 'auto', paddingRight: '24px'},
        '.MuiListItemText-root': {marginTop: '4px', marginBottom: '4px'},
        ...sx
      }}
    >
      <ListItem>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            noLink ?
              number :
              <a target='_blank' href={url} rel='noreferrer noopener'>
                {number}
              </a>
          }
          secondary={createdAt}
        />
      </ListItem>
    </List>
  )
}

export default TransactionBase
