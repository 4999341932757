import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchMarketZones } from '../../actions/main';
import mapValues from 'lodash/mapValues';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import has from 'lodash/has';
import filter from 'lodash/filter';
import { required, maxLength, phoneMobileRegex } from '../../common/validators';
import GoogleMapPopup from '../common/GoogleMapPopup';
import GooglePlaceAutoComplete from '../google-places/AutoComplete';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import APIService from '../../services/APIService';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import '../../common/forms.scss';
import { SYSTEM_COMPANY_IDS } from '../../common/constants';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';
import set from 'lodash/set';
import pick from 'lodash/pick';
import { addCompany } from '../../actions/api/companies';
import { isCurrentUserBelongsToCompany, isSystemCompany } from '../../common/utils';


const autocompleteFilters = createFilterOptions();

class CompanySiteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      isAddressSelected: false,
      disableRegionField: true,
      defaultLocation: {},
      allSiteHandlers: [],
      resetAddress: false,
      resetLocation: false,
      isLoadingSites: false,
      siteInputText: '',
      isFormEditable: true,
      fields: {
        name: {
          value: null,
          validators: [required('Name can not be blank'), maxLength(100)],
          errors: []
        },
        address: {
          value: {},
          validators: [required('Address can not be blank')],
          errors: []
        },
        location: {
          value: {},
          validators: [required()],
          errors: []
        },
        marketZoneId: {
          value: '',
          validators: [],
          errors: []
        },
        regionId: {
          value: '',
          validators: [],
          errors: []
        },
        mobile: {
          value: '',
          validators: [phoneMobileRegex('Please enter a valid phone number')],
          errors: []
        },
      }
    };

    this.formRef = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedCompanySite = this.setAllFieldsValuesBySelectedCompanySite.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleMarketZoneChange = this.handleMarketZoneChange.bind(this);
    this.getSelectedCompany = this.getSelectedCompany.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.setAddressFieldErrors = this.setAddressFieldErrors.bind(this);
    this.getAddressFieldErrors = this.getAddressFieldErrors.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.updateCompanySiteDetails = this.updateCompanySiteDetails.bind(this);
    this.changeSiteDetails = this.changeSiteDetails.bind(this)
    this.handleSiteInputChange = this.handleSiteInputChange.bind(this)
;  }

  componentDidMount() {
    if(this.props.selectedCompanySite) {
      setTimeout(() => this.setAllFieldsValuesBySelectedCompanySite(), 100);
    }
    if(isEmpty(this.props.marketZonesWithRegions)) {
      this.props.getMarketZones();
    }
    if(!this.props.selectedCompanySite && !SYSTEM_COMPANY_IDS.includes(this.props.companyId)) {
      this.getSelectedCompany();
    }
    if(this.props?.inputText) {
      const newState = { ...this.state };
      newState.siteInputText = this.props.inputText;
      newState.fields.name.value = this.props.inputText;
      newState.allSiteHandlers = [...newState.allSiteHandlers, {'name': this.props.inputText, 'inputValue': this.props.inputText}];
      this.setState(newState)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCompanySite && (!isEqual(prevProps.selectedCompanySite, this.props.selectedCompanySite))) {
      this.setAllFieldsValuesBySelectedCompanySite();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      newState.fields[key].errors = value;
    });
    this.setState(newState);
  }

  getSelectedCompany() {
    APIService.companies(this.props.companyId).get(this.props.token)
              .then((company) => {
                const newState = {...this.state};
                const locationValue = {
                  formatted_address: get(company, 'address.address', ''),
                  lat: get(company, 'address.latitude', ''),
                  lng: get(company, 'address.longitude', ''),
                  suburb: get(company, 'address.suburb', ''),
                  state: get(company, 'address.state', ''),
                  postCode: get(company, 'address.postCode', ''),
                  streetName: get(company, 'address.streetName', ''),
                };
                newState.defaultLocation = locationValue;
                this.setState(newState);
              });
  }

  fetchSiteHandlers = debounce(value => {
    if(value) {
      const queryParams = value ? {search_txt: value, is_active: true} : {};
      APIService.farms().appendToUrl('all/').get(this.props.token, null, queryParams).then(handlers => {
          this.setState({allSiteHandlers: handlers, isLoadingSites: false });
      });
    }
  }, 500);

  handleSiteInputChange = (event, value) => {
    if(event) {
      const newState = { ...this.state };
      newState.siteInputText = value;
      this.setState(newState, () => {
        if(value && value.length > 2) {
          this.fetchSiteHandlers(value);
        }
      })
    }
  }

  handleSiteChange(event, value) {
    let locationValue = {}, address = {};

    if(get(value, 'inputValue', false)) {
      set(address, 'name', '');
      set(address, 'address', '');
      set(address, 'latitude', '');
      set(address, 'longitude', '');
      set(address, 'suburb', '');
      set(address, 'state', '');
      set(address, 'postCode', '');
      set(address, 'streetName', '');

      set(locationValue, 'formatted_address', '');
      set(locationValue, 'lat', '');
      set(locationValue, 'lng', '');
      set(locationValue, 'suburb', '');
      set(locationValue, 'state', '');
      set(locationValue, 'postCode', '');
      set(locationValue, 'streetName', '');
      this.changeSiteDetails(value, address, locationValue)
    } else {
      set(address, 'name', get(value, 'address'));
      set(address, 'address', get(value, 'address'));
      set(address, 'latitude', get(value, 'latitude'));
      set(address, 'longitude', get(value, 'longitude'));
      set(address, 'suburb', get(value, 'suburb'));
      set(address, 'state', get(value, 'state'));
      set(address, 'postCode', get(value, 'postCode'));
      set(address, 'streetName', get(value, 'streetName'));

      set(locationValue, 'formatted_address', get(value, 'address'));
      set(locationValue, 'lat', get(value, 'latitude'));
      set(locationValue, 'lng', get(value, 'longitude'));
      set(locationValue, 'suburb', get(value, 'suburb'));
      set(locationValue, 'state', get(value, 'state'));
      set(locationValue, 'postCode', get(value, 'postCode'));
      set(locationValue, 'streetName', get(value, 'streetName'));

      if(isSystemCompany() || isCurrentUserBelongsToCompany(get(value, 'companyId')) || !get(value, 'companyTransactionParticipation') ) {
        this.setState({isFormEditable: true}, () => this.changeSiteDetails(value, address, locationValue));
      } else {
        this.setState({isFormEditable: false}, () => this.changeSiteDetails(value, address, locationValue));
      }

    }
  }

  changeSiteDetails(siteDetails, address, locationValue) {
    const newState = { ...this.state };
    if(get(siteDetails, 'inputValue', false)) {
      newState.isAddressSelected = false;
      siteDetails.name = get(siteDetails, 'inputValue');
      newState.siteInputText = get(siteDetails, 'inputValue');
    }
    else {
      newState.siteInputText = get(siteDetails, 'name');
      newState.isAddressSelected = true;
    }

    newState.fields.name.value = siteDetails;
    newState.resetAddress = true;
    newState.resetLocation = true;
    newState.fields.address.value = address;
    newState.fields.location.value = locationValue;
    newState.fields.marketZoneId.value = get(siteDetails, 'marketZoneId', '');
    newState.fields.regionId.value = get(siteDetails, 'regionId', '');
    newState.fields.mobile.value = get(siteDetails, 'mobile', '');
    this.setState(newState);
  }

  handleMarketZoneChange(value) {
    if (value !== 'VALUE_NOT_SELECTED') {
      const newState = {...this.state};
      const marketZone = find(this.props.marketZonesWithRegions, {id: value});
      if(isEmpty(marketZone) || isEmpty(marketZone['regions'])){
        newState.regions = [];
        newState.fields.regionId.validators = [];
        newState.fields.regionId.errors = [];
        newState.disableRegionField = true;
      } else {
        newState.regions = marketZone['regions'];
        newState.fields.regionId.validators = [];
        newState.disableRegionField = false;
      }
      newState.fields.regionId.value = null;
      newState.fields.marketZoneId.value = value;
      this.setState(newState, () => this.setFieldErrors('marketZoneId'));
    }
  }

  onNewRequestSiteOperator(selectedSiteOperator) {
    const newState = {...this.state};
    newState.siteOperatorSearchText = selectedSiteOperator.name;
    newState.fields.siteOperatorId.value = selectedSiteOperator.id;
    this.setState(newState);
    this.setFieldErrors('siteOperatorId');
  }

  onUpdateInputSiteOperator(searchText) {
    this.setState({
      ...this.state,
      siteOperatorSearchText: searchText
    });
  }

  handleSubmit(event) {
    event.stopPropagation();
    this.setAllFieldsErrors(()=>{
      const isFormInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });
      const submitData = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      if(has(submitData, 'regionId') && submitData.regionId === undefined) {
        submitData.regionId = null;
      }
      submitData.address.latitude = submitData.location.lat;
      submitData.address.longitude = submitData.location.lng;
      submitData.address.suburb = submitData.location.suburb || submitData.address.suburb;
      submitData.address.state = submitData.location.state || submitData.address.state;
      submitData.address.postCode = submitData.location.postCode || submitData.address.postCode;
      submitData.address.streetName = submitData.location.streetName || submitData.address.streetName;
      submitData.name = get(submitData.name, 'name') || get(submitData, 'name');
      delete submitData.location;
      if (!isFormInvalid){
        if(has(this.state.fields.name.value, 'companyId')) {
          let companyId = this.state.fields.name.value.companyId;
          APIService.companies(companyId).get().then(company => {
            let companyFilteredDetails = pick(company, ['abn', 'businessName', 'typeId', 'mobile', 'website',
            'startOfWeek', 'endOfWeek', 'brokerages'])
            set(companyFilteredDetails, 'address' , pick(company.address, ['address', 'latitude', 'longitude', 'suburb', 'state', 'postCode', 'streetName']));
            this.props.addCompany(companyFilteredDetails, this.updateCompanySiteDetails, true)
          })
        } else {
          this.props.submit(this.props.companyId, submitData, this.props.handleFarmSubmit);
        }
      }
    });
    event.preventDefault();
  }

  /* eslint no-unused-vars: off */
  updateCompanySiteDetails(companyDetails){
    let companyId = this.state.fields.name.value.companyId;
    let companySiteId = this.state.fields.name.value.id;
    const submitData = mapValues(this.state.fields, (field) => {
      return field.value;
    });
    if(has(submitData, 'regionId') && submitData.regionId === undefined) {
      submitData.regionId = null;
    }
    submitData.address.latitude = submitData.location.lat;
    submitData.address.longitude = submitData.location.lng;
    submitData.address.suburb = submitData.location.suburb;
    submitData.address.state = submitData.location.state;
    submitData.address.postCode = submitData.location.postCode;
    submitData.address.streetName = submitData.location.streetName;
    submitData.name = get(submitData.name, 'name');
    delete submitData.location;

    this.props.update(companyId, companySiteId, submitData, this.props.handleFarmSubmit)
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleSelectFieldChange(value, elementId) {
    this.setFieldValue(`${elementId}`, value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleAddressChange(data) {
    const newState = {...this.state};
    var address = {
      name: data.name,
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      streetName: data?.streetName,
      suburb: data?.suburb,
      state: data?.state,
      postCode: data?.postCode
    };
    newState.resetAddress = false;
    newState.resetLocation = false;
    newState.isAddressSelected = true;
    newState.fields.address.value = address;
    const location = this.state.fields.location.value;
    if(data.lat && (isEmpty(location) || isEmpty(location.formatted_address))) {
      const locationValue = {
        formatted_address: data.address,
        lat: parseFloat(parseFloat(data.lat).toFixed(6)),
        lng: parseFloat(parseFloat(data.lng).toFixed(6)),
        suburb: data?.suburb,
        state: data?.state,
        postCode: data?.postCode,
        streetName: data?.streetName
      };
      newState.fields.location.value = locationValue;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  setAddressFieldErrors(key) {
    const newState = {...this.state};
    newState.fields.address.errors = this.getAddressFieldErrors(key);
    this.setState(newState);
  }

  getAddressFieldErrors(key) {
    var errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value.name) || validator.isInvalid(value.address)) {
        errors.push(validator.message);
      } else {
        errors = [];
      }

    });

    return errors;
  }

  handleLocationChange(data) {
    const location = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data?.suburb,
      state: data?.state,
      postCode: data?.postCode,
      streetName: data?.streetName
    };
    const address = this.state.fields.address.value;
    const newState = {...this.state};
    newState.fields.location.value = location;
    newState.fields.location.errors = [];

    if(data.lat && (isEmpty(address) || isEmpty(address.name))) {
      newState.resetAddress = false;
      newState.resetLocation = false;
      const addressValue = {
        name: data.formatted_address,
        address: data.formatted_address,
        latitude: data.lat,
        longitude: data.lng,
        suburb: data?.suburb,
        state: data?.state,
        postCode: data?.postCode,
        streetName: data?.streetName
      };
      newState.fields.address.value = addressValue;
      newState.isAddressSelected = true;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    let item = (key==='address' && !this.state.isAddressSelected) ? '' : get(this.state, `fields[${key}].value`);
    if(isEqual(key, 'name') && has(item, 'name'))
      item = item.name

    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(item)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setAllFieldsValuesBySelectedCompanySite() {
    if (this.props.selectedCompanySite) {
      const newState = { ...this.state };

      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedCompanySite[key];
      });
      newState.resetAddress = false;
      const addressValue = {
        name: this.props.selectedCompanySite.address.name,
        address: this.props.selectedCompanySite.address.address,
        latitude: this.props.selectedCompanySite.address.latitude,
        longitude: this.props.selectedCompanySite.address.longitude,
        suburb: this.props.selectedCompanySite.address.suburb,
        state: this.props.selectedCompanySite.address.state,
        postCode: this.props.selectedCompanySite.address.postCode,
        streetName: this.props.selectedCompanySite.address.streetName
      };
      newState.fields.address.value = addressValue;
      newState.isAddressSelected = true;

      const locationValue = {
        formatted_address: this.props.selectedCompanySite.address.address,
        lat: this.props.selectedCompanySite.address.latitude,
        lng: this.props.selectedCompanySite.address.longitude,
        suburb: this.props.selectedCompanySite.address.suburb,
        state: this.props.selectedCompanySite.address.state,
        postCode: this.props.selectedCompanySite.address.postCode,
        streetName: this.props.selectedCompanySite.address.streetName
      };
      newState.fields.location.value = locationValue;
      newState.fields.marketZoneId.value = this.props.selectedCompanySite.marketZoneId;
      if(!isEmpty(this.props.marketZonesWithRegions) && this.props.selectedCompanySite.marketZoneId) {
        newState.regions = find(this.props.marketZonesWithRegions, {id: this.props.selectedCompanySite.marketZoneId}).regions;
        if(isEmpty(newState.regions)){
          newState.fields.regionId.validators = [];
          newState.fields.regionId.errors = [];
        }
        else{
          newState.fields.regionId.validators = [required()];
        }
      }
      newState.fields.regionId.value = this.props.selectedCompanySite.regionId;
      this.setState(newState);
    }
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleUpdateInput = (searchText, source, id) => {
    this.setFieldValue(id, searchText);
  };

  cancelDrawer(){
    this.props.closeDrawer();
  }

  isSearchableInput() {
    return get(this.state.fields.name.value, 'length') > 2;
  }

  render() {
    const isSearchableInput = this.isSearchableInput();
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
              {
                SYSTEM_COMPANY_IDS.includes(this.props.companyId) ?
                  <Autocomplete
                    id="handlerId"
                    options={this.state.allSiteHandlers || []}
                    getOptionLabel={option =>  option.name || this.state.siteInputText}
                    loading={this.state.isLoadingSites}
                    loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
                    disabled={!this.props.canAccessAny}
                    popupIcon={!this.props.canAccessAny ? <i className="icon-lock-inline"></i> : undefined}
                    noOptionsText={this.state.siteInputText ? "No results" : 'Start typing...'}
                    filterOptions={(options, params) => {
                      const filtered = autocompleteFilters(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}" as a new site`,
                        });
                      }
                      return filtered;
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        value={this.state.siteInputText}
                        variant="standard"
                        placeholder="Type at least 3 characters to search"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.isLoadingSites ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        label='Site Name'
                        fullWidth
                        helperText={this.state.fields.name.errors[0]}
                        error={this.state.fields.name.errors[0]}
                      />
                    )}
                    value={get(this.state.fields.name, 'value', '')}
                    onChange={this.handleSiteChange}
                    onInputChange={this.handleSiteInputChange}
                  /> : 
                  <CommonTextField
                    id="name"
                    label="Site Name"
                    helperText= {this.state.fields.name.errors[0]}
                    onChange={this.handleFieldChange}
                    onBlur={this.onFieldBlur}
                    value={this.state.fields.name.value}
                    disabled={!this.props.canAccessAny}
                    maxLength="100" />
              }
              </div>
              <div className="col-sm-12 form-wrap-70">
                <GooglePlaceAutoComplete
                  id="address"
                  hintText="Please select"
                  floatingLabelText="Address"
                  fullWidth
                  value={this.state.fields.address.value.address}
                  name={this.state.fields.address.value.name}
                  errorText= {this.state.fields.address.errors[0]}
                  onChange={this.handleAddressChange}
                  errorStyle={{textAlign: "left"}}
                  disabled={!this.state.isFormEditable}
                  onBlur={this.onFieldBlur}
                  resetState={this.state.resetAddress}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <GoogleMapPopup
                  id="location"
                  location={this.state.fields.location.value}
                  defaultLocation={this.state.defaultLocation}
                  errors={this.state.fields.location.errors}
                  onDone={this.handleLocationChange}
                  disabled={!this.state.isFormEditable}
                  resetState={this.state.resetLocation}
                  updateLocationFromSearchBox={this.handleAddressChange}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonAutoSelect
                  id="marketZoneId"
                  label="Market Zone (Optional)"
                  items={this.props.marketZones}
                  onChange={this.handleMarketZoneChange}
                  errorText={this.state.fields.marketZoneId.errors[0]}
                  value={this.state.fields.marketZoneId.value}
                  errorStyle={{textAlign: "left"}}
                  disabled={!this.state.isFormEditable}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonAutoSelect
                  id="regionId"
                  label="Region (Optional)"
                  items={isEmpty(this.state.regions) && isNumber(this.state.fields.marketZoneId.value) ? this.props.regions : this.state.regions}
                  errorText={this.state.fields.regionId.errors[0]}
                  onChange={this.handleSelectFieldChange}
                  disabled={!this.state.isFormEditable || isEmpty(this.state.regions)}
                  value={this.state.fields.regionId.value}
                  errorStyle={{textAlign: "left"}}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="mobile"
                  label="Phone/Mobile (Optional)"
                  placeholder="Please enter a 10 digit number starting with 0"
                  value={this.state.fields.mobile.value}
                  disabled={!this.state.isFormEditable}
                  onChange={this.handleFieldChange}
                  helperText={this.state.fields.mobile.errors[0]}
                  type="number"
                  onInput = {(e) =>{
                      e.target.value = e.target.value.toString().slice(0,10);
                  }}
                />
              </div>
              { this.props.canAccessAny ?
                <div className="col-sm-5 cardForm-action top15 padding-reset">
                  <CommonButton
                    type="button"
                    variant="contained"
                    label="Cancel"
                    default
                    onClick={this.props.selectedCompanySite ? this.props.cancelEdit : this.cancelDrawer }
                  />
                  <CommonButton
                    primary={true}
                    variant="contained"
                    label="Save"
                    type="submit" />
                </div> : ''
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const marketZonesWithRegions = state.main.marketZones || [];
  const marketZones = marketZonesWithRegions.map(mz => ({ name: mz.name, id: mz.id}));
  const regions = flatMap(marketZonesWithRegions, 'regions');
  return {
    marketZonesWithRegions,
    marketZones,
    siteOperators: filter(
      state.companySettings.employees.items,
      emp => {
        return includes(['site_admin', 'site_employee'], get(emp, 'type.name'));
      }
    ),
    serverErrors: state.companies.companySites.serverErrors,
    token: state.main.user.token,
    regions: regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMarketZones: () => dispatch(fetchMarketZones()),
    addCompany: (submitData, callback, skipRedirection) => dispatch(addCompany(submitData, callback, skipRedirection, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySiteForm);
