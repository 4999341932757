import React from "react";
import { connect } from 'react-redux';
import './movementPreview.scss';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { isVisibleInCountry, toPhoneFormat } from '../../../common/utils'

class MovementPreview extends React.Component {
  render() {
    const { previewFields } = this.props;
    const { customer, customerNgr, customerContact } = previewFields.customerDetails;
    const { provider, providerContact } = previewFields.providerDetails;
    const { commodityDetails, freightContractDetails, pickupDetails, deliveryDetails } = previewFields;
    const isNGRVisible = isVisibleInCountry('ngr')
    const isABNVisible = isVisibleInCountry('abn')
    return (
      <div className="movement-preview">
        <div className="movement-header">
          <div className="agri-logo">
            {previewFields.currentUserCompanyLogo ? <img src={previewFields.currentUserCompanyLogo} alt={previewFields.currentUser.company.name } crossOrigin="anonymous"/> : <h2>{previewFields.currentUser.company.name}</h2>}
          </div>
          <div className="movement-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>Freight Movement</h4>
              {previewFields.contractReferenceNumber && <p className="reference"><span>Contract Number:</span> {previewFields.contractReferenceNumber}</p>}
              {previewFields.externalReferenceNumber && <p className="reference"><span>Contract Number:</span> {previewFields.externalReferenceNumber}</p>}
              <p className="reference"><span>Movement Number:</span> {previewFields.identifier.toUpperCase()}</p>
              <p className="reference"><span>Date Issued:</span> {previewFields.dateIssued}</p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.currentUser.company.name}</h4>
              {previewFields.currentUser.company.address.address && <p>{previewFields.currentUser.company.address.address}</p>}
              {previewFields.currentUser.email && <p><span>EMAIL: </span>{previewFields.currentUser.email}</p>}
              {previewFields.currentUser.company.mobile && <p><span>PHONE:</span> {toPhoneFormat(previewFields.currentUser.company.mobile)}</p>}
              {isABNVisible && previewFields.currentUser.company.abn && <p><span>ABN: </span> {previewFields.currentUser.company.abn}</p>}
            </div>
          </div>
        </div>
        <div className="movement-body">
          <p className="description">
            {"This document is a record of the Freight Movement agreed between the below Customer and Freight Provider following discussions between the Customer’s and Freight Provider’s representatives on "}
            {previewFields.dateIssued}{". "}
            <p>
              {"For further information please contact "}
              {previewFields.currentUserCompanyName}{" on "}{toPhoneFormat(previewFields.currentUserCompanyPhone)}
            </p>
          </p>
          <div className="movement-details">
            <div className="movement-details__left col-xs-12 col-sm-6 col-md-6">
              <h4>CUSTOMER</h4>
              <h5>{(get(customer,'entity') === 'farm' ? get(customer,'displayName') : (customerNgr?.shareholdersName ? customerNgr.shareholdersName : get(customer,'name')))}</h5>
              {isABNVisible && <p><span className="pre-label">ABN</span> <span className="pre-content">{customerNgr?.shareholdersAbn ? customerNgr?.shareholdersAbn : get(customer,'abn')}</span></p>}
              <p><span className="pre-label">Entity</span> <span className="pre-content">{customerNgr?.shareholdersEntityName? customerNgr?.shareholdersEntityName : get(customer,'entityName')}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content">{customerNgr?.ngrNumber}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{customerContact && customerContact.name}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Phone</span> <span className="pre-content">{toPhoneFormat(get(customer,'mobile') || get(customer,'company.mobile'))}</span></p>
                <p><span className="pre-label">Email</span>
                  <span className="pre-content">{customerContact && customerContact.email} </span>
                </p>
              </div>
            </div>
            {!isEmpty(provider) && <div className="movement-details__right col-xs-12 col-sm-6 col-md-6">
              <h4>FREIGHT PROVIDER</h4>
              <h5>{get(provider,'name')}</h5>
              {isABNVisible && <p><span className="pre-label">ABN</span> <span className="pre-content">{get(provider,'abn')}</span></p>}
              <p><span className="pre-label">Entity</span> <span className="pre-content">{get(provider,'entityName')}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content"></span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{get(providerContact,'name')}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Phone</span> <span className="pre-content">{toPhoneFormat(get(providerContact,'mobile') || get(providerContact,'phone'))}</span></p>
                <p><span className="pre-label">Email</span> <span className="pre-content">{get(providerContact,'email')}</span></p>
                {!isEmpty(previewFields.subFreightProvider) && <div className="movement-details__right col-xs-12 col-sm-6 col-md-6">
                <p><span className="pre-label">SUB FREIGHT PROVIDER</span><span className="pre-content">{get(previewFields.subFreightProvider,'name')}</span></p>
            </div>
            }
              </div>
            </div>}
          </div>

          <div className="movement-details margintop-20">
            <div className="movement-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>COMMODITY DETAILS</h5>
              {commodityDetails && Object.keys(commodityDetails).map(function(key, index) {
                return <p key={index}><span className="pre-label">{key}</span> <span className="pre-content">{commodityDetails[key]}</span></p>;
              })}
            </div>
            <div className="movement-details__right col-xs-12 col-sm-6 col-md-6">
              {freightContractDetails && <h5>FREIGHT CONTRACT DETAILS</h5>}
              {freightContractDetails && Object.keys(freightContractDetails).map(function(key, index) {
                return <p key={index}><span className="pre-label">{key}</span> <span className="pre-content">{freightContractDetails[key]}</span></p>;
              })}
            </div>
          </div>
          {
            (!isEmpty(pickupDetails) || !isEmpty(deliveryDetails)) &&
            <div className="movement-details margintop-20">
              <div className="movement-details__left col-xs-12 col-sm-6 col-md-6">
                <h5>{previewFields.pickupHeader} DETAILS</h5>
                {pickupDetails && Object.keys(pickupDetails).map(function (key, index) {
                  return <p key={index}><span className="pre-label">{key}</span> <span
                    className="pre-content">{pickupDetails[key]}</span></p>;
                })}
              </div>
              <div className="movement-details__right col-xs-12 col-sm-6 col-md-6">
                <h5>{previewFields.deliveryHeader} DETAILS</h5>
                {deliveryDetails && Object.keys(deliveryDetails).map(function (key, index) {
                  return <p key={index}><span className="pre-label">{key}</span> <span
                    className="pre-content">{deliveryDetails[key]}</span></p>;
                })}
              </div>
            </div>
          }
        </div>

        {previewFields.generalConditions && <div className="commodity-details movement-conditions">
          <h4>Freight Contract Conditions</h4>
          <p>{previewFields.generalConditions}</p>
        </div>}
        {previewFields.specialConditions && <div className="commodity-details movement-conditions">
          <h4>Special Conditions</h4>
          <p>{previewFields.specialConditions}</p>
        </div>}
        {
          previewFields && previewFields.note && filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).length !== 0 &&
          <div className="movement-details margintop-20">
            <div className="movement-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>Notes</h5>
              {
                  filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).map(function(key, index) {
                    if (key != 'attachments')
                      return(<p key={index}>
                          <span className="pre-label">Description: </span>
                          <span className="pre-content">{previewFields.note[key]}</span>
                        </p>);
                    return (<p key={index}>
                      <span className="pre-label">Attachment: </span>
                      <span className="pre-content">{previewFields.note[key][0]['name']}</span>
                  </p>);
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect()(MovementPreview);
