import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import { addEmployeeErrors, editEmployeeErrors, addEmployee, receiveEmployees, setResponseReceived } from '../../actions/company-settings/employees';
import { forceStopLoader, myProfileUpdated, myProfileUpdateErrors } from '../../actions/main';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import axios from 'axios';
import { apiURL } from '../main';
import { getFreshdeskURL } from '../../common/utils';
import packageJson from '../../../package.json';
const VERSION = packageJson?.version

const MESSAGES = {
  CREATE_SUCCESS: 'Employee was successfully added',
  CREATE_FAILURE: 'Something went wrong while adding an Employee',
  UPDATE_SUCCESS: 'Employee details were successfully updated',
  MY_PROFILE_UPDATE_SUCCESS: 'Profile details were successfully updated',
};

export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const getRolesSuccess = roles => {
  return { type: GET_ROLES_SUCCESS, roles };
};
export const getRoles = () => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios
      .get(`${apiURL}/profiles/roles/`, config)
      .then(response => {
        const roles = response.data.filter(item => item.name != "observer")
        dispatch(getRolesSuccess(roles));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const getCompanyEmployees = (companyId, actionCreator, queryString='', stopLoader=false) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    APIService.companies(companyId)
      .employees()
      .appendToUrl(queryString)
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
        if(stopLoader)
          dispatch(forceStopLoader());
      });
  }
};

export const getCompanyEmployeesMinimal = (companyId, actionCreator, getToken=null) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    APIService.companies(companyId)
      .employees()
      .appendToUrl('minimal/')
      .get(getToken || token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createEmployee = (companyId, data, actionCreator, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .employees()
    .post(data, token)
    .then(item => {
      if(!data.username && item.errors?.username?.length > 0 && item.errors.username[0].includes('already exists'))
        alertifyjs.error("User with this email exists and is archived. Can't create employee with this email.");

      if (item.id == null || item.errors) {
        dispatch(addEmployeeErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        dispatch(getCompanyEmployees(companyId, receiveEmployees));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (callback)
          callback();
      }
      dispatch(setResponseReceived(true));
    });
};

export const createAndGetContact = (companyId, data, actionCreator, establishmentDetails, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .employees()
    .post(data, token)
    .then(item => {
      if (item.id == null || item.errors) {
        dispatch(addEmployeeErrors(item.errors));
      } else {
        dispatch(addEmployee(item));
        establishmentDetails.company
          ? actionCreator(establishmentDetails.company, establishmentDetails.value, item.id)
          : actionCreator(establishmentDetails, item.id);
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (callback)
          callback();
      }
    });
};

export const updateEmployee = (companyId, employeeId, data, actionCreator, isMyProfile = false, callback) => (dispatch, getState) => {
  const { token, user } = getState().main.user;
  APIService.companies(companyId)
    .employees(employeeId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        if (isMyProfile) {
          dispatch(myProfileUpdateErrors(item.errors));
        } else {
          dispatch(editEmployeeErrors(item.errors));
        }
      } else {
        if (user.id === item.id) {
          const myProfileFields = pick(item, ['title', 'firstName', 'lastName', 'type', 'mobile', 'email', 'farm', 'username']);
          dispatch(myProfileUpdated(myProfileFields));
        }
        if (isMyProfile) {
          alertifyjs.success(MESSAGES.MY_PROFILE_UPDATE_SUCCESS);
        } else {
          dispatch(actionCreator(item));
          alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
          if (callback)
            callback();
        }
      }
    });
};

export const getLoggedInUserDetails = () => (dispatch, getState) => {
  const { token, user } = getState().main.user;
  APIService.companies(user.companyId)
    .employees(user.id)
    .get(token, {}, { many_to_one_relations: 'company__platformfeatures,company__address' })
    .then(item => {
      if (item.id) {
        dispatch(myProfileUpdated(item));
      }
    });
};

export const canCreateEmployee = (id, type, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const apiService = type === 'farm' ? APIService.farms(id) : APIService.companies(id);
  apiService['employees/new']()
    .get(token)
    .then(res => {
      if (res.result) {
        if (callback) {
          callback();
        }
      } else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to create employee for the party because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank' rel="noopener noreferrer">FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
    });
};

export const deleteEmployee = (item, actionCreator)  => (dispatch, getState) => {
  if (!item.id) return;

  alertifyjs.confirm(
    'Delete Employee',
    `Are you sure you want to delete this employee, ${item.name}?`,
    () => {
      const { token } = getState().main.user;
      APIService.profiles(item.id)
        .put({'action': 'archived'}, token)
        .then(() => {
          alertifyjs.success('Employee deleted successfully', 2, () => {
            dispatch(actionCreator(item));
          });
        });
    },
    () => {});
};

export const activateEmployee = (item, actionCreator)  => (dispatch, getState) => {
  if (!item.id) return;

  alertifyjs.confirm(
    'Activate Employee',
    `Are you sure you want to activate this employee, ${item.name}?`,
    () => {
      const { token } = getState().main.user;
      APIService.profiles(item.id)
        .put({'action': 'activate'}, token)
        .then(() => {
          alertifyjs.success('Employee activated successfully', 2, () => {
            dispatch(actionCreator(item));
          });
        });
    },
    () => {});
};
