import React from 'react';
import { connect } from "react-redux";
import GenericTable from "../components/GenericTable";
import {
    getSelectedOrder,
    receiveOrder,
    getPaginatedOrdersResponse,
    getPackOrders,
  } from "../actions/companies/orders";
import { find, includes } from 'lodash';
import { ORDERS_TABLE_COLUMN_LIMIT, PACK_ORDERS_HEADERS } from "../common/constants";
import FreightOrderActions from '../components/freights/FreightOrderActions';
import { getFreightOrderActionMenuOptions, regenerateOrderPDF } from '../components/freights/utils';
import APIService from '../services/APIService';
import {isLoading} from "../actions/main";
import { handleFreightOrderOptionsMapper } from "../actions/companies/orders";
import { get, isEmpty } from 'lodash';
import { getCustomColumns } from '../actions/companies';
import { getContractIdFromCurrentRoute } from '../common/utils';

class PackOrdersTable extends React.Component {

    componentDidMount() {
      this.props.getCustomColumns('pack_order_table');
    }

    render() {
        return (
            <div>
              <GenericTable {...this.props} />
              <FreightOrderActions {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  const token = state.main.user.token;
  const packOrderColumns = [...PACK_ORDERS_HEADERS];
  let customColumns = packOrderColumns;
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(packOrderColumns, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > ORDERS_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, ORDERS_TABLE_COLUMN_LIMIT-1);
  }
  return {
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    clearSearch: getPaginatedOrdersResponse,
    columns: customColumns,
    currentUser: state.main.user.user,
    items: state.companies.orders.cogOrders,
    globalSearch: true,
    optionsItems: item => getFreightOrderActionMenuOptions(item, subItems, clickedOption),
    order: 'desc',
    orderBy: 'createdAt',
    paginationData: state.companies.orders.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedOrderId: state.companies.orders.selectedOrderId,
    selectedOrder: state.companies.orders.selectedOrder,
    selectedOrderTypeId: state.companies.orders.selectedOrderTypeId,
    useNestedOptionMenu: true,
    clickedOption: clickedOption,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    subItems: subItems,
    token,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    voidFilter: true,
    displayIDColumn: 'identifier'
  };
};

const mapDispatchToProps = (dispatch, that) => {
    return {
      handleOptionClick: (event, item, baseEntity) => {
        const canPassContractDetailsMappingFrom = (get(item, 'key') === 'void_and_duplicate' && that.isContractDetailsModule)
        const mappingFrom = canPassContractDetailsMappingFrom ? 'contractDetailsModule' : 'listing';
        if(get(item, 'key') === 'regenerate_pdf')
          return regenerateOrderPDF(baseEntity);
        else
          dispatch(handleFreightOrderOptionsMapper(event, item, baseEntity, mappingFrom));
      },
      handleDefaultCellClick: (item) => {
        dispatch(getSelectedOrder(item.id, receiveOrder, false, false, false, false, true));
        dispatch(isLoading('orderDetail'));
        document.location.hash = '/pack/orders/' + item.id + '/order';
      },
      navigateTo: url => dispatch(getPackOrders(url, true)),
      changePageSize: (url, pageSize) => {
        if (includes(url, '?')){
          url = `${url}&page_size=${pageSize}`;
        } else {
          url = `${url}?page_size=${pageSize}`;
        }
        dispatch(getPackOrders( url, true));
      },
      getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
        const service = APIService.freights().orders();
        service.appendToUrl(`web/search/?page_size=${pageSize}`);
        const contractId = getContractIdFromCurrentRoute();
        if(contractId) {
          service.appendToUrl(`&commodity_contract_id=${contractId}`);
        }
        if (page) {
          service.appendToUrl(`&page=${page}`);
        }
        if(searchText) {
          service.appendToUrl(`&search=${searchText}`);
        }
        if(orderBy) {
          service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
        }
        if(includeVoid) {
          service.appendToUrl(`&include_void=${includeVoid}`);
        }
        return service.URL;
      },
      getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
      dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackOrdersTable);
