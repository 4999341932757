import APIService from '../../services/APIService';
import {
  startFetchingCommodities,
  startFetchingCommoditiesWithVarieties,
  startFetchingCommoditiesWithGrades,
  startFetchingCommoditiesWithVarietiesAndGrades,
  resetIsFetchingCommodities,
  resetIsFetchingCommoditiesWithVarieties,
  resetIsFetchingCommoditiesWithGrades,
  resetIsFetchingCommoditiesWithVarietiesAndGrades,
  receiveCommodities,
  receiveCommoditiesWithVarieties,
  receiveCommoditiesWithGrades,
  receiveCommoditiesWithVarietiesAndGrades,
} from '../master/commodities';
import { getCurrentCountry } from '../../common/utils'

export const getCommodities = (includeUnknown=false) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { isFetchingCommodities } = getState().master.commodities;
  if (!isFetchingCommodities) {
    dispatch(startFetchingCommodities());
    APIService.commodities()
      .get(token, null, {countryId: getCurrentCountry().id, includeUnknown: includeUnknown})
      .then(items => {
        dispatch(receiveCommodities(items));
      })
      .catch(() => {
        dispatch(resetIsFetchingCommodities());
      });
  }
};

export const getCommoditiesWithVarieties = () => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { isFetchingCommoditiesWithVarieties } = getState().master.commodities;
  if (!isFetchingCommoditiesWithVarieties) {
    dispatch(startFetchingCommoditiesWithVarieties());
    APIService.commodities()
      .get(token, null, { includes: 'variety', countryId: getCurrentCountry().id })
      .then(items => {
        dispatch(receiveCommoditiesWithVarieties(items));
      })
      .catch(() => {
        dispatch(resetIsFetchingCommoditiesWithVarieties());
      });
  }
};

export const getCommoditiesWithGrades = (includeUnknown=false) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { isFetchingCommoditiesWithGrades } = getState().master.commodities;
  if (!isFetchingCommoditiesWithGrades) {
    dispatch(startFetchingCommoditiesWithGrades());
    APIService.commodities()
      .get(token, null, { includes: 'grade', countryId: getCurrentCountry().id, includeUnknown: includeUnknown })
      .then(items => {
        dispatch(receiveCommoditiesWithGrades(items));
      })
      .catch(() => {
        dispatch(resetIsFetchingCommoditiesWithGrades());
      });
  }
};

export const getCommoditiesWithVarietiesAndGrades = (includeUnknown=false) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { isFetchingCommoditiesWithVarietiesAndGrades } = getState().master.commodities;
  if (!isFetchingCommoditiesWithVarietiesAndGrades) {
    dispatch(startFetchingCommoditiesWithVarietiesAndGrades());
    APIService.commodities()
      .get(token, null, { includes: 'variety,grade', countryId: getCurrentCountry().id, includeUnknown: includeUnknown})
      .then(items => {
        dispatch(receiveCommoditiesWithVarietiesAndGrades(items));
      })
      .catch(() => {
        dispatch(resetIsFetchingCommoditiesWithVarietiesAndGrades());
      });
  }
};
