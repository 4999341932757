import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  InputAdornment, MenuItem, TextField, FormControl, FormHelperText, InputLabel, Select,
} from '@mui/material';
import {
  isEmpty, isEqual, get, some, forEach, mapValues, set, has,
} from 'lodash';
import APIService from '../../services/APIService';
import {
  required,
  maxLength,
} from '../../common/validators';
import { AUSTRALIA_COUNTRY_ID, BALES_UNIT } from "../../common/constants";
import { getCountryDisplayUnit } from "../../common/utils";
import { positiveDecimalFilter } from '../../common/input-filters';
import GoogleMapPopup from '../common/GoogleMapPopup';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';

class StorageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddressSelected: false,
      defaultLocation: {},
      fields: {
        barcodeNumber: {
          value: '',
          validators: [],
          errors: []
        },
        name: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        address: {
          value: {},
          validators: [required()],
          errors: []
        },
        type: {
          value: '',
          validators: [required()],
          errors: []
        },
        size: {
          value: '',
          validators: [required()],
          errors: []
        },
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedHomeStorage = this.setAllFieldsValuesBySelectedHomeStorage.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.getSelectedFarm = this.getSelectedFarm.bind(this);
  }

  componentDidMount() {
    if(!this.props.selectedHomeStorage) {
      this.getSelectedFarm();
    }
    this.setAllFieldsValuesBySelectedHomeStorage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedHomeStorage) {
      if (this.props.selectedHomeStorage.id !== prevProps.selectedHomeStorage.id) {
        this.setAllFieldsValuesBySelectedHomeStorage();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      if(has(newState.fields,key)){
        newState.fields[key].errors = value;
      }
    });
    this.setState(newState);
  }

  getSelectedFarm() {
    APIService.farms(this.props.farmId).get(this.props.token)
      .then((farm) => {
        const address = {
          formatted_address: farm.address.address,
          lat: farm.address.latitude,
          lng: farm.address.longitude,
          suburb: farm.address.suburb,
          state: farm.address.state,
          stateId: farm.address.stateId,
          postCode: farm.address.postCode,
          streetName: farm.address.streetName,
        };
        const newState = {...this.state};
        newState.defaultLocation = address;
        this.setState(newState);
      });
  }

  handleStorageTypeChange = (id, value) => this.setFieldValue(id, value, () => {
    if(isEmpty(this.state.fields.address.validators))
      this.setState({fields: {...this.state.fields, address: {...this.state.fields.address, validators: [required()]}}})
  })


  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors(async () => {
      const isFormInvalid = some(this.state.fields, (field) => {
        return !isEmpty(field.errors);
      });
      const submitData = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      const data = {
        ...submitData,
        address: {
          latitude: submitData.address.lat,
          longitude: submitData.address.lng,
          address: submitData.address.formatted_address,
          suburb: submitData.address.suburb,
          state: submitData.address.state,
          postCode: submitData.address.postCode,
          streetName: submitData.address.streetName,
        },
      };
      data.size = parseFloat(data.size);
      if (!isFormInvalid) {
        this.props.submit(this.props.farmId, data, this.props.afterSubmit);
      }
    });
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleAddressChange(data) {
    const address = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    const newState = {...this.state};
    newState.fields.address.value = address;
    newState.fields.address.errors = [];
    newState.isAddressSelected = true;
    this.setState(newState);
  }

  handleSelectChange(key, event) {
    this.setFieldValue(key, event.target.value);
  }

  setFieldValue(key, value, callback) {
    const newState = {...this.state};
    set(newState.fields, key + '.value', value);
    this.setState(newState, () => {
      this.setFieldErrors(key);
      if (callback) callback(this.state);
    });
  }


  getFieldErrors(key) {
    const errors = [];
    const value = (key=='address' && !this.state.isAddressSelected) ? '' : get(this.state.fields,`${key}.value`);
    const validators = get(this.state.fields,`${key}.validators`) || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = { ...this.state };
    set(newState.fields, key + '.errors', this.getFieldErrors(key));
    this.setState(newState);
  }

  setAllFieldsValuesBySelectedHomeStorage() {
    if (this.props.selectedHomeStorage) {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedHomeStorage[key];
      });

      const addressValue = {
        formatted_address: this.props.selectedHomeStorage.address['address'],
        lat: this.props.selectedHomeStorage.address['latitude'],
        lng: this.props.selectedHomeStorage.address['longitude'],
        suburb: this.props.selectedHomeStorage.address['suburb'],
        state: this.props.selectedHomeStorage.address['state'],
        postCode: this.props.selectedHomeStorage.address['postCode'],
        streetName: this.props.selectedHomeStorage.address['streetName'],
      };
      newState.fields.address.value = addressValue;
      newState.isAddressSelected = true;
      this.setState(newState);
    }
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleSizeFieldChange = (event) => {
    this.setFieldValue(event.target.id, event.target.value, (state) => {
      const newState = { ...state };
      this.setState(newState, () => {
          if(get(this.state,'fields.size.value')){
            this.setFieldErrors('size');
          }
        }
      );
    });
  };


  isFieldHayStackTypeSelected() {
     return isEqual(get(this.state.fields, 'type.value'), 'field_hay_stack');
  }

  getSizeUnit() {
    if(this.isFieldHayStackTypeSelected() && get(this.props.currentUser, 'company.countryId') == AUSTRALIA_COUNTRY_ID)
      return BALES_UNIT;
    return get(this.props.currentUser, 'unit') || getCountryDisplayUnit();
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="name"
                  label="Storage Name"
                  helperText={this.state.fields.name.errors[0]}
                  onChange={this.handleFieldChange}
                  onBlur={this.onFieldBlur}
                  value={this.state.fields.name.value}
                  maxLength="100"
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <FormControl
                  error={!!this.state.fields.type.errors[0]}
                  style={{width: '100%'}}
                  variant="standard">
                  <InputLabel htmlFor="type">Type</InputLabel>
                  <Select
                    id="type"
                    value={this.state.fields.type.value}
                    onChange={event => this.handleStorageTypeChange('type', event.target.value)}
                    variant="standard">
                    <MenuItem value="silo">Silo</MenuItem>
                    <MenuItem value="bag">Bag</MenuItem>
                    <MenuItem value="bin">Bin</MenuItem>
                    <MenuItem value="shed">Shed</MenuItem>
                    <MenuItem value="bunker">Bunker</MenuItem>
                    <MenuItem value="field_hay_stack">Field Hay Stack</MenuItem>
                    <MenuItem value="silage_pit">Silage Pit</MenuItem>
                    <MenuItem value="tank">Tank</MenuItem>
                  </Select>
                  <FormHelperText>{this.state.fields.type.errors[0]}</FormHelperText>
                </FormControl>
              </div>
              <div className="col-sm-12 form-wrap-70">
                <TextField
                  error={!isEmpty(this.state.fields.size.errors[0])}
                  id="size"
                  label="Size"
                  value={this.state.fields.size.value}
                  fullWidth
                  helperText={this.state.fields.size.errors[0]}
                  onChange={this.handleSizeFieldChange}
                  onBlur={this.onFieldBlur}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>
                      {
                       this.getSizeUnit()
                      }
                      </InputAdornment>
                  }}
                  variant="standard" />
              </div>
              {
                this.isFieldHayStackTypeSelected() &&
                <div className="col-sm-12 form-wrap-70">
                  <TextField
                    error={!isEmpty(this.state.fields.barcodeNumber.errors[0])}
                    id="barcodeNumber"
                    label="Barcode Number (Optional)"
                    value={this.state.fields.barcodeNumber.value}
                    fullWidth
                    helperText={this.state.fields.barcodeNumber.errors[0]}
                    onChange={this.handleFieldChange}
                    onBlur={this.onFieldBlur}
                    variant="standard" />
              </div>
              }
              <div className="col-sm-12 form-wrap-70">
                <GoogleMapPopup
                  id="address"
                  location={this.state.fields.address.value}
                  defaultLocation={this.state.defaultLocation}
                  errors={this.state.fields.address.errors}
                  onDone={this.handleAddressChange}
                  showDefaultLocationOnLabel={!!this.props.selectedHomeStorage}
                  updateLocationFromSearchBox={this.handleAddressChange}
                />
              </div>
            </div>
            <div className="col-sm-12 cardForm-action top15 padding-reset">
              <CommonButton
                variant="outlined"
                label="Cancel"
                type="button"
                default
                onClick={this.props.selectedHomeStorage ? this.props.cancelEdit : this.props.closeDrawer}
              />
              <CommonButton
                variant="contained"
                primary={true}
                label="Save"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    serverErrors: state.companies.storages.serverErrors,
    token: state.main.user.token,
    currentUser: state.main.user.user,
  };
};

export default connect(mapStateToProps)(StorageForm);
