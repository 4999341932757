import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  currentUserCompanyType,
} from '../common/utils';
import {
  WHITE,
  WARNING_ORANGE,
} from '../common/constants';
import { get } from 'lodash';

class NonProductionEnvironmentHeader extends Component {
  render() {
    const {setEnvLabel, serverEnv, user, token, version} = this.props;
    const isNonProd = setEnvLabel && !['production', 'staging'].includes(serverEnv)
    return (
      <div>
      {
        isNonProd ?
        <div id='non-prod-header'>
          <div style={{
            position: 'fixed',
            zIndex: '1001',
            right: '32%',
            padding: '5px 10px',
            backgroundColor: WARNING_ORANGE,
            color: WHITE,
            fontSize: '10px',
            width: 'auto',
            maxHeight: '87px',
            minHeight: '87px',
          }}>
            <div>
              [{get(user, 'id')}] {get(user, 'name')} ({get(user, 'type.displayName')}) </div>
            <div>
              [{get(user, 'companyId')}] {get(user, 'company.name')} ({currentUserCompanyType()})
            </div>
            <div> {`Token: ${token}`} </div>
            <div>[{version}] NON-PRODUCTION SYSTEM</div>
          </div>
        </div>
        : ''
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serverEnv: state.main.serverEnv,
    setEnvLabel: state.main.setEnvLabel,
    token: state.main.user.token,
    user: state.main.user.user,
    version: state.main.version || 'vUnknown',
  };
};

export default connect(mapStateToProps)(NonProductionEnvironmentHeader);
