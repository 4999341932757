import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import { InputAdornment, Autocomplete, TextField, Tooltip } from '@mui/material/';
import { required } from '../../common/validators';
import '../../common/forms.scss';
import CommonButton from '../common/CommonButton';
import CommonSelect from '../common/select/CommonSelect';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import CommonTextField from '../common/CommonTextField';
import APIService from '../../services/APIService';
import { mapValues, map, isEmpty, get, includes, compact, set, flatten, filter, uniqWith, isEqual, forEach, every} from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { getCommoditiesWithGrades } from '../../actions/api/commodities';
import { cartesian, getSeasons, getCountryCurrency } from '../../common/utils';
import CommonDatePicker from '../common/CommonDatePicker';
import GradeMultiSelect from '../common/autocomplete/GradeMultiSelect';

class FeeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      sites: [],
      open: false,
      frequency_options: [
        'Monthly',
        'Weekly'
      ],
      selectedItems: [],
      company: undefined,
      fields: {
        siteIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        commodityIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        gradeIds: {
          value: [],
          validators: [],
          errors: [],
        },
        perUnit: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        type: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        frequency: {
          value: 'Monthly',
          validators: [required()],
          errors: [],
        },
        customerGroups: {
          value: [],
          validators: [],
          errors: [],
        },
        customerCompanies: {
          value: [],
          validators: [],
          errors: [],
        },
        customers: {
          value: [],
          validators: [],
          errors: [],
        },
        seasons: {
          value: [],
          validators: [],
          errors: [],
        },
        gracePeriod: {
          value: null,
          validators: [],
          errors: [],
        },
        startDate: {
          value: null,
          validators: [required()],
          errors: [],
        },
        endDate: {
          value: null,
          validators: [required()],
          errors: [],
        },
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleGracePeriodChange = this.handleGracePeriodChange.bind(this);
    this.getGroupsAndCompanies = this.getGroupsAndCompanies.bind(this);
    this.handleRedundantFeeCreation = this.handleRedundantFeeCreation.bind(this);
  }

  componentDidMount() {
    const { open, dispatch, selected, type } = this.props;
    this.getSites();
    this.getCompany();
    if (type === 'exception') {
      this.getCompanies();
    }
    dispatch(getCommoditiesWithGrades());
    if (this.state.open != open)
      this.setState({ open: open });
    if (selected)
      this.setAllFieldValuesFromSelectedFee();
  }

  componentDidUpdate(prevProp, prevState) {
    const { open } = this.props;
    if (this.state.open != open)
      this.setState({ open: open });
    if (prevState.fields.type.value != this.state.fields.type.value){
      const newState = { ...this.state.fields };
      if(this.state.fields.type.value == 'Storage Fees') {
        newState.seasons.validators = [required()];
      }
      if(this.state.fields.type.value != 'Storage Fees' ) {
        newState.seasons.validators = [];
        newState.seasons.value = [];
        newState.gracePeriod.validators = [];
        newState.gracePeriod.value = null;
      }
      this.setState(newState);
    }
  }

  setAllFieldValuesFromSelectedFee() {
    const { selected } = this.props;
    if (selected) {
      const newState = { ...this.state };
      newState.fields.perUnit.value = selected.perUnit;
      newState.fields.siteIds.value = [selected.siteId];
      newState.fields.commodityIds.value = [selected.commodityId];
      newState.fields.type.value = selected.type;
      newState.fields.frequency.value = [selected.frequency];
      newState.fields.perUnit.value = [selected.fees];
      newState.fields.seasons.value = [selected.season];
      newState.fields.gracePeriod.value = [selected.gracePeriod];
      newState.fields.customerGroups.value = compact([selected.forGroupId]);
      newState.fields.customerCompanies.value = compact([selected.forCompanyId]);
      newState.fields.customers.value = compact([selected.forGroupId, selected.forCompanyId]);
      newState.fields.startDate.value = selected.startDate.split("T")[0];
      newState.fields.endDate.value = selected.endDate.split("T")[0];
      newState.fields.gradeIds.value = [selected.gradeId];
      newState.selectedItems = selected.forCompanyId ? this.props.companies.filter(company => company.id == selected.forCompanyId) : this.props.groups.filter(group => group.id == selected.forGroupId);
      this.setState(newState);
    }
  }

  toSelectOptions(dataList) {
    return map(dataList, item => ({ id: item, name: item }));
  }

  getCompanies() {
    APIService.companies().appendToUrl('directory/names/').get().then(companies => {
      this.setState({ companies: companies });

    });
  }

  handleChange(value, fieldId) {
    const newState = { ...this.state.fields };
    if (value) {
      set(newState, `${fieldId}.value`, value);
      this.getFieldErrors(newState[fieldId])
      this.setState(newState);
    }
  }

  handleGracePeriodChange(value, fieldId) {
    const newState = { ...this.state.fields };
    if (value) {
      set(newState, `${fieldId}.value`, value);
      this.setState(newState);
    }
  }

  getSites() {
    const { companyId } = this.props;
    APIService.companies(companyId).company_sites().appendToUrl('minimal/').get().then(sites => this.setState({ sites: map(sites, site => ({ id: site.id, name: site.name, isActive: site.isActive })) }));
  }

  getCompany() {
    const { companyId } = this.props;
    APIService.companies(companyId)
      .get()
      .then(company => this.setState({ company: company }, () => {
        if (this.state.company.warehouseInvoiceFrequency === 'weekly') {
          const newState = {...this.state};
          newState.fields.frequency.value = 'Weekly';
          this.setState(newState);
        }
      }));
  }

  getCompaniesOfSelectedGroups() {
    let groups = uniqWith(this.state.fields.customerGroups.value, isEqual);
    let companies = [];
    groups.map(group_id =>{
      const items = this.props.groups.find(group => group.id === group_id)?.companyIdsBelongingToGroup;
      companies = [...companies, ...items];
    })
    return companies
  }

  getPayloadCompanies(args, data) {
    let payload =[]
    const { seasons, gracePeriod, customerCompanies, startDate, endDate, gradeIds } = data;
    args.push(customerCompanies);
    if (includes([1,2], gracePeriod))
      args.push([gracePeriod]);

    let results = cartesian(...args);
    results = uniqWith(results, isEqual)
    map(results, result => {
      let res = {
        perUnit: result[0],
        commodityId: result[1],
        siteId: result[2],
        frequency: result[3],
        type: result[4],
        startDate: startDate,
        endDate: endDate
      };
      let gradeIndex = 5;
      let gradeIdsExists = !isEmpty(gradeIds);
      if (!isEmpty(seasons) && !isEmpty(customerCompanies)) {
        res['season'] = result[5];
        gradeIndex += 1
        let companyAndGracePeriodIndex = gradeIdsExists ? 7 : 6;
        res['forCompanyId'] = result[companyAndGracePeriodIndex];
        companyAndGracePeriodIndex += 1
        res['gracePeriod'] = includes([1,2], gracePeriod) ? result[companyAndGracePeriodIndex] : null; 
        args.splice(companyAndGracePeriodIndex - 1, 1)
        args.splice(companyAndGracePeriodIndex, 1)
      }

      if (!isEmpty(seasons) && isEmpty(customerCompanies)) {
          res['season'] = result[5];
          gradeIndex += 1
          let companyAndGracePeriodIndex = gradeIdsExists ? 7 : 6;
          res['gracePeriod'] = includes([1,2], gracePeriod) ? result[companyAndGracePeriodIndex] : null;;
          args.splice(companyAndGracePeriodIndex, 1)}
      if (isEmpty(seasons) && !isEmpty(customerCompanies)) {
          let companyAndGracePeriodIndex = gradeIdsExists ? 6 : 5;
          res['forCompanyId'] = result[companyAndGracePeriodIndex];
          args.splice(companyAndGracePeriodIndex, 1)
      }
      if (gradeIdsExists)
        res['gradeId'] = result[gradeIndex];
      payload.push(res);
      
    });
    return payload;
  }

  getGroupsPayload(args, data) {
    const { seasons, gracePeriod, customerGroups, startDate, endDate, gradeIds } = data;
    let payload = []
    args.push(customerGroups);
    if (includes([1,2], gracePeriod))
      args.push([gracePeriod]);
    let results = cartesian(...args);
    results = uniqWith(results, isEqual)
    map(results, result => {
      let res = {
        perUnit: result[0],
        commodityId: result[1],
        siteId: result[2],
        frequency: result[3],
        type: result[4],
        startDate: startDate,
        endDate: endDate
      };
      let gradeIndex = 5;
      let gradeIdsExists = !isEmpty(gradeIds);
      if (!isEmpty(seasons) && !isEmpty(customerGroups)) {
        res['season'] = result[5];
        gradeIndex += 1
        let groupAndGracePeriodIndex = gradeIdsExists ? 7 : 6;
        res['forGroupId'] = result[groupAndGracePeriodIndex];
        groupAndGracePeriodIndex += 1
        res['gracePeriod'] = includes([1,2], gracePeriod) ? result[groupAndGracePeriodIndex] : null; 
        args.splice(groupAndGracePeriodIndex-1, 1)
        args.splice(groupAndGracePeriodIndex, 1)
      }

      if (!isEmpty(seasons) && isEmpty(customerGroups)) {
          res['season'] = result[5];
          gradeIndex += 1
          let groupAndGracePeriodIndex = gradeIdsExists ? 7 : 6;
          res['gracePeriod'] = includes([1,2], gracePeriod) ? result[groupAndGracePeriodIndex] : null;;
          args.splice(groupAndGracePeriodIndex, 1)}
      if (isEmpty(seasons) && !isEmpty(customerGroups)) {
          let groupAndGracePeriodIndex = gradeIdsExists ? 6 : 5;
          res['forGroupId'] = result[groupAndGracePeriodIndex];
          args.splice(groupAndGracePeriodIndex, 1)
      }
      if (gradeIdsExists)
        res['gradeId'] = result[gradeIndex];
      payload.push(res);
    });
    return payload;
  }

  getPayload() {
    let data = mapValues(this.state.fields, 'value');
    const { commodityIds, siteIds, perUnit, frequency, type, seasons, gradeIds, customerGroups, customerCompanies, gracePeriod, startDate, endDate } = data;
    let companiesPayload = []
    let groupsPayload = []
    let args = [[perUnit], commodityIds, siteIds, [frequency], [type]];
    let payload = [];
    if (!isEmpty(seasons))
      args.push(flatten([seasons]));
    if (!isEmpty(gradeIds))
      args.push(flatten([gradeIds]));
    if(this.props.type == 'exception') {
      if (!isEmpty(customerCompanies))
        companiesPayload = this.getPayloadCompanies(args, data)

      if (!isEmpty(customerGroups))
        groupsPayload = this.getGroupsPayload(args, data)
      payload = [...companiesPayload, ...groupsPayload];
    } else {
      if (includes([1,2], gracePeriod))
        args.push([gracePeriod]);

      let results = cartesian(...args);
      results = uniqWith(results, isEqual)
      map(results, result => {
        let res = {
          perUnit: result[0],
          commodityId: result[1],
          siteId: result[2],
          frequency: result[3],
          type: result[4],
          startDate: startDate,
          endDate: endDate
        };
        let gradeIndex = 5;
        if (!isEmpty(seasons)) {
          res['season'] = result[5];
          gradeIndex += 1
          res['gracePeriod'] = includes([1,2], gracePeriod) ? result[!isEmpty(gradeIds) ? 7 :6] : null;
        }
        if (!isEmpty(gradeIds))
          res['gradeId'] = result[gradeIndex]
        payload.push(res);
      });
    }

    let allPayloadCommodityIds = [...new Set(payload.map(obj => obj.commodityId))];
    const commodityGradeMapping = {};
    allPayloadCommodityIds.forEach(commodityId => {
      const gradeIds = this.props.grades.filter(gradeObj => gradeObj.commodityId === commodityId).map(gradeObj => gradeObj.id);
      if (gradeIds.length > 0)
        commodityGradeMapping[commodityId] = gradeIds;
    });
    return payload.filter(obj => {
      const { commodityId, gradeId } = obj;
      if (gradeId) {
        if (commodityGradeMapping[commodityId] && commodityGradeMapping[commodityId].includes(gradeId))
          return true;
        else {
          const containsGradeId = payload.some(payloadObj => {
            return commodityGradeMapping[commodityId].some(mappedGradeId => payloadObj.gradeId === mappedGradeId);
          });
          if (!containsGradeId) {
            obj.gradeId = null;
            return true;
          }
          return false;
        }
      }
      return true;
    });  
  }

  isEndDateValid() {
    const end_date = new Date(this.state.fields.endDate.value);
    const start_date = new Date(this.state.fields.startDate.value);
    if (end_date < start_date)
      return false;
    return true;
  }

  getFieldErrors(field){
    set(field, 'errors', []);
    field.validators.forEach((validator) => {
      if (validator.isInvalid(field.value)) {
        field.errors.push(validator.message);
      }
    });
  }

  applyValidatorsOn() {
    const newState = {...this.state};
    forEach(newState.fields, (field) => {
      this.getFieldErrors(field);
    });
    this.setState(newState);
  };

  isValid() {
    const { fields } = this.state;
    const standardCondition =  every(fields, state => isEmpty(state.errors));
    if (this.props.type === 'exception')
      return standardCondition ? (!isEmpty(fields.customers.value) || !isEmpty(this.state.selectedItems)) ? '' : 'Either customers or company group is required' : 'Please fill all mandatory Fields';
    return standardCondition ? '' : 'Please fill all mandatory Fields';
  }

  handleRedundantFeeCreation(payload) {
    const { allFees, groups } = this.props;
    const { companies } = this.state;
    const matchingItems = [];
    let match = true;
    forEach(payload, payloadItem => {
      if (allFees.length != 0) {
        forEach(allFees, feeItem => {
          match = true;
          for (const key in payloadItem) {
            if(key == 'forGroupId' && payloadItem[key]) {
              if (feeItem[key] && payloadItem[key] != feeItem[key])
                match = false;
              else if (feeItem['forCompanyId']) {
                let group = groups.find(group => group.id == payloadItem.forGroupId)
                let companyIds = group && group?.companyIdsBelongingToGroup.map(company => company.id)
                if (companyIds && !companyIds.includes(feeItem['forCompanyId']))
                  match = false;
              }
            }

            if (key == 'forCompanyId' && payloadItem[key]) {
              if (feeItem[key] && payloadItem[key] != feeItem[key])
                match = false;
              else if (feeItem['forGroupId']) {
                let group = groups.find(group => group.id == feeItem?.forGroupId)
                let companyIds = group && group?.companyIdsBelongingToGroup.map(company => company.id)
                if (companyIds && !companyIds.includes(payloadItem['forCompanyId']))
                  match = false;
              }
            }
            if (!includes(['forGroupId', 'forCompanyId', 'perUnit'], key) && payloadItem[key] != feeItem[key])
              match = false;
          }
          if (match) 
            matchingItems.push(feeItem);
        });
      }
    });
    let itemsList = [];
    if (matchingItems.length > 0) {
      const commonStyle = 'text-align: center; border: 1px solid #000; padding: 8px;';
      matchingItems && matchingItems.map((item) => {
        let name = (item?.forCompanyId) ? companies.find((company) => company.id == item.forCompanyId)?.name : 
        groups.find(group => group.id == item?.forGroupId)?.name
        let site = this.state.sites.find((site) => site.id == item.siteId)?.name
        let commodity = this.props.commodities.find((commodity) => commodity.id == item.commodityId)?.displayName

        itemsList += `<tr>
        <td style="${commonStyle}">${name}</td>
        <td style="${commonStyle}">${site}</td>
        <td style="${commonStyle}">${commodity}</td>
        <td style="${commonStyle}">${item.season ? item.season : '-'}</td>
        </tr>`
      })
      return {'items': itemsList, 'type': matchingItems[0].type}
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(this.props.selected)
      alertifyjs.alert(
        'Warning',
        'This will recalculate the not invoiced section fees and not the Invoices that are billed.',
        () => this.submitAction()
      ).set('labels', { ok: 'Cancel' })
    else
      this.submitAction()
  }

  submitAction = () => {
    this.applyValidatorsOn();
    const errorMessage = this.isValid();
    if(errorMessage){
      alertifyjs.error(errorMessage);
      return;
    }
    if (!this.isEndDateValid()){
      alertifyjs.error("End Date cannot be less than the Start Date")
      return;
    }
    let companies = this.getCompaniesOfSelectedGroups()
    let commonItems = companies && companies.map(company => company.id).filter((companyId) => this.state.fields.customerCompanies.value.includes(companyId))
    if (commonItems.length > 0) {
      let items = []
      let groups = uniqWith(this.state.fields.customerGroups.value, isEqual)
      groups.map(group_id =>{
        const group = this.props.groups.find(group => group.id === group_id);
        group?.companyIdsBelongingToGroup.map(company => {
          if (commonItems.includes(get(company, 'id'))) 
            items.push(`${company.name}(${group?.name})` )
        })
      })
      let entity = commonItems.length == 1 ? 'company has' : 'companies have'
      let itemsList = items.map(item => `<li>${item}</li>`).join('\n');
      let message = `<div>
                        <p>The following ${entity} been selected twice:</p>
                        <ul>${itemsList}</ul>
                    </div>`;
      alertifyjs.alert('Warning', message).set('labels', { ok: 'Cancel' })
      return  
    }

    let payload = this.getPayload();
    let action = 'post';
    let path = 'fees/';
    if (this.props.selected) {
      action = 'put';
      payload = payload[0];
      path += `${this.props.selected.id}/`;
    }
    let items = []
    if (this.props.type == 'exception' && !this.props.selected) {
      let redundantFees = this.handleRedundantFeeCreation(payload);
      if (this.props.type == 'exception' && redundantFees && redundantFees['items'].length != 0) {
        items = redundantFees['items']
        let entity = items.length == 1 ? 'company/group' : 'companies/groups'
        let message = `<div>
                          <p>The ${redundantFees['type']} has already been applied to the following ${entity}.</p>
                          <div style="max-height: 400px; overflow-y: auto;">
                          <table style="border-collapse: collapse; width: 100%;">
                            <thead>
                              <tr>
                                <th style="border: 1px solid #000; padding: 8px;">Company/Group Name</th>
                                <th style="border: 1px solid #000; padding: 8px;">Site</th>
                                <th style="border: 1px solid #000; padding: 8px;">Commodity</th>
                                <th style="border: 1px solid #000; padding: 8px;">Season</th>
                              </tr>
                            </thead>
                            <tbody>
                                ${items}
                            </tbody>
                          </table>
                          </div>
                      </div>`;
        alertifyjs.alert('Warning', message).set('labels', { ok: 'Cancel' })
      }
    }

    APIService.farms().appendToUrl(path)[action](payload).then(data => {
      if (get(data, 'details[0]')) {
          if (includes(data.details[0], 'duplicate key') || isEqual(data.details, 'Redundant fees'))
            alertifyjs.error('You are trying to create duplicate Fee for one or more Site/Commodity/Season combination. Please fix that and re-try!');
          else if (isEqual(data.details, 'Overlapping Tenure'))
            alertifyjs.error('You are trying to create Fee for one or more overlapping tenures. Please fix that and re-try!', 3);
          else
            alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success('Successfully created!');
        this.onClose(null, true);
      };
    });
  };

  onMultiSelectChange = (id, selectedItems) => {
    const newState = { ...this.state };
    newState.fields[id].value = map(selectedItems, 'id');
    this.getFieldErrors(newState.fields[id]);
    this.setState(newState);
  };

  onClose = (event, refresh) => {
    this.setState({ open: false }, () => this.props.onClose(refresh));
  };

  onPercentageChange(value) {
    const newState = { ...this.state };
    newState.fields.perUnit.value = value;
    this.getFieldErrors(newState.fields.perUnit);
    this.setState(newState);
  }

  getGroupsAndCompanies() {
    const { companies } = this.state;
    let groups = []
    groups = this.props.groups && this.props.groups.filter(group => group.type == 'fees').map(group => ({
        id: group.id, 
        name: group.name
    }))
    let customers = groups.map(group => {
      return {
        category: 'Groups',
        ...group,
      }
    })
    companies.map(company => customers.push({id: company.id, name: company.name, category: 'Companies'}));
    return customers;
  }

  handleCustomerChange = (event, selectedItems) => {
    const newState = {...this.state};
    if (selectedItems.length == 0) {
      newState.fields.customers.value = []
      newState.fields.customerGroups.value = []
      newState.fields.customerCompanies.value = []
      newState.selectedItems = []
    } else {
      newState.fields.customers.value = [...map(selectedItems, 'id')]
      newState.fields.customerGroups.value = [...map(filter(selectedItems, item => item.category === 'Groups'), 'id')];
      newState.fields.customerCompanies.value = [...map(filter(selectedItems, item => item.category === 'Companies'), 'id')];
      newState.selectedItems = [...selectedItems]
    }
    this.setState(newState);
  }

  getLastDateOfMonth(year, month) {
    const lastDate = new Date(year, month, 0);
    return lastDate.getDate();
  }

  handleDateChange = (value, id) => {
    const newState = {...this.state};
    set(newState.fields, `${id}.value`, value);
    set(newState.fields, `${id}.errors`, []);
    let warehouseInvoiceFrequency = get(this.props.selectedCompany, 'warehouseInvoiceFrequency');
    let warehouseStartOfWeek = get(this.props.selectedCompany, 'warehouseInvoiceStartOfWeek');
    if (value && warehouseInvoiceFrequency === 'weekly') {
      const date = new Date(value);
      let day = date.getDay();
      if (id === 'startDate') {
        if (warehouseStartOfWeek !== day)
          set(newState.fields, `${id}.errors`, ['Incorrect start of week.']);
      }
      else {
        let warehouseEndOfWeek = warehouseStartOfWeek - 1;
        warehouseEndOfWeek = warehouseEndOfWeek === -1 ? 6 : warehouseEndOfWeek;
        if (warehouseEndOfWeek !== day)
          set(newState.fields, `${id}.errors`, ['Incorrect end of week.']);
      }
    }
    this.setState(newState);
  }

  render() {
    const { commodities, type } = this.props;
    const { sites, fields, open } = this.state;
    const seasons = map(getSeasons(), season => ({id: season, name: season}));
    const isCreate = this.props.selected ? false : true;
    const customerOptions = this.props.type == 'exception' && this.getGroupsAndCompanies();
    const grades = this.props.grades.filter(grade => includes(fields.commodityIds.value, grade.commodityId));
    const isEditingDisabled = !isCreate && this.state.company?.warehouseInvoiceFrequency?.toLowerCase() == 'weekly'

    return (
      <SideDrawer isOpen={open} title={isCreate ? 'Add Fees' : 'Edit Fees'} onClose={this.onClose}>
        <form noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              {isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommonMultiSelect
                    id="siteIds"
                    items={filter(sites, site => site?.isActive)}
                    selectedItems={fields.siteIds.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    placeholder="Select Site(s)..."
                    label='Site'
                    error={this.state.fields.siteIds.errors[0]}
                    selectAll
                    clearAll
                  />
                </div>
              }{
                !isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                <CommonSelect
                  id="siteIds"
                  onChange={this.handleChange}
                  items={sites}
                  value={fields.siteIds.value}
                  selectConfig={{ text: 'name', value: 'id' }}
                  disabled
                  floatingLabelText={'Site'}
                />
              </div>
              }
              {
                type === 'exception' && isCreate &&
                  <div className="col-sm-12 form-wrap" style={{ marginBottom: '5px', marginTop: '15px' }}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id="forCustomerIds"
                      options={customerOptions}
                      groupBy={item => item.category}
                      getOptionLabel={item => item.name}
                      renderInput={(params) => (
                        <TextField
                          label="Customers"
                          {...params}
                          variant='outlined'
                        />
                      )}
                      value={this.state.selectedItems}
                      onChange={this.handleCustomerChange}
                    />
                  </div>
              }
              {
                type === 'exception' && !isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommonSelect
                    id="forCustomerIds"
                    onChange={this.handleCustomerChange}
                    items={customerOptions}
                    value={this.state.selectedItems[0]?.name}
                    selectConfig={{ text: 'name', value: 'name' }}
                    floatingLabelText={'Customers'}
                    disabled
                  />
              </div>
              }
              {
                isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommodityMultiSelect
                    id="commodityIds"
                    commodities={commodities}
                    selectedCommodities={fields.commodityIds.value}
                    error={this.state.fields.commodityIds.errors[0]}
                    onChange={this.onMultiSelectChange}
                    placeholder='Select Commodities...'
                  />
                </div>
              }{
                !isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommonSelect
                    id="commodityIds"
                    onChange={this.handleChange}
                    items={commodities}
                    value={fields.commodityIds.value}
                    selectConfig={{ text: 'displayName', value: 'id' }}
                    floatingLabelText={'Commodities'}
                    disabled
                  />
              </div>
              }
              {
                isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <GradeMultiSelect
                    id="gradeIds"
                    onChange={this.onMultiSelectChange}
                    options={grades}
                    selected={this.state.fields.gradeIds.value}
                    placeholder={isEmpty(grades) ? 'Select Commodities...' : 'Leave blank if applicable for all grades'}
                    label="Grades (Optional)"
                    disabled={get(this.props.selectedCompany, 'warehouseInvoiceGroupGrades')}
                  />
                </div>
              }
              {
                !isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommonSelect
                    id="gradeIds"
                    onChange={this.handleChange}
                    items={grades}
                    value={fields.gradeIds.value}
                    selectConfig={{ text: 'name', value: 'id' }}
                    floatingLabelText={'Grades'}
                    disabled
                  />
              </div>
              }
              <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                <CommonSelect
                  id="type"
                  onChange={this.handleChange}
                  items={map(this.toSelectOptions(get(this.props, 'type_options')), option => { return { id: option.name, value: option.name }; })}
                  value={this.state.fields.type.value}
                  selectConfig={{ text: 'value', value: 'id' }}
                  disabled={!isCreate}
                  floatingLabelText={'Fee Type'}
                  errorText={this.state.fields.type.errors[0]}
                />
              </div>
              {isCreate &&
              <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                <CommonMultiSelect
                  id="seasons"
                  items={seasons}
                  selectedItems={fields.seasons.value}
                  onChange={this.onMultiSelectChange}
                  placeholder='Select Seasons...'
                  label="Select season"
                  selectAll
                  clearAll
                  displayField="name"
                />
              </div>
              }
              {
                !isCreate &&
                <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                  <CommonSelect
                    id="seasons"
                    onChange={this.handleChange}
                    items={seasons}
                    value={fields.seasons.value}
                    selectConfig={{ text: 'name', value: 'id' }}
                    floatingLabelText={'Season'}
                    disabled
                  />
              </div>
              }
              { this.state.fields.type.value === "Storage Fees" &&
              <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                <CommonSelect
                  id="gracePeriod"
                  items={[{'id': 1, 'value': 'Month of inload'}, {'id': 2, 'value': 'Month of inload and subsequent month'}]}
                  value={this.state.fields.gracePeriod.value}
                  onChange={this.handleGracePeriodChange}
                  selectConfig={{ text: 'value', value: 'id' }}
                  includeEmptyOption
                  floatingLabelText={'Grace Period'}
                />
              </div>
              }
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id='perUnit'
                  label={this.state.fields.type.value === "Storage Fees" ? "Fee per MT/month" :"Fee per MT"}
                  value={this.state.fields.perUnit.value}
                  helperText={this.state.fields.perUnit.errors[0]}
                  onChange={event => this.onPercentageChange(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCountryCurrency()}
                      </InputAdornment>
                    ),
                  }}

                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonDatePicker
                  id="startDate"
                  floatingLabelText="Start Date"
                  errorText={this.state.fields.startDate.errors[0]}
                  value={this.state.fields.startDate.value}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonDatePicker
                  id="endDate"
                  floatingLabelText="End Date"
                  errorText={this.state.fields.endDate.errors[0]}
                  value={this.state.fields.endDate.value}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0' }}>
                <CommonSelect
                  id="frequency"
                  onChange={this.handleChange}
                  items={map(this.toSelectOptions(get(this.state, 'frequency_options')), option => { return { id: option.name, value: option.name }; })}
                  value={this.state.fields.frequency.value}
                  selectConfig={{ text: 'value', value: 'id' }}
                  floatingLabelText={'Invoice Frequency'}
                  disabled
                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0', textAlign: 'center' }}>
                <CommonButton
                  variant="contained"
                  onClick={this.onClose}
                  label='Cancel'
                />
                <Tooltip title={isEditingDisabled ? 'Editing is not allowed for weekly fees currently': ''} placement='top'>
                  <span>
                    <CommonButton
                      variant="contained"
                      type='submit'
                      primary
                      onClick={this.handleSubmit}
                      disabled={isEditingDisabled}
                      label='Submit'
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </form>
      </SideDrawer>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
    grades: state.master.grades.items,
  };
};

export default connect(mapStateToProps)(FeeForm);
