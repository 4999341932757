import React from 'react';
import {connect} from 'react-redux';

import Paper from '@mui/material/Paper';
import ScaleIcon from '@mui/icons-material/Scale';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import {EMPTY_VALUE, PACK_ORDER_TYPE_ID, PRIMARY_COLOR_GREEN, UNKNOWN_COMMODITY_ID, UNKNOWN_GRADE_ID} from '../../../common/constants';
import moment from 'moment';
import Tooltip from '../../../common/Tooltip';
import {getGradeName, getHandlerName, getSiteName, getCountryFormats, getCurrentCountry, getCountryLabel, getCountryConfig, getCountryCurrency, toPhoneFormat} from '../../../common/utils';
import {max, omit, pick, get, has, isNumber, isEmpty, map, includes, isEqual} from "lodash";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { TextField } from '@mui/material';
import alertifyjs from 'alertifyjs';
import APIService from '../../../services/APIService';
import { IconButton, Tooltip as MUITooltip } from '@mui/material';
import UnitConversions from '../../common/UnitConversions';
import UpdateLocation from '../../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Create from '@mui/icons-material/Create';

const EDITABLE_FIELDS_MAPPING = {
  'Container Capacity': 'capacityEdit',
  'Sample Number': 'sampleEdit',
  'Release Number': 'releaseNumberEdit'
}

const EDITABLE_FIELDS_KEY_MAPPING = {
  'capacityEdit': 'Container Capacity',
  'sampleEdit': 'Sample Number',
  'releaseNumberEdit': 'Release Number'
}

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (
      <KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />
    );
  }
  return (
    <KeyboardArrowDown
      style={{ fill: '#112c42', height: '20px', width: '20px' }}
    />
  );
};

class FreightContractDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      capacityEdit: false,
      sampleEdit: false,
      sampleNumber: this.props.movement.sampleNumber,
      containerCapacity: get(this.props.movement, 'containerCapacity'),
      releaseNumber: get(this.props.movement, 'releaseNumber'),
      releaseNumberEdit: false,
      conversion: false,
      openConsignorLocationForm: false,
      openConsigneeLocationForm: false,
      selectedConsignorLocationType: undefined,
      selectedConsigneeLocationType: undefined,
    };
    this.getContractDetailsDataAsPerGrid = this.getContractDetailsDataAsPerGrid.bind(this);
    this.validate = this.validate.bind(this);
    this.onChangeFunction = this.onChangeFunction.bind(this);
  }

  toggleExpanded = () => this.setState(prevState => ({ expanded: !prevState.expanded}));

  toggleEdit = (key, value) => {
    const toggleButton = get(EDITABLE_FIELDS_MAPPING, key);
    this.setState({[toggleButton]: value})
  }

  toggleConversion = () => this.setState({ conversion: !this.state.conversion});

  onChangeFunction(event) {
    event.preventDefault();
    const { id, value } = event.target;
    this.setState({ [id]: value });
  }

  updateContainer(id){
    const key = get(EDITABLE_FIELDS_KEY_MAPPING, id)
    this.toggleEdit(key, false)
    const payload = {
      [id]: this.state[id],
    }
    APIService
    .freights()
    .contracts(this.props.movement.id)
    .put(payload)
    .then((res) => {
      if (res?.errors) {
        alertifyjs.error(`${res.errors}`, 1, () => window.location.reload())
      } else {
        alertifyjs.success(`Pack Movement updated successfully!`, 1, () => window.location.reload())
      }
    })
    .catch(() => alertifyjs.error("An Error Occurred"));
  }

  validate(event) {
    const { id } = event.target;
    let message = id === 'sampleNumber'
    ? `Sample Number of the container will be changed to '${this.state.sampleNumber}' across all active transactions. Do you want to proceed?`
    : `Capacity of the container will be changed to '${this.state.containerCapacity}' across all active transactions. Do you want to proceed?`;
    if (id === 'releaseNumber') {
      message = `Do you want to update the release number to ${this.state.releaseNumber} ?`;
      const orderReleaseNumbers = get(this.props.order, 'freightContainer.releaseNumbers') || get(this.props.movement, 'order.freightContainer.releaseNumbers') || [];
      if (!includes(orderReleaseNumbers, this.state.releaseNumber)) {
        alertifyjs.warning('Invalid Release Number!');
        this.setState({releaseNumberEdit: false, releaseNumber: get(this.props.movement, 'releaseNumber')});
        return;
      }
    }
    alertifyjs.confirm(
      'Warning',
      message,
      () => this.updateContainer(id),
      () => this.setState({[id]: this.props.movement[id] ? this.props.movement[id] : '', capacityEdit: false, sampleEdit: false, releaseNumberEdit: false}),
    );
  }

  toggleBookingDialog = (key, value) => this.props.toggleBookingPopup(key, value);

  getContractDetailsDataAsPerGrid = ( contractDetails, dataLists, toggleBookingDialog, showApplications) => {
    const { validate, onChangeFunction, sampleEdit, capacityEdit, sampleNumber, containerCapacity, toggleEdit, toggleConversion, releaseNumberEdit, releaseNumber } = contractDetails;
    return (
      <div>
        <ul className="field-label-list">
          {Object.keys(dataLists).map(function(key, index) {
             return (
               <li key={index}>
                 {<Tooltip
                   className="field-label ellipses"
                   tooltipText={key}
                   textContent={key}
                 />}
                 {
                  ['Pickup Booking No', 'Delivery Booking No'].includes(key) &&
                  <ModeEditIcon onClick={() => toggleBookingDialog(key, dataLists[key])} className='material-icons' id='edit' style={{fontSize: '20px', marginLeft: '20px', cursor: 'pointer'}} /> 
                 }
                 {['Pickup Site', 'Delivery Site', 'Pickup Address', 'Delivery Address'].includes(key) ?
                 <Tooltip
                   className="field-value"
                   tooltipText={dataLists[key] || EMPTY_VALUE}
                   textContent={dataLists[key] || EMPTY_VALUE}
                 /> :
                 !['Container Capacity', 'Sample Number', 'Release Number'].includes(key) && <Tooltip
                   className="field-value ellipses"
                   tooltipText={dataLists[key] || EMPTY_VALUE}
                   textContent={dataLists[key] || EMPTY_VALUE}
                   valueStyle={{ whiteSpace: 'normal' }}
                   prefix={
                     getCurrentCountry().config?.showConversions && key.toLowerCase() === 'tonnage' &&
                      <MUITooltip title='View in other units' arrow>
                        <span style={{marginLeft: '10px'}}>
                          <IconButton size='small' color='primary' onClick={() => toggleConversion()}>
                            <ScaleIcon fontSize='small' />
                          </IconButton>
                        </span>
                      </MUITooltip>
                    }
                 />}
                 {['Container Capacity', 'Sample Number', 'Release Number'].includes(key) && (
                  <React.Fragment>
                    {key === 'Container Capacity' && (
                      capacityEdit ? (
                        <TextField
                          id="containerCapacity"
                          value={containerCapacity || ''}
                          onChange={onChangeFunction}
                          onBlur={validate}
                          variant="standard"
                          inputProps={{
                            type: 'number',
                            min: 1,
                          }}
                        />
                      ) : (
                        <Tooltip
                          id="containerCapacity"
                          className="field-value ellipses"
                          tooltipText={containerCapacity || EMPTY_VALUE}
                          textContent={containerCapacity || EMPTY_VALUE}
                          valueStyle={{ width: 'fit-content' }}
                        />
                      )
                    )
                    }
                    {key === 'Sample Number' && (
                      sampleEdit ? (
                        <TextField
                          id="sampleNumber"
                          value={sampleNumber || ''}
                          onChange={onChangeFunction}
                          onBlur={validate}
                          variant="standard"
                        />
                      ) : (
                        <Tooltip
                          id="sampleNumber"
                          className="field-value ellipses"
                          tooltipText={sampleNumber || EMPTY_VALUE}
                          textContent={sampleNumber || EMPTY_VALUE}
                          valueStyle={{ width: 'fit-content' }}
                        />
                      )
                    )
                    }
                    {key === 'Release Number' && (
                      releaseNumberEdit ? (
                        <TextField
                          id="releaseNumber"
                          value={releaseNumber || ''}
                          onChange={onChangeFunction}
                          onBlur={validate}
                          variant="standard"
                        />
                      ) : (
                        <Tooltip
                          id="releaseNumber"
                          className="field-value ellipses"
                          tooltipText={releaseNumber || EMPTY_VALUE}
                          textContent={releaseNumber || EMPTY_VALUE}
                          valueStyle={{ width: 'fit-content' }}
                        />
                      )
                    )
                    }
                    {!capacityEdit && key === 'Container Capacity' && (
                      <ModeEditIcon
                        onClick={() => toggleEdit(key, true)}
                        className="material-icons"
                        id="edit"
                        style={{ fontSize: '20px', marginLeft: '5px', cursor: 'pointer' }}
                      />
                    )}
                    {!sampleEdit && key === 'Sample Number' && (
                      <ModeEditIcon
                        onClick={() => toggleEdit(key, true)}
                        className="material-icons"
                        id="edit"
                        style={{ fontSize: '20px', marginLeft: '5px', cursor: 'pointer' }}
                      />
                    )}
                    {!releaseNumberEdit && key === 'Release Number' && (
                      <ModeEditIcon
                        onClick={() => toggleEdit(key, true)}
                        className="material-icons"
                        id="edit"
                        style={{ fontSize: '20px', marginLeft: '5px', cursor: 'pointer' }}
                      />
                    )}
                  </React.Fragment>
                  )}
               </li>
             );
           })}
        </ul>
        {
          showApplications && this.props.movement && this.props.movement.chemicalApplications?.length > 0 &&
            <div className="section-details">
              {this.getChemicalApplicationsSection(this.props.movement.chemicalApplications, 'Applications')}
            </div>
        }
      </div>
    );
  };

  getChemicalApplicationsSection = (applications, title) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(applications, (application, index) => (
              <li key={index} style={{width: '100%'}}>
              <span style={{width: '100%'}}>{application.summary}</span>
              </li>
            ))
          }
        </ul>
      </div>
    );
  };


  isPureAdHoc = () => {
    return get(this.props, 'movement.isAdHoc') && !this.hasParent();
  };

  hasParent = () => {
    const { movement } = this.props;
    return movement && (get(movement,'orderId') || get(movement,'commodityContractId'));
  };

  getConsignorName = () => {
    const { movement } = this.props;
    if(get(movement, 'isAdHoc')){
      if(this.hasParent()){
        if(movement.orderId){
          return getHandlerName(get(movement, 'order.freightPickup.consignor'));
        } else if(movement.commodityContractId){
          return get(movement, 'commodityContract.consignor.handler.displayName');
        } else {
          return EMPTY_VALUE;
        }
      } else {
        return EMPTY_VALUE;
      }
    } else {
      return getHandlerName(get(movement, 'freightPickup.consignor'));
    }
  };

  getConsigneeName = () => {
    const { movement } = this.props;
    if(get(movement, 'isAdHoc')){
      if(this.hasParent()){
        if(movement.orderId){
          return getHandlerName(get(movement, 'order.freightDelivery.consignee'));
        } else if(movement.commodityContractId){
          return get(movement, 'commodityContractConsigneesWithSites.0.handler.displayName');
        } else {
          return EMPTY_VALUE;
        }
      } else {
        return EMPTY_VALUE;
      }
    } else {
      return getHandlerName(get(movement, 'freightDelivery.consignee'));
    }
  };

  getPickupAddress = () => {
    const handler = get(this.props.movement, 'freightPickup.consignor.handler');
    const sites = get(this.props.movement, 'freightPickup.consignor.sites');
    let address = get(handler, 'address.name');
    let locationType = 'farm';
    
    if (!isEmpty(sites)) {
      address = get(sites, '0.location.address.address')
      locationType = 'storage';
    }

    if(!address){
      return EMPTY_VALUE
    }

    return <div style={{ whiteSpace: 'normal' }}>
      <span><>{address}</>
      <IconButton 
        onClick={() => this.setState({openConsignorLocationForm: true, selectedConsignorLocationType: locationType})}
        style={{paddingLeft: '0px'}}
        size="small">
          <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
      </IconButton>
      </span>
    </div>
  }

  getPickupSiteMobile = () => {
    const handler = get(this.props.movement, 'freightPickup.consignor.handler');
    const mobile = get(handler, 'mobile');
    if(!mobile)
      return EMPTY_VALUE

    return <div style={{ whiteSpace: 'normal' }}>
      <span><>{toPhoneFormat(mobile)}</>
      <IconButton 
        onClick={() => this.setState({openConsignorLocationForm: true, selectedConsignorLocationType: 'farm'})}
        style={{paddingLeft: '0px'}}
        size="small">
          <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
      </IconButton>
      </span>
    </div>
  }

  getDeliveryAddress = () => {
    const handler = get(this.props.movement, 'freightDelivery.consignee.handler');
    const sites = get(this.props.movement, 'freightDelivery.consignee.sites');
    let address = get(handler, 'address.name');
    let locationType = 'farm';

    if (!isEmpty(sites)) {
      address = get(sites, '0.location.address.address')
      locationType = 'storage';
    }

    if(!address){
      return EMPTY_VALUE
    }
    
    return <div style={{ whiteSpace: 'normal' }}>
      <span><>{address}</>
      <IconButton 
        onClick={() => this.setState({openConsigneeLocationForm: true, selectedConsigneeLocationType: locationType})}
        style={{paddingLeft: '0px'}}
        size="small">
          <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
      </IconButton>
      </span>
    </div>
  }

  getDeliverySiteMobile = () => {
    const handler = get(this.props.movement, 'freightDelivery.consignee.handler');
    const mobile = get(handler, 'mobile');

    if(!mobile)
      return EMPTY_VALUE
    
    return <div style={{ whiteSpace: 'normal' }}>
      <span><>{toPhoneFormat(mobile)}</>
      <IconButton 
        onClick={() => this.setState({openConsigneeLocationForm: true, selectedConsigneeLocationType: 'farm'})}
        style={{paddingLeft: '0px'}}
        size="small">
          <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
      </IconButton>
      </span>
    </div>
  }

  getConsignorSiteName = () => {
    const { movement } = this.props;
    if(get(movement, 'isAdHoc')){
      if(this.hasParent()){
        if(movement.orderId){
          return getSiteName(get(movement, 'order.freightPickup.consignor'));
        } else if(movement.commodityContractId){
          return get(movement, 'commodityContract.consignor.sites.0.location.name');
        } else {
          return EMPTY_VALUE;
        }
      } else {
        return EMPTY_VALUE;
      }
    } else {
      return getSiteName(get(movement, 'freightPickup.consignor'));
    }
  };

  getConsigneeSiteName = () => {
    const { movement } = this.props;
    if(get(movement, 'isAdHoc')){
      if(this.hasParent()){
        if(movement.orderId){
          return getSiteName(get(movement, 'order.freightDelivery.consignee'));
        } else if(movement.commodityContractId){
          return get(movement, 'commodityContractConsigneesWithSites.0.sites.0.location.name');
        } else {
          return EMPTY_VALUE;
        }
      } else {
        return EMPTY_VALUE;
      }
    } else {
      return getSiteName(get(movement, 'freightDelivery.consignee'));
    }
  };

  getSiteNameWithLocation = checkpointHandlerType => {
    const siteName = checkpointHandlerType === 'consignor' ? this.getConsignorSiteName() : this.getConsigneeSiteName();
    if (!siteName || siteName === EMPTY_VALUE)
      return siteName;
    return <div style={{ whiteSpace: 'normal' }}>
      <span><>{siteName}</>
      <IconButton 
        onClick={() => checkpointHandlerType === 'consignor' ? this.setState({openConsignorStorageForm: true}) : this.setState({openConsigneeStorageForm: true})}
        style={{paddingLeft: '0px'}}
        size="small">
          <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN }} />
      </IconButton>
      </span>
    </div>
  }

  isPackOrder = typeId => typeId === PACK_ORDER_TYPE_ID;

  priceUnit = () => get(this.props, 'movement.requestedUnit') || get(this.props, 'movement.commodity.tonnageUnit') || get(this.props, 'movement.commodity.priceUnit');

  getGeneralSection = () => {
    const { movement } = this.props;
    const isAdHoc = get(movement, 'isAdHoc');
    let payload = {};
    const unit = this.priceUnit();
    if(movement.quantity)
      payload[movement.quantityLabel] = movement.quantity + ' ' + movement.quantityUnit;
    const regoLabel = getCountryLabel('rego')
    const tonnageLabel = getCountryLabel('tonnage')

    payload = {
      ...payload,
      [tonnageLabel]: movement.plannedTonnage ? movement.plannedTonnage + ` ${unit}` : EMPTY_VALUE,
      'Commodity': (isEqual(movement?.commodityId, UNKNOWN_COMMODITY_ID) ? EMPTY_VALUE : get(movement, 'commodity.displayName', EMPTY_VALUE)),
      'Variety': get(movement, 'variety.name', EMPTY_VALUE),
      'Grade': (isEqual(movement?.plannedGradeId, UNKNOWN_GRADE_ID) ? EMPTY_VALUE : getGradeName(movement) || EMPTY_VALUE),
      'Season': get(movement, 'season', EMPTY_VALUE),
      [regoLabel]: get(movement, 'plannedTruck.rego', EMPTY_VALUE),
    };
    if (get(movement, 'typeId') && this.isPackOrder(movement.typeId)) {
      payload = omit(payload, ['Tonnage', 'Season', regoLabel])
      payload["Container Number"] = movement.containerNumber;
      payload["Tare"] = movement.containerTare;
      const shippingLine = get(movement, 'shippingLineName')
      if(shippingLine)
        payload['Shipping Line'] = shippingLine;
    }
    else {
      if(this.isPureAdHocOrAdHocLinkedToCC() || movement.isCreatedAsDelivered){
        payload = omit(payload, ['Tonnage', regoLabel]);
      } else if (isAdHoc && get(movement,'orderId')){
        payload = omit(payload, ['Tonnage', regoLabel]);
      }
    }

    return payload;
  };

  getOtherPackOrderDetails = () => {
    const { movement } = this.props;
    const { order } = this.props;
    let payload = {
      'ISO CODE': movement.isoCode,
      'Seal 1 Number': movement.sealNumbers[0],
      'Seal 2 Number': movement.sealNumbers[1],
      'Sample Number': movement.sampleNumber,
      'Container Capacity': get(movement, 'containerCapacity'),
    };
    if (order) {
      payload['Dual Seal'] = order.freightContainer.dualSeal ? 'Yes' : 'No';
      payload['Container Lining'] = order.freightContainer.containerLining ? 'Yes' : 'No';
    }
    payload['Release Number'] = get(movement, 'releaseNumber')
    return payload;
  }

  getPickupPayload = () => {
    const { movement } = this.props;
    const isAdHoc = get(movement, 'isAdHoc');
    const showTime = has(movement, 'freightPickup.showTime') ? movement.freightPickup.showTime : true;
    let mPickupDate = movement.plannedPickupDatetime ? moment(movement.plannedPickupDatetime) : null;
    let payload = {};
    if(!movement.isCreatedAsDelivered) {
      payload = {
        'Pickup Site': this.getConsignorName(),
        'Pickup Storage': this.getConsignorSiteName(),
        'Pickup Address': this.getPickupAddress(),
        'Pickup Site Phone No': this.getPickupSiteMobile(),
        'Pickup Date': mPickupDate ? mPickupDate.format(getCountryFormats().date) : EMPTY_VALUE,
        'Pickup Time': mPickupDate && showTime ? mPickupDate.format("hh:mm A") : EMPTY_VALUE,
        'Pickup Order No': get(movement, 'freightPickup.orderNumber', EMPTY_VALUE),
        'Pickup Booking No': get(movement, 'freightPickup.bookingNumber', EMPTY_VALUE),
        'Pickup Instructions': get(movement, 'freightPickup.instructions', EMPTY_VALUE)
      };
      if (isAdHoc) {
        payload = omit(payload, ['Pickup Date', 'Pickup Time', 'Pickup Instructions']);
        if(!get(movement, 'orderId')){
          payload = omit(payload, ['Pickup Booking No']);
        }
      }

    }
    return payload;
  };

  getDeliveryPayload = () => {
    const { movement } = this.props;
    const showTime = has(movement, 'freightDelivery.showTime') ? movement.freightDelivery.showTime : true;
    let mDeliveryDate = movement.plannedDeliveryDatetime ? moment(movement.plannedDeliveryDatetime) : null;
    const isAdHoc = get(movement, 'isAdHoc');
    let payload = {};
    if(!movement.isCreatedAsDelivered) {
      payload = {
        'Delivery Site': this.getConsigneeName(),
        'Delivery Storage': this.getConsigneeSiteName(),
        'Delivery Address': this.getDeliveryAddress(),
        'Delivery Site Phone No': this.getDeliverySiteMobile(),
        'Delivery Date': mDeliveryDate ? mDeliveryDate.format(getCountryFormats().date) : EMPTY_VALUE,
        'Delivery Time': mDeliveryDate && showTime ? mDeliveryDate.format("hh:mm A") : EMPTY_VALUE,
        'Delivery Order No': get(movement, 'freightDelivery.orderNumber', EMPTY_VALUE),
        'Delivery Booking No': get(movement, 'freightDelivery.bookingNumber', EMPTY_VALUE),
        'Delivery Instructions': get(movement, 'freightDelivery.instructions', EMPTY_VALUE)
      };
      if (isAdHoc) {
        payload = omit(payload, ['Delivery Date', 'Delivery Time', 'Delivery Instructions']);
        if(!get(movement, 'orderId')){
          payload = omit(payload, ['Delivery Booking No']);
        }
      }
    }

    return payload;
  };

  isPureAdHocOrAdHocLinkedToCC = () => {
    const { movement } = this.props;
    const isAdHoc = get(movement, 'isAdHoc');
    return (this.isPureAdHoc() || (isAdHoc && get(movement,'commodityContractId')));
  };

  isAdHocAndCreatedFromFreightOrder = () => {
    const { movement } = this.props;
    return get(movement, 'isAdHoc') && get(movement, 'orderId');
  };

  isUserNonDeliveryOnus = () => {
    const { movement } = this.props;
    if (
      get(movement, 'isCustomer') === false &&
      get(movement, 'isFreightProvider') === false  &&
      (get(movement, 'isBuyer') || get(movement, 'isSeller'))
    ) {
      const deliveryOnus = get(movement, 'commodityContract.deliveryOnus');
      return deliveryOnus === 'Seller' ? this.props.currentUser.companyId === get(movement, 'buyer.companyId') : this.props.currentUser.companyId === get(movement, 'seller.companyId');
    }
    return false;
  };

  getInvoicePayload = () => {
    const { movement } = this.props;
    const currency = movement?.currency || getCountryCurrency();
    let commissionRate = '';
    let rateFreightIn = get(movement, 'rateFreightIn');
    const rateFreightOut = get(movement, 'rateFreightOut');
    const estimatedDistance = get(movement, 'estimatedDistance');
    var rateOvers = parseFloat(get(movement,'rateOvers', 0)).toFixed(2);
    if (isNaN(rateOvers)){
      rateOvers = '';
    }
    const estimatedTime = get(movement, 'estimatedTime');
    if (rateFreightIn && rateFreightOut) {
      let rate = max([parseFloat(rateFreightIn) - parseFloat(rateFreightOut), 0]);
      commissionRate = rate  > 0 ? `${currency} ${rate.toFixed(2)}` : '';
    }

    let payload = {
      'Invoicing': get(movement, 'invoicing', EMPTY_VALUE),
      'Payment Term': get(movement, 'paymentTerm.name', EMPTY_VALUE),
    };

    if(get(movement, 'isCustomer') && commissionRate)
      {
        payload = {
          ...payload,
          'Freight Rate In': isNumber(rateFreightIn) ? `${currency} ${parseFloat(rateFreightIn).toFixed(2)}` : EMPTY_VALUE,
          'Freight Rate Out': isNumber(rateFreightOut) ? `${currency} ${parseFloat(rateFreightOut).toFixed(2)}` : EMPTY_VALUE,
          'Commission Rate': commissionRate,
        };
      }
    if(get(movement, 'isFreightProvider') || (get(movement, 'isCustomer') && !commissionRate) || get(movement, 'isCreatedByUserParty') || get(movement, 'isCreator')) {
      payload = {
        ...payload,
        'Freight Rate': isNumber(rateFreightOut) ? `${currency} ${parseFloat(rateFreightOut).toFixed(2)}` : EMPTY_VALUE,
      };
    }
    payload = {
      ...payload,
      'Overs Rate': rateOvers ? `${currency} ${rateOvers}` : EMPTY_VALUE,
      'Est. Distance': estimatedDistance ? estimatedDistance + ' ' + getCountryConfig()?.distanceUnit : EMPTY_VALUE,
      'Est. Total Time': estimatedTime ? estimatedTime : EMPTY_VALUE,
    };

    if (!get(movement, 'isCustomer') && !get(movement, 'isCreatedByUserParty') && !get(movement, 'isCreator') && !get(movement,'isFreightProvider')) {
      payload = omit(payload, ['Freight Rate In', 'Commission Rate', 'Freight Rate Out', 'Invoicing', 'Payment Term', 'Freight Rate', 'Overs Rate']);
    }

    if (get(movement, 'isSelf')) {
      payload = omit(payload, ['Freight Rate In', 'Commission Rate', 'Freight Rate Out', 'Invoicing', 'Payment Term', 'Freight Rate', 'Overs Rate']);
    }

    if (this.isUserNonDeliveryOnus())
      return pick(payload, ['Est. Distance', 'Est. Total Time']);
    return payload;
  };

  render() {
    const { expanded, sampleEdit, capacityEdit, sampleNumber, containerCapacity, releaseNumberEdit, releaseNumber } = this.state;
    const { movement } = this.props;
    const consignor = get(movement, 'freightPickup.consignor');
    const consignee = get(movement, 'freightDelivery.consignee');
    const isPackOrder = get(movement, 'typeId') === PACK_ORDER_TYPE_ID;
    const { validate, onChangeFunction, toggleEdit, toggleConversion } = this;
    const contractDetails = {
      validate,
      onChangeFunction,
      sampleEdit,
      capacityEdit,
      sampleNumber,
      containerCapacity,
      releaseNumberEdit,
      releaseNumber,
      toggleEdit,
      toggleConversion,
    };
    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          {isPackOrder ? 'Pack Order Details' : 'Freight Contract Details'}
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {
          expanded && !isPackOrder &&
          <div className="section-details-container-4">
            {
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getGeneralSection(), false, true)
            }
            {
              !movement.isCreatedAsDelivered &&
              (this.isAdHocAndCreatedFromFreightOrder() || !this.isPureAdHocOrAdHocLinkedToCC()) &&
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getPickupPayload(), this.toggleBookingDialog)
            }
            {
              !movement.isCreatedAsDelivered &&
              (this.isAdHocAndCreatedFromFreightOrder() || !this.isPureAdHocOrAdHocLinkedToCC()) &&
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getDeliveryPayload(), this.toggleBookingDialog)
            }
            {
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getInvoicePayload())
            }
          </div>
        }
        {
          expanded && isPackOrder &&
          <div className="section-details-container-4">
            {
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getGeneralSection())
            }
            {
              this.getContractDetailsDataAsPerGrid(contractDetails, this.getOtherPackOrderDetails())
            }
          </div>
        }
        {this.state.conversion &&
          <UnitConversions
            onClose={() => this.setState({conversion: false})}
            unitConversions={movement.unitConversions}
            preferredUnit={movement.requestedUnit || movement.commodity.priceUnit}
            commodityUnit={movement.commodity.unit}
          />
        }
        {this.state.openConsignorLocationForm && this.state.selectedConsignorLocationType === 'farm' &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={get(consignor, 'handler.id')} entity='farm' onCloseDrawer={() => this.setState({openConsignorLocationForm: false, selectedConsignorLocationType: undefined})}/>
        }
        {this.state.openConsigneeLocationForm && this.state.selectedConsigneeLocationType === 'farm' &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={get(consignee, 'handler.id')} entity='farm' onCloseDrawer={() => this.setState({openConsigneeLocationForm: false, selectedConsigneeLocationType: undefined})}/>
        }
        {this.state.openConsignorLocationForm && this.state.selectedConsignorLocationType === 'storage' &&
          <UpdateLocation updateEntities={() => window.location.reload()} farmId={get(consignor, 'handler.id')} selectedStorage={get(consignor, 'sites.0.location')} entity='storage' onCloseDrawer={() => this.setState({openConsignorLocationForm: false, selectedConsignorLocationType: undefined})}/>
        }
        {this.state.openConsigneeLocationForm && this.state.selectedConsigneeLocationType === 'storage' &&
          <UpdateLocation updateEntities={() => window.location.reload()} farmId={get(consignee, 'handler.id')} selectedStorage={get(consignee, 'sites.0.location')} entity='storage' onCloseDrawer={() => this.setState({openConsigneeLocationForm: false, selectedConsigneeLocationType: undefined})}/>
        }
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.main.user.user,
  };
};

export default connect(mapStateToProps)(FreightContractDetails);
