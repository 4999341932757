import React from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, includes } from 'lodash';
import APIService from '../../services/APIService';
import {
  setHeaderText, setBreadcrumbs, forceStopLoader, isLoading, setDownloadBar, isSearchApplied
} from '../../actions/main';
import { getPaginatedOrdersResponse, getRequestOrders } from '../../actions/companies/orders';
import AddButton from '../common/AddButton';
import RequestOrdersTable from '../../containers/RequestOrdersTable';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import {
  FO_GO_FILTER_STATUSES, ORDERS_TABLE_COLUMN_LIMIT, REQUEST_ORDERS_HEADERS, COMPANY_ADMIN, OFFICE_ADMIN,
  SYSTEM, OBSERVER_TYPE_ID, DEFAULT_ORDERS_TABLE_COLUMN_LIMIT, FILTER_KEYS_TO_EXCLUDE, PREDEFINED_DATE_RANGE_FILTER_KEYS, ORDER_FILTER_KEYS_MAPPING
} from '../../common/constants';
import CommonListingButton from '../common/CommonListingButton';
import SideDrawer from '../common/SideDrawer';
import alertifyjs from 'alertifyjs';
import Button from '@mui/material/Button/Button';
import { Tooltip, Paper } from '@mui/material';
import DownloadDataDialog from '../common/DownloadDataDialog';
import Filters from '../common/Filters';
import FilterListIcon from '@mui/icons-material/FilterList';
import { isAtGlobalOrders, attachCSVEventListener, defaultViewAction } from '../../common/utils';
import FiltersAppliedChip from '../common/FiltersAppliedChip';

class RequestOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
      customTableColumnOptions: false,
      customTableColumnNames: {},
      applyFilters: false,
      filters: {},
      openSideDrawer: false,
      filter_statuses: FO_GO_FILTER_STATUSES,
      filterValues: {
        commodity__id__in: [],
        planned_grade__id__in: [],
        status__in: [],
        customer__company__id__in: [],
        delivery_start_date_range: '',
        delivery_end_date_range: '',
        freight_pickup__date_time__gte: '',
        freight_delivery__date_time__lte: '',
        season__in: [],
        type_id__in: [],
        freight_pickup__consignor__handler__id__in: [],
        freight_delivery__consignee__handler__id__in: [],
        updated_at_date_range: '',
        updated_at__lte: '',
        updated_at__gte: ''
      },
      customColumnTitle: undefined,
      isFilteredCsv: false,
      csvPopup: false,
      searchView: false,
      customColumns: true,
    };
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchOrders();
    this.setHeaderAndBreadcrumbs();
    this.props.applySearch(null);

    APIService.profiles()
      .filters('request_order')
      .get(this.props.token)
      .then(res => {
        this.setState({
          filters: get(res, 'request_order', {}),
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.applySearch(null);
    if(window.location.hash.includes('?') && isAtGlobalOrders())
      window.location.hash = window.location.hash.split('?')[0]
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener('request-orders-csv-ready', 'Orders', this.onDownloadResponse);
  }

  setHeaderAndBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    let headerText = 'Request Orders';
    let breadcrumbs = [{ text: headerText + countLabel }];
    this.props.setHeaderText(headerText);
    this.props.setBreadcrumbs(breadcrumbs);
  }

  fetchOrders() {
    this.setState({isLoading: true}, () => {
      const callback = orders => this.setState({orders: orders, isLoading: false})
      this.props.getRequestOrders(null, null, callback)
    });
  }

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/request_order_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const requestOrderColumns = REQUEST_ORDERS_HEADERS;
    return requestOrderColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && isAtGlobalOrders()) {
        const { filters } = this.state;
        APIService
          .profiles()
          .filters()
          .post({ request_order: filters }, this.props.token)
          .then(res => this.setState(
            {filters: res?.filters?.request_order || {}},
            this.fetchOrders
          ));
      }
    });
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  fetchCSVData = () => {
    const { setDownloadBar } = this.props;
    setDownloadBar('Your Orders CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.searchView && this.props.isSearchApplied)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    const service = APIService.freights().orders();
    service.appendToUrl(`requests/csv/?${param}`);
    if (this.state.customColumns)
      service.appendToUrl('&custom_csv');
    this.setState({csvPopup: false, searchView: false})
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => this.setState({ csvData: csvData || [] }));
  };

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(ORDER_FILTER_KEYS_MAPPING, key)) : value.length !== 0

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  toggleCustomColumnDownloads = () => {
    this.setState({customColumns: !this.state.customColumns})
  }

  render() {
    return (
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            {this.canExportCSV() && (
              <CommonListingButton
                defaultHandler={() => this.customCsvEnabled(false)}
                showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0)) && isAtGlobalOrders()}
                optionMapper={[
                  { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
                  { name: 'Filtered List', fx: () => this.customCsvEnabled(true)  },
                ]}
                title='Download Contents of the table in a CSV'
                name='Export'
              />
            )}
            <DownloadDataDialog
              open={this.state.csvPopup}
              onClose={() => this.setState({csvPopup: false, searchView: false})}
              title='Download Request Orders Data'
              enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
              isSearchApplied={this.props.isSearchApplied}
              searchView={this.state.searchView}
              onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
              isFilteredCsv={this.state.isFilteredCsv}
              onDownload={this.fetchCSVData}
              customColumnTitle={this.state.customColumnTitle}
              user={this.props.currentUser}
              csvType="request_orders_csv"
              updateColumnCount={(count) => this.updateColumnCount(count)}
              toggleCustomColumnDownloads={this.toggleCustomColumnDownloads}
            />
            {isAtGlobalOrders() && (
              <Tooltip title='Apply filters' placement='top'>
                <Button
                  value={this.state.applyFilters}
                  variant="contained"
                  type='button'
                  onClick={() => this.handleFilters(true)}
                  color='primary'
                  className='add-button'
                  style={{ float: 'right', marginLeft: '10px' }}
                >
                  <FilterListIcon style={{ paddingRight: '5px' }} />
                  FILTERS{' '}
                  {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                    ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                    : ''}
                </Button>
              </Tooltip>
            )}
            {this.state.applyFilters && (
              <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                <Filters
                  isLoading={this.props.isLoading}
                  forceStopLoader={this.props.forceStopLoader}
                  handleFilterState={this.handleFilterState}
                  filters={this.state.filters}
                  statusTemp={this.state.filter_statuses}
                  filterValues={this.state.filterValues}
                  showOrderTypes={true}
                />
              </SideDrawer>
            )}
            <AddButton
              label='Request Order'
              href='/#/orders/requests/new'
              app='order'
              tooltipTitle='Create Request Order'
              tooltipPlacement='top'
            />
            <div style={{float: 'right', marginRight: '10px'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
            <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="request_order_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={ORDERS_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_ORDERS_TABLE_COLUMN_LIMIT}
              />
            </SideDrawer>
          </div>
          <FiltersAppliedChip filters={this.state.filters} show={isAtGlobalOrders()} style={{paddingRight: '45%'}} />
          <RequestOrdersTable dontRedirect={this.props.dontRedirect} />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    currentUser: state.main.user.user,
    count: get(state.companies.orders, 'paginationData.count') || 0,
    isSearchApplied: state.main.isSearchApplied,
  };
};

const mapDispatchToProps = dispatch => ({
  getRequestOrders: (url, forceStopLoader, callback) => dispatch(getRequestOrders(url, forceStopLoader, callback)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  getPaginatedOrdersResponse: orders => dispatch(getPaginatedOrdersResponse(orders)),
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestOrders);
