import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import GoogleMapPopup from '../common/GoogleMapPopup';
import GooglePlaceAutoComplete from '../google-places/AutoComplete';
import { fetchGrowerCompanyByABN as fetchCompanyByABN, getTracks } from '../../actions/main';
import { getCompanyDetails } from '../../actions/companies';
import {
  required,
  maxLength,
  phoneMobileRegex,
} from '../../common/validators';
import { fetchMarketZones, clearAbnErrors, forceStopLoader } from '../../actions/main';
import { getBrokerCompanies } from '../../actions/api/companies';
import {
  isCurrentUserGrower,
  currentUserCompany,
  getCompanyIdFromCurrentRoute,
  canEditFarm,
  disableEditFarm,
  getFarmIdFromCurrentRoute,
  isSystemCompany,
  getWeek,
  farmStocksPath,
  isTransactionParticipated,
  getCurrentCountry,
  toPhoneFormat,
  isStaff
} from '../../common/utils';
import CommonButton from '../common/CommonButton';
import { ChipLabel } from '../common/ChipLabel';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import {setBreadcrumbs} from '../../actions/main';
import {PRIMARY_COLOR_GREEN, GROWER, BROKER, WEIGH_BRIDGE_COMPANY_LIST} from '../../common/constants';
import SideDrawer from '../common/SideDrawer';
import CommonSelect from '../common/select/CommonSelect';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import alertifyjs from 'alertifyjs';
import Autocomplete from '@mui/material/Autocomplete';
import { uniqBy, pick, includes, has, get, set, isEqual, flatMap, filter, find, isEmpty,
         some, forEach, isNumber, mapValues, map, startCase } from 'lodash';
import PhoneField from '../common/PhoneField';
import ABNField from '../common/ABNField'

class FarmForm extends Component {
  constructor(props) {
    super(props);
    var abn = '', mobile = '',address = {}, location = {};
    const companyIdFromRoute = getCompanyIdFromCurrentRoute();
    const isGrowerUser = isCurrentUserGrower();
    if(isGrowerUser || (companyIdFromRoute)) {
      var selectedCompany = null;
      if(isGrowerUser){
        selectedCompany = currentUserCompany();
      } else if(this.props.selectedCompany){
        selectedCompany = this.props.selectedCompany;
      }
      if(selectedCompany){
        abn = selectedCompany.abn;
        mobile = selectedCompany.mobile;
        address = pick(selectedCompany.address, ['name', 'address', 'latitude', 'longitude', 'suburb', 'streetName', 'stateId', 'postCode']);
        if(address){
          location = {
            formatted_address: address.address,
            lat: parseFloat(parseFloat(address.latitude).toFixed(6)),
            lng: parseFloat(parseFloat(address.longitude).toFixed(6)),
            suburb: address.suburb,
            state: address.state,
            postCode: address.postCode,
            streetName: address.streetName,
          };
        }
      }
    } else {
      try {
        abn = this.props.company.abn || this.state.fields['abn']['value'];
      } catch(err) {
        //ignore
      }
    }
    this.state = {
      selectedFarm: null,
      isPrimaryBroker: null,
      selectedFarmId: undefined,
      openSideDrawer: false,
      regions: [],
      regionSearchText: '',
      marketZoneSearchText: '',
      disableAbnField: false,
      showCompanyDetails: false,
      selectedCompanyId: this.props.companyId,
      disableRegionField: true,
      isAcceptReject: includes(get(props, 'location.pathname'), 'accept-reject'),
      isPendingRequest: includes(get(props, 'location.pathname'), 'pending-request'),
      defaultLocation: {},
      fields: {
        abn: {
          value: abn,
          validators: [required()],
          errors: []
        },
        marketZoneId: {
          value: null,
          validators: [],
          errors: []
        },
        regionId: {
          value: null,
          validators: [],
          errors: []
        },
        name: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        address: {
          value: address,
          validators: [required()],
          errors: []
        },
        location: {
          value: location,
          validators: [required()],
          errors: []
        },
        mobile: {
          value: mobile,
          validators: [phoneMobileRegex(null)],
          errors: []
        },
        brokerCompanyId: {
          value: null,
          validators: [],
          errors: []
        },
        allowInloadSlotOrderBooking: {
          value: true,
          validators: [],
          errors: []
        },
        allowOutloadSlotOrderBooking: {
          value: true,
          validators: [],
          errors: []
        },
        externallySyncSource: {
          value: '',
          validators: [],
          errors: []
        },
        stocksManagement: {
          value: false,
          validators: [],
          errors: []
        },
        grossFirstInload: {
          value: false,
        },
        grossFirstOutload: {
          value: false,
        },
        weighBridge: {
          value: false,
        },
        populateDefaultWeights: {
          value: false,
        },
        isCashPricesEnabled: {
          value: true,
        },
        netWeightWhenPackingContainer: {
          value: false
        },
        mandateTruckVendorDec: {
          value: false
        },
        weighBridgeCompanyName: {
          value: '',
          validators: [],
          errors: []
        },
        valuesToCopy : {
          value: [],
          validators: [],
          errors: []
        },
        copyFromInload: {
          value: false,
          validators: [],
          errors: []
        },
        valuesToCopyForOutloadFromStorage : {
          value: [],
          validators: [],
          errors: []
        },
        copyToOutloadForStorage: {
          value: false,
          validators: [],
          errors: []
        },
        defaultTrack: {
          value: undefined,
          validators: [],
          errors: []
        }
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleMarketZoneChange = this.handleMarketZoneChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onRegionFieldBlur = this.onRegionFieldBlur.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.onOpenSideDrawer = this.onOpenSideDrawer.bind(this);
    this.onCloseSideDrawer = this.onCloseSideDrawer.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.onAddressFieldBlur = this.onAddressFieldBlur.bind(this);
    this.handleFarmChange = this.handleFarmChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.fillCompanyDefaultValues = false;
    this.handleValuesToCopyChange = this.handleValuesToCopyChange.bind(this);
    this.handleValuesToCopyChangeForStorage = this.handleValuesToCopyChangeForStorage.bind(this);
    this.handleValuesToCopyChangeForField = this.handleValuesToCopyChangeForField.bind(this);
  }

  componentDidMount() {
    this.props.forceStopLoader();
    this.getCompanyIfNeeded();
    if (this.props.selectedFarm) {
      this.setAllFieldsValuesBySelectedFarm(this.props.selectedFarm);
      this.setBreadcrumbs();
    }

    if (isEmpty(this.props.marketZones)) {
      this.props.fetchMarketZones();
    }

    if (isEmpty(this.props.brokerCompanies)) {
      this.props.getBrokerCompanies();
    }
    if(isSystemCompany()) {
      this.props.getTracks();
    }
  }

  getCompanyIfNeeded() {
    if(getCompanyIdFromCurrentRoute() && (!this.props.selectedCompany || this.props.selectedCompany.id !== parseInt(getCompanyIdFromCurrentRoute()))) {
      const companyId = getCompanyIdFromCurrentRoute();
      companyId && this.props.getCompany(companyId);
    }
  }

  setBreadcrumbs() {
    if((this.props.farmId && this.props.farmName) || this.props.selectedFarm){
      const companyId = getCompanyIdFromCurrentRoute();
      const farmName = get(this.props, 'selectedFarm.name') || this.props.farmName;
      const farmId = get(this.props, 'selectedFarm.id') || this.props.farmId;
      const entityLabelPlural = this.entityLabel(true);
      let breadcrumbs = [];
      let mainEntityRoute = this.isGrowerSite() ?
                            '/farms' :
                            `/companies/${get(this.props.selectedFarm, 'companyId') || companyId}/company-sites?sites`;
      if(companyId && this.props.selectedCompany) {

        const subRoute = this.isGrowerSite() ? '/farms' : '/company-sites?sites';
        const parentRoute = '/companies/' + this.props.selectedCompany.id;
        breadcrumbs = [
          {text: 'Companies', route: '/companies'},
          {text: this.props.selectedCompany.name, route:  parentRoute + '/details'},
          {text: entityLabelPlural, route: parentRoute + subRoute},
          {text: 'Settings'}
        ];
        const stocksOption = { text: farmName, route: parentRoute + farmStocksPath(farmId) };
        const detailsOption = { text: farmName };
        if(this.props.selectedFarm.isAssociated) {
          breadcrumbs.splice(breadcrumbs.length-1, 0, stocksOption);
        } else {
          breadcrumbs.splice(breadcrumbs.length-1, 1, detailsOption);
        }
      } else {
        if((this.props.selectedFarm.isAssociated || isSystemCompany()) && !this.state.isAcceptReject) {
          breadcrumbs = [
            {text: entityLabelPlural, route: mainEntityRoute},
            {text: farmName, route: farmStocksPath(farmId) },
            {text: 'Settings'}
          ];
        } else if (this.state.isPendingRequest || this.state.isAcceptReject) {
          breadcrumbs = [
            {text: entityLabelPlural, route: mainEntityRoute},
            {text: farmName},
          ];
        } else {
          breadcrumbs = [
            {text: entityLabelPlural, route: mainEntityRoute},
          ];

          breadcrumbs = breadcrumbs.concat([{text: 'Settings'}]);
        }
      }
      if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedFarm && (!isEqual(prevProps.selectedFarm, this.props.selectedFarm))) {
      this.setAllFieldsValuesBySelectedFarm(this.props.selectedFarm);
    }
    this.setBreadcrumbs();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      if (newState.fields[key]) {
        newState.fields[key].errors = value;
      }
    });
    if(!props.farmId && this.fillCompanyDefaultValues){
      this.fillCompanyDefaultValues = false;
      if(!isEmpty(props.company) || !isCurrentUserGrower()){
        newState.fields.mobile.value = props.company.mobile;
        if(props.company.mobile){
          newState.fields.mobile.errors = [];
        }
        newState.fields.address.value = pick(props.company.address, ['name', 'address', 'latitude', 'longitude', 'suburb', 'streetName', 'state', 'postCode']);
        if(newState.fields.address.value){
          newState.fields.address.errors = [];
        }
        if(props.company.address){
          newState.fields.location.value = {
            formatted_address: props.company.address.address,
            lat: parseFloat(parseFloat(props.company.address.latitude).toFixed(6)),
            lng: parseFloat(parseFloat(props.company.address.longitude).toFixed(6)),
            suburb: props.company.address.suburb,
            state: props.company.address.state,
            postCode: props.company.address.postCode,
            streetName: props.company.address.streetName,
          };
          newState.fields.location.errors = [];
        }
      }
    }
    this.setState(newState);

    if(newState.checkSubmit && Object.keys(props.serverErrors).length === 0) {
      this.onCloseSideDrawer();
    }
  }

  componentWillUnmount() {
    this.props.clearAbnErrors();
  }

  setAllFieldsValuesBySelectedFarm(farmDetails) {
    if (!isEmpty(farmDetails)) {
      const newState = { ...this.state };
      newState.isPrimaryBroker = farmDetails.isManagedByUser;
      forEach(newState.fields, (value, key) => {
        if(key == 'abn' && !newState.fields.abn.value){
          newState.fields[key].value = farmDetails?.company?.abn;
          this.props.company.entityName = farmDetails?.company?.entityName;
          this.props.company.businessName = farmDetails?.company?.businessName;
          newState.showCompanyDetails = true;
        } else if (key === 'address') {
          newState.fields[key].value = pick(farmDetails[key], ['name', 'address', 'latitude', 'longitude']);
        } else if (key === 'location') {
          const locationValue = {
            formatted_address: farmDetails['address']?.address,
            lat: farmDetails['address']?.latitude,
            lng: farmDetails['address']?.longitude,
            suburb: farmDetails['address']?.suburb,
            state: farmDetails['address']?.state,
            postCode: farmDetails['address']?.postCode,
            streetName: farmDetails['address']?.streetName,
          };
          newState.fields[key].value = locationValue;
        } else if(key !== 'abn') {
          newState.fields[key].value = farmDetails[key];
        }
      });
      newState.selectedCompanyId = farmDetails?.company?.id;
      newState.marketZones = this.props.marketZones;
      newState.fields.marketZoneId.value = farmDetails.marketZoneId;
      if(!newState.fields.marketZoneId.value && farmDetails.companyId !== this.props.currentUser.companyId && get(farmDetails, 'company.isRegistered')) {
        newState.fields.marketZoneId.errors = [];
        newState.fields.marketZoneId.validators = [];
      }
      if(!isEmpty(this.props.marketZonesWithRegions) && farmDetails['marketZoneId']) {
        newState.regions = find(this.props.marketZonesWithRegions, {id: farmDetails.marketZoneId}).regions;
        if(isEmpty(newState.regions)){
          newState.fields.regionId.validators = [];
          newState.fields.regionId.errors = [];
        }
        else{
          newState.fields.regionId.validators = [required()];
        }
      }
      newState.fields.regionId.value = farmDetails.regionId;
      newState.disableAbnField = true;
      newState.fields.stocksManagement.value = farmDetails.stocksManagement;
      newState.fields.allowInloadSlotOrderBooking.value = farmDetails.allowInloadSlotOrderBooking;
      newState.fields.allowOutloadSlotOrderBooking.value = farmDetails.allowOutloadSlotOrderBooking;
      newState.fields.externallySyncSource.value = farmDetails.externallySyncSource || '';
      newState.fields.grossFirstInload.value = farmDetails.grossFirstInload;
      newState.fields.grossFirstOutload.value = farmDetails.grossFirstOutload;
      newState.fields.weighBridge.value = farmDetails.weighBridge;
      newState.fields.weighBridgeCompanyName.value = farmDetails.weighBridgeCompanyName;
      newState.fields.defaultTrack.value = farmDetails.defaultTrack;
      newState.fields.netWeightWhenPackingContainer.value = farmDetails?.netWeightWhenPackingContainer;
      newState.fields.mandateTruckVendorDec.value = farmDetails?.mandateTruckVendorDec;
      if (newState.fields.weighBridge.value)
        newState.fields.weighBridgeCompanyName.validators = [required()];
      this.setState(newState);
    }
  }

  handleSubmit(event) {
    this.setAllFieldsErrors();
    const submitData = this.getSubmitData();
    if (!this.getIsFormInvalid()) {
      var companyId = null;
      const companyIdFromRoute = getCompanyIdFromCurrentRoute();
      if(this.props.selectedFarm) {
        companyId = this.props.selectedFarm.companyId
      } else {
        if(companyIdFromRoute){
          companyId = companyIdFromRoute;
        } else if (isCurrentUserGrower()) {
          companyId = currentUserCompany().id;
        } else {
          companyId = this.props.newCompany ? this.props.companyId : (!isEmpty(this.props.selectedFarm) ? this.props.selectedFarm.companyId : this.props.company.id);
        }
      }
      submitData.address.latitude = submitData.location.lat;
      submitData.address.longitude = submitData.location.lng;
      submitData.address.suburb = submitData.location.suburb;
      submitData.address.state = submitData.location.state;
      submitData.address.postCode = submitData.location.postCode;
      submitData.address.streetName = submitData.location.streetName;
      delete submitData.location;
      if(this.state.selectedFarmId) {
        submitData.existingId = this.state.selectedFarmId;
      }

      if(this.isGrowerSite() && this.isNewBrokerCompanyAssigned(submitData)) {
        alertifyjs.confirm(
          'Please Note',
          'Broker once added cannot be changed. For changing the broker you would need to get in touch with the AgriChain team. Do you want to proceed with this broker selection?',
          () => this._handleSubmit(companyId, submitData),
          () => {}
        );
      } else {
        this._handleSubmit(companyId, submitData);
      }
    }

    event.preventDefault();
  }

  _handleSubmit(companyId, data) {
    this.props.submit(companyId, data);
    this.setState({
      checkSubmit: true,
      primaryBroker: false,
    });
  }

  isNewBrokerCompanyAssigned(data) {
    if(this.props.currentUser.company.typeId === GROWER)
    if(this.props.selectedFarm) {
      return isNumber(data.brokerCompanyId) && data.brokerCompanyId !== this.props.selectedFarm.brokerCompanyId;
    } else {
      return isNumber(data.brokerCompanyId);
    }
  }

  entityLabel(plural) {
    let label = 'Site';
    if(this.isGrowerSite())
      label = 'Farm';

    if(plural)
      label += 's';

    return label;
  }

  isGrowerSite() {
    const { selectedFarm, company, selectedCompany } = this.props;
    if(selectedFarm)
      return get(selectedFarm, 'company.typeId') === GROWER;

    return (get(company, 'typeId') || get(selectedCompany, 'typeId')) === GROWER;
  }

  handleAccept() {
    this.setAllFieldsErrors();

    const submitData = this.getSubmitData();
    const acceptFarmData = isCurrentUserGrower() ? {
      isBrokerRequestAcceptedByGrower: true,
    } : {
      ...pick(submitData, []),
      isGrowerRequestAcceptedByBroker: true,
    };

    const entityLabel = this.entityLabel().toLowerCase();

    if (!this.getIsFormInvalid()) {
      if(isCurrentUserGrower()) {
        alertifyjs.confirm(
          'Please Note.',
          `Once accepted, Broker for this ${entityLabel} cannot be changed. For changing the broker you would need to get in touch with the AgriChain team. Do you want to proceed with this broker selection?`,
          () => { this._handleAccept(acceptFarmData);},
          () => {}
        );
      } else {
        this._handleAccept(acceptFarmData);
      }
    }
  }

  _handleAccept(data) {
    this.props.acceptFarm(this.props.farmId, data);
  }

  handleReject() {
    if (isCurrentUserGrower()) {
      this.props.growerRejectFarm(this.props.farmId);
    } else {
      this.props.brokerRejectFarm(this.props.farmId, {
        brokerCompanyId: null,
      });
    }
  }

  getIsFormInvalid() {
    return some(this.state.fields, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  }

  getSubmitData() {
    const submitData = mapValues(this.state.fields, (field) => {
      return field.value;
    });

    delete submitData.abn;

    return submitData;
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleAddressChange(data) {
    const newState = {...this.state};
    var address = {
      name: data.name,
      address: data.address,
      latitude: data.lat,
      longitude: data.lng,
      suburb: data?.suburb,
      state: data?.state,
      postCode: data?.postCode,
      streetName: data?.streetName,
    };
    newState.fields.address.value = address;
    const location = this.state.fields.location.value;
    if(data.lat && (isEmpty(location) || isEmpty(location.formatted_address))) {
      const locationValue = {
        formatted_address: data.address,
        lat: parseFloat(parseFloat(data.lat).toFixed(6)),
        lng: parseFloat(parseFloat(data.lng).toFixed(6)),
        suburb: data?.suburb,
        state: data?.state,
        postCode: data?.postCode,
        streetName: data?.streetName,
      };
      newState.fields.location.value = locationValue;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  setAddressFieldErrors(key) {
    const newState = {...this.state};
    newState.fields.address.errors = this.getAddressFieldErrors(key);
    this.setState(newState);
  }

  getAddressFieldErrors(key) {
    var errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    forEach(validators, (validator) => {
      if (validator.isInvalid(value.name) || validator.isInvalid(value.address)) {
        errors.push(validator.message);
      } else {
        errors = [];
      }

    });

    return errors;
  }

  handleLocationChange(data) {
    const location = {
      formatted_address: data.formatted_address,
      lat: data.lat,
      lng: data.lng,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
      streetName: data.streetName,
    };
    const address = this.state.fields.address.value;
    const newState = {...this.state};
    newState.fields.location.value = location;
    newState.fields.location.errors = [];

    if(data.lat && (isEmpty(address) || isEmpty(address.name))) {
      const addressValue = {
        name: data.formatted_address,
        address: data.formatted_address,
        latitude: data.lat,
        longitude: data.lng,
        suburb: data.suburb,
        state: data.state,
        postCode: data.postCode,
        streetName: data.streetName,
      };
      newState.fields.address.value = addressValue;
    }
    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }

  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value || null);
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    set(newState.fields, `${key}.value`, value);
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    if(!isEmpty(key)) {
      const value = get(this.state.fields, key).value;
      const validators = get(this.state.fields, key).validators || [];

      forEach(validators, (validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }

    return errors;
  }

  setFieldErrors(key) {
    const newState = {...this.state};
    set(newState.fields, `${key}.errors`, this.getFieldErrors(key));
    this.setState(newState);
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    forEach(newState.fields, (value, key) => {
      if (key !== 'address'){
        newState.fields[key].errors = this.getFieldErrors(key);
      }
    });

    this.setState(newState, () => this.setAddressFieldErrors('address'));
  }


  handleUpdateInput = (searchText, source, id) => {
    this.setFieldValue(id, searchText);
  };

  onBlurAbn(event) {
    this.fillCompanyDefaultValues = true;
    if(this.state.fields.abn.errors.length === 0 && this.state.fields.abn.value) {
      this.props.fetchCompanyByABN(event.target.value);
    } else {
      this.setState(state => ({
        ...state,
        showCompanyDetails: false
      }));
      this.setFieldErrors(event.target.id);
    }
  }

  navigateToCompanyForm() {
    document.location.hash = '#/companies';
  }

  handleMarketZoneChange(value) {
    if (value !== 'VALUE_NOT_SELECTED') {
      const newState = {...this.state};
      const marketZone = find(this.props.marketZonesWithRegions, {id: value});
      if(isEmpty(marketZone) || isEmpty(marketZone['regions'])){
        newState.regions = [];
        newState.fields.regionId.validators = [];
        newState.fields.regionId.errors = [];
        newState.disableRegionField = true;
      } else {
        newState.regions = marketZone['regions'];
        newState.fields.regionId.validators = [];
        newState.disableRegionField = false;
      }
      newState.fields.regionId.value = null;
      newState.fields.marketZoneId.value = value;
      this.setState(newState, () => this.setFieldErrors('marketZoneId'));
    }
  }

  handleFarmChange(value, id, item) {
    this.setState({selectedFarmId: value, selectedFarm: item}, () => {
      if(item) {
        item.company = this.props.company;
        this.setAllFieldsValuesBySelectedFarm(item);
      }
    });
  }

  isExistingFarmSelected() {
    return !isEmpty(
      get(this.props, 'company.farms')
    ) && find(
      this.props.existingFarms, {id: this.state.selectedFarmId}
    );
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  onAddressFieldBlur(event) {
    const address = this.state.fields.address.value;
    const location = this.state.fields.location.value;
    if(address && !address.latitude && (isEmpty(location) || isEmpty(location.formatted_address))) {
      if(this.props.selectedCompany && this.props.selectedCompany.address.latitude){
        const locationValue = {
          formatted_address: this.props.selectedCompany.address.address,
          lat: parseFloat(parseFloat(this.props.selectedCompany.address.latitude).toFixed(6)),
          lng: parseFloat(parseFloat(this.props.selectedCompany.address.longitude).toFixed(6)),
          suburb: this.props.selectedCompany.address.suburb,
          state: this.props.selectedCompany.address.state,
          postCode: this.props.selectedCompany.address.postCode,
          streetName: this.props.selectedCompany.address.streetName,
        };
        const newState = {...this.state};
        newState.defaultLocation = locationValue;
        this.setState(newState);
      }
    }
    this.setFieldErrors(event.target.id);
  }

  onRegionFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  cancelDrawer(){
    this.props.closeDrawer();
  }

  onOpenSideDrawer() {
    this.setState({
      isPrimaryBroker: get(this.props, 'selectedFarm.isManagedByUser'),
      openSideDrawer: true,
    });
  }

  onCloseSideDrawer() {
    this.setState({
      checkSubmit: false,
      openSideDrawer: false,
    });
  }

  canAccessFarm = () => {
    const typeId = get(this.props.currentUser,'company.typeId');
    const farmId = getFarmIdFromCurrentRoute();
    const selectedFarm = this.props.selectedFarm;
    if(selectedFarm && farmId === selectedFarm.id && ((
      typeId === GROWER && selectedFarm.isGrowerAcceptanceRequired && selectedFarm.isBrokerRequestAcceptedByGrower ) || (
        typeId === BROKER && selectedFarm.id === farmId && selectedFarm.brokerCompanyId !== this.props.companyId
      )
    )) {
      window.location.hash = `#/farms/${this.props.selectedFarm.id}/settings/details`;
    }
  };

  _getCompany() {
    return this.props.selectedCompany || get(this.props, 'selectedFarm.company') || this.props.company;
  }

  handleIsPrimaryBrokerChange = () => event => {
    const newState = {...this.state};
    newState.isPrimaryBroker = event.target.checked;
    this.setState(newState);
  };

  handleCheckbox(event) {
    const newState = { ...this.state };
    set(newState.fields, `${event.target.id}.value`, event.target.checked);
    if (event.target.id == 'weighBridge' && event.target.checked == false) {
      set(newState.fields, `weighBridgeCompanyName.value`, '');
      newState.fields.weighBridgeCompanyName.validators = [];
    }
    if (event.target.id == 'weighBridge' && event.target.checked == true) {
      newState.fields.weighBridgeCompanyName.validators = [required()];
    }
    this.setState(newState);
  }

  handleValuesToCopyChange(id, selectedItems) {
    const newState = { ...this.state };
    const items = uniqBy(selectedItems, 'id');
    set(newState, `fields.${id}.value`,  map(items, 'id'));
    this.setState(newState);
  }

  handleValuesToCopyChangeForField(event, selectedItems){
    this.handleValuesToCopyChange('valuesToCopy', selectedItems);
  }

  handleValuesToCopyChangeForStorage(event, selectedItems){
    this.handleValuesToCopyChange('valuesToCopyForOutloadFromStorage', selectedItems);
  }


  render() {
    this.canAccessFarm();
    const isGrowerSite = this.isGrowerSite();
    const dataSourceConfig = {
      text: 'name',
      value: 'id',
    };
    let company = this.props.selectedCompany || get(this.props, 'selectedFarm.company') || this.props.company;
    if(isEmpty(company)) {
      company = this.props.currentUser.company;
    }
    const country = getCurrentCountry(company?.countryCode)
    const disableForm = (this.isExistingFarmSelected() && isTransactionParticipated(company))  || (isCurrentUserGrower() || getCompanyIdFromCurrentRoute() ? this.state.isAcceptReject : (
      this.state.isAcceptReject ||
      (Object.keys(this.props.company).length == 0 || this.props.company['entityName'] == '' || this.state.fields.abn.value == '')
    ));

    const entityName = this.props.company.entityName && !getCompanyIdFromCurrentRoute() ?
                       (
                         <div className="col-sm-12 form-wrap-70">
                           <CommonTextField
                             id="entityName"
                             label="Entity Name"
                             disabled={true}
                             value={this.props.company.entityName}
                           />
                         </div>
                       ) : null;

    const businessName = this.props.company.businessName && !getCompanyIdFromCurrentRoute() ?
                         (
                           <div className="col-sm-12 form-wrap-70">
                             <CommonTextField
                               id="businessName"
                               disabled={true}
                               label="Business Name"
                               value={this.props.company.businessName}
                             />
                           </div>
                         ) : null;

    const companyButton = (
      this.props.newCompany && !includes(get(this.props.company, 'errors.abn[0]'), 'grower')
    ) ? (
      <Link to='/companies' style={{color: PRIMARY_COLOR_GREEN, fontSize: '0.75rem', marginTop: '-14px', marginLeft: '15px'}} >
        Add Company
      </Link>
    ) : null;

    const selectedFarm = this.props.selectedFarm;
    const brokerCompany = selectedFarm && find(this.props.brokerCompanies, {id: selectedFarm.brokerCompanyId});
    const entityLabel = this.entityLabel();
    const fieldsToCopyList = [
      {id: 'grade_id', name: 'Grades'},
      {id: 'specs', name: 'Specs'},
      {id: 'variety_id', name: 'Variety'},
      {id: 'estimated_net_weight', name: 'Net Weight'},
    ];
    const getForm = (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="cardForm cardForm--drawer">
          <div className="cardForm-content row">
            {
              !getCompanyIdFromCurrentRoute() && !isCurrentUserGrower() ?
              <div className="col-sm-12 form-wrap-70">
                <ABNField
                  id="abn"
                  label="ABN"
                  type="number"
                  placeholder="Please enter 11 digit ABN number"
                  value={this.state.fields.abn.value}
                  maxlength="11"
                  helperText= {this.props.newCompany ? get(this.props.company, 'errors.abn[0]') : (this.state.fields.abn.errors[0] || this.props.abnError) }
                  onBlur={this.onBlurAbn.bind(this)}
                  onChange={this.handleFieldChange}
                  disabled={!this.props.showHeading}
                  countryCode={country.code}
                />
              </div>
              : null
            }
            {
              !isCurrentUserGrower() ?
              <div className="col-sm-12" style={{padding: '0px'}}>
                { entityName }
              </div>
              : null
            }
            {
              !isCurrentUserGrower() ?
              <div className="col-sm-12" style={{padding: '0px'}}>
                { businessName }
              </div>
              : null
            }
            {
             this.props.company && !isEmpty(this.props.existingFarms) &&
              <div className="col-sm-12 form-wrap-70">
                <CommonSelect
                  id="selectedFarmId"
                  floatingLabelText={`Select ${entityLabel}`}
                  selectConfig={{text: 'name', value: 'id'}}
                  items={this.props.existingFarms}
                  value={this.state.selectedFarmId}
                  onChange={this.handleFarmChange}
                  style={{width: '100%'}}
                  includeEmptyOption={true}
                  emptyOptionText={`New ${entityLabel}`}
                />
              </div>
            }
            {
              !isCurrentUserGrower() ?
              <div className="col-sm-12" style={{padding: '0px'}}>
                { companyButton }
              </div>
              : null
            }
            {
              !this.isExistingFarmSelected() && <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="name"
                  label={`${entityLabel} Name`}
                  placeholder="Please enter"
                  helperText= {this.state.fields.name.errors[0]}
                  onChange={this.handleFieldChange}
                  value={this.state.fields.name.value}
                  maxLength="100"
                  onBlur={this.onFieldBlur}
                  disabled={disableForm || disableEditFarm(this.props.selectedFarm)}
                />
              </div>
            }
            <div className="col-sm-12 form-wrap">
              <GooglePlaceAutoComplete
                id="address"
                hintText="Please select an address from suggestions"
                floatingLabelText="Address"
                fullWidth
                value={this.state.fields.address.value.address}
                name={this.state.fields.address.value.name}
                errorText= {this.state.fields.address.errors[0]}
                onChange={this.handleAddressChange}
                errorStyle={{textAlign: "left"}}
                disabled={disableForm || disableEditFarm(this.props.selectedFarm)}
                onBlur={this.onAddressFieldBlur}
                countryCode={country.countryCode}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <PhoneField
                id="mobile"
                label="Phone/Mobile (Optional)"
                value={this.state.fields.mobile.value}
                onChange={this.handleFieldChange}
                helperText={this.state.fields.mobile.errors[0]}
                fullWidth
                disabled={disableForm || disableEditFarm(this.props.selectedFarm)}
                onBlur={this.onFieldBlur}
                countryCode={country.countryCode}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <GoogleMapPopup
                id="location"
                location={this.state.fields.location.value}
                defaultLocation={this.state.defaultLocation}
                errors={this.state.fields.location.errors}
                onDone={this.handleLocationChange}
                useCurrentGeoLocation={false}
                updateLocationFromSearchBox={this.handleAddressChange}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonAutoSelect
                id="marketZoneId"
                label="Market Zone (Optional)"
                items={this.props.marketZones}
                dataSourceConfig={dataSourceConfig}
                errorText={this.state.fields.marketZoneId.errors[0]}
                onChange={this.handleMarketZoneChange}
                value={this.state.fields.marketZoneId.value}
                onBlur={this.onFieldBlur}
                disabled={disableForm || disableEditFarm(this.props.selectedFarm)}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonAutoSelect
                id="regionId"
                label="Region (Optional)"
                items={isEmpty(this.state.regions) && isNumber(this.state.fields.marketZoneId.value) ? this.props.regions : this.state.regions}
                dataSourceConfig={dataSourceConfig}
                errorText={this.state.disableRegionField ? null : this.state.fields.regionId.errors[0]}
                onChange={this.handleSelectFieldChange}
                value={this.state.fields.regionId.value}
                onBlur={this.onRegionFieldBlur}
                disabled={disableForm || this.state.disableRegionField || disableEditFarm(this.props.selectedFarm)}
              />
            </div>
            {isSystemCompany() &&
            <div className="col-sm-12 form-wrap-70">
              <CommonAutoSelect
                  id='defaultTrack'
                  label='Default Port (Cash Prices)'
                  value={this.state.fields.defaultTrack.value}
                  errorStyle={{ textAlign: 'left' }}
                  onChange={this.handleSelectFieldChange}
                  items={this.props.tracks}
              />
            </div>
            }
            {
              isSystemCompany() && 
              <div className="col-sm-12 form-wrap-70">
                  <CommonAutoSelect
                    id="externallySyncSource"
                    label="External Sync Source (Optional)"
                    items={[{id: 'grainstor', name: 'GrainStor'}, {id: 'grainsoft', name: 'GrainSoft'}]}
                    value={this.state.fields.externallySyncSource.value}
                    onChange={this.handleSelectFieldChange}
                    dontAutoselectSingleItem
                  />
                </div>
            }
            {
              isCurrentUserGrower() && isGrowerSite ?
              <div className="col-sm-12 form-wrap-70">
                <CommonAutoSelect
                  id="brokerCompanyId"
                  label="Broker Company (Optional)"
                  items={this.props.brokerCompanies}
                  value={this.state.fields.brokerCompanyId.value}
                  errorText={this.state.fields.brokerCompanyId.errors[0]}
                  onChange={this.handleSelectFieldChange}
                  onBlur={this.onFieldBlur}
                  disabled={get(this.props, 'selectedFarm.brokerCompanyId')}
                  dontAutoselectSingleItem
                />
              </div>
              : ''
            }
            { company && !company.isRegistered && !brokerCompany && ((this.state.selectedFarm && !get(this.state.selectedFarm, 'isManaged') && !brokerCompany) || !this.state.selectedFarm) && isGrowerSite &&
              <div className="col-sm-12 form-wrap-70">
                <FormControlLabel
                  control={<Checkbox color="primary" value="primaryBroker" checked={this.state.isPrimaryBroker} onChange={this.handleIsPrimaryBrokerChange()} />}
                  label={`Are you the primary Broker for this ${entityLabel}?`}
                />
              </div>
            }
            {isSystemCompany() &&
              <div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="allowInloadSlotOrderBooking"
                        color="primary"
                        checked={this.state.fields.allowInloadSlotOrderBooking.value}
                        onChange={this.handleCheckbox}
                        disabled={this.props.selectedFarm?.id && !this.props.selectedFarm?.companySlotOrderBooking}
                      />
                    }
                    label="Allow Order Inload Slot Booking"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="allowOutloadSlotOrderBooking"
                        color="primary"
                        checked={this.state.fields.allowOutloadSlotOrderBooking.value}
                        onChange={this.handleCheckbox}
                        disabled={this.props.selectedFarm?.id && !this.props.selectedFarm?.companySlotOrderBooking}
                      />
                    }
                    label="Allow Order Outload Slot Booking"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="stocksManagement"
                        color="primary"
                        checked={this.state.fields.stocksManagement.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Stocks Management"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="grossFirstInload"
                        color="primary"
                        checked={this.state.fields.grossFirstInload.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Gross First Inload"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="grossFirstOutload"
                        color="primary"
                        checked={this.state.fields.grossFirstOutload.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Gross First Outload"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="populateDefaultWeights"
                        color="primary"
                        checked={this.state.fields.populateDefaultWeights.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Populate Default Weights"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isCashPricesEnabled"
                        color="primary"
                        checked={this.state.fields.isCashPricesEnabled.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Cash Prices Enabled"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="netWeightWhenPackingContainer"
                        color="primary"
                        checked={this.state.fields.netWeightWhenPackingContainer.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Net Weight When Packing Container"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="mandateTruckVendorDec"
                        color="primary"
                        checked={this.state.fields.mandateTruckVendorDec.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Mandate Truck Vendor Dec"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="weighBridge"
                        color="primary"
                        checked={this.state.fields.weighBridge.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Weigh Bridge"
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonAutoSelect
                    id="weighBridgeCompanyName"
                    label="Weigh Bridge Company Name"
                    items={WEIGH_BRIDGE_COMPANY_LIST}
                    dataSourceConfig={dataSourceConfig}
                    errorText={this.state.fields.weighBridgeCompanyName.errors[0]}
                    onChange={this.handleSelectFieldChange}
                    value={this.state.fields.weighBridgeCompanyName.value}
                    disabled={!this.state.fields.weighBridge.value}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="copyFromInload"
                        color="primary"
                        checked={this.state.fields.copyFromInload.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Copy From Inload (Outload from field)"
                  />
                </div>
              <div className="col-sm-12 form-wrap-70">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="valuesToCopy"
                  limitTags={1}
                  onChange={this.handleValuesToCopyChangeForField}
                  options={fieldsToCopyList}
                  getOptionLabel={option => option.name}
                  value={filter(fieldsToCopyList, item => includes(this.state.fields.valuesToCopy.value, item.id))}
                  renderInput={
                    params => <TextField {...params} label="Values To Copy" InputLabelProps={{ shrink: true }} variant="standard" fullWidth />
                  }
                  disabled={!this.state.fields.copyFromInload.value}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="copyToOutloadForStorage"
                        color="primary"
                        checked={this.state.fields.copyToOutloadForStorage.value}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label="Copy From Inload (Outload from storage)"
                  />
                </div>
              <div className="col-sm-12 form-wrap-70">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="valuesToCopyForOutloadFromStorage"
                  limitTags={1}
                  onChange={this.handleValuesToCopyChangeForStorage}
                  options={fieldsToCopyList}
                  getOptionLabel={option => option.name}
                  value={filter(fieldsToCopyList, item => includes(this.state.fields.valuesToCopyForOutloadFromStorage.value, item.id))}
                  renderInput={
                    params => <TextField {...params} label="Values To Copy" InputLabelProps={{ shrink: true }} variant="standard" fullWidth />
                  }
                  disabled={!this.state.fields.copyToOutloadForStorage.value}
                />
              </div>
            </div>
            }
            {
              this.state.isAcceptReject ?
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                  type="button"
                  label="Accept"
                  variant="contained"
                  primary
                  onClick={this.handleAccept}
                />
                <CommonButton
                  type="button"
                  label="Reject"
                  variant="contained"
                  secondary
                  onClick={this.handleReject}
                />
              </div>
              :
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                  type="button"
                  label="Cancel"
                  default
                  onClick={!selectedFarm ? this.cancelDrawer : this.onCloseSideDrawer}
                  variant="outlined"
                />
                <CommonButton
                  type="submit"
                  variant="contained"
                  label="Save"
                  primary
                />
              </div>
            }
          </div>
        </div>
      </form>
    );

    const getHeaderButtons = () => {
      if (this.state.isAcceptReject) {
        if (isCurrentUserGrower()) {
          return (
            <div>
              <CommonButton
                label="Accept"
                primary={true}
                onClick={this.handleAccept}
                variant="contained"
              />
              <CommonButton
                label="Reject"
                secondary={true}
                onClick={this.handleReject}
                variant="contained"
              />
            </div>
          );
        }

        return (
          <CommonButton
            label="Review"
            primary={true}
            onClick={this.onOpenSideDrawer}
            variant="contained"
          />
        );
      }
      if (canEditFarm(this.props.selectedFarm)) {
        return (
          <CommonButton
            label='Edit'
            primary={true}
            onClick={this.onOpenSideDrawer}
            variant="contained"
          />
        );
      }
    };

    const headerButtons = getHeaderButtons();

    return (
      <div>
        <SideDrawer
          isOpen={this.state.openSideDrawer}
          title={this.state.isAcceptReject ? `Review ${entityLabel}` : `Edit ${entityLabel}`}
          size="small"
          onClose={this.onCloseSideDrawer}
        >
          {getForm}
        </SideDrawer>
        {selectedFarm &&
         <div className="card">
           <div className="card-header">
             <h3 className="header-title" style={{display: 'inline-flex', alignItems: 'center'}}>
               {entityLabel} Details
         <React.Fragment>
           {
             selectedFarm?.externallySyncSource ?
               <ChipLabel label={`Synced from ${startCase(selectedFarm.externallySyncSource)}`} style={{marginLeft: '10px'}} color='primary' /> :
             null
           }
           {
            isStaff() && selectedFarm?.companySlotOrderBooking && !selectedFarm?.allowInloadSlotOrderBooking && !selectedFarm?.allowOutloadSlotOrderBooking ?
               <ChipLabel label='Slot-Order Booking Restricted' style={{marginLeft: '10px'}}  color='warning' /> :
             null
           }
         </React.Fragment>
             </h3>
             {
               headerButtons ?
                 <div className="header-content">
                   <div className="header-item">
                     {headerButtons}
                   </div>
                 </div>
               : null
             }
           </div>
           <div className="card-content">
             <div className="col-xs-6">
               <div className="label-content">
                 {country.config.abn.label}
                 <span>{(selectedFarm.company && selectedFarm.company.abn) || '-'}</span>
               </div>
               <div className="label-content">
                 Entity Name
                 <span>{(selectedFarm.company && selectedFarm.company.entityName) || '-'}</span>
               </div>
               <div className="label-content">
                 Business Name
                 <span>{(selectedFarm.company && selectedFarm.company.businessName) || '-'}</span>
               </div>
               <div className="label-content">
                 {entityLabel} Name
                 <span>{(selectedFarm.name) || '-'}</span>
               </div>
               <div className="label-content">
                 Address
                 <span>{(selectedFarm.company && selectedFarm.address.address) || '-'}</span>
               </div>
               {
                 get(selectedFarm, 'isAlone') &&
                 <div>
                   <div className="label-content">
                     Website
                     <span>{selectedFarm.company.website || '-'}</span>
                   </div>
                 </div>
               }
             </div>
             <div className="col-xs-6">
               <div className="label-content">
                 Market Zone
                 <span>{(selectedFarm.marketZone && selectedFarm.marketZone.name) || '-'}</span>
               </div>
               <div className="label-content">
                 Region
                 <span>{(selectedFarm.region && selectedFarm.region.name) || '-'}</span>
               </div>
               <div className="label-content">
                 Phone/Mobile
                 <span>{selectedFarm.mobile ? toPhoneFormat(selectedFarm.mobile) : '-'}</span>
               </div>
               {
                 get(selectedFarm, 'isAlone') &&
                 <div>
                   <div className="label-content">
                     Primary Business
                     <span>Grower</span>
                   </div>
                   <div className="label-content">
                     Start of week
                     <span>{getWeek(selectedFarm.company.startOfWeek)}</span>
                   </div>
                   <div className="label-content">
                     End of week
                     <span>{getWeek(selectedFarm.company.endOfWeek)}</span>
                   </div>
                   {
                     get(selectedFarm, 'companyLogoUrl') &&
                     <div className="label-content">
                       Logo
                       <span><img style={{width: '75px'}} src={get(selectedFarm, 'companyLogoUrl')}/></span>
                     </div>
                   }
                 </div>
               }
               {
                 isCurrentUserGrower() &&
                 isGrowerSite &&
                <div className="label-content">
                  Broker Company
                  <span>{(brokerCompany && brokerCompany.name) || '-'}</span>
                </div>
               }
             </div>
           </div>
         </div>
        }
        {!selectedFarm && getForm}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.main.user.user.companyId;
  const serverErrors = state.companies.farms.serverErrors;
  const marketZonesWithRegions = state.main.marketZones || [];
  const marketZones = marketZonesWithRegions.map(mz => ({ name: mz.name, id: mz.id}));
  const regions = flatMap(marketZonesWithRegions, 'regions');
  var newCompany = false;
  var company = { businessName: '', entityName: '' };

  if (has(state.main.company, 'errors')) {
    newCompany = true;
    company['errors'] = state.main.company.errors;
  } else {
    company = state.main.company;
  }

  let existingFarms = company.farms;

  if(existingFarms) {
    existingFarms = filter(company.farms, {isAcceptedByGrower: true});
  }

  const tracks = get(state.main, 'tracks');

  return {
    selectedFarm: state.companies.farms.selectedFarm,
    existingFarms,
    newCompany,
    company,
    companyId,
    serverErrors,
    marketZonesWithRegions,
    marketZones,
    regions,
    brokerCompanies: state.companies.companies.brokerCompanies,
    breadcrumbs: state.main.breadcrumbs,
    selectedCompany: state.companies.companies.selectedCompany,
    currentUser: state.main.user.user,
    tracks: tracks,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMarketZones: () => dispatch(fetchMarketZones()),
  clearAbnErrors: () => dispatch(clearAbnErrors()),
  fetchCompanyByABN: (abn) => dispatch(fetchCompanyByABN(abn)),
  getBrokerCompanies: () => dispatch(getBrokerCompanies()),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  getCompany:(companyId) => dispatch(getCompanyDetails(companyId)),
  getTracks: () => dispatch(getTracks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmForm);
