import { Paper } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { get, has, includes, isEmpty } from 'lodash';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import AddButton from '../common/AddButton';
import { getPackOrders } from '../../actions/companies/orders';
import PackOrdersTable from '../../containers/PackOrdersTable';
import { setHeaderText, setBreadcrumbs, forceStopLoader, setDownloadBar, isLoading } from '../../actions/main';
import { isAtGlobalOrders, defaultViewAction, attachCSVEventListener } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import { COMPANY_ADMIN, OBSERVER_TYPE_ID, OFFICE_ADMIN, SYSTEM, DEFAULT_ORDERS_TABLE_COLUMN_LIMIT, ORDERS_TABLE_COLUMN_LIMIT, PACK_ORDERS_HEADERS, PO_FILTER_STATUSES, PREDEFINED_DATE_RANGE_FILTER_KEYS, FILTER_KEYS_TO_EXCLUDE, PACK_ORDER_FILTER_KEYS_MAPPING} from '../../common/constants';
import DownloadDataDialog from '../common/DownloadDataDialog';
import { Button, Tooltip } from '@mui/material';
import Filters from '../common/Filters';
import FilterListIcon from '@mui/icons-material/FilterList';


class PackOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
      customTableColumnOptions: false,
      customTableColumnNames: {},
      csvPopup: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      csvData: [],
      customColumnTitle: undefined,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      filter_statuses: PO_FILTER_STATUSES,
      filterValues: {
        customer__company__id__in: [],
        freight_shipping__shipping_line__id__in: [],
        status__in: [],
        commodity__id__in: [],
        planned_grade__id__in: [],
        season__in: [],
        freight_container__consignor__handler__id__in: [],
        pack_by_date_range: '',
        freight_container__pack_by_date__gte: '',
        freight_container__pack_by_date__lte: '',
        deliver_by_date_range: '',
        freight_container__deliver_by_date__gte: '',
        freight_container__deliver_by_date__lte: '',
      },
    };
    this.toggleCustomColumnDownloads = this.toggleCustomColumnDownloads.bind(this);
  }

  componentDidMount() {
    this.fetchOrders();
    this.setHeaderAndBreadcrumbs();
    this._attachCSVEventListener()

    APIService.profiles()
      .filters('pack_order')
      .get()
      .then(res => this.setState({filters: get(res, 'pack_order', {})}));
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener('pack-orders-csv-ready', 'Orders', this.onDownloadResponse);
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs();
    this.props.forceStopLoader();
  }
  componentWillUnmount() {
    if(window.location.hash.includes('?') && isAtGlobalOrders())
      window.location.hash = window.location.hash.split('?')[0]
  }

  setHeaderAndBreadcrumbs() {
    const { count } = this.props;
    const countLabel = ` (${count})`;
    let headerText = 'Pack Orders';
    let breadcrumbs = [{ text: headerText + countLabel }];
    this.props.setHeaderText(headerText);
    this.props.setBreadcrumbs(breadcrumbs);
  }

  fetchOrders() {
    let queryParams = '';
    if (this.props.match.params && has(this.props.match.params, 'contract_id'))
      queryParams += `&commodity_contract_id=${get(this.props.match.params, 'contract_id')}`;
    this.setState({isLoading: true}, () => {
      const callback = orders => this.setState({orders: orders, isLoading: false})
      this.props.getPackOrders(null, null, queryParams, callback);
    });
  }

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/pack_order_table/`).get();
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const packOrderColumns = [...PACK_ORDERS_HEADERS];
    return packOrderColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  fetchCSVData = () => {
    var param = '';
    if (this.state.customColumns)
      param += '&custom_csv';
    const queryParams = new URLSearchParams(this.props.location.search)
    let commodityContractId = queryParams.get('commodity_contract_id') || get(this.props.match.params, 'contract_id');
    if(commodityContractId)
      param += `&commodity_contract_id=${commodityContractId}`;
    this.setState({csvPopup: false})
    this.props.setDownloadBar(`Your Pack Orders CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.`, true);
    APIService.freights().appendToUrl(`pack/orders/csv/?${param}`)
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || [] });
      });
  };

  customCsvEnabled() {
    const newState = {...this.state};
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  handleAddOrderButtonClick = () => {
    const func = this.props.onHandleAddPackOrderButtonClick;
    const order = get(this.props, 'order');
    if (func) func();
    else if (!order) window.location = '/#/orders/pack/new';
  }

  handleFilters = bool => this.setState({applyFilters: bool, openSideDrawer: bool});

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && isAtGlobalOrders()) {
        const { filters } = this.state;
        APIService.profiles()
                  .filters()
                  .post({ pack_order: filters })
                  .then(res => {
                    this.props.isLoading();
                    this.setState({ isLoading: true, filters: res?.filters?.pack_order || {} }, () => this.fetchOrders());
                  });
      }
    });
  };

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(PACK_ORDER_FILTER_KEYS_MAPPING, key)) : value.length !== 0

  toggleCustomColumnDownloads = () => {
    this.setState({customColumns: !this.state.customColumns})
  }

  render() {
    return (
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          <div>
            <AddButton
              label='Pack Order'
              onClick={this.handleAddOrderButtonClick}
              app='order'
              tooltipTitle='Create Pack Order'
              tooltipPlacement='top'
            />
          </div>
          {isAtGlobalOrders() && (
            <Tooltip title='Apply filters' placement='top'>
              <Button
                value={this.state.applyFilters}
                variant="contained"
                type='button'
                onClick={() => this.handleFilters(true)}
                color='primary'
                className='add-button'
                style={{ float: 'right', marginRight: '10px' }}
              >
                <FilterListIcon style={{ paddingRight: '5px' }} />
                FILTERS{' '}
                {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                  ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                  : ''}
              </Button>
            </Tooltip>
            )}
            {this.state.applyFilters && (
              <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                <Filters
                  isLoading={this.props.isLoading}
                  forceStopLoader={this.props.forceStopLoader}
                  handleFilterState={this.handleFilterState}
                  filters={this.state.filters}
                  statusTemp={this.state.filter_statuses}
                  filterValues={this.state.filterValues}
                  isPackOrderFilter
                />
              </SideDrawer>
            )}
          <div style={{float: 'right', marginRight: '10px'}}>
          <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
          </div>
          <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="pack_order_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={ORDERS_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_ORDERS_TABLE_COLUMN_LIMIT}
              />
           </SideDrawer>
          {this.canExportCSV() && (
            <CommonListingButton
              defaultHandler={() => this.customCsvEnabled()}
              optionMapper={[
                { name: 'Complete List', fx: () => this.customCsvEnabled() },
              ]}
              title='Download Contents of the table in a CSV'
              name='Export'
            />
          )}
          <DownloadDataDialog
            open={this.state.csvPopup}
            onClose={() => this.setState({csvPopup: false})}
            title='Download Pack Orders Data'
            onDownload={this.fetchCSVData}
            csvType="pack_orders_csv"
            enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
            customColumnTitle={this.state.customColumnTitle}
            updateColumnCount={(count) => this.updateColumnCount(count)}
            user={this.props.currentUser}
            token={this.props.token}
            toggleCustomColumnDownloads={this.toggleCustomColumnDownloads}
          />
          <PackOrdersTable dontRedirect={this.props.dontRedirect}  isContractDetailsModule={Boolean(this.props.contractId)}/>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    currentUser: state.main.user.user,
    count: get(state.companies.orders, 'paginationData.count') || 0,
  };
};

const mapDispatchToProps = dispatch => ({
  setHeaderText: text => dispatch(setHeaderText(text)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  getPackOrders: (url, forceStopLoader, queryParams, callback) => dispatch(getPackOrders(url, forceStopLoader, queryParams, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackOrders);
