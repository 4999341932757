import React from 'react';
import GeneralConditions from './GeneralConditions';
import SpecialConditions from './SpecialConditions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import ChainOfResponsibility from './ChainOfResponsibility';

class Conditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        specialConditionActive: false,
        value: 0
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  tabChanges = (event, value) => {
      this.setState({ value });
  };

  render() {
      const { value } = this.state;

    return (
      <div className="subTab">
        <Tabs indicatorColor="primary" className="subTab-header" value={value} onChange={this.tabChanges}>
          <Tab label="General Conditions"  className={value !== 0 ? 'unselected-subtab' : ''} />
          <Tab label="Special Conditions"  className={value !== 1 ? 'unselected-subtab' : ''} />
          <Tab label="Chain Of Responsibility"  className={value !== 2 ? 'unselected-subtab' : ''} />
        </Tabs>
        <div className="subTab-container">
          {value === 0 && <GeneralConditions {...this.props} />}
          {value === 1 && <SpecialConditions {...this.props} />}
          {value === 2 && <ChainOfResponsibility {...this.props} />}
        </div>
      </div>
    );
  }
}

export default Conditions;
