import React from 'react';
import SubscriberIcon from '@mui/icons-material/VerifiedUser';
import RegisteredIcon from '@mui/icons-material/PrivacyTip';
import find from 'lodash/find'
import get from 'lodash/get'
import startCase from 'lodash/startCase'
import isObject from 'lodash/isObject'
import { COMPANY_TYPES } from '../../common/constants'
import { isStaff, isObserver } from '../../common/utils';
import HTMLTooltip from '../common/HTMLTooltip';


const getValueFromKeys = (obj, keys) => {
  const key = find(keys, key => {
    const value = get(obj, key)
    return value || false
  })
  return key ? get(obj, key) : undefined
}

const SCHEMA = [
  {
    label: 'Business Name:',
    formatter: obj => {
      let keys = ['formattedName', 'displayName', 'name']
      let val = getValueFromKeys(obj, keys)
      if(obj.isSubscriber || obj.transactionParticipation)
        return <span style={{display: 'flex', alignItems: 'center'}}>
                 {val}
      <SubscriberIcon fontSize='small' color='primary' sx={{marginLeft: '4px', fontSize: '1rem', marginTop: '-3px'}} />
               </span>
      if(obj.isRegistered || obj.isRegisteredText === 'Yes')
        return <span style={{display: 'flex', alignItems: 'center'}}>
                 {val}
                 <RegisteredIcon fontSize='small' color='warning' sx={{marginLeft: '4px', fontSize: '1rem', marginTop: '-3px'}} />
               </span>
      return val
    }
  },
  {
    label: 'Entity Name:', keys: ['entityName']
  },
  {
    label: 'ABN:', keys: ['abn']
  },
  {
    label: 'Primary Business:',
    formatter: obj => {
      if(obj?.type?.displayName)
        return obj.type.displayName
      if(obj?.typeId) {
        let val = COMPANY_TYPES[obj.typeId.toString()]
        if(val)
          return val === 'bhc' ? 'BHC' : startCase(val)
      }
    }
  },
  {label: 'Address:', keys: ['address.address', 'address.name', 'address']},
  {label: 'ID', formatter: obj => (isStaff() || isObserver()) ? (obj?.companyId || obj?.id) : undefined}
]

const TooltipTitle = ({ company }) => {
  return (
    <div style={{width: '100%'}}>
      {
        SCHEMA.map(obj => {
          let val;
          if(obj?.keys?.length > 0) {
            val = getValueFromKeys(company, obj.keys)
            if(isObject(val))
              val = undefined
          } else if (obj?.formatter) {
            val = obj.formatter(company)
          }
          return val ? (
            <div key={obj.label} style={{display: 'flex', alignItems: 'flex-start', margin: '6px 0', flexDirection: 'column'}}>
              <span style={{color: 'rgba(0, 0, 0, 0.5)', marginRight: '16px', width: '100px', minWidth: '100px'}}>{obj.label}</span>
              <span>{val}</span>
            </div>
          ) : null
        })
      }
    </div>
  )
}

const CompanyTooltip = ({company, spanStyle, children}) => {
  return (
    <HTMLTooltip
      sx={{
        '.MuiTooltip-tooltip': {
          padding:'4px 12px'
        }
      }}
      title={
        <React.Fragment>
          <TooltipTitle company={company} />
        </React.Fragment>
      }
    >
      <span style={{display: 'flex', ...spanStyle}}>
        {children}
      </span>
    </HTMLTooltip>
  )
}

export default CompanyTooltip;
