import React, { Component } from 'react';
import { connect } from 'react-redux';
import {required, valueBetween} from '../../common/validators';
import CommonButton from '../common/CommonButton';
import APIService from "../../services/APIService";
import { TextField } from "@mui/material";
import {positiveDecimalFilter} from "../../common/input-filters";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import SpecParametersValue from "../common/SpecParametersValue";
import SeasonSelect from "../common/select/SeasonSelect";
import CommonSelect from "../common/select/CommonSelect";
import CommodityAutoComplete from "../common/autocomplete/CommodityAutoComplete";
import VarietyAutoComplete from "../common/autocomplete/VarietyAutoComplete";
import GradeAutoComplete from "../common/autocomplete/GradeAutoComplete";
import {COMMODITIES, FERTILISER_IDS, FIELD, MT_UNIT, SEASON_NA, SITE_EXTERNALLY_MANAGED_ERROR} from "../../common/constants";
import CommonAutoSelect from "../common/autocomplete/CommonAutoSelect";
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import { generateIdentifier, isSystemCompany, getCountryLabel, isCompanyGrower, isMoistureSpecMandatory, getCountryDisplayUnit } from "../../common/utils";
import CommonTextField from "../common/CommonTextField";
import {
  includes, isObject, every, has, get, set, find, cloneDeep, mapValues, some, forEach, isEmpty,
  isNull
} from "lodash";
import LoaderInline from '../LoaderInline';
import omit from 'lodash/omit';
import alertifyjs from 'alertifyjs';
import uniqBy from 'lodash/uniqBy';

class SystemStorageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFarm: null,
      selectedStorage: undefined,
      selectedCompanies: [],
      commoditySpecs: [],
      gradeSpecs: [],
      ngrs: [],
      farmInputText: '',
      handlers: [],
      storages: [],
      serverErrors: [],
      isVarietyMandatory: false,
      fields: {
        farmId: {
          value: props.farmId || undefined,
          validators: [required()],
          errors: [],
        },
        storageId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        inload:{
          comment: {
            value: null,
            validators: [],
            errors: []
          },
          commodityId: {
            value: null,
            validators: [required()],
            errors: []
          },
          varietyId: {
            value: null,
            validators: [],
            errors: []
          },
          gradeId: {
            value: null,
            validators: [required()],
            errors: []
          },
          season: {
            value: '',
            validators: [required()],
            errors: []
          },
          stockOwner: {
            value: '',
            validators: [required()],
            errors: []
          },
          ngrId: {
            value: null,
            validators: [required()],
            errors: []
          },
          estimatedNetWeight: {
            value: '',
            validators: [required()],
            errors: []
          },
          loadIdentifier: {
            value: generateIdentifier('load'),
            validators: [required()],
            errors: []
          },
          specs: {},
          quantity:{
            value: undefined,
            validators: [],
            errors: []
          },
        },
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.handleFarmInputChange = this.handleFarmInputChange.bind(this);
    this.handleFarmIdChange = this.handleFarmIdChange.bind(this);
    this.setFieldWarnings = this.setFieldWarnings.bind(this);
    this.getFieldWarnings = this.getFieldWarnings.bind(this);
  }

  componentDidMount() {
    this.fetchDirectoryCompanies();
  }



  isVarietyMandatory = () => {
    const { selectedFarm } = this.state
    let result = false
    if(selectedFarm?.isVarietyMandatory) {
      const isMandatoryForGrowers = selectedFarm.userTypeForVarietyMandatory.includes('growers')
      const isMandatoryForNonGrowers = selectedFarm.userTypeForVarietyMandatory.includes('non_growers')
      let isVarietyMandatoryForLoad = ['inload', 'inload_and_outload'].some(type => selectedFarm.loadTypeForVarietyMandatory.includes(type))
      result = isMandatoryForGrowers && isMandatoryForNonGrowers && isVarietyMandatoryForLoad
      if(!result && isVarietyMandatoryForLoad && this.state.fields.inload.stockOwner.value) {
        const company = find(this.state.selectedCompanies, {id: this.state.fields.inload.stockOwner.value})
        if(company) {
          const isGrower = isCompanyGrower(company)
          result = isMandatoryForGrowers ? isGrower : !isGrower
        }
      }
    }

    return result
  }

  setVarietyMandatory = () => {
    const isVarietyMandatory = this.isVarietyMandatory()
    if(isVarietyMandatory && !this.state.isVarietyMandatory) {
      const newState = {...this.state}
      newState.fields.inload.varietyId.validators = [required()];
      newState.isVarietyMandatory = true;
      setTimeout( () => this.setState(newState), 100)
    } else if(!isVarietyMandatory && this.state.isVarietyMandatory) {
      const newState = {...this.state}
      newState.fields.inload.varietyId.validators = [];
      newState.fields.inload.varietyId.errors = [];
      newState.isVarietyMandatory = false;
      setTimeout( () => this.setState(newState), 100)
    }
  }


  fetchStorages() {
    const farmId = this.state.fields.farmId.value;
    if(farmId) {
      APIService
        .farms(farmId)
        .storages('home')
        .appendToUrl('minimal/')
        .get(this.props.token)
        .then(storages => {
          this.setState({storages: storages});
        });
    } else this.setState({storages: []});
  }
  getNgrs= companyId => {
    const {token} = this.props;
    if(companyId)
      APIService
      .companies(companyId)
      .ngrs()
      .get(token)
      .then(ngrs => {
        this.setState({ngrs: ngrs});
      });
    else
      this.setState({ngrs: []});
  };

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      if(key == 'inload'){
        forEach(value, (valueField, valueKey) => {
          if(valueKey == 'commodity'){
            valueKey = 'commodityId';
          }
          newState.fields[key][valueKey].errors = valueField;
        });
      } else if(has(newState.fields,key)){
        newState.fields[key].errors = value;
      }
    });
    this.setState(newState);
  }

  handleSelectCompanyChange = async (value, id) =>{
    const {fields} = this.state;
    set(fields, `${id}.value`, value || null);
    this.setState({fields: fields}, this.setVarietyMandatory);
    await this.getNgrs(value);
  };

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (field, key) => {
      if(key !== 'specs' && key !== 'inload'){
        newState.fields[key].errors = this.getFieldErrors(key);
      } else if(key == 'inload'){
        forEach(field, (fieldValue, fieldKey) => {
          if (fieldKey === 'specs') {
            forEach(fieldValue, (value, specParam) => {
              newState.fields[key][fieldKey][specParam].errors = this.getFieldErrors(`${key}.${fieldKey}.${specParam}`);
            });
          }
          else{
            newState.fields[key][fieldKey].errors = this.getFieldErrors(`${key}.${fieldKey}`);
          }
        });
      }
    });
    this.setState(newState);
  }

  valid(fields) {
    return every(fields, (field) => {
      if(has(field, 'errors'))
        return field.errors.length === 0;
      else
        return true;
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors();

    let isFormInvalid = some(this.state.fields, (field) => {
      return some(field.validators, validator => {
        return validator.isInvalid(field.value);
      });
    });
    const inload = mapValues(this.state.fields.inload, 'value');
    inload.specs = mapValues(this.state.fields.inload.specs, 'value');

    const identifierLoad = await APIService.loads().appendToUrl(`identifier/${this.state.fields.inload.loadIdentifier.value.toUpperCase()}/`).get()
    if (identifierLoad) {
      const newState = { ...this.state };
      newState.fields.inload.loadIdentifier.errors.push('Identifier already exists');
      this.setState(newState);
      return
    } else {
      inload.loadIdentifier = inload.loadIdentifier.toUpperCase();
    }

    let data = {
      storageId: get(this.state.selectedStorage, 'id'),
      inload: inload
    };
    let site = find(this.state.handlers, {id: get(this.state, `fields.farmId.value`)});
    if (get(site, 'stocksManagement') == true && this.props.currentUser.companyId != get(site, 'companyId') && !isSystemCompany()){
      alertifyjs.error("Inturns into and Outturns from this site can only be created/edited by the company employees. Please contact the site manager for creating this load.");
      isFormInvalid = true;
    }
    if (!isFormInvalid && this.valid(this.state.fields.inload) && !this.getAreSpecsInvalid()){
      data = omit(data, ['inload.stockOwner']);
      this.props.submit(this.state.fields.farmId.value, data, this.props.afterSubmit);
    }
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    set(newState.fields, key + '.value', value);
    this.setState(newState, () => this.setFieldErrors(key));
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.handleFieldChange(event);
    }
  }

  getFieldErrors(key) {
    const errors = [];
    const value = get(this.state.fields,`${key}.value`);
    const validators = get(this.state.fields,`${key}.validators`) || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = { ...this.state };
    set(newState.fields, key + '.errors', this.getFieldErrors(key));
    this.setState(newState, ()=> {
      if (includes(key, 'gradeId'))
        this.setGradeSpecs();
    });
  }

  setFieldWarnings(path, specCode) {
    this.setState(state => set(state, `${path}.warnings`, this.getFieldWarnings(path, specCode)));
  }

  getFieldWarnings(path, specCode) {
    const { gradeSpecs } = this.state;
    const warnings = [];
    const value = get(this.state, `${path}.value`);
    
    gradeSpecs.forEach(spec => {
      if (spec['code'] === specCode) {
        const min = get(spec, 'min', null)
        const max = get(spec, 'max', null)
        if (!(isNull(min) || isNull(max) || isNull(value) || value === '')){
          if (!(value >= min && value <= max))
            warnings.push(`GTA Suggested Range: ${min} - ${max}`);
        }
      }}
    )
    return warnings;
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleFieldChange = (event) => {
    this.setFieldValue(event.target.id, event.target.value);
  };

  handleCommodityChange = (value) => {
    const pastCommodityId = this.state.fields.inload.commodityId.value
    if (pastCommodityId != value) {
      const commoditySpecs = get(find(this.props.commodities, { id: value }), 'specs', []);
      let isCommodityCanola = false;
      if (value === COMMODITIES.CANOLA) {
        isCommodityCanola = true;
      }
      const modelSpecs = this.getModelSpecsByCommoditySpecs(commoditySpecs, isCommodityCanola);
      const newState = {...this.state};
      newState.fields.inload.varietyId.value = undefined;
      newState.fields.inload.gradeId.value = undefined;
      newState.fields.inload.commodityId.value = value;
      newState.fields.inload.specs = cloneDeep(modelSpecs);
      newState.commoditySpecs = commoditySpecs;
      if(this.hasQuantityBasedCommodity())
        newState.fields.inload.quantity.validators = [required()];
      else
        newState.fields.inload.quantity.validators = [];

      if(FERTILISER_IDS.includes(value))
        newState.fields.inload.season.value = SEASON_NA
      else if(FERTILISER_IDS.includes(pastCommodityId) && this.state.fields.inload.season.value)
        newState.fields.inload.season.value = null

      this.setState(newState,() => {
        this.setFieldErrors('inload.commodityId');
      });
    }
  };

  getModelSpecsByCommoditySpecs(commoditySpecs, isCommodityCanola) {
    const modelSpecs = {};
    const isMoistureMandatory = isMoistureSpecMandatory();
    if (!isEmpty(commoditySpecs)) {
      forEach(commoditySpecs, (spec) => {
        modelSpecs[spec.code] = {
          ...FIELD,
          validators: [valueBetween(spec.min, spec.max, true)],
          value: '',
        };
        if (((isCommodityCanola && includes(['COIL', 'IMPU'], spec.code)) || (isMoistureMandatory && spec.code === 'M'))) {
          modelSpecs[spec.code]['validators'].push(required());
        }
      });
    }
    return modelSpecs;
  }

  handleVarietyChange = (value, id)  => {
    if (this.state.fields.inload.varietyId.value !== value) {
      this.setFieldValue(id, value);
    }
  };

  handleGradeChange = (valueObject, id) => {
    this.setFieldValue(id, valueObject.id);
  };


  setGradeSpecs() {
    const value = this.state.fields.inload.gradeId.value;
    const commodityGrades = get(
      find(this.props.commodities, { id: this.state.fields.inload.commodityId.value }),
      'grades',
      []
    );
    const gradeSpecs = get(
      find(commodityGrades, { id: value }),
      'specs',
      []
    );
    const gtaCode = get(
      find(commodityGrades, { id: value }),
      'gtaCode',
      []
    );
    if (value) {
      const updatedSpecs = gtaCode ? gradeSpecs : [];
      
      this.setState(
        state => ({
          ...state,
          gradeSpecs: updatedSpecs
        }), 
        () => {
          forEach(gradeSpecs, (specCode) => 
            this.setFieldWarnings(`fields.inload.specs.${specCode['code']}`, specCode['code'])
          );
        }
      );
    }
  } 

  getAreSpecsInvalid = () => {
    return some(this.state.fields.inload.specs, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  };

  handleSelectFieldChange = (value, id) => {
    this.setFieldValue(id, value);
  };

  handleSpecsChange = (specCode, specValue) => {
    this.setFieldValue(`inload.specs.${specCode}`, specValue);
    this.setFieldWarnings(`fields.inload.specs.${specCode}`, specCode);
  };


  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.fields.inload.commodityId.value
    return id ? find(this.props.commodities, {id: id}) : null
  }

  hasQuantityBasedCommodity(commodityId) {
    return Boolean(this.getSelectedCommodity(commodityId || this.state.fields.inload.commodityId.value)?.isQuantityBased)
  }

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  handleFarmInputChange = (event, value) => {
    this.setState({farmInputText: value});
  };

  fetchDirectoryCompanies = async () =>{
    let companyData = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(this.props.token);
    companyData.push({
      name: get(this.props, 'currentUser.company.name'),
      id: get(this.props, 'currentUser.companyId')
    });
    this.setState({
      selectedCompanies: companyData
    });
  };

  handleFarmIdChange = (event, item) => {
    const selectedFarm = isObject(item) ? item : undefined;
    const farmId = isObject(item) ? item.id : '';

    const newState = {...this.state};
    newState.selectedFarm = selectedFarm;
    newState.fields.farmId.value = farmId;
    newState.handlers = uniqBy([...newState.handlers, selectedFarm], 'id');
    if (get(selectedFarm, 'externallySyncSource'))
      alertifyjs.error(SITE_EXTERNALLY_MANAGED_ERROR);
    this.setState(newState, () => {
      this.fetchStorages()
      this.setVarietyMandatory()
    });
  };

  handleStorageChange = (value, id, item) => {
    const newState = {...this.state};
    newState.selectedStorage = item;
    newState.fields.storageId.value = value;
    this.setState(newState);
  };


  render() {
    const { specs } = this.state.fields.inload;
    let isCommodityCanola = this.state.fields.inload.commodityId.value === COMMODITIES.CANOLA;
    const unit = getCountryDisplayUnit() || MT_UNIT;
    return (
      <div>
        {
          this.state.isFetchingHandlers ?
          <LoaderInline containerClassName='inline-loader-container' /> :
          <form onSubmit={this.handleSubmit} noValidate>
            <div className="cardForm cardForm--drawer">
              <div className="cardForm-content row">
                <div className='col-sm-12 form-wrap-70'>
                  <SiteAsyncAutocomplete
                    limitTags={2}
                    label="Farm/Site Name"
                    id="farmId"
                    onChange={item => this.handleFarmIdChange(null, item)}
                    selected={get(this.state, 'selectedFarm', '')}
                    minLength={3}
                    variant="standard"
                    fullWidth
                    activeSitesOnly
                    addLabel
                    errorText={this.state.fields.farmId.errors[0]}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonAutoSelect
                    items={this.state.storages}
                    id="storageId"
                    label="Storage"
                    value={this.state.fields.storageId.value}
                    errorText={this.state.fields.storageId.errors[0]}
                    onChange={this.handleStorageChange}
                  />
                </div>
                <div className='col-sm-12 form-wrap-70'>
                    <TextField
                      id='inload.loadIdentifier'
                      label='Identifier'
                      placeholder='Identifier'
                      onChange={this.handleIdentifierChange}
                      value={this.state.fields.inload.loadIdentifier.value}
                      error={!isEmpty(this.state.fields.inload.loadIdentifier.errors)}
                      helperText={get(this.state, 'fields.inload.loadIdentifier.errors[0]', '')}
                      inputProps={{
                        maxLength: 14,
                      }}
                      fullWidth
                      variant='standard'
                    />
                  </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommodityAutoComplete
                    id="inload.commodityId"
                    floatingLabelText="Commodity"
                    commodityId={this.state.fields.inload.commodityId.value}
                    onChange={this.handleCommodityChange}
                    errorText={get(this.state, 'fields.inload.commodityId.errors[0]', '')}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <VarietyAutoComplete
                    id="inload.varietyId"
                    label={this.state.isVarietyMandatory ? "Variety" : "Variety (Optional)"}
                    commodityId={this.state.fields.inload.commodityId.value}
                    varietyId={this.state.fields.inload.varietyId.value}
                    dependsOnCommodity
                    onChange={this.handleVarietyChange}
                    errorText={get(this.state, 'fields.inload.varietyId.errors[0]', '')}
                    disabled={!!get(this.props, 'selectedHomeStorage.stocks[0].currentTonnage')}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <GradeAutoComplete
                    id="inload.gradeId"
                    floatingLabelText="Grade"
                    commodityId={this.state.fields.inload.commodityId.value}
                    onChange={this.handleGradeChange}
                    errorText={get(this.state, 'fields.inload.gradeId.errors[0]', '')}
                    disabled={!this.state.fields.inload.commodityId.value}
                    dependsOnCommodity
                    dependsOnSeason
                    specs={mapValues(this.state.fields.inload.specs, spec => spec.value)}
                    gradeId={this.state.fields.inload.gradeId.value}
                    selectedGradeId={this.state.fields.inload.gradeId.value}
                    season={this.state.fields.inload.season.value}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <SeasonSelect
                    id="inload.season"
                    floatingLabelText="Season"
                    onChange={this.handleSelectFieldChange}
                    season={this.state.fields.inload.season.value}
                    errorText={get(this.state, 'fields.inload.season.errors[0]', '')}
                  />
                </div>
                <div className={"col-sm-12 form-wrap-70"}>
                  <CommonAutoSelect
                    id="inload.stockOwner"
                    label="Stock Owner"
                    items={this.state.selectedCompanies}
                    selectConfig={{ value: 'id', text: 'name' }}
                    onChange={this.handleSelectCompanyChange}
                    errorText={get(this.state, 'fields.inload.stockOwner.errors[0]', '')}
                  />
                </div>

                <div className={"col-sm-12 form-wrap-70"}>
                  <CommonSelect
                    id="inload.ngrId"
                    floatingLabelText="NGR"
                    items={this.state.ngrs}
                    selectConfig={{ value: 'id', text: 'ngrNumber' }}
                    onChange={this.handleSelectFieldChange}
                    errorText={get(this.state, 'fields.inload.ngrId.errors[0]', '')}
                  />
                </div>
                {
                  this.hasQuantityBasedCommodity() && <div className={"col-sm-12 form-wrap-70"}>
                    <TextField
                      variant="standard"
                      error={!isEmpty(this.state.fields.inload.quantity.errors[0])}
                      id="inload.quantity"
                      label={this.quantityLabel()}
                      value={this.state.fields.inload.quantity.value || ''}
                      fullWidth
                      helperText={this.state.fields.inload.quantity.errors[0]}
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2,9999.99)}
                      onChange={this.handleFieldChange}
                      onBlur={this.handleFieldChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{this.quantityUnit()}</InputAdornment>
                      }}
                      type='number'
                    />
                  </div>
                }
                <div className={"col-sm-12 form-wrap-70"}>
                  <TextField
                    variant="standard"
                    error={!isEmpty(this.state.fields.inload.estimatedNetWeight.errors[0])}
                    id="inload.estimatedNetWeight"
                    label={getCountryLabel('stockField')}
                    placeholder={getCountryLabel('stockField')}
                    value={this.state.fields.inload.estimatedNetWeight.value || ''}
                    fullWidth
                    helperText={this.state.fields.inload.estimatedNetWeight.errors[0]}
                    onKeyDown={(event)=>positiveDecimalFilter(event, 2,9999.99)}
                    onChange={this.handleFieldChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{unit}</InputAdornment>
                    }}
                  />
                </div>
                <div className="label-small">
                  <SpecParametersValue
                    commoditySpecs={this.state.commoditySpecs}
                    fieldsSpecs={specs}
                    onChange={this.handleSpecsChange}
                    errorTexts={mapValues(specs, spec => {
                        return get(spec, 'errors[0]', '');
                    })}
                    warningTexts={mapValues(specs, spec => get(spec, 'warnings[0]', ''))}
                    allOptional={!isCommodityCanola && !isMoistureSpecMandatory()}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="inload.comment"
                    label="Comment (Optional)"
                    placeholder="Comment (Optional)"
                    value={this.state.fields.inload.comment.value || ''}
                    fullWidth
                    onChange={this.handleFieldChange}
                    multiline
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                  variant="outlined"
                  label="Cancel"
                  type="button"
                  default
                  onClick={this.props.closeDrawer}
                />
                <CommonButton variant="contained" primary={true} label='Save' type="submit" disabled={Boolean(get(this.state.selectedFarm, 'externallySyncSource'))} />
              </div>
            </div>
          </form>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    serverErrors: state.companies.systemStorages.serverErrors || [],
    currentUser: state.main.user.user,
    token: state.main.user.token,
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(SystemStorageForm);
