import React from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { setHeaderText, setBreadcrumbs } from '../../../actions/main';
import { isAtGlobalVendorDecs } from '../../../common/utils';
import APIService from '../../../services/APIService';
import Preview from '../Preview';
import LoaderInline from '../../LoaderInline';

class VendorDecDetails extends React.Component {

  constructor(props) {
    super(props);
    this.hasParent = !isAtGlobalVendorDecs();
    this.vendorDecId = get(this.props, 'match.params.vendor_dec_id');
    this.state = {
      previewData: null,
      vendorDec: null,
    };
  }

  componentDidMount() {
    this.setLayout();
    this.fetchPreviewData();
    this.fetchDetails();
  }

  componentDidUpdate() {
    const { headerText, breadcrumbs } = this.props;
    if(this.getHeaderText() !== headerText)
      this.setHeaderText();
    if(!isEqual(this.getBreadcrumbs(), breadcrumbs))
      this.setBreadcrumbs();
  }

  setLayout() {
    this.setHeaderText();
    this.setBreadcrumbs();
  }

  fetchPreviewData() {
    if(!this.vendorDecId)
      return;

    APIService.vendor_decs(this.vendorDecId).appendToUrl('pdf/').request(
      'GET', null, null, { responseType: 'blob' }
    ).then(response => {
      this.setState({
        previewData: window.URL.createObjectURL(response.data)
      });
    });
  }

  fetchDetails() {
    if(!this.vendorDecId)
      return;

    APIService.vendor_decs().appendToUrl(`web/${this.vendorDecId}/`).get().then(response => {
      this.setState({
        vendorDec: response
      });
    });
  }

  setHeaderText() {
    this.props.dispatch(setHeaderText(this.getHeaderText()));
  }

  setBreadcrumbs() {
    this.props.dispatch(setBreadcrumbs(this.getBreadcrumbs()));
  }

  getHeaderText() {
    const { headerText } = this.props;
    return this.hasParent ? headerText : this.detailsHeaderText();
  }

  detailsHeaderText() {
    const { vendorDec } = this.state;
    return `Vendor Declaration ${get(vendorDec, 'identifier', '...')}`;
  }

  getBreadcrumbs() {
    const { baseBreadcrumbs } = this.props;
    const { vendorDec } = this.state;
    const vendorDecBreadcrumb = {text: `${get(vendorDec, 'identifier', '....')}`};
    if(this.hasParent && baseBreadcrumbs)
      return [...baseBreadcrumbs, vendorDecBreadcrumb];
    return [
      {text: 'Vendor Declarations', route: '/vendor-decs'},
      vendorDecBreadcrumb
    ];
  }

  render() {
    const { previewData } = this.state;
    return(
      <div style={{height: 'calc(100vh - 175px'}}>
        {
          previewData ?
          <Preview data={previewData} /> :
          <LoaderInline />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs
  };
};

export default connect(mapStateToProps)(VendorDecDetails);
